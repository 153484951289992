@use '../../assets/style/colors.scss' as cols;


@mixin header-large() {
  font-family: 'FibraOneAlt', sans-serif;
  font-weight: 400;
  font-size: 48px;
  line-height: 54px;
  color: cols.$petroleum;
}
@mixin header-medium() {
  font-family: 'FibraOneAlt', sans-serif;
  font-weight: 400 !important;
  font-size: 32px !important;
  line-height: 40px !important;
  color: cols.$petroleum;
}
@mixin header-small() {
  font-family: 'FibraOneAlt', sans-serif;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: cols.$petroleum;
  margin-block-end: 0 !important;
}
@mixin header-x-small-bold() {
  font-family: 'FibraOneAlt', sans-serif;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: cols.$petroleum;
}
@mixin header-x-small() {
  font-family: 'FibraOneAlt', sans-serif;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: cols.$petroleum;
  margin-block-end: 0 !important;
}
@mixin body-header() {
  font-family: 'FibraOneAlt', sans-serif;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: cols.$petroleum;
}
@mixin body-copy() {
  font-family: 'FibraOneAlt', sans-serif;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: cols.$petroleum;
  margin-block-start: 0 !important;
}
@mixin body-small-header() {
  font-family: 'FibraOneAlt', sans-serif;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 18px !important;
  color: cols.$petroleum;
}
@mixin body-small() {
  font-family: 'FibraOneAlt', sans-serif;
  font-weight: 400;
  font-size: 13px !important;
  line-height: 18px !important;
  color: cols.$petroleum;
}
@mixin x-small() {
  font-family: 'FibraOneAlt', sans-serif;
  font-weight: 400 !important;
  font-size: 9px !important;
  line-height: 12px !important;
  color: cols.$petroleum;
}
