// Custom Theming for Angular Material
@use '@angular/material' as mat;
@use './variables.scss' as *;
@use './colors.scss' as *;
@use './utilities.scss' as *;
@use './material.scss' as *;
@use './mixin.scss' as *;
@use './fonts.scss';
@use './checkbox.scss';
@use './radio-button.scss';

@include mat.core();

$mit-evida-evida: mat.m2-define-palette($md-evida);
$mit-evida-primary: mat.m2-define-palette($mit-evida-evida, 500, A200, A200);
$mit-evida-accent: mat.m2-define-palette($mit-evida-evida, A200, A100, A400);
$mit-evida-warn: mat.m2-define-palette($mit-evida-evida);

$mit-evida-theme: mat.m2-define-light-theme((
  color: (
    primary: $mit-evida-primary,
    accent: $mit-evida-accent,
    warn: $mit-evida-warn,
    evida: $mit-evida-evida
  ),
));

* {
  box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

html {
  @include mat.all-component-typographies($mit-evida-typography);
  @include mat.all-component-themes($mit-evida-theme);
  
  --mdc-outlined-text-field-error-focus-outline-color: $mit-evida-theme.$warn !important;
  --mdc-outlined-text-field-error-hover-outline-color: $mit-evida-theme.$warn !important;
  --mdc-outlined-text-field-error-outline-color: $mit-evida-theme.$warn !important;
  --mdc-outlined-text-field-disabled-outline-color: #ed7100 !important;
}

html,
body {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  min-height: 100%;

  &.burger-menu {
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  color: $green;

  &:hover {
    text-decoration: underline;
  }
}

hr {
  border-width: 0;
  border-bottom: solid 1px $petroleum30pct;
}

code,
.code {
  background: $lightGray;
  display: inline-block;

  &--small {
    font-size: 10px;
  }
}

svg {
  shape-rendering: geometricPrecision;
}

.evida-elevated,
.mat-form-field-flex--evida-elevated .mat-form-field-wrapper .mat-form-field-flex,
.mat-mdc-form-field-flex--evida-elevated .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex {
  background: $white !important;
  border-radius: 5px;
  border-width: 0;
  box-shadow: 0px 0px 34px 4px #000000 8%;
  box-shadow: 0px 12px 34px 0px #000000 4%;
  box-shadow: 0px 4px 17px 0px #000000 8%;
}


/*
Because of the recent changes on the layout of the footer and the page content,
this spacing logic is going to be shared with the soft-login and evida modules
We used to have a boxing of this style

<content>
<footer mt100px>
<first-section mb50px >
<second-section >
</footer>

to have the menu cover the full height of the page we needed to rearrange the footer
so the first section would be floating. of course that broke things and spacing needed
to be moved outside. Now in the content section we must place this <div class="__spacer"></div>
to make up for the new design layout
*/

$previousSpacing: 100px;
$floatingSectionHeight: 180px;
$maxBottomSpacing: 50px;

.__spacer {
  // dunder to avoid collisions
  width: 100%;
  // using this syntax to let SCSS to handle this
  height: ($previousSpacing + $floatingSectionHeight + $maxBottomSpacing);
}

/*
Hide Arrows From Input Number
*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.tooltip-container {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05), 0px 3px 25px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px 15px;
  font-family: 'FibraOneAlt', sans-serif;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #0e4744;
  z-index: 100;
  position: fixed;
  max-width: 280px;
  width: auto;
  background: #ffffff;
  color: #0E4744;
  text-align: center;

  &.right {
    &::before {
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #ffffff;
      position: absolute;
      top: calc(50% - 10px);
      left: -10px;
    }
  }

  &.left {
    &::before {
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #ffffff;
      position: absolute;
      top: calc(50% - 10px);
      right: -10px;
    }
  }

  &.bottom {
    &::before {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #ffffff;
      position: absolute;
      left: calc(50% - 10px);
      top: -10px;
    }
  }

  &.top {
    &::before {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #ffffff;
      position: absolute;
      left: calc(50% - 10px);
      bottom: -10px;
    }
  }
}