@use './mixin.scss' as *;

/**
 * @license
 * MyFonts Webfont Build ID 3969139, 2020-11-05T08:24:15-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FibraOneAlt-Bold by Los Andes
 * URL: https://www.myfonts.com/fonts/los-andes/fibra-one/alt-bold/
 * Copyright: Jorge Cisterna
 *
 *
 *
 * © 2020 MyFonts Inc
*/

// FibraOneAlt-Bold
@font-face {
  font-family: 'FibraOneAlt';
  src: url('/assets/fonts/FibraOneAlt-Bold.woff2') format('woff2'),
    url('/assets/fonts/FibraOneAlt-Boldfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// FibraOneAlt-Light
@font-face {
  font-family: 'FibraOneAlt';
  src: url('/assets/fonts/FibraOneAlt-Light.woff2') format('woff2'),
    url('/assets/fonts/FibraOneAlt-Light.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Body header
strong,
.body-header {
  @include body-header();
}

// Body copy
body,
p,
.body-copy,
.mat-checkbox-label,
.mat-mdc-checkbox,
input,
mat-select,
mat-option,
mat-label {
  @include body-copy();
}

// Body small header
.small-header,
.body-small-header {
  @include body-small-header();
}

// Body small
small,
.small,
.body-small {
  @include body-small();
}

// Body x-small
.x-small {
  @include x-small();
}

.mat-mdc-outlined-button,
.mdc-button__label {
  letter-spacing: normal !important;
}

.mdc-button {
  line-height: 34px !important;
} 
