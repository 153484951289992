$petroleum: #0e4744;
$petroleum70pct: #567e7c;
$petroleum30pct: #b7c8c7;
$petroleum10pct: #e7edec;
$petroleum5pct: #f3f6f6;
$darkGreen: #267b38;
$green: #2ea149;
$green10pct: #eaf6ed;
$green20pct: #D5ECDB;
$orange: #ed7100;
$darkOrange: #aa3636;
$lightOrange: #fbe3cc;
$yellow: #e9c258;
$darkYellow: #c3b091;
$black: #1b1b1a;
$black40pct: #a4a4a3;
$gray: #949493;
$lightGray: #e5e5e5;
$gray15pct: #dcdcdc;
$borderGray: #e6e6e6;
$disabled: $petroleum30pct;
$error: $darkOrange;
$ok: $darkGreen;

$white: #ffffff;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$md-evida: (
  50: $petroleum5pct,
  100: $petroleum10pct,
  200: $petroleum30pct,
  300: $petroleum70pct,
  400: $petroleum,
  500: $green,
  600: $darkGreen,
  700: $black40pct,
  800: $black,
  900: $black,
  A100: $orange,
  A200: $darkOrange,
  A400: $yellow,
  A700: $darkYellow,
  contrast: (50: desaturate(#0e4744, 100%),
    100: desaturate(#126c31, 100%),
    200: #f3f6f6,
    300: desaturate(#1a8142, 100%),
    400: desaturate(#1e8949, 100%),
    500: desaturate(#409b64, 100%),
    600: desaturate(#35b558, 100%),
    700: desaturate(#2ea149, 100%),
    800: desaturate(#9ad19d, 100%),
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Keeping these because tailwind generates these as text-{color}
.fg-petroleum {
  color: $petroleum  !important;
}

.fg-petroleum70pct {
  color: $petroleum70pct  !important;
}

.fg-petroleum30pct {
  color: $petroleum30pct  !important;
}

.fg-petroleum10pct {
  color: $petroleum10pct  !important;
}

.fg-petroleum5pct {
  color: $petroleum5pct  !important;
}

.fg-darkGreen {
  color: $darkGreen  !important;
}

.fg-green {
  color: $green  !important;
}

.fg-green10pct {
  color: $green10pct  !important;
}

.fg-orange {
  color: $orange  !important;
}

.fg-darkOrange {
  color: $darkOrange  !important;
}

.fg-yellow {
  color: $yellow  !important;
}

.fg-darkYellow {
  color: $darkYellow  !important;
}

.fg-black {
  color: $black  !important;
}

.fg-black40pct {
  color: $black40pct  !important;
}

.fg-gray {
  color: $gray  !important;
}

.fg-lightGray {
  color: $lightGray  !important;
}

.fg-borderGray {
  color: $borderGray  !important;
}

.fg-white {
  color: $white  !important;
}
