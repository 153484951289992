@use "./colors.scss" as cols;

mat-radio-group {
  display: flex;
  flex-direction: column;

  mat-radio-button {
    .mdc-radio {
      margin-bottom: auto;
      margin-top: -5px;
    }

    .mdc-radio__background {
      background-color: transparent;
    }

    .mdc-radio__outer-circle {
      border: 1px solid cols.$borderGray !important;
      width: 28px !important;
      height: 28px !important;
      background-color: cols.$white;
    }

    .mdc-radio__inner-circle {
      background-color: cols.$green;
    }

    .mdc-radio__inner-circle {
      background-color: cols.$green !important;
      border-style: none !important;
      border: 18px solid cols.$green !important;
      position: absolute !important;
      top: -4px !important;
      left: -4px !important;
    }

    .mat-radio-ripple .mat-ripple-element {
      opacity: 0 !important;
    }

    .mdc-radio__background::before {
      display: none;
    }

    .mdc-radio__native-control:enabled:checked
      + .mdc-radio__background
      .mdc-radio__outer-circle,
    .mat-mdc-radio-button
      .mdc-radio__native-control:enabled:checked
      + .mdc-radio__background
      .mdc-radio__inner-circle {
      border: 2px solid cols.$green !important;
    }
  }
}
