@use '@angular/material' as mat;
@use './variables.scss' as vars;
@use './colors.scss' as cols;

mat-internal-form-field {
  width: 100%;
  background-color: red;
}

span.mat-mdc-form-field-required-marker {
  color: cols.$error !important;
}

.mat-mdc-dialog-container,
.mat-mdc-dialog-container .mdc-dialog__container,
.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 20px;
}

form {
  width: 100%;
}

.mat-mdc-row, .mdc-data-table__content {
  font-size: inherit !important
}

mat-card,
mat-card.mat-mdc-card {
  background: cols.$petroleum5pct !important;
  margin: 0 !important;
  padding: 30px;
  border-radius: 4px;
  border: transparent;
  box-shadow: none !important;

  &.mat-card--narrow {
    padding: 20px !important;
  }

  &.evida--dark {
    border-radius: 5px !important;
    box-shadow: none !important;
    padding: 30px !important;
    background: mat.m2-get-contrast-color-from-palette(cols.$md-evida, 200) !important;
  }

  mat-card-content,
  .mat-mdc-card-content {
    margin: 0 !important;
    padding: 0 !important;
  }

  mat-card-actions,
  mat-card-actions.mat-mdc-card-actions {
    width: 100%;
    margin: 0 !important;
  }

  mat-card-footer,
  mat-card-footer.mat-mdc-card-footer {
    padding-top: 40px;
    margin: 0 !important;
    display: flex !important;
  }

  /* Input form fields */

  mat-form-field {
    display: flex;
    padding-bottom: 10px;

    .mat-mdc-text-field-wrapper.mdc-text-field,
    .mat-mdc-form-field-flex {
      background-color: cols.$white !important;
    }
    
  }

  .mat-mdc-form-field-error {
    color: cols.$error !important;
  }
}
