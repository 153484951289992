@use "./colors.scss" as cols;

mat-checkbox {
    .mdc-checkbox__ripple {
        display: none;
    }

    .mdc-checkbox {
        margin-top: -2px !important;
        margin-right: 20px !important;
        box-sizing: border-box;
    }

    .mdc-checkbox__background {
        position: absolute;
        top: 0;
        left: 0;
        margin: auto !important;
        width: 27px !important;
        height: 27px !important;
        background-color: cols.$white;
        border-radius: 5px !important;
        outline-width: 1px;
        outline-style: solid;
        outline-color: cols.$borderGray !important;
        border-width: 0px;
    }

    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
        background-color: cols.$green !important;
    }

    .mdc-checkbox__checkmark {
        width: 16px;
        top: 6px;
        left: 6px;
    }

    .mdc-checkbox__checkmark-path {
        stroke: cols.$white !important;
        stroke-width: 4px !important;
    }
}