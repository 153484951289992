/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */

*, ::before, ::after {
  border-color: currentColor;
}

/**
  * This injects Tailwind's component classes and any component classes
  * registered by plugins.
  */

.container {
  width: 100%;
}

@media (min-width: 1px) {
  .container {
    max-width: 1px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1920px;
  }
}

/**
  * This injects Tailwind's utility classes and any utility classes registered
  * by plugins.
  */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.focus\:sr-only:focus {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.focus\:not-sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.inset-60px {
  top: 60px;
  right: 60px;
  bottom: 60px;
  left: 60px;
}

.inset-80px {
  top: 80px;
  right: 80px;
  bottom: 80px;
  left: 80px;
}

.inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-x-60px {
  left: 60px;
  right: 60px;
}

.inset-x-80px {
  left: 80px;
  right: 80px;
}

.inset-x-auto {
  left: auto;
  right: auto;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.inset-y-60px {
  top: 60px;
  bottom: 60px;
}

.inset-y-80px {
  top: 80px;
  bottom: 80px;
}

.inset-y-auto {
  top: auto;
  bottom: auto;
}

.top-0 {
  top: 0;
}

.top-60px {
  top: 60px;
}

.top-80px {
  top: 80px;
}

.top-auto {
  top: auto;
}

.right-0 {
  right: 0;
}

.right-60px {
  right: 60px;
}

.right-80px {
  right: 80px;
}

.right-auto {
  right: auto;
}

.bottom-0 {
  bottom: 0;
}

.bottom-60px {
  bottom: 60px;
}

.bottom-80px {
  bottom: 80px;
}

.bottom-auto {
  bottom: auto;
}

.left-0 {
  left: 0;
}

.left-60px {
  left: 60px;
}

.left-80px {
  left: 80px;
}

.left-auto {
  left: auto;
}

.isolate {
  isolation: isolate;
}

.isolation-auto {
  isolation: auto;
}

.z-0 {
  z-index: 0 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-auto {
  z-index: auto !important;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.order-none {
  order: 0;
}

.col-auto {
  grid-column: auto;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-12 {
  grid-column-start: 12;
}

.col-start-13 {
  grid-column-start: 13;
}

.col-start-auto {
  grid-column-start: auto;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-end-7 {
  grid-column-end: 7;
}

.col-end-8 {
  grid-column-end: 8;
}

.col-end-9 {
  grid-column-end: 9;
}

.col-end-10 {
  grid-column-end: 10;
}

.col-end-11 {
  grid-column-end: 11;
}

.col-end-12 {
  grid-column-end: 12;
}

.col-end-13 {
  grid-column-end: 13;
}

.col-end-auto {
  grid-column-end: auto;
}

.row-auto {
  grid-row: auto;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-4 {
  grid-row: span 4 / span 4;
}

.row-span-5 {
  grid-row: span 5 / span 5;
}

.row-span-6 {
  grid-row: span 6 / span 6;
}

.row-span-full {
  grid-row: 1 / -1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-start-7 {
  grid-row-start: 7;
}

.row-start-auto {
  grid-row-start: auto;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-end-6 {
  grid-row-end: 6;
}

.row-end-7 {
  grid-row-end: 7;
}

.row-end-auto {
  grid-row-end: auto;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both {
  clear: both;
}

.clear-none {
  clear: none;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 0.75rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-8 {
  margin: 2rem;
}

.m-10 {
  margin: 2.5rem;
}

.m-12 {
  margin: 3rem;
}

.m-16 {
  margin: 4rem;
}

.m-20 {
  margin: 5rem;
}

.m-24 {
  margin: 6rem;
}

.m-32 {
  margin: 8rem;
}

.m-40 {
  margin: 10rem;
}

.m-48 {
  margin: 12rem;
}

.m-56 {
  margin: 14rem;
}

.m-64 {
  margin: 16rem;
}

.m-auto {
  margin: auto;
}

.m-300px {
  margin: 300px;
}

.m-230px {
  margin: 230px;
}

.m-180px {
  margin: 180px;
}

.m-147px {
  margin: 147px;
}

.m-123px {
  margin: 123px;
}

.m-100px {
  margin: 100px;
}

.m-80px {
  margin: 80px;
}

.m-60px {
  margin: 60px;
}

.m-50px {
  margin: 50px;
}

.m-40px {
  margin: 40px;
}

.m-35px {
  margin: 35px;
}

.m-34px-i {
  margin: 34px !important;
}

.m-30px-i {
  margin: 30px !important;
}

.m-24px-i {
  margin: 24px !important;
}

.m-20px-i {
  margin: 20px !important;
}

.m-15px-i {
  margin: 15px !important;
}

.m-13px-i {
  margin: 13px !important;
}

.m-12px-i {
  margin: 12px !important;
}

.m-10px-i {
  margin: 10px !important;
}

.m-8px-i {
  margin: 8px !important;
}

.m-6px-i {
  margin: 6px !important;
}

.m-5px-i {
  margin: 5px !important;
}

.m-3px-i {
  margin: 3px !important;
}

.m-0px-i {
  margin: 0px !important;
}

.m-34px {
  margin: 34px;
}

.m-30px {
  margin: 30px;
}

.m-24px {
  margin: 24px;
}

.m-20px {
  margin: 20px;
}

.m-15px {
  margin: 15px;
}

.m-13px {
  margin: 13px;
}

.m-12px {
  margin: 12px;
}

.m-10px {
  margin: 10px;
}

.m-8px {
  margin: 8px;
}

.m-6px {
  margin: 6px;
}

.m-5px {
  margin: 5px;
}

.m-3px {
  margin: 3px;
}

.m-menu {
  margin: 275px;
}

.m-px {
  margin: 1px !important;
}

.-m-1 {
  margin: -0.25rem;
}

.-m-2 {
  margin: -0.5rem;
}

.-m-3 {
  margin: -0.75rem;
}

.-m-4 {
  margin: -1rem;
}

.-m-5 {
  margin: -1.25rem;
}

.-m-6 {
  margin: -1.5rem;
}

.-m-8 {
  margin: -2rem;
}

.-m-10 {
  margin: -2.5rem;
}

.-m-12 {
  margin: -3rem;
}

.-m-16 {
  margin: -4rem;
}

.-m-20 {
  margin: -5rem;
}

.-m-24 {
  margin: -6rem;
}

.-m-32 {
  margin: -8rem;
}

.-m-40 {
  margin: -10rem;
}

.-m-48 {
  margin: -12rem;
}

.-m-56 {
  margin: -14rem;
}

.-m-64 {
  margin: -16rem;
}

.-m-300px {
  margin: -300px;
}

.-m-230px {
  margin: -230px;
}

.-m-180px {
  margin: -180px;
}

.-m-147px {
  margin: -147px;
}

.-m-123px {
  margin: -123px;
}

.-m-100px {
  margin: -100px;
}

.-m-80px {
  margin: -80px;
}

.-m-60px {
  margin: -60px;
}

.-m-50px {
  margin: -50px;
}

.-m-40px {
  margin: -40px;
}

.-m-35px {
  margin: -35px;
}

.-m-34px-i {
  margin: 34px !important;
}

.-m-30px-i {
  margin: 30px !important;
}

.-m-24px-i {
  margin: 24px !important;
}

.-m-20px-i {
  margin: 20px !important;
}

.-m-15px-i {
  margin: 15px !important;
}

.-m-13px-i {
  margin: 13px !important;
}

.-m-12px-i {
  margin: 12px !important;
}

.-m-10px-i {
  margin: 10px !important;
}

.-m-8px-i {
  margin: 8px !important;
}

.-m-6px-i {
  margin: 6px !important;
}

.-m-5px-i {
  margin: 5px !important;
}

.-m-3px-i {
  margin: 3px !important;
}

.-m-0px-i {
  margin: 0px !important;
}

.-m-34px {
  margin: -34px;
}

.-m-30px {
  margin: -30px;
}

.-m-24px {
  margin: -24px;
}

.-m-20px {
  margin: -20px;
}

.-m-15px {
  margin: -15px;
}

.-m-13px {
  margin: -13px;
}

.-m-12px {
  margin: -12px;
}

.-m-10px {
  margin: -10px;
}

.-m-8px {
  margin: -8px;
}

.-m-6px {
  margin: -6px;
}

.-m-5px {
  margin: -5px;
}

.-m-3px {
  margin: -3px;
}

.-m-menu {
  margin: -275px;
}

.-m-px {
  margin: 1px !important;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}

.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}

.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-300px {
  margin-left: 300px;
  margin-right: 300px;
}

.mx-230px {
  margin-left: 230px;
  margin-right: 230px;
}

.mx-180px {
  margin-left: 180px;
  margin-right: 180px;
}

.mx-147px {
  margin-left: 147px;
  margin-right: 147px;
}

.mx-123px {
  margin-left: 123px;
  margin-right: 123px;
}

.mx-100px {
  margin-left: 100px;
  margin-right: 100px;
}

.mx-80px {
  margin-left: 80px;
  margin-right: 80px;
}

.mx-60px {
  margin-left: 60px;
  margin-right: 60px;
}

.mx-50px {
  margin-left: 50px;
  margin-right: 50px;
}

.mx-40px {
  margin-left: 40px;
  margin-right: 40px;
}

.mx-35px {
  margin-left: 35px;
  margin-right: 35px;
}

.mx-34px-i {
  margin-left: 34px !important;
  margin-right: 34px !important;
}

.mx-30px-i {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mx-24px-i {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.mx-20px-i {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mx-15px-i {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mx-13px-i {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.mx-12px-i {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.mx-10px-i {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mx-8px-i {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mx-6px-i {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.mx-5px-i {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mx-3px-i {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.mx-0px-i {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mx-34px {
  margin-left: 34px;
  margin-right: 34px;
}

.mx-30px {
  margin-left: 30px;
  margin-right: 30px;
}

.mx-24px {
  margin-left: 24px;
  margin-right: 24px;
}

.mx-20px {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-15px {
  margin-left: 15px;
  margin-right: 15px;
}

.mx-13px {
  margin-left: 13px;
  margin-right: 13px;
}

.mx-12px {
  margin-left: 12px;
  margin-right: 12px;
}

.mx-10px {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-8px {
  margin-left: 8px;
  margin-right: 8px;
}

.mx-6px {
  margin-left: 6px;
  margin-right: 6px;
}

.mx-5px {
  margin-left: 5px;
  margin-right: 5px;
}

.mx-3px {
  margin-left: 3px;
  margin-right: 3px;
}

.mx-menu {
  margin-left: 275px;
  margin-right: 275px;
}

.mx-px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}

.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}

.-mx-40 {
  margin-left: -10rem;
  margin-right: -10rem;
}

.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}

.-mx-56 {
  margin-left: -14rem;
  margin-right: -14rem;
}

.-mx-64 {
  margin-left: -16rem;
  margin-right: -16rem;
}

.-mx-300px {
  margin-left: -300px;
  margin-right: -300px;
}

.-mx-230px {
  margin-left: -230px;
  margin-right: -230px;
}

.-mx-180px {
  margin-left: -180px;
  margin-right: -180px;
}

.-mx-147px {
  margin-left: -147px;
  margin-right: -147px;
}

.-mx-123px {
  margin-left: -123px;
  margin-right: -123px;
}

.-mx-100px {
  margin-left: -100px;
  margin-right: -100px;
}

.-mx-80px {
  margin-left: -80px;
  margin-right: -80px;
}

.-mx-60px {
  margin-left: -60px;
  margin-right: -60px;
}

.-mx-50px {
  margin-left: -50px;
  margin-right: -50px;
}

.-mx-40px {
  margin-left: -40px;
  margin-right: -40px;
}

.-mx-35px {
  margin-left: -35px;
  margin-right: -35px;
}

.-mx-34px-i {
  margin-left: 34px !important;
  margin-right: 34px !important;
}

.-mx-30px-i {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.-mx-24px-i {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.-mx-20px-i {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.-mx-15px-i {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.-mx-13px-i {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.-mx-12px-i {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.-mx-10px-i {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.-mx-8px-i {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.-mx-6px-i {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.-mx-5px-i {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.-mx-3px-i {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.-mx-0px-i {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.-mx-34px {
  margin-left: -34px;
  margin-right: -34px;
}

.-mx-30px {
  margin-left: -30px;
  margin-right: -30px;
}

.-mx-24px {
  margin-left: -24px;
  margin-right: -24px;
}

.-mx-20px {
  margin-left: -20px;
  margin-right: -20px;
}

.-mx-15px {
  margin-left: -15px;
  margin-right: -15px;
}

.-mx-13px {
  margin-left: -13px;
  margin-right: -13px;
}

.-mx-12px {
  margin-left: -12px;
  margin-right: -12px;
}

.-mx-10px {
  margin-left: -10px;
  margin-right: -10px;
}

.-mx-8px {
  margin-left: -8px;
  margin-right: -8px;
}

.-mx-6px {
  margin-left: -6px;
  margin-right: -6px;
}

.-mx-5px {
  margin-left: -5px;
  margin-right: -5px;
}

.-mx-3px {
  margin-left: -3px;
  margin-right: -3px;
}

.-mx-menu {
  margin-left: -275px;
  margin-right: -275px;
}

.-mx-px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-300px {
  margin-top: 300px;
  margin-bottom: 300px;
}

.my-230px {
  margin-top: 230px;
  margin-bottom: 230px;
}

.my-180px {
  margin-top: 180px;
  margin-bottom: 180px;
}

.my-147px {
  margin-top: 147px;
  margin-bottom: 147px;
}

.my-123px {
  margin-top: 123px;
  margin-bottom: 123px;
}

.my-100px {
  margin-top: 100px;
  margin-bottom: 100px;
}

.my-80px {
  margin-top: 80px;
  margin-bottom: 80px;
}

.my-60px {
  margin-top: 60px;
  margin-bottom: 60px;
}

.my-50px {
  margin-top: 50px;
  margin-bottom: 50px;
}

.my-40px {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-35px {
  margin-top: 35px;
  margin-bottom: 35px;
}

.my-34px-i {
  margin-top: 34px !important;
  margin-bottom: 34px !important;
}

.my-30px-i {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my-24px-i {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-20px-i {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-15px-i {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-13px-i {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.my-12px-i {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.my-10px-i {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-8px-i {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-6px-i {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.my-5px-i {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my-3px-i {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.my-0px-i {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-34px {
  margin-top: 34px;
  margin-bottom: 34px;
}

.my-30px {
  margin-top: 30px;
  margin-bottom: 30px;
}

.my-24px {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-20px {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-15px {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my-13px {
  margin-top: 13px;
  margin-bottom: 13px;
}

.my-12px {
  margin-top: 12px;
  margin-bottom: 12px;
}

.my-10px {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-8px {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-6px {
  margin-top: 6px;
  margin-bottom: 6px;
}

.my-5px {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-3px {
  margin-top: 3px;
  margin-bottom: 3px;
}

.my-menu {
  margin-top: 275px;
  margin-bottom: 275px;
}

.my-px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.-my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.-my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem;
}

.-my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem;
}

.-my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem;
}

.-my-300px {
  margin-top: -300px;
  margin-bottom: -300px;
}

.-my-230px {
  margin-top: -230px;
  margin-bottom: -230px;
}

.-my-180px {
  margin-top: -180px;
  margin-bottom: -180px;
}

.-my-147px {
  margin-top: -147px;
  margin-bottom: -147px;
}

.-my-123px {
  margin-top: -123px;
  margin-bottom: -123px;
}

.-my-100px {
  margin-top: -100px;
  margin-bottom: -100px;
}

.-my-80px {
  margin-top: -80px;
  margin-bottom: -80px;
}

.-my-60px {
  margin-top: -60px;
  margin-bottom: -60px;
}

.-my-50px {
  margin-top: -50px;
  margin-bottom: -50px;
}

.-my-40px {
  margin-top: -40px;
  margin-bottom: -40px;
}

.-my-35px {
  margin-top: -35px;
  margin-bottom: -35px;
}

.-my-34px-i {
  margin-top: 34px !important;
  margin-bottom: 34px !important;
}

.-my-30px-i {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.-my-24px-i {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.-my-20px-i {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.-my-15px-i {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.-my-13px-i {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.-my-12px-i {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.-my-10px-i {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.-my-8px-i {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.-my-6px-i {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.-my-5px-i {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.-my-3px-i {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.-my-0px-i {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.-my-34px {
  margin-top: -34px;
  margin-bottom: -34px;
}

.-my-30px {
  margin-top: -30px;
  margin-bottom: -30px;
}

.-my-24px {
  margin-top: -24px;
  margin-bottom: -24px;
}

.-my-20px {
  margin-top: -20px;
  margin-bottom: -20px;
}

.-my-15px {
  margin-top: -15px;
  margin-bottom: -15px;
}

.-my-13px {
  margin-top: -13px;
  margin-bottom: -13px;
}

.-my-12px {
  margin-top: -12px;
  margin-bottom: -12px;
}

.-my-10px {
  margin-top: -10px;
  margin-bottom: -10px;
}

.-my-8px {
  margin-top: -8px;
  margin-bottom: -8px;
}

.-my-6px {
  margin-top: -6px;
  margin-bottom: -6px;
}

.-my-5px {
  margin-top: -5px;
  margin-bottom: -5px;
}

.-my-3px {
  margin-top: -3px;
  margin-bottom: -3px;
}

.-my-menu {
  margin-top: -275px;
  margin-bottom: -275px;
}

.-my-px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mt-56 {
  margin-top: 14rem;
}

.mt-64 {
  margin-top: 16rem;
}

.mt-auto {
  margin-top: auto;
}

.mt-300px {
  margin-top: 300px;
}

.mt-230px {
  margin-top: 230px;
}

.mt-180px {
  margin-top: 180px;
}

.mt-147px {
  margin-top: 147px;
}

.mt-123px {
  margin-top: 123px;
}

.mt-100px {
  margin-top: 100px;
}

.mt-80px {
  margin-top: 80px;
}

.mt-60px {
  margin-top: 60px;
}

.mt-50px {
  margin-top: 50px;
}

.mt-40px {
  margin-top: 40px;
}

.mt-35px {
  margin-top: 35px;
}

.mt-34px-i {
  margin-top: 34px !important;
}

.mt-30px-i {
  margin-top: 30px !important;
}

.mt-24px-i {
  margin-top: 24px !important;
}

.mt-20px-i {
  margin-top: 20px !important;
}

.mt-15px-i {
  margin-top: 15px !important;
}

.mt-13px-i {
  margin-top: 13px !important;
}

.mt-12px-i {
  margin-top: 12px !important;
}

.mt-10px-i {
  margin-top: 10px !important;
}

.mt-8px-i {
  margin-top: 8px !important;
}

.mt-6px-i {
  margin-top: 6px !important;
}

.mt-5px-i {
  margin-top: 5px !important;
}

.mt-3px-i {
  margin-top: 3px !important;
}

.mt-0px-i {
  margin-top: 0px !important;
}

.mt-34px {
  margin-top: 34px;
}

.mt-30px {
  margin-top: 30px;
}

.mt-24px {
  margin-top: 24px;
}

.mt-20px {
  margin-top: 20px;
}

.mt-15px {
  margin-top: 15px;
}

.mt-13px {
  margin-top: 13px;
}

.mt-12px {
  margin-top: 12px;
}

.mt-10px {
  margin-top: 10px;
}

.mt-8px {
  margin-top: 8px;
}

.mt-6px {
  margin-top: 6px;
}

.mt-5px {
  margin-top: 5px;
}

.mt-3px {
  margin-top: 3px;
}

.mt-menu {
  margin-top: 275px;
}

.mt-px {
  margin-top: 1px !important;
}

.-mt-1 {
  margin-top: -0.25rem;
}

.-mt-2 {
  margin-top: -0.5rem;
}

.-mt-3 {
  margin-top: -0.75rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mt-40 {
  margin-top: -10rem;
}

.-mt-48 {
  margin-top: -12rem;
}

.-mt-56 {
  margin-top: -14rem;
}

.-mt-64 {
  margin-top: -16rem;
}

.-mt-300px {
  margin-top: -300px;
}

.-mt-230px {
  margin-top: -230px;
}

.-mt-180px {
  margin-top: -180px;
}

.-mt-147px {
  margin-top: -147px;
}

.-mt-123px {
  margin-top: -123px;
}

.-mt-100px {
  margin-top: -100px;
}

.-mt-80px {
  margin-top: -80px;
}

.-mt-60px {
  margin-top: -60px;
}

.-mt-50px {
  margin-top: -50px;
}

.-mt-40px {
  margin-top: -40px;
}

.-mt-35px {
  margin-top: -35px;
}

.-mt-34px-i {
  margin-top: 34px !important;
}

.-mt-30px-i {
  margin-top: 30px !important;
}

.-mt-24px-i {
  margin-top: 24px !important;
}

.-mt-20px-i {
  margin-top: 20px !important;
}

.-mt-15px-i {
  margin-top: 15px !important;
}

.-mt-13px-i {
  margin-top: 13px !important;
}

.-mt-12px-i {
  margin-top: 12px !important;
}

.-mt-10px-i {
  margin-top: 10px !important;
}

.-mt-8px-i {
  margin-top: 8px !important;
}

.-mt-6px-i {
  margin-top: 6px !important;
}

.-mt-5px-i {
  margin-top: 5px !important;
}

.-mt-3px-i {
  margin-top: 3px !important;
}

.-mt-0px-i {
  margin-top: 0px !important;
}

.-mt-34px {
  margin-top: -34px;
}

.-mt-30px {
  margin-top: -30px;
}

.-mt-24px {
  margin-top: -24px;
}

.-mt-20px {
  margin-top: -20px;
}

.-mt-15px {
  margin-top: -15px;
}

.-mt-13px {
  margin-top: -13px;
}

.-mt-12px {
  margin-top: -12px;
}

.-mt-10px {
  margin-top: -10px;
}

.-mt-8px {
  margin-top: -8px;
}

.-mt-6px {
  margin-top: -6px;
}

.-mt-5px {
  margin-top: -5px;
}

.-mt-3px {
  margin-top: -3px;
}

.-mt-menu {
  margin-top: -275px;
}

.-mt-px {
  margin-top: 1px !important;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mr-24 {
  margin-right: 6rem;
}

.mr-32 {
  margin-right: 8rem;
}

.mr-40 {
  margin-right: 10rem;
}

.mr-48 {
  margin-right: 12rem;
}

.mr-56 {
  margin-right: 14rem;
}

.mr-64 {
  margin-right: 16rem;
}

.mr-auto {
  margin-right: auto;
}

.mr-300px {
  margin-right: 300px;
}

.mr-230px {
  margin-right: 230px;
}

.mr-180px {
  margin-right: 180px;
}

.mr-147px {
  margin-right: 147px;
}

.mr-123px {
  margin-right: 123px;
}

.mr-100px {
  margin-right: 100px;
}

.mr-80px {
  margin-right: 80px;
}

.mr-60px {
  margin-right: 60px;
}

.mr-50px {
  margin-right: 50px;
}

.mr-40px {
  margin-right: 40px;
}

.mr-35px {
  margin-right: 35px;
}

.mr-34px-i {
  margin-right: 34px !important;
}

.mr-30px-i {
  margin-right: 30px !important;
}

.mr-24px-i {
  margin-right: 24px !important;
}

.mr-20px-i {
  margin-right: 20px !important;
}

.mr-15px-i {
  margin-right: 15px !important;
}

.mr-13px-i {
  margin-right: 13px !important;
}

.mr-12px-i {
  margin-right: 12px !important;
}

.mr-10px-i {
  margin-right: 10px !important;
}

.mr-8px-i {
  margin-right: 8px !important;
}

.mr-6px-i {
  margin-right: 6px !important;
}

.mr-5px-i {
  margin-right: 5px !important;
}

.mr-3px-i {
  margin-right: 3px !important;
}

.mr-0px-i {
  margin-right: 0px !important;
}

.mr-34px {
  margin-right: 34px;
}

.mr-30px {
  margin-right: 30px;
}

.mr-24px {
  margin-right: 24px;
}

.mr-20px {
  margin-right: 20px;
}

.mr-15px {
  margin-right: 15px;
}

.mr-13px {
  margin-right: 13px;
}

.mr-12px {
  margin-right: 12px;
}

.mr-10px {
  margin-right: 10px;
}

.mr-8px {
  margin-right: 8px;
}

.mr-6px {
  margin-right: 6px;
}

.mr-5px {
  margin-right: 5px;
}

.mr-3px {
  margin-right: 3px;
}

.mr-menu {
  margin-right: 275px;
}

.mr-px {
  margin-right: 1px !important;
}

.-mr-1 {
  margin-right: -0.25rem;
}

.-mr-2 {
  margin-right: -0.5rem;
}

.-mr-3 {
  margin-right: -0.75rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.-mr-5 {
  margin-right: -1.25rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.-mr-8 {
  margin-right: -2rem;
}

.-mr-10 {
  margin-right: -2.5rem;
}

.-mr-12 {
  margin-right: -3rem;
}

.-mr-16 {
  margin-right: -4rem;
}

.-mr-20 {
  margin-right: -5rem;
}

.-mr-24 {
  margin-right: -6rem;
}

.-mr-32 {
  margin-right: -8rem;
}

.-mr-40 {
  margin-right: -10rem;
}

.-mr-48 {
  margin-right: -12rem;
}

.-mr-56 {
  margin-right: -14rem;
}

.-mr-64 {
  margin-right: -16rem;
}

.-mr-300px {
  margin-right: -300px;
}

.-mr-230px {
  margin-right: -230px;
}

.-mr-180px {
  margin-right: -180px;
}

.-mr-147px {
  margin-right: -147px;
}

.-mr-123px {
  margin-right: -123px;
}

.-mr-100px {
  margin-right: -100px;
}

.-mr-80px {
  margin-right: -80px;
}

.-mr-60px {
  margin-right: -60px;
}

.-mr-50px {
  margin-right: -50px;
}

.-mr-40px {
  margin-right: -40px;
}

.-mr-35px {
  margin-right: -35px;
}

.-mr-34px-i {
  margin-right: 34px !important;
}

.-mr-30px-i {
  margin-right: 30px !important;
}

.-mr-24px-i {
  margin-right: 24px !important;
}

.-mr-20px-i {
  margin-right: 20px !important;
}

.-mr-15px-i {
  margin-right: 15px !important;
}

.-mr-13px-i {
  margin-right: 13px !important;
}

.-mr-12px-i {
  margin-right: 12px !important;
}

.-mr-10px-i {
  margin-right: 10px !important;
}

.-mr-8px-i {
  margin-right: 8px !important;
}

.-mr-6px-i {
  margin-right: 6px !important;
}

.-mr-5px-i {
  margin-right: 5px !important;
}

.-mr-3px-i {
  margin-right: 3px !important;
}

.-mr-0px-i {
  margin-right: 0px !important;
}

.-mr-34px {
  margin-right: -34px;
}

.-mr-30px {
  margin-right: -30px;
}

.-mr-24px {
  margin-right: -24px;
}

.-mr-20px {
  margin-right: -20px;
}

.-mr-15px {
  margin-right: -15px;
}

.-mr-13px {
  margin-right: -13px;
}

.-mr-12px {
  margin-right: -12px;
}

.-mr-10px {
  margin-right: -10px;
}

.-mr-8px {
  margin-right: -8px;
}

.-mr-6px {
  margin-right: -6px;
}

.-mr-5px {
  margin-right: -5px;
}

.-mr-3px {
  margin-right: -3px;
}

.-mr-menu {
  margin-right: -275px;
}

.-mr-px {
  margin-right: 1px !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.mb-48 {
  margin-bottom: 12rem;
}

.mb-56 {
  margin-bottom: 14rem;
}

.mb-64 {
  margin-bottom: 16rem;
}

.mb-auto {
  margin-bottom: auto;
}

.mb-300px {
  margin-bottom: 300px;
}

.mb-230px {
  margin-bottom: 230px;
}

.mb-180px {
  margin-bottom: 180px;
}

.mb-147px {
  margin-bottom: 147px;
}

.mb-123px {
  margin-bottom: 123px;
}

.mb-100px {
  margin-bottom: 100px;
}

.mb-80px {
  margin-bottom: 80px;
}

.mb-60px {
  margin-bottom: 60px;
}

.mb-50px {
  margin-bottom: 50px;
}

.mb-40px {
  margin-bottom: 40px;
}

.mb-35px {
  margin-bottom: 35px;
}

.mb-34px-i {
  margin-bottom: 34px !important;
}

.mb-30px-i {
  margin-bottom: 30px !important;
}

.mb-24px-i {
  margin-bottom: 24px !important;
}

.mb-20px-i {
  margin-bottom: 20px !important;
}

.mb-15px-i {
  margin-bottom: 15px !important;
}

.mb-13px-i {
  margin-bottom: 13px !important;
}

.mb-12px-i {
  margin-bottom: 12px !important;
}

.mb-10px-i {
  margin-bottom: 10px !important;
}

.mb-8px-i {
  margin-bottom: 8px !important;
}

.mb-6px-i {
  margin-bottom: 6px !important;
}

.mb-5px-i {
  margin-bottom: 5px !important;
}

.mb-3px-i {
  margin-bottom: 3px !important;
}

.mb-0px-i {
  margin-bottom: 0px !important;
}

.mb-34px {
  margin-bottom: 34px;
}

.mb-30px {
  margin-bottom: 30px;
}

.mb-24px {
  margin-bottom: 24px;
}

.mb-20px {
  margin-bottom: 20px;
}

.mb-15px {
  margin-bottom: 15px;
}

.mb-13px {
  margin-bottom: 13px;
}

.mb-12px {
  margin-bottom: 12px;
}

.mb-10px {
  margin-bottom: 10px;
}

.mb-8px {
  margin-bottom: 8px;
}

.mb-6px {
  margin-bottom: 6px;
}

.mb-5px {
  margin-bottom: 5px;
}

.mb-3px {
  margin-bottom: 3px;
}

.mb-menu {
  margin-bottom: 275px;
}

.mb-px {
  margin-bottom: 1px !important;
}

.-mb-1 {
  margin-bottom: -0.25rem;
}

.-mb-2 {
  margin-bottom: -0.5rem;
}

.-mb-3 {
  margin-bottom: -0.75rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.-mb-12 {
  margin-bottom: -3rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.-mb-20 {
  margin-bottom: -5rem;
}

.-mb-24 {
  margin-bottom: -6rem;
}

.-mb-32 {
  margin-bottom: -8rem;
}

.-mb-40 {
  margin-bottom: -10rem;
}

.-mb-48 {
  margin-bottom: -12rem;
}

.-mb-56 {
  margin-bottom: -14rem;
}

.-mb-64 {
  margin-bottom: -16rem;
}

.-mb-300px {
  margin-bottom: -300px;
}

.-mb-230px {
  margin-bottom: -230px;
}

.-mb-180px {
  margin-bottom: -180px;
}

.-mb-147px {
  margin-bottom: -147px;
}

.-mb-123px {
  margin-bottom: -123px;
}

.-mb-100px {
  margin-bottom: -100px;
}

.-mb-80px {
  margin-bottom: -80px;
}

.-mb-60px {
  margin-bottom: -60px;
}

.-mb-50px {
  margin-bottom: -50px;
}

.-mb-40px {
  margin-bottom: -40px;
}

.-mb-35px {
  margin-bottom: -35px;
}

.-mb-34px-i {
  margin-bottom: 34px !important;
}

.-mb-30px-i {
  margin-bottom: 30px !important;
}

.-mb-24px-i {
  margin-bottom: 24px !important;
}

.-mb-20px-i {
  margin-bottom: 20px !important;
}

.-mb-15px-i {
  margin-bottom: 15px !important;
}

.-mb-13px-i {
  margin-bottom: 13px !important;
}

.-mb-12px-i {
  margin-bottom: 12px !important;
}

.-mb-10px-i {
  margin-bottom: 10px !important;
}

.-mb-8px-i {
  margin-bottom: 8px !important;
}

.-mb-6px-i {
  margin-bottom: 6px !important;
}

.-mb-5px-i {
  margin-bottom: 5px !important;
}

.-mb-3px-i {
  margin-bottom: 3px !important;
}

.-mb-0px-i {
  margin-bottom: 0px !important;
}

.-mb-34px {
  margin-bottom: -34px;
}

.-mb-30px {
  margin-bottom: -30px;
}

.-mb-24px {
  margin-bottom: -24px;
}

.-mb-20px {
  margin-bottom: -20px;
}

.-mb-15px {
  margin-bottom: -15px;
}

.-mb-13px {
  margin-bottom: -13px;
}

.-mb-12px {
  margin-bottom: -12px;
}

.-mb-10px {
  margin-bottom: -10px;
}

.-mb-8px {
  margin-bottom: -8px;
}

.-mb-6px {
  margin-bottom: -6px;
}

.-mb-5px {
  margin-bottom: -5px;
}

.-mb-3px {
  margin-bottom: -3px;
}

.-mb-menu {
  margin-bottom: -275px;
}

.-mb-px {
  margin-bottom: 1px !important;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-20 {
  margin-left: 5rem;
}

.ml-24 {
  margin-left: 6rem;
}

.ml-32 {
  margin-left: 8rem;
}

.ml-40 {
  margin-left: 10rem;
}

.ml-48 {
  margin-left: 12rem;
}

.ml-56 {
  margin-left: 14rem;
}

.ml-64 {
  margin-left: 16rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-300px {
  margin-left: 300px;
}

.ml-230px {
  margin-left: 230px;
}

.ml-180px {
  margin-left: 180px;
}

.ml-147px {
  margin-left: 147px;
}

.ml-123px {
  margin-left: 123px;
}

.ml-100px {
  margin-left: 100px;
}

.ml-80px {
  margin-left: 80px;
}

.ml-60px {
  margin-left: 60px;
}

.ml-50px {
  margin-left: 50px;
}

.ml-40px {
  margin-left: 40px;
}

.ml-35px {
  margin-left: 35px;
}

.ml-34px-i {
  margin-left: 34px !important;
}

.ml-30px-i {
  margin-left: 30px !important;
}

.ml-24px-i {
  margin-left: 24px !important;
}

.ml-20px-i {
  margin-left: 20px !important;
}

.ml-15px-i {
  margin-left: 15px !important;
}

.ml-13px-i {
  margin-left: 13px !important;
}

.ml-12px-i {
  margin-left: 12px !important;
}

.ml-10px-i {
  margin-left: 10px !important;
}

.ml-8px-i {
  margin-left: 8px !important;
}

.ml-6px-i {
  margin-left: 6px !important;
}

.ml-5px-i {
  margin-left: 5px !important;
}

.ml-3px-i {
  margin-left: 3px !important;
}

.ml-0px-i {
  margin-left: 0px !important;
}

.ml-34px {
  margin-left: 34px;
}

.ml-30px {
  margin-left: 30px;
}

.ml-24px {
  margin-left: 24px;
}

.ml-20px {
  margin-left: 20px;
}

.ml-15px {
  margin-left: 15px;
}

.ml-13px {
  margin-left: 13px;
}

.ml-12px {
  margin-left: 12px;
}

.ml-10px {
  margin-left: 10px;
}

.ml-8px {
  margin-left: 8px;
}

.ml-6px {
  margin-left: 6px;
}

.ml-5px {
  margin-left: 5px;
}

.ml-3px {
  margin-left: 3px;
}

.ml-menu {
  margin-left: 275px;
}

.ml-px {
  margin-left: 1px !important;
}

.-ml-1 {
  margin-left: -0.25rem;
}

.-ml-2 {
  margin-left: -0.5rem;
}

.-ml-3 {
  margin-left: -0.75rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-ml-5 {
  margin-left: -1.25rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-ml-8 {
  margin-left: -2rem;
}

.-ml-10 {
  margin-left: -2.5rem;
}

.-ml-12 {
  margin-left: -3rem;
}

.-ml-16 {
  margin-left: -4rem;
}

.-ml-20 {
  margin-left: -5rem;
}

.-ml-24 {
  margin-left: -6rem;
}

.-ml-32 {
  margin-left: -8rem;
}

.-ml-40 {
  margin-left: -10rem;
}

.-ml-48 {
  margin-left: -12rem;
}

.-ml-56 {
  margin-left: -14rem;
}

.-ml-64 {
  margin-left: -16rem;
}

.-ml-300px {
  margin-left: -300px;
}

.-ml-230px {
  margin-left: -230px;
}

.-ml-180px {
  margin-left: -180px;
}

.-ml-147px {
  margin-left: -147px;
}

.-ml-123px {
  margin-left: -123px;
}

.-ml-100px {
  margin-left: -100px;
}

.-ml-80px {
  margin-left: -80px;
}

.-ml-60px {
  margin-left: -60px;
}

.-ml-50px {
  margin-left: -50px;
}

.-ml-40px {
  margin-left: -40px;
}

.-ml-35px {
  margin-left: -35px;
}

.-ml-34px-i {
  margin-left: 34px !important;
}

.-ml-30px-i {
  margin-left: 30px !important;
}

.-ml-24px-i {
  margin-left: 24px !important;
}

.-ml-20px-i {
  margin-left: 20px !important;
}

.-ml-15px-i {
  margin-left: 15px !important;
}

.-ml-13px-i {
  margin-left: 13px !important;
}

.-ml-12px-i {
  margin-left: 12px !important;
}

.-ml-10px-i {
  margin-left: 10px !important;
}

.-ml-8px-i {
  margin-left: 8px !important;
}

.-ml-6px-i {
  margin-left: 6px !important;
}

.-ml-5px-i {
  margin-left: 5px !important;
}

.-ml-3px-i {
  margin-left: 3px !important;
}

.-ml-0px-i {
  margin-left: 0px !important;
}

.-ml-34px {
  margin-left: -34px;
}

.-ml-30px {
  margin-left: -30px;
}

.-ml-24px {
  margin-left: -24px;
}

.-ml-20px {
  margin-left: -20px;
}

.-ml-15px {
  margin-left: -15px;
}

.-ml-13px {
  margin-left: -13px;
}

.-ml-12px {
  margin-left: -12px;
}

.-ml-10px {
  margin-left: -10px;
}

.-ml-8px {
  margin-left: -8px;
}

.-ml-6px {
  margin-left: -6px;
}

.-ml-5px {
  margin-left: -5px;
}

.-ml-3px {
  margin-left: -3px;
}

.-ml-menu {
  margin-left: -275px;
}

.-ml-px {
  margin-left: 1px !important;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.inline-table {
  display: inline-table;
}

.table-caption {
  display: table-caption;
}

.table-cell {
  display: table-cell;
}

.table-column {
  display: table-column;
}

.table-column-group {
  display: table-column-group;
}

.table-footer-group {
  display: table-footer-group;
}

.table-header-group {
  display: table-header-group;
}

.table-row-group {
  display: table-row-group;
}

.table-row {
  display: table-row;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.list-item {
  display: list-item;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: 0.25rem;
}

.h-2 {
  height: 0.5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-32 {
  height: 8rem;
}

.h-40 {
  height: 10rem;
}

.h-48 {
  height: 12rem;
}

.h-56 {
  height: 14rem;
}

.h-64 {
  height: 16rem;
}

.h-auto {
  height: auto;
}

.h-300px {
  height: 300px;
}

.h-230px {
  height: 230px;
}

.h-180px {
  height: 180px;
}

.h-147px {
  height: 147px;
}

.h-123px {
  height: 123px;
}

.h-100px {
  height: 100px;
}

.h-80px {
  height: 80px;
}

.h-60px {
  height: 60px;
}

.h-50px {
  height: 50px;
}

.h-40px {
  height: 40px;
}

.h-35px {
  height: 35px;
}

.h-34px-i {
  height: 34px !important;
}

.h-30px-i {
  height: 30px !important;
}

.h-24px-i {
  height: 24px !important;
}

.h-20px-i {
  height: 20px !important;
}

.h-15px-i {
  height: 15px !important;
}

.h-13px-i {
  height: 13px !important;
}

.h-12px-i {
  height: 12px !important;
}

.h-10px-i {
  height: 10px !important;
}

.h-8px-i {
  height: 8px !important;
}

.h-6px-i {
  height: 6px !important;
}

.h-5px-i {
  height: 5px !important;
}

.h-3px-i {
  height: 3px !important;
}

.h-0px-i {
  height: 0px !important;
}

.h-34px {
  height: 34px;
}

.h-30px {
  height: 30px;
}

.h-24px {
  height: 24px;
}

.h-20px {
  height: 20px;
}

.h-15px {
  height: 15px;
}

.h-13px {
  height: 13px;
}

.h-12px {
  height: 12px;
}

.h-10px {
  height: 10px;
}

.h-8px {
  height: 8px;
}

.h-6px {
  height: 6px;
}

.h-5px {
  height: 5px;
}

.h-3px {
  height: 3px;
}

.h-menu {
  height: 275px;
}

.h-px {
  height: 1px !important;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-0 {
  min-height: 0;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-32 {
  width: 8rem;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-56 {
  width: 14rem;
}

.w-64 {
  width: 16rem;
}

.w-auto {
  width: auto !important;
}

.w-300px {
  width: 300px;
}

.w-230px {
  width: 230px;
}

.w-180px {
  width: 180px;
}

.w-147px {
  width: 147px;
}

.w-123px {
  width: 123px;
}

.w-100px {
  width: 100px;
}

.w-80px {
  width: 80px;
}

.w-60px {
  width: 60px;
}

.w-50px {
  width: 50px;
}

.w-40px {
  width: 40px;
}

.w-35px {
  width: 35px;
}

.w-34px-i {
  width: 34px !important;
}

.w-30px-i {
  width: 30px !important;
}

.w-24px-i {
  width: 24px !important;
}

.w-20px-i {
  width: 20px !important;
}

.w-15px-i {
  width: 15px !important;
}

.w-13px-i {
  width: 13px !important;
}

.w-12px-i {
  width: 12px !important;
}

.w-10px-i {
  width: 10px !important;
}

.w-8px-i {
  width: 8px !important;
}

.w-6px-i {
  width: 6px !important;
}

.w-5px-i {
  width: 5px !important;
}

.w-3px-i {
  width: 3px !important;
}

.w-0px-i {
  width: 0px !important;
}

.w-34px {
  width: 34px;
}

.w-30px {
  width: 30px;
}

.w-24px {
  width: 24px;
}

.w-20px {
  width: 20px;
}

.w-15px {
  width: 15px;
}

.w-13px {
  width: 13px;
}

.w-12px {
  width: 12px;
}

.w-10px {
  width: 10px;
}

.w-8px {
  width: 8px;
}

.w-6px {
  width: 6px;
}

.w-5px {
  width: 5px;
}

.w-3px {
  width: 3px;
}

.w-menu {
  width: 275px;
}

.w-px {
  width: 1px !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-1\/3 {
  width: 33.333333% !important;
}

.w-2\/3 {
  width: 66.666667% !important;
}

.w-1\/4 {
  width: 25% !important;
}

.w-2\/4 {
  width: 50% !important;
}

.w-3\/4 {
  width: 75% !important;
}

.w-1\/5 {
  width: 20% !important;
}

.w-2\/5 {
  width: 40% !important;
}

.w-3\/5 {
  width: 60% !important;
}

.w-4\/5 {
  width: 80% !important;
}

.w-1\/6 {
  width: 16.666667% !important;
}

.w-2\/6 {
  width: 33.333333% !important;
}

.w-3\/6 {
  width: 50% !important;
}

.w-4\/6 {
  width: 66.666667% !important;
}

.w-5\/6 {
  width: 83.333333% !important;
}

.w-1\/12 {
  width: 8.333333% !important;
}

.w-2\/12 {
  width: 16.666667% !important;
}

.w-3\/12 {
  width: 25% !important;
}

.w-4\/12 {
  width: 33.333333% !important;
}

.w-5\/12 {
  width: 41.666667% !important;
}

.w-6\/12 {
  width: 50% !important;
}

.w-7\/12 {
  width: 58.333333% !important;
}

.w-8\/12 {
  width: 66.666667% !important;
}

.w-9\/12 {
  width: 75% !important;
}

.w-10\/12 {
  width: 83.333333% !important;
}

.w-11\/12 {
  width: 91.666667% !important;
}

.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.min-w-0 {
  min-width: 0;
}

.min-w-full {
  min-width: 100%;
}

.max-w-1420px {
  max-width: 1420px;
}

.max-w-1004px {
  max-width: 1004px;
}

.max-w-920px {
  max-width: 920px;
}

.max-w-645px {
  max-width: 645px;
}

.max-w-menu {
  max-width: 275px;
}

.max-w-780px {
  max-width: 780px !important;
}

.max-w-240px {
  max-width: 240px !important;
}

.max-w-100px {
  max-width: 100px !important;
}

.max-w-285px {
  max-width: 285px !important;
}

.max-w-100pct {
  max-width: 100% !important;
}

.max-w-none {
  max-width: none !important;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-screen-xs {
  max-width: 1px;
}

.max-w-screen-sm {
  max-width: 768px;
}

.max-w-screen-md {
  max-width: 1024px;
}

.max-w-screen-lg {
  max-width: 1440px;
}

.max-w-screen-xl {
  max-width: 1920px;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
}

.origin-center {
  transform-origin: center;
}

.origin-top {
  transform-origin: top;
}

.origin-top-right {
  transform-origin: top right;
}

.origin-right {
  transform-origin: right;
}

.origin-bottom-right {
  transform-origin: bottom right;
}

.origin-bottom {
  transform-origin: bottom;
}

.origin-bottom-left {
  transform-origin: bottom left;
}

.origin-left {
  transform-origin: left;
}

.origin-top-left {
  transform-origin: top left;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-none {
  transform: none;
}

.translate-x-0 {
  --tw-translate-x: 0;
}

.translate-x-1 {
  --tw-translate-x: 0.25rem;
}

.translate-x-2 {
  --tw-translate-x: 0.5rem;
}

.translate-x-3 {
  --tw-translate-x: 0.75rem;
}

.translate-x-4 {
  --tw-translate-x: 1rem;
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
}

.translate-x-6 {
  --tw-translate-x: 1.5rem;
}

.translate-x-8 {
  --tw-translate-x: 2rem;
}

.translate-x-10 {
  --tw-translate-x: 2.5rem;
}

.translate-x-12 {
  --tw-translate-x: 3rem;
}

.translate-x-16 {
  --tw-translate-x: 4rem;
}

.translate-x-20 {
  --tw-translate-x: 5rem;
}

.translate-x-24 {
  --tw-translate-x: 6rem;
}

.translate-x-32 {
  --tw-translate-x: 8rem;
}

.translate-x-40 {
  --tw-translate-x: 10rem;
}

.translate-x-48 {
  --tw-translate-x: 12rem;
}

.translate-x-56 {
  --tw-translate-x: 14rem;
}

.translate-x-64 {
  --tw-translate-x: 16rem;
}

.translate-x-300px {
  --tw-translate-x: 300px;
}

.translate-x-230px {
  --tw-translate-x: 230px;
}

.translate-x-180px {
  --tw-translate-x: 180px;
}

.translate-x-147px {
  --tw-translate-x: 147px;
}

.translate-x-123px {
  --tw-translate-x: 123px;
}

.translate-x-100px {
  --tw-translate-x: 100px;
}

.translate-x-80px {
  --tw-translate-x: 80px;
}

.translate-x-60px {
  --tw-translate-x: 60px;
}

.translate-x-50px {
  --tw-translate-x: 50px;
}

.translate-x-40px {
  --tw-translate-x: 40px;
}

.translate-x-35px {
  --tw-translate-x: 35px;
}

.translate-x-34px-i {
  --tw-translate-x: 34px !important;
}

.translate-x-30px-i {
  --tw-translate-x: 30px !important;
}

.translate-x-24px-i {
  --tw-translate-x: 24px !important;
}

.translate-x-20px-i {
  --tw-translate-x: 20px !important;
}

.translate-x-15px-i {
  --tw-translate-x: 15px !important;
}

.translate-x-13px-i {
  --tw-translate-x: 13px !important;
}

.translate-x-12px-i {
  --tw-translate-x: 12px !important;
}

.translate-x-10px-i {
  --tw-translate-x: 10px !important;
}

.translate-x-8px-i {
  --tw-translate-x: 8px !important;
}

.translate-x-6px-i {
  --tw-translate-x: 6px !important;
}

.translate-x-5px-i {
  --tw-translate-x: 5px !important;
}

.translate-x-3px-i {
  --tw-translate-x: 3px !important;
}

.translate-x-0px-i {
  --tw-translate-x: 0px !important;
}

.translate-x-34px {
  --tw-translate-x: 34px;
}

.translate-x-30px {
  --tw-translate-x: 30px;
}

.translate-x-24px {
  --tw-translate-x: 24px;
}

.translate-x-20px {
  --tw-translate-x: 20px;
}

.translate-x-15px {
  --tw-translate-x: 15px;
}

.translate-x-13px {
  --tw-translate-x: 13px;
}

.translate-x-12px {
  --tw-translate-x: 12px;
}

.translate-x-10px {
  --tw-translate-x: 10px;
}

.translate-x-8px {
  --tw-translate-x: 8px;
}

.translate-x-6px {
  --tw-translate-x: 6px;
}

.translate-x-5px {
  --tw-translate-x: 5px;
}

.translate-x-3px {
  --tw-translate-x: 3px;
}

.translate-x-menu {
  --tw-translate-x: 275px;
}

.translate-x-px {
  --tw-translate-x: 1px !important;
}

.-translate-x-1 {
  --tw-translate-x: -0.25rem;
}

.-translate-x-2 {
  --tw-translate-x: -0.5rem;
}

.-translate-x-3 {
  --tw-translate-x: -0.75rem;
}

.-translate-x-4 {
  --tw-translate-x: -1rem;
}

.-translate-x-5 {
  --tw-translate-x: -1.25rem;
}

.-translate-x-6 {
  --tw-translate-x: -1.5rem;
}

.-translate-x-8 {
  --tw-translate-x: -2rem;
}

.-translate-x-10 {
  --tw-translate-x: -2.5rem;
}

.-translate-x-12 {
  --tw-translate-x: -3rem;
}

.-translate-x-16 {
  --tw-translate-x: -4rem;
}

.-translate-x-20 {
  --tw-translate-x: -5rem;
}

.-translate-x-24 {
  --tw-translate-x: -6rem;
}

.-translate-x-32 {
  --tw-translate-x: -8rem;
}

.-translate-x-40 {
  --tw-translate-x: -10rem;
}

.-translate-x-48 {
  --tw-translate-x: -12rem;
}

.-translate-x-56 {
  --tw-translate-x: -14rem;
}

.-translate-x-64 {
  --tw-translate-x: -16rem;
}

.-translate-x-300px {
  --tw-translate-x: -300px;
}

.-translate-x-230px {
  --tw-translate-x: -230px;
}

.-translate-x-180px {
  --tw-translate-x: -180px;
}

.-translate-x-147px {
  --tw-translate-x: -147px;
}

.-translate-x-123px {
  --tw-translate-x: -123px;
}

.-translate-x-100px {
  --tw-translate-x: -100px;
}

.-translate-x-80px {
  --tw-translate-x: -80px;
}

.-translate-x-60px {
  --tw-translate-x: -60px;
}

.-translate-x-50px {
  --tw-translate-x: -50px;
}

.-translate-x-40px {
  --tw-translate-x: -40px;
}

.-translate-x-35px {
  --tw-translate-x: -35px;
}

.-translate-x-34px-i {
  --tw-translate-x: 34px !important;
}

.-translate-x-30px-i {
  --tw-translate-x: 30px !important;
}

.-translate-x-24px-i {
  --tw-translate-x: 24px !important;
}

.-translate-x-20px-i {
  --tw-translate-x: 20px !important;
}

.-translate-x-15px-i {
  --tw-translate-x: 15px !important;
}

.-translate-x-13px-i {
  --tw-translate-x: 13px !important;
}

.-translate-x-12px-i {
  --tw-translate-x: 12px !important;
}

.-translate-x-10px-i {
  --tw-translate-x: 10px !important;
}

.-translate-x-8px-i {
  --tw-translate-x: 8px !important;
}

.-translate-x-6px-i {
  --tw-translate-x: 6px !important;
}

.-translate-x-5px-i {
  --tw-translate-x: 5px !important;
}

.-translate-x-3px-i {
  --tw-translate-x: 3px !important;
}

.-translate-x-0px-i {
  --tw-translate-x: 0px !important;
}

.-translate-x-34px {
  --tw-translate-x: -34px;
}

.-translate-x-30px {
  --tw-translate-x: -30px;
}

.-translate-x-24px {
  --tw-translate-x: -24px;
}

.-translate-x-20px {
  --tw-translate-x: -20px;
}

.-translate-x-15px {
  --tw-translate-x: -15px;
}

.-translate-x-13px {
  --tw-translate-x: -13px;
}

.-translate-x-12px {
  --tw-translate-x: -12px;
}

.-translate-x-10px {
  --tw-translate-x: -10px;
}

.-translate-x-8px {
  --tw-translate-x: -8px;
}

.-translate-x-6px {
  --tw-translate-x: -6px;
}

.-translate-x-5px {
  --tw-translate-x: -5px;
}

.-translate-x-3px {
  --tw-translate-x: -3px;
}

.-translate-x-menu {
  --tw-translate-x: -275px;
}

.-translate-x-px {
  --tw-translate-x: 1px !important;
}

.-translate-x-full {
  --tw-translate-x: -100%;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
}

.translate-x-full {
  --tw-translate-x: 100%;
}

.translate-y-0 {
  --tw-translate-y: 0;
}

.translate-y-1 {
  --tw-translate-y: 0.25rem;
}

.translate-y-2 {
  --tw-translate-y: 0.5rem;
}

.translate-y-3 {
  --tw-translate-y: 0.75rem;
}

.translate-y-4 {
  --tw-translate-y: 1rem;
}

.translate-y-5 {
  --tw-translate-y: 1.25rem;
}

.translate-y-6 {
  --tw-translate-y: 1.5rem;
}

.translate-y-8 {
  --tw-translate-y: 2rem;
}

.translate-y-10 {
  --tw-translate-y: 2.5rem;
}

.translate-y-12 {
  --tw-translate-y: 3rem;
}

.translate-y-16 {
  --tw-translate-y: 4rem;
}

.translate-y-20 {
  --tw-translate-y: 5rem;
}

.translate-y-24 {
  --tw-translate-y: 6rem;
}

.translate-y-32 {
  --tw-translate-y: 8rem;
}

.translate-y-40 {
  --tw-translate-y: 10rem;
}

.translate-y-48 {
  --tw-translate-y: 12rem;
}

.translate-y-56 {
  --tw-translate-y: 14rem;
}

.translate-y-64 {
  --tw-translate-y: 16rem;
}

.translate-y-300px {
  --tw-translate-y: 300px;
}

.translate-y-230px {
  --tw-translate-y: 230px;
}

.translate-y-180px {
  --tw-translate-y: 180px;
}

.translate-y-147px {
  --tw-translate-y: 147px;
}

.translate-y-123px {
  --tw-translate-y: 123px;
}

.translate-y-100px {
  --tw-translate-y: 100px;
}

.translate-y-80px {
  --tw-translate-y: 80px;
}

.translate-y-60px {
  --tw-translate-y: 60px;
}

.translate-y-50px {
  --tw-translate-y: 50px;
}

.translate-y-40px {
  --tw-translate-y: 40px;
}

.translate-y-35px {
  --tw-translate-y: 35px;
}

.translate-y-34px-i {
  --tw-translate-y: 34px !important;
}

.translate-y-30px-i {
  --tw-translate-y: 30px !important;
}

.translate-y-24px-i {
  --tw-translate-y: 24px !important;
}

.translate-y-20px-i {
  --tw-translate-y: 20px !important;
}

.translate-y-15px-i {
  --tw-translate-y: 15px !important;
}

.translate-y-13px-i {
  --tw-translate-y: 13px !important;
}

.translate-y-12px-i {
  --tw-translate-y: 12px !important;
}

.translate-y-10px-i {
  --tw-translate-y: 10px !important;
}

.translate-y-8px-i {
  --tw-translate-y: 8px !important;
}

.translate-y-6px-i {
  --tw-translate-y: 6px !important;
}

.translate-y-5px-i {
  --tw-translate-y: 5px !important;
}

.translate-y-3px-i {
  --tw-translate-y: 3px !important;
}

.translate-y-0px-i {
  --tw-translate-y: 0px !important;
}

.translate-y-34px {
  --tw-translate-y: 34px;
}

.translate-y-30px {
  --tw-translate-y: 30px;
}

.translate-y-24px {
  --tw-translate-y: 24px;
}

.translate-y-20px {
  --tw-translate-y: 20px;
}

.translate-y-15px {
  --tw-translate-y: 15px;
}

.translate-y-13px {
  --tw-translate-y: 13px;
}

.translate-y-12px {
  --tw-translate-y: 12px;
}

.translate-y-10px {
  --tw-translate-y: 10px;
}

.translate-y-8px {
  --tw-translate-y: 8px;
}

.translate-y-6px {
  --tw-translate-y: 6px;
}

.translate-y-5px {
  --tw-translate-y: 5px;
}

.translate-y-3px {
  --tw-translate-y: 3px;
}

.translate-y-menu {
  --tw-translate-y: 275px;
}

.translate-y-px {
  --tw-translate-y: 1px !important;
}

.-translate-y-1 {
  --tw-translate-y: -0.25rem;
}

.-translate-y-2 {
  --tw-translate-y: -0.5rem;
}

.-translate-y-3 {
  --tw-translate-y: -0.75rem;
}

.-translate-y-4 {
  --tw-translate-y: -1rem;
}

.-translate-y-5 {
  --tw-translate-y: -1.25rem;
}

.-translate-y-6 {
  --tw-translate-y: -1.5rem;
}

.-translate-y-8 {
  --tw-translate-y: -2rem;
}

.-translate-y-10 {
  --tw-translate-y: -2.5rem;
}

.-translate-y-12 {
  --tw-translate-y: -3rem;
}

.-translate-y-16 {
  --tw-translate-y: -4rem;
}

.-translate-y-20 {
  --tw-translate-y: -5rem;
}

.-translate-y-24 {
  --tw-translate-y: -6rem;
}

.-translate-y-32 {
  --tw-translate-y: -8rem;
}

.-translate-y-40 {
  --tw-translate-y: -10rem;
}

.-translate-y-48 {
  --tw-translate-y: -12rem;
}

.-translate-y-56 {
  --tw-translate-y: -14rem;
}

.-translate-y-64 {
  --tw-translate-y: -16rem;
}

.-translate-y-300px {
  --tw-translate-y: -300px;
}

.-translate-y-230px {
  --tw-translate-y: -230px;
}

.-translate-y-180px {
  --tw-translate-y: -180px;
}

.-translate-y-147px {
  --tw-translate-y: -147px;
}

.-translate-y-123px {
  --tw-translate-y: -123px;
}

.-translate-y-100px {
  --tw-translate-y: -100px;
}

.-translate-y-80px {
  --tw-translate-y: -80px;
}

.-translate-y-60px {
  --tw-translate-y: -60px;
}

.-translate-y-50px {
  --tw-translate-y: -50px;
}

.-translate-y-40px {
  --tw-translate-y: -40px;
}

.-translate-y-35px {
  --tw-translate-y: -35px;
}

.-translate-y-34px-i {
  --tw-translate-y: 34px !important;
}

.-translate-y-30px-i {
  --tw-translate-y: 30px !important;
}

.-translate-y-24px-i {
  --tw-translate-y: 24px !important;
}

.-translate-y-20px-i {
  --tw-translate-y: 20px !important;
}

.-translate-y-15px-i {
  --tw-translate-y: 15px !important;
}

.-translate-y-13px-i {
  --tw-translate-y: 13px !important;
}

.-translate-y-12px-i {
  --tw-translate-y: 12px !important;
}

.-translate-y-10px-i {
  --tw-translate-y: 10px !important;
}

.-translate-y-8px-i {
  --tw-translate-y: 8px !important;
}

.-translate-y-6px-i {
  --tw-translate-y: 6px !important;
}

.-translate-y-5px-i {
  --tw-translate-y: 5px !important;
}

.-translate-y-3px-i {
  --tw-translate-y: 3px !important;
}

.-translate-y-0px-i {
  --tw-translate-y: 0px !important;
}

.-translate-y-34px {
  --tw-translate-y: -34px;
}

.-translate-y-30px {
  --tw-translate-y: -30px;
}

.-translate-y-24px {
  --tw-translate-y: -24px;
}

.-translate-y-20px {
  --tw-translate-y: -20px;
}

.-translate-y-15px {
  --tw-translate-y: -15px;
}

.-translate-y-13px {
  --tw-translate-y: -13px;
}

.-translate-y-12px {
  --tw-translate-y: -12px;
}

.-translate-y-10px {
  --tw-translate-y: -10px;
}

.-translate-y-8px {
  --tw-translate-y: -8px;
}

.-translate-y-6px {
  --tw-translate-y: -6px;
}

.-translate-y-5px {
  --tw-translate-y: -5px;
}

.-translate-y-3px {
  --tw-translate-y: -3px;
}

.-translate-y-menu {
  --tw-translate-y: -275px;
}

.-translate-y-px {
  --tw-translate-y: 1px !important;
}

.-translate-y-full {
  --tw-translate-y: -100%;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}

.translate-y-1\/2 {
  --tw-translate-y: 50%;
}

.translate-y-full {
  --tw-translate-y: 100%;
}

.hover\:translate-x-0:hover {
  --tw-translate-x: 0;
}

.hover\:translate-x-1:hover {
  --tw-translate-x: 0.25rem;
}

.hover\:translate-x-2:hover {
  --tw-translate-x: 0.5rem;
}

.hover\:translate-x-3:hover {
  --tw-translate-x: 0.75rem;
}

.hover\:translate-x-4:hover {
  --tw-translate-x: 1rem;
}

.hover\:translate-x-5:hover {
  --tw-translate-x: 1.25rem;
}

.hover\:translate-x-6:hover {
  --tw-translate-x: 1.5rem;
}

.hover\:translate-x-8:hover {
  --tw-translate-x: 2rem;
}

.hover\:translate-x-10:hover {
  --tw-translate-x: 2.5rem;
}

.hover\:translate-x-12:hover {
  --tw-translate-x: 3rem;
}

.hover\:translate-x-16:hover {
  --tw-translate-x: 4rem;
}

.hover\:translate-x-20:hover {
  --tw-translate-x: 5rem;
}

.hover\:translate-x-24:hover {
  --tw-translate-x: 6rem;
}

.hover\:translate-x-32:hover {
  --tw-translate-x: 8rem;
}

.hover\:translate-x-40:hover {
  --tw-translate-x: 10rem;
}

.hover\:translate-x-48:hover {
  --tw-translate-x: 12rem;
}

.hover\:translate-x-56:hover {
  --tw-translate-x: 14rem;
}

.hover\:translate-x-64:hover {
  --tw-translate-x: 16rem;
}

.hover\:translate-x-300px:hover {
  --tw-translate-x: 300px;
}

.hover\:translate-x-230px:hover {
  --tw-translate-x: 230px;
}

.hover\:translate-x-180px:hover {
  --tw-translate-x: 180px;
}

.hover\:translate-x-147px:hover {
  --tw-translate-x: 147px;
}

.hover\:translate-x-123px:hover {
  --tw-translate-x: 123px;
}

.hover\:translate-x-100px:hover {
  --tw-translate-x: 100px;
}

.hover\:translate-x-80px:hover {
  --tw-translate-x: 80px;
}

.hover\:translate-x-60px:hover {
  --tw-translate-x: 60px;
}

.hover\:translate-x-50px:hover {
  --tw-translate-x: 50px;
}

.hover\:translate-x-40px:hover {
  --tw-translate-x: 40px;
}

.hover\:translate-x-35px:hover {
  --tw-translate-x: 35px;
}

.hover\:translate-x-34px-i:hover {
  --tw-translate-x: 34px !important;
}

.hover\:translate-x-30px-i:hover {
  --tw-translate-x: 30px !important;
}

.hover\:translate-x-24px-i:hover {
  --tw-translate-x: 24px !important;
}

.hover\:translate-x-20px-i:hover {
  --tw-translate-x: 20px !important;
}

.hover\:translate-x-15px-i:hover {
  --tw-translate-x: 15px !important;
}

.hover\:translate-x-13px-i:hover {
  --tw-translate-x: 13px !important;
}

.hover\:translate-x-12px-i:hover {
  --tw-translate-x: 12px !important;
}

.hover\:translate-x-10px-i:hover {
  --tw-translate-x: 10px !important;
}

.hover\:translate-x-8px-i:hover {
  --tw-translate-x: 8px !important;
}

.hover\:translate-x-6px-i:hover {
  --tw-translate-x: 6px !important;
}

.hover\:translate-x-5px-i:hover {
  --tw-translate-x: 5px !important;
}

.hover\:translate-x-3px-i:hover {
  --tw-translate-x: 3px !important;
}

.hover\:translate-x-0px-i:hover {
  --tw-translate-x: 0px !important;
}

.hover\:translate-x-34px:hover {
  --tw-translate-x: 34px;
}

.hover\:translate-x-30px:hover {
  --tw-translate-x: 30px;
}

.hover\:translate-x-24px:hover {
  --tw-translate-x: 24px;
}

.hover\:translate-x-20px:hover {
  --tw-translate-x: 20px;
}

.hover\:translate-x-15px:hover {
  --tw-translate-x: 15px;
}

.hover\:translate-x-13px:hover {
  --tw-translate-x: 13px;
}

.hover\:translate-x-12px:hover {
  --tw-translate-x: 12px;
}

.hover\:translate-x-10px:hover {
  --tw-translate-x: 10px;
}

.hover\:translate-x-8px:hover {
  --tw-translate-x: 8px;
}

.hover\:translate-x-6px:hover {
  --tw-translate-x: 6px;
}

.hover\:translate-x-5px:hover {
  --tw-translate-x: 5px;
}

.hover\:translate-x-3px:hover {
  --tw-translate-x: 3px;
}

.hover\:translate-x-menu:hover {
  --tw-translate-x: 275px;
}

.hover\:translate-x-px:hover {
  --tw-translate-x: 1px !important;
}

.hover\:-translate-x-1:hover {
  --tw-translate-x: -0.25rem;
}

.hover\:-translate-x-2:hover {
  --tw-translate-x: -0.5rem;
}

.hover\:-translate-x-3:hover {
  --tw-translate-x: -0.75rem;
}

.hover\:-translate-x-4:hover {
  --tw-translate-x: -1rem;
}

.hover\:-translate-x-5:hover {
  --tw-translate-x: -1.25rem;
}

.hover\:-translate-x-6:hover {
  --tw-translate-x: -1.5rem;
}

.hover\:-translate-x-8:hover {
  --tw-translate-x: -2rem;
}

.hover\:-translate-x-10:hover {
  --tw-translate-x: -2.5rem;
}

.hover\:-translate-x-12:hover {
  --tw-translate-x: -3rem;
}

.hover\:-translate-x-16:hover {
  --tw-translate-x: -4rem;
}

.hover\:-translate-x-20:hover {
  --tw-translate-x: -5rem;
}

.hover\:-translate-x-24:hover {
  --tw-translate-x: -6rem;
}

.hover\:-translate-x-32:hover {
  --tw-translate-x: -8rem;
}

.hover\:-translate-x-40:hover {
  --tw-translate-x: -10rem;
}

.hover\:-translate-x-48:hover {
  --tw-translate-x: -12rem;
}

.hover\:-translate-x-56:hover {
  --tw-translate-x: -14rem;
}

.hover\:-translate-x-64:hover {
  --tw-translate-x: -16rem;
}

.hover\:-translate-x-300px:hover {
  --tw-translate-x: -300px;
}

.hover\:-translate-x-230px:hover {
  --tw-translate-x: -230px;
}

.hover\:-translate-x-180px:hover {
  --tw-translate-x: -180px;
}

.hover\:-translate-x-147px:hover {
  --tw-translate-x: -147px;
}

.hover\:-translate-x-123px:hover {
  --tw-translate-x: -123px;
}

.hover\:-translate-x-100px:hover {
  --tw-translate-x: -100px;
}

.hover\:-translate-x-80px:hover {
  --tw-translate-x: -80px;
}

.hover\:-translate-x-60px:hover {
  --tw-translate-x: -60px;
}

.hover\:-translate-x-50px:hover {
  --tw-translate-x: -50px;
}

.hover\:-translate-x-40px:hover {
  --tw-translate-x: -40px;
}

.hover\:-translate-x-35px:hover {
  --tw-translate-x: -35px;
}

.hover\:-translate-x-34px-i:hover {
  --tw-translate-x: 34px !important;
}

.hover\:-translate-x-30px-i:hover {
  --tw-translate-x: 30px !important;
}

.hover\:-translate-x-24px-i:hover {
  --tw-translate-x: 24px !important;
}

.hover\:-translate-x-20px-i:hover {
  --tw-translate-x: 20px !important;
}

.hover\:-translate-x-15px-i:hover {
  --tw-translate-x: 15px !important;
}

.hover\:-translate-x-13px-i:hover {
  --tw-translate-x: 13px !important;
}

.hover\:-translate-x-12px-i:hover {
  --tw-translate-x: 12px !important;
}

.hover\:-translate-x-10px-i:hover {
  --tw-translate-x: 10px !important;
}

.hover\:-translate-x-8px-i:hover {
  --tw-translate-x: 8px !important;
}

.hover\:-translate-x-6px-i:hover {
  --tw-translate-x: 6px !important;
}

.hover\:-translate-x-5px-i:hover {
  --tw-translate-x: 5px !important;
}

.hover\:-translate-x-3px-i:hover {
  --tw-translate-x: 3px !important;
}

.hover\:-translate-x-0px-i:hover {
  --tw-translate-x: 0px !important;
}

.hover\:-translate-x-34px:hover {
  --tw-translate-x: -34px;
}

.hover\:-translate-x-30px:hover {
  --tw-translate-x: -30px;
}

.hover\:-translate-x-24px:hover {
  --tw-translate-x: -24px;
}

.hover\:-translate-x-20px:hover {
  --tw-translate-x: -20px;
}

.hover\:-translate-x-15px:hover {
  --tw-translate-x: -15px;
}

.hover\:-translate-x-13px:hover {
  --tw-translate-x: -13px;
}

.hover\:-translate-x-12px:hover {
  --tw-translate-x: -12px;
}

.hover\:-translate-x-10px:hover {
  --tw-translate-x: -10px;
}

.hover\:-translate-x-8px:hover {
  --tw-translate-x: -8px;
}

.hover\:-translate-x-6px:hover {
  --tw-translate-x: -6px;
}

.hover\:-translate-x-5px:hover {
  --tw-translate-x: -5px;
}

.hover\:-translate-x-3px:hover {
  --tw-translate-x: -3px;
}

.hover\:-translate-x-menu:hover {
  --tw-translate-x: -275px;
}

.hover\:-translate-x-px:hover {
  --tw-translate-x: 1px !important;
}

.hover\:-translate-x-full:hover {
  --tw-translate-x: -100%;
}

.hover\:-translate-x-1\/2:hover {
  --tw-translate-x: -50%;
}

.hover\:translate-x-1\/2:hover {
  --tw-translate-x: 50%;
}

.hover\:translate-x-full:hover {
  --tw-translate-x: 100%;
}

.hover\:translate-y-0:hover {
  --tw-translate-y: 0;
}

.hover\:translate-y-1:hover {
  --tw-translate-y: 0.25rem;
}

.hover\:translate-y-2:hover {
  --tw-translate-y: 0.5rem;
}

.hover\:translate-y-3:hover {
  --tw-translate-y: 0.75rem;
}

.hover\:translate-y-4:hover {
  --tw-translate-y: 1rem;
}

.hover\:translate-y-5:hover {
  --tw-translate-y: 1.25rem;
}

.hover\:translate-y-6:hover {
  --tw-translate-y: 1.5rem;
}

.hover\:translate-y-8:hover {
  --tw-translate-y: 2rem;
}

.hover\:translate-y-10:hover {
  --tw-translate-y: 2.5rem;
}

.hover\:translate-y-12:hover {
  --tw-translate-y: 3rem;
}

.hover\:translate-y-16:hover {
  --tw-translate-y: 4rem;
}

.hover\:translate-y-20:hover {
  --tw-translate-y: 5rem;
}

.hover\:translate-y-24:hover {
  --tw-translate-y: 6rem;
}

.hover\:translate-y-32:hover {
  --tw-translate-y: 8rem;
}

.hover\:translate-y-40:hover {
  --tw-translate-y: 10rem;
}

.hover\:translate-y-48:hover {
  --tw-translate-y: 12rem;
}

.hover\:translate-y-56:hover {
  --tw-translate-y: 14rem;
}

.hover\:translate-y-64:hover {
  --tw-translate-y: 16rem;
}

.hover\:translate-y-300px:hover {
  --tw-translate-y: 300px;
}

.hover\:translate-y-230px:hover {
  --tw-translate-y: 230px;
}

.hover\:translate-y-180px:hover {
  --tw-translate-y: 180px;
}

.hover\:translate-y-147px:hover {
  --tw-translate-y: 147px;
}

.hover\:translate-y-123px:hover {
  --tw-translate-y: 123px;
}

.hover\:translate-y-100px:hover {
  --tw-translate-y: 100px;
}

.hover\:translate-y-80px:hover {
  --tw-translate-y: 80px;
}

.hover\:translate-y-60px:hover {
  --tw-translate-y: 60px;
}

.hover\:translate-y-50px:hover {
  --tw-translate-y: 50px;
}

.hover\:translate-y-40px:hover {
  --tw-translate-y: 40px;
}

.hover\:translate-y-35px:hover {
  --tw-translate-y: 35px;
}

.hover\:translate-y-34px-i:hover {
  --tw-translate-y: 34px !important;
}

.hover\:translate-y-30px-i:hover {
  --tw-translate-y: 30px !important;
}

.hover\:translate-y-24px-i:hover {
  --tw-translate-y: 24px !important;
}

.hover\:translate-y-20px-i:hover {
  --tw-translate-y: 20px !important;
}

.hover\:translate-y-15px-i:hover {
  --tw-translate-y: 15px !important;
}

.hover\:translate-y-13px-i:hover {
  --tw-translate-y: 13px !important;
}

.hover\:translate-y-12px-i:hover {
  --tw-translate-y: 12px !important;
}

.hover\:translate-y-10px-i:hover {
  --tw-translate-y: 10px !important;
}

.hover\:translate-y-8px-i:hover {
  --tw-translate-y: 8px !important;
}

.hover\:translate-y-6px-i:hover {
  --tw-translate-y: 6px !important;
}

.hover\:translate-y-5px-i:hover {
  --tw-translate-y: 5px !important;
}

.hover\:translate-y-3px-i:hover {
  --tw-translate-y: 3px !important;
}

.hover\:translate-y-0px-i:hover {
  --tw-translate-y: 0px !important;
}

.hover\:translate-y-34px:hover {
  --tw-translate-y: 34px;
}

.hover\:translate-y-30px:hover {
  --tw-translate-y: 30px;
}

.hover\:translate-y-24px:hover {
  --tw-translate-y: 24px;
}

.hover\:translate-y-20px:hover {
  --tw-translate-y: 20px;
}

.hover\:translate-y-15px:hover {
  --tw-translate-y: 15px;
}

.hover\:translate-y-13px:hover {
  --tw-translate-y: 13px;
}

.hover\:translate-y-12px:hover {
  --tw-translate-y: 12px;
}

.hover\:translate-y-10px:hover {
  --tw-translate-y: 10px;
}

.hover\:translate-y-8px:hover {
  --tw-translate-y: 8px;
}

.hover\:translate-y-6px:hover {
  --tw-translate-y: 6px;
}

.hover\:translate-y-5px:hover {
  --tw-translate-y: 5px;
}

.hover\:translate-y-3px:hover {
  --tw-translate-y: 3px;
}

.hover\:translate-y-menu:hover {
  --tw-translate-y: 275px;
}

.hover\:translate-y-px:hover {
  --tw-translate-y: 1px !important;
}

.hover\:-translate-y-1:hover {
  --tw-translate-y: -0.25rem;
}

.hover\:-translate-y-2:hover {
  --tw-translate-y: -0.5rem;
}

.hover\:-translate-y-3:hover {
  --tw-translate-y: -0.75rem;
}

.hover\:-translate-y-4:hover {
  --tw-translate-y: -1rem;
}

.hover\:-translate-y-5:hover {
  --tw-translate-y: -1.25rem;
}

.hover\:-translate-y-6:hover {
  --tw-translate-y: -1.5rem;
}

.hover\:-translate-y-8:hover {
  --tw-translate-y: -2rem;
}

.hover\:-translate-y-10:hover {
  --tw-translate-y: -2.5rem;
}

.hover\:-translate-y-12:hover {
  --tw-translate-y: -3rem;
}

.hover\:-translate-y-16:hover {
  --tw-translate-y: -4rem;
}

.hover\:-translate-y-20:hover {
  --tw-translate-y: -5rem;
}

.hover\:-translate-y-24:hover {
  --tw-translate-y: -6rem;
}

.hover\:-translate-y-32:hover {
  --tw-translate-y: -8rem;
}

.hover\:-translate-y-40:hover {
  --tw-translate-y: -10rem;
}

.hover\:-translate-y-48:hover {
  --tw-translate-y: -12rem;
}

.hover\:-translate-y-56:hover {
  --tw-translate-y: -14rem;
}

.hover\:-translate-y-64:hover {
  --tw-translate-y: -16rem;
}

.hover\:-translate-y-300px:hover {
  --tw-translate-y: -300px;
}

.hover\:-translate-y-230px:hover {
  --tw-translate-y: -230px;
}

.hover\:-translate-y-180px:hover {
  --tw-translate-y: -180px;
}

.hover\:-translate-y-147px:hover {
  --tw-translate-y: -147px;
}

.hover\:-translate-y-123px:hover {
  --tw-translate-y: -123px;
}

.hover\:-translate-y-100px:hover {
  --tw-translate-y: -100px;
}

.hover\:-translate-y-80px:hover {
  --tw-translate-y: -80px;
}

.hover\:-translate-y-60px:hover {
  --tw-translate-y: -60px;
}

.hover\:-translate-y-50px:hover {
  --tw-translate-y: -50px;
}

.hover\:-translate-y-40px:hover {
  --tw-translate-y: -40px;
}

.hover\:-translate-y-35px:hover {
  --tw-translate-y: -35px;
}

.hover\:-translate-y-34px-i:hover {
  --tw-translate-y: 34px !important;
}

.hover\:-translate-y-30px-i:hover {
  --tw-translate-y: 30px !important;
}

.hover\:-translate-y-24px-i:hover {
  --tw-translate-y: 24px !important;
}

.hover\:-translate-y-20px-i:hover {
  --tw-translate-y: 20px !important;
}

.hover\:-translate-y-15px-i:hover {
  --tw-translate-y: 15px !important;
}

.hover\:-translate-y-13px-i:hover {
  --tw-translate-y: 13px !important;
}

.hover\:-translate-y-12px-i:hover {
  --tw-translate-y: 12px !important;
}

.hover\:-translate-y-10px-i:hover {
  --tw-translate-y: 10px !important;
}

.hover\:-translate-y-8px-i:hover {
  --tw-translate-y: 8px !important;
}

.hover\:-translate-y-6px-i:hover {
  --tw-translate-y: 6px !important;
}

.hover\:-translate-y-5px-i:hover {
  --tw-translate-y: 5px !important;
}

.hover\:-translate-y-3px-i:hover {
  --tw-translate-y: 3px !important;
}

.hover\:-translate-y-0px-i:hover {
  --tw-translate-y: 0px !important;
}

.hover\:-translate-y-34px:hover {
  --tw-translate-y: -34px;
}

.hover\:-translate-y-30px:hover {
  --tw-translate-y: -30px;
}

.hover\:-translate-y-24px:hover {
  --tw-translate-y: -24px;
}

.hover\:-translate-y-20px:hover {
  --tw-translate-y: -20px;
}

.hover\:-translate-y-15px:hover {
  --tw-translate-y: -15px;
}

.hover\:-translate-y-13px:hover {
  --tw-translate-y: -13px;
}

.hover\:-translate-y-12px:hover {
  --tw-translate-y: -12px;
}

.hover\:-translate-y-10px:hover {
  --tw-translate-y: -10px;
}

.hover\:-translate-y-8px:hover {
  --tw-translate-y: -8px;
}

.hover\:-translate-y-6px:hover {
  --tw-translate-y: -6px;
}

.hover\:-translate-y-5px:hover {
  --tw-translate-y: -5px;
}

.hover\:-translate-y-3px:hover {
  --tw-translate-y: -3px;
}

.hover\:-translate-y-menu:hover {
  --tw-translate-y: -275px;
}

.hover\:-translate-y-px:hover {
  --tw-translate-y: 1px !important;
}

.hover\:-translate-y-full:hover {
  --tw-translate-y: -100%;
}

.hover\:-translate-y-1\/2:hover {
  --tw-translate-y: -50%;
}

.hover\:translate-y-1\/2:hover {
  --tw-translate-y: 50%;
}

.hover\:translate-y-full:hover {
  --tw-translate-y: 100%;
}

.focus\:translate-x-0:focus {
  --tw-translate-x: 0;
}

.focus\:translate-x-1:focus {
  --tw-translate-x: 0.25rem;
}

.focus\:translate-x-2:focus {
  --tw-translate-x: 0.5rem;
}

.focus\:translate-x-3:focus {
  --tw-translate-x: 0.75rem;
}

.focus\:translate-x-4:focus {
  --tw-translate-x: 1rem;
}

.focus\:translate-x-5:focus {
  --tw-translate-x: 1.25rem;
}

.focus\:translate-x-6:focus {
  --tw-translate-x: 1.5rem;
}

.focus\:translate-x-8:focus {
  --tw-translate-x: 2rem;
}

.focus\:translate-x-10:focus {
  --tw-translate-x: 2.5rem;
}

.focus\:translate-x-12:focus {
  --tw-translate-x: 3rem;
}

.focus\:translate-x-16:focus {
  --tw-translate-x: 4rem;
}

.focus\:translate-x-20:focus {
  --tw-translate-x: 5rem;
}

.focus\:translate-x-24:focus {
  --tw-translate-x: 6rem;
}

.focus\:translate-x-32:focus {
  --tw-translate-x: 8rem;
}

.focus\:translate-x-40:focus {
  --tw-translate-x: 10rem;
}

.focus\:translate-x-48:focus {
  --tw-translate-x: 12rem;
}

.focus\:translate-x-56:focus {
  --tw-translate-x: 14rem;
}

.focus\:translate-x-64:focus {
  --tw-translate-x: 16rem;
}

.focus\:translate-x-300px:focus {
  --tw-translate-x: 300px;
}

.focus\:translate-x-230px:focus {
  --tw-translate-x: 230px;
}

.focus\:translate-x-180px:focus {
  --tw-translate-x: 180px;
}

.focus\:translate-x-147px:focus {
  --tw-translate-x: 147px;
}

.focus\:translate-x-123px:focus {
  --tw-translate-x: 123px;
}

.focus\:translate-x-100px:focus {
  --tw-translate-x: 100px;
}

.focus\:translate-x-80px:focus {
  --tw-translate-x: 80px;
}

.focus\:translate-x-60px:focus {
  --tw-translate-x: 60px;
}

.focus\:translate-x-50px:focus {
  --tw-translate-x: 50px;
}

.focus\:translate-x-40px:focus {
  --tw-translate-x: 40px;
}

.focus\:translate-x-35px:focus {
  --tw-translate-x: 35px;
}

.focus\:translate-x-34px-i:focus {
  --tw-translate-x: 34px !important;
}

.focus\:translate-x-30px-i:focus {
  --tw-translate-x: 30px !important;
}

.focus\:translate-x-24px-i:focus {
  --tw-translate-x: 24px !important;
}

.focus\:translate-x-20px-i:focus {
  --tw-translate-x: 20px !important;
}

.focus\:translate-x-15px-i:focus {
  --tw-translate-x: 15px !important;
}

.focus\:translate-x-13px-i:focus {
  --tw-translate-x: 13px !important;
}

.focus\:translate-x-12px-i:focus {
  --tw-translate-x: 12px !important;
}

.focus\:translate-x-10px-i:focus {
  --tw-translate-x: 10px !important;
}

.focus\:translate-x-8px-i:focus {
  --tw-translate-x: 8px !important;
}

.focus\:translate-x-6px-i:focus {
  --tw-translate-x: 6px !important;
}

.focus\:translate-x-5px-i:focus {
  --tw-translate-x: 5px !important;
}

.focus\:translate-x-3px-i:focus {
  --tw-translate-x: 3px !important;
}

.focus\:translate-x-0px-i:focus {
  --tw-translate-x: 0px !important;
}

.focus\:translate-x-34px:focus {
  --tw-translate-x: 34px;
}

.focus\:translate-x-30px:focus {
  --tw-translate-x: 30px;
}

.focus\:translate-x-24px:focus {
  --tw-translate-x: 24px;
}

.focus\:translate-x-20px:focus {
  --tw-translate-x: 20px;
}

.focus\:translate-x-15px:focus {
  --tw-translate-x: 15px;
}

.focus\:translate-x-13px:focus {
  --tw-translate-x: 13px;
}

.focus\:translate-x-12px:focus {
  --tw-translate-x: 12px;
}

.focus\:translate-x-10px:focus {
  --tw-translate-x: 10px;
}

.focus\:translate-x-8px:focus {
  --tw-translate-x: 8px;
}

.focus\:translate-x-6px:focus {
  --tw-translate-x: 6px;
}

.focus\:translate-x-5px:focus {
  --tw-translate-x: 5px;
}

.focus\:translate-x-3px:focus {
  --tw-translate-x: 3px;
}

.focus\:translate-x-menu:focus {
  --tw-translate-x: 275px;
}

.focus\:translate-x-px:focus {
  --tw-translate-x: 1px !important;
}

.focus\:-translate-x-1:focus {
  --tw-translate-x: -0.25rem;
}

.focus\:-translate-x-2:focus {
  --tw-translate-x: -0.5rem;
}

.focus\:-translate-x-3:focus {
  --tw-translate-x: -0.75rem;
}

.focus\:-translate-x-4:focus {
  --tw-translate-x: -1rem;
}

.focus\:-translate-x-5:focus {
  --tw-translate-x: -1.25rem;
}

.focus\:-translate-x-6:focus {
  --tw-translate-x: -1.5rem;
}

.focus\:-translate-x-8:focus {
  --tw-translate-x: -2rem;
}

.focus\:-translate-x-10:focus {
  --tw-translate-x: -2.5rem;
}

.focus\:-translate-x-12:focus {
  --tw-translate-x: -3rem;
}

.focus\:-translate-x-16:focus {
  --tw-translate-x: -4rem;
}

.focus\:-translate-x-20:focus {
  --tw-translate-x: -5rem;
}

.focus\:-translate-x-24:focus {
  --tw-translate-x: -6rem;
}

.focus\:-translate-x-32:focus {
  --tw-translate-x: -8rem;
}

.focus\:-translate-x-40:focus {
  --tw-translate-x: -10rem;
}

.focus\:-translate-x-48:focus {
  --tw-translate-x: -12rem;
}

.focus\:-translate-x-56:focus {
  --tw-translate-x: -14rem;
}

.focus\:-translate-x-64:focus {
  --tw-translate-x: -16rem;
}

.focus\:-translate-x-300px:focus {
  --tw-translate-x: -300px;
}

.focus\:-translate-x-230px:focus {
  --tw-translate-x: -230px;
}

.focus\:-translate-x-180px:focus {
  --tw-translate-x: -180px;
}

.focus\:-translate-x-147px:focus {
  --tw-translate-x: -147px;
}

.focus\:-translate-x-123px:focus {
  --tw-translate-x: -123px;
}

.focus\:-translate-x-100px:focus {
  --tw-translate-x: -100px;
}

.focus\:-translate-x-80px:focus {
  --tw-translate-x: -80px;
}

.focus\:-translate-x-60px:focus {
  --tw-translate-x: -60px;
}

.focus\:-translate-x-50px:focus {
  --tw-translate-x: -50px;
}

.focus\:-translate-x-40px:focus {
  --tw-translate-x: -40px;
}

.focus\:-translate-x-35px:focus {
  --tw-translate-x: -35px;
}

.focus\:-translate-x-34px-i:focus {
  --tw-translate-x: 34px !important;
}

.focus\:-translate-x-30px-i:focus {
  --tw-translate-x: 30px !important;
}

.focus\:-translate-x-24px-i:focus {
  --tw-translate-x: 24px !important;
}

.focus\:-translate-x-20px-i:focus {
  --tw-translate-x: 20px !important;
}

.focus\:-translate-x-15px-i:focus {
  --tw-translate-x: 15px !important;
}

.focus\:-translate-x-13px-i:focus {
  --tw-translate-x: 13px !important;
}

.focus\:-translate-x-12px-i:focus {
  --tw-translate-x: 12px !important;
}

.focus\:-translate-x-10px-i:focus {
  --tw-translate-x: 10px !important;
}

.focus\:-translate-x-8px-i:focus {
  --tw-translate-x: 8px !important;
}

.focus\:-translate-x-6px-i:focus {
  --tw-translate-x: 6px !important;
}

.focus\:-translate-x-5px-i:focus {
  --tw-translate-x: 5px !important;
}

.focus\:-translate-x-3px-i:focus {
  --tw-translate-x: 3px !important;
}

.focus\:-translate-x-0px-i:focus {
  --tw-translate-x: 0px !important;
}

.focus\:-translate-x-34px:focus {
  --tw-translate-x: -34px;
}

.focus\:-translate-x-30px:focus {
  --tw-translate-x: -30px;
}

.focus\:-translate-x-24px:focus {
  --tw-translate-x: -24px;
}

.focus\:-translate-x-20px:focus {
  --tw-translate-x: -20px;
}

.focus\:-translate-x-15px:focus {
  --tw-translate-x: -15px;
}

.focus\:-translate-x-13px:focus {
  --tw-translate-x: -13px;
}

.focus\:-translate-x-12px:focus {
  --tw-translate-x: -12px;
}

.focus\:-translate-x-10px:focus {
  --tw-translate-x: -10px;
}

.focus\:-translate-x-8px:focus {
  --tw-translate-x: -8px;
}

.focus\:-translate-x-6px:focus {
  --tw-translate-x: -6px;
}

.focus\:-translate-x-5px:focus {
  --tw-translate-x: -5px;
}

.focus\:-translate-x-3px:focus {
  --tw-translate-x: -3px;
}

.focus\:-translate-x-menu:focus {
  --tw-translate-x: -275px;
}

.focus\:-translate-x-px:focus {
  --tw-translate-x: 1px !important;
}

.focus\:-translate-x-full:focus {
  --tw-translate-x: -100%;
}

.focus\:-translate-x-1\/2:focus {
  --tw-translate-x: -50%;
}

.focus\:translate-x-1\/2:focus {
  --tw-translate-x: 50%;
}

.focus\:translate-x-full:focus {
  --tw-translate-x: 100%;
}

.focus\:translate-y-0:focus {
  --tw-translate-y: 0;
}

.focus\:translate-y-1:focus {
  --tw-translate-y: 0.25rem;
}

.focus\:translate-y-2:focus {
  --tw-translate-y: 0.5rem;
}

.focus\:translate-y-3:focus {
  --tw-translate-y: 0.75rem;
}

.focus\:translate-y-4:focus {
  --tw-translate-y: 1rem;
}

.focus\:translate-y-5:focus {
  --tw-translate-y: 1.25rem;
}

.focus\:translate-y-6:focus {
  --tw-translate-y: 1.5rem;
}

.focus\:translate-y-8:focus {
  --tw-translate-y: 2rem;
}

.focus\:translate-y-10:focus {
  --tw-translate-y: 2.5rem;
}

.focus\:translate-y-12:focus {
  --tw-translate-y: 3rem;
}

.focus\:translate-y-16:focus {
  --tw-translate-y: 4rem;
}

.focus\:translate-y-20:focus {
  --tw-translate-y: 5rem;
}

.focus\:translate-y-24:focus {
  --tw-translate-y: 6rem;
}

.focus\:translate-y-32:focus {
  --tw-translate-y: 8rem;
}

.focus\:translate-y-40:focus {
  --tw-translate-y: 10rem;
}

.focus\:translate-y-48:focus {
  --tw-translate-y: 12rem;
}

.focus\:translate-y-56:focus {
  --tw-translate-y: 14rem;
}

.focus\:translate-y-64:focus {
  --tw-translate-y: 16rem;
}

.focus\:translate-y-300px:focus {
  --tw-translate-y: 300px;
}

.focus\:translate-y-230px:focus {
  --tw-translate-y: 230px;
}

.focus\:translate-y-180px:focus {
  --tw-translate-y: 180px;
}

.focus\:translate-y-147px:focus {
  --tw-translate-y: 147px;
}

.focus\:translate-y-123px:focus {
  --tw-translate-y: 123px;
}

.focus\:translate-y-100px:focus {
  --tw-translate-y: 100px;
}

.focus\:translate-y-80px:focus {
  --tw-translate-y: 80px;
}

.focus\:translate-y-60px:focus {
  --tw-translate-y: 60px;
}

.focus\:translate-y-50px:focus {
  --tw-translate-y: 50px;
}

.focus\:translate-y-40px:focus {
  --tw-translate-y: 40px;
}

.focus\:translate-y-35px:focus {
  --tw-translate-y: 35px;
}

.focus\:translate-y-34px-i:focus {
  --tw-translate-y: 34px !important;
}

.focus\:translate-y-30px-i:focus {
  --tw-translate-y: 30px !important;
}

.focus\:translate-y-24px-i:focus {
  --tw-translate-y: 24px !important;
}

.focus\:translate-y-20px-i:focus {
  --tw-translate-y: 20px !important;
}

.focus\:translate-y-15px-i:focus {
  --tw-translate-y: 15px !important;
}

.focus\:translate-y-13px-i:focus {
  --tw-translate-y: 13px !important;
}

.focus\:translate-y-12px-i:focus {
  --tw-translate-y: 12px !important;
}

.focus\:translate-y-10px-i:focus {
  --tw-translate-y: 10px !important;
}

.focus\:translate-y-8px-i:focus {
  --tw-translate-y: 8px !important;
}

.focus\:translate-y-6px-i:focus {
  --tw-translate-y: 6px !important;
}

.focus\:translate-y-5px-i:focus {
  --tw-translate-y: 5px !important;
}

.focus\:translate-y-3px-i:focus {
  --tw-translate-y: 3px !important;
}

.focus\:translate-y-0px-i:focus {
  --tw-translate-y: 0px !important;
}

.focus\:translate-y-34px:focus {
  --tw-translate-y: 34px;
}

.focus\:translate-y-30px:focus {
  --tw-translate-y: 30px;
}

.focus\:translate-y-24px:focus {
  --tw-translate-y: 24px;
}

.focus\:translate-y-20px:focus {
  --tw-translate-y: 20px;
}

.focus\:translate-y-15px:focus {
  --tw-translate-y: 15px;
}

.focus\:translate-y-13px:focus {
  --tw-translate-y: 13px;
}

.focus\:translate-y-12px:focus {
  --tw-translate-y: 12px;
}

.focus\:translate-y-10px:focus {
  --tw-translate-y: 10px;
}

.focus\:translate-y-8px:focus {
  --tw-translate-y: 8px;
}

.focus\:translate-y-6px:focus {
  --tw-translate-y: 6px;
}

.focus\:translate-y-5px:focus {
  --tw-translate-y: 5px;
}

.focus\:translate-y-3px:focus {
  --tw-translate-y: 3px;
}

.focus\:translate-y-menu:focus {
  --tw-translate-y: 275px;
}

.focus\:translate-y-px:focus {
  --tw-translate-y: 1px !important;
}

.focus\:-translate-y-1:focus {
  --tw-translate-y: -0.25rem;
}

.focus\:-translate-y-2:focus {
  --tw-translate-y: -0.5rem;
}

.focus\:-translate-y-3:focus {
  --tw-translate-y: -0.75rem;
}

.focus\:-translate-y-4:focus {
  --tw-translate-y: -1rem;
}

.focus\:-translate-y-5:focus {
  --tw-translate-y: -1.25rem;
}

.focus\:-translate-y-6:focus {
  --tw-translate-y: -1.5rem;
}

.focus\:-translate-y-8:focus {
  --tw-translate-y: -2rem;
}

.focus\:-translate-y-10:focus {
  --tw-translate-y: -2.5rem;
}

.focus\:-translate-y-12:focus {
  --tw-translate-y: -3rem;
}

.focus\:-translate-y-16:focus {
  --tw-translate-y: -4rem;
}

.focus\:-translate-y-20:focus {
  --tw-translate-y: -5rem;
}

.focus\:-translate-y-24:focus {
  --tw-translate-y: -6rem;
}

.focus\:-translate-y-32:focus {
  --tw-translate-y: -8rem;
}

.focus\:-translate-y-40:focus {
  --tw-translate-y: -10rem;
}

.focus\:-translate-y-48:focus {
  --tw-translate-y: -12rem;
}

.focus\:-translate-y-56:focus {
  --tw-translate-y: -14rem;
}

.focus\:-translate-y-64:focus {
  --tw-translate-y: -16rem;
}

.focus\:-translate-y-300px:focus {
  --tw-translate-y: -300px;
}

.focus\:-translate-y-230px:focus {
  --tw-translate-y: -230px;
}

.focus\:-translate-y-180px:focus {
  --tw-translate-y: -180px;
}

.focus\:-translate-y-147px:focus {
  --tw-translate-y: -147px;
}

.focus\:-translate-y-123px:focus {
  --tw-translate-y: -123px;
}

.focus\:-translate-y-100px:focus {
  --tw-translate-y: -100px;
}

.focus\:-translate-y-80px:focus {
  --tw-translate-y: -80px;
}

.focus\:-translate-y-60px:focus {
  --tw-translate-y: -60px;
}

.focus\:-translate-y-50px:focus {
  --tw-translate-y: -50px;
}

.focus\:-translate-y-40px:focus {
  --tw-translate-y: -40px;
}

.focus\:-translate-y-35px:focus {
  --tw-translate-y: -35px;
}

.focus\:-translate-y-34px-i:focus {
  --tw-translate-y: 34px !important;
}

.focus\:-translate-y-30px-i:focus {
  --tw-translate-y: 30px !important;
}

.focus\:-translate-y-24px-i:focus {
  --tw-translate-y: 24px !important;
}

.focus\:-translate-y-20px-i:focus {
  --tw-translate-y: 20px !important;
}

.focus\:-translate-y-15px-i:focus {
  --tw-translate-y: 15px !important;
}

.focus\:-translate-y-13px-i:focus {
  --tw-translate-y: 13px !important;
}

.focus\:-translate-y-12px-i:focus {
  --tw-translate-y: 12px !important;
}

.focus\:-translate-y-10px-i:focus {
  --tw-translate-y: 10px !important;
}

.focus\:-translate-y-8px-i:focus {
  --tw-translate-y: 8px !important;
}

.focus\:-translate-y-6px-i:focus {
  --tw-translate-y: 6px !important;
}

.focus\:-translate-y-5px-i:focus {
  --tw-translate-y: 5px !important;
}

.focus\:-translate-y-3px-i:focus {
  --tw-translate-y: 3px !important;
}

.focus\:-translate-y-0px-i:focus {
  --tw-translate-y: 0px !important;
}

.focus\:-translate-y-34px:focus {
  --tw-translate-y: -34px;
}

.focus\:-translate-y-30px:focus {
  --tw-translate-y: -30px;
}

.focus\:-translate-y-24px:focus {
  --tw-translate-y: -24px;
}

.focus\:-translate-y-20px:focus {
  --tw-translate-y: -20px;
}

.focus\:-translate-y-15px:focus {
  --tw-translate-y: -15px;
}

.focus\:-translate-y-13px:focus {
  --tw-translate-y: -13px;
}

.focus\:-translate-y-12px:focus {
  --tw-translate-y: -12px;
}

.focus\:-translate-y-10px:focus {
  --tw-translate-y: -10px;
}

.focus\:-translate-y-8px:focus {
  --tw-translate-y: -8px;
}

.focus\:-translate-y-6px:focus {
  --tw-translate-y: -6px;
}

.focus\:-translate-y-5px:focus {
  --tw-translate-y: -5px;
}

.focus\:-translate-y-3px:focus {
  --tw-translate-y: -3px;
}

.focus\:-translate-y-menu:focus {
  --tw-translate-y: -275px;
}

.focus\:-translate-y-px:focus {
  --tw-translate-y: 1px !important;
}

.focus\:-translate-y-full:focus {
  --tw-translate-y: -100%;
}

.focus\:-translate-y-1\/2:focus {
  --tw-translate-y: -50%;
}

.focus\:translate-y-1\/2:focus {
  --tw-translate-y: 50%;
}

.focus\:translate-y-full:focus {
  --tw-translate-y: 100%;
}

.rotate-0 {
  --tw-rotate: 0;
}

.rotate-1 {
  --tw-rotate: 1deg;
}

.rotate-2 {
  --tw-rotate: 2deg;
}

.rotate-3 {
  --tw-rotate: 3deg;
}

.rotate-6 {
  --tw-rotate: 6deg;
}

.rotate-12 {
  --tw-rotate: 12deg;
}

.rotate-45 {
  --tw-rotate: 45deg;
}

.rotate-90 {
  --tw-rotate: 90deg;
}

.rotate-180 {
  --tw-rotate: 180deg;
}

.-rotate-180 {
  --tw-rotate: -180deg;
}

.-rotate-90 {
  --tw-rotate: -90deg;
}

.-rotate-45 {
  --tw-rotate: -45deg;
}

.-rotate-12 {
  --tw-rotate: -12deg;
}

.-rotate-6 {
  --tw-rotate: -6deg;
}

.-rotate-3 {
  --tw-rotate: -3deg;
}

.-rotate-2 {
  --tw-rotate: -2deg;
}

.-rotate-1 {
  --tw-rotate: -1deg;
}

.hover\:rotate-0:hover {
  --tw-rotate: 0;
}

.hover\:rotate-1:hover {
  --tw-rotate: 1deg;
}

.hover\:rotate-2:hover {
  --tw-rotate: 2deg;
}

.hover\:rotate-3:hover {
  --tw-rotate: 3deg;
}

.hover\:rotate-6:hover {
  --tw-rotate: 6deg;
}

.hover\:rotate-12:hover {
  --tw-rotate: 12deg;
}

.hover\:rotate-45:hover {
  --tw-rotate: 45deg;
}

.hover\:rotate-90:hover {
  --tw-rotate: 90deg;
}

.hover\:rotate-180:hover {
  --tw-rotate: 180deg;
}

.hover\:-rotate-180:hover {
  --tw-rotate: -180deg;
}

.hover\:-rotate-90:hover {
  --tw-rotate: -90deg;
}

.hover\:-rotate-45:hover {
  --tw-rotate: -45deg;
}

.hover\:-rotate-12:hover {
  --tw-rotate: -12deg;
}

.hover\:-rotate-6:hover {
  --tw-rotate: -6deg;
}

.hover\:-rotate-3:hover {
  --tw-rotate: -3deg;
}

.hover\:-rotate-2:hover {
  --tw-rotate: -2deg;
}

.hover\:-rotate-1:hover {
  --tw-rotate: -1deg;
}

.focus\:rotate-0:focus {
  --tw-rotate: 0;
}

.focus\:rotate-1:focus {
  --tw-rotate: 1deg;
}

.focus\:rotate-2:focus {
  --tw-rotate: 2deg;
}

.focus\:rotate-3:focus {
  --tw-rotate: 3deg;
}

.focus\:rotate-6:focus {
  --tw-rotate: 6deg;
}

.focus\:rotate-12:focus {
  --tw-rotate: 12deg;
}

.focus\:rotate-45:focus {
  --tw-rotate: 45deg;
}

.focus\:rotate-90:focus {
  --tw-rotate: 90deg;
}

.focus\:rotate-180:focus {
  --tw-rotate: 180deg;
}

.focus\:-rotate-180:focus {
  --tw-rotate: -180deg;
}

.focus\:-rotate-90:focus {
  --tw-rotate: -90deg;
}

.focus\:-rotate-45:focus {
  --tw-rotate: -45deg;
}

.focus\:-rotate-12:focus {
  --tw-rotate: -12deg;
}

.focus\:-rotate-6:focus {
  --tw-rotate: -6deg;
}

.focus\:-rotate-3:focus {
  --tw-rotate: -3deg;
}

.focus\:-rotate-2:focus {
  --tw-rotate: -2deg;
}

.focus\:-rotate-1:focus {
  --tw-rotate: -1deg;
}

.skew-x-0 {
  --tw-skew-x: 0;
}

.skew-x-1 {
  --tw-skew-x: 1deg;
}

.skew-x-2 {
  --tw-skew-x: 2deg;
}

.skew-x-3 {
  --tw-skew-x: 3deg;
}

.skew-x-6 {
  --tw-skew-x: 6deg;
}

.skew-x-12 {
  --tw-skew-x: 12deg;
}

.-skew-x-12 {
  --tw-skew-x: -12deg;
}

.-skew-x-6 {
  --tw-skew-x: -6deg;
}

.-skew-x-3 {
  --tw-skew-x: -3deg;
}

.-skew-x-2 {
  --tw-skew-x: -2deg;
}

.-skew-x-1 {
  --tw-skew-x: -1deg;
}

.skew-y-0 {
  --tw-skew-y: 0;
}

.skew-y-1 {
  --tw-skew-y: 1deg;
}

.skew-y-2 {
  --tw-skew-y: 2deg;
}

.skew-y-3 {
  --tw-skew-y: 3deg;
}

.skew-y-6 {
  --tw-skew-y: 6deg;
}

.skew-y-12 {
  --tw-skew-y: 12deg;
}

.-skew-y-12 {
  --tw-skew-y: -12deg;
}

.-skew-y-6 {
  --tw-skew-y: -6deg;
}

.-skew-y-3 {
  --tw-skew-y: -3deg;
}

.-skew-y-2 {
  --tw-skew-y: -2deg;
}

.-skew-y-1 {
  --tw-skew-y: -1deg;
}

.hover\:skew-x-0:hover {
  --tw-skew-x: 0;
}

.hover\:skew-x-1:hover {
  --tw-skew-x: 1deg;
}

.hover\:skew-x-2:hover {
  --tw-skew-x: 2deg;
}

.hover\:skew-x-3:hover {
  --tw-skew-x: 3deg;
}

.hover\:skew-x-6:hover {
  --tw-skew-x: 6deg;
}

.hover\:skew-x-12:hover {
  --tw-skew-x: 12deg;
}

.hover\:-skew-x-12:hover {
  --tw-skew-x: -12deg;
}

.hover\:-skew-x-6:hover {
  --tw-skew-x: -6deg;
}

.hover\:-skew-x-3:hover {
  --tw-skew-x: -3deg;
}

.hover\:-skew-x-2:hover {
  --tw-skew-x: -2deg;
}

.hover\:-skew-x-1:hover {
  --tw-skew-x: -1deg;
}

.hover\:skew-y-0:hover {
  --tw-skew-y: 0;
}

.hover\:skew-y-1:hover {
  --tw-skew-y: 1deg;
}

.hover\:skew-y-2:hover {
  --tw-skew-y: 2deg;
}

.hover\:skew-y-3:hover {
  --tw-skew-y: 3deg;
}

.hover\:skew-y-6:hover {
  --tw-skew-y: 6deg;
}

.hover\:skew-y-12:hover {
  --tw-skew-y: 12deg;
}

.hover\:-skew-y-12:hover {
  --tw-skew-y: -12deg;
}

.hover\:-skew-y-6:hover {
  --tw-skew-y: -6deg;
}

.hover\:-skew-y-3:hover {
  --tw-skew-y: -3deg;
}

.hover\:-skew-y-2:hover {
  --tw-skew-y: -2deg;
}

.hover\:-skew-y-1:hover {
  --tw-skew-y: -1deg;
}

.focus\:skew-x-0:focus {
  --tw-skew-x: 0;
}

.focus\:skew-x-1:focus {
  --tw-skew-x: 1deg;
}

.focus\:skew-x-2:focus {
  --tw-skew-x: 2deg;
}

.focus\:skew-x-3:focus {
  --tw-skew-x: 3deg;
}

.focus\:skew-x-6:focus {
  --tw-skew-x: 6deg;
}

.focus\:skew-x-12:focus {
  --tw-skew-x: 12deg;
}

.focus\:-skew-x-12:focus {
  --tw-skew-x: -12deg;
}

.focus\:-skew-x-6:focus {
  --tw-skew-x: -6deg;
}

.focus\:-skew-x-3:focus {
  --tw-skew-x: -3deg;
}

.focus\:-skew-x-2:focus {
  --tw-skew-x: -2deg;
}

.focus\:-skew-x-1:focus {
  --tw-skew-x: -1deg;
}

.focus\:skew-y-0:focus {
  --tw-skew-y: 0;
}

.focus\:skew-y-1:focus {
  --tw-skew-y: 1deg;
}

.focus\:skew-y-2:focus {
  --tw-skew-y: 2deg;
}

.focus\:skew-y-3:focus {
  --tw-skew-y: 3deg;
}

.focus\:skew-y-6:focus {
  --tw-skew-y: 6deg;
}

.focus\:skew-y-12:focus {
  --tw-skew-y: 12deg;
}

.focus\:-skew-y-12:focus {
  --tw-skew-y: -12deg;
}

.focus\:-skew-y-6:focus {
  --tw-skew-y: -6deg;
}

.focus\:-skew-y-3:focus {
  --tw-skew-y: -3deg;
}

.focus\:-skew-y-2:focus {
  --tw-skew-y: -2deg;
}

.focus\:-skew-y-1:focus {
  --tw-skew-y: -1deg;
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

.hover\:scale-0:hover {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.hover\:scale-50:hover {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
}

.hover\:scale-75:hover {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
}

.hover\:scale-90:hover {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
}

.hover\:scale-95:hover {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
}

.hover\:scale-100:hover {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}

.hover\:scale-150:hover {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

.focus\:scale-0:focus {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.focus\:scale-50:focus {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
}

.focus\:scale-75:focus {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
}

.focus\:scale-90:focus {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
}

.focus\:scale-95:focus {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
}

.focus\:scale-100:focus {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.focus\:scale-105:focus {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.focus\:scale-110:focus {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

.focus\:scale-125:focus {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}

.focus\:scale-150:focus {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

.scale-x-0 {
  --tw-scale-x: 0;
}

.scale-x-50 {
  --tw-scale-x: .5;
}

.scale-x-75 {
  --tw-scale-x: .75;
}

.scale-x-90 {
  --tw-scale-x: .9;
}

.scale-x-95 {
  --tw-scale-x: .95;
}

.scale-x-100 {
  --tw-scale-x: 1;
}

.scale-x-105 {
  --tw-scale-x: 1.05;
}

.scale-x-110 {
  --tw-scale-x: 1.1;
}

.scale-x-125 {
  --tw-scale-x: 1.25;
}

.scale-x-150 {
  --tw-scale-x: 1.5;
}

.scale-y-0 {
  --tw-scale-y: 0;
}

.scale-y-50 {
  --tw-scale-y: .5;
}

.scale-y-75 {
  --tw-scale-y: .75;
}

.scale-y-90 {
  --tw-scale-y: .9;
}

.scale-y-95 {
  --tw-scale-y: .95;
}

.scale-y-100 {
  --tw-scale-y: 1;
}

.scale-y-105 {
  --tw-scale-y: 1.05;
}

.scale-y-110 {
  --tw-scale-y: 1.1;
}

.scale-y-125 {
  --tw-scale-y: 1.25;
}

.scale-y-150 {
  --tw-scale-y: 1.5;
}

.hover\:scale-x-0:hover {
  --tw-scale-x: 0;
}

.hover\:scale-x-50:hover {
  --tw-scale-x: .5;
}

.hover\:scale-x-75:hover {
  --tw-scale-x: .75;
}

.hover\:scale-x-90:hover {
  --tw-scale-x: .9;
}

.hover\:scale-x-95:hover {
  --tw-scale-x: .95;
}

.hover\:scale-x-100:hover {
  --tw-scale-x: 1;
}

.hover\:scale-x-105:hover {
  --tw-scale-x: 1.05;
}

.hover\:scale-x-110:hover {
  --tw-scale-x: 1.1;
}

.hover\:scale-x-125:hover {
  --tw-scale-x: 1.25;
}

.hover\:scale-x-150:hover {
  --tw-scale-x: 1.5;
}

.hover\:scale-y-0:hover {
  --tw-scale-y: 0;
}

.hover\:scale-y-50:hover {
  --tw-scale-y: .5;
}

.hover\:scale-y-75:hover {
  --tw-scale-y: .75;
}

.hover\:scale-y-90:hover {
  --tw-scale-y: .9;
}

.hover\:scale-y-95:hover {
  --tw-scale-y: .95;
}

.hover\:scale-y-100:hover {
  --tw-scale-y: 1;
}

.hover\:scale-y-105:hover {
  --tw-scale-y: 1.05;
}

.hover\:scale-y-110:hover {
  --tw-scale-y: 1.1;
}

.hover\:scale-y-125:hover {
  --tw-scale-y: 1.25;
}

.hover\:scale-y-150:hover {
  --tw-scale-y: 1.5;
}

.focus\:scale-x-0:focus {
  --tw-scale-x: 0;
}

.focus\:scale-x-50:focus {
  --tw-scale-x: .5;
}

.focus\:scale-x-75:focus {
  --tw-scale-x: .75;
}

.focus\:scale-x-90:focus {
  --tw-scale-x: .9;
}

.focus\:scale-x-95:focus {
  --tw-scale-x: .95;
}

.focus\:scale-x-100:focus {
  --tw-scale-x: 1;
}

.focus\:scale-x-105:focus {
  --tw-scale-x: 1.05;
}

.focus\:scale-x-110:focus {
  --tw-scale-x: 1.1;
}

.focus\:scale-x-125:focus {
  --tw-scale-x: 1.25;
}

.focus\:scale-x-150:focus {
  --tw-scale-x: 1.5;
}

.focus\:scale-y-0:focus {
  --tw-scale-y: 0;
}

.focus\:scale-y-50:focus {
  --tw-scale-y: .5;
}

.focus\:scale-y-75:focus {
  --tw-scale-y: .75;
}

.focus\:scale-y-90:focus {
  --tw-scale-y: .9;
}

.focus\:scale-y-95:focus {
  --tw-scale-y: .95;
}

.focus\:scale-y-100:focus {
  --tw-scale-y: 1;
}

.focus\:scale-y-105:focus {
  --tw-scale-y: 1.05;
}

.focus\:scale-y-110:focus {
  --tw-scale-y: 1.1;
}

.focus\:scale-y-125:focus {
  --tw-scale-y: 1.25;
}

.focus\:scale-y-150:focus {
  --tw-scale-y: 1.5;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.animate-none {
  animation: none;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-bounce {
  animation: bounce 1s infinite;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.select-none {
  -webkit-user-select: none;
          user-select: none;
}

.select-text {
  -webkit-user-select: text;
          user-select: text;
}

.select-all {
  -webkit-user-select: all;
          user-select: all;
}

.select-auto {
  -webkit-user-select: auto;
          user-select: auto;
}

.resize-none {
  resize: none;
}

.resize-y {
  resize: vertical;
}

.resize-x {
  resize: horizontal;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-none {
  list-style-type: none;
}

.list-disc {
  list-style-type: disc;
}

.list-decimal {
  list-style-type: decimal;
}

.appearance-none {
  appearance: none;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.auto-cols-min {
  grid-auto-columns: min-content;
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.auto-rows-auto {
  grid-auto-rows: auto;
}

.auto-rows-min {
  grid-auto-rows: min-content;
}

.auto-rows-max {
  grid-auto-rows: max-content;
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-none {
  grid-template-columns: none;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.grid-rows-none {
  grid-template-rows: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.place-content-start {
  place-content: start;
}

.place-content-end {
  place-content: end;
}

.place-content-between {
  place-content: space-between;
}

.place-content-around {
  place-content: space-around;
}

.place-content-evenly {
  place-content: space-evenly;
}

.place-content-stretch {
  place-content: stretch;
}

.place-items-start {
  place-items: start;
}

.place-items-end {
  place-items: end;
}

.place-items-center {
  place-items: center;
}

.place-items-stretch {
  place-items: stretch;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-32 {
  gap: 8rem;
}

.gap-40 {
  gap: 10rem;
}

.gap-48 {
  gap: 12rem;
}

.gap-56 {
  gap: 14rem;
}

.gap-64 {
  gap: 16rem;
}

.gap-300px {
  gap: 300px;
}

.gap-230px {
  gap: 230px;
}

.gap-180px {
  gap: 180px;
}

.gap-147px {
  gap: 147px;
}

.gap-123px {
  gap: 123px;
}

.gap-100px {
  gap: 100px;
}

.gap-80px {
  gap: 80px;
}

.gap-60px {
  gap: 60px;
}

.gap-50px {
  gap: 50px;
}

.gap-40px {
  gap: 40px;
}

.gap-35px {
  gap: 35px;
}

.gap-34px-i {
  gap: 34px !important;
}

.gap-30px-i {
  gap: 30px !important;
}

.gap-24px-i {
  gap: 24px !important;
}

.gap-20px-i {
  gap: 20px !important;
}

.gap-15px-i {
  gap: 15px !important;
}

.gap-13px-i {
  gap: 13px !important;
}

.gap-12px-i {
  gap: 12px !important;
}

.gap-10px-i {
  gap: 10px !important;
}

.gap-8px-i {
  gap: 8px !important;
}

.gap-6px-i {
  gap: 6px !important;
}

.gap-5px-i {
  gap: 5px !important;
}

.gap-3px-i {
  gap: 3px !important;
}

.gap-0px-i {
  gap: 0px !important;
}

.gap-34px {
  gap: 34px;
}

.gap-30px {
  gap: 30px;
}

.gap-24px {
  gap: 24px;
}

.gap-20px {
  gap: 20px;
}

.gap-15px {
  gap: 15px;
}

.gap-13px {
  gap: 13px;
}

.gap-12px {
  gap: 12px;
}

.gap-10px {
  gap: 10px;
}

.gap-8px {
  gap: 8px;
}

.gap-6px {
  gap: 6px;
}

.gap-5px {
  gap: 5px;
}

.gap-3px {
  gap: 3px;
}

.gap-menu {
  gap: 275px;
}

.gap-px {
  gap: 1px !important;
}

.gap-x-0 {
  column-gap: 0;
}

.gap-x-1 {
  column-gap: 0.25rem;
}

.gap-x-2 {
  column-gap: 0.5rem;
}

.gap-x-3 {
  column-gap: 0.75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-x-20 {
  column-gap: 5rem;
}

.gap-x-24 {
  column-gap: 6rem;
}

.gap-x-32 {
  column-gap: 8rem;
}

.gap-x-40 {
  column-gap: 10rem;
}

.gap-x-48 {
  column-gap: 12rem;
}

.gap-x-56 {
  column-gap: 14rem;
}

.gap-x-64 {
  column-gap: 16rem;
}

.gap-x-300px {
  column-gap: 300px;
}

.gap-x-230px {
  column-gap: 230px;
}

.gap-x-180px {
  column-gap: 180px;
}

.gap-x-147px {
  column-gap: 147px;
}

.gap-x-123px {
  column-gap: 123px;
}

.gap-x-100px {
  column-gap: 100px;
}

.gap-x-80px {
  column-gap: 80px;
}

.gap-x-60px {
  column-gap: 60px;
}

.gap-x-50px {
  column-gap: 50px;
}

.gap-x-40px {
  column-gap: 40px;
}

.gap-x-35px {
  column-gap: 35px;
}

.gap-x-34px-i {
  column-gap: 34px !important;
}

.gap-x-30px-i {
  column-gap: 30px !important;
}

.gap-x-24px-i {
  column-gap: 24px !important;
}

.gap-x-20px-i {
  column-gap: 20px !important;
}

.gap-x-15px-i {
  column-gap: 15px !important;
}

.gap-x-13px-i {
  column-gap: 13px !important;
}

.gap-x-12px-i {
  column-gap: 12px !important;
}

.gap-x-10px-i {
  column-gap: 10px !important;
}

.gap-x-8px-i {
  column-gap: 8px !important;
}

.gap-x-6px-i {
  column-gap: 6px !important;
}

.gap-x-5px-i {
  column-gap: 5px !important;
}

.gap-x-3px-i {
  column-gap: 3px !important;
}

.gap-x-0px-i {
  column-gap: 0px !important;
}

.gap-x-34px {
  column-gap: 34px;
}

.gap-x-30px {
  column-gap: 30px;
}

.gap-x-24px {
  column-gap: 24px;
}

.gap-x-20px {
  column-gap: 20px;
}

.gap-x-15px {
  column-gap: 15px;
}

.gap-x-13px {
  column-gap: 13px;
}

.gap-x-12px {
  column-gap: 12px;
}

.gap-x-10px {
  column-gap: 10px;
}

.gap-x-8px {
  column-gap: 8px;
}

.gap-x-6px {
  column-gap: 6px;
}

.gap-x-5px {
  column-gap: 5px;
}

.gap-x-3px {
  column-gap: 3px;
}

.gap-x-menu {
  column-gap: 275px;
}

.gap-x-px {
  column-gap: 1px !important;
}

.gap-y-0 {
  row-gap: 0;
}

.gap-y-1 {
  row-gap: 0.25rem;
}

.gap-y-2 {
  row-gap: 0.5rem;
}

.gap-y-3 {
  row-gap: 0.75rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.gap-y-20 {
  row-gap: 5rem;
}

.gap-y-24 {
  row-gap: 6rem;
}

.gap-y-32 {
  row-gap: 8rem;
}

.gap-y-40 {
  row-gap: 10rem;
}

.gap-y-48 {
  row-gap: 12rem;
}

.gap-y-56 {
  row-gap: 14rem;
}

.gap-y-64 {
  row-gap: 16rem;
}

.gap-y-300px {
  row-gap: 300px;
}

.gap-y-230px {
  row-gap: 230px;
}

.gap-y-180px {
  row-gap: 180px;
}

.gap-y-147px {
  row-gap: 147px;
}

.gap-y-123px {
  row-gap: 123px;
}

.gap-y-100px {
  row-gap: 100px;
}

.gap-y-80px {
  row-gap: 80px;
}

.gap-y-60px {
  row-gap: 60px;
}

.gap-y-50px {
  row-gap: 50px;
}

.gap-y-40px {
  row-gap: 40px;
}

.gap-y-35px {
  row-gap: 35px;
}

.gap-y-34px-i {
  row-gap: 34px !important;
}

.gap-y-30px-i {
  row-gap: 30px !important;
}

.gap-y-24px-i {
  row-gap: 24px !important;
}

.gap-y-20px-i {
  row-gap: 20px !important;
}

.gap-y-15px-i {
  row-gap: 15px !important;
}

.gap-y-13px-i {
  row-gap: 13px !important;
}

.gap-y-12px-i {
  row-gap: 12px !important;
}

.gap-y-10px-i {
  row-gap: 10px !important;
}

.gap-y-8px-i {
  row-gap: 8px !important;
}

.gap-y-6px-i {
  row-gap: 6px !important;
}

.gap-y-5px-i {
  row-gap: 5px !important;
}

.gap-y-3px-i {
  row-gap: 3px !important;
}

.gap-y-0px-i {
  row-gap: 0px !important;
}

.gap-y-34px {
  row-gap: 34px;
}

.gap-y-30px {
  row-gap: 30px;
}

.gap-y-24px {
  row-gap: 24px;
}

.gap-y-20px {
  row-gap: 20px;
}

.gap-y-15px {
  row-gap: 15px;
}

.gap-y-13px {
  row-gap: 13px;
}

.gap-y-12px {
  row-gap: 12px;
}

.gap-y-10px {
  row-gap: 10px;
}

.gap-y-8px {
  row-gap: 8px;
}

.gap-y-6px {
  row-gap: 6px;
}

.gap-y-5px {
  row-gap: 5px;
}

.gap-y-3px {
  row-gap: 3px;
}

.gap-y-menu {
  row-gap: 275px;
}

.gap-y-px {
  row-gap: 1px !important;
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5rem * var(--tw-space-x-reverse));
  margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6rem * var(--tw-space-x-reverse));
  margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8rem * var(--tw-space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(10rem * var(--tw-space-x-reverse));
  margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12rem * var(--tw-space-x-reverse));
  margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(14rem * var(--tw-space-x-reverse));
  margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(16rem * var(--tw-space-x-reverse));
  margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-300px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(300px * var(--tw-space-x-reverse));
  margin-left: calc(300px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-230px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(230px * var(--tw-space-x-reverse));
  margin-left: calc(230px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-180px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(180px * var(--tw-space-x-reverse));
  margin-left: calc(180px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-147px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(147px * var(--tw-space-x-reverse));
  margin-left: calc(147px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-123px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(123px * var(--tw-space-x-reverse));
  margin-left: calc(123px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-100px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(100px * var(--tw-space-x-reverse));
  margin-left: calc(100px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-80px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(80px * var(--tw-space-x-reverse));
  margin-left: calc(80px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-60px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(60px * var(--tw-space-x-reverse));
  margin-left: calc(60px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-50px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(50px * var(--tw-space-x-reverse));
  margin-left: calc(50px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-40px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(40px * var(--tw-space-x-reverse));
  margin-left: calc(40px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-35px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(35px * var(--tw-space-x-reverse));
  margin-left: calc(35px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-34px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(34px !important * var(--tw-space-x-reverse));
  margin-left: calc(34px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-30px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(30px !important * var(--tw-space-x-reverse));
  margin-left: calc(30px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-24px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(24px !important * var(--tw-space-x-reverse));
  margin-left: calc(24px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-20px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(20px !important * var(--tw-space-x-reverse));
  margin-left: calc(20px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-15px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(15px !important * var(--tw-space-x-reverse));
  margin-left: calc(15px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-13px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(13px !important * var(--tw-space-x-reverse));
  margin-left: calc(13px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-12px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12px !important * var(--tw-space-x-reverse));
  margin-left: calc(12px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-10px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(10px !important * var(--tw-space-x-reverse));
  margin-left: calc(10px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8px !important * var(--tw-space-x-reverse));
  margin-left: calc(8px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6px !important * var(--tw-space-x-reverse));
  margin-left: calc(6px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5px !important * var(--tw-space-x-reverse));
  margin-left: calc(5px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3px !important * var(--tw-space-x-reverse));
  margin-left: calc(3px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px !important * var(--tw-space-x-reverse));
  margin-left: calc(0px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-34px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(34px * var(--tw-space-x-reverse));
  margin-left: calc(34px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-30px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(30px * var(--tw-space-x-reverse));
  margin-left: calc(30px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-24px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(24px * var(--tw-space-x-reverse));
  margin-left: calc(24px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-20px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(20px * var(--tw-space-x-reverse));
  margin-left: calc(20px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-15px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(15px * var(--tw-space-x-reverse));
  margin-left: calc(15px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-13px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(13px * var(--tw-space-x-reverse));
  margin-left: calc(13px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-12px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12px * var(--tw-space-x-reverse));
  margin-left: calc(12px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-10px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(10px * var(--tw-space-x-reverse));
  margin-left: calc(10px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8px * var(--tw-space-x-reverse));
  margin-left: calc(8px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6px * var(--tw-space-x-reverse));
  margin-left: calc(6px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5px * var(--tw-space-x-reverse));
  margin-left: calc(5px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3px * var(--tw-space-x-reverse));
  margin-left: calc(3px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-menu > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(275px * var(--tw-space-x-reverse));
  margin-left: calc(275px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1px !important * var(--tw-space-x-reverse));
  margin-left: calc(1px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1rem * var(--tw-space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2rem * var(--tw-space-x-reverse));
  margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3rem * var(--tw-space-x-reverse));
  margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-4rem * var(--tw-space-x-reverse));
  margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-5rem * var(--tw-space-x-reverse));
  margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-6rem * var(--tw-space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-8rem * var(--tw-space-x-reverse));
  margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-10rem * var(--tw-space-x-reverse));
  margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-12rem * var(--tw-space-x-reverse));
  margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-14rem * var(--tw-space-x-reverse));
  margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-16rem * var(--tw-space-x-reverse));
  margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-300px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-300px * var(--tw-space-x-reverse));
  margin-left: calc(-300px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-230px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-230px * var(--tw-space-x-reverse));
  margin-left: calc(-230px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-180px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-180px * var(--tw-space-x-reverse));
  margin-left: calc(-180px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-147px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-147px * var(--tw-space-x-reverse));
  margin-left: calc(-147px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-123px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-123px * var(--tw-space-x-reverse));
  margin-left: calc(-123px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-100px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-100px * var(--tw-space-x-reverse));
  margin-left: calc(-100px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-80px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-80px * var(--tw-space-x-reverse));
  margin-left: calc(-80px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-60px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-60px * var(--tw-space-x-reverse));
  margin-left: calc(-60px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-50px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-50px * var(--tw-space-x-reverse));
  margin-left: calc(-50px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-40px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-40px * var(--tw-space-x-reverse));
  margin-left: calc(-40px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-35px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-35px * var(--tw-space-x-reverse));
  margin-left: calc(-35px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-34px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(34px !important * var(--tw-space-x-reverse));
  margin-left: calc(34px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-30px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(30px !important * var(--tw-space-x-reverse));
  margin-left: calc(30px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-24px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(24px !important * var(--tw-space-x-reverse));
  margin-left: calc(24px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-20px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(20px !important * var(--tw-space-x-reverse));
  margin-left: calc(20px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-15px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(15px !important * var(--tw-space-x-reverse));
  margin-left: calc(15px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-13px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(13px !important * var(--tw-space-x-reverse));
  margin-left: calc(13px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-12px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12px !important * var(--tw-space-x-reverse));
  margin-left: calc(12px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-10px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(10px !important * var(--tw-space-x-reverse));
  margin-left: calc(10px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-8px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8px !important * var(--tw-space-x-reverse));
  margin-left: calc(8px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-6px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6px !important * var(--tw-space-x-reverse));
  margin-left: calc(6px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-5px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5px !important * var(--tw-space-x-reverse));
  margin-left: calc(5px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-3px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3px !important * var(--tw-space-x-reverse));
  margin-left: calc(3px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-0px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px !important * var(--tw-space-x-reverse));
  margin-left: calc(0px !important * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-34px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-34px * var(--tw-space-x-reverse));
  margin-left: calc(-34px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-30px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-30px * var(--tw-space-x-reverse));
  margin-left: calc(-30px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-24px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-24px * var(--tw-space-x-reverse));
  margin-left: calc(-24px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-20px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-20px * var(--tw-space-x-reverse));
  margin-left: calc(-20px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-15px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-15px * var(--tw-space-x-reverse));
  margin-left: calc(-15px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-13px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-13px * var(--tw-space-x-reverse));
  margin-left: calc(-13px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-12px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-12px * var(--tw-space-x-reverse));
  margin-left: calc(-12px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-10px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-10px * var(--tw-space-x-reverse));
  margin-left: calc(-10px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-8px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-8px * var(--tw-space-x-reverse));
  margin-left: calc(-8px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-6px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-6px * var(--tw-space-x-reverse));
  margin-left: calc(-6px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-5px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-5px * var(--tw-space-x-reverse));
  margin-left: calc(-5px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-3px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3px * var(--tw-space-x-reverse));
  margin-left: calc(-3px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-menu > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-275px * var(--tw-space-x-reverse));
  margin-left: calc(-275px * calc(1 - var(--tw-space-x-reverse)));
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1px !important * var(--tw-space-x-reverse));
  margin-left: calc(1px !important * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}

.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}

.space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse));
}

.space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8rem * var(--tw-space-y-reverse));
}

.space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10rem * var(--tw-space-y-reverse));
}

.space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12rem * var(--tw-space-y-reverse));
}

.space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(14rem * var(--tw-space-y-reverse));
}

.space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16rem * var(--tw-space-y-reverse));
}

.space-y-300px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(300px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(300px * var(--tw-space-y-reverse));
}

.space-y-230px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(230px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(230px * var(--tw-space-y-reverse));
}

.space-y-180px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(180px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(180px * var(--tw-space-y-reverse));
}

.space-y-147px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(147px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(147px * var(--tw-space-y-reverse));
}

.space-y-123px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(123px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(123px * var(--tw-space-y-reverse));
}

.space-y-100px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(100px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(100px * var(--tw-space-y-reverse));
}

.space-y-80px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(80px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(80px * var(--tw-space-y-reverse));
}

.space-y-60px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(60px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(60px * var(--tw-space-y-reverse));
}

.space-y-50px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(50px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(50px * var(--tw-space-y-reverse));
}

.space-y-40px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(40px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(40px * var(--tw-space-y-reverse));
}

.space-y-35px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(35px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(35px * var(--tw-space-y-reverse));
}

.space-y-34px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(34px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(34px !important * var(--tw-space-y-reverse));
}

.space-y-30px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(30px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(30px !important * var(--tw-space-y-reverse));
}

.space-y-24px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(24px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(24px !important * var(--tw-space-y-reverse));
}

.space-y-20px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20px !important * var(--tw-space-y-reverse));
}

.space-y-15px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(15px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(15px !important * var(--tw-space-y-reverse));
}

.space-y-13px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(13px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(13px !important * var(--tw-space-y-reverse));
}

.space-y-12px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12px !important * var(--tw-space-y-reverse));
}

.space-y-10px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(10px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10px !important * var(--tw-space-y-reverse));
}

.space-y-8px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8px !important * var(--tw-space-y-reverse));
}

.space-y-6px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6px !important * var(--tw-space-y-reverse));
}

.space-y-5px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5px !important * var(--tw-space-y-reverse));
}

.space-y-3px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3px !important * var(--tw-space-y-reverse));
}

.space-y-0px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px !important * var(--tw-space-y-reverse));
}

.space-y-34px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(34px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(34px * var(--tw-space-y-reverse));
}

.space-y-30px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(30px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(30px * var(--tw-space-y-reverse));
}

.space-y-24px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(24px * var(--tw-space-y-reverse));
}

.space-y-20px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20px * var(--tw-space-y-reverse));
}

.space-y-15px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(15px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(15px * var(--tw-space-y-reverse));
}

.space-y-13px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(13px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(13px * var(--tw-space-y-reverse));
}

.space-y-12px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12px * var(--tw-space-y-reverse));
}

.space-y-10px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(10px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10px * var(--tw-space-y-reverse));
}

.space-y-8px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

.space-y-6px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6px * var(--tw-space-y-reverse));
}

.space-y-5px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5px * var(--tw-space-y-reverse));
}

.space-y-3px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3px * var(--tw-space-y-reverse));
}

.space-y-menu > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(275px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(275px * var(--tw-space-y-reverse));
}

.space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px !important * var(--tw-space-y-reverse));
}

.-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
}

.-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
}

.-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
}

.-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
}

.-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
}

.-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
}

.-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
}

.-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
}

.-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
}

.-space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
}

.-space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
}

.-space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
}

.-space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
}

.-space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
}

.-space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
}

.-space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
}

.-space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
}

.-space-y-300px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-300px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-300px * var(--tw-space-y-reverse));
}

.-space-y-230px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-230px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-230px * var(--tw-space-y-reverse));
}

.-space-y-180px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-180px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-180px * var(--tw-space-y-reverse));
}

.-space-y-147px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-147px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-147px * var(--tw-space-y-reverse));
}

.-space-y-123px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-123px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-123px * var(--tw-space-y-reverse));
}

.-space-y-100px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-100px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-100px * var(--tw-space-y-reverse));
}

.-space-y-80px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-80px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-80px * var(--tw-space-y-reverse));
}

.-space-y-60px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-60px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-60px * var(--tw-space-y-reverse));
}

.-space-y-50px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-50px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-50px * var(--tw-space-y-reverse));
}

.-space-y-40px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-40px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-40px * var(--tw-space-y-reverse));
}

.-space-y-35px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-35px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-35px * var(--tw-space-y-reverse));
}

.-space-y-34px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(34px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(34px !important * var(--tw-space-y-reverse));
}

.-space-y-30px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(30px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(30px !important * var(--tw-space-y-reverse));
}

.-space-y-24px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(24px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(24px !important * var(--tw-space-y-reverse));
}

.-space-y-20px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20px !important * var(--tw-space-y-reverse));
}

.-space-y-15px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(15px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(15px !important * var(--tw-space-y-reverse));
}

.-space-y-13px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(13px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(13px !important * var(--tw-space-y-reverse));
}

.-space-y-12px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12px !important * var(--tw-space-y-reverse));
}

.-space-y-10px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(10px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10px !important * var(--tw-space-y-reverse));
}

.-space-y-8px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8px !important * var(--tw-space-y-reverse));
}

.-space-y-6px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6px !important * var(--tw-space-y-reverse));
}

.-space-y-5px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5px !important * var(--tw-space-y-reverse));
}

.-space-y-3px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3px !important * var(--tw-space-y-reverse));
}

.-space-y-0px-i > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px !important * var(--tw-space-y-reverse));
}

.-space-y-34px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-34px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-34px * var(--tw-space-y-reverse));
}

.-space-y-30px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-30px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-30px * var(--tw-space-y-reverse));
}

.-space-y-24px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-24px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-24px * var(--tw-space-y-reverse));
}

.-space-y-20px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-20px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-20px * var(--tw-space-y-reverse));
}

.-space-y-15px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-15px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-15px * var(--tw-space-y-reverse));
}

.-space-y-13px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-13px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-13px * var(--tw-space-y-reverse));
}

.-space-y-12px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-12px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-12px * var(--tw-space-y-reverse));
}

.-space-y-10px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-10px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-10px * var(--tw-space-y-reverse));
}

.-space-y-8px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-8px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8px * var(--tw-space-y-reverse));
}

.-space-y-6px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-6px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6px * var(--tw-space-y-reverse));
}

.-space-y-5px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-5px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-5px * var(--tw-space-y-reverse));
}

.-space-y-3px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3px * var(--tw-space-y-reverse));
}

.-space-y-menu > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-275px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-275px * var(--tw-space-y-reverse));
}

.-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1px !important * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px !important * var(--tw-space-y-reverse));
}

.space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1;
}

.space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

.divide-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(2px * var(--tw-divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(4px * var(--tw-divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(8px * var(--tw-divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-x-default > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.divide-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
}

.divide-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
}

.divide-y-default > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 1;
}

.divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 1;
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.divide-dashed > :not([hidden]) ~ :not([hidden]) {
  border-style: dashed;
}

.divide-dotted > :not([hidden]) ~ :not([hidden]) {
  border-style: dotted;
}

.divide-double > :not([hidden]) ~ :not([hidden]) {
  border-style: double;
}

.divide-none > :not([hidden]) ~ :not([hidden]) {
  border-style: none;
}

.divide-transparent > :not([hidden]) ~ :not([hidden]) {
  border-color: transparent;
}

.divide-current > :not([hidden]) ~ :not([hidden]) {
  border-color: currentColor;
}

.divide-black > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(27, 27, 26, var(--tw-divide-opacity));
}

.divide-black40pct > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(164, 164, 163, var(--tw-divide-opacity));
}

.divide-white > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
}

.divide-petroleum > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(14, 71, 68, var(--tw-divide-opacity));
}

.divide-petroleum70pct > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(86, 126, 124, var(--tw-divide-opacity));
}

.divide-petroleum30pct > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(183, 200, 199, var(--tw-divide-opacity));
}

.divide-disabled > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(183, 200, 199, var(--tw-divide-opacity));
}

.divide-petroleum10pct > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(231, 237, 236, var(--tw-divide-opacity));
}

.divide-petroleum5pct > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(243, 246, 246, var(--tw-divide-opacity));
}

.divide-darkGreen > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(38, 123, 56, var(--tw-divide-opacity));
}

.divide-ok > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(38, 123, 56, var(--tw-divide-opacity));
}

.divide-green > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(46, 161, 73, var(--tw-divide-opacity));
}

.divide-green10pct > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(234, 246, 237, var(--tw-divide-opacity));
}

.divide-orange > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(237, 113, 0, var(--tw-divide-opacity));
}

.divide-darkOrange > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(170, 54, 54, var(--tw-divide-opacity));
}

.divide-lightOrange > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(251, 227, 204, var(--tw-divide-opacity));
}

.divide-error > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(170, 54, 54, var(--tw-divide-opacity));
}

.divide-yellow > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(233, 194, 88, var(--tw-divide-opacity));
}

.divide-darkYellow > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(195, 176, 145, var(--tw-divide-opacity));
}

.divide-gray > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(148, 148, 147, var(--tw-divide-opacity));
}

.divide-lightGray > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-divide-opacity));
}

.divide-borderGray > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(230, 230, 230, var(--tw-divide-opacity));
}

.divide-default > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(230, 230, 230, var(--tw-divide-opacity));
}

.divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0;
}

.divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.25;
}

.divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.5;
}

.divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.75;
}

.divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
}

.place-self-auto {
  place-self: auto;
}

.place-self-start {
  place-self: start;
}

.place-self-end {
  place-self: end;
}

.place-self-center {
  place-self: center;
}

.place-self-stretch {
  place-self: stretch;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.self-baseline {
  align-self: baseline;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overscroll-auto {
  overscroll-behavior: auto;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.overscroll-none {
  overscroll-behavior: none;
}

.overscroll-y-auto {
  overscroll-behavior-y: auto;
}

.overscroll-y-contain {
  overscroll-behavior-y: contain;
}

.overscroll-y-none {
  overscroll-behavior-y: none;
}

.overscroll-x-auto {
  overscroll-behavior-x: auto;
}

.overscroll-x-contain {
  overscroll-behavior-x: contain;
}

.overscroll-x-none {
  overscroll-behavior-x: none;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.overflow-clip {
  text-overflow: clip;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.rounded-none {
  border-radius: 0;
}

.rounded-5px {
  border-radius: 5px;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded-default {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-t-5px {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.rounded-t-sm {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.rounded-t-default {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-r-5px {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.rounded-r-default {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rounded-r-xl {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-b-5px {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.rounded-b-sm {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.rounded-b-default {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-l-5px {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.rounded-l-sm {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.rounded-l-default {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tl-5px {
  border-top-left-radius: 5px;
}

.rounded-tl-sm {
  border-top-left-radius: 0.125rem;
}

.rounded-tl-default {
  border-top-left-radius: 0.25rem;
}

.rounded-tl-md {
  border-top-left-radius: 0.375rem;
}

.rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}

.rounded-tl-xl {
  border-top-left-radius: 0.75rem;
}

.rounded-tl-2xl {
  border-top-left-radius: 1rem;
}

.rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}

.rounded-tl-full {
  border-top-left-radius: 9999px;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.rounded-tr-5px {
  border-top-right-radius: 5px;
}

.rounded-tr-sm {
  border-top-right-radius: 0.125rem;
}

.rounded-tr-default {
  border-top-right-radius: 0.25rem;
}

.rounded-tr-md {
  border-top-right-radius: 0.375rem;
}

.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}

.rounded-tr-xl {
  border-top-right-radius: 0.75rem;
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem;
}

.rounded-tr-3xl {
  border-top-right-radius: 1.5rem;
}

.rounded-tr-full {
  border-top-right-radius: 9999px;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-br-5px {
  border-bottom-right-radius: 5px;
}

.rounded-br-sm {
  border-bottom-right-radius: 0.125rem;
}

.rounded-br-default {
  border-bottom-right-radius: 0.25rem;
}

.rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}

.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-br-xl {
  border-bottom-right-radius: 0.75rem;
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}

.rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-bl-5px {
  border-bottom-left-radius: 5px;
}

.rounded-bl-sm {
  border-bottom-left-radius: 0.125rem;
}

.rounded-bl-default {
  border-bottom-left-radius: 0.25rem;
}

.rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}

.rounded-bl-xl {
  border-bottom-left-radius: 0.75rem;
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}

.rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem;
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border-default {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-t-8 {
  border-top-width: 8px;
}

.border-t-default {
  border-top-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-r-8 {
  border-right-width: 8px;
}

.border-r-default {
  border-right-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-b-8 {
  border-bottom-width: 8px;
}

.border-b-default {
  border-bottom-width: 1px;
}

.border-l-0 {
  border-left-width: 0;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-l-default {
  border-left-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-double {
  border-style: double;
}

.border-none {
  border-style: none;
}

.border-transparent {
  border-color: transparent;
}

.border-current {
  border-color: currentColor;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(27, 27, 26, var(--tw-border-opacity));
}

.border-black40pct {
  --tw-border-opacity: 1;
  border-color: rgba(164, 164, 163, var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.border-petroleum {
  --tw-border-opacity: 1;
  border-color: rgba(14, 71, 68, var(--tw-border-opacity));
}

.border-petroleum70pct {
  --tw-border-opacity: 1;
  border-color: rgba(86, 126, 124, var(--tw-border-opacity));
}

.border-petroleum30pct {
  --tw-border-opacity: 1;
  border-color: rgba(183, 200, 199, var(--tw-border-opacity));
}

.border-disabled {
  --tw-border-opacity: 1;
  border-color: rgba(183, 200, 199, var(--tw-border-opacity));
}

.border-petroleum10pct {
  --tw-border-opacity: 1;
  border-color: rgba(231, 237, 236, var(--tw-border-opacity));
}

.border-petroleum5pct {
  --tw-border-opacity: 1;
  border-color: rgba(243, 246, 246, var(--tw-border-opacity));
}

.border-darkGreen {
  --tw-border-opacity: 1;
  border-color: rgba(38, 123, 56, var(--tw-border-opacity));
}

.border-ok {
  --tw-border-opacity: 1;
  border-color: rgba(38, 123, 56, var(--tw-border-opacity));
}

.border-green {
  --tw-border-opacity: 1;
  border-color: rgba(46, 161, 73, var(--tw-border-opacity));
}

.border-green10pct {
  --tw-border-opacity: 1;
  border-color: rgba(234, 246, 237, var(--tw-border-opacity));
}

.border-orange {
  --tw-border-opacity: 1;
  border-color: rgba(237, 113, 0, var(--tw-border-opacity));
}

.border-darkOrange {
  --tw-border-opacity: 1;
  border-color: rgba(170, 54, 54, var(--tw-border-opacity));
}

.border-lightOrange {
  --tw-border-opacity: 1;
  border-color: rgba(251, 227, 204, var(--tw-border-opacity));
}

.border-error {
  --tw-border-opacity: 1;
  border-color: rgba(170, 54, 54, var(--tw-border-opacity));
}

.border-yellow {
  --tw-border-opacity: 1;
  border-color: rgba(233, 194, 88, var(--tw-border-opacity));
}

.border-darkYellow {
  --tw-border-opacity: 1;
  border-color: rgba(195, 176, 145, var(--tw-border-opacity));
}

.border-gray {
  --tw-border-opacity: 1;
  border-color: rgba(148, 148, 147, var(--tw-border-opacity));
}

.border-lightGray {
  --tw-border-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-border-opacity));
}

.border-borderGray {
  --tw-border-opacity: 1;
  border-color: rgba(230, 230, 230, var(--tw-border-opacity));
}

.border-default {
  --tw-border-opacity: 1;
  border-color: rgba(230, 230, 230, var(--tw-border-opacity));
}

.hover\:border-transparent:hover {
  border-color: transparent;
}

.hover\:border-current:hover {
  border-color: currentColor;
}

.hover\:border-black:hover {
  --tw-border-opacity: 1;
  border-color: rgba(27, 27, 26, var(--tw-border-opacity));
}

.hover\:border-black40pct:hover {
  --tw-border-opacity: 1;
  border-color: rgba(164, 164, 163, var(--tw-border-opacity));
}

.hover\:border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.hover\:border-petroleum:hover {
  --tw-border-opacity: 1;
  border-color: rgba(14, 71, 68, var(--tw-border-opacity));
}

.hover\:border-petroleum70pct:hover {
  --tw-border-opacity: 1;
  border-color: rgba(86, 126, 124, var(--tw-border-opacity));
}

.hover\:border-petroleum30pct:hover {
  --tw-border-opacity: 1;
  border-color: rgba(183, 200, 199, var(--tw-border-opacity));
}

.hover\:border-disabled:hover {
  --tw-border-opacity: 1;
  border-color: rgba(183, 200, 199, var(--tw-border-opacity));
}

.hover\:border-petroleum10pct:hover {
  --tw-border-opacity: 1;
  border-color: rgba(231, 237, 236, var(--tw-border-opacity));
}

.hover\:border-petroleum5pct:hover {
  --tw-border-opacity: 1;
  border-color: rgba(243, 246, 246, var(--tw-border-opacity));
}

.hover\:border-darkGreen:hover {
  --tw-border-opacity: 1;
  border-color: rgba(38, 123, 56, var(--tw-border-opacity));
}

.hover\:border-ok:hover {
  --tw-border-opacity: 1;
  border-color: rgba(38, 123, 56, var(--tw-border-opacity));
}

.hover\:border-green:hover {
  --tw-border-opacity: 1;
  border-color: rgba(46, 161, 73, var(--tw-border-opacity));
}

.hover\:border-green10pct:hover {
  --tw-border-opacity: 1;
  border-color: rgba(234, 246, 237, var(--tw-border-opacity));
}

.hover\:border-orange:hover {
  --tw-border-opacity: 1;
  border-color: rgba(237, 113, 0, var(--tw-border-opacity));
}

.hover\:border-darkOrange:hover {
  --tw-border-opacity: 1;
  border-color: rgba(170, 54, 54, var(--tw-border-opacity));
}

.hover\:border-lightOrange:hover {
  --tw-border-opacity: 1;
  border-color: rgba(251, 227, 204, var(--tw-border-opacity));
}

.hover\:border-error:hover {
  --tw-border-opacity: 1;
  border-color: rgba(170, 54, 54, var(--tw-border-opacity));
}

.hover\:border-yellow:hover {
  --tw-border-opacity: 1;
  border-color: rgba(233, 194, 88, var(--tw-border-opacity));
}

.hover\:border-darkYellow:hover {
  --tw-border-opacity: 1;
  border-color: rgba(195, 176, 145, var(--tw-border-opacity));
}

.hover\:border-gray:hover {
  --tw-border-opacity: 1;
  border-color: rgba(148, 148, 147, var(--tw-border-opacity));
}

.hover\:border-lightGray:hover {
  --tw-border-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-border-opacity));
}

.hover\:border-borderGray:hover {
  --tw-border-opacity: 1;
  border-color: rgba(230, 230, 230, var(--tw-border-opacity));
}

.hover\:border-default:hover {
  --tw-border-opacity: 1;
  border-color: rgba(230, 230, 230, var(--tw-border-opacity));
}

.focus\:border-transparent:focus {
  border-color: transparent;
}

.focus\:border-current:focus {
  border-color: currentColor;
}

.focus\:border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgba(27, 27, 26, var(--tw-border-opacity));
}

.focus\:border-black40pct:focus {
  --tw-border-opacity: 1;
  border-color: rgba(164, 164, 163, var(--tw-border-opacity));
}

.focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.focus\:border-petroleum:focus {
  --tw-border-opacity: 1;
  border-color: rgba(14, 71, 68, var(--tw-border-opacity));
}

.focus\:border-petroleum70pct:focus {
  --tw-border-opacity: 1;
  border-color: rgba(86, 126, 124, var(--tw-border-opacity));
}

.focus\:border-petroleum30pct:focus {
  --tw-border-opacity: 1;
  border-color: rgba(183, 200, 199, var(--tw-border-opacity));
}

.focus\:border-disabled:focus {
  --tw-border-opacity: 1;
  border-color: rgba(183, 200, 199, var(--tw-border-opacity));
}

.focus\:border-petroleum10pct:focus {
  --tw-border-opacity: 1;
  border-color: rgba(231, 237, 236, var(--tw-border-opacity));
}

.focus\:border-petroleum5pct:focus {
  --tw-border-opacity: 1;
  border-color: rgba(243, 246, 246, var(--tw-border-opacity));
}

.focus\:border-darkGreen:focus {
  --tw-border-opacity: 1;
  border-color: rgba(38, 123, 56, var(--tw-border-opacity));
}

.focus\:border-ok:focus {
  --tw-border-opacity: 1;
  border-color: rgba(38, 123, 56, var(--tw-border-opacity));
}

.focus\:border-green:focus {
  --tw-border-opacity: 1;
  border-color: rgba(46, 161, 73, var(--tw-border-opacity));
}

.focus\:border-green10pct:focus {
  --tw-border-opacity: 1;
  border-color: rgba(234, 246, 237, var(--tw-border-opacity));
}

.focus\:border-orange:focus {
  --tw-border-opacity: 1;
  border-color: rgba(237, 113, 0, var(--tw-border-opacity));
}

.focus\:border-darkOrange:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 54, 54, var(--tw-border-opacity));
}

.focus\:border-lightOrange:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 227, 204, var(--tw-border-opacity));
}

.focus\:border-error:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 54, 54, var(--tw-border-opacity));
}

.focus\:border-yellow:focus {
  --tw-border-opacity: 1;
  border-color: rgba(233, 194, 88, var(--tw-border-opacity));
}

.focus\:border-darkYellow:focus {
  --tw-border-opacity: 1;
  border-color: rgba(195, 176, 145, var(--tw-border-opacity));
}

.focus\:border-gray:focus {
  --tw-border-opacity: 1;
  border-color: rgba(148, 148, 147, var(--tw-border-opacity));
}

.focus\:border-lightGray:focus {
  --tw-border-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-border-opacity));
}

.focus\:border-borderGray:focus {
  --tw-border-opacity: 1;
  border-color: rgba(230, 230, 230, var(--tw-border-opacity));
}

.focus\:border-default:focus {
  --tw-border-opacity: 1;
  border-color: rgba(230, 230, 230, var(--tw-border-opacity));
}

.border-opacity-0 {
  --tw-border-opacity: 0;
}

.border-opacity-25 {
  --tw-border-opacity: 0.25;
}

.border-opacity-50 {
  --tw-border-opacity: 0.5;
}

.border-opacity-75 {
  --tw-border-opacity: 0.75;
}

.border-opacity-100 {
  --tw-border-opacity: 1;
}

.hover\:border-opacity-0:hover {
  --tw-border-opacity: 0;
}

.hover\:border-opacity-25:hover {
  --tw-border-opacity: 0.25;
}

.hover\:border-opacity-50:hover {
  --tw-border-opacity: 0.5;
}

.hover\:border-opacity-75:hover {
  --tw-border-opacity: 0.75;
}

.hover\:border-opacity-100:hover {
  --tw-border-opacity: 1;
}

.focus\:border-opacity-0:focus {
  --tw-border-opacity: 0;
}

.focus\:border-opacity-25:focus {
  --tw-border-opacity: 0.25;
}

.focus\:border-opacity-50:focus {
  --tw-border-opacity: 0.5;
}

.focus\:border-opacity-75:focus {
  --tw-border-opacity: 0.75;
}

.focus\:border-opacity-100:focus {
  --tw-border-opacity: 1;
}

.bg-transparent {
  background-color: transparent;
}

.bg-current {
  background-color: currentColor;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
}

.bg-black40pct {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-petroleum {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
}

.bg-petroleum70pct {
  --tw-bg-opacity: 1;
  background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
}

.bg-petroleum30pct {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
}

.bg-disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
}

.bg-petroleum10pct {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
}

.bg-petroleum5pct {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
}

.bg-darkGreen {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
}

.bg-ok {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
}

.bg-green10pct {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
}

.bg-orange {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
}

.bg-darkOrange {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
}

.bg-lightOrange {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
}

.bg-error {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
}

.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
}

.bg-darkYellow {
  --tw-bg-opacity: 1;
  background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
}

.bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
}

.bg-lightGray {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
}

.bg-borderGray {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: transparent;
}

.hover\:bg-current:hover {
  background-color: currentColor;
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
}

.hover\:bg-black40pct:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.hover\:bg-petroleum:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
}

.hover\:bg-petroleum70pct:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
}

.hover\:bg-petroleum30pct:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
}

.hover\:bg-disabled:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
}

.hover\:bg-petroleum10pct:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
}

.hover\:bg-petroleum5pct:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
}

.hover\:bg-darkGreen:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
}

.hover\:bg-ok:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
}

.hover\:bg-green:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
}

.hover\:bg-green10pct:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
}

.hover\:bg-orange:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
}

.hover\:bg-darkOrange:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
}

.hover\:bg-lightOrange:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
}

.hover\:bg-error:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
}

.hover\:bg-yellow:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
}

.hover\:bg-darkYellow:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
}

.hover\:bg-gray:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
}

.hover\:bg-lightGray:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
}

.hover\:bg-borderGray:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
}

.focus\:bg-transparent:focus {
  background-color: transparent;
}

.focus\:bg-current:focus {
  background-color: currentColor;
}

.focus\:bg-black:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
}

.focus\:bg-black40pct:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.focus\:bg-petroleum:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
}

.focus\:bg-petroleum70pct:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
}

.focus\:bg-petroleum30pct:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
}

.focus\:bg-disabled:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
}

.focus\:bg-petroleum10pct:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
}

.focus\:bg-petroleum5pct:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
}

.focus\:bg-darkGreen:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
}

.focus\:bg-ok:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
}

.focus\:bg-green:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
}

.focus\:bg-green10pct:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
}

.focus\:bg-orange:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
}

.focus\:bg-darkOrange:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
}

.focus\:bg-lightOrange:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
}

.focus\:bg-error:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
}

.focus\:bg-yellow:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
}

.focus\:bg-darkYellow:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
}

.focus\:bg-gray:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
}

.focus\:bg-lightGray:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
}

.focus\:bg-borderGray:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-opacity-25 {
  --tw-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --tw-bg-opacity: 1;
}

.hover\:bg-opacity-0:hover {
  --tw-bg-opacity: 0;
}

.hover\:bg-opacity-25:hover {
  --tw-bg-opacity: 0.25;
}

.hover\:bg-opacity-50:hover {
  --tw-bg-opacity: 0.5;
}

.hover\:bg-opacity-75:hover {
  --tw-bg-opacity: 0.75;
}

.hover\:bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}

.focus\:bg-opacity-0:focus {
  --tw-bg-opacity: 0;
}

.focus\:bg-opacity-25:focus {
  --tw-bg-opacity: 0.25;
}

.focus\:bg-opacity-50:focus {
  --tw-bg-opacity: 0.5;
}

.focus\:bg-opacity-75:focus {
  --tw-bg-opacity: 0.75;
}

.focus\:bg-opacity-100:focus {
  --tw-bg-opacity: 1;
}

.bg-none {
  background-image: none;
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--gradient-color-stops));
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--gradient-color-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--gradient-color-stops));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--gradient-color-stops));
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--gradient-color-stops));
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--gradient-color-stops));
}

.from-transparent {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.from-current {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.from-black {
  --tw-gradient-from: #1b1b1a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
}

.from-black40pct {
  --tw-gradient-from: #a4a4a3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
}

.from-white {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.from-petroleum {
  --tw-gradient-from: #0e4744;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
}

.from-petroleum70pct {
  --tw-gradient-from: #567e7c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
}

.from-petroleum30pct {
  --tw-gradient-from: #b7c8c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
}

.from-disabled {
  --tw-gradient-from: #b7c8c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
}

.from-petroleum10pct {
  --tw-gradient-from: #e7edec;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
}

.from-petroleum5pct {
  --tw-gradient-from: #f3f6f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
}

.from-darkGreen {
  --tw-gradient-from: #267b38;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
}

.from-ok {
  --tw-gradient-from: #267b38;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
}

.from-green {
  --tw-gradient-from: #2ea149;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
}

.from-green10pct {
  --tw-gradient-from: #eaf6ed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
}

.from-orange {
  --tw-gradient-from: #ed7100;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
}

.from-darkOrange {
  --tw-gradient-from: #aa3636;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
}

.from-lightOrange {
  --tw-gradient-from: #fbe3cc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
}

.from-error {
  --tw-gradient-from: #aa3636;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
}

.from-yellow {
  --tw-gradient-from: #e9c258;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
}

.from-darkYellow {
  --tw-gradient-from: #c3b091;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
}

.from-gray {
  --tw-gradient-from: #949493;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
}

.from-lightGray {
  --tw-gradient-from: #e5e5e5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
}

.from-borderGray {
  --tw-gradient-from: #e6e6e6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
}

.hover\:from-transparent:hover {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.hover\:from-current:hover {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.hover\:from-black:hover {
  --tw-gradient-from: #1b1b1a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
}

.hover\:from-black40pct:hover {
  --tw-gradient-from: #a4a4a3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
}

.hover\:from-white:hover {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.hover\:from-petroleum:hover {
  --tw-gradient-from: #0e4744;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
}

.hover\:from-petroleum70pct:hover {
  --tw-gradient-from: #567e7c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
}

.hover\:from-petroleum30pct:hover {
  --tw-gradient-from: #b7c8c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
}

.hover\:from-disabled:hover {
  --tw-gradient-from: #b7c8c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
}

.hover\:from-petroleum10pct:hover {
  --tw-gradient-from: #e7edec;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
}

.hover\:from-petroleum5pct:hover {
  --tw-gradient-from: #f3f6f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
}

.hover\:from-darkGreen:hover {
  --tw-gradient-from: #267b38;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
}

.hover\:from-ok:hover {
  --tw-gradient-from: #267b38;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
}

.hover\:from-green:hover {
  --tw-gradient-from: #2ea149;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
}

.hover\:from-green10pct:hover {
  --tw-gradient-from: #eaf6ed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
}

.hover\:from-orange:hover {
  --tw-gradient-from: #ed7100;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
}

.hover\:from-darkOrange:hover {
  --tw-gradient-from: #aa3636;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
}

.hover\:from-lightOrange:hover {
  --tw-gradient-from: #fbe3cc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
}

.hover\:from-error:hover {
  --tw-gradient-from: #aa3636;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
}

.hover\:from-yellow:hover {
  --tw-gradient-from: #e9c258;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
}

.hover\:from-darkYellow:hover {
  --tw-gradient-from: #c3b091;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
}

.hover\:from-gray:hover {
  --tw-gradient-from: #949493;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
}

.hover\:from-lightGray:hover {
  --tw-gradient-from: #e5e5e5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
}

.hover\:from-borderGray:hover {
  --tw-gradient-from: #e6e6e6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
}

.focus\:from-transparent:focus {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.focus\:from-current:focus {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.focus\:from-black:focus {
  --tw-gradient-from: #1b1b1a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
}

.focus\:from-black40pct:focus {
  --tw-gradient-from: #a4a4a3;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
}

.focus\:from-white:focus {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.focus\:from-petroleum:focus {
  --tw-gradient-from: #0e4744;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
}

.focus\:from-petroleum70pct:focus {
  --tw-gradient-from: #567e7c;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
}

.focus\:from-petroleum30pct:focus {
  --tw-gradient-from: #b7c8c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
}

.focus\:from-disabled:focus {
  --tw-gradient-from: #b7c8c7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
}

.focus\:from-petroleum10pct:focus {
  --tw-gradient-from: #e7edec;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
}

.focus\:from-petroleum5pct:focus {
  --tw-gradient-from: #f3f6f6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
}

.focus\:from-darkGreen:focus {
  --tw-gradient-from: #267b38;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
}

.focus\:from-ok:focus {
  --tw-gradient-from: #267b38;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
}

.focus\:from-green:focus {
  --tw-gradient-from: #2ea149;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
}

.focus\:from-green10pct:focus {
  --tw-gradient-from: #eaf6ed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
}

.focus\:from-orange:focus {
  --tw-gradient-from: #ed7100;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
}

.focus\:from-darkOrange:focus {
  --tw-gradient-from: #aa3636;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
}

.focus\:from-lightOrange:focus {
  --tw-gradient-from: #fbe3cc;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
}

.focus\:from-error:focus {
  --tw-gradient-from: #aa3636;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
}

.focus\:from-yellow:focus {
  --tw-gradient-from: #e9c258;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
}

.focus\:from-darkYellow:focus {
  --tw-gradient-from: #c3b091;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
}

.focus\:from-gray:focus {
  --tw-gradient-from: #949493;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
}

.focus\:from-lightGray:focus {
  --tw-gradient-from: #e5e5e5;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
}

.focus\:from-borderGray:focus {
  --tw-gradient-from: #e6e6e6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
}

.via-transparent {
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.via-current {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.via-black {
  --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
}

.via-black40pct {
  --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
}

.via-white {
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.via-petroleum {
  --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
}

.via-petroleum70pct {
  --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
}

.via-petroleum30pct {
  --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
}

.via-disabled {
  --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
}

.via-petroleum10pct {
  --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
}

.via-petroleum5pct {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
}

.via-darkGreen {
  --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
}

.via-ok {
  --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
}

.via-green {
  --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
}

.via-green10pct {
  --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
}

.via-orange {
  --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
}

.via-darkOrange {
  --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
}

.via-lightOrange {
  --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
}

.via-error {
  --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
}

.via-yellow {
  --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
}

.via-darkYellow {
  --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
}

.via-gray {
  --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
}

.via-lightGray {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
}

.via-borderGray {
  --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
}

.hover\:via-transparent:hover {
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.hover\:via-current:hover {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.hover\:via-black:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
}

.hover\:via-black40pct:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
}

.hover\:via-white:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.hover\:via-petroleum:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
}

.hover\:via-petroleum70pct:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
}

.hover\:via-petroleum30pct:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
}

.hover\:via-disabled:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
}

.hover\:via-petroleum10pct:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
}

.hover\:via-petroleum5pct:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
}

.hover\:via-darkGreen:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
}

.hover\:via-ok:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
}

.hover\:via-green:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
}

.hover\:via-green10pct:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
}

.hover\:via-orange:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
}

.hover\:via-darkOrange:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
}

.hover\:via-lightOrange:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
}

.hover\:via-error:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
}

.hover\:via-yellow:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
}

.hover\:via-darkYellow:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
}

.hover\:via-gray:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
}

.hover\:via-lightGray:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
}

.hover\:via-borderGray:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
}

.focus\:via-transparent:focus {
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
}

.focus\:via-current:focus {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.focus\:via-black:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
}

.focus\:via-black40pct:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
}

.focus\:via-white:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.focus\:via-petroleum:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
}

.focus\:via-petroleum70pct:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
}

.focus\:via-petroleum30pct:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
}

.focus\:via-disabled:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
}

.focus\:via-petroleum10pct:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
}

.focus\:via-petroleum5pct:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
}

.focus\:via-darkGreen:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
}

.focus\:via-ok:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
}

.focus\:via-green:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
}

.focus\:via-green10pct:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
}

.focus\:via-orange:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
}

.focus\:via-darkOrange:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
}

.focus\:via-lightOrange:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
}

.focus\:via-error:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
}

.focus\:via-yellow:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
}

.focus\:via-darkYellow:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
}

.focus\:via-gray:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
}

.focus\:via-lightGray:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
}

.focus\:via-borderGray:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
}

.to-transparent {
  --tw-gradient-to: transparent;
}

.to-current {
  --tw-gradient-to: currentColor;
}

.to-black {
  --tw-gradient-to: #1b1b1a;
}

.to-black40pct {
  --tw-gradient-to: #a4a4a3;
}

.to-white {
  --tw-gradient-to: #fff;
}

.to-petroleum {
  --tw-gradient-to: #0e4744;
}

.to-petroleum70pct {
  --tw-gradient-to: #567e7c;
}

.to-petroleum30pct {
  --tw-gradient-to: #b7c8c7;
}

.to-disabled {
  --tw-gradient-to: #b7c8c7;
}

.to-petroleum10pct {
  --tw-gradient-to: #e7edec;
}

.to-petroleum5pct {
  --tw-gradient-to: #f3f6f6;
}

.to-darkGreen {
  --tw-gradient-to: #267b38;
}

.to-ok {
  --tw-gradient-to: #267b38;
}

.to-green {
  --tw-gradient-to: #2ea149;
}

.to-green10pct {
  --tw-gradient-to: #eaf6ed;
}

.to-orange {
  --tw-gradient-to: #ed7100;
}

.to-darkOrange {
  --tw-gradient-to: #aa3636;
}

.to-lightOrange {
  --tw-gradient-to: #fbe3cc;
}

.to-error {
  --tw-gradient-to: #aa3636;
}

.to-yellow {
  --tw-gradient-to: #e9c258;
}

.to-darkYellow {
  --tw-gradient-to: #c3b091;
}

.to-gray {
  --tw-gradient-to: #949493;
}

.to-lightGray {
  --tw-gradient-to: #e5e5e5;
}

.to-borderGray {
  --tw-gradient-to: #e6e6e6;
}

.hover\:to-transparent:hover {
  --tw-gradient-to: transparent;
}

.hover\:to-current:hover {
  --tw-gradient-to: currentColor;
}

.hover\:to-black:hover {
  --tw-gradient-to: #1b1b1a;
}

.hover\:to-black40pct:hover {
  --tw-gradient-to: #a4a4a3;
}

.hover\:to-white:hover {
  --tw-gradient-to: #fff;
}

.hover\:to-petroleum:hover {
  --tw-gradient-to: #0e4744;
}

.hover\:to-petroleum70pct:hover {
  --tw-gradient-to: #567e7c;
}

.hover\:to-petroleum30pct:hover {
  --tw-gradient-to: #b7c8c7;
}

.hover\:to-disabled:hover {
  --tw-gradient-to: #b7c8c7;
}

.hover\:to-petroleum10pct:hover {
  --tw-gradient-to: #e7edec;
}

.hover\:to-petroleum5pct:hover {
  --tw-gradient-to: #f3f6f6;
}

.hover\:to-darkGreen:hover {
  --tw-gradient-to: #267b38;
}

.hover\:to-ok:hover {
  --tw-gradient-to: #267b38;
}

.hover\:to-green:hover {
  --tw-gradient-to: #2ea149;
}

.hover\:to-green10pct:hover {
  --tw-gradient-to: #eaf6ed;
}

.hover\:to-orange:hover {
  --tw-gradient-to: #ed7100;
}

.hover\:to-darkOrange:hover {
  --tw-gradient-to: #aa3636;
}

.hover\:to-lightOrange:hover {
  --tw-gradient-to: #fbe3cc;
}

.hover\:to-error:hover {
  --tw-gradient-to: #aa3636;
}

.hover\:to-yellow:hover {
  --tw-gradient-to: #e9c258;
}

.hover\:to-darkYellow:hover {
  --tw-gradient-to: #c3b091;
}

.hover\:to-gray:hover {
  --tw-gradient-to: #949493;
}

.hover\:to-lightGray:hover {
  --tw-gradient-to: #e5e5e5;
}

.hover\:to-borderGray:hover {
  --tw-gradient-to: #e6e6e6;
}

.focus\:to-transparent:focus {
  --tw-gradient-to: transparent;
}

.focus\:to-current:focus {
  --tw-gradient-to: currentColor;
}

.focus\:to-black:focus {
  --tw-gradient-to: #1b1b1a;
}

.focus\:to-black40pct:focus {
  --tw-gradient-to: #a4a4a3;
}

.focus\:to-white:focus {
  --tw-gradient-to: #fff;
}

.focus\:to-petroleum:focus {
  --tw-gradient-to: #0e4744;
}

.focus\:to-petroleum70pct:focus {
  --tw-gradient-to: #567e7c;
}

.focus\:to-petroleum30pct:focus {
  --tw-gradient-to: #b7c8c7;
}

.focus\:to-disabled:focus {
  --tw-gradient-to: #b7c8c7;
}

.focus\:to-petroleum10pct:focus {
  --tw-gradient-to: #e7edec;
}

.focus\:to-petroleum5pct:focus {
  --tw-gradient-to: #f3f6f6;
}

.focus\:to-darkGreen:focus {
  --tw-gradient-to: #267b38;
}

.focus\:to-ok:focus {
  --tw-gradient-to: #267b38;
}

.focus\:to-green:focus {
  --tw-gradient-to: #2ea149;
}

.focus\:to-green10pct:focus {
  --tw-gradient-to: #eaf6ed;
}

.focus\:to-orange:focus {
  --tw-gradient-to: #ed7100;
}

.focus\:to-darkOrange:focus {
  --tw-gradient-to: #aa3636;
}

.focus\:to-lightOrange:focus {
  --tw-gradient-to: #fbe3cc;
}

.focus\:to-error:focus {
  --tw-gradient-to: #aa3636;
}

.focus\:to-yellow:focus {
  --tw-gradient-to: #e9c258;
}

.focus\:to-darkYellow:focus {
  --tw-gradient-to: #c3b091;
}

.focus\:to-gray:focus {
  --tw-gradient-to: #949493;
}

.focus\:to-lightGray:focus {
  --tw-gradient-to: #e5e5e5;
}

.focus\:to-borderGray:focus {
  --tw-gradient-to: #e6e6e6;
}

.decoration-slice {
  -webkit-box-decoration-break: slice;
          box-decoration-break: slice;
}

.decoration-clone {
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
}

.bg-auto {
  background-size: auto;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-local {
  background-attachment: local;
}

.bg-scroll {
  background-attachment: scroll;
}

.bg-clip-border {
  background-clip: border-box;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-clip-content {
  background-clip: content-box;
}

.bg-clip-text {
  background-clip: text;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-left {
  background-position: left;
}

.bg-left-bottom {
  background-position: left bottom;
}

.bg-left-top {
  background-position: left top;
}

.bg-right {
  background-position: right;
}

.bg-right-bottom {
  background-position: right bottom;
}

.bg-right-top {
  background-position: right top;
}

.bg-top {
  background-position: top;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-repeat-round {
  background-repeat: round;
}

.bg-repeat-space {
  background-repeat: space;
}

.bg-origin-border {
  background-origin: border-box;
}

.bg-origin-padding {
  background-origin: padding-box;
}

.bg-origin-content {
  background-origin: content-box;
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-0 {
  stroke-width: 0;
}

.stroke-1 {
  stroke-width: 1;
}

.stroke-2 {
  stroke-width: 2;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.object-none {
  object-fit: none;
}

.object-scale-down {
  object-fit: scale-down;
}

.object-bottom {
  object-position: bottom;
}

.object-center {
  object-position: center;
}

.object-left {
  object-position: left;
}

.object-left-bottom {
  object-position: left bottom;
}

.object-left-top {
  object-position: left top;
}

.object-right {
  object-position: right;
}

.object-right-bottom {
  object-position: right bottom;
}

.object-right-top {
  object-position: right top;
}

.object-top {
  object-position: top;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-16 {
  padding: 4rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.p-32 {
  padding: 8rem;
}

.p-40 {
  padding: 10rem;
}

.p-48 {
  padding: 12rem;
}

.p-56 {
  padding: 14rem;
}

.p-64 {
  padding: 16rem;
}

.p-300px {
  padding: 300px;
}

.p-230px {
  padding: 230px;
}

.p-180px {
  padding: 180px;
}

.p-147px {
  padding: 147px;
}

.p-123px {
  padding: 123px;
}

.p-100px {
  padding: 100px;
}

.p-80px {
  padding: 80px;
}

.p-60px {
  padding: 60px;
}

.p-50px {
  padding: 50px;
}

.p-40px {
  padding: 40px;
}

.p-35px {
  padding: 35px;
}

.p-34px-i {
  padding: 34px !important;
}

.p-30px-i {
  padding: 30px !important;
}

.p-24px-i {
  padding: 24px !important;
}

.p-20px-i {
  padding: 20px !important;
}

.p-15px-i {
  padding: 15px !important;
}

.p-13px-i {
  padding: 13px !important;
}

.p-12px-i {
  padding: 12px !important;
}

.p-10px-i {
  padding: 10px !important;
}

.p-8px-i {
  padding: 8px !important;
}

.p-6px-i {
  padding: 6px !important;
}

.p-5px-i {
  padding: 5px !important;
}

.p-3px-i {
  padding: 3px !important;
}

.p-0px-i {
  padding: 0px !important;
}

.p-34px {
  padding: 34px;
}

.p-30px {
  padding: 30px;
}

.p-24px {
  padding: 24px;
}

.p-20px {
  padding: 20px;
}

.p-15px {
  padding: 15px;
}

.p-13px {
  padding: 13px;
}

.p-12px {
  padding: 12px;
}

.p-10px {
  padding: 10px;
}

.p-8px {
  padding: 8px;
}

.p-6px {
  padding: 6px;
}

.p-5px {
  padding: 5px;
}

.p-3px {
  padding: 3px;
}

.p-menu {
  padding: 275px;
}

.p-px {
  padding: 1px !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.px-300px {
  padding-left: 300px;
  padding-right: 300px;
}

.px-230px {
  padding-left: 230px;
  padding-right: 230px;
}

.px-180px {
  padding-left: 180px;
  padding-right: 180px;
}

.px-147px {
  padding-left: 147px;
  padding-right: 147px;
}

.px-123px {
  padding-left: 123px;
  padding-right: 123px;
}

.px-100px {
  padding-left: 100px;
  padding-right: 100px;
}

.px-80px {
  padding-left: 80px;
  padding-right: 80px;
}

.px-60px {
  padding-left: 60px;
  padding-right: 60px;
}

.px-50px {
  padding-left: 50px;
  padding-right: 50px;
}

.px-40px {
  padding-left: 40px;
  padding-right: 40px;
}

.px-35px {
  padding-left: 35px;
  padding-right: 35px;
}

.px-34px-i {
  padding-left: 34px !important;
  padding-right: 34px !important;
}

.px-30px-i {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.px-24px-i {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.px-20px-i {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-15px-i {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.px-13px-i {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.px-12px-i {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.px-10px-i {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px-8px-i {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.px-6px-i {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.px-5px-i {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.px-3px-i {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.px-0px-i {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-34px {
  padding-left: 34px;
  padding-right: 34px;
}

.px-30px {
  padding-left: 30px;
  padding-right: 30px;
}

.px-24px {
  padding-left: 24px;
  padding-right: 24px;
}

.px-20px {
  padding-left: 20px;
  padding-right: 20px;
}

.px-15px {
  padding-left: 15px;
  padding-right: 15px;
}

.px-13px {
  padding-left: 13px;
  padding-right: 13px;
}

.px-12px {
  padding-left: 12px;
  padding-right: 12px;
}

.px-10px {
  padding-left: 10px;
  padding-right: 10px;
}

.px-8px {
  padding-left: 8px;
  padding-right: 8px;
}

.px-6px {
  padding-left: 6px;
  padding-right: 6px;
}

.px-5px {
  padding-left: 5px;
  padding-right: 5px;
}

.px-3px {
  padding-left: 3px;
  padding-right: 3px;
}

.px-menu {
  padding-left: 275px;
  padding-right: 275px;
}

.px-px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.py-300px {
  padding-top: 300px;
  padding-bottom: 300px;
}

.py-230px {
  padding-top: 230px;
  padding-bottom: 230px;
}

.py-180px {
  padding-top: 180px;
  padding-bottom: 180px;
}

.py-147px {
  padding-top: 147px;
  padding-bottom: 147px;
}

.py-123px {
  padding-top: 123px;
  padding-bottom: 123px;
}

.py-100px {
  padding-top: 100px;
  padding-bottom: 100px;
}

.py-80px {
  padding-top: 80px;
  padding-bottom: 80px;
}

.py-60px {
  padding-top: 60px;
  padding-bottom: 60px;
}

.py-50px {
  padding-top: 50px;
  padding-bottom: 50px;
}

.py-40px {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-35px {
  padding-top: 35px;
  padding-bottom: 35px;
}

.py-34px-i {
  padding-top: 34px !important;
  padding-bottom: 34px !important;
}

.py-30px-i {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-24px-i {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-20px-i {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-15px-i {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-13px-i {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.py-12px-i {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-10px-i {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-8px-i {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-6px-i {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.py-5px-i {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py-3px-i {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.py-0px-i {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-34px {
  padding-top: 34px;
  padding-bottom: 34px;
}

.py-30px {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-24px {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-15px {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-13px {
  padding-top: 13px;
  padding-bottom: 13px;
}

.py-12px {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-10px {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-8px {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-6px {
  padding-top: 6px;
  padding-bottom: 6px;
}

.py-5px {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-3px {
  padding-top: 3px;
  padding-bottom: 3px;
}

.py-menu {
  padding-top: 275px;
  padding-bottom: 275px;
}

.py-px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pt-56 {
  padding-top: 14rem;
}

.pt-64 {
  padding-top: 16rem;
}

.pt-300px {
  padding-top: 300px;
}

.pt-230px {
  padding-top: 230px;
}

.pt-180px {
  padding-top: 180px;
}

.pt-147px {
  padding-top: 147px;
}

.pt-123px {
  padding-top: 123px;
}

.pt-100px {
  padding-top: 100px;
}

.pt-80px {
  padding-top: 80px;
}

.pt-60px {
  padding-top: 60px;
}

.pt-50px {
  padding-top: 50px;
}

.pt-40px {
  padding-top: 40px;
}

.pt-35px {
  padding-top: 35px;
}

.pt-34px-i {
  padding-top: 34px !important;
}

.pt-30px-i {
  padding-top: 30px !important;
}

.pt-24px-i {
  padding-top: 24px !important;
}

.pt-20px-i {
  padding-top: 20px !important;
}

.pt-15px-i {
  padding-top: 15px !important;
}

.pt-13px-i {
  padding-top: 13px !important;
}

.pt-12px-i {
  padding-top: 12px !important;
}

.pt-10px-i {
  padding-top: 10px !important;
}

.pt-8px-i {
  padding-top: 8px !important;
}

.pt-6px-i {
  padding-top: 6px !important;
}

.pt-5px-i {
  padding-top: 5px !important;
}

.pt-3px-i {
  padding-top: 3px !important;
}

.pt-0px-i {
  padding-top: 0px !important;
}

.pt-34px {
  padding-top: 34px;
}

.pt-30px {
  padding-top: 30px;
}

.pt-24px {
  padding-top: 24px;
}

.pt-20px {
  padding-top: 20px;
}

.pt-15px {
  padding-top: 15px;
}

.pt-13px {
  padding-top: 13px;
}

.pt-12px {
  padding-top: 12px;
}

.pt-10px {
  padding-top: 10px;
}

.pt-8px {
  padding-top: 8px;
}

.pt-6px {
  padding-top: 6px;
}

.pt-5px {
  padding-top: 5px;
}

.pt-3px {
  padding-top: 3px;
}

.pt-menu {
  padding-top: 275px;
}

.pt-px {
  padding-top: 1px !important;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pr-24 {
  padding-right: 6rem;
}

.pr-32 {
  padding-right: 8rem;
}

.pr-40 {
  padding-right: 10rem;
}

.pr-48 {
  padding-right: 12rem;
}

.pr-56 {
  padding-right: 14rem;
}

.pr-64 {
  padding-right: 16rem;
}

.pr-300px {
  padding-right: 300px;
}

.pr-230px {
  padding-right: 230px;
}

.pr-180px {
  padding-right: 180px;
}

.pr-147px {
  padding-right: 147px;
}

.pr-123px {
  padding-right: 123px;
}

.pr-100px {
  padding-right: 100px;
}

.pr-80px {
  padding-right: 80px;
}

.pr-60px {
  padding-right: 60px;
}

.pr-50px {
  padding-right: 50px;
}

.pr-40px {
  padding-right: 40px;
}

.pr-35px {
  padding-right: 35px;
}

.pr-34px-i {
  padding-right: 34px !important;
}

.pr-30px-i {
  padding-right: 30px !important;
}

.pr-24px-i {
  padding-right: 24px !important;
}

.pr-20px-i {
  padding-right: 20px !important;
}

.pr-15px-i {
  padding-right: 15px !important;
}

.pr-13px-i {
  padding-right: 13px !important;
}

.pr-12px-i {
  padding-right: 12px !important;
}

.pr-10px-i {
  padding-right: 10px !important;
}

.pr-8px-i {
  padding-right: 8px !important;
}

.pr-6px-i {
  padding-right: 6px !important;
}

.pr-5px-i {
  padding-right: 5px !important;
}

.pr-3px-i {
  padding-right: 3px !important;
}

.pr-0px-i {
  padding-right: 0px !important;
}

.pr-34px {
  padding-right: 34px;
}

.pr-30px {
  padding-right: 30px;
}

.pr-24px {
  padding-right: 24px;
}

.pr-20px {
  padding-right: 20px;
}

.pr-15px {
  padding-right: 15px;
}

.pr-13px {
  padding-right: 13px;
}

.pr-12px {
  padding-right: 12px;
}

.pr-10px {
  padding-right: 10px;
}

.pr-8px {
  padding-right: 8px;
}

.pr-6px {
  padding-right: 6px;
}

.pr-5px {
  padding-right: 5px;
}

.pr-3px {
  padding-right: 3px;
}

.pr-menu {
  padding-right: 275px;
}

.pr-px {
  padding-right: 1px !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pb-56 {
  padding-bottom: 14rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.pb-300px {
  padding-bottom: 300px;
}

.pb-230px {
  padding-bottom: 230px;
}

.pb-180px {
  padding-bottom: 180px;
}

.pb-147px {
  padding-bottom: 147px;
}

.pb-123px {
  padding-bottom: 123px;
}

.pb-100px {
  padding-bottom: 100px;
}

.pb-80px {
  padding-bottom: 80px;
}

.pb-60px {
  padding-bottom: 60px;
}

.pb-50px {
  padding-bottom: 50px;
}

.pb-40px {
  padding-bottom: 40px;
}

.pb-35px {
  padding-bottom: 35px;
}

.pb-34px-i {
  padding-bottom: 34px !important;
}

.pb-30px-i {
  padding-bottom: 30px !important;
}

.pb-24px-i {
  padding-bottom: 24px !important;
}

.pb-20px-i {
  padding-bottom: 20px !important;
}

.pb-15px-i {
  padding-bottom: 15px !important;
}

.pb-13px-i {
  padding-bottom: 13px !important;
}

.pb-12px-i {
  padding-bottom: 12px !important;
}

.pb-10px-i {
  padding-bottom: 10px !important;
}

.pb-8px-i {
  padding-bottom: 8px !important;
}

.pb-6px-i {
  padding-bottom: 6px !important;
}

.pb-5px-i {
  padding-bottom: 5px !important;
}

.pb-3px-i {
  padding-bottom: 3px !important;
}

.pb-0px-i {
  padding-bottom: 0px !important;
}

.pb-34px {
  padding-bottom: 34px;
}

.pb-30px {
  padding-bottom: 30px;
}

.pb-24px {
  padding-bottom: 24px;
}

.pb-20px {
  padding-bottom: 20px;
}

.pb-15px {
  padding-bottom: 15px;
}

.pb-13px {
  padding-bottom: 13px;
}

.pb-12px {
  padding-bottom: 12px;
}

.pb-10px {
  padding-bottom: 10px;
}

.pb-8px {
  padding-bottom: 8px;
}

.pb-6px {
  padding-bottom: 6px;
}

.pb-5px {
  padding-bottom: 5px;
}

.pb-3px {
  padding-bottom: 3px;
}

.pb-menu {
  padding-bottom: 275px;
}

.pb-px {
  padding-bottom: 1px !important;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pl-32 {
  padding-left: 8rem;
}

.pl-40 {
  padding-left: 10rem;
}

.pl-48 {
  padding-left: 12rem;
}

.pl-56 {
  padding-left: 14rem;
}

.pl-64 {
  padding-left: 16rem;
}

.pl-300px {
  padding-left: 300px;
}

.pl-230px {
  padding-left: 230px;
}

.pl-180px {
  padding-left: 180px;
}

.pl-147px {
  padding-left: 147px;
}

.pl-123px {
  padding-left: 123px;
}

.pl-100px {
  padding-left: 100px;
}

.pl-80px {
  padding-left: 80px;
}

.pl-60px {
  padding-left: 60px;
}

.pl-50px {
  padding-left: 50px;
}

.pl-40px {
  padding-left: 40px;
}

.pl-35px {
  padding-left: 35px;
}

.pl-34px-i {
  padding-left: 34px !important;
}

.pl-30px-i {
  padding-left: 30px !important;
}

.pl-24px-i {
  padding-left: 24px !important;
}

.pl-20px-i {
  padding-left: 20px !important;
}

.pl-15px-i {
  padding-left: 15px !important;
}

.pl-13px-i {
  padding-left: 13px !important;
}

.pl-12px-i {
  padding-left: 12px !important;
}

.pl-10px-i {
  padding-left: 10px !important;
}

.pl-8px-i {
  padding-left: 8px !important;
}

.pl-6px-i {
  padding-left: 6px !important;
}

.pl-5px-i {
  padding-left: 5px !important;
}

.pl-3px-i {
  padding-left: 3px !important;
}

.pl-0px-i {
  padding-left: 0px !important;
}

.pl-34px {
  padding-left: 34px;
}

.pl-30px {
  padding-left: 30px;
}

.pl-24px {
  padding-left: 24px;
}

.pl-20px {
  padding-left: 20px;
}

.pl-15px {
  padding-left: 15px;
}

.pl-13px {
  padding-left: 13px;
}

.pl-12px {
  padding-left: 12px;
}

.pl-10px {
  padding-left: 10px;
}

.pl-8px {
  padding-left: 8px;
}

.pl-6px {
  padding-left: 6px;
}

.pl-5px {
  padding-left: 5px;
}

.pl-3px {
  padding-left: 3px;
}

.pl-menu {
  padding-left: 275px;
}

.pl-px {
  padding-left: 1px !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.font-sans {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.font-serif {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
}

.font-mono {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-size-42 {
  font-size: 42px !important;
}

.text-size-32 {
  font-size: 32px !important;
}

.text-size-28 {
  font-size: 24px !important;
}

.text-size-24 {
  font-size: 24px !important;
}

.text-size-20 {
  font-size: 20px !important;
}

.text-size-18 {
  font-size: 18px !important;
}

.font-hairline {
  font-weight: 100;
}

.font-thin {
  font-weight: 200;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700 !important;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.hover\:font-hairline:hover {
  font-weight: 100;
}

.hover\:font-thin:hover {
  font-weight: 200;
}

.hover\:font-light:hover {
  font-weight: 300 !important;
}

.hover\:font-normal:hover {
  font-weight: 400 !important;
}

.hover\:font-medium:hover {
  font-weight: 500;
}

.hover\:font-semibold:hover {
  font-weight: 600;
}

.hover\:font-bold:hover {
  font-weight: 700 !important;
}

.hover\:font-extrabold:hover {
  font-weight: 800;
}

.hover\:font-black:hover {
  font-weight: 900;
}

.focus\:font-hairline:focus {
  font-weight: 100;
}

.focus\:font-thin:focus {
  font-weight: 200;
}

.focus\:font-light:focus {
  font-weight: 300 !important;
}

.focus\:font-normal:focus {
  font-weight: 400 !important;
}

.focus\:font-medium:focus {
  font-weight: 500;
}

.focus\:font-semibold:focus {
  font-weight: 600;
}

.focus\:font-bold:focus {
  font-weight: 700 !important;
}

.focus\:font-extrabold:focus {
  font-weight: 800;
}

.focus\:font-black:focus {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.ordinal, .slashed-zero, .lining-nums, .oldstyle-nums, .proportional-nums, .tabular-nums, .diagonal-fractions, .stacked-fractions {
  --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.normal-nums {
  font-variant-numeric: normal;
}

.ordinal {
  --tw-ordinal: ordinal;
}

.slashed-zero {
  --tw-slashed-zero: slashed-zero;
}

.lining-nums {
  --tw-numeric-figure: lining-nums;
}

.oldstyle-nums {
  --tw-numeric-figure: oldstyle-nums;
}

.proportional-nums {
  --tw-numeric-spacing: proportional-nums;
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
}

.diagonal-fractions {
  --tw-numeric-fraction: diagonal-fractions;
}

.stacked-fractions {
  --tw-numeric-fraction: stacked-fractions;
}

.leading-size-54 {
  line-height: 54px !important;
}

.leading-size-40 {
  line-height: 40px !important;
}

.leading-size-32 {
  line-height: 32px !important;
}

.leading-size-28 {
  line-height: 28px !important;
}

.leading-size-24 {
  line-height: 24px !important;
}

.tracking-tightest {
  letter-spacing: -0.1em;
}

.tracking-tighter {
  letter-spacing: -0.05em;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.tracking-normal {
  letter-spacing: 0 !important;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.tracking-widest {
  letter-spacing: 0.1em;
}

.text-transparent {
  color: transparent;
}

.text-current {
  color: currentColor;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(27, 27, 26, var(--tw-text-opacity));
}

.text-black40pct {
  --tw-text-opacity: 1;
  color: rgba(164, 164, 163, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-petroleum {
  --tw-text-opacity: 1;
  color: rgba(14, 71, 68, var(--tw-text-opacity));
}

.text-petroleum70pct {
  --tw-text-opacity: 1;
  color: rgba(86, 126, 124, var(--tw-text-opacity));
}

.text-petroleum30pct {
  --tw-text-opacity: 1;
  color: rgba(183, 200, 199, var(--tw-text-opacity));
}

.text-disabled {
  --tw-text-opacity: 1;
  color: rgba(183, 200, 199, var(--tw-text-opacity));
}

.text-petroleum10pct {
  --tw-text-opacity: 1;
  color: rgba(231, 237, 236, var(--tw-text-opacity));
}

.text-petroleum5pct {
  --tw-text-opacity: 1;
  color: rgba(243, 246, 246, var(--tw-text-opacity));
}

.text-darkGreen {
  --tw-text-opacity: 1;
  color: rgba(38, 123, 56, var(--tw-text-opacity));
}

.text-ok {
  --tw-text-opacity: 1;
  color: rgba(38, 123, 56, var(--tw-text-opacity));
}

.text-green {
  --tw-text-opacity: 1;
  color: rgba(46, 161, 73, var(--tw-text-opacity));
}

.text-green10pct {
  --tw-text-opacity: 1;
  color: rgba(234, 246, 237, var(--tw-text-opacity));
}

.text-orange {
  --tw-text-opacity: 1;
  color: rgba(237, 113, 0, var(--tw-text-opacity));
}

.text-darkOrange {
  --tw-text-opacity: 1;
  color: rgba(170, 54, 54, var(--tw-text-opacity));
}

.text-lightOrange {
  --tw-text-opacity: 1;
  color: rgba(251, 227, 204, var(--tw-text-opacity));
}

.text-error {
  --tw-text-opacity: 1;
  color: rgba(170, 54, 54, var(--tw-text-opacity));
}

.text-yellow {
  --tw-text-opacity: 1;
  color: rgba(233, 194, 88, var(--tw-text-opacity));
}

.text-darkYellow {
  --tw-text-opacity: 1;
  color: rgba(195, 176, 145, var(--tw-text-opacity));
}

.text-gray {
  --tw-text-opacity: 1;
  color: rgba(148, 148, 147, var(--tw-text-opacity));
}

.text-lightGray {
  --tw-text-opacity: 1;
  color: rgba(229, 229, 229, var(--tw-text-opacity));
}

.text-borderGray {
  --tw-text-opacity: 1;
  color: rgba(230, 230, 230, var(--tw-text-opacity));
}

.hover\:text-transparent:hover {
  color: transparent;
}

.hover\:text-current:hover {
  color: currentColor;
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgba(27, 27, 26, var(--tw-text-opacity));
}

.hover\:text-black40pct:hover {
  --tw-text-opacity: 1;
  color: rgba(164, 164, 163, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:text-petroleum:hover {
  --tw-text-opacity: 1;
  color: rgba(14, 71, 68, var(--tw-text-opacity));
}

.hover\:text-petroleum70pct:hover {
  --tw-text-opacity: 1;
  color: rgba(86, 126, 124, var(--tw-text-opacity));
}

.hover\:text-petroleum30pct:hover {
  --tw-text-opacity: 1;
  color: rgba(183, 200, 199, var(--tw-text-opacity));
}

.hover\:text-disabled:hover {
  --tw-text-opacity: 1;
  color: rgba(183, 200, 199, var(--tw-text-opacity));
}

.hover\:text-petroleum10pct:hover {
  --tw-text-opacity: 1;
  color: rgba(231, 237, 236, var(--tw-text-opacity));
}

.hover\:text-petroleum5pct:hover {
  --tw-text-opacity: 1;
  color: rgba(243, 246, 246, var(--tw-text-opacity));
}

.hover\:text-darkGreen:hover {
  --tw-text-opacity: 1;
  color: rgba(38, 123, 56, var(--tw-text-opacity));
}

.hover\:text-ok:hover {
  --tw-text-opacity: 1;
  color: rgba(38, 123, 56, var(--tw-text-opacity));
}

.hover\:text-green:hover {
  --tw-text-opacity: 1;
  color: rgba(46, 161, 73, var(--tw-text-opacity));
}

.hover\:text-green10pct:hover {
  --tw-text-opacity: 1;
  color: rgba(234, 246, 237, var(--tw-text-opacity));
}

.hover\:text-orange:hover {
  --tw-text-opacity: 1;
  color: rgba(237, 113, 0, var(--tw-text-opacity));
}

.hover\:text-darkOrange:hover {
  --tw-text-opacity: 1;
  color: rgba(170, 54, 54, var(--tw-text-opacity));
}

.hover\:text-lightOrange:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 227, 204, var(--tw-text-opacity));
}

.hover\:text-error:hover {
  --tw-text-opacity: 1;
  color: rgba(170, 54, 54, var(--tw-text-opacity));
}

.hover\:text-yellow:hover {
  --tw-text-opacity: 1;
  color: rgba(233, 194, 88, var(--tw-text-opacity));
}

.hover\:text-darkYellow:hover {
  --tw-text-opacity: 1;
  color: rgba(195, 176, 145, var(--tw-text-opacity));
}

.hover\:text-gray:hover {
  --tw-text-opacity: 1;
  color: rgba(148, 148, 147, var(--tw-text-opacity));
}

.hover\:text-lightGray:hover {
  --tw-text-opacity: 1;
  color: rgba(229, 229, 229, var(--tw-text-opacity));
}

.hover\:text-borderGray:hover {
  --tw-text-opacity: 1;
  color: rgba(230, 230, 230, var(--tw-text-opacity));
}

.focus\:text-transparent:focus {
  color: transparent;
}

.focus\:text-current:focus {
  color: currentColor;
}

.focus\:text-black:focus {
  --tw-text-opacity: 1;
  color: rgba(27, 27, 26, var(--tw-text-opacity));
}

.focus\:text-black40pct:focus {
  --tw-text-opacity: 1;
  color: rgba(164, 164, 163, var(--tw-text-opacity));
}

.focus\:text-white:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.focus\:text-petroleum:focus {
  --tw-text-opacity: 1;
  color: rgba(14, 71, 68, var(--tw-text-opacity));
}

.focus\:text-petroleum70pct:focus {
  --tw-text-opacity: 1;
  color: rgba(86, 126, 124, var(--tw-text-opacity));
}

.focus\:text-petroleum30pct:focus {
  --tw-text-opacity: 1;
  color: rgba(183, 200, 199, var(--tw-text-opacity));
}

.focus\:text-disabled:focus {
  --tw-text-opacity: 1;
  color: rgba(183, 200, 199, var(--tw-text-opacity));
}

.focus\:text-petroleum10pct:focus {
  --tw-text-opacity: 1;
  color: rgba(231, 237, 236, var(--tw-text-opacity));
}

.focus\:text-petroleum5pct:focus {
  --tw-text-opacity: 1;
  color: rgba(243, 246, 246, var(--tw-text-opacity));
}

.focus\:text-darkGreen:focus {
  --tw-text-opacity: 1;
  color: rgba(38, 123, 56, var(--tw-text-opacity));
}

.focus\:text-ok:focus {
  --tw-text-opacity: 1;
  color: rgba(38, 123, 56, var(--tw-text-opacity));
}

.focus\:text-green:focus {
  --tw-text-opacity: 1;
  color: rgba(46, 161, 73, var(--tw-text-opacity));
}

.focus\:text-green10pct:focus {
  --tw-text-opacity: 1;
  color: rgba(234, 246, 237, var(--tw-text-opacity));
}

.focus\:text-orange:focus {
  --tw-text-opacity: 1;
  color: rgba(237, 113, 0, var(--tw-text-opacity));
}

.focus\:text-darkOrange:focus {
  --tw-text-opacity: 1;
  color: rgba(170, 54, 54, var(--tw-text-opacity));
}

.focus\:text-lightOrange:focus {
  --tw-text-opacity: 1;
  color: rgba(251, 227, 204, var(--tw-text-opacity));
}

.focus\:text-error:focus {
  --tw-text-opacity: 1;
  color: rgba(170, 54, 54, var(--tw-text-opacity));
}

.focus\:text-yellow:focus {
  --tw-text-opacity: 1;
  color: rgba(233, 194, 88, var(--tw-text-opacity));
}

.focus\:text-darkYellow:focus {
  --tw-text-opacity: 1;
  color: rgba(195, 176, 145, var(--tw-text-opacity));
}

.focus\:text-gray:focus {
  --tw-text-opacity: 1;
  color: rgba(148, 148, 147, var(--tw-text-opacity));
}

.focus\:text-lightGray:focus {
  --tw-text-opacity: 1;
  color: rgba(229, 229, 229, var(--tw-text-opacity));
}

.focus\:text-borderGray:focus {
  --tw-text-opacity: 1;
  color: rgba(230, 230, 230, var(--tw-text-opacity));
}

.text-opacity-0 {
  --tw-text-opacity: 0;
}

.text-opacity-25 {
  --tw-text-opacity: 0.25;
}

.text-opacity-50 {
  --tw-text-opacity: 0.5;
}

.text-opacity-75 {
  --tw-text-opacity: 0.75;
}

.text-opacity-100 {
  --tw-text-opacity: 1;
}

.hover\:text-opacity-0:hover {
  --tw-text-opacity: 0;
}

.hover\:text-opacity-25:hover {
  --tw-text-opacity: 0.25;
}

.hover\:text-opacity-50:hover {
  --tw-text-opacity: 0.5;
}

.hover\:text-opacity-75:hover {
  --tw-text-opacity: 0.75;
}

.hover\:text-opacity-100:hover {
  --tw-text-opacity: 1;
}

.focus\:text-opacity-0:focus {
  --tw-text-opacity: 0;
}

.focus\:text-opacity-25:focus {
  --tw-text-opacity: 0.25;
}

.focus\:text-opacity-50:focus {
  --tw-text-opacity: 0.5;
}

.focus\:text-opacity-75:focus {
  --tw-text-opacity: 0.75;
}

.focus\:text-opacity-100:focus {
  --tw-text-opacity: 1;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.no-underline {
  text-decoration: none;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.hover\:line-through:hover {
  text-decoration: line-through;
}

.hover\:no-underline:hover {
  text-decoration: none;
}

.focus\:underline:focus {
  text-decoration: underline;
}

.focus\:line-through:focus {
  text-decoration: line-through;
}

.focus\:no-underline:focus {
  text-decoration: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.placeholder-transparent::placeholder {
  color: transparent;
}

.placeholder-current::placeholder {
  color: currentColor;
}

.placeholder-black::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(27, 27, 26, var(--tw-placeholder-opacity));
}

.placeholder-black40pct::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(164, 164, 163, var(--tw-placeholder-opacity));
}

.placeholder-white::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.placeholder-petroleum::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(14, 71, 68, var(--tw-placeholder-opacity));
}

.placeholder-petroleum70pct::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(86, 126, 124, var(--tw-placeholder-opacity));
}

.placeholder-petroleum30pct::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
}

.placeholder-disabled::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
}

.placeholder-petroleum10pct::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(231, 237, 236, var(--tw-placeholder-opacity));
}

.placeholder-petroleum5pct::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 246, 246, var(--tw-placeholder-opacity));
}

.placeholder-darkGreen::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
}

.placeholder-ok::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
}

.placeholder-green::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(46, 161, 73, var(--tw-placeholder-opacity));
}

.placeholder-green10pct::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(234, 246, 237, var(--tw-placeholder-opacity));
}

.placeholder-orange::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 113, 0, var(--tw-placeholder-opacity));
}

.placeholder-darkOrange::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
}

.placeholder-lightOrange::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 227, 204, var(--tw-placeholder-opacity));
}

.placeholder-error::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
}

.placeholder-yellow::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(233, 194, 88, var(--tw-placeholder-opacity));
}

.placeholder-darkYellow::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(195, 176, 145, var(--tw-placeholder-opacity));
}

.placeholder-gray::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(148, 148, 147, var(--tw-placeholder-opacity));
}

.placeholder-lightGray::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 229, 229, var(--tw-placeholder-opacity));
}

.placeholder-borderGray::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(230, 230, 230, var(--tw-placeholder-opacity));
}

.focus\:placeholder-transparent:focus::placeholder {
  color: transparent;
}

.focus\:placeholder-current:focus::placeholder {
  color: currentColor;
}

.focus\:placeholder-black:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(27, 27, 26, var(--tw-placeholder-opacity));
}

.focus\:placeholder-black40pct:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(164, 164, 163, var(--tw-placeholder-opacity));
}

.focus\:placeholder-white:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.focus\:placeholder-petroleum:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(14, 71, 68, var(--tw-placeholder-opacity));
}

.focus\:placeholder-petroleum70pct:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(86, 126, 124, var(--tw-placeholder-opacity));
}

.focus\:placeholder-petroleum30pct:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
}

.focus\:placeholder-disabled:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
}

.focus\:placeholder-petroleum10pct:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(231, 237, 236, var(--tw-placeholder-opacity));
}

.focus\:placeholder-petroleum5pct:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 246, 246, var(--tw-placeholder-opacity));
}

.focus\:placeholder-darkGreen:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
}

.focus\:placeholder-ok:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
}

.focus\:placeholder-green:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(46, 161, 73, var(--tw-placeholder-opacity));
}

.focus\:placeholder-green10pct:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(234, 246, 237, var(--tw-placeholder-opacity));
}

.focus\:placeholder-orange:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 113, 0, var(--tw-placeholder-opacity));
}

.focus\:placeholder-darkOrange:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
}

.focus\:placeholder-lightOrange:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 227, 204, var(--tw-placeholder-opacity));
}

.focus\:placeholder-error:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
}

.focus\:placeholder-yellow:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(233, 194, 88, var(--tw-placeholder-opacity));
}

.focus\:placeholder-darkYellow:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(195, 176, 145, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(148, 148, 147, var(--tw-placeholder-opacity));
}

.focus\:placeholder-lightGray:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 229, 229, var(--tw-placeholder-opacity));
}

.focus\:placeholder-borderGray:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(230, 230, 230, var(--tw-placeholder-opacity));
}

.placeholder-opacity-0::placeholder {
  --tw-placeholder-opacity: 0;
}

.placeholder-opacity-25::placeholder {
  --tw-placeholder-opacity: 0.25;
}

.placeholder-opacity-50::placeholder {
  --tw-placeholder-opacity: 0.5;
}

.placeholder-opacity-75::placeholder {
  --tw-placeholder-opacity: 0.75;
}

.placeholder-opacity-100::placeholder {
  --tw-placeholder-opacity: 1;
}

.focus\:placeholder-opacity-0:focus::placeholder {
  --tw-placeholder-opacity: 0;
}

.focus\:placeholder-opacity-25:focus::placeholder {
  --tw-placeholder-opacity: 0.25;
}

.focus\:placeholder-opacity-50:focus::placeholder {
  --tw-placeholder-opacity: 0.5;
}

.focus\:placeholder-opacity-75:focus::placeholder {
  --tw-placeholder-opacity: 0.75;
}

.focus\:placeholder-opacity-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.hover\:opacity-0:hover {
  opacity: 0;
}

.hover\:opacity-25:hover {
  opacity: 0.25;
}

.hover\:opacity-50:hover {
  opacity: 0.5;
}

.hover\:opacity-75:hover {
  opacity: 0.75;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.focus\:opacity-0:focus {
  opacity: 0;
}

.focus\:opacity-25:focus {
  opacity: 0.25;
}

.focus\:opacity-50:focus {
  opacity: 0.5;
}

.focus\:opacity-75:focus {
  opacity: 0.75;
}

.focus\:opacity-100:focus {
  opacity: 1;
}

.bg-blend-normal {
  background-blend-mode: normal;
}

.bg-blend-multiply {
  background-blend-mode: multiply;
}

.bg-blend-screen {
  background-blend-mode: screen;
}

.bg-blend-overlay {
  background-blend-mode: overlay;
}

.bg-blend-darken {
  background-blend-mode: darken;
}

.bg-blend-lighten {
  background-blend-mode: lighten;
}

.bg-blend-color-dodge {
  background-blend-mode: color-dodge;
}

.bg-blend-color-burn {
  background-blend-mode: color-burn;
}

.bg-blend-hard-light {
  background-blend-mode: hard-light;
}

.bg-blend-soft-light {
  background-blend-mode: soft-light;
}

.bg-blend-difference {
  background-blend-mode: difference;
}

.bg-blend-exclusion {
  background-blend-mode: exclusion;
}

.bg-blend-hue {
  background-blend-mode: hue;
}

.bg-blend-saturation {
  background-blend-mode: saturation;
}

.bg-blend-color {
  background-blend-mode: color;
}

.bg-blend-luminosity {
  background-blend-mode: luminosity;
}

.mix-blend-normal {
  mix-blend-mode: normal;
}

.mix-blend-multiply {
  mix-blend-mode: multiply;
}

.mix-blend-screen {
  mix-blend-mode: screen;
}

.mix-blend-overlay {
  mix-blend-mode: overlay;
}

.mix-blend-darken {
  mix-blend-mode: darken;
}

.mix-blend-lighten {
  mix-blend-mode: lighten;
}

.mix-blend-color-dodge {
  mix-blend-mode: color-dodge;
}

.mix-blend-color-burn {
  mix-blend-mode: color-burn;
}

.mix-blend-hard-light {
  mix-blend-mode: hard-light;
}

.mix-blend-soft-light {
  mix-blend-mode: soft-light;
}

.mix-blend-difference {
  mix-blend-mode: difference;
}

.mix-blend-exclusion {
  mix-blend-mode: exclusion;
}

.mix-blend-hue {
  mix-blend-mode: hue;
}

.mix-blend-saturation {
  mix-blend-mode: saturation;
}

.mix-blend-color {
  mix-blend-mode: color;
}

.mix-blend-luminosity {
  mix-blend-mode: luminosity;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.shadow-xs {
  --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-default {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-outline {
  --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-xs:hover {
  --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-sm:hover {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-default:hover {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-2xl:hover {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-inner:hover {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-outline:hover {
  --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-none:hover {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-xs:focus {
  --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-sm:focus {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-default:focus {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-md:focus {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-lg:focus {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-xl:focus {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-2xl:focus {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-inner:focus {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline:focus {
  --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.outline-white {
  outline: 2px dotted white;
  outline-offset: 2px;
}

.outline-black {
  outline: 2px dotted black;
  outline-offset: 2px;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:outline-white:focus {
  outline: 2px dotted white;
  outline-offset: 2px;
}

.focus\:outline-black:focus {
  outline: 2px dotted black;
  outline-offset: 2px;
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(147, 197, 253, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.ring-inset {
  --tw-ring-inset: inset;
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter-none {
  filter: none;
}

.backdrop-filter {
  --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-filter-none {
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}

.transition-none {
  transition-property: none;
}

.transition-all {
  transition-property: all;
}

.transition-default {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
}

.transition-opacity {
  transition-property: opacity;
}

.transition-shadow {
  transition-property: box-shadow;
}

.transition-transform {
  transition-property: transform;
}

.delay-75 {
  transition-delay: 75ms;
}

.delay-100 {
  transition-delay: 100ms;
}

.delay-150 {
  transition-delay: 150ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.delay-300 {
  transition-delay: 300ms;
}

.delay-500 {
  transition-delay: 500ms;
}

.delay-700 {
  transition-delay: 700ms;
}

.delay-1000 {
  transition-delay: 1000ms;
}

.duration-75 {
  transition-duration: 75ms;
}

.duration-100 {
  transition-duration: 100ms;
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-500 {
  transition-duration: 500ms;
}

.duration-700 {
  transition-duration: 700ms;
}

.duration-1000 {
  transition-duration: 1000ms;
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

/**
  * Use this directive to control where Tailwind injects the responsive
  * variations of each utility.
  *
  * If omitted, Tailwind will append these classes to the very end of
  * your stylesheet by default.
  */

@media (min-width: 1px) {
  .xs\:container {
    width: 100%;
  }

  @media (min-width: 1px) {
    .xs\:container {
      max-width: 1px;
    }
  }

  @media (min-width: 768px) {
    .xs\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .xs\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1440px) {
    .xs\:container {
      max-width: 1440px;
    }
  }

  @media (min-width: 1920px) {
    .xs\:container {
      max-width: 1920px;
    }
  }

  .xs\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .xs\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .xs\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .xs\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .xs\:pointer-events-none {
    pointer-events: none;
  }

  .xs\:pointer-events-auto {
    pointer-events: auto;
  }

  .xs\:visible {
    visibility: visible;
  }

  .xs\:invisible {
    visibility: hidden;
  }

  .xs\:static {
    position: static;
  }

  .xs\:fixed {
    position: fixed;
  }

  .xs\:absolute {
    position: absolute;
  }

  .xs\:relative {
    position: relative;
  }

  .xs\:sticky {
    position: sticky;
  }

  .xs\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .xs\:inset-60px {
    top: 60px;
    right: 60px;
    bottom: 60px;
    left: 60px;
  }

  .xs\:inset-80px {
    top: 80px;
    right: 80px;
    bottom: 80px;
    left: 80px;
  }

  .xs\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .xs\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .xs\:inset-x-60px {
    left: 60px;
    right: 60px;
  }

  .xs\:inset-x-80px {
    left: 80px;
    right: 80px;
  }

  .xs\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .xs\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .xs\:inset-y-60px {
    top: 60px;
    bottom: 60px;
  }

  .xs\:inset-y-80px {
    top: 80px;
    bottom: 80px;
  }

  .xs\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .xs\:top-0 {
    top: 0;
  }

  .xs\:top-60px {
    top: 60px;
  }

  .xs\:top-80px {
    top: 80px;
  }

  .xs\:top-auto {
    top: auto;
  }

  .xs\:right-0 {
    right: 0;
  }

  .xs\:right-60px {
    right: 60px;
  }

  .xs\:right-80px {
    right: 80px;
  }

  .xs\:right-auto {
    right: auto;
  }

  .xs\:bottom-0 {
    bottom: 0;
  }

  .xs\:bottom-60px {
    bottom: 60px;
  }

  .xs\:bottom-80px {
    bottom: 80px;
  }

  .xs\:bottom-auto {
    bottom: auto;
  }

  .xs\:left-0 {
    left: 0;
  }

  .xs\:left-60px {
    left: 60px;
  }

  .xs\:left-80px {
    left: 80px;
  }

  .xs\:left-auto {
    left: auto;
  }

  .xs\:z-0 {
    z-index: 0 !important;
  }

  .xs\:z-10 {
    z-index: 10 !important;
  }

  .xs\:z-20 {
    z-index: 20;
  }

  .xs\:z-30 {
    z-index: 30;
  }

  .xs\:z-40 {
    z-index: 40;
  }

  .xs\:z-50 {
    z-index: 50;
  }

  .xs\:z-auto {
    z-index: auto !important;
  }

  .xs\:order-1 {
    order: 1;
  }

  .xs\:order-2 {
    order: 2;
  }

  .xs\:order-3 {
    order: 3;
  }

  .xs\:order-4 {
    order: 4;
  }

  .xs\:order-5 {
    order: 5;
  }

  .xs\:order-6 {
    order: 6;
  }

  .xs\:order-7 {
    order: 7;
  }

  .xs\:order-8 {
    order: 8;
  }

  .xs\:order-9 {
    order: 9;
  }

  .xs\:order-10 {
    order: 10;
  }

  .xs\:order-11 {
    order: 11;
  }

  .xs\:order-12 {
    order: 12;
  }

  .xs\:order-first {
    order: -9999;
  }

  .xs\:order-last {
    order: 9999;
  }

  .xs\:order-none {
    order: 0;
  }

  .xs\:col-auto {
    grid-column: auto;
  }

  .xs\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xs\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xs\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xs\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xs\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xs\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xs\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xs\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xs\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .xs\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .xs\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .xs\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .xs\:col-span-full {
    grid-column: 1 / -1;
  }

  .xs\:col-start-1 {
    grid-column-start: 1;
  }

  .xs\:col-start-2 {
    grid-column-start: 2;
  }

  .xs\:col-start-3 {
    grid-column-start: 3;
  }

  .xs\:col-start-4 {
    grid-column-start: 4;
  }

  .xs\:col-start-5 {
    grid-column-start: 5;
  }

  .xs\:col-start-6 {
    grid-column-start: 6;
  }

  .xs\:col-start-7 {
    grid-column-start: 7;
  }

  .xs\:col-start-8 {
    grid-column-start: 8;
  }

  .xs\:col-start-9 {
    grid-column-start: 9;
  }

  .xs\:col-start-10 {
    grid-column-start: 10;
  }

  .xs\:col-start-11 {
    grid-column-start: 11;
  }

  .xs\:col-start-12 {
    grid-column-start: 12;
  }

  .xs\:col-start-13 {
    grid-column-start: 13;
  }

  .xs\:col-start-auto {
    grid-column-start: auto;
  }

  .xs\:col-end-1 {
    grid-column-end: 1;
  }

  .xs\:col-end-2 {
    grid-column-end: 2;
  }

  .xs\:col-end-3 {
    grid-column-end: 3;
  }

  .xs\:col-end-4 {
    grid-column-end: 4;
  }

  .xs\:col-end-5 {
    grid-column-end: 5;
  }

  .xs\:col-end-6 {
    grid-column-end: 6;
  }

  .xs\:col-end-7 {
    grid-column-end: 7;
  }

  .xs\:col-end-8 {
    grid-column-end: 8;
  }

  .xs\:col-end-9 {
    grid-column-end: 9;
  }

  .xs\:col-end-10 {
    grid-column-end: 10;
  }

  .xs\:col-end-11 {
    grid-column-end: 11;
  }

  .xs\:col-end-12 {
    grid-column-end: 12;
  }

  .xs\:col-end-13 {
    grid-column-end: 13;
  }

  .xs\:col-end-auto {
    grid-column-end: auto;
  }

  .xs\:row-auto {
    grid-row: auto;
  }

  .xs\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .xs\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .xs\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .xs\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .xs\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .xs\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .xs\:row-span-full {
    grid-row: 1 / -1;
  }

  .xs\:row-start-1 {
    grid-row-start: 1;
  }

  .xs\:row-start-2 {
    grid-row-start: 2;
  }

  .xs\:row-start-3 {
    grid-row-start: 3;
  }

  .xs\:row-start-4 {
    grid-row-start: 4;
  }

  .xs\:row-start-5 {
    grid-row-start: 5;
  }

  .xs\:row-start-6 {
    grid-row-start: 6;
  }

  .xs\:row-start-7 {
    grid-row-start: 7;
  }

  .xs\:row-start-auto {
    grid-row-start: auto;
  }

  .xs\:row-end-1 {
    grid-row-end: 1;
  }

  .xs\:row-end-2 {
    grid-row-end: 2;
  }

  .xs\:row-end-3 {
    grid-row-end: 3;
  }

  .xs\:row-end-4 {
    grid-row-end: 4;
  }

  .xs\:row-end-5 {
    grid-row-end: 5;
  }

  .xs\:row-end-6 {
    grid-row-end: 6;
  }

  .xs\:row-end-7 {
    grid-row-end: 7;
  }

  .xs\:row-end-auto {
    grid-row-end: auto;
  }

  .xs\:float-right {
    float: right;
  }

  .xs\:float-left {
    float: left;
  }

  .xs\:float-none {
    float: none;
  }

  .xs\:clear-left {
    clear: left;
  }

  .xs\:clear-right {
    clear: right;
  }

  .xs\:clear-both {
    clear: both;
  }

  .xs\:clear-none {
    clear: none;
  }

  .xs\:m-0 {
    margin: 0;
  }

  .xs\:m-1 {
    margin: 0.25rem;
  }

  .xs\:m-2 {
    margin: 0.5rem;
  }

  .xs\:m-3 {
    margin: 0.75rem;
  }

  .xs\:m-4 {
    margin: 1rem;
  }

  .xs\:m-5 {
    margin: 1.25rem;
  }

  .xs\:m-6 {
    margin: 1.5rem;
  }

  .xs\:m-8 {
    margin: 2rem;
  }

  .xs\:m-10 {
    margin: 2.5rem;
  }

  .xs\:m-12 {
    margin: 3rem;
  }

  .xs\:m-16 {
    margin: 4rem;
  }

  .xs\:m-20 {
    margin: 5rem;
  }

  .xs\:m-24 {
    margin: 6rem;
  }

  .xs\:m-32 {
    margin: 8rem;
  }

  .xs\:m-40 {
    margin: 10rem;
  }

  .xs\:m-48 {
    margin: 12rem;
  }

  .xs\:m-56 {
    margin: 14rem;
  }

  .xs\:m-64 {
    margin: 16rem;
  }

  .xs\:m-auto {
    margin: auto;
  }

  .xs\:m-300px {
    margin: 300px;
  }

  .xs\:m-230px {
    margin: 230px;
  }

  .xs\:m-180px {
    margin: 180px;
  }

  .xs\:m-147px {
    margin: 147px;
  }

  .xs\:m-123px {
    margin: 123px;
  }

  .xs\:m-100px {
    margin: 100px;
  }

  .xs\:m-80px {
    margin: 80px;
  }

  .xs\:m-60px {
    margin: 60px;
  }

  .xs\:m-50px {
    margin: 50px;
  }

  .xs\:m-40px {
    margin: 40px;
  }

  .xs\:m-35px {
    margin: 35px;
  }

  .xs\:m-34px-i {
    margin: 34px !important;
  }

  .xs\:m-30px-i {
    margin: 30px !important;
  }

  .xs\:m-24px-i {
    margin: 24px !important;
  }

  .xs\:m-20px-i {
    margin: 20px !important;
  }

  .xs\:m-15px-i {
    margin: 15px !important;
  }

  .xs\:m-13px-i {
    margin: 13px !important;
  }

  .xs\:m-12px-i {
    margin: 12px !important;
  }

  .xs\:m-10px-i {
    margin: 10px !important;
  }

  .xs\:m-8px-i {
    margin: 8px !important;
  }

  .xs\:m-6px-i {
    margin: 6px !important;
  }

  .xs\:m-5px-i {
    margin: 5px !important;
  }

  .xs\:m-3px-i {
    margin: 3px !important;
  }

  .xs\:m-0px-i {
    margin: 0px !important;
  }

  .xs\:m-34px {
    margin: 34px;
  }

  .xs\:m-30px {
    margin: 30px;
  }

  .xs\:m-24px {
    margin: 24px;
  }

  .xs\:m-20px {
    margin: 20px;
  }

  .xs\:m-15px {
    margin: 15px;
  }

  .xs\:m-13px {
    margin: 13px;
  }

  .xs\:m-12px {
    margin: 12px;
  }

  .xs\:m-10px {
    margin: 10px;
  }

  .xs\:m-8px {
    margin: 8px;
  }

  .xs\:m-6px {
    margin: 6px;
  }

  .xs\:m-5px {
    margin: 5px;
  }

  .xs\:m-3px {
    margin: 3px;
  }

  .xs\:m-menu {
    margin: 275px;
  }

  .xs\:m-px {
    margin: 1px !important;
  }

  .xs\:-m-1 {
    margin: -0.25rem;
  }

  .xs\:-m-2 {
    margin: -0.5rem;
  }

  .xs\:-m-3 {
    margin: -0.75rem;
  }

  .xs\:-m-4 {
    margin: -1rem;
  }

  .xs\:-m-5 {
    margin: -1.25rem;
  }

  .xs\:-m-6 {
    margin: -1.5rem;
  }

  .xs\:-m-8 {
    margin: -2rem;
  }

  .xs\:-m-10 {
    margin: -2.5rem;
  }

  .xs\:-m-12 {
    margin: -3rem;
  }

  .xs\:-m-16 {
    margin: -4rem;
  }

  .xs\:-m-20 {
    margin: -5rem;
  }

  .xs\:-m-24 {
    margin: -6rem;
  }

  .xs\:-m-32 {
    margin: -8rem;
  }

  .xs\:-m-40 {
    margin: -10rem;
  }

  .xs\:-m-48 {
    margin: -12rem;
  }

  .xs\:-m-56 {
    margin: -14rem;
  }

  .xs\:-m-64 {
    margin: -16rem;
  }

  .xs\:-m-300px {
    margin: -300px;
  }

  .xs\:-m-230px {
    margin: -230px;
  }

  .xs\:-m-180px {
    margin: -180px;
  }

  .xs\:-m-147px {
    margin: -147px;
  }

  .xs\:-m-123px {
    margin: -123px;
  }

  .xs\:-m-100px {
    margin: -100px;
  }

  .xs\:-m-80px {
    margin: -80px;
  }

  .xs\:-m-60px {
    margin: -60px;
  }

  .xs\:-m-50px {
    margin: -50px;
  }

  .xs\:-m-40px {
    margin: -40px;
  }

  .xs\:-m-35px {
    margin: -35px;
  }

  .xs\:-m-34px-i {
    margin: 34px !important;
  }

  .xs\:-m-30px-i {
    margin: 30px !important;
  }

  .xs\:-m-24px-i {
    margin: 24px !important;
  }

  .xs\:-m-20px-i {
    margin: 20px !important;
  }

  .xs\:-m-15px-i {
    margin: 15px !important;
  }

  .xs\:-m-13px-i {
    margin: 13px !important;
  }

  .xs\:-m-12px-i {
    margin: 12px !important;
  }

  .xs\:-m-10px-i {
    margin: 10px !important;
  }

  .xs\:-m-8px-i {
    margin: 8px !important;
  }

  .xs\:-m-6px-i {
    margin: 6px !important;
  }

  .xs\:-m-5px-i {
    margin: 5px !important;
  }

  .xs\:-m-3px-i {
    margin: 3px !important;
  }

  .xs\:-m-0px-i {
    margin: 0px !important;
  }

  .xs\:-m-34px {
    margin: -34px;
  }

  .xs\:-m-30px {
    margin: -30px;
  }

  .xs\:-m-24px {
    margin: -24px;
  }

  .xs\:-m-20px {
    margin: -20px;
  }

  .xs\:-m-15px {
    margin: -15px;
  }

  .xs\:-m-13px {
    margin: -13px;
  }

  .xs\:-m-12px {
    margin: -12px;
  }

  .xs\:-m-10px {
    margin: -10px;
  }

  .xs\:-m-8px {
    margin: -8px;
  }

  .xs\:-m-6px {
    margin: -6px;
  }

  .xs\:-m-5px {
    margin: -5px;
  }

  .xs\:-m-3px {
    margin: -3px;
  }

  .xs\:-m-menu {
    margin: -275px;
  }

  .xs\:-m-px {
    margin: 1px !important;
  }

  .xs\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xs\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .xs\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .xs\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .xs\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xs\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .xs\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xs\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xs\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .xs\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .xs\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .xs\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .xs\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .xs\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .xs\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .xs\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .xs\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .xs\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .xs\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xs\:mx-300px {
    margin-left: 300px;
    margin-right: 300px;
  }

  .xs\:mx-230px {
    margin-left: 230px;
    margin-right: 230px;
  }

  .xs\:mx-180px {
    margin-left: 180px;
    margin-right: 180px;
  }

  .xs\:mx-147px {
    margin-left: 147px;
    margin-right: 147px;
  }

  .xs\:mx-123px {
    margin-left: 123px;
    margin-right: 123px;
  }

  .xs\:mx-100px {
    margin-left: 100px;
    margin-right: 100px;
  }

  .xs\:mx-80px {
    margin-left: 80px;
    margin-right: 80px;
  }

  .xs\:mx-60px {
    margin-left: 60px;
    margin-right: 60px;
  }

  .xs\:mx-50px {
    margin-left: 50px;
    margin-right: 50px;
  }

  .xs\:mx-40px {
    margin-left: 40px;
    margin-right: 40px;
  }

  .xs\:mx-35px {
    margin-left: 35px;
    margin-right: 35px;
  }

  .xs\:mx-34px-i {
    margin-left: 34px !important;
    margin-right: 34px !important;
  }

  .xs\:mx-30px-i {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .xs\:mx-24px-i {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .xs\:mx-20px-i {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .xs\:mx-15px-i {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .xs\:mx-13px-i {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }

  .xs\:mx-12px-i {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .xs\:mx-10px-i {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .xs\:mx-8px-i {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .xs\:mx-6px-i {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .xs\:mx-5px-i {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .xs\:mx-3px-i {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .xs\:mx-0px-i {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .xs\:mx-34px {
    margin-left: 34px;
    margin-right: 34px;
  }

  .xs\:mx-30px {
    margin-left: 30px;
    margin-right: 30px;
  }

  .xs\:mx-24px {
    margin-left: 24px;
    margin-right: 24px;
  }

  .xs\:mx-20px {
    margin-left: 20px;
    margin-right: 20px;
  }

  .xs\:mx-15px {
    margin-left: 15px;
    margin-right: 15px;
  }

  .xs\:mx-13px {
    margin-left: 13px;
    margin-right: 13px;
  }

  .xs\:mx-12px {
    margin-left: 12px;
    margin-right: 12px;
  }

  .xs\:mx-10px {
    margin-left: 10px;
    margin-right: 10px;
  }

  .xs\:mx-8px {
    margin-left: 8px;
    margin-right: 8px;
  }

  .xs\:mx-6px {
    margin-left: 6px;
    margin-right: 6px;
  }

  .xs\:mx-5px {
    margin-left: 5px;
    margin-right: 5px;
  }

  .xs\:mx-3px {
    margin-left: 3px;
    margin-right: 3px;
  }

  .xs\:mx-menu {
    margin-left: 275px;
    margin-right: 275px;
  }

  .xs\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .xs\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .xs\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .xs\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .xs\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .xs\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .xs\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .xs\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .xs\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .xs\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .xs\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .xs\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .xs\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .xs\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .xs\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .xs\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .xs\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .xs\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .xs\:-mx-300px {
    margin-left: -300px;
    margin-right: -300px;
  }

  .xs\:-mx-230px {
    margin-left: -230px;
    margin-right: -230px;
  }

  .xs\:-mx-180px {
    margin-left: -180px;
    margin-right: -180px;
  }

  .xs\:-mx-147px {
    margin-left: -147px;
    margin-right: -147px;
  }

  .xs\:-mx-123px {
    margin-left: -123px;
    margin-right: -123px;
  }

  .xs\:-mx-100px {
    margin-left: -100px;
    margin-right: -100px;
  }

  .xs\:-mx-80px {
    margin-left: -80px;
    margin-right: -80px;
  }

  .xs\:-mx-60px {
    margin-left: -60px;
    margin-right: -60px;
  }

  .xs\:-mx-50px {
    margin-left: -50px;
    margin-right: -50px;
  }

  .xs\:-mx-40px {
    margin-left: -40px;
    margin-right: -40px;
  }

  .xs\:-mx-35px {
    margin-left: -35px;
    margin-right: -35px;
  }

  .xs\:-mx-34px-i {
    margin-left: 34px !important;
    margin-right: 34px !important;
  }

  .xs\:-mx-30px-i {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .xs\:-mx-24px-i {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .xs\:-mx-20px-i {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .xs\:-mx-15px-i {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .xs\:-mx-13px-i {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }

  .xs\:-mx-12px-i {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .xs\:-mx-10px-i {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .xs\:-mx-8px-i {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .xs\:-mx-6px-i {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .xs\:-mx-5px-i {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .xs\:-mx-3px-i {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .xs\:-mx-0px-i {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .xs\:-mx-34px {
    margin-left: -34px;
    margin-right: -34px;
  }

  .xs\:-mx-30px {
    margin-left: -30px;
    margin-right: -30px;
  }

  .xs\:-mx-24px {
    margin-left: -24px;
    margin-right: -24px;
  }

  .xs\:-mx-20px {
    margin-left: -20px;
    margin-right: -20px;
  }

  .xs\:-mx-15px {
    margin-left: -15px;
    margin-right: -15px;
  }

  .xs\:-mx-13px {
    margin-left: -13px;
    margin-right: -13px;
  }

  .xs\:-mx-12px {
    margin-left: -12px;
    margin-right: -12px;
  }

  .xs\:-mx-10px {
    margin-left: -10px;
    margin-right: -10px;
  }

  .xs\:-mx-8px {
    margin-left: -8px;
    margin-right: -8px;
  }

  .xs\:-mx-6px {
    margin-left: -6px;
    margin-right: -6px;
  }

  .xs\:-mx-5px {
    margin-left: -5px;
    margin-right: -5px;
  }

  .xs\:-mx-3px {
    margin-left: -3px;
    margin-right: -3px;
  }

  .xs\:-mx-menu {
    margin-left: -275px;
    margin-right: -275px;
  }

  .xs\:-mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .xs\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xs\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .xs\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .xs\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .xs\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .xs\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .xs\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .xs\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xs\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .xs\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .xs\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .xs\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .xs\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .xs\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .xs\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .xs\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .xs\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .xs\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .xs\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .xs\:my-300px {
    margin-top: 300px;
    margin-bottom: 300px;
  }

  .xs\:my-230px {
    margin-top: 230px;
    margin-bottom: 230px;
  }

  .xs\:my-180px {
    margin-top: 180px;
    margin-bottom: 180px;
  }

  .xs\:my-147px {
    margin-top: 147px;
    margin-bottom: 147px;
  }

  .xs\:my-123px {
    margin-top: 123px;
    margin-bottom: 123px;
  }

  .xs\:my-100px {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .xs\:my-80px {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .xs\:my-60px {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .xs\:my-50px {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .xs\:my-40px {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .xs\:my-35px {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .xs\:my-34px-i {
    margin-top: 34px !important;
    margin-bottom: 34px !important;
  }

  .xs\:my-30px-i {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .xs\:my-24px-i {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .xs\:my-20px-i {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .xs\:my-15px-i {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .xs\:my-13px-i {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }

  .xs\:my-12px-i {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .xs\:my-10px-i {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .xs\:my-8px-i {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .xs\:my-6px-i {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .xs\:my-5px-i {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .xs\:my-3px-i {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .xs\:my-0px-i {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .xs\:my-34px {
    margin-top: 34px;
    margin-bottom: 34px;
  }

  .xs\:my-30px {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .xs\:my-24px {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .xs\:my-20px {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .xs\:my-15px {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .xs\:my-13px {
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .xs\:my-12px {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .xs\:my-10px {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .xs\:my-8px {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .xs\:my-6px {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .xs\:my-5px {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .xs\:my-3px {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .xs\:my-menu {
    margin-top: 275px;
    margin-bottom: 275px;
  }

  .xs\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .xs\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .xs\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .xs\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .xs\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .xs\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .xs\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .xs\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .xs\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .xs\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .xs\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .xs\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .xs\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .xs\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .xs\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .xs\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .xs\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .xs\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .xs\:-my-300px {
    margin-top: -300px;
    margin-bottom: -300px;
  }

  .xs\:-my-230px {
    margin-top: -230px;
    margin-bottom: -230px;
  }

  .xs\:-my-180px {
    margin-top: -180px;
    margin-bottom: -180px;
  }

  .xs\:-my-147px {
    margin-top: -147px;
    margin-bottom: -147px;
  }

  .xs\:-my-123px {
    margin-top: -123px;
    margin-bottom: -123px;
  }

  .xs\:-my-100px {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .xs\:-my-80px {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .xs\:-my-60px {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .xs\:-my-50px {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .xs\:-my-40px {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .xs\:-my-35px {
    margin-top: -35px;
    margin-bottom: -35px;
  }

  .xs\:-my-34px-i {
    margin-top: 34px !important;
    margin-bottom: 34px !important;
  }

  .xs\:-my-30px-i {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .xs\:-my-24px-i {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .xs\:-my-20px-i {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .xs\:-my-15px-i {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .xs\:-my-13px-i {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }

  .xs\:-my-12px-i {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .xs\:-my-10px-i {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .xs\:-my-8px-i {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .xs\:-my-6px-i {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .xs\:-my-5px-i {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .xs\:-my-3px-i {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .xs\:-my-0px-i {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .xs\:-my-34px {
    margin-top: -34px;
    margin-bottom: -34px;
  }

  .xs\:-my-30px {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .xs\:-my-24px {
    margin-top: -24px;
    margin-bottom: -24px;
  }

  .xs\:-my-20px {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .xs\:-my-15px {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .xs\:-my-13px {
    margin-top: -13px;
    margin-bottom: -13px;
  }

  .xs\:-my-12px {
    margin-top: -12px;
    margin-bottom: -12px;
  }

  .xs\:-my-10px {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .xs\:-my-8px {
    margin-top: -8px;
    margin-bottom: -8px;
  }

  .xs\:-my-6px {
    margin-top: -6px;
    margin-bottom: -6px;
  }

  .xs\:-my-5px {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .xs\:-my-3px {
    margin-top: -3px;
    margin-bottom: -3px;
  }

  .xs\:-my-menu {
    margin-top: -275px;
    margin-bottom: -275px;
  }

  .xs\:-my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .xs\:mt-0 {
    margin-top: 0;
  }

  .xs\:mt-1 {
    margin-top: 0.25rem;
  }

  .xs\:mt-2 {
    margin-top: 0.5rem;
  }

  .xs\:mt-3 {
    margin-top: 0.75rem;
  }

  .xs\:mt-4 {
    margin-top: 1rem;
  }

  .xs\:mt-5 {
    margin-top: 1.25rem;
  }

  .xs\:mt-6 {
    margin-top: 1.5rem;
  }

  .xs\:mt-8 {
    margin-top: 2rem;
  }

  .xs\:mt-10 {
    margin-top: 2.5rem;
  }

  .xs\:mt-12 {
    margin-top: 3rem;
  }

  .xs\:mt-16 {
    margin-top: 4rem;
  }

  .xs\:mt-20 {
    margin-top: 5rem;
  }

  .xs\:mt-24 {
    margin-top: 6rem;
  }

  .xs\:mt-32 {
    margin-top: 8rem;
  }

  .xs\:mt-40 {
    margin-top: 10rem;
  }

  .xs\:mt-48 {
    margin-top: 12rem;
  }

  .xs\:mt-56 {
    margin-top: 14rem;
  }

  .xs\:mt-64 {
    margin-top: 16rem;
  }

  .xs\:mt-auto {
    margin-top: auto;
  }

  .xs\:mt-300px {
    margin-top: 300px;
  }

  .xs\:mt-230px {
    margin-top: 230px;
  }

  .xs\:mt-180px {
    margin-top: 180px;
  }

  .xs\:mt-147px {
    margin-top: 147px;
  }

  .xs\:mt-123px {
    margin-top: 123px;
  }

  .xs\:mt-100px {
    margin-top: 100px;
  }

  .xs\:mt-80px {
    margin-top: 80px;
  }

  .xs\:mt-60px {
    margin-top: 60px;
  }

  .xs\:mt-50px {
    margin-top: 50px;
  }

  .xs\:mt-40px {
    margin-top: 40px;
  }

  .xs\:mt-35px {
    margin-top: 35px;
  }

  .xs\:mt-34px-i {
    margin-top: 34px !important;
  }

  .xs\:mt-30px-i {
    margin-top: 30px !important;
  }

  .xs\:mt-24px-i {
    margin-top: 24px !important;
  }

  .xs\:mt-20px-i {
    margin-top: 20px !important;
  }

  .xs\:mt-15px-i {
    margin-top: 15px !important;
  }

  .xs\:mt-13px-i {
    margin-top: 13px !important;
  }

  .xs\:mt-12px-i {
    margin-top: 12px !important;
  }

  .xs\:mt-10px-i {
    margin-top: 10px !important;
  }

  .xs\:mt-8px-i {
    margin-top: 8px !important;
  }

  .xs\:mt-6px-i {
    margin-top: 6px !important;
  }

  .xs\:mt-5px-i {
    margin-top: 5px !important;
  }

  .xs\:mt-3px-i {
    margin-top: 3px !important;
  }

  .xs\:mt-0px-i {
    margin-top: 0px !important;
  }

  .xs\:mt-34px {
    margin-top: 34px;
  }

  .xs\:mt-30px {
    margin-top: 30px;
  }

  .xs\:mt-24px {
    margin-top: 24px;
  }

  .xs\:mt-20px {
    margin-top: 20px;
  }

  .xs\:mt-15px {
    margin-top: 15px;
  }

  .xs\:mt-13px {
    margin-top: 13px;
  }

  .xs\:mt-12px {
    margin-top: 12px;
  }

  .xs\:mt-10px {
    margin-top: 10px;
  }

  .xs\:mt-8px {
    margin-top: 8px;
  }

  .xs\:mt-6px {
    margin-top: 6px;
  }

  .xs\:mt-5px {
    margin-top: 5px;
  }

  .xs\:mt-3px {
    margin-top: 3px;
  }

  .xs\:mt-menu {
    margin-top: 275px;
  }

  .xs\:mt-px {
    margin-top: 1px !important;
  }

  .xs\:-mt-1 {
    margin-top: -0.25rem;
  }

  .xs\:-mt-2 {
    margin-top: -0.5rem;
  }

  .xs\:-mt-3 {
    margin-top: -0.75rem;
  }

  .xs\:-mt-4 {
    margin-top: -1rem;
  }

  .xs\:-mt-5 {
    margin-top: -1.25rem;
  }

  .xs\:-mt-6 {
    margin-top: -1.5rem;
  }

  .xs\:-mt-8 {
    margin-top: -2rem;
  }

  .xs\:-mt-10 {
    margin-top: -2.5rem;
  }

  .xs\:-mt-12 {
    margin-top: -3rem;
  }

  .xs\:-mt-16 {
    margin-top: -4rem;
  }

  .xs\:-mt-20 {
    margin-top: -5rem;
  }

  .xs\:-mt-24 {
    margin-top: -6rem;
  }

  .xs\:-mt-32 {
    margin-top: -8rem;
  }

  .xs\:-mt-40 {
    margin-top: -10rem;
  }

  .xs\:-mt-48 {
    margin-top: -12rem;
  }

  .xs\:-mt-56 {
    margin-top: -14rem;
  }

  .xs\:-mt-64 {
    margin-top: -16rem;
  }

  .xs\:-mt-300px {
    margin-top: -300px;
  }

  .xs\:-mt-230px {
    margin-top: -230px;
  }

  .xs\:-mt-180px {
    margin-top: -180px;
  }

  .xs\:-mt-147px {
    margin-top: -147px;
  }

  .xs\:-mt-123px {
    margin-top: -123px;
  }

  .xs\:-mt-100px {
    margin-top: -100px;
  }

  .xs\:-mt-80px {
    margin-top: -80px;
  }

  .xs\:-mt-60px {
    margin-top: -60px;
  }

  .xs\:-mt-50px {
    margin-top: -50px;
  }

  .xs\:-mt-40px {
    margin-top: -40px;
  }

  .xs\:-mt-35px {
    margin-top: -35px;
  }

  .xs\:-mt-34px-i {
    margin-top: 34px !important;
  }

  .xs\:-mt-30px-i {
    margin-top: 30px !important;
  }

  .xs\:-mt-24px-i {
    margin-top: 24px !important;
  }

  .xs\:-mt-20px-i {
    margin-top: 20px !important;
  }

  .xs\:-mt-15px-i {
    margin-top: 15px !important;
  }

  .xs\:-mt-13px-i {
    margin-top: 13px !important;
  }

  .xs\:-mt-12px-i {
    margin-top: 12px !important;
  }

  .xs\:-mt-10px-i {
    margin-top: 10px !important;
  }

  .xs\:-mt-8px-i {
    margin-top: 8px !important;
  }

  .xs\:-mt-6px-i {
    margin-top: 6px !important;
  }

  .xs\:-mt-5px-i {
    margin-top: 5px !important;
  }

  .xs\:-mt-3px-i {
    margin-top: 3px !important;
  }

  .xs\:-mt-0px-i {
    margin-top: 0px !important;
  }

  .xs\:-mt-34px {
    margin-top: -34px;
  }

  .xs\:-mt-30px {
    margin-top: -30px;
  }

  .xs\:-mt-24px {
    margin-top: -24px;
  }

  .xs\:-mt-20px {
    margin-top: -20px;
  }

  .xs\:-mt-15px {
    margin-top: -15px;
  }

  .xs\:-mt-13px {
    margin-top: -13px;
  }

  .xs\:-mt-12px {
    margin-top: -12px;
  }

  .xs\:-mt-10px {
    margin-top: -10px;
  }

  .xs\:-mt-8px {
    margin-top: -8px;
  }

  .xs\:-mt-6px {
    margin-top: -6px;
  }

  .xs\:-mt-5px {
    margin-top: -5px;
  }

  .xs\:-mt-3px {
    margin-top: -3px;
  }

  .xs\:-mt-menu {
    margin-top: -275px;
  }

  .xs\:-mt-px {
    margin-top: 1px !important;
  }

  .xs\:mr-0 {
    margin-right: 0;
  }

  .xs\:mr-1 {
    margin-right: 0.25rem;
  }

  .xs\:mr-2 {
    margin-right: 0.5rem;
  }

  .xs\:mr-3 {
    margin-right: 0.75rem;
  }

  .xs\:mr-4 {
    margin-right: 1rem;
  }

  .xs\:mr-5 {
    margin-right: 1.25rem;
  }

  .xs\:mr-6 {
    margin-right: 1.5rem;
  }

  .xs\:mr-8 {
    margin-right: 2rem;
  }

  .xs\:mr-10 {
    margin-right: 2.5rem;
  }

  .xs\:mr-12 {
    margin-right: 3rem;
  }

  .xs\:mr-16 {
    margin-right: 4rem;
  }

  .xs\:mr-20 {
    margin-right: 5rem;
  }

  .xs\:mr-24 {
    margin-right: 6rem;
  }

  .xs\:mr-32 {
    margin-right: 8rem;
  }

  .xs\:mr-40 {
    margin-right: 10rem;
  }

  .xs\:mr-48 {
    margin-right: 12rem;
  }

  .xs\:mr-56 {
    margin-right: 14rem;
  }

  .xs\:mr-64 {
    margin-right: 16rem;
  }

  .xs\:mr-auto {
    margin-right: auto;
  }

  .xs\:mr-300px {
    margin-right: 300px;
  }

  .xs\:mr-230px {
    margin-right: 230px;
  }

  .xs\:mr-180px {
    margin-right: 180px;
  }

  .xs\:mr-147px {
    margin-right: 147px;
  }

  .xs\:mr-123px {
    margin-right: 123px;
  }

  .xs\:mr-100px {
    margin-right: 100px;
  }

  .xs\:mr-80px {
    margin-right: 80px;
  }

  .xs\:mr-60px {
    margin-right: 60px;
  }

  .xs\:mr-50px {
    margin-right: 50px;
  }

  .xs\:mr-40px {
    margin-right: 40px;
  }

  .xs\:mr-35px {
    margin-right: 35px;
  }

  .xs\:mr-34px-i {
    margin-right: 34px !important;
  }

  .xs\:mr-30px-i {
    margin-right: 30px !important;
  }

  .xs\:mr-24px-i {
    margin-right: 24px !important;
  }

  .xs\:mr-20px-i {
    margin-right: 20px !important;
  }

  .xs\:mr-15px-i {
    margin-right: 15px !important;
  }

  .xs\:mr-13px-i {
    margin-right: 13px !important;
  }

  .xs\:mr-12px-i {
    margin-right: 12px !important;
  }

  .xs\:mr-10px-i {
    margin-right: 10px !important;
  }

  .xs\:mr-8px-i {
    margin-right: 8px !important;
  }

  .xs\:mr-6px-i {
    margin-right: 6px !important;
  }

  .xs\:mr-5px-i {
    margin-right: 5px !important;
  }

  .xs\:mr-3px-i {
    margin-right: 3px !important;
  }

  .xs\:mr-0px-i {
    margin-right: 0px !important;
  }

  .xs\:mr-34px {
    margin-right: 34px;
  }

  .xs\:mr-30px {
    margin-right: 30px;
  }

  .xs\:mr-24px {
    margin-right: 24px;
  }

  .xs\:mr-20px {
    margin-right: 20px;
  }

  .xs\:mr-15px {
    margin-right: 15px;
  }

  .xs\:mr-13px {
    margin-right: 13px;
  }

  .xs\:mr-12px {
    margin-right: 12px;
  }

  .xs\:mr-10px {
    margin-right: 10px;
  }

  .xs\:mr-8px {
    margin-right: 8px;
  }

  .xs\:mr-6px {
    margin-right: 6px;
  }

  .xs\:mr-5px {
    margin-right: 5px;
  }

  .xs\:mr-3px {
    margin-right: 3px;
  }

  .xs\:mr-menu {
    margin-right: 275px;
  }

  .xs\:mr-px {
    margin-right: 1px !important;
  }

  .xs\:-mr-1 {
    margin-right: -0.25rem;
  }

  .xs\:-mr-2 {
    margin-right: -0.5rem;
  }

  .xs\:-mr-3 {
    margin-right: -0.75rem;
  }

  .xs\:-mr-4 {
    margin-right: -1rem;
  }

  .xs\:-mr-5 {
    margin-right: -1.25rem;
  }

  .xs\:-mr-6 {
    margin-right: -1.5rem;
  }

  .xs\:-mr-8 {
    margin-right: -2rem;
  }

  .xs\:-mr-10 {
    margin-right: -2.5rem;
  }

  .xs\:-mr-12 {
    margin-right: -3rem;
  }

  .xs\:-mr-16 {
    margin-right: -4rem;
  }

  .xs\:-mr-20 {
    margin-right: -5rem;
  }

  .xs\:-mr-24 {
    margin-right: -6rem;
  }

  .xs\:-mr-32 {
    margin-right: -8rem;
  }

  .xs\:-mr-40 {
    margin-right: -10rem;
  }

  .xs\:-mr-48 {
    margin-right: -12rem;
  }

  .xs\:-mr-56 {
    margin-right: -14rem;
  }

  .xs\:-mr-64 {
    margin-right: -16rem;
  }

  .xs\:-mr-300px {
    margin-right: -300px;
  }

  .xs\:-mr-230px {
    margin-right: -230px;
  }

  .xs\:-mr-180px {
    margin-right: -180px;
  }

  .xs\:-mr-147px {
    margin-right: -147px;
  }

  .xs\:-mr-123px {
    margin-right: -123px;
  }

  .xs\:-mr-100px {
    margin-right: -100px;
  }

  .xs\:-mr-80px {
    margin-right: -80px;
  }

  .xs\:-mr-60px {
    margin-right: -60px;
  }

  .xs\:-mr-50px {
    margin-right: -50px;
  }

  .xs\:-mr-40px {
    margin-right: -40px;
  }

  .xs\:-mr-35px {
    margin-right: -35px;
  }

  .xs\:-mr-34px-i {
    margin-right: 34px !important;
  }

  .xs\:-mr-30px-i {
    margin-right: 30px !important;
  }

  .xs\:-mr-24px-i {
    margin-right: 24px !important;
  }

  .xs\:-mr-20px-i {
    margin-right: 20px !important;
  }

  .xs\:-mr-15px-i {
    margin-right: 15px !important;
  }

  .xs\:-mr-13px-i {
    margin-right: 13px !important;
  }

  .xs\:-mr-12px-i {
    margin-right: 12px !important;
  }

  .xs\:-mr-10px-i {
    margin-right: 10px !important;
  }

  .xs\:-mr-8px-i {
    margin-right: 8px !important;
  }

  .xs\:-mr-6px-i {
    margin-right: 6px !important;
  }

  .xs\:-mr-5px-i {
    margin-right: 5px !important;
  }

  .xs\:-mr-3px-i {
    margin-right: 3px !important;
  }

  .xs\:-mr-0px-i {
    margin-right: 0px !important;
  }

  .xs\:-mr-34px {
    margin-right: -34px;
  }

  .xs\:-mr-30px {
    margin-right: -30px;
  }

  .xs\:-mr-24px {
    margin-right: -24px;
  }

  .xs\:-mr-20px {
    margin-right: -20px;
  }

  .xs\:-mr-15px {
    margin-right: -15px;
  }

  .xs\:-mr-13px {
    margin-right: -13px;
  }

  .xs\:-mr-12px {
    margin-right: -12px;
  }

  .xs\:-mr-10px {
    margin-right: -10px;
  }

  .xs\:-mr-8px {
    margin-right: -8px;
  }

  .xs\:-mr-6px {
    margin-right: -6px;
  }

  .xs\:-mr-5px {
    margin-right: -5px;
  }

  .xs\:-mr-3px {
    margin-right: -3px;
  }

  .xs\:-mr-menu {
    margin-right: -275px;
  }

  .xs\:-mr-px {
    margin-right: 1px !important;
  }

  .xs\:mb-0 {
    margin-bottom: 0;
  }

  .xs\:mb-1 {
    margin-bottom: 0.25rem;
  }

  .xs\:mb-2 {
    margin-bottom: 0.5rem;
  }

  .xs\:mb-3 {
    margin-bottom: 0.75rem;
  }

  .xs\:mb-4 {
    margin-bottom: 1rem;
  }

  .xs\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .xs\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .xs\:mb-8 {
    margin-bottom: 2rem;
  }

  .xs\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .xs\:mb-12 {
    margin-bottom: 3rem;
  }

  .xs\:mb-16 {
    margin-bottom: 4rem;
  }

  .xs\:mb-20 {
    margin-bottom: 5rem;
  }

  .xs\:mb-24 {
    margin-bottom: 6rem;
  }

  .xs\:mb-32 {
    margin-bottom: 8rem;
  }

  .xs\:mb-40 {
    margin-bottom: 10rem;
  }

  .xs\:mb-48 {
    margin-bottom: 12rem;
  }

  .xs\:mb-56 {
    margin-bottom: 14rem;
  }

  .xs\:mb-64 {
    margin-bottom: 16rem;
  }

  .xs\:mb-auto {
    margin-bottom: auto;
  }

  .xs\:mb-300px {
    margin-bottom: 300px;
  }

  .xs\:mb-230px {
    margin-bottom: 230px;
  }

  .xs\:mb-180px {
    margin-bottom: 180px;
  }

  .xs\:mb-147px {
    margin-bottom: 147px;
  }

  .xs\:mb-123px {
    margin-bottom: 123px;
  }

  .xs\:mb-100px {
    margin-bottom: 100px;
  }

  .xs\:mb-80px {
    margin-bottom: 80px;
  }

  .xs\:mb-60px {
    margin-bottom: 60px;
  }

  .xs\:mb-50px {
    margin-bottom: 50px;
  }

  .xs\:mb-40px {
    margin-bottom: 40px;
  }

  .xs\:mb-35px {
    margin-bottom: 35px;
  }

  .xs\:mb-34px-i {
    margin-bottom: 34px !important;
  }

  .xs\:mb-30px-i {
    margin-bottom: 30px !important;
  }

  .xs\:mb-24px-i {
    margin-bottom: 24px !important;
  }

  .xs\:mb-20px-i {
    margin-bottom: 20px !important;
  }

  .xs\:mb-15px-i {
    margin-bottom: 15px !important;
  }

  .xs\:mb-13px-i {
    margin-bottom: 13px !important;
  }

  .xs\:mb-12px-i {
    margin-bottom: 12px !important;
  }

  .xs\:mb-10px-i {
    margin-bottom: 10px !important;
  }

  .xs\:mb-8px-i {
    margin-bottom: 8px !important;
  }

  .xs\:mb-6px-i {
    margin-bottom: 6px !important;
  }

  .xs\:mb-5px-i {
    margin-bottom: 5px !important;
  }

  .xs\:mb-3px-i {
    margin-bottom: 3px !important;
  }

  .xs\:mb-0px-i {
    margin-bottom: 0px !important;
  }

  .xs\:mb-34px {
    margin-bottom: 34px;
  }

  .xs\:mb-30px {
    margin-bottom: 30px;
  }

  .xs\:mb-24px {
    margin-bottom: 24px;
  }

  .xs\:mb-20px {
    margin-bottom: 20px;
  }

  .xs\:mb-15px {
    margin-bottom: 15px;
  }

  .xs\:mb-13px {
    margin-bottom: 13px;
  }

  .xs\:mb-12px {
    margin-bottom: 12px;
  }

  .xs\:mb-10px {
    margin-bottom: 10px;
  }

  .xs\:mb-8px {
    margin-bottom: 8px;
  }

  .xs\:mb-6px {
    margin-bottom: 6px;
  }

  .xs\:mb-5px {
    margin-bottom: 5px;
  }

  .xs\:mb-3px {
    margin-bottom: 3px;
  }

  .xs\:mb-menu {
    margin-bottom: 275px;
  }

  .xs\:mb-px {
    margin-bottom: 1px !important;
  }

  .xs\:-mb-1 {
    margin-bottom: -0.25rem;
  }

  .xs\:-mb-2 {
    margin-bottom: -0.5rem;
  }

  .xs\:-mb-3 {
    margin-bottom: -0.75rem;
  }

  .xs\:-mb-4 {
    margin-bottom: -1rem;
  }

  .xs\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .xs\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .xs\:-mb-8 {
    margin-bottom: -2rem;
  }

  .xs\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .xs\:-mb-12 {
    margin-bottom: -3rem;
  }

  .xs\:-mb-16 {
    margin-bottom: -4rem;
  }

  .xs\:-mb-20 {
    margin-bottom: -5rem;
  }

  .xs\:-mb-24 {
    margin-bottom: -6rem;
  }

  .xs\:-mb-32 {
    margin-bottom: -8rem;
  }

  .xs\:-mb-40 {
    margin-bottom: -10rem;
  }

  .xs\:-mb-48 {
    margin-bottom: -12rem;
  }

  .xs\:-mb-56 {
    margin-bottom: -14rem;
  }

  .xs\:-mb-64 {
    margin-bottom: -16rem;
  }

  .xs\:-mb-300px {
    margin-bottom: -300px;
  }

  .xs\:-mb-230px {
    margin-bottom: -230px;
  }

  .xs\:-mb-180px {
    margin-bottom: -180px;
  }

  .xs\:-mb-147px {
    margin-bottom: -147px;
  }

  .xs\:-mb-123px {
    margin-bottom: -123px;
  }

  .xs\:-mb-100px {
    margin-bottom: -100px;
  }

  .xs\:-mb-80px {
    margin-bottom: -80px;
  }

  .xs\:-mb-60px {
    margin-bottom: -60px;
  }

  .xs\:-mb-50px {
    margin-bottom: -50px;
  }

  .xs\:-mb-40px {
    margin-bottom: -40px;
  }

  .xs\:-mb-35px {
    margin-bottom: -35px;
  }

  .xs\:-mb-34px-i {
    margin-bottom: 34px !important;
  }

  .xs\:-mb-30px-i {
    margin-bottom: 30px !important;
  }

  .xs\:-mb-24px-i {
    margin-bottom: 24px !important;
  }

  .xs\:-mb-20px-i {
    margin-bottom: 20px !important;
  }

  .xs\:-mb-15px-i {
    margin-bottom: 15px !important;
  }

  .xs\:-mb-13px-i {
    margin-bottom: 13px !important;
  }

  .xs\:-mb-12px-i {
    margin-bottom: 12px !important;
  }

  .xs\:-mb-10px-i {
    margin-bottom: 10px !important;
  }

  .xs\:-mb-8px-i {
    margin-bottom: 8px !important;
  }

  .xs\:-mb-6px-i {
    margin-bottom: 6px !important;
  }

  .xs\:-mb-5px-i {
    margin-bottom: 5px !important;
  }

  .xs\:-mb-3px-i {
    margin-bottom: 3px !important;
  }

  .xs\:-mb-0px-i {
    margin-bottom: 0px !important;
  }

  .xs\:-mb-34px {
    margin-bottom: -34px;
  }

  .xs\:-mb-30px {
    margin-bottom: -30px;
  }

  .xs\:-mb-24px {
    margin-bottom: -24px;
  }

  .xs\:-mb-20px {
    margin-bottom: -20px;
  }

  .xs\:-mb-15px {
    margin-bottom: -15px;
  }

  .xs\:-mb-13px {
    margin-bottom: -13px;
  }

  .xs\:-mb-12px {
    margin-bottom: -12px;
  }

  .xs\:-mb-10px {
    margin-bottom: -10px;
  }

  .xs\:-mb-8px {
    margin-bottom: -8px;
  }

  .xs\:-mb-6px {
    margin-bottom: -6px;
  }

  .xs\:-mb-5px {
    margin-bottom: -5px;
  }

  .xs\:-mb-3px {
    margin-bottom: -3px;
  }

  .xs\:-mb-menu {
    margin-bottom: -275px;
  }

  .xs\:-mb-px {
    margin-bottom: 1px !important;
  }

  .xs\:ml-0 {
    margin-left: 0;
  }

  .xs\:ml-1 {
    margin-left: 0.25rem;
  }

  .xs\:ml-2 {
    margin-left: 0.5rem;
  }

  .xs\:ml-3 {
    margin-left: 0.75rem;
  }

  .xs\:ml-4 {
    margin-left: 1rem;
  }

  .xs\:ml-5 {
    margin-left: 1.25rem;
  }

  .xs\:ml-6 {
    margin-left: 1.5rem;
  }

  .xs\:ml-8 {
    margin-left: 2rem;
  }

  .xs\:ml-10 {
    margin-left: 2.5rem;
  }

  .xs\:ml-12 {
    margin-left: 3rem;
  }

  .xs\:ml-16 {
    margin-left: 4rem;
  }

  .xs\:ml-20 {
    margin-left: 5rem;
  }

  .xs\:ml-24 {
    margin-left: 6rem;
  }

  .xs\:ml-32 {
    margin-left: 8rem;
  }

  .xs\:ml-40 {
    margin-left: 10rem;
  }

  .xs\:ml-48 {
    margin-left: 12rem;
  }

  .xs\:ml-56 {
    margin-left: 14rem;
  }

  .xs\:ml-64 {
    margin-left: 16rem;
  }

  .xs\:ml-auto {
    margin-left: auto;
  }

  .xs\:ml-300px {
    margin-left: 300px;
  }

  .xs\:ml-230px {
    margin-left: 230px;
  }

  .xs\:ml-180px {
    margin-left: 180px;
  }

  .xs\:ml-147px {
    margin-left: 147px;
  }

  .xs\:ml-123px {
    margin-left: 123px;
  }

  .xs\:ml-100px {
    margin-left: 100px;
  }

  .xs\:ml-80px {
    margin-left: 80px;
  }

  .xs\:ml-60px {
    margin-left: 60px;
  }

  .xs\:ml-50px {
    margin-left: 50px;
  }

  .xs\:ml-40px {
    margin-left: 40px;
  }

  .xs\:ml-35px {
    margin-left: 35px;
  }

  .xs\:ml-34px-i {
    margin-left: 34px !important;
  }

  .xs\:ml-30px-i {
    margin-left: 30px !important;
  }

  .xs\:ml-24px-i {
    margin-left: 24px !important;
  }

  .xs\:ml-20px-i {
    margin-left: 20px !important;
  }

  .xs\:ml-15px-i {
    margin-left: 15px !important;
  }

  .xs\:ml-13px-i {
    margin-left: 13px !important;
  }

  .xs\:ml-12px-i {
    margin-left: 12px !important;
  }

  .xs\:ml-10px-i {
    margin-left: 10px !important;
  }

  .xs\:ml-8px-i {
    margin-left: 8px !important;
  }

  .xs\:ml-6px-i {
    margin-left: 6px !important;
  }

  .xs\:ml-5px-i {
    margin-left: 5px !important;
  }

  .xs\:ml-3px-i {
    margin-left: 3px !important;
  }

  .xs\:ml-0px-i {
    margin-left: 0px !important;
  }

  .xs\:ml-34px {
    margin-left: 34px;
  }

  .xs\:ml-30px {
    margin-left: 30px;
  }

  .xs\:ml-24px {
    margin-left: 24px;
  }

  .xs\:ml-20px {
    margin-left: 20px;
  }

  .xs\:ml-15px {
    margin-left: 15px;
  }

  .xs\:ml-13px {
    margin-left: 13px;
  }

  .xs\:ml-12px {
    margin-left: 12px;
  }

  .xs\:ml-10px {
    margin-left: 10px;
  }

  .xs\:ml-8px {
    margin-left: 8px;
  }

  .xs\:ml-6px {
    margin-left: 6px;
  }

  .xs\:ml-5px {
    margin-left: 5px;
  }

  .xs\:ml-3px {
    margin-left: 3px;
  }

  .xs\:ml-menu {
    margin-left: 275px;
  }

  .xs\:ml-px {
    margin-left: 1px !important;
  }

  .xs\:-ml-1 {
    margin-left: -0.25rem;
  }

  .xs\:-ml-2 {
    margin-left: -0.5rem;
  }

  .xs\:-ml-3 {
    margin-left: -0.75rem;
  }

  .xs\:-ml-4 {
    margin-left: -1rem;
  }

  .xs\:-ml-5 {
    margin-left: -1.25rem;
  }

  .xs\:-ml-6 {
    margin-left: -1.5rem;
  }

  .xs\:-ml-8 {
    margin-left: -2rem;
  }

  .xs\:-ml-10 {
    margin-left: -2.5rem;
  }

  .xs\:-ml-12 {
    margin-left: -3rem;
  }

  .xs\:-ml-16 {
    margin-left: -4rem;
  }

  .xs\:-ml-20 {
    margin-left: -5rem;
  }

  .xs\:-ml-24 {
    margin-left: -6rem;
  }

  .xs\:-ml-32 {
    margin-left: -8rem;
  }

  .xs\:-ml-40 {
    margin-left: -10rem;
  }

  .xs\:-ml-48 {
    margin-left: -12rem;
  }

  .xs\:-ml-56 {
    margin-left: -14rem;
  }

  .xs\:-ml-64 {
    margin-left: -16rem;
  }

  .xs\:-ml-300px {
    margin-left: -300px;
  }

  .xs\:-ml-230px {
    margin-left: -230px;
  }

  .xs\:-ml-180px {
    margin-left: -180px;
  }

  .xs\:-ml-147px {
    margin-left: -147px;
  }

  .xs\:-ml-123px {
    margin-left: -123px;
  }

  .xs\:-ml-100px {
    margin-left: -100px;
  }

  .xs\:-ml-80px {
    margin-left: -80px;
  }

  .xs\:-ml-60px {
    margin-left: -60px;
  }

  .xs\:-ml-50px {
    margin-left: -50px;
  }

  .xs\:-ml-40px {
    margin-left: -40px;
  }

  .xs\:-ml-35px {
    margin-left: -35px;
  }

  .xs\:-ml-34px-i {
    margin-left: 34px !important;
  }

  .xs\:-ml-30px-i {
    margin-left: 30px !important;
  }

  .xs\:-ml-24px-i {
    margin-left: 24px !important;
  }

  .xs\:-ml-20px-i {
    margin-left: 20px !important;
  }

  .xs\:-ml-15px-i {
    margin-left: 15px !important;
  }

  .xs\:-ml-13px-i {
    margin-left: 13px !important;
  }

  .xs\:-ml-12px-i {
    margin-left: 12px !important;
  }

  .xs\:-ml-10px-i {
    margin-left: 10px !important;
  }

  .xs\:-ml-8px-i {
    margin-left: 8px !important;
  }

  .xs\:-ml-6px-i {
    margin-left: 6px !important;
  }

  .xs\:-ml-5px-i {
    margin-left: 5px !important;
  }

  .xs\:-ml-3px-i {
    margin-left: 3px !important;
  }

  .xs\:-ml-0px-i {
    margin-left: 0px !important;
  }

  .xs\:-ml-34px {
    margin-left: -34px;
  }

  .xs\:-ml-30px {
    margin-left: -30px;
  }

  .xs\:-ml-24px {
    margin-left: -24px;
  }

  .xs\:-ml-20px {
    margin-left: -20px;
  }

  .xs\:-ml-15px {
    margin-left: -15px;
  }

  .xs\:-ml-13px {
    margin-left: -13px;
  }

  .xs\:-ml-12px {
    margin-left: -12px;
  }

  .xs\:-ml-10px {
    margin-left: -10px;
  }

  .xs\:-ml-8px {
    margin-left: -8px;
  }

  .xs\:-ml-6px {
    margin-left: -6px;
  }

  .xs\:-ml-5px {
    margin-left: -5px;
  }

  .xs\:-ml-3px {
    margin-left: -3px;
  }

  .xs\:-ml-menu {
    margin-left: -275px;
  }

  .xs\:-ml-px {
    margin-left: 1px !important;
  }

  .xs\:box-border {
    box-sizing: border-box;
  }

  .xs\:box-content {
    box-sizing: content-box;
  }

  .xs\:block {
    display: block;
  }

  .xs\:inline-block {
    display: inline-block;
  }

  .xs\:inline {
    display: inline;
  }

  .xs\:flex {
    display: flex;
  }

  .xs\:inline-flex {
    display: inline-flex;
  }

  .xs\:table {
    display: table;
  }

  .xs\:inline-table {
    display: inline-table;
  }

  .xs\:table-caption {
    display: table-caption;
  }

  .xs\:table-cell {
    display: table-cell;
  }

  .xs\:table-column {
    display: table-column;
  }

  .xs\:table-column-group {
    display: table-column-group;
  }

  .xs\:table-footer-group {
    display: table-footer-group;
  }

  .xs\:table-header-group {
    display: table-header-group;
  }

  .xs\:table-row-group {
    display: table-row-group;
  }

  .xs\:table-row {
    display: table-row;
  }

  .xs\:flow-root {
    display: flow-root;
  }

  .xs\:grid {
    display: grid;
  }

  .xs\:inline-grid {
    display: inline-grid;
  }

  .xs\:contents {
    display: contents;
  }

  .xs\:list-item {
    display: list-item;
  }

  .xs\:hidden {
    display: none;
  }

  .xs\:h-0 {
    height: 0;
  }

  .xs\:h-1 {
    height: 0.25rem;
  }

  .xs\:h-2 {
    height: 0.5rem;
  }

  .xs\:h-3 {
    height: 0.75rem;
  }

  .xs\:h-4 {
    height: 1rem;
  }

  .xs\:h-5 {
    height: 1.25rem;
  }

  .xs\:h-6 {
    height: 1.5rem;
  }

  .xs\:h-8 {
    height: 2rem;
  }

  .xs\:h-10 {
    height: 2.5rem;
  }

  .xs\:h-12 {
    height: 3rem;
  }

  .xs\:h-16 {
    height: 4rem;
  }

  .xs\:h-20 {
    height: 5rem;
  }

  .xs\:h-24 {
    height: 6rem;
  }

  .xs\:h-32 {
    height: 8rem;
  }

  .xs\:h-40 {
    height: 10rem;
  }

  .xs\:h-48 {
    height: 12rem;
  }

  .xs\:h-56 {
    height: 14rem;
  }

  .xs\:h-64 {
    height: 16rem;
  }

  .xs\:h-auto {
    height: auto;
  }

  .xs\:h-300px {
    height: 300px;
  }

  .xs\:h-230px {
    height: 230px;
  }

  .xs\:h-180px {
    height: 180px;
  }

  .xs\:h-147px {
    height: 147px;
  }

  .xs\:h-123px {
    height: 123px;
  }

  .xs\:h-100px {
    height: 100px;
  }

  .xs\:h-80px {
    height: 80px;
  }

  .xs\:h-60px {
    height: 60px;
  }

  .xs\:h-50px {
    height: 50px;
  }

  .xs\:h-40px {
    height: 40px;
  }

  .xs\:h-35px {
    height: 35px;
  }

  .xs\:h-34px-i {
    height: 34px !important;
  }

  .xs\:h-30px-i {
    height: 30px !important;
  }

  .xs\:h-24px-i {
    height: 24px !important;
  }

  .xs\:h-20px-i {
    height: 20px !important;
  }

  .xs\:h-15px-i {
    height: 15px !important;
  }

  .xs\:h-13px-i {
    height: 13px !important;
  }

  .xs\:h-12px-i {
    height: 12px !important;
  }

  .xs\:h-10px-i {
    height: 10px !important;
  }

  .xs\:h-8px-i {
    height: 8px !important;
  }

  .xs\:h-6px-i {
    height: 6px !important;
  }

  .xs\:h-5px-i {
    height: 5px !important;
  }

  .xs\:h-3px-i {
    height: 3px !important;
  }

  .xs\:h-0px-i {
    height: 0px !important;
  }

  .xs\:h-34px {
    height: 34px;
  }

  .xs\:h-30px {
    height: 30px;
  }

  .xs\:h-24px {
    height: 24px;
  }

  .xs\:h-20px {
    height: 20px;
  }

  .xs\:h-15px {
    height: 15px;
  }

  .xs\:h-13px {
    height: 13px;
  }

  .xs\:h-12px {
    height: 12px;
  }

  .xs\:h-10px {
    height: 10px;
  }

  .xs\:h-8px {
    height: 8px;
  }

  .xs\:h-6px {
    height: 6px;
  }

  .xs\:h-5px {
    height: 5px;
  }

  .xs\:h-3px {
    height: 3px;
  }

  .xs\:h-menu {
    height: 275px;
  }

  .xs\:h-px {
    height: 1px !important;
  }

  .xs\:h-full {
    height: 100%;
  }

  .xs\:h-screen {
    height: 100vh;
  }

  .xs\:max-h-full {
    max-height: 100%;
  }

  .xs\:max-h-screen {
    max-height: 100vh;
  }

  .xs\:min-h-0 {
    min-height: 0;
  }

  .xs\:min-h-full {
    min-height: 100%;
  }

  .xs\:min-h-screen {
    min-height: 100vh;
  }

  .xs\:w-0 {
    width: 0;
  }

  .xs\:w-1 {
    width: 0.25rem;
  }

  .xs\:w-2 {
    width: 0.5rem;
  }

  .xs\:w-3 {
    width: 0.75rem;
  }

  .xs\:w-4 {
    width: 1rem;
  }

  .xs\:w-5 {
    width: 1.25rem;
  }

  .xs\:w-6 {
    width: 1.5rem;
  }

  .xs\:w-8 {
    width: 2rem;
  }

  .xs\:w-10 {
    width: 2.5rem;
  }

  .xs\:w-12 {
    width: 3rem;
  }

  .xs\:w-16 {
    width: 4rem;
  }

  .xs\:w-20 {
    width: 5rem;
  }

  .xs\:w-24 {
    width: 6rem;
  }

  .xs\:w-32 {
    width: 8rem;
  }

  .xs\:w-40 {
    width: 10rem;
  }

  .xs\:w-48 {
    width: 12rem;
  }

  .xs\:w-56 {
    width: 14rem;
  }

  .xs\:w-64 {
    width: 16rem;
  }

  .xs\:w-auto {
    width: auto !important;
  }

  .xs\:w-300px {
    width: 300px;
  }

  .xs\:w-230px {
    width: 230px;
  }

  .xs\:w-180px {
    width: 180px;
  }

  .xs\:w-147px {
    width: 147px;
  }

  .xs\:w-123px {
    width: 123px;
  }

  .xs\:w-100px {
    width: 100px;
  }

  .xs\:w-80px {
    width: 80px;
  }

  .xs\:w-60px {
    width: 60px;
  }

  .xs\:w-50px {
    width: 50px;
  }

  .xs\:w-40px {
    width: 40px;
  }

  .xs\:w-35px {
    width: 35px;
  }

  .xs\:w-34px-i {
    width: 34px !important;
  }

  .xs\:w-30px-i {
    width: 30px !important;
  }

  .xs\:w-24px-i {
    width: 24px !important;
  }

  .xs\:w-20px-i {
    width: 20px !important;
  }

  .xs\:w-15px-i {
    width: 15px !important;
  }

  .xs\:w-13px-i {
    width: 13px !important;
  }

  .xs\:w-12px-i {
    width: 12px !important;
  }

  .xs\:w-10px-i {
    width: 10px !important;
  }

  .xs\:w-8px-i {
    width: 8px !important;
  }

  .xs\:w-6px-i {
    width: 6px !important;
  }

  .xs\:w-5px-i {
    width: 5px !important;
  }

  .xs\:w-3px-i {
    width: 3px !important;
  }

  .xs\:w-0px-i {
    width: 0px !important;
  }

  .xs\:w-34px {
    width: 34px;
  }

  .xs\:w-30px {
    width: 30px;
  }

  .xs\:w-24px {
    width: 24px;
  }

  .xs\:w-20px {
    width: 20px;
  }

  .xs\:w-15px {
    width: 15px;
  }

  .xs\:w-13px {
    width: 13px;
  }

  .xs\:w-12px {
    width: 12px;
  }

  .xs\:w-10px {
    width: 10px;
  }

  .xs\:w-8px {
    width: 8px;
  }

  .xs\:w-6px {
    width: 6px;
  }

  .xs\:w-5px {
    width: 5px;
  }

  .xs\:w-3px {
    width: 3px;
  }

  .xs\:w-menu {
    width: 275px;
  }

  .xs\:w-px {
    width: 1px !important;
  }

  .xs\:w-1\/2 {
    width: 50% !important;
  }

  .xs\:w-1\/3 {
    width: 33.333333% !important;
  }

  .xs\:w-2\/3 {
    width: 66.666667% !important;
  }

  .xs\:w-1\/4 {
    width: 25% !important;
  }

  .xs\:w-2\/4 {
    width: 50% !important;
  }

  .xs\:w-3\/4 {
    width: 75% !important;
  }

  .xs\:w-1\/5 {
    width: 20% !important;
  }

  .xs\:w-2\/5 {
    width: 40% !important;
  }

  .xs\:w-3\/5 {
    width: 60% !important;
  }

  .xs\:w-4\/5 {
    width: 80% !important;
  }

  .xs\:w-1\/6 {
    width: 16.666667% !important;
  }

  .xs\:w-2\/6 {
    width: 33.333333% !important;
  }

  .xs\:w-3\/6 {
    width: 50% !important;
  }

  .xs\:w-4\/6 {
    width: 66.666667% !important;
  }

  .xs\:w-5\/6 {
    width: 83.333333% !important;
  }

  .xs\:w-1\/12 {
    width: 8.333333% !important;
  }

  .xs\:w-2\/12 {
    width: 16.666667% !important;
  }

  .xs\:w-3\/12 {
    width: 25% !important;
  }

  .xs\:w-4\/12 {
    width: 33.333333% !important;
  }

  .xs\:w-5\/12 {
    width: 41.666667% !important;
  }

  .xs\:w-6\/12 {
    width: 50% !important;
  }

  .xs\:w-7\/12 {
    width: 58.333333% !important;
  }

  .xs\:w-8\/12 {
    width: 66.666667% !important;
  }

  .xs\:w-9\/12 {
    width: 75% !important;
  }

  .xs\:w-10\/12 {
    width: 83.333333% !important;
  }

  .xs\:w-11\/12 {
    width: 91.666667% !important;
  }

  .xs\:w-full {
    width: 100% !important;
  }

  .xs\:w-screen {
    width: 100vw !important;
  }

  .xs\:min-w-0 {
    min-width: 0;
  }

  .xs\:min-w-full {
    min-width: 100%;
  }

  .xs\:max-w-1420px {
    max-width: 1420px;
  }

  .xs\:max-w-1004px {
    max-width: 1004px;
  }

  .xs\:max-w-920px {
    max-width: 920px;
  }

  .xs\:max-w-645px {
    max-width: 645px;
  }

  .xs\:max-w-menu {
    max-width: 275px;
  }

  .xs\:max-w-780px {
    max-width: 780px !important;
  }

  .xs\:max-w-240px {
    max-width: 240px !important;
  }

  .xs\:max-w-100px {
    max-width: 100px !important;
  }

  .xs\:max-w-285px {
    max-width: 285px !important;
  }

  .xs\:max-w-100pct {
    max-width: 100% !important;
  }

  .xs\:max-w-none {
    max-width: none !important;
  }

  .xs\:max-w-xs {
    max-width: 20rem;
  }

  .xs\:max-w-sm {
    max-width: 24rem;
  }

  .xs\:max-w-md {
    max-width: 28rem;
  }

  .xs\:max-w-lg {
    max-width: 32rem;
  }

  .xs\:max-w-xl {
    max-width: 36rem;
  }

  .xs\:max-w-2xl {
    max-width: 42rem;
  }

  .xs\:max-w-3xl {
    max-width: 48rem;
  }

  .xs\:max-w-4xl {
    max-width: 56rem;
  }

  .xs\:max-w-5xl {
    max-width: 64rem;
  }

  .xs\:max-w-6xl {
    max-width: 72rem;
  }

  .xs\:max-w-full {
    max-width: 100% !important;
  }

  .xs\:max-w-screen-xs {
    max-width: 1px;
  }

  .xs\:max-w-screen-sm {
    max-width: 768px;
  }

  .xs\:max-w-screen-md {
    max-width: 1024px;
  }

  .xs\:max-w-screen-lg {
    max-width: 1440px;
  }

  .xs\:max-w-screen-xl {
    max-width: 1920px;
  }

  .xs\:flex-1 {
    flex: 1 1 0%;
  }

  .xs\:flex-auto {
    flex: 1 1 auto;
  }

  .xs\:flex-initial {
    flex: 0 1 auto;
  }

  .xs\:flex-none {
    flex: none;
  }

  .xs\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .xs\:flex-shrink {
    flex-shrink: 1;
  }

  .xs\:flex-grow-0 {
    flex-grow: 0;
  }

  .xs\:flex-grow {
    flex-grow: 1;
  }

  .xs\:table-auto {
    table-layout: auto;
  }

  .xs\:table-fixed {
    table-layout: fixed;
  }

  .xs\:border-collapse {
    border-collapse: collapse;
  }

  .xs\:border-separate {
    border-collapse: separate;
  }

  .xs\:origin-center {
    transform-origin: center;
  }

  .xs\:origin-top {
    transform-origin: top;
  }

  .xs\:origin-top-right {
    transform-origin: top right;
  }

  .xs\:origin-right {
    transform-origin: right;
  }

  .xs\:origin-bottom-right {
    transform-origin: bottom right;
  }

  .xs\:origin-bottom {
    transform-origin: bottom;
  }

  .xs\:origin-bottom-left {
    transform-origin: bottom left;
  }

  .xs\:origin-left {
    transform-origin: left;
  }

  .xs\:origin-top-left {
    transform-origin: top left;
  }

  .xs\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xs\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xs\:transform-none {
    transform: none;
  }

  .xs\:translate-x-0 {
    --tw-translate-x: 0;
  }

  .xs\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }

  .xs\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }

  .xs\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }

  .xs\:translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .xs\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .xs\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .xs\:translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .xs\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .xs\:translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .xs\:translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .xs\:translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .xs\:translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .xs\:translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .xs\:translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .xs\:translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .xs\:translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .xs\:translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .xs\:translate-x-300px {
    --tw-translate-x: 300px;
  }

  .xs\:translate-x-230px {
    --tw-translate-x: 230px;
  }

  .xs\:translate-x-180px {
    --tw-translate-x: 180px;
  }

  .xs\:translate-x-147px {
    --tw-translate-x: 147px;
  }

  .xs\:translate-x-123px {
    --tw-translate-x: 123px;
  }

  .xs\:translate-x-100px {
    --tw-translate-x: 100px;
  }

  .xs\:translate-x-80px {
    --tw-translate-x: 80px;
  }

  .xs\:translate-x-60px {
    --tw-translate-x: 60px;
  }

  .xs\:translate-x-50px {
    --tw-translate-x: 50px;
  }

  .xs\:translate-x-40px {
    --tw-translate-x: 40px;
  }

  .xs\:translate-x-35px {
    --tw-translate-x: 35px;
  }

  .xs\:translate-x-34px-i {
    --tw-translate-x: 34px !important;
  }

  .xs\:translate-x-30px-i {
    --tw-translate-x: 30px !important;
  }

  .xs\:translate-x-24px-i {
    --tw-translate-x: 24px !important;
  }

  .xs\:translate-x-20px-i {
    --tw-translate-x: 20px !important;
  }

  .xs\:translate-x-15px-i {
    --tw-translate-x: 15px !important;
  }

  .xs\:translate-x-13px-i {
    --tw-translate-x: 13px !important;
  }

  .xs\:translate-x-12px-i {
    --tw-translate-x: 12px !important;
  }

  .xs\:translate-x-10px-i {
    --tw-translate-x: 10px !important;
  }

  .xs\:translate-x-8px-i {
    --tw-translate-x: 8px !important;
  }

  .xs\:translate-x-6px-i {
    --tw-translate-x: 6px !important;
  }

  .xs\:translate-x-5px-i {
    --tw-translate-x: 5px !important;
  }

  .xs\:translate-x-3px-i {
    --tw-translate-x: 3px !important;
  }

  .xs\:translate-x-0px-i {
    --tw-translate-x: 0px !important;
  }

  .xs\:translate-x-34px {
    --tw-translate-x: 34px;
  }

  .xs\:translate-x-30px {
    --tw-translate-x: 30px;
  }

  .xs\:translate-x-24px {
    --tw-translate-x: 24px;
  }

  .xs\:translate-x-20px {
    --tw-translate-x: 20px;
  }

  .xs\:translate-x-15px {
    --tw-translate-x: 15px;
  }

  .xs\:translate-x-13px {
    --tw-translate-x: 13px;
  }

  .xs\:translate-x-12px {
    --tw-translate-x: 12px;
  }

  .xs\:translate-x-10px {
    --tw-translate-x: 10px;
  }

  .xs\:translate-x-8px {
    --tw-translate-x: 8px;
  }

  .xs\:translate-x-6px {
    --tw-translate-x: 6px;
  }

  .xs\:translate-x-5px {
    --tw-translate-x: 5px;
  }

  .xs\:translate-x-3px {
    --tw-translate-x: 3px;
  }

  .xs\:translate-x-menu {
    --tw-translate-x: 275px;
  }

  .xs\:translate-x-px {
    --tw-translate-x: 1px !important;
  }

  .xs\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }

  .xs\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }

  .xs\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }

  .xs\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .xs\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .xs\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .xs\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .xs\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .xs\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .xs\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .xs\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .xs\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .xs\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .xs\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .xs\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .xs\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .xs\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .xs\:-translate-x-300px {
    --tw-translate-x: -300px;
  }

  .xs\:-translate-x-230px {
    --tw-translate-x: -230px;
  }

  .xs\:-translate-x-180px {
    --tw-translate-x: -180px;
  }

  .xs\:-translate-x-147px {
    --tw-translate-x: -147px;
  }

  .xs\:-translate-x-123px {
    --tw-translate-x: -123px;
  }

  .xs\:-translate-x-100px {
    --tw-translate-x: -100px;
  }

  .xs\:-translate-x-80px {
    --tw-translate-x: -80px;
  }

  .xs\:-translate-x-60px {
    --tw-translate-x: -60px;
  }

  .xs\:-translate-x-50px {
    --tw-translate-x: -50px;
  }

  .xs\:-translate-x-40px {
    --tw-translate-x: -40px;
  }

  .xs\:-translate-x-35px {
    --tw-translate-x: -35px;
  }

  .xs\:-translate-x-34px-i {
    --tw-translate-x: 34px !important;
  }

  .xs\:-translate-x-30px-i {
    --tw-translate-x: 30px !important;
  }

  .xs\:-translate-x-24px-i {
    --tw-translate-x: 24px !important;
  }

  .xs\:-translate-x-20px-i {
    --tw-translate-x: 20px !important;
  }

  .xs\:-translate-x-15px-i {
    --tw-translate-x: 15px !important;
  }

  .xs\:-translate-x-13px-i {
    --tw-translate-x: 13px !important;
  }

  .xs\:-translate-x-12px-i {
    --tw-translate-x: 12px !important;
  }

  .xs\:-translate-x-10px-i {
    --tw-translate-x: 10px !important;
  }

  .xs\:-translate-x-8px-i {
    --tw-translate-x: 8px !important;
  }

  .xs\:-translate-x-6px-i {
    --tw-translate-x: 6px !important;
  }

  .xs\:-translate-x-5px-i {
    --tw-translate-x: 5px !important;
  }

  .xs\:-translate-x-3px-i {
    --tw-translate-x: 3px !important;
  }

  .xs\:-translate-x-0px-i {
    --tw-translate-x: 0px !important;
  }

  .xs\:-translate-x-34px {
    --tw-translate-x: -34px;
  }

  .xs\:-translate-x-30px {
    --tw-translate-x: -30px;
  }

  .xs\:-translate-x-24px {
    --tw-translate-x: -24px;
  }

  .xs\:-translate-x-20px {
    --tw-translate-x: -20px;
  }

  .xs\:-translate-x-15px {
    --tw-translate-x: -15px;
  }

  .xs\:-translate-x-13px {
    --tw-translate-x: -13px;
  }

  .xs\:-translate-x-12px {
    --tw-translate-x: -12px;
  }

  .xs\:-translate-x-10px {
    --tw-translate-x: -10px;
  }

  .xs\:-translate-x-8px {
    --tw-translate-x: -8px;
  }

  .xs\:-translate-x-6px {
    --tw-translate-x: -6px;
  }

  .xs\:-translate-x-5px {
    --tw-translate-x: -5px;
  }

  .xs\:-translate-x-3px {
    --tw-translate-x: -3px;
  }

  .xs\:-translate-x-menu {
    --tw-translate-x: -275px;
  }

  .xs\:-translate-x-px {
    --tw-translate-x: 1px !important;
  }

  .xs\:-translate-x-full {
    --tw-translate-x: -100%;
  }

  .xs\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .xs\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .xs\:translate-x-full {
    --tw-translate-x: 100%;
  }

  .xs\:translate-y-0 {
    --tw-translate-y: 0;
  }

  .xs\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }

  .xs\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }

  .xs\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }

  .xs\:translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .xs\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .xs\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .xs\:translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .xs\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .xs\:translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .xs\:translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .xs\:translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .xs\:translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .xs\:translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .xs\:translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .xs\:translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .xs\:translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .xs\:translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .xs\:translate-y-300px {
    --tw-translate-y: 300px;
  }

  .xs\:translate-y-230px {
    --tw-translate-y: 230px;
  }

  .xs\:translate-y-180px {
    --tw-translate-y: 180px;
  }

  .xs\:translate-y-147px {
    --tw-translate-y: 147px;
  }

  .xs\:translate-y-123px {
    --tw-translate-y: 123px;
  }

  .xs\:translate-y-100px {
    --tw-translate-y: 100px;
  }

  .xs\:translate-y-80px {
    --tw-translate-y: 80px;
  }

  .xs\:translate-y-60px {
    --tw-translate-y: 60px;
  }

  .xs\:translate-y-50px {
    --tw-translate-y: 50px;
  }

  .xs\:translate-y-40px {
    --tw-translate-y: 40px;
  }

  .xs\:translate-y-35px {
    --tw-translate-y: 35px;
  }

  .xs\:translate-y-34px-i {
    --tw-translate-y: 34px !important;
  }

  .xs\:translate-y-30px-i {
    --tw-translate-y: 30px !important;
  }

  .xs\:translate-y-24px-i {
    --tw-translate-y: 24px !important;
  }

  .xs\:translate-y-20px-i {
    --tw-translate-y: 20px !important;
  }

  .xs\:translate-y-15px-i {
    --tw-translate-y: 15px !important;
  }

  .xs\:translate-y-13px-i {
    --tw-translate-y: 13px !important;
  }

  .xs\:translate-y-12px-i {
    --tw-translate-y: 12px !important;
  }

  .xs\:translate-y-10px-i {
    --tw-translate-y: 10px !important;
  }

  .xs\:translate-y-8px-i {
    --tw-translate-y: 8px !important;
  }

  .xs\:translate-y-6px-i {
    --tw-translate-y: 6px !important;
  }

  .xs\:translate-y-5px-i {
    --tw-translate-y: 5px !important;
  }

  .xs\:translate-y-3px-i {
    --tw-translate-y: 3px !important;
  }

  .xs\:translate-y-0px-i {
    --tw-translate-y: 0px !important;
  }

  .xs\:translate-y-34px {
    --tw-translate-y: 34px;
  }

  .xs\:translate-y-30px {
    --tw-translate-y: 30px;
  }

  .xs\:translate-y-24px {
    --tw-translate-y: 24px;
  }

  .xs\:translate-y-20px {
    --tw-translate-y: 20px;
  }

  .xs\:translate-y-15px {
    --tw-translate-y: 15px;
  }

  .xs\:translate-y-13px {
    --tw-translate-y: 13px;
  }

  .xs\:translate-y-12px {
    --tw-translate-y: 12px;
  }

  .xs\:translate-y-10px {
    --tw-translate-y: 10px;
  }

  .xs\:translate-y-8px {
    --tw-translate-y: 8px;
  }

  .xs\:translate-y-6px {
    --tw-translate-y: 6px;
  }

  .xs\:translate-y-5px {
    --tw-translate-y: 5px;
  }

  .xs\:translate-y-3px {
    --tw-translate-y: 3px;
  }

  .xs\:translate-y-menu {
    --tw-translate-y: 275px;
  }

  .xs\:translate-y-px {
    --tw-translate-y: 1px !important;
  }

  .xs\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }

  .xs\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }

  .xs\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }

  .xs\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .xs\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .xs\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .xs\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .xs\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .xs\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .xs\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .xs\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .xs\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .xs\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .xs\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .xs\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .xs\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .xs\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .xs\:-translate-y-300px {
    --tw-translate-y: -300px;
  }

  .xs\:-translate-y-230px {
    --tw-translate-y: -230px;
  }

  .xs\:-translate-y-180px {
    --tw-translate-y: -180px;
  }

  .xs\:-translate-y-147px {
    --tw-translate-y: -147px;
  }

  .xs\:-translate-y-123px {
    --tw-translate-y: -123px;
  }

  .xs\:-translate-y-100px {
    --tw-translate-y: -100px;
  }

  .xs\:-translate-y-80px {
    --tw-translate-y: -80px;
  }

  .xs\:-translate-y-60px {
    --tw-translate-y: -60px;
  }

  .xs\:-translate-y-50px {
    --tw-translate-y: -50px;
  }

  .xs\:-translate-y-40px {
    --tw-translate-y: -40px;
  }

  .xs\:-translate-y-35px {
    --tw-translate-y: -35px;
  }

  .xs\:-translate-y-34px-i {
    --tw-translate-y: 34px !important;
  }

  .xs\:-translate-y-30px-i {
    --tw-translate-y: 30px !important;
  }

  .xs\:-translate-y-24px-i {
    --tw-translate-y: 24px !important;
  }

  .xs\:-translate-y-20px-i {
    --tw-translate-y: 20px !important;
  }

  .xs\:-translate-y-15px-i {
    --tw-translate-y: 15px !important;
  }

  .xs\:-translate-y-13px-i {
    --tw-translate-y: 13px !important;
  }

  .xs\:-translate-y-12px-i {
    --tw-translate-y: 12px !important;
  }

  .xs\:-translate-y-10px-i {
    --tw-translate-y: 10px !important;
  }

  .xs\:-translate-y-8px-i {
    --tw-translate-y: 8px !important;
  }

  .xs\:-translate-y-6px-i {
    --tw-translate-y: 6px !important;
  }

  .xs\:-translate-y-5px-i {
    --tw-translate-y: 5px !important;
  }

  .xs\:-translate-y-3px-i {
    --tw-translate-y: 3px !important;
  }

  .xs\:-translate-y-0px-i {
    --tw-translate-y: 0px !important;
  }

  .xs\:-translate-y-34px {
    --tw-translate-y: -34px;
  }

  .xs\:-translate-y-30px {
    --tw-translate-y: -30px;
  }

  .xs\:-translate-y-24px {
    --tw-translate-y: -24px;
  }

  .xs\:-translate-y-20px {
    --tw-translate-y: -20px;
  }

  .xs\:-translate-y-15px {
    --tw-translate-y: -15px;
  }

  .xs\:-translate-y-13px {
    --tw-translate-y: -13px;
  }

  .xs\:-translate-y-12px {
    --tw-translate-y: -12px;
  }

  .xs\:-translate-y-10px {
    --tw-translate-y: -10px;
  }

  .xs\:-translate-y-8px {
    --tw-translate-y: -8px;
  }

  .xs\:-translate-y-6px {
    --tw-translate-y: -6px;
  }

  .xs\:-translate-y-5px {
    --tw-translate-y: -5px;
  }

  .xs\:-translate-y-3px {
    --tw-translate-y: -3px;
  }

  .xs\:-translate-y-menu {
    --tw-translate-y: -275px;
  }

  .xs\:-translate-y-px {
    --tw-translate-y: 1px !important;
  }

  .xs\:-translate-y-full {
    --tw-translate-y: -100%;
  }

  .xs\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .xs\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .xs\:translate-y-full {
    --tw-translate-y: 100%;
  }

  .xs\:hover\:translate-x-0:hover {
    --tw-translate-x: 0;
  }

  .xs\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }

  .xs\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }

  .xs\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }

  .xs\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .xs\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .xs\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .xs\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .xs\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .xs\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .xs\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .xs\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .xs\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .xs\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .xs\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .xs\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .xs\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .xs\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .xs\:hover\:translate-x-300px:hover {
    --tw-translate-x: 300px;
  }

  .xs\:hover\:translate-x-230px:hover {
    --tw-translate-x: 230px;
  }

  .xs\:hover\:translate-x-180px:hover {
    --tw-translate-x: 180px;
  }

  .xs\:hover\:translate-x-147px:hover {
    --tw-translate-x: 147px;
  }

  .xs\:hover\:translate-x-123px:hover {
    --tw-translate-x: 123px;
  }

  .xs\:hover\:translate-x-100px:hover {
    --tw-translate-x: 100px;
  }

  .xs\:hover\:translate-x-80px:hover {
    --tw-translate-x: 80px;
  }

  .xs\:hover\:translate-x-60px:hover {
    --tw-translate-x: 60px;
  }

  .xs\:hover\:translate-x-50px:hover {
    --tw-translate-x: 50px;
  }

  .xs\:hover\:translate-x-40px:hover {
    --tw-translate-x: 40px;
  }

  .xs\:hover\:translate-x-35px:hover {
    --tw-translate-x: 35px;
  }

  .xs\:hover\:translate-x-34px-i:hover {
    --tw-translate-x: 34px !important;
  }

  .xs\:hover\:translate-x-30px-i:hover {
    --tw-translate-x: 30px !important;
  }

  .xs\:hover\:translate-x-24px-i:hover {
    --tw-translate-x: 24px !important;
  }

  .xs\:hover\:translate-x-20px-i:hover {
    --tw-translate-x: 20px !important;
  }

  .xs\:hover\:translate-x-15px-i:hover {
    --tw-translate-x: 15px !important;
  }

  .xs\:hover\:translate-x-13px-i:hover {
    --tw-translate-x: 13px !important;
  }

  .xs\:hover\:translate-x-12px-i:hover {
    --tw-translate-x: 12px !important;
  }

  .xs\:hover\:translate-x-10px-i:hover {
    --tw-translate-x: 10px !important;
  }

  .xs\:hover\:translate-x-8px-i:hover {
    --tw-translate-x: 8px !important;
  }

  .xs\:hover\:translate-x-6px-i:hover {
    --tw-translate-x: 6px !important;
  }

  .xs\:hover\:translate-x-5px-i:hover {
    --tw-translate-x: 5px !important;
  }

  .xs\:hover\:translate-x-3px-i:hover {
    --tw-translate-x: 3px !important;
  }

  .xs\:hover\:translate-x-0px-i:hover {
    --tw-translate-x: 0px !important;
  }

  .xs\:hover\:translate-x-34px:hover {
    --tw-translate-x: 34px;
  }

  .xs\:hover\:translate-x-30px:hover {
    --tw-translate-x: 30px;
  }

  .xs\:hover\:translate-x-24px:hover {
    --tw-translate-x: 24px;
  }

  .xs\:hover\:translate-x-20px:hover {
    --tw-translate-x: 20px;
  }

  .xs\:hover\:translate-x-15px:hover {
    --tw-translate-x: 15px;
  }

  .xs\:hover\:translate-x-13px:hover {
    --tw-translate-x: 13px;
  }

  .xs\:hover\:translate-x-12px:hover {
    --tw-translate-x: 12px;
  }

  .xs\:hover\:translate-x-10px:hover {
    --tw-translate-x: 10px;
  }

  .xs\:hover\:translate-x-8px:hover {
    --tw-translate-x: 8px;
  }

  .xs\:hover\:translate-x-6px:hover {
    --tw-translate-x: 6px;
  }

  .xs\:hover\:translate-x-5px:hover {
    --tw-translate-x: 5px;
  }

  .xs\:hover\:translate-x-3px:hover {
    --tw-translate-x: 3px;
  }

  .xs\:hover\:translate-x-menu:hover {
    --tw-translate-x: 275px;
  }

  .xs\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px !important;
  }

  .xs\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }

  .xs\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }

  .xs\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }

  .xs\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .xs\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .xs\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .xs\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .xs\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .xs\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .xs\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .xs\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .xs\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .xs\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .xs\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .xs\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .xs\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .xs\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .xs\:hover\:-translate-x-300px:hover {
    --tw-translate-x: -300px;
  }

  .xs\:hover\:-translate-x-230px:hover {
    --tw-translate-x: -230px;
  }

  .xs\:hover\:-translate-x-180px:hover {
    --tw-translate-x: -180px;
  }

  .xs\:hover\:-translate-x-147px:hover {
    --tw-translate-x: -147px;
  }

  .xs\:hover\:-translate-x-123px:hover {
    --tw-translate-x: -123px;
  }

  .xs\:hover\:-translate-x-100px:hover {
    --tw-translate-x: -100px;
  }

  .xs\:hover\:-translate-x-80px:hover {
    --tw-translate-x: -80px;
  }

  .xs\:hover\:-translate-x-60px:hover {
    --tw-translate-x: -60px;
  }

  .xs\:hover\:-translate-x-50px:hover {
    --tw-translate-x: -50px;
  }

  .xs\:hover\:-translate-x-40px:hover {
    --tw-translate-x: -40px;
  }

  .xs\:hover\:-translate-x-35px:hover {
    --tw-translate-x: -35px;
  }

  .xs\:hover\:-translate-x-34px-i:hover {
    --tw-translate-x: 34px !important;
  }

  .xs\:hover\:-translate-x-30px-i:hover {
    --tw-translate-x: 30px !important;
  }

  .xs\:hover\:-translate-x-24px-i:hover {
    --tw-translate-x: 24px !important;
  }

  .xs\:hover\:-translate-x-20px-i:hover {
    --tw-translate-x: 20px !important;
  }

  .xs\:hover\:-translate-x-15px-i:hover {
    --tw-translate-x: 15px !important;
  }

  .xs\:hover\:-translate-x-13px-i:hover {
    --tw-translate-x: 13px !important;
  }

  .xs\:hover\:-translate-x-12px-i:hover {
    --tw-translate-x: 12px !important;
  }

  .xs\:hover\:-translate-x-10px-i:hover {
    --tw-translate-x: 10px !important;
  }

  .xs\:hover\:-translate-x-8px-i:hover {
    --tw-translate-x: 8px !important;
  }

  .xs\:hover\:-translate-x-6px-i:hover {
    --tw-translate-x: 6px !important;
  }

  .xs\:hover\:-translate-x-5px-i:hover {
    --tw-translate-x: 5px !important;
  }

  .xs\:hover\:-translate-x-3px-i:hover {
    --tw-translate-x: 3px !important;
  }

  .xs\:hover\:-translate-x-0px-i:hover {
    --tw-translate-x: 0px !important;
  }

  .xs\:hover\:-translate-x-34px:hover {
    --tw-translate-x: -34px;
  }

  .xs\:hover\:-translate-x-30px:hover {
    --tw-translate-x: -30px;
  }

  .xs\:hover\:-translate-x-24px:hover {
    --tw-translate-x: -24px;
  }

  .xs\:hover\:-translate-x-20px:hover {
    --tw-translate-x: -20px;
  }

  .xs\:hover\:-translate-x-15px:hover {
    --tw-translate-x: -15px;
  }

  .xs\:hover\:-translate-x-13px:hover {
    --tw-translate-x: -13px;
  }

  .xs\:hover\:-translate-x-12px:hover {
    --tw-translate-x: -12px;
  }

  .xs\:hover\:-translate-x-10px:hover {
    --tw-translate-x: -10px;
  }

  .xs\:hover\:-translate-x-8px:hover {
    --tw-translate-x: -8px;
  }

  .xs\:hover\:-translate-x-6px:hover {
    --tw-translate-x: -6px;
  }

  .xs\:hover\:-translate-x-5px:hover {
    --tw-translate-x: -5px;
  }

  .xs\:hover\:-translate-x-3px:hover {
    --tw-translate-x: -3px;
  }

  .xs\:hover\:-translate-x-menu:hover {
    --tw-translate-x: -275px;
  }

  .xs\:hover\:-translate-x-px:hover {
    --tw-translate-x: 1px !important;
  }

  .xs\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .xs\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .xs\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .xs\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .xs\:hover\:translate-y-0:hover {
    --tw-translate-y: 0;
  }

  .xs\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }

  .xs\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }

  .xs\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }

  .xs\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .xs\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .xs\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .xs\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .xs\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .xs\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .xs\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .xs\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .xs\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .xs\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .xs\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .xs\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .xs\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .xs\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .xs\:hover\:translate-y-300px:hover {
    --tw-translate-y: 300px;
  }

  .xs\:hover\:translate-y-230px:hover {
    --tw-translate-y: 230px;
  }

  .xs\:hover\:translate-y-180px:hover {
    --tw-translate-y: 180px;
  }

  .xs\:hover\:translate-y-147px:hover {
    --tw-translate-y: 147px;
  }

  .xs\:hover\:translate-y-123px:hover {
    --tw-translate-y: 123px;
  }

  .xs\:hover\:translate-y-100px:hover {
    --tw-translate-y: 100px;
  }

  .xs\:hover\:translate-y-80px:hover {
    --tw-translate-y: 80px;
  }

  .xs\:hover\:translate-y-60px:hover {
    --tw-translate-y: 60px;
  }

  .xs\:hover\:translate-y-50px:hover {
    --tw-translate-y: 50px;
  }

  .xs\:hover\:translate-y-40px:hover {
    --tw-translate-y: 40px;
  }

  .xs\:hover\:translate-y-35px:hover {
    --tw-translate-y: 35px;
  }

  .xs\:hover\:translate-y-34px-i:hover {
    --tw-translate-y: 34px !important;
  }

  .xs\:hover\:translate-y-30px-i:hover {
    --tw-translate-y: 30px !important;
  }

  .xs\:hover\:translate-y-24px-i:hover {
    --tw-translate-y: 24px !important;
  }

  .xs\:hover\:translate-y-20px-i:hover {
    --tw-translate-y: 20px !important;
  }

  .xs\:hover\:translate-y-15px-i:hover {
    --tw-translate-y: 15px !important;
  }

  .xs\:hover\:translate-y-13px-i:hover {
    --tw-translate-y: 13px !important;
  }

  .xs\:hover\:translate-y-12px-i:hover {
    --tw-translate-y: 12px !important;
  }

  .xs\:hover\:translate-y-10px-i:hover {
    --tw-translate-y: 10px !important;
  }

  .xs\:hover\:translate-y-8px-i:hover {
    --tw-translate-y: 8px !important;
  }

  .xs\:hover\:translate-y-6px-i:hover {
    --tw-translate-y: 6px !important;
  }

  .xs\:hover\:translate-y-5px-i:hover {
    --tw-translate-y: 5px !important;
  }

  .xs\:hover\:translate-y-3px-i:hover {
    --tw-translate-y: 3px !important;
  }

  .xs\:hover\:translate-y-0px-i:hover {
    --tw-translate-y: 0px !important;
  }

  .xs\:hover\:translate-y-34px:hover {
    --tw-translate-y: 34px;
  }

  .xs\:hover\:translate-y-30px:hover {
    --tw-translate-y: 30px;
  }

  .xs\:hover\:translate-y-24px:hover {
    --tw-translate-y: 24px;
  }

  .xs\:hover\:translate-y-20px:hover {
    --tw-translate-y: 20px;
  }

  .xs\:hover\:translate-y-15px:hover {
    --tw-translate-y: 15px;
  }

  .xs\:hover\:translate-y-13px:hover {
    --tw-translate-y: 13px;
  }

  .xs\:hover\:translate-y-12px:hover {
    --tw-translate-y: 12px;
  }

  .xs\:hover\:translate-y-10px:hover {
    --tw-translate-y: 10px;
  }

  .xs\:hover\:translate-y-8px:hover {
    --tw-translate-y: 8px;
  }

  .xs\:hover\:translate-y-6px:hover {
    --tw-translate-y: 6px;
  }

  .xs\:hover\:translate-y-5px:hover {
    --tw-translate-y: 5px;
  }

  .xs\:hover\:translate-y-3px:hover {
    --tw-translate-y: 3px;
  }

  .xs\:hover\:translate-y-menu:hover {
    --tw-translate-y: 275px;
  }

  .xs\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px !important;
  }

  .xs\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }

  .xs\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }

  .xs\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }

  .xs\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .xs\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .xs\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .xs\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .xs\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .xs\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .xs\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .xs\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .xs\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .xs\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .xs\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .xs\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .xs\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .xs\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .xs\:hover\:-translate-y-300px:hover {
    --tw-translate-y: -300px;
  }

  .xs\:hover\:-translate-y-230px:hover {
    --tw-translate-y: -230px;
  }

  .xs\:hover\:-translate-y-180px:hover {
    --tw-translate-y: -180px;
  }

  .xs\:hover\:-translate-y-147px:hover {
    --tw-translate-y: -147px;
  }

  .xs\:hover\:-translate-y-123px:hover {
    --tw-translate-y: -123px;
  }

  .xs\:hover\:-translate-y-100px:hover {
    --tw-translate-y: -100px;
  }

  .xs\:hover\:-translate-y-80px:hover {
    --tw-translate-y: -80px;
  }

  .xs\:hover\:-translate-y-60px:hover {
    --tw-translate-y: -60px;
  }

  .xs\:hover\:-translate-y-50px:hover {
    --tw-translate-y: -50px;
  }

  .xs\:hover\:-translate-y-40px:hover {
    --tw-translate-y: -40px;
  }

  .xs\:hover\:-translate-y-35px:hover {
    --tw-translate-y: -35px;
  }

  .xs\:hover\:-translate-y-34px-i:hover {
    --tw-translate-y: 34px !important;
  }

  .xs\:hover\:-translate-y-30px-i:hover {
    --tw-translate-y: 30px !important;
  }

  .xs\:hover\:-translate-y-24px-i:hover {
    --tw-translate-y: 24px !important;
  }

  .xs\:hover\:-translate-y-20px-i:hover {
    --tw-translate-y: 20px !important;
  }

  .xs\:hover\:-translate-y-15px-i:hover {
    --tw-translate-y: 15px !important;
  }

  .xs\:hover\:-translate-y-13px-i:hover {
    --tw-translate-y: 13px !important;
  }

  .xs\:hover\:-translate-y-12px-i:hover {
    --tw-translate-y: 12px !important;
  }

  .xs\:hover\:-translate-y-10px-i:hover {
    --tw-translate-y: 10px !important;
  }

  .xs\:hover\:-translate-y-8px-i:hover {
    --tw-translate-y: 8px !important;
  }

  .xs\:hover\:-translate-y-6px-i:hover {
    --tw-translate-y: 6px !important;
  }

  .xs\:hover\:-translate-y-5px-i:hover {
    --tw-translate-y: 5px !important;
  }

  .xs\:hover\:-translate-y-3px-i:hover {
    --tw-translate-y: 3px !important;
  }

  .xs\:hover\:-translate-y-0px-i:hover {
    --tw-translate-y: 0px !important;
  }

  .xs\:hover\:-translate-y-34px:hover {
    --tw-translate-y: -34px;
  }

  .xs\:hover\:-translate-y-30px:hover {
    --tw-translate-y: -30px;
  }

  .xs\:hover\:-translate-y-24px:hover {
    --tw-translate-y: -24px;
  }

  .xs\:hover\:-translate-y-20px:hover {
    --tw-translate-y: -20px;
  }

  .xs\:hover\:-translate-y-15px:hover {
    --tw-translate-y: -15px;
  }

  .xs\:hover\:-translate-y-13px:hover {
    --tw-translate-y: -13px;
  }

  .xs\:hover\:-translate-y-12px:hover {
    --tw-translate-y: -12px;
  }

  .xs\:hover\:-translate-y-10px:hover {
    --tw-translate-y: -10px;
  }

  .xs\:hover\:-translate-y-8px:hover {
    --tw-translate-y: -8px;
  }

  .xs\:hover\:-translate-y-6px:hover {
    --tw-translate-y: -6px;
  }

  .xs\:hover\:-translate-y-5px:hover {
    --tw-translate-y: -5px;
  }

  .xs\:hover\:-translate-y-3px:hover {
    --tw-translate-y: -3px;
  }

  .xs\:hover\:-translate-y-menu:hover {
    --tw-translate-y: -275px;
  }

  .xs\:hover\:-translate-y-px:hover {
    --tw-translate-y: 1px !important;
  }

  .xs\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .xs\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .xs\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .xs\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .xs\:focus\:translate-x-0:focus {
    --tw-translate-x: 0;
  }

  .xs\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }

  .xs\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }

  .xs\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }

  .xs\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .xs\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .xs\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .xs\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .xs\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .xs\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .xs\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .xs\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .xs\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .xs\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .xs\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .xs\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .xs\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .xs\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .xs\:focus\:translate-x-300px:focus {
    --tw-translate-x: 300px;
  }

  .xs\:focus\:translate-x-230px:focus {
    --tw-translate-x: 230px;
  }

  .xs\:focus\:translate-x-180px:focus {
    --tw-translate-x: 180px;
  }

  .xs\:focus\:translate-x-147px:focus {
    --tw-translate-x: 147px;
  }

  .xs\:focus\:translate-x-123px:focus {
    --tw-translate-x: 123px;
  }

  .xs\:focus\:translate-x-100px:focus {
    --tw-translate-x: 100px;
  }

  .xs\:focus\:translate-x-80px:focus {
    --tw-translate-x: 80px;
  }

  .xs\:focus\:translate-x-60px:focus {
    --tw-translate-x: 60px;
  }

  .xs\:focus\:translate-x-50px:focus {
    --tw-translate-x: 50px;
  }

  .xs\:focus\:translate-x-40px:focus {
    --tw-translate-x: 40px;
  }

  .xs\:focus\:translate-x-35px:focus {
    --tw-translate-x: 35px;
  }

  .xs\:focus\:translate-x-34px-i:focus {
    --tw-translate-x: 34px !important;
  }

  .xs\:focus\:translate-x-30px-i:focus {
    --tw-translate-x: 30px !important;
  }

  .xs\:focus\:translate-x-24px-i:focus {
    --tw-translate-x: 24px !important;
  }

  .xs\:focus\:translate-x-20px-i:focus {
    --tw-translate-x: 20px !important;
  }

  .xs\:focus\:translate-x-15px-i:focus {
    --tw-translate-x: 15px !important;
  }

  .xs\:focus\:translate-x-13px-i:focus {
    --tw-translate-x: 13px !important;
  }

  .xs\:focus\:translate-x-12px-i:focus {
    --tw-translate-x: 12px !important;
  }

  .xs\:focus\:translate-x-10px-i:focus {
    --tw-translate-x: 10px !important;
  }

  .xs\:focus\:translate-x-8px-i:focus {
    --tw-translate-x: 8px !important;
  }

  .xs\:focus\:translate-x-6px-i:focus {
    --tw-translate-x: 6px !important;
  }

  .xs\:focus\:translate-x-5px-i:focus {
    --tw-translate-x: 5px !important;
  }

  .xs\:focus\:translate-x-3px-i:focus {
    --tw-translate-x: 3px !important;
  }

  .xs\:focus\:translate-x-0px-i:focus {
    --tw-translate-x: 0px !important;
  }

  .xs\:focus\:translate-x-34px:focus {
    --tw-translate-x: 34px;
  }

  .xs\:focus\:translate-x-30px:focus {
    --tw-translate-x: 30px;
  }

  .xs\:focus\:translate-x-24px:focus {
    --tw-translate-x: 24px;
  }

  .xs\:focus\:translate-x-20px:focus {
    --tw-translate-x: 20px;
  }

  .xs\:focus\:translate-x-15px:focus {
    --tw-translate-x: 15px;
  }

  .xs\:focus\:translate-x-13px:focus {
    --tw-translate-x: 13px;
  }

  .xs\:focus\:translate-x-12px:focus {
    --tw-translate-x: 12px;
  }

  .xs\:focus\:translate-x-10px:focus {
    --tw-translate-x: 10px;
  }

  .xs\:focus\:translate-x-8px:focus {
    --tw-translate-x: 8px;
  }

  .xs\:focus\:translate-x-6px:focus {
    --tw-translate-x: 6px;
  }

  .xs\:focus\:translate-x-5px:focus {
    --tw-translate-x: 5px;
  }

  .xs\:focus\:translate-x-3px:focus {
    --tw-translate-x: 3px;
  }

  .xs\:focus\:translate-x-menu:focus {
    --tw-translate-x: 275px;
  }

  .xs\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px !important;
  }

  .xs\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }

  .xs\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }

  .xs\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }

  .xs\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .xs\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .xs\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .xs\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .xs\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .xs\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .xs\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .xs\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .xs\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .xs\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .xs\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .xs\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .xs\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .xs\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .xs\:focus\:-translate-x-300px:focus {
    --tw-translate-x: -300px;
  }

  .xs\:focus\:-translate-x-230px:focus {
    --tw-translate-x: -230px;
  }

  .xs\:focus\:-translate-x-180px:focus {
    --tw-translate-x: -180px;
  }

  .xs\:focus\:-translate-x-147px:focus {
    --tw-translate-x: -147px;
  }

  .xs\:focus\:-translate-x-123px:focus {
    --tw-translate-x: -123px;
  }

  .xs\:focus\:-translate-x-100px:focus {
    --tw-translate-x: -100px;
  }

  .xs\:focus\:-translate-x-80px:focus {
    --tw-translate-x: -80px;
  }

  .xs\:focus\:-translate-x-60px:focus {
    --tw-translate-x: -60px;
  }

  .xs\:focus\:-translate-x-50px:focus {
    --tw-translate-x: -50px;
  }

  .xs\:focus\:-translate-x-40px:focus {
    --tw-translate-x: -40px;
  }

  .xs\:focus\:-translate-x-35px:focus {
    --tw-translate-x: -35px;
  }

  .xs\:focus\:-translate-x-34px-i:focus {
    --tw-translate-x: 34px !important;
  }

  .xs\:focus\:-translate-x-30px-i:focus {
    --tw-translate-x: 30px !important;
  }

  .xs\:focus\:-translate-x-24px-i:focus {
    --tw-translate-x: 24px !important;
  }

  .xs\:focus\:-translate-x-20px-i:focus {
    --tw-translate-x: 20px !important;
  }

  .xs\:focus\:-translate-x-15px-i:focus {
    --tw-translate-x: 15px !important;
  }

  .xs\:focus\:-translate-x-13px-i:focus {
    --tw-translate-x: 13px !important;
  }

  .xs\:focus\:-translate-x-12px-i:focus {
    --tw-translate-x: 12px !important;
  }

  .xs\:focus\:-translate-x-10px-i:focus {
    --tw-translate-x: 10px !important;
  }

  .xs\:focus\:-translate-x-8px-i:focus {
    --tw-translate-x: 8px !important;
  }

  .xs\:focus\:-translate-x-6px-i:focus {
    --tw-translate-x: 6px !important;
  }

  .xs\:focus\:-translate-x-5px-i:focus {
    --tw-translate-x: 5px !important;
  }

  .xs\:focus\:-translate-x-3px-i:focus {
    --tw-translate-x: 3px !important;
  }

  .xs\:focus\:-translate-x-0px-i:focus {
    --tw-translate-x: 0px !important;
  }

  .xs\:focus\:-translate-x-34px:focus {
    --tw-translate-x: -34px;
  }

  .xs\:focus\:-translate-x-30px:focus {
    --tw-translate-x: -30px;
  }

  .xs\:focus\:-translate-x-24px:focus {
    --tw-translate-x: -24px;
  }

  .xs\:focus\:-translate-x-20px:focus {
    --tw-translate-x: -20px;
  }

  .xs\:focus\:-translate-x-15px:focus {
    --tw-translate-x: -15px;
  }

  .xs\:focus\:-translate-x-13px:focus {
    --tw-translate-x: -13px;
  }

  .xs\:focus\:-translate-x-12px:focus {
    --tw-translate-x: -12px;
  }

  .xs\:focus\:-translate-x-10px:focus {
    --tw-translate-x: -10px;
  }

  .xs\:focus\:-translate-x-8px:focus {
    --tw-translate-x: -8px;
  }

  .xs\:focus\:-translate-x-6px:focus {
    --tw-translate-x: -6px;
  }

  .xs\:focus\:-translate-x-5px:focus {
    --tw-translate-x: -5px;
  }

  .xs\:focus\:-translate-x-3px:focus {
    --tw-translate-x: -3px;
  }

  .xs\:focus\:-translate-x-menu:focus {
    --tw-translate-x: -275px;
  }

  .xs\:focus\:-translate-x-px:focus {
    --tw-translate-x: 1px !important;
  }

  .xs\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .xs\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .xs\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .xs\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .xs\:focus\:translate-y-0:focus {
    --tw-translate-y: 0;
  }

  .xs\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }

  .xs\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }

  .xs\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }

  .xs\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .xs\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .xs\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .xs\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .xs\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .xs\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .xs\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .xs\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .xs\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .xs\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .xs\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .xs\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .xs\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .xs\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .xs\:focus\:translate-y-300px:focus {
    --tw-translate-y: 300px;
  }

  .xs\:focus\:translate-y-230px:focus {
    --tw-translate-y: 230px;
  }

  .xs\:focus\:translate-y-180px:focus {
    --tw-translate-y: 180px;
  }

  .xs\:focus\:translate-y-147px:focus {
    --tw-translate-y: 147px;
  }

  .xs\:focus\:translate-y-123px:focus {
    --tw-translate-y: 123px;
  }

  .xs\:focus\:translate-y-100px:focus {
    --tw-translate-y: 100px;
  }

  .xs\:focus\:translate-y-80px:focus {
    --tw-translate-y: 80px;
  }

  .xs\:focus\:translate-y-60px:focus {
    --tw-translate-y: 60px;
  }

  .xs\:focus\:translate-y-50px:focus {
    --tw-translate-y: 50px;
  }

  .xs\:focus\:translate-y-40px:focus {
    --tw-translate-y: 40px;
  }

  .xs\:focus\:translate-y-35px:focus {
    --tw-translate-y: 35px;
  }

  .xs\:focus\:translate-y-34px-i:focus {
    --tw-translate-y: 34px !important;
  }

  .xs\:focus\:translate-y-30px-i:focus {
    --tw-translate-y: 30px !important;
  }

  .xs\:focus\:translate-y-24px-i:focus {
    --tw-translate-y: 24px !important;
  }

  .xs\:focus\:translate-y-20px-i:focus {
    --tw-translate-y: 20px !important;
  }

  .xs\:focus\:translate-y-15px-i:focus {
    --tw-translate-y: 15px !important;
  }

  .xs\:focus\:translate-y-13px-i:focus {
    --tw-translate-y: 13px !important;
  }

  .xs\:focus\:translate-y-12px-i:focus {
    --tw-translate-y: 12px !important;
  }

  .xs\:focus\:translate-y-10px-i:focus {
    --tw-translate-y: 10px !important;
  }

  .xs\:focus\:translate-y-8px-i:focus {
    --tw-translate-y: 8px !important;
  }

  .xs\:focus\:translate-y-6px-i:focus {
    --tw-translate-y: 6px !important;
  }

  .xs\:focus\:translate-y-5px-i:focus {
    --tw-translate-y: 5px !important;
  }

  .xs\:focus\:translate-y-3px-i:focus {
    --tw-translate-y: 3px !important;
  }

  .xs\:focus\:translate-y-0px-i:focus {
    --tw-translate-y: 0px !important;
  }

  .xs\:focus\:translate-y-34px:focus {
    --tw-translate-y: 34px;
  }

  .xs\:focus\:translate-y-30px:focus {
    --tw-translate-y: 30px;
  }

  .xs\:focus\:translate-y-24px:focus {
    --tw-translate-y: 24px;
  }

  .xs\:focus\:translate-y-20px:focus {
    --tw-translate-y: 20px;
  }

  .xs\:focus\:translate-y-15px:focus {
    --tw-translate-y: 15px;
  }

  .xs\:focus\:translate-y-13px:focus {
    --tw-translate-y: 13px;
  }

  .xs\:focus\:translate-y-12px:focus {
    --tw-translate-y: 12px;
  }

  .xs\:focus\:translate-y-10px:focus {
    --tw-translate-y: 10px;
  }

  .xs\:focus\:translate-y-8px:focus {
    --tw-translate-y: 8px;
  }

  .xs\:focus\:translate-y-6px:focus {
    --tw-translate-y: 6px;
  }

  .xs\:focus\:translate-y-5px:focus {
    --tw-translate-y: 5px;
  }

  .xs\:focus\:translate-y-3px:focus {
    --tw-translate-y: 3px;
  }

  .xs\:focus\:translate-y-menu:focus {
    --tw-translate-y: 275px;
  }

  .xs\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px !important;
  }

  .xs\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }

  .xs\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }

  .xs\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }

  .xs\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .xs\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .xs\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .xs\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .xs\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .xs\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .xs\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .xs\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .xs\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .xs\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .xs\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .xs\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .xs\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .xs\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .xs\:focus\:-translate-y-300px:focus {
    --tw-translate-y: -300px;
  }

  .xs\:focus\:-translate-y-230px:focus {
    --tw-translate-y: -230px;
  }

  .xs\:focus\:-translate-y-180px:focus {
    --tw-translate-y: -180px;
  }

  .xs\:focus\:-translate-y-147px:focus {
    --tw-translate-y: -147px;
  }

  .xs\:focus\:-translate-y-123px:focus {
    --tw-translate-y: -123px;
  }

  .xs\:focus\:-translate-y-100px:focus {
    --tw-translate-y: -100px;
  }

  .xs\:focus\:-translate-y-80px:focus {
    --tw-translate-y: -80px;
  }

  .xs\:focus\:-translate-y-60px:focus {
    --tw-translate-y: -60px;
  }

  .xs\:focus\:-translate-y-50px:focus {
    --tw-translate-y: -50px;
  }

  .xs\:focus\:-translate-y-40px:focus {
    --tw-translate-y: -40px;
  }

  .xs\:focus\:-translate-y-35px:focus {
    --tw-translate-y: -35px;
  }

  .xs\:focus\:-translate-y-34px-i:focus {
    --tw-translate-y: 34px !important;
  }

  .xs\:focus\:-translate-y-30px-i:focus {
    --tw-translate-y: 30px !important;
  }

  .xs\:focus\:-translate-y-24px-i:focus {
    --tw-translate-y: 24px !important;
  }

  .xs\:focus\:-translate-y-20px-i:focus {
    --tw-translate-y: 20px !important;
  }

  .xs\:focus\:-translate-y-15px-i:focus {
    --tw-translate-y: 15px !important;
  }

  .xs\:focus\:-translate-y-13px-i:focus {
    --tw-translate-y: 13px !important;
  }

  .xs\:focus\:-translate-y-12px-i:focus {
    --tw-translate-y: 12px !important;
  }

  .xs\:focus\:-translate-y-10px-i:focus {
    --tw-translate-y: 10px !important;
  }

  .xs\:focus\:-translate-y-8px-i:focus {
    --tw-translate-y: 8px !important;
  }

  .xs\:focus\:-translate-y-6px-i:focus {
    --tw-translate-y: 6px !important;
  }

  .xs\:focus\:-translate-y-5px-i:focus {
    --tw-translate-y: 5px !important;
  }

  .xs\:focus\:-translate-y-3px-i:focus {
    --tw-translate-y: 3px !important;
  }

  .xs\:focus\:-translate-y-0px-i:focus {
    --tw-translate-y: 0px !important;
  }

  .xs\:focus\:-translate-y-34px:focus {
    --tw-translate-y: -34px;
  }

  .xs\:focus\:-translate-y-30px:focus {
    --tw-translate-y: -30px;
  }

  .xs\:focus\:-translate-y-24px:focus {
    --tw-translate-y: -24px;
  }

  .xs\:focus\:-translate-y-20px:focus {
    --tw-translate-y: -20px;
  }

  .xs\:focus\:-translate-y-15px:focus {
    --tw-translate-y: -15px;
  }

  .xs\:focus\:-translate-y-13px:focus {
    --tw-translate-y: -13px;
  }

  .xs\:focus\:-translate-y-12px:focus {
    --tw-translate-y: -12px;
  }

  .xs\:focus\:-translate-y-10px:focus {
    --tw-translate-y: -10px;
  }

  .xs\:focus\:-translate-y-8px:focus {
    --tw-translate-y: -8px;
  }

  .xs\:focus\:-translate-y-6px:focus {
    --tw-translate-y: -6px;
  }

  .xs\:focus\:-translate-y-5px:focus {
    --tw-translate-y: -5px;
  }

  .xs\:focus\:-translate-y-3px:focus {
    --tw-translate-y: -3px;
  }

  .xs\:focus\:-translate-y-menu:focus {
    --tw-translate-y: -275px;
  }

  .xs\:focus\:-translate-y-px:focus {
    --tw-translate-y: 1px !important;
  }

  .xs\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .xs\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .xs\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .xs\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .xs\:rotate-0 {
    --tw-rotate: 0;
  }

  .xs\:rotate-1 {
    --tw-rotate: 1deg;
  }

  .xs\:rotate-2 {
    --tw-rotate: 2deg;
  }

  .xs\:rotate-3 {
    --tw-rotate: 3deg;
  }

  .xs\:rotate-6 {
    --tw-rotate: 6deg;
  }

  .xs\:rotate-12 {
    --tw-rotate: 12deg;
  }

  .xs\:rotate-45 {
    --tw-rotate: 45deg;
  }

  .xs\:rotate-90 {
    --tw-rotate: 90deg;
  }

  .xs\:rotate-180 {
    --tw-rotate: 180deg;
  }

  .xs\:-rotate-180 {
    --tw-rotate: -180deg;
  }

  .xs\:-rotate-90 {
    --tw-rotate: -90deg;
  }

  .xs\:-rotate-45 {
    --tw-rotate: -45deg;
  }

  .xs\:-rotate-12 {
    --tw-rotate: -12deg;
  }

  .xs\:-rotate-6 {
    --tw-rotate: -6deg;
  }

  .xs\:-rotate-3 {
    --tw-rotate: -3deg;
  }

  .xs\:-rotate-2 {
    --tw-rotate: -2deg;
  }

  .xs\:-rotate-1 {
    --tw-rotate: -1deg;
  }

  .xs\:hover\:rotate-0:hover {
    --tw-rotate: 0;
  }

  .xs\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .xs\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .xs\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .xs\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .xs\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .xs\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .xs\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .xs\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .xs\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .xs\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .xs\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .xs\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .xs\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .xs\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .xs\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .xs\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .xs\:focus\:rotate-0:focus {
    --tw-rotate: 0;
  }

  .xs\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .xs\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .xs\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .xs\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .xs\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .xs\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .xs\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .xs\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .xs\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .xs\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .xs\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .xs\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .xs\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .xs\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .xs\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .xs\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .xs\:skew-x-0 {
    --tw-skew-x: 0;
  }

  .xs\:skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .xs\:skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .xs\:skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .xs\:skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .xs\:skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .xs\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .xs\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .xs\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .xs\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .xs\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .xs\:skew-y-0 {
    --tw-skew-y: 0;
  }

  .xs\:skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .xs\:skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .xs\:skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .xs\:skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .xs\:skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .xs\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .xs\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .xs\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .xs\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .xs\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .xs\:hover\:skew-x-0:hover {
    --tw-skew-x: 0;
  }

  .xs\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .xs\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .xs\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .xs\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .xs\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .xs\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .xs\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .xs\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .xs\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .xs\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .xs\:hover\:skew-y-0:hover {
    --tw-skew-y: 0;
  }

  .xs\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .xs\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .xs\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .xs\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .xs\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .xs\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .xs\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .xs\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .xs\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .xs\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .xs\:focus\:skew-x-0:focus {
    --tw-skew-x: 0;
  }

  .xs\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .xs\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .xs\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .xs\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .xs\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .xs\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .xs\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .xs\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .xs\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .xs\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .xs\:focus\:skew-y-0:focus {
    --tw-skew-y: 0;
  }

  .xs\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .xs\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .xs\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .xs\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .xs\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .xs\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .xs\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .xs\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .xs\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .xs\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .xs\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xs\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xs\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xs\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xs\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xs\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xs\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xs\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xs\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xs\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xs\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xs\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xs\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xs\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xs\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xs\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xs\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xs\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xs\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xs\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xs\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xs\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xs\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xs\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xs\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xs\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xs\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xs\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xs\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xs\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xs\:scale-x-0 {
    --tw-scale-x: 0;
  }

  .xs\:scale-x-50 {
    --tw-scale-x: .5;
  }

  .xs\:scale-x-75 {
    --tw-scale-x: .75;
  }

  .xs\:scale-x-90 {
    --tw-scale-x: .9;
  }

  .xs\:scale-x-95 {
    --tw-scale-x: .95;
  }

  .xs\:scale-x-100 {
    --tw-scale-x: 1;
  }

  .xs\:scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .xs\:scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .xs\:scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .xs\:scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .xs\:scale-y-0 {
    --tw-scale-y: 0;
  }

  .xs\:scale-y-50 {
    --tw-scale-y: .5;
  }

  .xs\:scale-y-75 {
    --tw-scale-y: .75;
  }

  .xs\:scale-y-90 {
    --tw-scale-y: .9;
  }

  .xs\:scale-y-95 {
    --tw-scale-y: .95;
  }

  .xs\:scale-y-100 {
    --tw-scale-y: 1;
  }

  .xs\:scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .xs\:scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .xs\:scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .xs\:scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .xs\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .xs\:hover\:scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .xs\:hover\:scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .xs\:hover\:scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .xs\:hover\:scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .xs\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .xs\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .xs\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .xs\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .xs\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .xs\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .xs\:hover\:scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .xs\:hover\:scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .xs\:hover\:scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .xs\:hover\:scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .xs\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .xs\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .xs\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .xs\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .xs\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .xs\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .xs\:focus\:scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .xs\:focus\:scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .xs\:focus\:scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .xs\:focus\:scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .xs\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .xs\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .xs\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .xs\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .xs\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .xs\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .xs\:focus\:scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .xs\:focus\:scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .xs\:focus\:scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .xs\:focus\:scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .xs\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .xs\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .xs\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .xs\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .xs\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .xs\:animate-none {
    animation: none;
  }

  .xs\:animate-spin {
    animation: spin 1s linear infinite;
  }

  .xs\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .xs\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .xs\:animate-bounce {
    animation: bounce 1s infinite;
  }

  .xs\:cursor-auto {
    cursor: auto;
  }

  .xs\:cursor-default {
    cursor: default;
  }

  .xs\:cursor-pointer {
    cursor: pointer;
  }

  .xs\:cursor-wait {
    cursor: wait;
  }

  .xs\:cursor-text {
    cursor: text;
  }

  .xs\:cursor-move {
    cursor: move;
  }

  .xs\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .xs\:select-none {
    -webkit-user-select: none;
            user-select: none;
  }

  .xs\:select-text {
    -webkit-user-select: text;
            user-select: text;
  }

  .xs\:select-all {
    -webkit-user-select: all;
            user-select: all;
  }

  .xs\:select-auto {
    -webkit-user-select: auto;
            user-select: auto;
  }

  .xs\:resize-none {
    resize: none;
  }

  .xs\:resize-y {
    resize: vertical;
  }

  .xs\:resize-x {
    resize: horizontal;
  }

  .xs\:resize {
    resize: both;
  }

  .xs\:list-inside {
    list-style-position: inside;
  }

  .xs\:list-outside {
    list-style-position: outside;
  }

  .xs\:list-none {
    list-style-type: none;
  }

  .xs\:list-disc {
    list-style-type: disc;
  }

  .xs\:list-decimal {
    list-style-type: decimal;
  }

  .xs\:appearance-none {
    appearance: none;
  }

  .xs\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .xs\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .xs\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .xs\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .xs\:grid-flow-row {
    grid-auto-flow: row;
  }

  .xs\:grid-flow-col {
    grid-auto-flow: column;
  }

  .xs\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .xs\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .xs\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .xs\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .xs\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .xs\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .xs\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xs\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xs\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xs\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xs\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xs\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xs\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xs\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xs\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .xs\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .xs\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .xs\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xs\:grid-cols-none {
    grid-template-columns: none;
  }

  .xs\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .xs\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xs\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xs\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xs\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xs\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xs\:grid-rows-none {
    grid-template-rows: none;
  }

  .xs\:flex-row {
    flex-direction: row;
  }

  .xs\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .xs\:flex-col {
    flex-direction: column;
  }

  .xs\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .xs\:flex-wrap {
    flex-wrap: wrap;
  }

  .xs\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .xs\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .xs\:place-content-center {
    place-content: center;
  }

  .xs\:place-content-start {
    place-content: start;
  }

  .xs\:place-content-end {
    place-content: end;
  }

  .xs\:place-content-between {
    place-content: space-between;
  }

  .xs\:place-content-around {
    place-content: space-around;
  }

  .xs\:place-content-evenly {
    place-content: space-evenly;
  }

  .xs\:place-content-stretch {
    place-content: stretch;
  }

  .xs\:place-items-start {
    place-items: start;
  }

  .xs\:place-items-end {
    place-items: end;
  }

  .xs\:place-items-center {
    place-items: center;
  }

  .xs\:place-items-stretch {
    place-items: stretch;
  }

  .xs\:content-center {
    align-content: center;
  }

  .xs\:content-start {
    align-content: flex-start;
  }

  .xs\:content-end {
    align-content: flex-end;
  }

  .xs\:content-between {
    align-content: space-between;
  }

  .xs\:content-around {
    align-content: space-around;
  }

  .xs\:content-evenly {
    align-content: space-evenly;
  }

  .xs\:items-start {
    align-items: flex-start;
  }

  .xs\:items-end {
    align-items: flex-end;
  }

  .xs\:items-center {
    align-items: center;
  }

  .xs\:items-baseline {
    align-items: baseline;
  }

  .xs\:items-stretch {
    align-items: stretch;
  }

  .xs\:justify-start {
    justify-content: flex-start;
  }

  .xs\:justify-end {
    justify-content: flex-end;
  }

  .xs\:justify-center {
    justify-content: center;
  }

  .xs\:justify-between {
    justify-content: space-between;
  }

  .xs\:justify-around {
    justify-content: space-around;
  }

  .xs\:justify-evenly {
    justify-content: space-evenly;
  }

  .xs\:justify-items-start {
    justify-items: start;
  }

  .xs\:justify-items-end {
    justify-items: end;
  }

  .xs\:justify-items-center {
    justify-items: center;
  }

  .xs\:justify-items-stretch {
    justify-items: stretch;
  }

  .xs\:gap-0 {
    gap: 0;
  }

  .xs\:gap-1 {
    gap: 0.25rem;
  }

  .xs\:gap-2 {
    gap: 0.5rem;
  }

  .xs\:gap-3 {
    gap: 0.75rem;
  }

  .xs\:gap-4 {
    gap: 1rem;
  }

  .xs\:gap-5 {
    gap: 1.25rem;
  }

  .xs\:gap-6 {
    gap: 1.5rem;
  }

  .xs\:gap-8 {
    gap: 2rem;
  }

  .xs\:gap-10 {
    gap: 2.5rem;
  }

  .xs\:gap-12 {
    gap: 3rem;
  }

  .xs\:gap-16 {
    gap: 4rem;
  }

  .xs\:gap-20 {
    gap: 5rem;
  }

  .xs\:gap-24 {
    gap: 6rem;
  }

  .xs\:gap-32 {
    gap: 8rem;
  }

  .xs\:gap-40 {
    gap: 10rem;
  }

  .xs\:gap-48 {
    gap: 12rem;
  }

  .xs\:gap-56 {
    gap: 14rem;
  }

  .xs\:gap-64 {
    gap: 16rem;
  }

  .xs\:gap-300px {
    gap: 300px;
  }

  .xs\:gap-230px {
    gap: 230px;
  }

  .xs\:gap-180px {
    gap: 180px;
  }

  .xs\:gap-147px {
    gap: 147px;
  }

  .xs\:gap-123px {
    gap: 123px;
  }

  .xs\:gap-100px {
    gap: 100px;
  }

  .xs\:gap-80px {
    gap: 80px;
  }

  .xs\:gap-60px {
    gap: 60px;
  }

  .xs\:gap-50px {
    gap: 50px;
  }

  .xs\:gap-40px {
    gap: 40px;
  }

  .xs\:gap-35px {
    gap: 35px;
  }

  .xs\:gap-34px-i {
    gap: 34px !important;
  }

  .xs\:gap-30px-i {
    gap: 30px !important;
  }

  .xs\:gap-24px-i {
    gap: 24px !important;
  }

  .xs\:gap-20px-i {
    gap: 20px !important;
  }

  .xs\:gap-15px-i {
    gap: 15px !important;
  }

  .xs\:gap-13px-i {
    gap: 13px !important;
  }

  .xs\:gap-12px-i {
    gap: 12px !important;
  }

  .xs\:gap-10px-i {
    gap: 10px !important;
  }

  .xs\:gap-8px-i {
    gap: 8px !important;
  }

  .xs\:gap-6px-i {
    gap: 6px !important;
  }

  .xs\:gap-5px-i {
    gap: 5px !important;
  }

  .xs\:gap-3px-i {
    gap: 3px !important;
  }

  .xs\:gap-0px-i {
    gap: 0px !important;
  }

  .xs\:gap-34px {
    gap: 34px;
  }

  .xs\:gap-30px {
    gap: 30px;
  }

  .xs\:gap-24px {
    gap: 24px;
  }

  .xs\:gap-20px {
    gap: 20px;
  }

  .xs\:gap-15px {
    gap: 15px;
  }

  .xs\:gap-13px {
    gap: 13px;
  }

  .xs\:gap-12px {
    gap: 12px;
  }

  .xs\:gap-10px {
    gap: 10px;
  }

  .xs\:gap-8px {
    gap: 8px;
  }

  .xs\:gap-6px {
    gap: 6px;
  }

  .xs\:gap-5px {
    gap: 5px;
  }

  .xs\:gap-3px {
    gap: 3px;
  }

  .xs\:gap-menu {
    gap: 275px;
  }

  .xs\:gap-px {
    gap: 1px !important;
  }

  .xs\:gap-x-0 {
    column-gap: 0;
  }

  .xs\:gap-x-1 {
    column-gap: 0.25rem;
  }

  .xs\:gap-x-2 {
    column-gap: 0.5rem;
  }

  .xs\:gap-x-3 {
    column-gap: 0.75rem;
  }

  .xs\:gap-x-4 {
    column-gap: 1rem;
  }

  .xs\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .xs\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .xs\:gap-x-8 {
    column-gap: 2rem;
  }

  .xs\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .xs\:gap-x-12 {
    column-gap: 3rem;
  }

  .xs\:gap-x-16 {
    column-gap: 4rem;
  }

  .xs\:gap-x-20 {
    column-gap: 5rem;
  }

  .xs\:gap-x-24 {
    column-gap: 6rem;
  }

  .xs\:gap-x-32 {
    column-gap: 8rem;
  }

  .xs\:gap-x-40 {
    column-gap: 10rem;
  }

  .xs\:gap-x-48 {
    column-gap: 12rem;
  }

  .xs\:gap-x-56 {
    column-gap: 14rem;
  }

  .xs\:gap-x-64 {
    column-gap: 16rem;
  }

  .xs\:gap-x-300px {
    column-gap: 300px;
  }

  .xs\:gap-x-230px {
    column-gap: 230px;
  }

  .xs\:gap-x-180px {
    column-gap: 180px;
  }

  .xs\:gap-x-147px {
    column-gap: 147px;
  }

  .xs\:gap-x-123px {
    column-gap: 123px;
  }

  .xs\:gap-x-100px {
    column-gap: 100px;
  }

  .xs\:gap-x-80px {
    column-gap: 80px;
  }

  .xs\:gap-x-60px {
    column-gap: 60px;
  }

  .xs\:gap-x-50px {
    column-gap: 50px;
  }

  .xs\:gap-x-40px {
    column-gap: 40px;
  }

  .xs\:gap-x-35px {
    column-gap: 35px;
  }

  .xs\:gap-x-34px-i {
    column-gap: 34px !important;
  }

  .xs\:gap-x-30px-i {
    column-gap: 30px !important;
  }

  .xs\:gap-x-24px-i {
    column-gap: 24px !important;
  }

  .xs\:gap-x-20px-i {
    column-gap: 20px !important;
  }

  .xs\:gap-x-15px-i {
    column-gap: 15px !important;
  }

  .xs\:gap-x-13px-i {
    column-gap: 13px !important;
  }

  .xs\:gap-x-12px-i {
    column-gap: 12px !important;
  }

  .xs\:gap-x-10px-i {
    column-gap: 10px !important;
  }

  .xs\:gap-x-8px-i {
    column-gap: 8px !important;
  }

  .xs\:gap-x-6px-i {
    column-gap: 6px !important;
  }

  .xs\:gap-x-5px-i {
    column-gap: 5px !important;
  }

  .xs\:gap-x-3px-i {
    column-gap: 3px !important;
  }

  .xs\:gap-x-0px-i {
    column-gap: 0px !important;
  }

  .xs\:gap-x-34px {
    column-gap: 34px;
  }

  .xs\:gap-x-30px {
    column-gap: 30px;
  }

  .xs\:gap-x-24px {
    column-gap: 24px;
  }

  .xs\:gap-x-20px {
    column-gap: 20px;
  }

  .xs\:gap-x-15px {
    column-gap: 15px;
  }

  .xs\:gap-x-13px {
    column-gap: 13px;
  }

  .xs\:gap-x-12px {
    column-gap: 12px;
  }

  .xs\:gap-x-10px {
    column-gap: 10px;
  }

  .xs\:gap-x-8px {
    column-gap: 8px;
  }

  .xs\:gap-x-6px {
    column-gap: 6px;
  }

  .xs\:gap-x-5px {
    column-gap: 5px;
  }

  .xs\:gap-x-3px {
    column-gap: 3px;
  }

  .xs\:gap-x-menu {
    column-gap: 275px;
  }

  .xs\:gap-x-px {
    column-gap: 1px !important;
  }

  .xs\:gap-y-0 {
    row-gap: 0;
  }

  .xs\:gap-y-1 {
    row-gap: 0.25rem;
  }

  .xs\:gap-y-2 {
    row-gap: 0.5rem;
  }

  .xs\:gap-y-3 {
    row-gap: 0.75rem;
  }

  .xs\:gap-y-4 {
    row-gap: 1rem;
  }

  .xs\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .xs\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .xs\:gap-y-8 {
    row-gap: 2rem;
  }

  .xs\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .xs\:gap-y-12 {
    row-gap: 3rem;
  }

  .xs\:gap-y-16 {
    row-gap: 4rem;
  }

  .xs\:gap-y-20 {
    row-gap: 5rem;
  }

  .xs\:gap-y-24 {
    row-gap: 6rem;
  }

  .xs\:gap-y-32 {
    row-gap: 8rem;
  }

  .xs\:gap-y-40 {
    row-gap: 10rem;
  }

  .xs\:gap-y-48 {
    row-gap: 12rem;
  }

  .xs\:gap-y-56 {
    row-gap: 14rem;
  }

  .xs\:gap-y-64 {
    row-gap: 16rem;
  }

  .xs\:gap-y-300px {
    row-gap: 300px;
  }

  .xs\:gap-y-230px {
    row-gap: 230px;
  }

  .xs\:gap-y-180px {
    row-gap: 180px;
  }

  .xs\:gap-y-147px {
    row-gap: 147px;
  }

  .xs\:gap-y-123px {
    row-gap: 123px;
  }

  .xs\:gap-y-100px {
    row-gap: 100px;
  }

  .xs\:gap-y-80px {
    row-gap: 80px;
  }

  .xs\:gap-y-60px {
    row-gap: 60px;
  }

  .xs\:gap-y-50px {
    row-gap: 50px;
  }

  .xs\:gap-y-40px {
    row-gap: 40px;
  }

  .xs\:gap-y-35px {
    row-gap: 35px;
  }

  .xs\:gap-y-34px-i {
    row-gap: 34px !important;
  }

  .xs\:gap-y-30px-i {
    row-gap: 30px !important;
  }

  .xs\:gap-y-24px-i {
    row-gap: 24px !important;
  }

  .xs\:gap-y-20px-i {
    row-gap: 20px !important;
  }

  .xs\:gap-y-15px-i {
    row-gap: 15px !important;
  }

  .xs\:gap-y-13px-i {
    row-gap: 13px !important;
  }

  .xs\:gap-y-12px-i {
    row-gap: 12px !important;
  }

  .xs\:gap-y-10px-i {
    row-gap: 10px !important;
  }

  .xs\:gap-y-8px-i {
    row-gap: 8px !important;
  }

  .xs\:gap-y-6px-i {
    row-gap: 6px !important;
  }

  .xs\:gap-y-5px-i {
    row-gap: 5px !important;
  }

  .xs\:gap-y-3px-i {
    row-gap: 3px !important;
  }

  .xs\:gap-y-0px-i {
    row-gap: 0px !important;
  }

  .xs\:gap-y-34px {
    row-gap: 34px;
  }

  .xs\:gap-y-30px {
    row-gap: 30px;
  }

  .xs\:gap-y-24px {
    row-gap: 24px;
  }

  .xs\:gap-y-20px {
    row-gap: 20px;
  }

  .xs\:gap-y-15px {
    row-gap: 15px;
  }

  .xs\:gap-y-13px {
    row-gap: 13px;
  }

  .xs\:gap-y-12px {
    row-gap: 12px;
  }

  .xs\:gap-y-10px {
    row-gap: 10px;
  }

  .xs\:gap-y-8px {
    row-gap: 8px;
  }

  .xs\:gap-y-6px {
    row-gap: 6px;
  }

  .xs\:gap-y-5px {
    row-gap: 5px;
  }

  .xs\:gap-y-3px {
    row-gap: 3px;
  }

  .xs\:gap-y-menu {
    row-gap: 275px;
  }

  .xs\:gap-y-px {
    row-gap: 1px !important;
  }

  .xs\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(300px * var(--tw-space-x-reverse));
    margin-left: calc(300px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(230px * var(--tw-space-x-reverse));
    margin-left: calc(230px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(180px * var(--tw-space-x-reverse));
    margin-left: calc(180px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(147px * var(--tw-space-x-reverse));
    margin-left: calc(147px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(123px * var(--tw-space-x-reverse));
    margin-left: calc(123px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(100px * var(--tw-space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(80px * var(--tw-space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(60px * var(--tw-space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(50px * var(--tw-space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(40px * var(--tw-space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(35px * var(--tw-space-x-reverse));
    margin-left: calc(35px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px !important * var(--tw-space-x-reverse));
    margin-left: calc(34px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px !important * var(--tw-space-x-reverse));
    margin-left: calc(30px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px !important * var(--tw-space-x-reverse));
    margin-left: calc(24px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px !important * var(--tw-space-x-reverse));
    margin-left: calc(20px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px !important * var(--tw-space-x-reverse));
    margin-left: calc(15px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px !important * var(--tw-space-x-reverse));
    margin-left: calc(13px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px !important * var(--tw-space-x-reverse));
    margin-left: calc(12px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px !important * var(--tw-space-x-reverse));
    margin-left: calc(10px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px !important * var(--tw-space-x-reverse));
    margin-left: calc(8px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px !important * var(--tw-space-x-reverse));
    margin-left: calc(6px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px !important * var(--tw-space-x-reverse));
    margin-left: calc(5px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px !important * var(--tw-space-x-reverse));
    margin-left: calc(3px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px !important * var(--tw-space-x-reverse));
    margin-left: calc(0px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px * var(--tw-space-x-reverse));
    margin-left: calc(34px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px * var(--tw-space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px * var(--tw-space-x-reverse));
    margin-left: calc(24px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px * var(--tw-space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px * var(--tw-space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px * var(--tw-space-x-reverse));
    margin-left: calc(13px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px * var(--tw-space-x-reverse));
    margin-left: calc(12px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px * var(--tw-space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px * var(--tw-space-x-reverse));
    margin-left: calc(8px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px * var(--tw-space-x-reverse));
    margin-left: calc(6px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px * var(--tw-space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px * var(--tw-space-x-reverse));
    margin-left: calc(3px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(275px * var(--tw-space-x-reverse));
    margin-left: calc(275px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px !important * var(--tw-space-x-reverse));
    margin-left: calc(1px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-300px * var(--tw-space-x-reverse));
    margin-left: calc(-300px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-230px * var(--tw-space-x-reverse));
    margin-left: calc(-230px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-180px * var(--tw-space-x-reverse));
    margin-left: calc(-180px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-147px * var(--tw-space-x-reverse));
    margin-left: calc(-147px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-123px * var(--tw-space-x-reverse));
    margin-left: calc(-123px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-100px * var(--tw-space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-80px * var(--tw-space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-60px * var(--tw-space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-50px * var(--tw-space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-40px * var(--tw-space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-35px * var(--tw-space-x-reverse));
    margin-left: calc(-35px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px !important * var(--tw-space-x-reverse));
    margin-left: calc(34px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px !important * var(--tw-space-x-reverse));
    margin-left: calc(30px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px !important * var(--tw-space-x-reverse));
    margin-left: calc(24px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px !important * var(--tw-space-x-reverse));
    margin-left: calc(20px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px !important * var(--tw-space-x-reverse));
    margin-left: calc(15px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px !important * var(--tw-space-x-reverse));
    margin-left: calc(13px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px !important * var(--tw-space-x-reverse));
    margin-left: calc(12px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px !important * var(--tw-space-x-reverse));
    margin-left: calc(10px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px !important * var(--tw-space-x-reverse));
    margin-left: calc(8px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px !important * var(--tw-space-x-reverse));
    margin-left: calc(6px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px !important * var(--tw-space-x-reverse));
    margin-left: calc(5px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px !important * var(--tw-space-x-reverse));
    margin-left: calc(3px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px !important * var(--tw-space-x-reverse));
    margin-left: calc(0px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-34px * var(--tw-space-x-reverse));
    margin-left: calc(-34px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-30px * var(--tw-space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24px * var(--tw-space-x-reverse));
    margin-left: calc(-24px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20px * var(--tw-space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15px * var(--tw-space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13px * var(--tw-space-x-reverse));
    margin-left: calc(-13px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12px * var(--tw-space-x-reverse));
    margin-left: calc(-12px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10px * var(--tw-space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8px * var(--tw-space-x-reverse));
    margin-left: calc(-8px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6px * var(--tw-space-x-reverse));
    margin-left: calc(-6px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5px * var(--tw-space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3px * var(--tw-space-x-reverse));
    margin-left: calc(-3px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-275px * var(--tw-space-x-reverse));
    margin-left: calc(-275px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px !important * var(--tw-space-x-reverse));
    margin-left: calc(1px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xs\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .xs\:space-y-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(300px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(300px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(230px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(230px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(180px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(180px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(147px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(147px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(123px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(123px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(100px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(80px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(60px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(50px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(40px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(35px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(35px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(275px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(275px * var(--tw-space-y-reverse));
  }

  .xs\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-300px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-300px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-230px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-230px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-180px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-180px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-147px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-147px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-123px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-123px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-100px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-80px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-60px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-50px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-40px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-35px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-35px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px !important * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-34px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-34px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-30px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-275px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-275px * var(--tw-space-y-reverse));
  }

  .xs\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px !important * var(--tw-space-y-reverse));
  }

  .xs\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .xs\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .xs\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xs\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xs\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xs\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xs\:divide-x-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xs\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .xs\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .xs\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .xs\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .xs\:divide-y-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .xs\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .xs\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .xs\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .xs\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .xs\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .xs\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .xs\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .xs\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }

  .xs\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .xs\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-divide-opacity));
  }

  .xs\:divide-black40pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-divide-opacity));
  }

  .xs\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .xs\:divide-petroleum > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-divide-opacity));
  }

  .xs\:divide-petroleum70pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-divide-opacity));
  }

  .xs\:divide-petroleum30pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-divide-opacity));
  }

  .xs\:divide-disabled > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-divide-opacity));
  }

  .xs\:divide-petroleum10pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-divide-opacity));
  }

  .xs\:divide-petroleum5pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-divide-opacity));
  }

  .xs\:divide-darkGreen > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-divide-opacity));
  }

  .xs\:divide-ok > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-divide-opacity));
  }

  .xs\:divide-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-divide-opacity));
  }

  .xs\:divide-green10pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-divide-opacity));
  }

  .xs\:divide-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-divide-opacity));
  }

  .xs\:divide-darkOrange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-divide-opacity));
  }

  .xs\:divide-lightOrange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-divide-opacity));
  }

  .xs\:divide-error > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-divide-opacity));
  }

  .xs\:divide-yellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-divide-opacity));
  }

  .xs\:divide-darkYellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-divide-opacity));
  }

  .xs\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-divide-opacity));
  }

  .xs\:divide-lightGray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-divide-opacity));
  }

  .xs\:divide-borderGray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-divide-opacity));
  }

  .xs\:divide-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-divide-opacity));
  }

  .xs\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .xs\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }

  .xs\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }

  .xs\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }

  .xs\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .xs\:place-self-auto {
    place-self: auto;
  }

  .xs\:place-self-start {
    place-self: start;
  }

  .xs\:place-self-end {
    place-self: end;
  }

  .xs\:place-self-center {
    place-self: center;
  }

  .xs\:place-self-stretch {
    place-self: stretch;
  }

  .xs\:self-auto {
    align-self: auto;
  }

  .xs\:self-start {
    align-self: flex-start;
  }

  .xs\:self-end {
    align-self: flex-end;
  }

  .xs\:self-center {
    align-self: center;
  }

  .xs\:self-stretch {
    align-self: stretch;
  }

  .xs\:self-baseline {
    align-self: baseline;
  }

  .xs\:justify-self-auto {
    justify-self: auto;
  }

  .xs\:justify-self-start {
    justify-self: start;
  }

  .xs\:justify-self-end {
    justify-self: end;
  }

  .xs\:justify-self-center {
    justify-self: center;
  }

  .xs\:justify-self-stretch {
    justify-self: stretch;
  }

  .xs\:overflow-auto {
    overflow: auto;
  }

  .xs\:overflow-hidden {
    overflow: hidden;
  }

  .xs\:overflow-visible {
    overflow: visible;
  }

  .xs\:overflow-scroll {
    overflow: scroll;
  }

  .xs\:overflow-x-auto {
    overflow-x: auto;
  }

  .xs\:overflow-y-auto {
    overflow-y: auto;
  }

  .xs\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .xs\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .xs\:overflow-x-visible {
    overflow-x: visible;
  }

  .xs\:overflow-y-visible {
    overflow-y: visible;
  }

  .xs\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .xs\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .xs\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .xs\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .xs\:overscroll-none {
    overscroll-behavior: none;
  }

  .xs\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .xs\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .xs\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .xs\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .xs\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .xs\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .xs\:whitespace-normal {
    white-space: normal;
  }

  .xs\:whitespace-nowrap {
    white-space: nowrap;
  }

  .xs\:whitespace-pre {
    white-space: pre;
  }

  .xs\:whitespace-pre-line {
    white-space: pre-line;
  }

  .xs\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .xs\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .xs\:break-words {
    overflow-wrap: break-word;
  }

  .xs\:break-all {
    word-break: break-all;
  }

  .xs\:rounded-none {
    border-radius: 0;
  }

  .xs\:rounded-5px {
    border-radius: 5px;
  }

  .xs\:rounded-sm {
    border-radius: 0.125rem;
  }

  .xs\:rounded-default {
    border-radius: 0.25rem;
  }

  .xs\:rounded-md {
    border-radius: 0.375rem;
  }

  .xs\:rounded-lg {
    border-radius: 0.5rem;
  }

  .xs\:rounded-xl {
    border-radius: 0.75rem;
  }

  .xs\:rounded-2xl {
    border-radius: 1rem;
  }

  .xs\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .xs\:rounded-full {
    border-radius: 9999px;
  }

  .xs\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .xs\:rounded-t-5px {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .xs\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .xs\:rounded-t-default {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .xs\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .xs\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .xs\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .xs\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .xs\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .xs\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .xs\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .xs\:rounded-r-5px {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .xs\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .xs\:rounded-r-default {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .xs\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .xs\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .xs\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .xs\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .xs\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .xs\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .xs\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xs\:rounded-b-5px {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .xs\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .xs\:rounded-b-default {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .xs\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .xs\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .xs\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .xs\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xs\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xs\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xs\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xs\:rounded-l-5px {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .xs\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .xs\:rounded-l-default {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .xs\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .xs\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .xs\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .xs\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xs\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xs\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xs\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .xs\:rounded-tl-5px {
    border-top-left-radius: 5px;
  }

  .xs\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .xs\:rounded-tl-default {
    border-top-left-radius: 0.25rem;
  }

  .xs\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .xs\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .xs\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .xs\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .xs\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .xs\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .xs\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .xs\:rounded-tr-5px {
    border-top-right-radius: 5px;
  }

  .xs\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .xs\:rounded-tr-default {
    border-top-right-radius: 0.25rem;
  }

  .xs\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .xs\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .xs\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .xs\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .xs\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .xs\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .xs\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .xs\:rounded-br-5px {
    border-bottom-right-radius: 5px;
  }

  .xs\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .xs\:rounded-br-default {
    border-bottom-right-radius: 0.25rem;
  }

  .xs\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .xs\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .xs\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .xs\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .xs\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .xs\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .xs\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .xs\:rounded-bl-5px {
    border-bottom-left-radius: 5px;
  }

  .xs\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .xs\:rounded-bl-default {
    border-bottom-left-radius: 0.25rem;
  }

  .xs\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .xs\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .xs\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .xs\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .xs\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .xs\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .xs\:border-0 {
    border-width: 0;
  }

  .xs\:border-2 {
    border-width: 2px;
  }

  .xs\:border-4 {
    border-width: 4px;
  }

  .xs\:border-8 {
    border-width: 8px;
  }

  .xs\:border-default {
    border-width: 1px;
  }

  .xs\:border-t-0 {
    border-top-width: 0;
  }

  .xs\:border-t-2 {
    border-top-width: 2px;
  }

  .xs\:border-t-4 {
    border-top-width: 4px;
  }

  .xs\:border-t-8 {
    border-top-width: 8px;
  }

  .xs\:border-t-default {
    border-top-width: 1px;
  }

  .xs\:border-r-0 {
    border-right-width: 0;
  }

  .xs\:border-r-2 {
    border-right-width: 2px;
  }

  .xs\:border-r-4 {
    border-right-width: 4px;
  }

  .xs\:border-r-8 {
    border-right-width: 8px;
  }

  .xs\:border-r-default {
    border-right-width: 1px;
  }

  .xs\:border-b-0 {
    border-bottom-width: 0;
  }

  .xs\:border-b-2 {
    border-bottom-width: 2px;
  }

  .xs\:border-b-4 {
    border-bottom-width: 4px;
  }

  .xs\:border-b-8 {
    border-bottom-width: 8px;
  }

  .xs\:border-b-default {
    border-bottom-width: 1px;
  }

  .xs\:border-l-0 {
    border-left-width: 0;
  }

  .xs\:border-l-2 {
    border-left-width: 2px;
  }

  .xs\:border-l-4 {
    border-left-width: 4px;
  }

  .xs\:border-l-8 {
    border-left-width: 8px;
  }

  .xs\:border-l-default {
    border-left-width: 1px;
  }

  .xs\:border-solid {
    border-style: solid;
  }

  .xs\:border-dashed {
    border-style: dashed;
  }

  .xs\:border-dotted {
    border-style: dotted;
  }

  .xs\:border-double {
    border-style: double;
  }

  .xs\:border-none {
    border-style: none;
  }

  .xs\:border-transparent {
    border-color: transparent;
  }

  .xs\:border-current {
    border-color: currentColor;
  }

  .xs\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .xs\:border-black40pct {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .xs\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xs\:border-petroleum {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .xs\:border-petroleum70pct {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .xs\:border-petroleum30pct {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .xs\:border-disabled {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .xs\:border-petroleum10pct {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .xs\:border-petroleum5pct {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .xs\:border-darkGreen {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .xs\:border-ok {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .xs\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .xs\:border-green10pct {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .xs\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .xs\:border-darkOrange {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .xs\:border-lightOrange {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .xs\:border-error {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .xs\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .xs\:border-darkYellow {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .xs\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .xs\:border-lightGray {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .xs\:border-borderGray {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .xs\:border-default {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .xs\:hover\:border-transparent:hover {
    border-color: transparent;
  }

  .xs\:hover\:border-current:hover {
    border-color: currentColor;
  }

  .xs\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .xs\:hover\:border-black40pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .xs\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xs\:hover\:border-petroleum:hover {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .xs\:hover\:border-petroleum70pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .xs\:hover\:border-petroleum30pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .xs\:hover\:border-disabled:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .xs\:hover\:border-petroleum10pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .xs\:hover\:border-petroleum5pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .xs\:hover\:border-darkGreen:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .xs\:hover\:border-ok:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .xs\:hover\:border-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .xs\:hover\:border-green10pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .xs\:hover\:border-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .xs\:hover\:border-darkOrange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .xs\:hover\:border-lightOrange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .xs\:hover\:border-error:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .xs\:hover\:border-yellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .xs\:hover\:border-darkYellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .xs\:hover\:border-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .xs\:hover\:border-lightGray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .xs\:hover\:border-borderGray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .xs\:hover\:border-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .xs\:focus\:border-transparent:focus {
    border-color: transparent;
  }

  .xs\:focus\:border-current:focus {
    border-color: currentColor;
  }

  .xs\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .xs\:focus\:border-black40pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .xs\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xs\:focus\:border-petroleum:focus {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .xs\:focus\:border-petroleum70pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .xs\:focus\:border-petroleum30pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .xs\:focus\:border-disabled:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .xs\:focus\:border-petroleum10pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .xs\:focus\:border-petroleum5pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .xs\:focus\:border-darkGreen:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .xs\:focus\:border-ok:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .xs\:focus\:border-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .xs\:focus\:border-green10pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .xs\:focus\:border-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .xs\:focus\:border-darkOrange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .xs\:focus\:border-lightOrange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .xs\:focus\:border-error:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .xs\:focus\:border-yellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .xs\:focus\:border-darkYellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .xs\:focus\:border-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .xs\:focus\:border-lightGray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .xs\:focus\:border-borderGray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .xs\:focus\:border-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .xs\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .xs\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .xs\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .xs\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .xs\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .xs\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .xs\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }

  .xs\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }

  .xs\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }

  .xs\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .xs\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .xs\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }

  .xs\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }

  .xs\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }

  .xs\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .xs\:bg-transparent {
    background-color: transparent;
  }

  .xs\:bg-current {
    background-color: currentColor;
  }

  .xs\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .xs\:bg-black40pct {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .xs\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xs\:bg-petroleum {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .xs\:bg-petroleum70pct {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .xs\:bg-petroleum30pct {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .xs\:bg-disabled {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .xs\:bg-petroleum10pct {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .xs\:bg-petroleum5pct {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .xs\:bg-darkGreen {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .xs\:bg-ok {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .xs\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .xs\:bg-green10pct {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .xs\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .xs\:bg-darkOrange {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .xs\:bg-lightOrange {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .xs\:bg-error {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .xs\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .xs\:bg-darkYellow {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .xs\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .xs\:bg-lightGray {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .xs\:bg-borderGray {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-transparent:hover {
    background-color: transparent;
  }

  .xs\:hover\:bg-current:hover {
    background-color: currentColor;
  }

  .xs\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-black40pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-petroleum:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-petroleum70pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-petroleum30pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-disabled:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-petroleum10pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-petroleum5pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-darkGreen:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-ok:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-green10pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-darkOrange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-lightOrange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-error:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-yellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-darkYellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-lightGray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .xs\:hover\:bg-borderGray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-transparent:focus {
    background-color: transparent;
  }

  .xs\:focus\:bg-current:focus {
    background-color: currentColor;
  }

  .xs\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-black40pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-petroleum:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-petroleum70pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-petroleum30pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-disabled:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-petroleum10pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-petroleum5pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-darkGreen:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-ok:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-green10pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-darkOrange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-lightOrange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-error:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-yellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-darkYellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-lightGray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .xs\:focus\:bg-borderGray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .xs\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .xs\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .xs\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .xs\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .xs\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .xs\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .xs\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }

  .xs\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }

  .xs\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }

  .xs\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .xs\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .xs\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }

  .xs\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }

  .xs\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }

  .xs\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .xs\:bg-none {
    background-image: none;
  }

  .xs\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .xs\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .xs\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .xs\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .xs\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .xs\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .xs\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .xs\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .xs\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xs\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xs\:from-black {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .xs\:from-black40pct {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .xs\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xs\:from-petroleum {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .xs\:from-petroleum70pct {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .xs\:from-petroleum30pct {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xs\:from-disabled {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xs\:from-petroleum10pct {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .xs\:from-petroleum5pct {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .xs\:from-darkGreen {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xs\:from-ok {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xs\:from-green {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .xs\:from-green10pct {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .xs\:from-orange {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .xs\:from-darkOrange {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xs\:from-lightOrange {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .xs\:from-error {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xs\:from-yellow {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .xs\:from-darkYellow {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .xs\:from-gray {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .xs\:from-lightGray {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .xs\:from-borderGray {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .xs\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xs\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xs\:hover\:from-black:hover {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .xs\:hover\:from-black40pct:hover {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .xs\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xs\:hover\:from-petroleum:hover {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .xs\:hover\:from-petroleum70pct:hover {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .xs\:hover\:from-petroleum30pct:hover {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xs\:hover\:from-disabled:hover {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xs\:hover\:from-petroleum10pct:hover {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .xs\:hover\:from-petroleum5pct:hover {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .xs\:hover\:from-darkGreen:hover {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xs\:hover\:from-ok:hover {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xs\:hover\:from-green:hover {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .xs\:hover\:from-green10pct:hover {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .xs\:hover\:from-orange:hover {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .xs\:hover\:from-darkOrange:hover {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xs\:hover\:from-lightOrange:hover {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .xs\:hover\:from-error:hover {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xs\:hover\:from-yellow:hover {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .xs\:hover\:from-darkYellow:hover {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .xs\:hover\:from-gray:hover {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .xs\:hover\:from-lightGray:hover {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .xs\:hover\:from-borderGray:hover {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .xs\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xs\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xs\:focus\:from-black:focus {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .xs\:focus\:from-black40pct:focus {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .xs\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xs\:focus\:from-petroleum:focus {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .xs\:focus\:from-petroleum70pct:focus {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .xs\:focus\:from-petroleum30pct:focus {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xs\:focus\:from-disabled:focus {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xs\:focus\:from-petroleum10pct:focus {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .xs\:focus\:from-petroleum5pct:focus {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .xs\:focus\:from-darkGreen:focus {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xs\:focus\:from-ok:focus {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xs\:focus\:from-green:focus {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .xs\:focus\:from-green10pct:focus {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .xs\:focus\:from-orange:focus {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .xs\:focus\:from-darkOrange:focus {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xs\:focus\:from-lightOrange:focus {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .xs\:focus\:from-error:focus {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xs\:focus\:from-yellow:focus {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .xs\:focus\:from-darkYellow:focus {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .xs\:focus\:from-gray:focus {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .xs\:focus\:from-lightGray:focus {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .xs\:focus\:from-borderGray:focus {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .xs\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xs\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xs\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .xs\:via-black40pct {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .xs\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xs\:via-petroleum {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .xs\:via-petroleum70pct {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .xs\:via-petroleum30pct {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xs\:via-disabled {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xs\:via-petroleum10pct {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .xs\:via-petroleum5pct {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .xs\:via-darkGreen {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xs\:via-ok {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xs\:via-green {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .xs\:via-green10pct {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .xs\:via-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .xs\:via-darkOrange {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xs\:via-lightOrange {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .xs\:via-error {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xs\:via-yellow {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .xs\:via-darkYellow {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .xs\:via-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .xs\:via-lightGray {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .xs\:via-borderGray {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .xs\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xs\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xs\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .xs\:hover\:via-black40pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .xs\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xs\:hover\:via-petroleum:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .xs\:hover\:via-petroleum70pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .xs\:hover\:via-petroleum30pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xs\:hover\:via-disabled:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xs\:hover\:via-petroleum10pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .xs\:hover\:via-petroleum5pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .xs\:hover\:via-darkGreen:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xs\:hover\:via-ok:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xs\:hover\:via-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .xs\:hover\:via-green10pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .xs\:hover\:via-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .xs\:hover\:via-darkOrange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xs\:hover\:via-lightOrange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .xs\:hover\:via-error:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xs\:hover\:via-yellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .xs\:hover\:via-darkYellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .xs\:hover\:via-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .xs\:hover\:via-lightGray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .xs\:hover\:via-borderGray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .xs\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xs\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xs\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .xs\:focus\:via-black40pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .xs\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xs\:focus\:via-petroleum:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .xs\:focus\:via-petroleum70pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .xs\:focus\:via-petroleum30pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xs\:focus\:via-disabled:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xs\:focus\:via-petroleum10pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .xs\:focus\:via-petroleum5pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .xs\:focus\:via-darkGreen:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xs\:focus\:via-ok:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xs\:focus\:via-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .xs\:focus\:via-green10pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .xs\:focus\:via-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .xs\:focus\:via-darkOrange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xs\:focus\:via-lightOrange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .xs\:focus\:via-error:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xs\:focus\:via-yellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .xs\:focus\:via-darkYellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .xs\:focus\:via-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .xs\:focus\:via-lightGray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .xs\:focus\:via-borderGray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .xs\:to-transparent {
    --tw-gradient-to: transparent;
  }

  .xs\:to-current {
    --tw-gradient-to: currentColor;
  }

  .xs\:to-black {
    --tw-gradient-to: #1b1b1a;
  }

  .xs\:to-black40pct {
    --tw-gradient-to: #a4a4a3;
  }

  .xs\:to-white {
    --tw-gradient-to: #fff;
  }

  .xs\:to-petroleum {
    --tw-gradient-to: #0e4744;
  }

  .xs\:to-petroleum70pct {
    --tw-gradient-to: #567e7c;
  }

  .xs\:to-petroleum30pct {
    --tw-gradient-to: #b7c8c7;
  }

  .xs\:to-disabled {
    --tw-gradient-to: #b7c8c7;
  }

  .xs\:to-petroleum10pct {
    --tw-gradient-to: #e7edec;
  }

  .xs\:to-petroleum5pct {
    --tw-gradient-to: #f3f6f6;
  }

  .xs\:to-darkGreen {
    --tw-gradient-to: #267b38;
  }

  .xs\:to-ok {
    --tw-gradient-to: #267b38;
  }

  .xs\:to-green {
    --tw-gradient-to: #2ea149;
  }

  .xs\:to-green10pct {
    --tw-gradient-to: #eaf6ed;
  }

  .xs\:to-orange {
    --tw-gradient-to: #ed7100;
  }

  .xs\:to-darkOrange {
    --tw-gradient-to: #aa3636;
  }

  .xs\:to-lightOrange {
    --tw-gradient-to: #fbe3cc;
  }

  .xs\:to-error {
    --tw-gradient-to: #aa3636;
  }

  .xs\:to-yellow {
    --tw-gradient-to: #e9c258;
  }

  .xs\:to-darkYellow {
    --tw-gradient-to: #c3b091;
  }

  .xs\:to-gray {
    --tw-gradient-to: #949493;
  }

  .xs\:to-lightGray {
    --tw-gradient-to: #e5e5e5;
  }

  .xs\:to-borderGray {
    --tw-gradient-to: #e6e6e6;
  }

  .xs\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .xs\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .xs\:hover\:to-black:hover {
    --tw-gradient-to: #1b1b1a;
  }

  .xs\:hover\:to-black40pct:hover {
    --tw-gradient-to: #a4a4a3;
  }

  .xs\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }

  .xs\:hover\:to-petroleum:hover {
    --tw-gradient-to: #0e4744;
  }

  .xs\:hover\:to-petroleum70pct:hover {
    --tw-gradient-to: #567e7c;
  }

  .xs\:hover\:to-petroleum30pct:hover {
    --tw-gradient-to: #b7c8c7;
  }

  .xs\:hover\:to-disabled:hover {
    --tw-gradient-to: #b7c8c7;
  }

  .xs\:hover\:to-petroleum10pct:hover {
    --tw-gradient-to: #e7edec;
  }

  .xs\:hover\:to-petroleum5pct:hover {
    --tw-gradient-to: #f3f6f6;
  }

  .xs\:hover\:to-darkGreen:hover {
    --tw-gradient-to: #267b38;
  }

  .xs\:hover\:to-ok:hover {
    --tw-gradient-to: #267b38;
  }

  .xs\:hover\:to-green:hover {
    --tw-gradient-to: #2ea149;
  }

  .xs\:hover\:to-green10pct:hover {
    --tw-gradient-to: #eaf6ed;
  }

  .xs\:hover\:to-orange:hover {
    --tw-gradient-to: #ed7100;
  }

  .xs\:hover\:to-darkOrange:hover {
    --tw-gradient-to: #aa3636;
  }

  .xs\:hover\:to-lightOrange:hover {
    --tw-gradient-to: #fbe3cc;
  }

  .xs\:hover\:to-error:hover {
    --tw-gradient-to: #aa3636;
  }

  .xs\:hover\:to-yellow:hover {
    --tw-gradient-to: #e9c258;
  }

  .xs\:hover\:to-darkYellow:hover {
    --tw-gradient-to: #c3b091;
  }

  .xs\:hover\:to-gray:hover {
    --tw-gradient-to: #949493;
  }

  .xs\:hover\:to-lightGray:hover {
    --tw-gradient-to: #e5e5e5;
  }

  .xs\:hover\:to-borderGray:hover {
    --tw-gradient-to: #e6e6e6;
  }

  .xs\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .xs\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .xs\:focus\:to-black:focus {
    --tw-gradient-to: #1b1b1a;
  }

  .xs\:focus\:to-black40pct:focus {
    --tw-gradient-to: #a4a4a3;
  }

  .xs\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }

  .xs\:focus\:to-petroleum:focus {
    --tw-gradient-to: #0e4744;
  }

  .xs\:focus\:to-petroleum70pct:focus {
    --tw-gradient-to: #567e7c;
  }

  .xs\:focus\:to-petroleum30pct:focus {
    --tw-gradient-to: #b7c8c7;
  }

  .xs\:focus\:to-disabled:focus {
    --tw-gradient-to: #b7c8c7;
  }

  .xs\:focus\:to-petroleum10pct:focus {
    --tw-gradient-to: #e7edec;
  }

  .xs\:focus\:to-petroleum5pct:focus {
    --tw-gradient-to: #f3f6f6;
  }

  .xs\:focus\:to-darkGreen:focus {
    --tw-gradient-to: #267b38;
  }

  .xs\:focus\:to-ok:focus {
    --tw-gradient-to: #267b38;
  }

  .xs\:focus\:to-green:focus {
    --tw-gradient-to: #2ea149;
  }

  .xs\:focus\:to-green10pct:focus {
    --tw-gradient-to: #eaf6ed;
  }

  .xs\:focus\:to-orange:focus {
    --tw-gradient-to: #ed7100;
  }

  .xs\:focus\:to-darkOrange:focus {
    --tw-gradient-to: #aa3636;
  }

  .xs\:focus\:to-lightOrange:focus {
    --tw-gradient-to: #fbe3cc;
  }

  .xs\:focus\:to-error:focus {
    --tw-gradient-to: #aa3636;
  }

  .xs\:focus\:to-yellow:focus {
    --tw-gradient-to: #e9c258;
  }

  .xs\:focus\:to-darkYellow:focus {
    --tw-gradient-to: #c3b091;
  }

  .xs\:focus\:to-gray:focus {
    --tw-gradient-to: #949493;
  }

  .xs\:focus\:to-lightGray:focus {
    --tw-gradient-to: #e5e5e5;
  }

  .xs\:focus\:to-borderGray:focus {
    --tw-gradient-to: #e6e6e6;
  }

  .xs\:bg-auto {
    background-size: auto;
  }

  .xs\:bg-cover {
    background-size: cover;
  }

  .xs\:bg-contain {
    background-size: contain;
  }

  .xs\:bg-fixed {
    background-attachment: fixed;
  }

  .xs\:bg-local {
    background-attachment: local;
  }

  .xs\:bg-scroll {
    background-attachment: scroll;
  }

  .xs\:bg-clip-border {
    background-clip: border-box;
  }

  .xs\:bg-clip-padding {
    background-clip: padding-box;
  }

  .xs\:bg-clip-content {
    background-clip: content-box;
  }

  .xs\:bg-clip-text {
    background-clip: text;
  }

  .xs\:bg-bottom {
    background-position: bottom;
  }

  .xs\:bg-center {
    background-position: center;
  }

  .xs\:bg-left {
    background-position: left;
  }

  .xs\:bg-left-bottom {
    background-position: left bottom;
  }

  .xs\:bg-left-top {
    background-position: left top;
  }

  .xs\:bg-right {
    background-position: right;
  }

  .xs\:bg-right-bottom {
    background-position: right bottom;
  }

  .xs\:bg-right-top {
    background-position: right top;
  }

  .xs\:bg-top {
    background-position: top;
  }

  .xs\:bg-repeat {
    background-repeat: repeat;
  }

  .xs\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .xs\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .xs\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .xs\:bg-repeat-round {
    background-repeat: round;
  }

  .xs\:bg-repeat-space {
    background-repeat: space;
  }

  .xs\:fill-current {
    fill: currentColor;
  }

  .xs\:stroke-current {
    stroke: currentColor;
  }

  .xs\:stroke-0 {
    stroke-width: 0;
  }

  .xs\:stroke-1 {
    stroke-width: 1;
  }

  .xs\:stroke-2 {
    stroke-width: 2;
  }

  .xs\:object-contain {
    object-fit: contain;
  }

  .xs\:object-cover {
    object-fit: cover;
  }

  .xs\:object-fill {
    object-fit: fill;
  }

  .xs\:object-none {
    object-fit: none;
  }

  .xs\:object-scale-down {
    object-fit: scale-down;
  }

  .xs\:object-bottom {
    object-position: bottom;
  }

  .xs\:object-center {
    object-position: center;
  }

  .xs\:object-left {
    object-position: left;
  }

  .xs\:object-left-bottom {
    object-position: left bottom;
  }

  .xs\:object-left-top {
    object-position: left top;
  }

  .xs\:object-right {
    object-position: right;
  }

  .xs\:object-right-bottom {
    object-position: right bottom;
  }

  .xs\:object-right-top {
    object-position: right top;
  }

  .xs\:object-top {
    object-position: top;
  }

  .xs\:p-0 {
    padding: 0;
  }

  .xs\:p-1 {
    padding: 0.25rem;
  }

  .xs\:p-2 {
    padding: 0.5rem;
  }

  .xs\:p-3 {
    padding: 0.75rem;
  }

  .xs\:p-4 {
    padding: 1rem;
  }

  .xs\:p-5 {
    padding: 1.25rem;
  }

  .xs\:p-6 {
    padding: 1.5rem;
  }

  .xs\:p-8 {
    padding: 2rem;
  }

  .xs\:p-10 {
    padding: 2.5rem;
  }

  .xs\:p-12 {
    padding: 3rem;
  }

  .xs\:p-16 {
    padding: 4rem;
  }

  .xs\:p-20 {
    padding: 5rem;
  }

  .xs\:p-24 {
    padding: 6rem;
  }

  .xs\:p-32 {
    padding: 8rem;
  }

  .xs\:p-40 {
    padding: 10rem;
  }

  .xs\:p-48 {
    padding: 12rem;
  }

  .xs\:p-56 {
    padding: 14rem;
  }

  .xs\:p-64 {
    padding: 16rem;
  }

  .xs\:p-300px {
    padding: 300px;
  }

  .xs\:p-230px {
    padding: 230px;
  }

  .xs\:p-180px {
    padding: 180px;
  }

  .xs\:p-147px {
    padding: 147px;
  }

  .xs\:p-123px {
    padding: 123px;
  }

  .xs\:p-100px {
    padding: 100px;
  }

  .xs\:p-80px {
    padding: 80px;
  }

  .xs\:p-60px {
    padding: 60px;
  }

  .xs\:p-50px {
    padding: 50px;
  }

  .xs\:p-40px {
    padding: 40px;
  }

  .xs\:p-35px {
    padding: 35px;
  }

  .xs\:p-34px-i {
    padding: 34px !important;
  }

  .xs\:p-30px-i {
    padding: 30px !important;
  }

  .xs\:p-24px-i {
    padding: 24px !important;
  }

  .xs\:p-20px-i {
    padding: 20px !important;
  }

  .xs\:p-15px-i {
    padding: 15px !important;
  }

  .xs\:p-13px-i {
    padding: 13px !important;
  }

  .xs\:p-12px-i {
    padding: 12px !important;
  }

  .xs\:p-10px-i {
    padding: 10px !important;
  }

  .xs\:p-8px-i {
    padding: 8px !important;
  }

  .xs\:p-6px-i {
    padding: 6px !important;
  }

  .xs\:p-5px-i {
    padding: 5px !important;
  }

  .xs\:p-3px-i {
    padding: 3px !important;
  }

  .xs\:p-0px-i {
    padding: 0px !important;
  }

  .xs\:p-34px {
    padding: 34px;
  }

  .xs\:p-30px {
    padding: 30px;
  }

  .xs\:p-24px {
    padding: 24px;
  }

  .xs\:p-20px {
    padding: 20px;
  }

  .xs\:p-15px {
    padding: 15px;
  }

  .xs\:p-13px {
    padding: 13px;
  }

  .xs\:p-12px {
    padding: 12px;
  }

  .xs\:p-10px {
    padding: 10px;
  }

  .xs\:p-8px {
    padding: 8px;
  }

  .xs\:p-6px {
    padding: 6px;
  }

  .xs\:p-5px {
    padding: 5px;
  }

  .xs\:p-3px {
    padding: 3px;
  }

  .xs\:p-menu {
    padding: 275px;
  }

  .xs\:p-px {
    padding: 1px !important;
  }

  .xs\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xs\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .xs\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .xs\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .xs\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xs\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xs\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xs\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xs\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xs\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xs\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xs\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xs\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xs\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .xs\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .xs\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .xs\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .xs\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .xs\:px-300px {
    padding-left: 300px;
    padding-right: 300px;
  }

  .xs\:px-230px {
    padding-left: 230px;
    padding-right: 230px;
  }

  .xs\:px-180px {
    padding-left: 180px;
    padding-right: 180px;
  }

  .xs\:px-147px {
    padding-left: 147px;
    padding-right: 147px;
  }

  .xs\:px-123px {
    padding-left: 123px;
    padding-right: 123px;
  }

  .xs\:px-100px {
    padding-left: 100px;
    padding-right: 100px;
  }

  .xs\:px-80px {
    padding-left: 80px;
    padding-right: 80px;
  }

  .xs\:px-60px {
    padding-left: 60px;
    padding-right: 60px;
  }

  .xs\:px-50px {
    padding-left: 50px;
    padding-right: 50px;
  }

  .xs\:px-40px {
    padding-left: 40px;
    padding-right: 40px;
  }

  .xs\:px-35px {
    padding-left: 35px;
    padding-right: 35px;
  }

  .xs\:px-34px-i {
    padding-left: 34px !important;
    padding-right: 34px !important;
  }

  .xs\:px-30px-i {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .xs\:px-24px-i {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .xs\:px-20px-i {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .xs\:px-15px-i {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .xs\:px-13px-i {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }

  .xs\:px-12px-i {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .xs\:px-10px-i {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .xs\:px-8px-i {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .xs\:px-6px-i {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .xs\:px-5px-i {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .xs\:px-3px-i {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .xs\:px-0px-i {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .xs\:px-34px {
    padding-left: 34px;
    padding-right: 34px;
  }

  .xs\:px-30px {
    padding-left: 30px;
    padding-right: 30px;
  }

  .xs\:px-24px {
    padding-left: 24px;
    padding-right: 24px;
  }

  .xs\:px-20px {
    padding-left: 20px;
    padding-right: 20px;
  }

  .xs\:px-15px {
    padding-left: 15px;
    padding-right: 15px;
  }

  .xs\:px-13px {
    padding-left: 13px;
    padding-right: 13px;
  }

  .xs\:px-12px {
    padding-left: 12px;
    padding-right: 12px;
  }

  .xs\:px-10px {
    padding-left: 10px;
    padding-right: 10px;
  }

  .xs\:px-8px {
    padding-left: 8px;
    padding-right: 8px;
  }

  .xs\:px-6px {
    padding-left: 6px;
    padding-right: 6px;
  }

  .xs\:px-5px {
    padding-left: 5px;
    padding-right: 5px;
  }

  .xs\:px-3px {
    padding-left: 3px;
    padding-right: 3px;
  }

  .xs\:px-menu {
    padding-left: 275px;
    padding-right: 275px;
  }

  .xs\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .xs\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xs\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .xs\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .xs\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .xs\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xs\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xs\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xs\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xs\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xs\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xs\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .xs\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .xs\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xs\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .xs\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .xs\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .xs\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .xs\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .xs\:py-300px {
    padding-top: 300px;
    padding-bottom: 300px;
  }

  .xs\:py-230px {
    padding-top: 230px;
    padding-bottom: 230px;
  }

  .xs\:py-180px {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .xs\:py-147px {
    padding-top: 147px;
    padding-bottom: 147px;
  }

  .xs\:py-123px {
    padding-top: 123px;
    padding-bottom: 123px;
  }

  .xs\:py-100px {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .xs\:py-80px {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .xs\:py-60px {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .xs\:py-50px {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .xs\:py-40px {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .xs\:py-35px {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .xs\:py-34px-i {
    padding-top: 34px !important;
    padding-bottom: 34px !important;
  }

  .xs\:py-30px-i {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .xs\:py-24px-i {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .xs\:py-20px-i {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .xs\:py-15px-i {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .xs\:py-13px-i {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  .xs\:py-12px-i {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .xs\:py-10px-i {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .xs\:py-8px-i {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .xs\:py-6px-i {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .xs\:py-5px-i {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .xs\:py-3px-i {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .xs\:py-0px-i {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .xs\:py-34px {
    padding-top: 34px;
    padding-bottom: 34px;
  }

  .xs\:py-30px {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .xs\:py-24px {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .xs\:py-20px {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .xs\:py-15px {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .xs\:py-13px {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .xs\:py-12px {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .xs\:py-10px {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .xs\:py-8px {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .xs\:py-6px {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .xs\:py-5px {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .xs\:py-3px {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .xs\:py-menu {
    padding-top: 275px;
    padding-bottom: 275px;
  }

  .xs\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .xs\:pt-0 {
    padding-top: 0;
  }

  .xs\:pt-1 {
    padding-top: 0.25rem;
  }

  .xs\:pt-2 {
    padding-top: 0.5rem;
  }

  .xs\:pt-3 {
    padding-top: 0.75rem;
  }

  .xs\:pt-4 {
    padding-top: 1rem;
  }

  .xs\:pt-5 {
    padding-top: 1.25rem;
  }

  .xs\:pt-6 {
    padding-top: 1.5rem;
  }

  .xs\:pt-8 {
    padding-top: 2rem;
  }

  .xs\:pt-10 {
    padding-top: 2.5rem;
  }

  .xs\:pt-12 {
    padding-top: 3rem;
  }

  .xs\:pt-16 {
    padding-top: 4rem;
  }

  .xs\:pt-20 {
    padding-top: 5rem;
  }

  .xs\:pt-24 {
    padding-top: 6rem;
  }

  .xs\:pt-32 {
    padding-top: 8rem;
  }

  .xs\:pt-40 {
    padding-top: 10rem;
  }

  .xs\:pt-48 {
    padding-top: 12rem;
  }

  .xs\:pt-56 {
    padding-top: 14rem;
  }

  .xs\:pt-64 {
    padding-top: 16rem;
  }

  .xs\:pt-300px {
    padding-top: 300px;
  }

  .xs\:pt-230px {
    padding-top: 230px;
  }

  .xs\:pt-180px {
    padding-top: 180px;
  }

  .xs\:pt-147px {
    padding-top: 147px;
  }

  .xs\:pt-123px {
    padding-top: 123px;
  }

  .xs\:pt-100px {
    padding-top: 100px;
  }

  .xs\:pt-80px {
    padding-top: 80px;
  }

  .xs\:pt-60px {
    padding-top: 60px;
  }

  .xs\:pt-50px {
    padding-top: 50px;
  }

  .xs\:pt-40px {
    padding-top: 40px;
  }

  .xs\:pt-35px {
    padding-top: 35px;
  }

  .xs\:pt-34px-i {
    padding-top: 34px !important;
  }

  .xs\:pt-30px-i {
    padding-top: 30px !important;
  }

  .xs\:pt-24px-i {
    padding-top: 24px !important;
  }

  .xs\:pt-20px-i {
    padding-top: 20px !important;
  }

  .xs\:pt-15px-i {
    padding-top: 15px !important;
  }

  .xs\:pt-13px-i {
    padding-top: 13px !important;
  }

  .xs\:pt-12px-i {
    padding-top: 12px !important;
  }

  .xs\:pt-10px-i {
    padding-top: 10px !important;
  }

  .xs\:pt-8px-i {
    padding-top: 8px !important;
  }

  .xs\:pt-6px-i {
    padding-top: 6px !important;
  }

  .xs\:pt-5px-i {
    padding-top: 5px !important;
  }

  .xs\:pt-3px-i {
    padding-top: 3px !important;
  }

  .xs\:pt-0px-i {
    padding-top: 0px !important;
  }

  .xs\:pt-34px {
    padding-top: 34px;
  }

  .xs\:pt-30px {
    padding-top: 30px;
  }

  .xs\:pt-24px {
    padding-top: 24px;
  }

  .xs\:pt-20px {
    padding-top: 20px;
  }

  .xs\:pt-15px {
    padding-top: 15px;
  }

  .xs\:pt-13px {
    padding-top: 13px;
  }

  .xs\:pt-12px {
    padding-top: 12px;
  }

  .xs\:pt-10px {
    padding-top: 10px;
  }

  .xs\:pt-8px {
    padding-top: 8px;
  }

  .xs\:pt-6px {
    padding-top: 6px;
  }

  .xs\:pt-5px {
    padding-top: 5px;
  }

  .xs\:pt-3px {
    padding-top: 3px;
  }

  .xs\:pt-menu {
    padding-top: 275px;
  }

  .xs\:pt-px {
    padding-top: 1px !important;
  }

  .xs\:pr-0 {
    padding-right: 0;
  }

  .xs\:pr-1 {
    padding-right: 0.25rem;
  }

  .xs\:pr-2 {
    padding-right: 0.5rem;
  }

  .xs\:pr-3 {
    padding-right: 0.75rem;
  }

  .xs\:pr-4 {
    padding-right: 1rem;
  }

  .xs\:pr-5 {
    padding-right: 1.25rem;
  }

  .xs\:pr-6 {
    padding-right: 1.5rem;
  }

  .xs\:pr-8 {
    padding-right: 2rem;
  }

  .xs\:pr-10 {
    padding-right: 2.5rem;
  }

  .xs\:pr-12 {
    padding-right: 3rem;
  }

  .xs\:pr-16 {
    padding-right: 4rem;
  }

  .xs\:pr-20 {
    padding-right: 5rem;
  }

  .xs\:pr-24 {
    padding-right: 6rem;
  }

  .xs\:pr-32 {
    padding-right: 8rem;
  }

  .xs\:pr-40 {
    padding-right: 10rem;
  }

  .xs\:pr-48 {
    padding-right: 12rem;
  }

  .xs\:pr-56 {
    padding-right: 14rem;
  }

  .xs\:pr-64 {
    padding-right: 16rem;
  }

  .xs\:pr-300px {
    padding-right: 300px;
  }

  .xs\:pr-230px {
    padding-right: 230px;
  }

  .xs\:pr-180px {
    padding-right: 180px;
  }

  .xs\:pr-147px {
    padding-right: 147px;
  }

  .xs\:pr-123px {
    padding-right: 123px;
  }

  .xs\:pr-100px {
    padding-right: 100px;
  }

  .xs\:pr-80px {
    padding-right: 80px;
  }

  .xs\:pr-60px {
    padding-right: 60px;
  }

  .xs\:pr-50px {
    padding-right: 50px;
  }

  .xs\:pr-40px {
    padding-right: 40px;
  }

  .xs\:pr-35px {
    padding-right: 35px;
  }

  .xs\:pr-34px-i {
    padding-right: 34px !important;
  }

  .xs\:pr-30px-i {
    padding-right: 30px !important;
  }

  .xs\:pr-24px-i {
    padding-right: 24px !important;
  }

  .xs\:pr-20px-i {
    padding-right: 20px !important;
  }

  .xs\:pr-15px-i {
    padding-right: 15px !important;
  }

  .xs\:pr-13px-i {
    padding-right: 13px !important;
  }

  .xs\:pr-12px-i {
    padding-right: 12px !important;
  }

  .xs\:pr-10px-i {
    padding-right: 10px !important;
  }

  .xs\:pr-8px-i {
    padding-right: 8px !important;
  }

  .xs\:pr-6px-i {
    padding-right: 6px !important;
  }

  .xs\:pr-5px-i {
    padding-right: 5px !important;
  }

  .xs\:pr-3px-i {
    padding-right: 3px !important;
  }

  .xs\:pr-0px-i {
    padding-right: 0px !important;
  }

  .xs\:pr-34px {
    padding-right: 34px;
  }

  .xs\:pr-30px {
    padding-right: 30px;
  }

  .xs\:pr-24px {
    padding-right: 24px;
  }

  .xs\:pr-20px {
    padding-right: 20px;
  }

  .xs\:pr-15px {
    padding-right: 15px;
  }

  .xs\:pr-13px {
    padding-right: 13px;
  }

  .xs\:pr-12px {
    padding-right: 12px;
  }

  .xs\:pr-10px {
    padding-right: 10px;
  }

  .xs\:pr-8px {
    padding-right: 8px;
  }

  .xs\:pr-6px {
    padding-right: 6px;
  }

  .xs\:pr-5px {
    padding-right: 5px;
  }

  .xs\:pr-3px {
    padding-right: 3px;
  }

  .xs\:pr-menu {
    padding-right: 275px;
  }

  .xs\:pr-px {
    padding-right: 1px !important;
  }

  .xs\:pb-0 {
    padding-bottom: 0;
  }

  .xs\:pb-1 {
    padding-bottom: 0.25rem;
  }

  .xs\:pb-2 {
    padding-bottom: 0.5rem;
  }

  .xs\:pb-3 {
    padding-bottom: 0.75rem;
  }

  .xs\:pb-4 {
    padding-bottom: 1rem;
  }

  .xs\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .xs\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .xs\:pb-8 {
    padding-bottom: 2rem;
  }

  .xs\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .xs\:pb-12 {
    padding-bottom: 3rem;
  }

  .xs\:pb-16 {
    padding-bottom: 4rem;
  }

  .xs\:pb-20 {
    padding-bottom: 5rem;
  }

  .xs\:pb-24 {
    padding-bottom: 6rem;
  }

  .xs\:pb-32 {
    padding-bottom: 8rem;
  }

  .xs\:pb-40 {
    padding-bottom: 10rem;
  }

  .xs\:pb-48 {
    padding-bottom: 12rem;
  }

  .xs\:pb-56 {
    padding-bottom: 14rem;
  }

  .xs\:pb-64 {
    padding-bottom: 16rem;
  }

  .xs\:pb-300px {
    padding-bottom: 300px;
  }

  .xs\:pb-230px {
    padding-bottom: 230px;
  }

  .xs\:pb-180px {
    padding-bottom: 180px;
  }

  .xs\:pb-147px {
    padding-bottom: 147px;
  }

  .xs\:pb-123px {
    padding-bottom: 123px;
  }

  .xs\:pb-100px {
    padding-bottom: 100px;
  }

  .xs\:pb-80px {
    padding-bottom: 80px;
  }

  .xs\:pb-60px {
    padding-bottom: 60px;
  }

  .xs\:pb-50px {
    padding-bottom: 50px;
  }

  .xs\:pb-40px {
    padding-bottom: 40px;
  }

  .xs\:pb-35px {
    padding-bottom: 35px;
  }

  .xs\:pb-34px-i {
    padding-bottom: 34px !important;
  }

  .xs\:pb-30px-i {
    padding-bottom: 30px !important;
  }

  .xs\:pb-24px-i {
    padding-bottom: 24px !important;
  }

  .xs\:pb-20px-i {
    padding-bottom: 20px !important;
  }

  .xs\:pb-15px-i {
    padding-bottom: 15px !important;
  }

  .xs\:pb-13px-i {
    padding-bottom: 13px !important;
  }

  .xs\:pb-12px-i {
    padding-bottom: 12px !important;
  }

  .xs\:pb-10px-i {
    padding-bottom: 10px !important;
  }

  .xs\:pb-8px-i {
    padding-bottom: 8px !important;
  }

  .xs\:pb-6px-i {
    padding-bottom: 6px !important;
  }

  .xs\:pb-5px-i {
    padding-bottom: 5px !important;
  }

  .xs\:pb-3px-i {
    padding-bottom: 3px !important;
  }

  .xs\:pb-0px-i {
    padding-bottom: 0px !important;
  }

  .xs\:pb-34px {
    padding-bottom: 34px;
  }

  .xs\:pb-30px {
    padding-bottom: 30px;
  }

  .xs\:pb-24px {
    padding-bottom: 24px;
  }

  .xs\:pb-20px {
    padding-bottom: 20px;
  }

  .xs\:pb-15px {
    padding-bottom: 15px;
  }

  .xs\:pb-13px {
    padding-bottom: 13px;
  }

  .xs\:pb-12px {
    padding-bottom: 12px;
  }

  .xs\:pb-10px {
    padding-bottom: 10px;
  }

  .xs\:pb-8px {
    padding-bottom: 8px;
  }

  .xs\:pb-6px {
    padding-bottom: 6px;
  }

  .xs\:pb-5px {
    padding-bottom: 5px;
  }

  .xs\:pb-3px {
    padding-bottom: 3px;
  }

  .xs\:pb-menu {
    padding-bottom: 275px;
  }

  .xs\:pb-px {
    padding-bottom: 1px !important;
  }

  .xs\:pl-0 {
    padding-left: 0;
  }

  .xs\:pl-1 {
    padding-left: 0.25rem;
  }

  .xs\:pl-2 {
    padding-left: 0.5rem;
  }

  .xs\:pl-3 {
    padding-left: 0.75rem;
  }

  .xs\:pl-4 {
    padding-left: 1rem;
  }

  .xs\:pl-5 {
    padding-left: 1.25rem;
  }

  .xs\:pl-6 {
    padding-left: 1.5rem;
  }

  .xs\:pl-8 {
    padding-left: 2rem;
  }

  .xs\:pl-10 {
    padding-left: 2.5rem;
  }

  .xs\:pl-12 {
    padding-left: 3rem;
  }

  .xs\:pl-16 {
    padding-left: 4rem;
  }

  .xs\:pl-20 {
    padding-left: 5rem;
  }

  .xs\:pl-24 {
    padding-left: 6rem;
  }

  .xs\:pl-32 {
    padding-left: 8rem;
  }

  .xs\:pl-40 {
    padding-left: 10rem;
  }

  .xs\:pl-48 {
    padding-left: 12rem;
  }

  .xs\:pl-56 {
    padding-left: 14rem;
  }

  .xs\:pl-64 {
    padding-left: 16rem;
  }

  .xs\:pl-300px {
    padding-left: 300px;
  }

  .xs\:pl-230px {
    padding-left: 230px;
  }

  .xs\:pl-180px {
    padding-left: 180px;
  }

  .xs\:pl-147px {
    padding-left: 147px;
  }

  .xs\:pl-123px {
    padding-left: 123px;
  }

  .xs\:pl-100px {
    padding-left: 100px;
  }

  .xs\:pl-80px {
    padding-left: 80px;
  }

  .xs\:pl-60px {
    padding-left: 60px;
  }

  .xs\:pl-50px {
    padding-left: 50px;
  }

  .xs\:pl-40px {
    padding-left: 40px;
  }

  .xs\:pl-35px {
    padding-left: 35px;
  }

  .xs\:pl-34px-i {
    padding-left: 34px !important;
  }

  .xs\:pl-30px-i {
    padding-left: 30px !important;
  }

  .xs\:pl-24px-i {
    padding-left: 24px !important;
  }

  .xs\:pl-20px-i {
    padding-left: 20px !important;
  }

  .xs\:pl-15px-i {
    padding-left: 15px !important;
  }

  .xs\:pl-13px-i {
    padding-left: 13px !important;
  }

  .xs\:pl-12px-i {
    padding-left: 12px !important;
  }

  .xs\:pl-10px-i {
    padding-left: 10px !important;
  }

  .xs\:pl-8px-i {
    padding-left: 8px !important;
  }

  .xs\:pl-6px-i {
    padding-left: 6px !important;
  }

  .xs\:pl-5px-i {
    padding-left: 5px !important;
  }

  .xs\:pl-3px-i {
    padding-left: 3px !important;
  }

  .xs\:pl-0px-i {
    padding-left: 0px !important;
  }

  .xs\:pl-34px {
    padding-left: 34px;
  }

  .xs\:pl-30px {
    padding-left: 30px;
  }

  .xs\:pl-24px {
    padding-left: 24px;
  }

  .xs\:pl-20px {
    padding-left: 20px;
  }

  .xs\:pl-15px {
    padding-left: 15px;
  }

  .xs\:pl-13px {
    padding-left: 13px;
  }

  .xs\:pl-12px {
    padding-left: 12px;
  }

  .xs\:pl-10px {
    padding-left: 10px;
  }

  .xs\:pl-8px {
    padding-left: 8px;
  }

  .xs\:pl-6px {
    padding-left: 6px;
  }

  .xs\:pl-5px {
    padding-left: 5px;
  }

  .xs\:pl-3px {
    padding-left: 3px;
  }

  .xs\:pl-menu {
    padding-left: 275px;
  }

  .xs\:pl-px {
    padding-left: 1px !important;
  }

  .xs\:text-left {
    text-align: left;
  }

  .xs\:text-center {
    text-align: center;
  }

  .xs\:text-right {
    text-align: right;
  }

  .xs\:text-justify {
    text-align: justify;
  }

  .xs\:align-baseline {
    vertical-align: baseline;
  }

  .xs\:align-top {
    vertical-align: top;
  }

  .xs\:align-middle {
    vertical-align: middle;
  }

  .xs\:align-bottom {
    vertical-align: bottom;
  }

  .xs\:align-text-top {
    vertical-align: text-top;
  }

  .xs\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .xs\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .xs\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .xs\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .xs\:text-size-42 {
    font-size: 42px !important;
  }

  .xs\:text-size-32 {
    font-size: 32px !important;
  }

  .xs\:text-size-28 {
    font-size: 24px !important;
  }

  .xs\:text-size-24 {
    font-size: 24px !important;
  }

  .xs\:text-size-20 {
    font-size: 20px !important;
  }

  .xs\:text-size-18 {
    font-size: 18px !important;
  }

  .xs\:font-hairline {
    font-weight: 100;
  }

  .xs\:font-thin {
    font-weight: 200;
  }

  .xs\:font-light {
    font-weight: 300 !important;
  }

  .xs\:font-normal {
    font-weight: 400 !important;
  }

  .xs\:font-medium {
    font-weight: 500;
  }

  .xs\:font-semibold {
    font-weight: 600;
  }

  .xs\:font-bold {
    font-weight: 700 !important;
  }

  .xs\:font-extrabold {
    font-weight: 800;
  }

  .xs\:font-black {
    font-weight: 900;
  }

  .xs\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .xs\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .xs\:hover\:font-light:hover {
    font-weight: 300 !important;
  }

  .xs\:hover\:font-normal:hover {
    font-weight: 400 !important;
  }

  .xs\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .xs\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .xs\:hover\:font-bold:hover {
    font-weight: 700 !important;
  }

  .xs\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .xs\:hover\:font-black:hover {
    font-weight: 900;
  }

  .xs\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .xs\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .xs\:focus\:font-light:focus {
    font-weight: 300 !important;
  }

  .xs\:focus\:font-normal:focus {
    font-weight: 400 !important;
  }

  .xs\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .xs\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .xs\:focus\:font-bold:focus {
    font-weight: 700 !important;
  }

  .xs\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .xs\:focus\:font-black:focus {
    font-weight: 900;
  }

  .xs\:uppercase {
    text-transform: uppercase;
  }

  .xs\:lowercase {
    text-transform: lowercase;
  }

  .xs\:capitalize {
    text-transform: capitalize;
  }

  .xs\:normal-case {
    text-transform: none;
  }

  .xs\:italic {
    font-style: italic;
  }

  .xs\:not-italic {
    font-style: normal;
  }

  .xs\:ordinal, .xs\:slashed-zero, .xs\:lining-nums, .xs\:oldstyle-nums, .xs\:proportional-nums, .xs\:tabular-nums, .xs\:diagonal-fractions, .xs\:stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xs\:normal-nums {
    font-variant-numeric: normal;
  }

  .xs\:ordinal {
    --tw-ordinal: ordinal;
  }

  .xs\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .xs\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .xs\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .xs\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .xs\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .xs\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .xs\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .xs\:leading-size-54 {
    line-height: 54px !important;
  }

  .xs\:leading-size-40 {
    line-height: 40px !important;
  }

  .xs\:leading-size-32 {
    line-height: 32px !important;
  }

  .xs\:leading-size-28 {
    line-height: 28px !important;
  }

  .xs\:leading-size-24 {
    line-height: 24px !important;
  }

  .xs\:tracking-tightest {
    letter-spacing: -0.1em;
  }

  .xs\:tracking-tighter {
    letter-spacing: -0.05em;
  }

  .xs\:tracking-tight {
    letter-spacing: -0.025em;
  }

  .xs\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .xs\:tracking-wide {
    letter-spacing: 0.025em;
  }

  .xs\:tracking-wider {
    letter-spacing: 0.05em;
  }

  .xs\:tracking-widest {
    letter-spacing: 0.1em;
  }

  .xs\:text-transparent {
    color: transparent;
  }

  .xs\:text-current {
    color: currentColor;
  }

  .xs\:text-black {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .xs\:text-black40pct {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .xs\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xs\:text-petroleum {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .xs\:text-petroleum70pct {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .xs\:text-petroleum30pct {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .xs\:text-disabled {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .xs\:text-petroleum10pct {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .xs\:text-petroleum5pct {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .xs\:text-darkGreen {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .xs\:text-ok {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .xs\:text-green {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .xs\:text-green10pct {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .xs\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .xs\:text-darkOrange {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .xs\:text-lightOrange {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .xs\:text-error {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .xs\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .xs\:text-darkYellow {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .xs\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .xs\:text-lightGray {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .xs\:text-borderGray {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .xs\:hover\:text-transparent:hover {
    color: transparent;
  }

  .xs\:hover\:text-current:hover {
    color: currentColor;
  }

  .xs\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .xs\:hover\:text-black40pct:hover {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .xs\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xs\:hover\:text-petroleum:hover {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .xs\:hover\:text-petroleum70pct:hover {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .xs\:hover\:text-petroleum30pct:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .xs\:hover\:text-disabled:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .xs\:hover\:text-petroleum10pct:hover {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .xs\:hover\:text-petroleum5pct:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .xs\:hover\:text-darkGreen:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .xs\:hover\:text-ok:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .xs\:hover\:text-green:hover {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .xs\:hover\:text-green10pct:hover {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .xs\:hover\:text-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .xs\:hover\:text-darkOrange:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .xs\:hover\:text-lightOrange:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .xs\:hover\:text-error:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .xs\:hover\:text-yellow:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .xs\:hover\:text-darkYellow:hover {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .xs\:hover\:text-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .xs\:hover\:text-lightGray:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .xs\:hover\:text-borderGray:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .xs\:focus\:text-transparent:focus {
    color: transparent;
  }

  .xs\:focus\:text-current:focus {
    color: currentColor;
  }

  .xs\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .xs\:focus\:text-black40pct:focus {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .xs\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xs\:focus\:text-petroleum:focus {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .xs\:focus\:text-petroleum70pct:focus {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .xs\:focus\:text-petroleum30pct:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .xs\:focus\:text-disabled:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .xs\:focus\:text-petroleum10pct:focus {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .xs\:focus\:text-petroleum5pct:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .xs\:focus\:text-darkGreen:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .xs\:focus\:text-ok:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .xs\:focus\:text-green:focus {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .xs\:focus\:text-green10pct:focus {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .xs\:focus\:text-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .xs\:focus\:text-darkOrange:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .xs\:focus\:text-lightOrange:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .xs\:focus\:text-error:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .xs\:focus\:text-yellow:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .xs\:focus\:text-darkYellow:focus {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .xs\:focus\:text-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .xs\:focus\:text-lightGray:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .xs\:focus\:text-borderGray:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .xs\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .xs\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .xs\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .xs\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .xs\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .xs\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .xs\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }

  .xs\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }

  .xs\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }

  .xs\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .xs\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .xs\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }

  .xs\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }

  .xs\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }

  .xs\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .xs\:underline {
    text-decoration: underline;
  }

  .xs\:line-through {
    text-decoration: line-through;
  }

  .xs\:no-underline {
    text-decoration: none;
  }

  .xs\:hover\:underline:hover {
    text-decoration: underline;
  }

  .xs\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .xs\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .xs\:focus\:underline:focus {
    text-decoration: underline;
  }

  .xs\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .xs\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .xs\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xs\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .xs\:placeholder-transparent::placeholder {
    color: transparent;
  }

  .xs\:placeholder-current::placeholder {
    color: currentColor;
  }

  .xs\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-black40pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-petroleum::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-petroleum70pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-petroleum30pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-disabled::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-petroleum10pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-petroleum5pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-darkGreen::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-ok::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-green10pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-darkOrange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-lightOrange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-error::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-yellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-darkYellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-lightGray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-borderGray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .xs\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .xs\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-black40pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-petroleum:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-petroleum70pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-petroleum30pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-disabled:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-petroleum10pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-petroleum5pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-darkGreen:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-ok:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-green10pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-darkOrange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-lightOrange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-error:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-yellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-darkYellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-lightGray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-placeholder-opacity));
  }

  .xs\:focus\:placeholder-borderGray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-placeholder-opacity));
  }

  .xs\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xs\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .xs\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .xs\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .xs\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xs\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xs\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .xs\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .xs\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .xs\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xs\:opacity-0 {
    opacity: 0;
  }

  .xs\:opacity-25 {
    opacity: 0.25;
  }

  .xs\:opacity-50 {
    opacity: 0.5;
  }

  .xs\:opacity-75 {
    opacity: 0.75;
  }

  .xs\:opacity-100 {
    opacity: 1;
  }

  .xs\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .xs\:hover\:opacity-25:hover {
    opacity: 0.25;
  }

  .xs\:hover\:opacity-50:hover {
    opacity: 0.5;
  }

  .xs\:hover\:opacity-75:hover {
    opacity: 0.75;
  }

  .xs\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .xs\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .xs\:focus\:opacity-25:focus {
    opacity: 0.25;
  }

  .xs\:focus\:opacity-50:focus {
    opacity: 0.5;
  }

  .xs\:focus\:opacity-75:focus {
    opacity: 0.75;
  }

  .xs\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .xs\:shadow-xs {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:shadow-default {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:shadow-outline {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:hover\:shadow-xs:hover {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:hover\:shadow-default:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:hover\:shadow-outline:hover {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:focus\:shadow-xs:focus {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:focus\:shadow-default:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:focus\:shadow-outline:focus {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xs\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .xs\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .xs\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .xs\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .xs\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .xs\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .xs\:transition-none {
    transition-property: none;
  }

  .xs\:transition-all {
    transition-property: all;
  }

  .xs\:transition-default {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .xs\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .xs\:transition-opacity {
    transition-property: opacity;
  }

  .xs\:transition-shadow {
    transition-property: box-shadow;
  }

  .xs\:transition-transform {
    transition-property: transform;
  }

  .xs\:delay-75 {
    transition-delay: 75ms;
  }

  .xs\:delay-100 {
    transition-delay: 100ms;
  }

  .xs\:delay-150 {
    transition-delay: 150ms;
  }

  .xs\:delay-200 {
    transition-delay: 200ms;
  }

  .xs\:delay-300 {
    transition-delay: 300ms;
  }

  .xs\:delay-500 {
    transition-delay: 500ms;
  }

  .xs\:delay-700 {
    transition-delay: 700ms;
  }

  .xs\:delay-1000 {
    transition-delay: 1000ms;
  }

  .xs\:duration-75 {
    transition-duration: 75ms;
  }

  .xs\:duration-100 {
    transition-duration: 100ms;
  }

  .xs\:duration-150 {
    transition-duration: 150ms;
  }

  .xs\:duration-200 {
    transition-duration: 200ms;
  }

  .xs\:duration-300 {
    transition-duration: 300ms;
  }

  .xs\:duration-500 {
    transition-duration: 500ms;
  }

  .xs\:duration-700 {
    transition-duration: 700ms;
  }

  .xs\:duration-1000 {
    transition-duration: 1000ms;
  }

  .xs\:ease-linear {
    transition-timing-function: linear;
  }

  .xs\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .xs\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .xs\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (min-width: 768px) {
  .sm\:container {
    width: 100%;
  }

  @media (min-width: 1px) {
    .sm\:container {
      max-width: 1px;
    }
  }

  @media (min-width: 768px) {
    .sm\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .sm\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1440px) {
    .sm\:container {
      max-width: 1440px;
    }
  }

  @media (min-width: 1920px) {
    .sm\:container {
      max-width: 1920px;
    }
  }

  .sm\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .sm\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .sm\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .sm\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .sm\:pointer-events-none {
    pointer-events: none;
  }

  .sm\:pointer-events-auto {
    pointer-events: auto;
  }

  .sm\:visible {
    visibility: visible;
  }

  .sm\:invisible {
    visibility: hidden;
  }

  .sm\:static {
    position: static;
  }

  .sm\:fixed {
    position: fixed;
  }

  .sm\:absolute {
    position: absolute;
  }

  .sm\:relative {
    position: relative;
  }

  .sm\:sticky {
    position: sticky;
  }

  .sm\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .sm\:inset-60px {
    top: 60px;
    right: 60px;
    bottom: 60px;
    left: 60px;
  }

  .sm\:inset-80px {
    top: 80px;
    right: 80px;
    bottom: 80px;
    left: 80px;
  }

  .sm\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .sm\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .sm\:inset-x-60px {
    left: 60px;
    right: 60px;
  }

  .sm\:inset-x-80px {
    left: 80px;
    right: 80px;
  }

  .sm\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .sm\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .sm\:inset-y-60px {
    top: 60px;
    bottom: 60px;
  }

  .sm\:inset-y-80px {
    top: 80px;
    bottom: 80px;
  }

  .sm\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .sm\:top-0 {
    top: 0;
  }

  .sm\:top-60px {
    top: 60px;
  }

  .sm\:top-80px {
    top: 80px;
  }

  .sm\:top-auto {
    top: auto;
  }

  .sm\:right-0 {
    right: 0;
  }

  .sm\:right-60px {
    right: 60px;
  }

  .sm\:right-80px {
    right: 80px;
  }

  .sm\:right-auto {
    right: auto;
  }

  .sm\:bottom-0 {
    bottom: 0;
  }

  .sm\:bottom-60px {
    bottom: 60px;
  }

  .sm\:bottom-80px {
    bottom: 80px;
  }

  .sm\:bottom-auto {
    bottom: auto;
  }

  .sm\:left-0 {
    left: 0;
  }

  .sm\:left-60px {
    left: 60px;
  }

  .sm\:left-80px {
    left: 80px;
  }

  .sm\:left-auto {
    left: auto;
  }

  .sm\:z-0 {
    z-index: 0 !important;
  }

  .sm\:z-10 {
    z-index: 10 !important;
  }

  .sm\:z-20 {
    z-index: 20;
  }

  .sm\:z-30 {
    z-index: 30;
  }

  .sm\:z-40 {
    z-index: 40;
  }

  .sm\:z-50 {
    z-index: 50;
  }

  .sm\:z-auto {
    z-index: auto !important;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:order-2 {
    order: 2;
  }

  .sm\:order-3 {
    order: 3;
  }

  .sm\:order-4 {
    order: 4;
  }

  .sm\:order-5 {
    order: 5;
  }

  .sm\:order-6 {
    order: 6;
  }

  .sm\:order-7 {
    order: 7;
  }

  .sm\:order-8 {
    order: 8;
  }

  .sm\:order-9 {
    order: 9;
  }

  .sm\:order-10 {
    order: 10;
  }

  .sm\:order-11 {
    order: 11;
  }

  .sm\:order-12 {
    order: 12;
  }

  .sm\:order-first {
    order: -9999;
  }

  .sm\:order-last {
    order: 9999;
  }

  .sm\:order-none {
    order: 0;
  }

  .sm\:col-auto {
    grid-column: auto;
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm\:col-span-full {
    grid-column: 1 / -1;
  }

  .sm\:col-start-1 {
    grid-column-start: 1;
  }

  .sm\:col-start-2 {
    grid-column-start: 2;
  }

  .sm\:col-start-3 {
    grid-column-start: 3;
  }

  .sm\:col-start-4 {
    grid-column-start: 4;
  }

  .sm\:col-start-5 {
    grid-column-start: 5;
  }

  .sm\:col-start-6 {
    grid-column-start: 6;
  }

  .sm\:col-start-7 {
    grid-column-start: 7;
  }

  .sm\:col-start-8 {
    grid-column-start: 8;
  }

  .sm\:col-start-9 {
    grid-column-start: 9;
  }

  .sm\:col-start-10 {
    grid-column-start: 10;
  }

  .sm\:col-start-11 {
    grid-column-start: 11;
  }

  .sm\:col-start-12 {
    grid-column-start: 12;
  }

  .sm\:col-start-13 {
    grid-column-start: 13;
  }

  .sm\:col-start-auto {
    grid-column-start: auto;
  }

  .sm\:col-end-1 {
    grid-column-end: 1;
  }

  .sm\:col-end-2 {
    grid-column-end: 2;
  }

  .sm\:col-end-3 {
    grid-column-end: 3;
  }

  .sm\:col-end-4 {
    grid-column-end: 4;
  }

  .sm\:col-end-5 {
    grid-column-end: 5;
  }

  .sm\:col-end-6 {
    grid-column-end: 6;
  }

  .sm\:col-end-7 {
    grid-column-end: 7;
  }

  .sm\:col-end-8 {
    grid-column-end: 8;
  }

  .sm\:col-end-9 {
    grid-column-end: 9;
  }

  .sm\:col-end-10 {
    grid-column-end: 10;
  }

  .sm\:col-end-11 {
    grid-column-end: 11;
  }

  .sm\:col-end-12 {
    grid-column-end: 12;
  }

  .sm\:col-end-13 {
    grid-column-end: 13;
  }

  .sm\:col-end-auto {
    grid-column-end: auto;
  }

  .sm\:row-auto {
    grid-row: auto;
  }

  .sm\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .sm\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .sm\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .sm\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .sm\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .sm\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .sm\:row-span-full {
    grid-row: 1 / -1;
  }

  .sm\:row-start-1 {
    grid-row-start: 1;
  }

  .sm\:row-start-2 {
    grid-row-start: 2;
  }

  .sm\:row-start-3 {
    grid-row-start: 3;
  }

  .sm\:row-start-4 {
    grid-row-start: 4;
  }

  .sm\:row-start-5 {
    grid-row-start: 5;
  }

  .sm\:row-start-6 {
    grid-row-start: 6;
  }

  .sm\:row-start-7 {
    grid-row-start: 7;
  }

  .sm\:row-start-auto {
    grid-row-start: auto;
  }

  .sm\:row-end-1 {
    grid-row-end: 1;
  }

  .sm\:row-end-2 {
    grid-row-end: 2;
  }

  .sm\:row-end-3 {
    grid-row-end: 3;
  }

  .sm\:row-end-4 {
    grid-row-end: 4;
  }

  .sm\:row-end-5 {
    grid-row-end: 5;
  }

  .sm\:row-end-6 {
    grid-row-end: 6;
  }

  .sm\:row-end-7 {
    grid-row-end: 7;
  }

  .sm\:row-end-auto {
    grid-row-end: auto;
  }

  .sm\:float-right {
    float: right;
  }

  .sm\:float-left {
    float: left;
  }

  .sm\:float-none {
    float: none;
  }

  .sm\:clear-left {
    clear: left;
  }

  .sm\:clear-right {
    clear: right;
  }

  .sm\:clear-both {
    clear: both;
  }

  .sm\:clear-none {
    clear: none;
  }

  .sm\:m-0 {
    margin: 0;
  }

  .sm\:m-1 {
    margin: 0.25rem;
  }

  .sm\:m-2 {
    margin: 0.5rem;
  }

  .sm\:m-3 {
    margin: 0.75rem;
  }

  .sm\:m-4 {
    margin: 1rem;
  }

  .sm\:m-5 {
    margin: 1.25rem;
  }

  .sm\:m-6 {
    margin: 1.5rem;
  }

  .sm\:m-8 {
    margin: 2rem;
  }

  .sm\:m-10 {
    margin: 2.5rem;
  }

  .sm\:m-12 {
    margin: 3rem;
  }

  .sm\:m-16 {
    margin: 4rem;
  }

  .sm\:m-20 {
    margin: 5rem;
  }

  .sm\:m-24 {
    margin: 6rem;
  }

  .sm\:m-32 {
    margin: 8rem;
  }

  .sm\:m-40 {
    margin: 10rem;
  }

  .sm\:m-48 {
    margin: 12rem;
  }

  .sm\:m-56 {
    margin: 14rem;
  }

  .sm\:m-64 {
    margin: 16rem;
  }

  .sm\:m-auto {
    margin: auto;
  }

  .sm\:m-300px {
    margin: 300px;
  }

  .sm\:m-230px {
    margin: 230px;
  }

  .sm\:m-180px {
    margin: 180px;
  }

  .sm\:m-147px {
    margin: 147px;
  }

  .sm\:m-123px {
    margin: 123px;
  }

  .sm\:m-100px {
    margin: 100px;
  }

  .sm\:m-80px {
    margin: 80px;
  }

  .sm\:m-60px {
    margin: 60px;
  }

  .sm\:m-50px {
    margin: 50px;
  }

  .sm\:m-40px {
    margin: 40px;
  }

  .sm\:m-35px {
    margin: 35px;
  }

  .sm\:m-34px-i {
    margin: 34px !important;
  }

  .sm\:m-30px-i {
    margin: 30px !important;
  }

  .sm\:m-24px-i {
    margin: 24px !important;
  }

  .sm\:m-20px-i {
    margin: 20px !important;
  }

  .sm\:m-15px-i {
    margin: 15px !important;
  }

  .sm\:m-13px-i {
    margin: 13px !important;
  }

  .sm\:m-12px-i {
    margin: 12px !important;
  }

  .sm\:m-10px-i {
    margin: 10px !important;
  }

  .sm\:m-8px-i {
    margin: 8px !important;
  }

  .sm\:m-6px-i {
    margin: 6px !important;
  }

  .sm\:m-5px-i {
    margin: 5px !important;
  }

  .sm\:m-3px-i {
    margin: 3px !important;
  }

  .sm\:m-0px-i {
    margin: 0px !important;
  }

  .sm\:m-34px {
    margin: 34px;
  }

  .sm\:m-30px {
    margin: 30px;
  }

  .sm\:m-24px {
    margin: 24px;
  }

  .sm\:m-20px {
    margin: 20px;
  }

  .sm\:m-15px {
    margin: 15px;
  }

  .sm\:m-13px {
    margin: 13px;
  }

  .sm\:m-12px {
    margin: 12px;
  }

  .sm\:m-10px {
    margin: 10px;
  }

  .sm\:m-8px {
    margin: 8px;
  }

  .sm\:m-6px {
    margin: 6px;
  }

  .sm\:m-5px {
    margin: 5px;
  }

  .sm\:m-3px {
    margin: 3px;
  }

  .sm\:m-menu {
    margin: 275px;
  }

  .sm\:m-px {
    margin: 1px !important;
  }

  .sm\:-m-1 {
    margin: -0.25rem;
  }

  .sm\:-m-2 {
    margin: -0.5rem;
  }

  .sm\:-m-3 {
    margin: -0.75rem;
  }

  .sm\:-m-4 {
    margin: -1rem;
  }

  .sm\:-m-5 {
    margin: -1.25rem;
  }

  .sm\:-m-6 {
    margin: -1.5rem;
  }

  .sm\:-m-8 {
    margin: -2rem;
  }

  .sm\:-m-10 {
    margin: -2.5rem;
  }

  .sm\:-m-12 {
    margin: -3rem;
  }

  .sm\:-m-16 {
    margin: -4rem;
  }

  .sm\:-m-20 {
    margin: -5rem;
  }

  .sm\:-m-24 {
    margin: -6rem;
  }

  .sm\:-m-32 {
    margin: -8rem;
  }

  .sm\:-m-40 {
    margin: -10rem;
  }

  .sm\:-m-48 {
    margin: -12rem;
  }

  .sm\:-m-56 {
    margin: -14rem;
  }

  .sm\:-m-64 {
    margin: -16rem;
  }

  .sm\:-m-300px {
    margin: -300px;
  }

  .sm\:-m-230px {
    margin: -230px;
  }

  .sm\:-m-180px {
    margin: -180px;
  }

  .sm\:-m-147px {
    margin: -147px;
  }

  .sm\:-m-123px {
    margin: -123px;
  }

  .sm\:-m-100px {
    margin: -100px;
  }

  .sm\:-m-80px {
    margin: -80px;
  }

  .sm\:-m-60px {
    margin: -60px;
  }

  .sm\:-m-50px {
    margin: -50px;
  }

  .sm\:-m-40px {
    margin: -40px;
  }

  .sm\:-m-35px {
    margin: -35px;
  }

  .sm\:-m-34px-i {
    margin: 34px !important;
  }

  .sm\:-m-30px-i {
    margin: 30px !important;
  }

  .sm\:-m-24px-i {
    margin: 24px !important;
  }

  .sm\:-m-20px-i {
    margin: 20px !important;
  }

  .sm\:-m-15px-i {
    margin: 15px !important;
  }

  .sm\:-m-13px-i {
    margin: 13px !important;
  }

  .sm\:-m-12px-i {
    margin: 12px !important;
  }

  .sm\:-m-10px-i {
    margin: 10px !important;
  }

  .sm\:-m-8px-i {
    margin: 8px !important;
  }

  .sm\:-m-6px-i {
    margin: 6px !important;
  }

  .sm\:-m-5px-i {
    margin: 5px !important;
  }

  .sm\:-m-3px-i {
    margin: 3px !important;
  }

  .sm\:-m-0px-i {
    margin: 0px !important;
  }

  .sm\:-m-34px {
    margin: -34px;
  }

  .sm\:-m-30px {
    margin: -30px;
  }

  .sm\:-m-24px {
    margin: -24px;
  }

  .sm\:-m-20px {
    margin: -20px;
  }

  .sm\:-m-15px {
    margin: -15px;
  }

  .sm\:-m-13px {
    margin: -13px;
  }

  .sm\:-m-12px {
    margin: -12px;
  }

  .sm\:-m-10px {
    margin: -10px;
  }

  .sm\:-m-8px {
    margin: -8px;
  }

  .sm\:-m-6px {
    margin: -6px;
  }

  .sm\:-m-5px {
    margin: -5px;
  }

  .sm\:-m-3px {
    margin: -3px;
  }

  .sm\:-m-menu {
    margin: -275px;
  }

  .sm\:-m-px {
    margin: 1px !important;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .sm\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .sm\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .sm\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .sm\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .sm\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .sm\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .sm\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .sm\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .sm\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .sm\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .sm\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:mx-300px {
    margin-left: 300px;
    margin-right: 300px;
  }

  .sm\:mx-230px {
    margin-left: 230px;
    margin-right: 230px;
  }

  .sm\:mx-180px {
    margin-left: 180px;
    margin-right: 180px;
  }

  .sm\:mx-147px {
    margin-left: 147px;
    margin-right: 147px;
  }

  .sm\:mx-123px {
    margin-left: 123px;
    margin-right: 123px;
  }

  .sm\:mx-100px {
    margin-left: 100px;
    margin-right: 100px;
  }

  .sm\:mx-80px {
    margin-left: 80px;
    margin-right: 80px;
  }

  .sm\:mx-60px {
    margin-left: 60px;
    margin-right: 60px;
  }

  .sm\:mx-50px {
    margin-left: 50px;
    margin-right: 50px;
  }

  .sm\:mx-40px {
    margin-left: 40px;
    margin-right: 40px;
  }

  .sm\:mx-35px {
    margin-left: 35px;
    margin-right: 35px;
  }

  .sm\:mx-34px-i {
    margin-left: 34px !important;
    margin-right: 34px !important;
  }

  .sm\:mx-30px-i {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .sm\:mx-24px-i {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .sm\:mx-20px-i {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .sm\:mx-15px-i {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .sm\:mx-13px-i {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }

  .sm\:mx-12px-i {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .sm\:mx-10px-i {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .sm\:mx-8px-i {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .sm\:mx-6px-i {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .sm\:mx-5px-i {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .sm\:mx-3px-i {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .sm\:mx-0px-i {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .sm\:mx-34px {
    margin-left: 34px;
    margin-right: 34px;
  }

  .sm\:mx-30px {
    margin-left: 30px;
    margin-right: 30px;
  }

  .sm\:mx-24px {
    margin-left: 24px;
    margin-right: 24px;
  }

  .sm\:mx-20px {
    margin-left: 20px;
    margin-right: 20px;
  }

  .sm\:mx-15px {
    margin-left: 15px;
    margin-right: 15px;
  }

  .sm\:mx-13px {
    margin-left: 13px;
    margin-right: 13px;
  }

  .sm\:mx-12px {
    margin-left: 12px;
    margin-right: 12px;
  }

  .sm\:mx-10px {
    margin-left: 10px;
    margin-right: 10px;
  }

  .sm\:mx-8px {
    margin-left: 8px;
    margin-right: 8px;
  }

  .sm\:mx-6px {
    margin-left: 6px;
    margin-right: 6px;
  }

  .sm\:mx-5px {
    margin-left: 5px;
    margin-right: 5px;
  }

  .sm\:mx-3px {
    margin-left: 3px;
    margin-right: 3px;
  }

  .sm\:mx-menu {
    margin-left: 275px;
    margin-right: 275px;
  }

  .sm\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .sm\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .sm\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .sm\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .sm\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .sm\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .sm\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .sm\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .sm\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .sm\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .sm\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .sm\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .sm\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .sm\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .sm\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .sm\:-mx-300px {
    margin-left: -300px;
    margin-right: -300px;
  }

  .sm\:-mx-230px {
    margin-left: -230px;
    margin-right: -230px;
  }

  .sm\:-mx-180px {
    margin-left: -180px;
    margin-right: -180px;
  }

  .sm\:-mx-147px {
    margin-left: -147px;
    margin-right: -147px;
  }

  .sm\:-mx-123px {
    margin-left: -123px;
    margin-right: -123px;
  }

  .sm\:-mx-100px {
    margin-left: -100px;
    margin-right: -100px;
  }

  .sm\:-mx-80px {
    margin-left: -80px;
    margin-right: -80px;
  }

  .sm\:-mx-60px {
    margin-left: -60px;
    margin-right: -60px;
  }

  .sm\:-mx-50px {
    margin-left: -50px;
    margin-right: -50px;
  }

  .sm\:-mx-40px {
    margin-left: -40px;
    margin-right: -40px;
  }

  .sm\:-mx-35px {
    margin-left: -35px;
    margin-right: -35px;
  }

  .sm\:-mx-34px-i {
    margin-left: 34px !important;
    margin-right: 34px !important;
  }

  .sm\:-mx-30px-i {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .sm\:-mx-24px-i {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .sm\:-mx-20px-i {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .sm\:-mx-15px-i {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .sm\:-mx-13px-i {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }

  .sm\:-mx-12px-i {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .sm\:-mx-10px-i {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .sm\:-mx-8px-i {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .sm\:-mx-6px-i {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .sm\:-mx-5px-i {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .sm\:-mx-3px-i {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .sm\:-mx-0px-i {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .sm\:-mx-34px {
    margin-left: -34px;
    margin-right: -34px;
  }

  .sm\:-mx-30px {
    margin-left: -30px;
    margin-right: -30px;
  }

  .sm\:-mx-24px {
    margin-left: -24px;
    margin-right: -24px;
  }

  .sm\:-mx-20px {
    margin-left: -20px;
    margin-right: -20px;
  }

  .sm\:-mx-15px {
    margin-left: -15px;
    margin-right: -15px;
  }

  .sm\:-mx-13px {
    margin-left: -13px;
    margin-right: -13px;
  }

  .sm\:-mx-12px {
    margin-left: -12px;
    margin-right: -12px;
  }

  .sm\:-mx-10px {
    margin-left: -10px;
    margin-right: -10px;
  }

  .sm\:-mx-8px {
    margin-left: -8px;
    margin-right: -8px;
  }

  .sm\:-mx-6px {
    margin-left: -6px;
    margin-right: -6px;
  }

  .sm\:-mx-5px {
    margin-left: -5px;
    margin-right: -5px;
  }

  .sm\:-mx-3px {
    margin-left: -3px;
    margin-right: -3px;
  }

  .sm\:-mx-menu {
    margin-left: -275px;
    margin-right: -275px;
  }

  .sm\:-mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .sm\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .sm\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .sm\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sm\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .sm\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .sm\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .sm\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .sm\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .sm\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .sm\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .sm\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .sm\:my-300px {
    margin-top: 300px;
    margin-bottom: 300px;
  }

  .sm\:my-230px {
    margin-top: 230px;
    margin-bottom: 230px;
  }

  .sm\:my-180px {
    margin-top: 180px;
    margin-bottom: 180px;
  }

  .sm\:my-147px {
    margin-top: 147px;
    margin-bottom: 147px;
  }

  .sm\:my-123px {
    margin-top: 123px;
    margin-bottom: 123px;
  }

  .sm\:my-100px {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .sm\:my-80px {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .sm\:my-60px {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .sm\:my-50px {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .sm\:my-40px {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .sm\:my-35px {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .sm\:my-34px-i {
    margin-top: 34px !important;
    margin-bottom: 34px !important;
  }

  .sm\:my-30px-i {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .sm\:my-24px-i {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .sm\:my-20px-i {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .sm\:my-15px-i {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .sm\:my-13px-i {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }

  .sm\:my-12px-i {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .sm\:my-10px-i {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .sm\:my-8px-i {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .sm\:my-6px-i {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .sm\:my-5px-i {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .sm\:my-3px-i {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .sm\:my-0px-i {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .sm\:my-34px {
    margin-top: 34px;
    margin-bottom: 34px;
  }

  .sm\:my-30px {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .sm\:my-24px {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .sm\:my-20px {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .sm\:my-15px {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .sm\:my-13px {
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .sm\:my-12px {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .sm\:my-10px {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .sm\:my-8px {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .sm\:my-6px {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .sm\:my-5px {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .sm\:my-3px {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .sm\:my-menu {
    margin-top: 275px;
    margin-bottom: 275px;
  }

  .sm\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .sm\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .sm\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .sm\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .sm\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .sm\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .sm\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .sm\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .sm\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .sm\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .sm\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .sm\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .sm\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .sm\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .sm\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .sm\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .sm\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .sm\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .sm\:-my-300px {
    margin-top: -300px;
    margin-bottom: -300px;
  }

  .sm\:-my-230px {
    margin-top: -230px;
    margin-bottom: -230px;
  }

  .sm\:-my-180px {
    margin-top: -180px;
    margin-bottom: -180px;
  }

  .sm\:-my-147px {
    margin-top: -147px;
    margin-bottom: -147px;
  }

  .sm\:-my-123px {
    margin-top: -123px;
    margin-bottom: -123px;
  }

  .sm\:-my-100px {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .sm\:-my-80px {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .sm\:-my-60px {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .sm\:-my-50px {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .sm\:-my-40px {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .sm\:-my-35px {
    margin-top: -35px;
    margin-bottom: -35px;
  }

  .sm\:-my-34px-i {
    margin-top: 34px !important;
    margin-bottom: 34px !important;
  }

  .sm\:-my-30px-i {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .sm\:-my-24px-i {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .sm\:-my-20px-i {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .sm\:-my-15px-i {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .sm\:-my-13px-i {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }

  .sm\:-my-12px-i {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .sm\:-my-10px-i {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .sm\:-my-8px-i {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .sm\:-my-6px-i {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .sm\:-my-5px-i {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .sm\:-my-3px-i {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .sm\:-my-0px-i {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .sm\:-my-34px {
    margin-top: -34px;
    margin-bottom: -34px;
  }

  .sm\:-my-30px {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .sm\:-my-24px {
    margin-top: -24px;
    margin-bottom: -24px;
  }

  .sm\:-my-20px {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .sm\:-my-15px {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .sm\:-my-13px {
    margin-top: -13px;
    margin-bottom: -13px;
  }

  .sm\:-my-12px {
    margin-top: -12px;
    margin-bottom: -12px;
  }

  .sm\:-my-10px {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .sm\:-my-8px {
    margin-top: -8px;
    margin-bottom: -8px;
  }

  .sm\:-my-6px {
    margin-top: -6px;
    margin-bottom: -6px;
  }

  .sm\:-my-5px {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .sm\:-my-3px {
    margin-top: -3px;
    margin-bottom: -3px;
  }

  .sm\:-my-menu {
    margin-top: -275px;
    margin-bottom: -275px;
  }

  .sm\:-my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-1 {
    margin-top: 0.25rem;
  }

  .sm\:mt-2 {
    margin-top: 0.5rem;
  }

  .sm\:mt-3 {
    margin-top: 0.75rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:mt-8 {
    margin-top: 2rem;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:mt-12 {
    margin-top: 3rem;
  }

  .sm\:mt-16 {
    margin-top: 4rem;
  }

  .sm\:mt-20 {
    margin-top: 5rem;
  }

  .sm\:mt-24 {
    margin-top: 6rem;
  }

  .sm\:mt-32 {
    margin-top: 8rem;
  }

  .sm\:mt-40 {
    margin-top: 10rem;
  }

  .sm\:mt-48 {
    margin-top: 12rem;
  }

  .sm\:mt-56 {
    margin-top: 14rem;
  }

  .sm\:mt-64 {
    margin-top: 16rem;
  }

  .sm\:mt-auto {
    margin-top: auto;
  }

  .sm\:mt-300px {
    margin-top: 300px;
  }

  .sm\:mt-230px {
    margin-top: 230px;
  }

  .sm\:mt-180px {
    margin-top: 180px;
  }

  .sm\:mt-147px {
    margin-top: 147px;
  }

  .sm\:mt-123px {
    margin-top: 123px;
  }

  .sm\:mt-100px {
    margin-top: 100px;
  }

  .sm\:mt-80px {
    margin-top: 80px;
  }

  .sm\:mt-60px {
    margin-top: 60px;
  }

  .sm\:mt-50px {
    margin-top: 50px;
  }

  .sm\:mt-40px {
    margin-top: 40px;
  }

  .sm\:mt-35px {
    margin-top: 35px;
  }

  .sm\:mt-34px-i {
    margin-top: 34px !important;
  }

  .sm\:mt-30px-i {
    margin-top: 30px !important;
  }

  .sm\:mt-24px-i {
    margin-top: 24px !important;
  }

  .sm\:mt-20px-i {
    margin-top: 20px !important;
  }

  .sm\:mt-15px-i {
    margin-top: 15px !important;
  }

  .sm\:mt-13px-i {
    margin-top: 13px !important;
  }

  .sm\:mt-12px-i {
    margin-top: 12px !important;
  }

  .sm\:mt-10px-i {
    margin-top: 10px !important;
  }

  .sm\:mt-8px-i {
    margin-top: 8px !important;
  }

  .sm\:mt-6px-i {
    margin-top: 6px !important;
  }

  .sm\:mt-5px-i {
    margin-top: 5px !important;
  }

  .sm\:mt-3px-i {
    margin-top: 3px !important;
  }

  .sm\:mt-0px-i {
    margin-top: 0px !important;
  }

  .sm\:mt-34px {
    margin-top: 34px;
  }

  .sm\:mt-30px {
    margin-top: 30px;
  }

  .sm\:mt-24px {
    margin-top: 24px;
  }

  .sm\:mt-20px {
    margin-top: 20px;
  }

  .sm\:mt-15px {
    margin-top: 15px;
  }

  .sm\:mt-13px {
    margin-top: 13px;
  }

  .sm\:mt-12px {
    margin-top: 12px;
  }

  .sm\:mt-10px {
    margin-top: 10px;
  }

  .sm\:mt-8px {
    margin-top: 8px;
  }

  .sm\:mt-6px {
    margin-top: 6px;
  }

  .sm\:mt-5px {
    margin-top: 5px;
  }

  .sm\:mt-3px {
    margin-top: 3px;
  }

  .sm\:mt-menu {
    margin-top: 275px;
  }

  .sm\:mt-px {
    margin-top: 1px !important;
  }

  .sm\:-mt-1 {
    margin-top: -0.25rem;
  }

  .sm\:-mt-2 {
    margin-top: -0.5rem;
  }

  .sm\:-mt-3 {
    margin-top: -0.75rem;
  }

  .sm\:-mt-4 {
    margin-top: -1rem;
  }

  .sm\:-mt-5 {
    margin-top: -1.25rem;
  }

  .sm\:-mt-6 {
    margin-top: -1.5rem;
  }

  .sm\:-mt-8 {
    margin-top: -2rem;
  }

  .sm\:-mt-10 {
    margin-top: -2.5rem;
  }

  .sm\:-mt-12 {
    margin-top: -3rem;
  }

  .sm\:-mt-16 {
    margin-top: -4rem;
  }

  .sm\:-mt-20 {
    margin-top: -5rem;
  }

  .sm\:-mt-24 {
    margin-top: -6rem;
  }

  .sm\:-mt-32 {
    margin-top: -8rem;
  }

  .sm\:-mt-40 {
    margin-top: -10rem;
  }

  .sm\:-mt-48 {
    margin-top: -12rem;
  }

  .sm\:-mt-56 {
    margin-top: -14rem;
  }

  .sm\:-mt-64 {
    margin-top: -16rem;
  }

  .sm\:-mt-300px {
    margin-top: -300px;
  }

  .sm\:-mt-230px {
    margin-top: -230px;
  }

  .sm\:-mt-180px {
    margin-top: -180px;
  }

  .sm\:-mt-147px {
    margin-top: -147px;
  }

  .sm\:-mt-123px {
    margin-top: -123px;
  }

  .sm\:-mt-100px {
    margin-top: -100px;
  }

  .sm\:-mt-80px {
    margin-top: -80px;
  }

  .sm\:-mt-60px {
    margin-top: -60px;
  }

  .sm\:-mt-50px {
    margin-top: -50px;
  }

  .sm\:-mt-40px {
    margin-top: -40px;
  }

  .sm\:-mt-35px {
    margin-top: -35px;
  }

  .sm\:-mt-34px-i {
    margin-top: 34px !important;
  }

  .sm\:-mt-30px-i {
    margin-top: 30px !important;
  }

  .sm\:-mt-24px-i {
    margin-top: 24px !important;
  }

  .sm\:-mt-20px-i {
    margin-top: 20px !important;
  }

  .sm\:-mt-15px-i {
    margin-top: 15px !important;
  }

  .sm\:-mt-13px-i {
    margin-top: 13px !important;
  }

  .sm\:-mt-12px-i {
    margin-top: 12px !important;
  }

  .sm\:-mt-10px-i {
    margin-top: 10px !important;
  }

  .sm\:-mt-8px-i {
    margin-top: 8px !important;
  }

  .sm\:-mt-6px-i {
    margin-top: 6px !important;
  }

  .sm\:-mt-5px-i {
    margin-top: 5px !important;
  }

  .sm\:-mt-3px-i {
    margin-top: 3px !important;
  }

  .sm\:-mt-0px-i {
    margin-top: 0px !important;
  }

  .sm\:-mt-34px {
    margin-top: -34px;
  }

  .sm\:-mt-30px {
    margin-top: -30px;
  }

  .sm\:-mt-24px {
    margin-top: -24px;
  }

  .sm\:-mt-20px {
    margin-top: -20px;
  }

  .sm\:-mt-15px {
    margin-top: -15px;
  }

  .sm\:-mt-13px {
    margin-top: -13px;
  }

  .sm\:-mt-12px {
    margin-top: -12px;
  }

  .sm\:-mt-10px {
    margin-top: -10px;
  }

  .sm\:-mt-8px {
    margin-top: -8px;
  }

  .sm\:-mt-6px {
    margin-top: -6px;
  }

  .sm\:-mt-5px {
    margin-top: -5px;
  }

  .sm\:-mt-3px {
    margin-top: -3px;
  }

  .sm\:-mt-menu {
    margin-top: -275px;
  }

  .sm\:-mt-px {
    margin-top: 1px !important;
  }

  .sm\:mr-0 {
    margin-right: 0;
  }

  .sm\:mr-1 {
    margin-right: 0.25rem;
  }

  .sm\:mr-2 {
    margin-right: 0.5rem;
  }

  .sm\:mr-3 {
    margin-right: 0.75rem;
  }

  .sm\:mr-4 {
    margin-right: 1rem;
  }

  .sm\:mr-5 {
    margin-right: 1.25rem;
  }

  .sm\:mr-6 {
    margin-right: 1.5rem;
  }

  .sm\:mr-8 {
    margin-right: 2rem;
  }

  .sm\:mr-10 {
    margin-right: 2.5rem;
  }

  .sm\:mr-12 {
    margin-right: 3rem;
  }

  .sm\:mr-16 {
    margin-right: 4rem;
  }

  .sm\:mr-20 {
    margin-right: 5rem;
  }

  .sm\:mr-24 {
    margin-right: 6rem;
  }

  .sm\:mr-32 {
    margin-right: 8rem;
  }

  .sm\:mr-40 {
    margin-right: 10rem;
  }

  .sm\:mr-48 {
    margin-right: 12rem;
  }

  .sm\:mr-56 {
    margin-right: 14rem;
  }

  .sm\:mr-64 {
    margin-right: 16rem;
  }

  .sm\:mr-auto {
    margin-right: auto;
  }

  .sm\:mr-300px {
    margin-right: 300px;
  }

  .sm\:mr-230px {
    margin-right: 230px;
  }

  .sm\:mr-180px {
    margin-right: 180px;
  }

  .sm\:mr-147px {
    margin-right: 147px;
  }

  .sm\:mr-123px {
    margin-right: 123px;
  }

  .sm\:mr-100px {
    margin-right: 100px;
  }

  .sm\:mr-80px {
    margin-right: 80px;
  }

  .sm\:mr-60px {
    margin-right: 60px;
  }

  .sm\:mr-50px {
    margin-right: 50px;
  }

  .sm\:mr-40px {
    margin-right: 40px;
  }

  .sm\:mr-35px {
    margin-right: 35px;
  }

  .sm\:mr-34px-i {
    margin-right: 34px !important;
  }

  .sm\:mr-30px-i {
    margin-right: 30px !important;
  }

  .sm\:mr-24px-i {
    margin-right: 24px !important;
  }

  .sm\:mr-20px-i {
    margin-right: 20px !important;
  }

  .sm\:mr-15px-i {
    margin-right: 15px !important;
  }

  .sm\:mr-13px-i {
    margin-right: 13px !important;
  }

  .sm\:mr-12px-i {
    margin-right: 12px !important;
  }

  .sm\:mr-10px-i {
    margin-right: 10px !important;
  }

  .sm\:mr-8px-i {
    margin-right: 8px !important;
  }

  .sm\:mr-6px-i {
    margin-right: 6px !important;
  }

  .sm\:mr-5px-i {
    margin-right: 5px !important;
  }

  .sm\:mr-3px-i {
    margin-right: 3px !important;
  }

  .sm\:mr-0px-i {
    margin-right: 0px !important;
  }

  .sm\:mr-34px {
    margin-right: 34px;
  }

  .sm\:mr-30px {
    margin-right: 30px;
  }

  .sm\:mr-24px {
    margin-right: 24px;
  }

  .sm\:mr-20px {
    margin-right: 20px;
  }

  .sm\:mr-15px {
    margin-right: 15px;
  }

  .sm\:mr-13px {
    margin-right: 13px;
  }

  .sm\:mr-12px {
    margin-right: 12px;
  }

  .sm\:mr-10px {
    margin-right: 10px;
  }

  .sm\:mr-8px {
    margin-right: 8px;
  }

  .sm\:mr-6px {
    margin-right: 6px;
  }

  .sm\:mr-5px {
    margin-right: 5px;
  }

  .sm\:mr-3px {
    margin-right: 3px;
  }

  .sm\:mr-menu {
    margin-right: 275px;
  }

  .sm\:mr-px {
    margin-right: 1px !important;
  }

  .sm\:-mr-1 {
    margin-right: -0.25rem;
  }

  .sm\:-mr-2 {
    margin-right: -0.5rem;
  }

  .sm\:-mr-3 {
    margin-right: -0.75rem;
  }

  .sm\:-mr-4 {
    margin-right: -1rem;
  }

  .sm\:-mr-5 {
    margin-right: -1.25rem;
  }

  .sm\:-mr-6 {
    margin-right: -1.5rem;
  }

  .sm\:-mr-8 {
    margin-right: -2rem;
  }

  .sm\:-mr-10 {
    margin-right: -2.5rem;
  }

  .sm\:-mr-12 {
    margin-right: -3rem;
  }

  .sm\:-mr-16 {
    margin-right: -4rem;
  }

  .sm\:-mr-20 {
    margin-right: -5rem;
  }

  .sm\:-mr-24 {
    margin-right: -6rem;
  }

  .sm\:-mr-32 {
    margin-right: -8rem;
  }

  .sm\:-mr-40 {
    margin-right: -10rem;
  }

  .sm\:-mr-48 {
    margin-right: -12rem;
  }

  .sm\:-mr-56 {
    margin-right: -14rem;
  }

  .sm\:-mr-64 {
    margin-right: -16rem;
  }

  .sm\:-mr-300px {
    margin-right: -300px;
  }

  .sm\:-mr-230px {
    margin-right: -230px;
  }

  .sm\:-mr-180px {
    margin-right: -180px;
  }

  .sm\:-mr-147px {
    margin-right: -147px;
  }

  .sm\:-mr-123px {
    margin-right: -123px;
  }

  .sm\:-mr-100px {
    margin-right: -100px;
  }

  .sm\:-mr-80px {
    margin-right: -80px;
  }

  .sm\:-mr-60px {
    margin-right: -60px;
  }

  .sm\:-mr-50px {
    margin-right: -50px;
  }

  .sm\:-mr-40px {
    margin-right: -40px;
  }

  .sm\:-mr-35px {
    margin-right: -35px;
  }

  .sm\:-mr-34px-i {
    margin-right: 34px !important;
  }

  .sm\:-mr-30px-i {
    margin-right: 30px !important;
  }

  .sm\:-mr-24px-i {
    margin-right: 24px !important;
  }

  .sm\:-mr-20px-i {
    margin-right: 20px !important;
  }

  .sm\:-mr-15px-i {
    margin-right: 15px !important;
  }

  .sm\:-mr-13px-i {
    margin-right: 13px !important;
  }

  .sm\:-mr-12px-i {
    margin-right: 12px !important;
  }

  .sm\:-mr-10px-i {
    margin-right: 10px !important;
  }

  .sm\:-mr-8px-i {
    margin-right: 8px !important;
  }

  .sm\:-mr-6px-i {
    margin-right: 6px !important;
  }

  .sm\:-mr-5px-i {
    margin-right: 5px !important;
  }

  .sm\:-mr-3px-i {
    margin-right: 3px !important;
  }

  .sm\:-mr-0px-i {
    margin-right: 0px !important;
  }

  .sm\:-mr-34px {
    margin-right: -34px;
  }

  .sm\:-mr-30px {
    margin-right: -30px;
  }

  .sm\:-mr-24px {
    margin-right: -24px;
  }

  .sm\:-mr-20px {
    margin-right: -20px;
  }

  .sm\:-mr-15px {
    margin-right: -15px;
  }

  .sm\:-mr-13px {
    margin-right: -13px;
  }

  .sm\:-mr-12px {
    margin-right: -12px;
  }

  .sm\:-mr-10px {
    margin-right: -10px;
  }

  .sm\:-mr-8px {
    margin-right: -8px;
  }

  .sm\:-mr-6px {
    margin-right: -6px;
  }

  .sm\:-mr-5px {
    margin-right: -5px;
  }

  .sm\:-mr-3px {
    margin-right: -3px;
  }

  .sm\:-mr-menu {
    margin-right: -275px;
  }

  .sm\:-mr-px {
    margin-right: 1px !important;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:mb-1 {
    margin-bottom: 0.25rem;
  }

  .sm\:mb-2 {
    margin-bottom: 0.5rem;
  }

  .sm\:mb-3 {
    margin-bottom: 0.75rem;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:mb-12 {
    margin-bottom: 3rem;
  }

  .sm\:mb-16 {
    margin-bottom: 4rem;
  }

  .sm\:mb-20 {
    margin-bottom: 5rem;
  }

  .sm\:mb-24 {
    margin-bottom: 6rem;
  }

  .sm\:mb-32 {
    margin-bottom: 8rem;
  }

  .sm\:mb-40 {
    margin-bottom: 10rem;
  }

  .sm\:mb-48 {
    margin-bottom: 12rem;
  }

  .sm\:mb-56 {
    margin-bottom: 14rem;
  }

  .sm\:mb-64 {
    margin-bottom: 16rem;
  }

  .sm\:mb-auto {
    margin-bottom: auto;
  }

  .sm\:mb-300px {
    margin-bottom: 300px;
  }

  .sm\:mb-230px {
    margin-bottom: 230px;
  }

  .sm\:mb-180px {
    margin-bottom: 180px;
  }

  .sm\:mb-147px {
    margin-bottom: 147px;
  }

  .sm\:mb-123px {
    margin-bottom: 123px;
  }

  .sm\:mb-100px {
    margin-bottom: 100px;
  }

  .sm\:mb-80px {
    margin-bottom: 80px;
  }

  .sm\:mb-60px {
    margin-bottom: 60px;
  }

  .sm\:mb-50px {
    margin-bottom: 50px;
  }

  .sm\:mb-40px {
    margin-bottom: 40px;
  }

  .sm\:mb-35px {
    margin-bottom: 35px;
  }

  .sm\:mb-34px-i {
    margin-bottom: 34px !important;
  }

  .sm\:mb-30px-i {
    margin-bottom: 30px !important;
  }

  .sm\:mb-24px-i {
    margin-bottom: 24px !important;
  }

  .sm\:mb-20px-i {
    margin-bottom: 20px !important;
  }

  .sm\:mb-15px-i {
    margin-bottom: 15px !important;
  }

  .sm\:mb-13px-i {
    margin-bottom: 13px !important;
  }

  .sm\:mb-12px-i {
    margin-bottom: 12px !important;
  }

  .sm\:mb-10px-i {
    margin-bottom: 10px !important;
  }

  .sm\:mb-8px-i {
    margin-bottom: 8px !important;
  }

  .sm\:mb-6px-i {
    margin-bottom: 6px !important;
  }

  .sm\:mb-5px-i {
    margin-bottom: 5px !important;
  }

  .sm\:mb-3px-i {
    margin-bottom: 3px !important;
  }

  .sm\:mb-0px-i {
    margin-bottom: 0px !important;
  }

  .sm\:mb-34px {
    margin-bottom: 34px;
  }

  .sm\:mb-30px {
    margin-bottom: 30px;
  }

  .sm\:mb-24px {
    margin-bottom: 24px;
  }

  .sm\:mb-20px {
    margin-bottom: 20px;
  }

  .sm\:mb-15px {
    margin-bottom: 15px;
  }

  .sm\:mb-13px {
    margin-bottom: 13px;
  }

  .sm\:mb-12px {
    margin-bottom: 12px;
  }

  .sm\:mb-10px {
    margin-bottom: 10px;
  }

  .sm\:mb-8px {
    margin-bottom: 8px;
  }

  .sm\:mb-6px {
    margin-bottom: 6px;
  }

  .sm\:mb-5px {
    margin-bottom: 5px;
  }

  .sm\:mb-3px {
    margin-bottom: 3px;
  }

  .sm\:mb-menu {
    margin-bottom: 275px;
  }

  .sm\:mb-px {
    margin-bottom: 1px !important;
  }

  .sm\:-mb-1 {
    margin-bottom: -0.25rem;
  }

  .sm\:-mb-2 {
    margin-bottom: -0.5rem;
  }

  .sm\:-mb-3 {
    margin-bottom: -0.75rem;
  }

  .sm\:-mb-4 {
    margin-bottom: -1rem;
  }

  .sm\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .sm\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .sm\:-mb-8 {
    margin-bottom: -2rem;
  }

  .sm\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .sm\:-mb-12 {
    margin-bottom: -3rem;
  }

  .sm\:-mb-16 {
    margin-bottom: -4rem;
  }

  .sm\:-mb-20 {
    margin-bottom: -5rem;
  }

  .sm\:-mb-24 {
    margin-bottom: -6rem;
  }

  .sm\:-mb-32 {
    margin-bottom: -8rem;
  }

  .sm\:-mb-40 {
    margin-bottom: -10rem;
  }

  .sm\:-mb-48 {
    margin-bottom: -12rem;
  }

  .sm\:-mb-56 {
    margin-bottom: -14rem;
  }

  .sm\:-mb-64 {
    margin-bottom: -16rem;
  }

  .sm\:-mb-300px {
    margin-bottom: -300px;
  }

  .sm\:-mb-230px {
    margin-bottom: -230px;
  }

  .sm\:-mb-180px {
    margin-bottom: -180px;
  }

  .sm\:-mb-147px {
    margin-bottom: -147px;
  }

  .sm\:-mb-123px {
    margin-bottom: -123px;
  }

  .sm\:-mb-100px {
    margin-bottom: -100px;
  }

  .sm\:-mb-80px {
    margin-bottom: -80px;
  }

  .sm\:-mb-60px {
    margin-bottom: -60px;
  }

  .sm\:-mb-50px {
    margin-bottom: -50px;
  }

  .sm\:-mb-40px {
    margin-bottom: -40px;
  }

  .sm\:-mb-35px {
    margin-bottom: -35px;
  }

  .sm\:-mb-34px-i {
    margin-bottom: 34px !important;
  }

  .sm\:-mb-30px-i {
    margin-bottom: 30px !important;
  }

  .sm\:-mb-24px-i {
    margin-bottom: 24px !important;
  }

  .sm\:-mb-20px-i {
    margin-bottom: 20px !important;
  }

  .sm\:-mb-15px-i {
    margin-bottom: 15px !important;
  }

  .sm\:-mb-13px-i {
    margin-bottom: 13px !important;
  }

  .sm\:-mb-12px-i {
    margin-bottom: 12px !important;
  }

  .sm\:-mb-10px-i {
    margin-bottom: 10px !important;
  }

  .sm\:-mb-8px-i {
    margin-bottom: 8px !important;
  }

  .sm\:-mb-6px-i {
    margin-bottom: 6px !important;
  }

  .sm\:-mb-5px-i {
    margin-bottom: 5px !important;
  }

  .sm\:-mb-3px-i {
    margin-bottom: 3px !important;
  }

  .sm\:-mb-0px-i {
    margin-bottom: 0px !important;
  }

  .sm\:-mb-34px {
    margin-bottom: -34px;
  }

  .sm\:-mb-30px {
    margin-bottom: -30px;
  }

  .sm\:-mb-24px {
    margin-bottom: -24px;
  }

  .sm\:-mb-20px {
    margin-bottom: -20px;
  }

  .sm\:-mb-15px {
    margin-bottom: -15px;
  }

  .sm\:-mb-13px {
    margin-bottom: -13px;
  }

  .sm\:-mb-12px {
    margin-bottom: -12px;
  }

  .sm\:-mb-10px {
    margin-bottom: -10px;
  }

  .sm\:-mb-8px {
    margin-bottom: -8px;
  }

  .sm\:-mb-6px {
    margin-bottom: -6px;
  }

  .sm\:-mb-5px {
    margin-bottom: -5px;
  }

  .sm\:-mb-3px {
    margin-bottom: -3px;
  }

  .sm\:-mb-menu {
    margin-bottom: -275px;
  }

  .sm\:-mb-px {
    margin-bottom: 1px !important;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:ml-1 {
    margin-left: 0.25rem;
  }

  .sm\:ml-2 {
    margin-left: 0.5rem;
  }

  .sm\:ml-3 {
    margin-left: 0.75rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:ml-5 {
    margin-left: 1.25rem;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:ml-8 {
    margin-left: 2rem;
  }

  .sm\:ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:ml-12 {
    margin-left: 3rem;
  }

  .sm\:ml-16 {
    margin-left: 4rem;
  }

  .sm\:ml-20 {
    margin-left: 5rem;
  }

  .sm\:ml-24 {
    margin-left: 6rem;
  }

  .sm\:ml-32 {
    margin-left: 8rem;
  }

  .sm\:ml-40 {
    margin-left: 10rem;
  }

  .sm\:ml-48 {
    margin-left: 12rem;
  }

  .sm\:ml-56 {
    margin-left: 14rem;
  }

  .sm\:ml-64 {
    margin-left: 16rem;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:ml-300px {
    margin-left: 300px;
  }

  .sm\:ml-230px {
    margin-left: 230px;
  }

  .sm\:ml-180px {
    margin-left: 180px;
  }

  .sm\:ml-147px {
    margin-left: 147px;
  }

  .sm\:ml-123px {
    margin-left: 123px;
  }

  .sm\:ml-100px {
    margin-left: 100px;
  }

  .sm\:ml-80px {
    margin-left: 80px;
  }

  .sm\:ml-60px {
    margin-left: 60px;
  }

  .sm\:ml-50px {
    margin-left: 50px;
  }

  .sm\:ml-40px {
    margin-left: 40px;
  }

  .sm\:ml-35px {
    margin-left: 35px;
  }

  .sm\:ml-34px-i {
    margin-left: 34px !important;
  }

  .sm\:ml-30px-i {
    margin-left: 30px !important;
  }

  .sm\:ml-24px-i {
    margin-left: 24px !important;
  }

  .sm\:ml-20px-i {
    margin-left: 20px !important;
  }

  .sm\:ml-15px-i {
    margin-left: 15px !important;
  }

  .sm\:ml-13px-i {
    margin-left: 13px !important;
  }

  .sm\:ml-12px-i {
    margin-left: 12px !important;
  }

  .sm\:ml-10px-i {
    margin-left: 10px !important;
  }

  .sm\:ml-8px-i {
    margin-left: 8px !important;
  }

  .sm\:ml-6px-i {
    margin-left: 6px !important;
  }

  .sm\:ml-5px-i {
    margin-left: 5px !important;
  }

  .sm\:ml-3px-i {
    margin-left: 3px !important;
  }

  .sm\:ml-0px-i {
    margin-left: 0px !important;
  }

  .sm\:ml-34px {
    margin-left: 34px;
  }

  .sm\:ml-30px {
    margin-left: 30px;
  }

  .sm\:ml-24px {
    margin-left: 24px;
  }

  .sm\:ml-20px {
    margin-left: 20px;
  }

  .sm\:ml-15px {
    margin-left: 15px;
  }

  .sm\:ml-13px {
    margin-left: 13px;
  }

  .sm\:ml-12px {
    margin-left: 12px;
  }

  .sm\:ml-10px {
    margin-left: 10px;
  }

  .sm\:ml-8px {
    margin-left: 8px;
  }

  .sm\:ml-6px {
    margin-left: 6px;
  }

  .sm\:ml-5px {
    margin-left: 5px;
  }

  .sm\:ml-3px {
    margin-left: 3px;
  }

  .sm\:ml-menu {
    margin-left: 275px;
  }

  .sm\:ml-px {
    margin-left: 1px !important;
  }

  .sm\:-ml-1 {
    margin-left: -0.25rem;
  }

  .sm\:-ml-2 {
    margin-left: -0.5rem;
  }

  .sm\:-ml-3 {
    margin-left: -0.75rem;
  }

  .sm\:-ml-4 {
    margin-left: -1rem;
  }

  .sm\:-ml-5 {
    margin-left: -1.25rem;
  }

  .sm\:-ml-6 {
    margin-left: -1.5rem;
  }

  .sm\:-ml-8 {
    margin-left: -2rem;
  }

  .sm\:-ml-10 {
    margin-left: -2.5rem;
  }

  .sm\:-ml-12 {
    margin-left: -3rem;
  }

  .sm\:-ml-16 {
    margin-left: -4rem;
  }

  .sm\:-ml-20 {
    margin-left: -5rem;
  }

  .sm\:-ml-24 {
    margin-left: -6rem;
  }

  .sm\:-ml-32 {
    margin-left: -8rem;
  }

  .sm\:-ml-40 {
    margin-left: -10rem;
  }

  .sm\:-ml-48 {
    margin-left: -12rem;
  }

  .sm\:-ml-56 {
    margin-left: -14rem;
  }

  .sm\:-ml-64 {
    margin-left: -16rem;
  }

  .sm\:-ml-300px {
    margin-left: -300px;
  }

  .sm\:-ml-230px {
    margin-left: -230px;
  }

  .sm\:-ml-180px {
    margin-left: -180px;
  }

  .sm\:-ml-147px {
    margin-left: -147px;
  }

  .sm\:-ml-123px {
    margin-left: -123px;
  }

  .sm\:-ml-100px {
    margin-left: -100px;
  }

  .sm\:-ml-80px {
    margin-left: -80px;
  }

  .sm\:-ml-60px {
    margin-left: -60px;
  }

  .sm\:-ml-50px {
    margin-left: -50px;
  }

  .sm\:-ml-40px {
    margin-left: -40px;
  }

  .sm\:-ml-35px {
    margin-left: -35px;
  }

  .sm\:-ml-34px-i {
    margin-left: 34px !important;
  }

  .sm\:-ml-30px-i {
    margin-left: 30px !important;
  }

  .sm\:-ml-24px-i {
    margin-left: 24px !important;
  }

  .sm\:-ml-20px-i {
    margin-left: 20px !important;
  }

  .sm\:-ml-15px-i {
    margin-left: 15px !important;
  }

  .sm\:-ml-13px-i {
    margin-left: 13px !important;
  }

  .sm\:-ml-12px-i {
    margin-left: 12px !important;
  }

  .sm\:-ml-10px-i {
    margin-left: 10px !important;
  }

  .sm\:-ml-8px-i {
    margin-left: 8px !important;
  }

  .sm\:-ml-6px-i {
    margin-left: 6px !important;
  }

  .sm\:-ml-5px-i {
    margin-left: 5px !important;
  }

  .sm\:-ml-3px-i {
    margin-left: 3px !important;
  }

  .sm\:-ml-0px-i {
    margin-left: 0px !important;
  }

  .sm\:-ml-34px {
    margin-left: -34px;
  }

  .sm\:-ml-30px {
    margin-left: -30px;
  }

  .sm\:-ml-24px {
    margin-left: -24px;
  }

  .sm\:-ml-20px {
    margin-left: -20px;
  }

  .sm\:-ml-15px {
    margin-left: -15px;
  }

  .sm\:-ml-13px {
    margin-left: -13px;
  }

  .sm\:-ml-12px {
    margin-left: -12px;
  }

  .sm\:-ml-10px {
    margin-left: -10px;
  }

  .sm\:-ml-8px {
    margin-left: -8px;
  }

  .sm\:-ml-6px {
    margin-left: -6px;
  }

  .sm\:-ml-5px {
    margin-left: -5px;
  }

  .sm\:-ml-3px {
    margin-left: -3px;
  }

  .sm\:-ml-menu {
    margin-left: -275px;
  }

  .sm\:-ml-px {
    margin-left: 1px !important;
  }

  .sm\:box-border {
    box-sizing: border-box;
  }

  .sm\:box-content {
    box-sizing: content-box;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:inline-flex {
    display: inline-flex;
  }

  .sm\:table {
    display: table;
  }

  .sm\:inline-table {
    display: inline-table;
  }

  .sm\:table-caption {
    display: table-caption;
  }

  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:table-column {
    display: table-column;
  }

  .sm\:table-column-group {
    display: table-column-group;
  }

  .sm\:table-footer-group {
    display: table-footer-group;
  }

  .sm\:table-header-group {
    display: table-header-group;
  }

  .sm\:table-row-group {
    display: table-row-group;
  }

  .sm\:table-row {
    display: table-row;
  }

  .sm\:flow-root {
    display: flow-root;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:inline-grid {
    display: inline-grid;
  }

  .sm\:contents {
    display: contents;
  }

  .sm\:list-item {
    display: list-item;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-0 {
    height: 0;
  }

  .sm\:h-1 {
    height: 0.25rem;
  }

  .sm\:h-2 {
    height: 0.5rem;
  }

  .sm\:h-3 {
    height: 0.75rem;
  }

  .sm\:h-4 {
    height: 1rem;
  }

  .sm\:h-5 {
    height: 1.25rem;
  }

  .sm\:h-6 {
    height: 1.5rem;
  }

  .sm\:h-8 {
    height: 2rem;
  }

  .sm\:h-10 {
    height: 2.5rem;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:h-16 {
    height: 4rem;
  }

  .sm\:h-20 {
    height: 5rem;
  }

  .sm\:h-24 {
    height: 6rem;
  }

  .sm\:h-32 {
    height: 8rem;
  }

  .sm\:h-40 {
    height: 10rem;
  }

  .sm\:h-48 {
    height: 12rem;
  }

  .sm\:h-56 {
    height: 14rem;
  }

  .sm\:h-64 {
    height: 16rem;
  }

  .sm\:h-auto {
    height: auto;
  }

  .sm\:h-300px {
    height: 300px;
  }

  .sm\:h-230px {
    height: 230px;
  }

  .sm\:h-180px {
    height: 180px;
  }

  .sm\:h-147px {
    height: 147px;
  }

  .sm\:h-123px {
    height: 123px;
  }

  .sm\:h-100px {
    height: 100px;
  }

  .sm\:h-80px {
    height: 80px;
  }

  .sm\:h-60px {
    height: 60px;
  }

  .sm\:h-50px {
    height: 50px;
  }

  .sm\:h-40px {
    height: 40px;
  }

  .sm\:h-35px {
    height: 35px;
  }

  .sm\:h-34px-i {
    height: 34px !important;
  }

  .sm\:h-30px-i {
    height: 30px !important;
  }

  .sm\:h-24px-i {
    height: 24px !important;
  }

  .sm\:h-20px-i {
    height: 20px !important;
  }

  .sm\:h-15px-i {
    height: 15px !important;
  }

  .sm\:h-13px-i {
    height: 13px !important;
  }

  .sm\:h-12px-i {
    height: 12px !important;
  }

  .sm\:h-10px-i {
    height: 10px !important;
  }

  .sm\:h-8px-i {
    height: 8px !important;
  }

  .sm\:h-6px-i {
    height: 6px !important;
  }

  .sm\:h-5px-i {
    height: 5px !important;
  }

  .sm\:h-3px-i {
    height: 3px !important;
  }

  .sm\:h-0px-i {
    height: 0px !important;
  }

  .sm\:h-34px {
    height: 34px;
  }

  .sm\:h-30px {
    height: 30px;
  }

  .sm\:h-24px {
    height: 24px;
  }

  .sm\:h-20px {
    height: 20px;
  }

  .sm\:h-15px {
    height: 15px;
  }

  .sm\:h-13px {
    height: 13px;
  }

  .sm\:h-12px {
    height: 12px;
  }

  .sm\:h-10px {
    height: 10px;
  }

  .sm\:h-8px {
    height: 8px;
  }

  .sm\:h-6px {
    height: 6px;
  }

  .sm\:h-5px {
    height: 5px;
  }

  .sm\:h-3px {
    height: 3px;
  }

  .sm\:h-menu {
    height: 275px;
  }

  .sm\:h-px {
    height: 1px !important;
  }

  .sm\:h-full {
    height: 100%;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:max-h-full {
    max-height: 100%;
  }

  .sm\:max-h-screen {
    max-height: 100vh;
  }

  .sm\:min-h-0 {
    min-height: 0;
  }

  .sm\:min-h-full {
    min-height: 100%;
  }

  .sm\:min-h-screen {
    min-height: 100vh;
  }

  .sm\:w-0 {
    width: 0;
  }

  .sm\:w-1 {
    width: 0.25rem;
  }

  .sm\:w-2 {
    width: 0.5rem;
  }

  .sm\:w-3 {
    width: 0.75rem;
  }

  .sm\:w-4 {
    width: 1rem;
  }

  .sm\:w-5 {
    width: 1.25rem;
  }

  .sm\:w-6 {
    width: 1.5rem;
  }

  .sm\:w-8 {
    width: 2rem;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:w-12 {
    width: 3rem;
  }

  .sm\:w-16 {
    width: 4rem;
  }

  .sm\:w-20 {
    width: 5rem;
  }

  .sm\:w-24 {
    width: 6rem;
  }

  .sm\:w-32 {
    width: 8rem;
  }

  .sm\:w-40 {
    width: 10rem;
  }

  .sm\:w-48 {
    width: 12rem;
  }

  .sm\:w-56 {
    width: 14rem;
  }

  .sm\:w-64 {
    width: 16rem;
  }

  .sm\:w-auto {
    width: auto !important;
  }

  .sm\:w-300px {
    width: 300px;
  }

  .sm\:w-230px {
    width: 230px;
  }

  .sm\:w-180px {
    width: 180px;
  }

  .sm\:w-147px {
    width: 147px;
  }

  .sm\:w-123px {
    width: 123px;
  }

  .sm\:w-100px {
    width: 100px;
  }

  .sm\:w-80px {
    width: 80px;
  }

  .sm\:w-60px {
    width: 60px;
  }

  .sm\:w-50px {
    width: 50px;
  }

  .sm\:w-40px {
    width: 40px;
  }

  .sm\:w-35px {
    width: 35px;
  }

  .sm\:w-34px-i {
    width: 34px !important;
  }

  .sm\:w-30px-i {
    width: 30px !important;
  }

  .sm\:w-24px-i {
    width: 24px !important;
  }

  .sm\:w-20px-i {
    width: 20px !important;
  }

  .sm\:w-15px-i {
    width: 15px !important;
  }

  .sm\:w-13px-i {
    width: 13px !important;
  }

  .sm\:w-12px-i {
    width: 12px !important;
  }

  .sm\:w-10px-i {
    width: 10px !important;
  }

  .sm\:w-8px-i {
    width: 8px !important;
  }

  .sm\:w-6px-i {
    width: 6px !important;
  }

  .sm\:w-5px-i {
    width: 5px !important;
  }

  .sm\:w-3px-i {
    width: 3px !important;
  }

  .sm\:w-0px-i {
    width: 0px !important;
  }

  .sm\:w-34px {
    width: 34px;
  }

  .sm\:w-30px {
    width: 30px;
  }

  .sm\:w-24px {
    width: 24px;
  }

  .sm\:w-20px {
    width: 20px;
  }

  .sm\:w-15px {
    width: 15px;
  }

  .sm\:w-13px {
    width: 13px;
  }

  .sm\:w-12px {
    width: 12px;
  }

  .sm\:w-10px {
    width: 10px;
  }

  .sm\:w-8px {
    width: 8px;
  }

  .sm\:w-6px {
    width: 6px;
  }

  .sm\:w-5px {
    width: 5px;
  }

  .sm\:w-3px {
    width: 3px;
  }

  .sm\:w-menu {
    width: 275px;
  }

  .sm\:w-px {
    width: 1px !important;
  }

  .sm\:w-1\/2 {
    width: 50% !important;
  }

  .sm\:w-1\/3 {
    width: 33.333333% !important;
  }

  .sm\:w-2\/3 {
    width: 66.666667% !important;
  }

  .sm\:w-1\/4 {
    width: 25% !important;
  }

  .sm\:w-2\/4 {
    width: 50% !important;
  }

  .sm\:w-3\/4 {
    width: 75% !important;
  }

  .sm\:w-1\/5 {
    width: 20% !important;
  }

  .sm\:w-2\/5 {
    width: 40% !important;
  }

  .sm\:w-3\/5 {
    width: 60% !important;
  }

  .sm\:w-4\/5 {
    width: 80% !important;
  }

  .sm\:w-1\/6 {
    width: 16.666667% !important;
  }

  .sm\:w-2\/6 {
    width: 33.333333% !important;
  }

  .sm\:w-3\/6 {
    width: 50% !important;
  }

  .sm\:w-4\/6 {
    width: 66.666667% !important;
  }

  .sm\:w-5\/6 {
    width: 83.333333% !important;
  }

  .sm\:w-1\/12 {
    width: 8.333333% !important;
  }

  .sm\:w-2\/12 {
    width: 16.666667% !important;
  }

  .sm\:w-3\/12 {
    width: 25% !important;
  }

  .sm\:w-4\/12 {
    width: 33.333333% !important;
  }

  .sm\:w-5\/12 {
    width: 41.666667% !important;
  }

  .sm\:w-6\/12 {
    width: 50% !important;
  }

  .sm\:w-7\/12 {
    width: 58.333333% !important;
  }

  .sm\:w-8\/12 {
    width: 66.666667% !important;
  }

  .sm\:w-9\/12 {
    width: 75% !important;
  }

  .sm\:w-10\/12 {
    width: 83.333333% !important;
  }

  .sm\:w-11\/12 {
    width: 91.666667% !important;
  }

  .sm\:w-full {
    width: 100% !important;
  }

  .sm\:w-screen {
    width: 100vw !important;
  }

  .sm\:min-w-0 {
    min-width: 0;
  }

  .sm\:min-w-full {
    min-width: 100%;
  }

  .sm\:max-w-1420px {
    max-width: 1420px;
  }

  .sm\:max-w-1004px {
    max-width: 1004px;
  }

  .sm\:max-w-920px {
    max-width: 920px;
  }

  .sm\:max-w-645px {
    max-width: 645px;
  }

  .sm\:max-w-menu {
    max-width: 275px;
  }

  .sm\:max-w-780px {
    max-width: 780px !important;
  }

  .sm\:max-w-240px {
    max-width: 240px !important;
  }

  .sm\:max-w-100px {
    max-width: 100px !important;
  }

  .sm\:max-w-285px {
    max-width: 285px !important;
  }

  .sm\:max-w-100pct {
    max-width: 100% !important;
  }

  .sm\:max-w-none {
    max-width: none !important;
  }

  .sm\:max-w-xs {
    max-width: 20rem;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-xl {
    max-width: 36rem;
  }

  .sm\:max-w-2xl {
    max-width: 42rem;
  }

  .sm\:max-w-3xl {
    max-width: 48rem;
  }

  .sm\:max-w-4xl {
    max-width: 56rem;
  }

  .sm\:max-w-5xl {
    max-width: 64rem;
  }

  .sm\:max-w-6xl {
    max-width: 72rem;
  }

  .sm\:max-w-full {
    max-width: 100% !important;
  }

  .sm\:max-w-screen-xs {
    max-width: 1px;
  }

  .sm\:max-w-screen-sm {
    max-width: 768px;
  }

  .sm\:max-w-screen-md {
    max-width: 1024px;
  }

  .sm\:max-w-screen-lg {
    max-width: 1440px;
  }

  .sm\:max-w-screen-xl {
    max-width: 1920px;
  }

  .sm\:flex-1 {
    flex: 1 1 0%;
  }

  .sm\:flex-auto {
    flex: 1 1 auto;
  }

  .sm\:flex-initial {
    flex: 0 1 auto;
  }

  .sm\:flex-none {
    flex: none;
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .sm\:flex-shrink {
    flex-shrink: 1;
  }

  .sm\:flex-grow-0 {
    flex-grow: 0;
  }

  .sm\:flex-grow {
    flex-grow: 1;
  }

  .sm\:table-auto {
    table-layout: auto;
  }

  .sm\:table-fixed {
    table-layout: fixed;
  }

  .sm\:border-collapse {
    border-collapse: collapse;
  }

  .sm\:border-separate {
    border-collapse: separate;
  }

  .sm\:origin-center {
    transform-origin: center;
  }

  .sm\:origin-top {
    transform-origin: top;
  }

  .sm\:origin-top-right {
    transform-origin: top right;
  }

  .sm\:origin-right {
    transform-origin: right;
  }

  .sm\:origin-bottom-right {
    transform-origin: bottom right;
  }

  .sm\:origin-bottom {
    transform-origin: bottom;
  }

  .sm\:origin-bottom-left {
    transform-origin: bottom left;
  }

  .sm\:origin-left {
    transform-origin: left;
  }

  .sm\:origin-top-left {
    transform-origin: top left;
  }

  .sm\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:transform-none {
    transform: none;
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0;
  }

  .sm\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }

  .sm\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }

  .sm\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }

  .sm\:translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .sm\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .sm\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .sm\:translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .sm\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .sm\:translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .sm\:translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .sm\:translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .sm\:translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .sm\:translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .sm\:translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .sm\:translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .sm\:translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .sm\:translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .sm\:translate-x-300px {
    --tw-translate-x: 300px;
  }

  .sm\:translate-x-230px {
    --tw-translate-x: 230px;
  }

  .sm\:translate-x-180px {
    --tw-translate-x: 180px;
  }

  .sm\:translate-x-147px {
    --tw-translate-x: 147px;
  }

  .sm\:translate-x-123px {
    --tw-translate-x: 123px;
  }

  .sm\:translate-x-100px {
    --tw-translate-x: 100px;
  }

  .sm\:translate-x-80px {
    --tw-translate-x: 80px;
  }

  .sm\:translate-x-60px {
    --tw-translate-x: 60px;
  }

  .sm\:translate-x-50px {
    --tw-translate-x: 50px;
  }

  .sm\:translate-x-40px {
    --tw-translate-x: 40px;
  }

  .sm\:translate-x-35px {
    --tw-translate-x: 35px;
  }

  .sm\:translate-x-34px-i {
    --tw-translate-x: 34px !important;
  }

  .sm\:translate-x-30px-i {
    --tw-translate-x: 30px !important;
  }

  .sm\:translate-x-24px-i {
    --tw-translate-x: 24px !important;
  }

  .sm\:translate-x-20px-i {
    --tw-translate-x: 20px !important;
  }

  .sm\:translate-x-15px-i {
    --tw-translate-x: 15px !important;
  }

  .sm\:translate-x-13px-i {
    --tw-translate-x: 13px !important;
  }

  .sm\:translate-x-12px-i {
    --tw-translate-x: 12px !important;
  }

  .sm\:translate-x-10px-i {
    --tw-translate-x: 10px !important;
  }

  .sm\:translate-x-8px-i {
    --tw-translate-x: 8px !important;
  }

  .sm\:translate-x-6px-i {
    --tw-translate-x: 6px !important;
  }

  .sm\:translate-x-5px-i {
    --tw-translate-x: 5px !important;
  }

  .sm\:translate-x-3px-i {
    --tw-translate-x: 3px !important;
  }

  .sm\:translate-x-0px-i {
    --tw-translate-x: 0px !important;
  }

  .sm\:translate-x-34px {
    --tw-translate-x: 34px;
  }

  .sm\:translate-x-30px {
    --tw-translate-x: 30px;
  }

  .sm\:translate-x-24px {
    --tw-translate-x: 24px;
  }

  .sm\:translate-x-20px {
    --tw-translate-x: 20px;
  }

  .sm\:translate-x-15px {
    --tw-translate-x: 15px;
  }

  .sm\:translate-x-13px {
    --tw-translate-x: 13px;
  }

  .sm\:translate-x-12px {
    --tw-translate-x: 12px;
  }

  .sm\:translate-x-10px {
    --tw-translate-x: 10px;
  }

  .sm\:translate-x-8px {
    --tw-translate-x: 8px;
  }

  .sm\:translate-x-6px {
    --tw-translate-x: 6px;
  }

  .sm\:translate-x-5px {
    --tw-translate-x: 5px;
  }

  .sm\:translate-x-3px {
    --tw-translate-x: 3px;
  }

  .sm\:translate-x-menu {
    --tw-translate-x: 275px;
  }

  .sm\:translate-x-px {
    --tw-translate-x: 1px !important;
  }

  .sm\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }

  .sm\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }

  .sm\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }

  .sm\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .sm\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .sm\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .sm\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .sm\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .sm\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .sm\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .sm\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .sm\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .sm\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .sm\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .sm\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .sm\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .sm\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .sm\:-translate-x-300px {
    --tw-translate-x: -300px;
  }

  .sm\:-translate-x-230px {
    --tw-translate-x: -230px;
  }

  .sm\:-translate-x-180px {
    --tw-translate-x: -180px;
  }

  .sm\:-translate-x-147px {
    --tw-translate-x: -147px;
  }

  .sm\:-translate-x-123px {
    --tw-translate-x: -123px;
  }

  .sm\:-translate-x-100px {
    --tw-translate-x: -100px;
  }

  .sm\:-translate-x-80px {
    --tw-translate-x: -80px;
  }

  .sm\:-translate-x-60px {
    --tw-translate-x: -60px;
  }

  .sm\:-translate-x-50px {
    --tw-translate-x: -50px;
  }

  .sm\:-translate-x-40px {
    --tw-translate-x: -40px;
  }

  .sm\:-translate-x-35px {
    --tw-translate-x: -35px;
  }

  .sm\:-translate-x-34px-i {
    --tw-translate-x: 34px !important;
  }

  .sm\:-translate-x-30px-i {
    --tw-translate-x: 30px !important;
  }

  .sm\:-translate-x-24px-i {
    --tw-translate-x: 24px !important;
  }

  .sm\:-translate-x-20px-i {
    --tw-translate-x: 20px !important;
  }

  .sm\:-translate-x-15px-i {
    --tw-translate-x: 15px !important;
  }

  .sm\:-translate-x-13px-i {
    --tw-translate-x: 13px !important;
  }

  .sm\:-translate-x-12px-i {
    --tw-translate-x: 12px !important;
  }

  .sm\:-translate-x-10px-i {
    --tw-translate-x: 10px !important;
  }

  .sm\:-translate-x-8px-i {
    --tw-translate-x: 8px !important;
  }

  .sm\:-translate-x-6px-i {
    --tw-translate-x: 6px !important;
  }

  .sm\:-translate-x-5px-i {
    --tw-translate-x: 5px !important;
  }

  .sm\:-translate-x-3px-i {
    --tw-translate-x: 3px !important;
  }

  .sm\:-translate-x-0px-i {
    --tw-translate-x: 0px !important;
  }

  .sm\:-translate-x-34px {
    --tw-translate-x: -34px;
  }

  .sm\:-translate-x-30px {
    --tw-translate-x: -30px;
  }

  .sm\:-translate-x-24px {
    --tw-translate-x: -24px;
  }

  .sm\:-translate-x-20px {
    --tw-translate-x: -20px;
  }

  .sm\:-translate-x-15px {
    --tw-translate-x: -15px;
  }

  .sm\:-translate-x-13px {
    --tw-translate-x: -13px;
  }

  .sm\:-translate-x-12px {
    --tw-translate-x: -12px;
  }

  .sm\:-translate-x-10px {
    --tw-translate-x: -10px;
  }

  .sm\:-translate-x-8px {
    --tw-translate-x: -8px;
  }

  .sm\:-translate-x-6px {
    --tw-translate-x: -6px;
  }

  .sm\:-translate-x-5px {
    --tw-translate-x: -5px;
  }

  .sm\:-translate-x-3px {
    --tw-translate-x: -3px;
  }

  .sm\:-translate-x-menu {
    --tw-translate-x: -275px;
  }

  .sm\:-translate-x-px {
    --tw-translate-x: 1px !important;
  }

  .sm\:-translate-x-full {
    --tw-translate-x: -100%;
  }

  .sm\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .sm\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .sm\:translate-x-full {
    --tw-translate-x: 100%;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0;
  }

  .sm\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }

  .sm\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }

  .sm\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }

  .sm\:translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .sm\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .sm\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .sm\:translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .sm\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .sm\:translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .sm\:translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .sm\:translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .sm\:translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .sm\:translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .sm\:translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .sm\:translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .sm\:translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .sm\:translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .sm\:translate-y-300px {
    --tw-translate-y: 300px;
  }

  .sm\:translate-y-230px {
    --tw-translate-y: 230px;
  }

  .sm\:translate-y-180px {
    --tw-translate-y: 180px;
  }

  .sm\:translate-y-147px {
    --tw-translate-y: 147px;
  }

  .sm\:translate-y-123px {
    --tw-translate-y: 123px;
  }

  .sm\:translate-y-100px {
    --tw-translate-y: 100px;
  }

  .sm\:translate-y-80px {
    --tw-translate-y: 80px;
  }

  .sm\:translate-y-60px {
    --tw-translate-y: 60px;
  }

  .sm\:translate-y-50px {
    --tw-translate-y: 50px;
  }

  .sm\:translate-y-40px {
    --tw-translate-y: 40px;
  }

  .sm\:translate-y-35px {
    --tw-translate-y: 35px;
  }

  .sm\:translate-y-34px-i {
    --tw-translate-y: 34px !important;
  }

  .sm\:translate-y-30px-i {
    --tw-translate-y: 30px !important;
  }

  .sm\:translate-y-24px-i {
    --tw-translate-y: 24px !important;
  }

  .sm\:translate-y-20px-i {
    --tw-translate-y: 20px !important;
  }

  .sm\:translate-y-15px-i {
    --tw-translate-y: 15px !important;
  }

  .sm\:translate-y-13px-i {
    --tw-translate-y: 13px !important;
  }

  .sm\:translate-y-12px-i {
    --tw-translate-y: 12px !important;
  }

  .sm\:translate-y-10px-i {
    --tw-translate-y: 10px !important;
  }

  .sm\:translate-y-8px-i {
    --tw-translate-y: 8px !important;
  }

  .sm\:translate-y-6px-i {
    --tw-translate-y: 6px !important;
  }

  .sm\:translate-y-5px-i {
    --tw-translate-y: 5px !important;
  }

  .sm\:translate-y-3px-i {
    --tw-translate-y: 3px !important;
  }

  .sm\:translate-y-0px-i {
    --tw-translate-y: 0px !important;
  }

  .sm\:translate-y-34px {
    --tw-translate-y: 34px;
  }

  .sm\:translate-y-30px {
    --tw-translate-y: 30px;
  }

  .sm\:translate-y-24px {
    --tw-translate-y: 24px;
  }

  .sm\:translate-y-20px {
    --tw-translate-y: 20px;
  }

  .sm\:translate-y-15px {
    --tw-translate-y: 15px;
  }

  .sm\:translate-y-13px {
    --tw-translate-y: 13px;
  }

  .sm\:translate-y-12px {
    --tw-translate-y: 12px;
  }

  .sm\:translate-y-10px {
    --tw-translate-y: 10px;
  }

  .sm\:translate-y-8px {
    --tw-translate-y: 8px;
  }

  .sm\:translate-y-6px {
    --tw-translate-y: 6px;
  }

  .sm\:translate-y-5px {
    --tw-translate-y: 5px;
  }

  .sm\:translate-y-3px {
    --tw-translate-y: 3px;
  }

  .sm\:translate-y-menu {
    --tw-translate-y: 275px;
  }

  .sm\:translate-y-px {
    --tw-translate-y: 1px !important;
  }

  .sm\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }

  .sm\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }

  .sm\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }

  .sm\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .sm\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .sm\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .sm\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .sm\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .sm\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .sm\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .sm\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .sm\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .sm\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .sm\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .sm\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .sm\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .sm\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .sm\:-translate-y-300px {
    --tw-translate-y: -300px;
  }

  .sm\:-translate-y-230px {
    --tw-translate-y: -230px;
  }

  .sm\:-translate-y-180px {
    --tw-translate-y: -180px;
  }

  .sm\:-translate-y-147px {
    --tw-translate-y: -147px;
  }

  .sm\:-translate-y-123px {
    --tw-translate-y: -123px;
  }

  .sm\:-translate-y-100px {
    --tw-translate-y: -100px;
  }

  .sm\:-translate-y-80px {
    --tw-translate-y: -80px;
  }

  .sm\:-translate-y-60px {
    --tw-translate-y: -60px;
  }

  .sm\:-translate-y-50px {
    --tw-translate-y: -50px;
  }

  .sm\:-translate-y-40px {
    --tw-translate-y: -40px;
  }

  .sm\:-translate-y-35px {
    --tw-translate-y: -35px;
  }

  .sm\:-translate-y-34px-i {
    --tw-translate-y: 34px !important;
  }

  .sm\:-translate-y-30px-i {
    --tw-translate-y: 30px !important;
  }

  .sm\:-translate-y-24px-i {
    --tw-translate-y: 24px !important;
  }

  .sm\:-translate-y-20px-i {
    --tw-translate-y: 20px !important;
  }

  .sm\:-translate-y-15px-i {
    --tw-translate-y: 15px !important;
  }

  .sm\:-translate-y-13px-i {
    --tw-translate-y: 13px !important;
  }

  .sm\:-translate-y-12px-i {
    --tw-translate-y: 12px !important;
  }

  .sm\:-translate-y-10px-i {
    --tw-translate-y: 10px !important;
  }

  .sm\:-translate-y-8px-i {
    --tw-translate-y: 8px !important;
  }

  .sm\:-translate-y-6px-i {
    --tw-translate-y: 6px !important;
  }

  .sm\:-translate-y-5px-i {
    --tw-translate-y: 5px !important;
  }

  .sm\:-translate-y-3px-i {
    --tw-translate-y: 3px !important;
  }

  .sm\:-translate-y-0px-i {
    --tw-translate-y: 0px !important;
  }

  .sm\:-translate-y-34px {
    --tw-translate-y: -34px;
  }

  .sm\:-translate-y-30px {
    --tw-translate-y: -30px;
  }

  .sm\:-translate-y-24px {
    --tw-translate-y: -24px;
  }

  .sm\:-translate-y-20px {
    --tw-translate-y: -20px;
  }

  .sm\:-translate-y-15px {
    --tw-translate-y: -15px;
  }

  .sm\:-translate-y-13px {
    --tw-translate-y: -13px;
  }

  .sm\:-translate-y-12px {
    --tw-translate-y: -12px;
  }

  .sm\:-translate-y-10px {
    --tw-translate-y: -10px;
  }

  .sm\:-translate-y-8px {
    --tw-translate-y: -8px;
  }

  .sm\:-translate-y-6px {
    --tw-translate-y: -6px;
  }

  .sm\:-translate-y-5px {
    --tw-translate-y: -5px;
  }

  .sm\:-translate-y-3px {
    --tw-translate-y: -3px;
  }

  .sm\:-translate-y-menu {
    --tw-translate-y: -275px;
  }

  .sm\:-translate-y-px {
    --tw-translate-y: 1px !important;
  }

  .sm\:-translate-y-full {
    --tw-translate-y: -100%;
  }

  .sm\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .sm\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .sm\:translate-y-full {
    --tw-translate-y: 100%;
  }

  .sm\:hover\:translate-x-0:hover {
    --tw-translate-x: 0;
  }

  .sm\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }

  .sm\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }

  .sm\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }

  .sm\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .sm\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .sm\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .sm\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .sm\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .sm\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .sm\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .sm\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .sm\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .sm\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .sm\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .sm\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .sm\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .sm\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .sm\:hover\:translate-x-300px:hover {
    --tw-translate-x: 300px;
  }

  .sm\:hover\:translate-x-230px:hover {
    --tw-translate-x: 230px;
  }

  .sm\:hover\:translate-x-180px:hover {
    --tw-translate-x: 180px;
  }

  .sm\:hover\:translate-x-147px:hover {
    --tw-translate-x: 147px;
  }

  .sm\:hover\:translate-x-123px:hover {
    --tw-translate-x: 123px;
  }

  .sm\:hover\:translate-x-100px:hover {
    --tw-translate-x: 100px;
  }

  .sm\:hover\:translate-x-80px:hover {
    --tw-translate-x: 80px;
  }

  .sm\:hover\:translate-x-60px:hover {
    --tw-translate-x: 60px;
  }

  .sm\:hover\:translate-x-50px:hover {
    --tw-translate-x: 50px;
  }

  .sm\:hover\:translate-x-40px:hover {
    --tw-translate-x: 40px;
  }

  .sm\:hover\:translate-x-35px:hover {
    --tw-translate-x: 35px;
  }

  .sm\:hover\:translate-x-34px-i:hover {
    --tw-translate-x: 34px !important;
  }

  .sm\:hover\:translate-x-30px-i:hover {
    --tw-translate-x: 30px !important;
  }

  .sm\:hover\:translate-x-24px-i:hover {
    --tw-translate-x: 24px !important;
  }

  .sm\:hover\:translate-x-20px-i:hover {
    --tw-translate-x: 20px !important;
  }

  .sm\:hover\:translate-x-15px-i:hover {
    --tw-translate-x: 15px !important;
  }

  .sm\:hover\:translate-x-13px-i:hover {
    --tw-translate-x: 13px !important;
  }

  .sm\:hover\:translate-x-12px-i:hover {
    --tw-translate-x: 12px !important;
  }

  .sm\:hover\:translate-x-10px-i:hover {
    --tw-translate-x: 10px !important;
  }

  .sm\:hover\:translate-x-8px-i:hover {
    --tw-translate-x: 8px !important;
  }

  .sm\:hover\:translate-x-6px-i:hover {
    --tw-translate-x: 6px !important;
  }

  .sm\:hover\:translate-x-5px-i:hover {
    --tw-translate-x: 5px !important;
  }

  .sm\:hover\:translate-x-3px-i:hover {
    --tw-translate-x: 3px !important;
  }

  .sm\:hover\:translate-x-0px-i:hover {
    --tw-translate-x: 0px !important;
  }

  .sm\:hover\:translate-x-34px:hover {
    --tw-translate-x: 34px;
  }

  .sm\:hover\:translate-x-30px:hover {
    --tw-translate-x: 30px;
  }

  .sm\:hover\:translate-x-24px:hover {
    --tw-translate-x: 24px;
  }

  .sm\:hover\:translate-x-20px:hover {
    --tw-translate-x: 20px;
  }

  .sm\:hover\:translate-x-15px:hover {
    --tw-translate-x: 15px;
  }

  .sm\:hover\:translate-x-13px:hover {
    --tw-translate-x: 13px;
  }

  .sm\:hover\:translate-x-12px:hover {
    --tw-translate-x: 12px;
  }

  .sm\:hover\:translate-x-10px:hover {
    --tw-translate-x: 10px;
  }

  .sm\:hover\:translate-x-8px:hover {
    --tw-translate-x: 8px;
  }

  .sm\:hover\:translate-x-6px:hover {
    --tw-translate-x: 6px;
  }

  .sm\:hover\:translate-x-5px:hover {
    --tw-translate-x: 5px;
  }

  .sm\:hover\:translate-x-3px:hover {
    --tw-translate-x: 3px;
  }

  .sm\:hover\:translate-x-menu:hover {
    --tw-translate-x: 275px;
  }

  .sm\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px !important;
  }

  .sm\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }

  .sm\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }

  .sm\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }

  .sm\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .sm\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .sm\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .sm\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .sm\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .sm\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .sm\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .sm\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .sm\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .sm\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .sm\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .sm\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .sm\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .sm\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .sm\:hover\:-translate-x-300px:hover {
    --tw-translate-x: -300px;
  }

  .sm\:hover\:-translate-x-230px:hover {
    --tw-translate-x: -230px;
  }

  .sm\:hover\:-translate-x-180px:hover {
    --tw-translate-x: -180px;
  }

  .sm\:hover\:-translate-x-147px:hover {
    --tw-translate-x: -147px;
  }

  .sm\:hover\:-translate-x-123px:hover {
    --tw-translate-x: -123px;
  }

  .sm\:hover\:-translate-x-100px:hover {
    --tw-translate-x: -100px;
  }

  .sm\:hover\:-translate-x-80px:hover {
    --tw-translate-x: -80px;
  }

  .sm\:hover\:-translate-x-60px:hover {
    --tw-translate-x: -60px;
  }

  .sm\:hover\:-translate-x-50px:hover {
    --tw-translate-x: -50px;
  }

  .sm\:hover\:-translate-x-40px:hover {
    --tw-translate-x: -40px;
  }

  .sm\:hover\:-translate-x-35px:hover {
    --tw-translate-x: -35px;
  }

  .sm\:hover\:-translate-x-34px-i:hover {
    --tw-translate-x: 34px !important;
  }

  .sm\:hover\:-translate-x-30px-i:hover {
    --tw-translate-x: 30px !important;
  }

  .sm\:hover\:-translate-x-24px-i:hover {
    --tw-translate-x: 24px !important;
  }

  .sm\:hover\:-translate-x-20px-i:hover {
    --tw-translate-x: 20px !important;
  }

  .sm\:hover\:-translate-x-15px-i:hover {
    --tw-translate-x: 15px !important;
  }

  .sm\:hover\:-translate-x-13px-i:hover {
    --tw-translate-x: 13px !important;
  }

  .sm\:hover\:-translate-x-12px-i:hover {
    --tw-translate-x: 12px !important;
  }

  .sm\:hover\:-translate-x-10px-i:hover {
    --tw-translate-x: 10px !important;
  }

  .sm\:hover\:-translate-x-8px-i:hover {
    --tw-translate-x: 8px !important;
  }

  .sm\:hover\:-translate-x-6px-i:hover {
    --tw-translate-x: 6px !important;
  }

  .sm\:hover\:-translate-x-5px-i:hover {
    --tw-translate-x: 5px !important;
  }

  .sm\:hover\:-translate-x-3px-i:hover {
    --tw-translate-x: 3px !important;
  }

  .sm\:hover\:-translate-x-0px-i:hover {
    --tw-translate-x: 0px !important;
  }

  .sm\:hover\:-translate-x-34px:hover {
    --tw-translate-x: -34px;
  }

  .sm\:hover\:-translate-x-30px:hover {
    --tw-translate-x: -30px;
  }

  .sm\:hover\:-translate-x-24px:hover {
    --tw-translate-x: -24px;
  }

  .sm\:hover\:-translate-x-20px:hover {
    --tw-translate-x: -20px;
  }

  .sm\:hover\:-translate-x-15px:hover {
    --tw-translate-x: -15px;
  }

  .sm\:hover\:-translate-x-13px:hover {
    --tw-translate-x: -13px;
  }

  .sm\:hover\:-translate-x-12px:hover {
    --tw-translate-x: -12px;
  }

  .sm\:hover\:-translate-x-10px:hover {
    --tw-translate-x: -10px;
  }

  .sm\:hover\:-translate-x-8px:hover {
    --tw-translate-x: -8px;
  }

  .sm\:hover\:-translate-x-6px:hover {
    --tw-translate-x: -6px;
  }

  .sm\:hover\:-translate-x-5px:hover {
    --tw-translate-x: -5px;
  }

  .sm\:hover\:-translate-x-3px:hover {
    --tw-translate-x: -3px;
  }

  .sm\:hover\:-translate-x-menu:hover {
    --tw-translate-x: -275px;
  }

  .sm\:hover\:-translate-x-px:hover {
    --tw-translate-x: 1px !important;
  }

  .sm\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .sm\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .sm\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .sm\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .sm\:hover\:translate-y-0:hover {
    --tw-translate-y: 0;
  }

  .sm\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }

  .sm\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }

  .sm\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }

  .sm\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .sm\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .sm\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .sm\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .sm\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .sm\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .sm\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .sm\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .sm\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .sm\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .sm\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .sm\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .sm\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .sm\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .sm\:hover\:translate-y-300px:hover {
    --tw-translate-y: 300px;
  }

  .sm\:hover\:translate-y-230px:hover {
    --tw-translate-y: 230px;
  }

  .sm\:hover\:translate-y-180px:hover {
    --tw-translate-y: 180px;
  }

  .sm\:hover\:translate-y-147px:hover {
    --tw-translate-y: 147px;
  }

  .sm\:hover\:translate-y-123px:hover {
    --tw-translate-y: 123px;
  }

  .sm\:hover\:translate-y-100px:hover {
    --tw-translate-y: 100px;
  }

  .sm\:hover\:translate-y-80px:hover {
    --tw-translate-y: 80px;
  }

  .sm\:hover\:translate-y-60px:hover {
    --tw-translate-y: 60px;
  }

  .sm\:hover\:translate-y-50px:hover {
    --tw-translate-y: 50px;
  }

  .sm\:hover\:translate-y-40px:hover {
    --tw-translate-y: 40px;
  }

  .sm\:hover\:translate-y-35px:hover {
    --tw-translate-y: 35px;
  }

  .sm\:hover\:translate-y-34px-i:hover {
    --tw-translate-y: 34px !important;
  }

  .sm\:hover\:translate-y-30px-i:hover {
    --tw-translate-y: 30px !important;
  }

  .sm\:hover\:translate-y-24px-i:hover {
    --tw-translate-y: 24px !important;
  }

  .sm\:hover\:translate-y-20px-i:hover {
    --tw-translate-y: 20px !important;
  }

  .sm\:hover\:translate-y-15px-i:hover {
    --tw-translate-y: 15px !important;
  }

  .sm\:hover\:translate-y-13px-i:hover {
    --tw-translate-y: 13px !important;
  }

  .sm\:hover\:translate-y-12px-i:hover {
    --tw-translate-y: 12px !important;
  }

  .sm\:hover\:translate-y-10px-i:hover {
    --tw-translate-y: 10px !important;
  }

  .sm\:hover\:translate-y-8px-i:hover {
    --tw-translate-y: 8px !important;
  }

  .sm\:hover\:translate-y-6px-i:hover {
    --tw-translate-y: 6px !important;
  }

  .sm\:hover\:translate-y-5px-i:hover {
    --tw-translate-y: 5px !important;
  }

  .sm\:hover\:translate-y-3px-i:hover {
    --tw-translate-y: 3px !important;
  }

  .sm\:hover\:translate-y-0px-i:hover {
    --tw-translate-y: 0px !important;
  }

  .sm\:hover\:translate-y-34px:hover {
    --tw-translate-y: 34px;
  }

  .sm\:hover\:translate-y-30px:hover {
    --tw-translate-y: 30px;
  }

  .sm\:hover\:translate-y-24px:hover {
    --tw-translate-y: 24px;
  }

  .sm\:hover\:translate-y-20px:hover {
    --tw-translate-y: 20px;
  }

  .sm\:hover\:translate-y-15px:hover {
    --tw-translate-y: 15px;
  }

  .sm\:hover\:translate-y-13px:hover {
    --tw-translate-y: 13px;
  }

  .sm\:hover\:translate-y-12px:hover {
    --tw-translate-y: 12px;
  }

  .sm\:hover\:translate-y-10px:hover {
    --tw-translate-y: 10px;
  }

  .sm\:hover\:translate-y-8px:hover {
    --tw-translate-y: 8px;
  }

  .sm\:hover\:translate-y-6px:hover {
    --tw-translate-y: 6px;
  }

  .sm\:hover\:translate-y-5px:hover {
    --tw-translate-y: 5px;
  }

  .sm\:hover\:translate-y-3px:hover {
    --tw-translate-y: 3px;
  }

  .sm\:hover\:translate-y-menu:hover {
    --tw-translate-y: 275px;
  }

  .sm\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px !important;
  }

  .sm\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }

  .sm\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }

  .sm\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }

  .sm\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .sm\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .sm\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .sm\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .sm\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .sm\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .sm\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .sm\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .sm\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .sm\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .sm\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .sm\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .sm\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .sm\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .sm\:hover\:-translate-y-300px:hover {
    --tw-translate-y: -300px;
  }

  .sm\:hover\:-translate-y-230px:hover {
    --tw-translate-y: -230px;
  }

  .sm\:hover\:-translate-y-180px:hover {
    --tw-translate-y: -180px;
  }

  .sm\:hover\:-translate-y-147px:hover {
    --tw-translate-y: -147px;
  }

  .sm\:hover\:-translate-y-123px:hover {
    --tw-translate-y: -123px;
  }

  .sm\:hover\:-translate-y-100px:hover {
    --tw-translate-y: -100px;
  }

  .sm\:hover\:-translate-y-80px:hover {
    --tw-translate-y: -80px;
  }

  .sm\:hover\:-translate-y-60px:hover {
    --tw-translate-y: -60px;
  }

  .sm\:hover\:-translate-y-50px:hover {
    --tw-translate-y: -50px;
  }

  .sm\:hover\:-translate-y-40px:hover {
    --tw-translate-y: -40px;
  }

  .sm\:hover\:-translate-y-35px:hover {
    --tw-translate-y: -35px;
  }

  .sm\:hover\:-translate-y-34px-i:hover {
    --tw-translate-y: 34px !important;
  }

  .sm\:hover\:-translate-y-30px-i:hover {
    --tw-translate-y: 30px !important;
  }

  .sm\:hover\:-translate-y-24px-i:hover {
    --tw-translate-y: 24px !important;
  }

  .sm\:hover\:-translate-y-20px-i:hover {
    --tw-translate-y: 20px !important;
  }

  .sm\:hover\:-translate-y-15px-i:hover {
    --tw-translate-y: 15px !important;
  }

  .sm\:hover\:-translate-y-13px-i:hover {
    --tw-translate-y: 13px !important;
  }

  .sm\:hover\:-translate-y-12px-i:hover {
    --tw-translate-y: 12px !important;
  }

  .sm\:hover\:-translate-y-10px-i:hover {
    --tw-translate-y: 10px !important;
  }

  .sm\:hover\:-translate-y-8px-i:hover {
    --tw-translate-y: 8px !important;
  }

  .sm\:hover\:-translate-y-6px-i:hover {
    --tw-translate-y: 6px !important;
  }

  .sm\:hover\:-translate-y-5px-i:hover {
    --tw-translate-y: 5px !important;
  }

  .sm\:hover\:-translate-y-3px-i:hover {
    --tw-translate-y: 3px !important;
  }

  .sm\:hover\:-translate-y-0px-i:hover {
    --tw-translate-y: 0px !important;
  }

  .sm\:hover\:-translate-y-34px:hover {
    --tw-translate-y: -34px;
  }

  .sm\:hover\:-translate-y-30px:hover {
    --tw-translate-y: -30px;
  }

  .sm\:hover\:-translate-y-24px:hover {
    --tw-translate-y: -24px;
  }

  .sm\:hover\:-translate-y-20px:hover {
    --tw-translate-y: -20px;
  }

  .sm\:hover\:-translate-y-15px:hover {
    --tw-translate-y: -15px;
  }

  .sm\:hover\:-translate-y-13px:hover {
    --tw-translate-y: -13px;
  }

  .sm\:hover\:-translate-y-12px:hover {
    --tw-translate-y: -12px;
  }

  .sm\:hover\:-translate-y-10px:hover {
    --tw-translate-y: -10px;
  }

  .sm\:hover\:-translate-y-8px:hover {
    --tw-translate-y: -8px;
  }

  .sm\:hover\:-translate-y-6px:hover {
    --tw-translate-y: -6px;
  }

  .sm\:hover\:-translate-y-5px:hover {
    --tw-translate-y: -5px;
  }

  .sm\:hover\:-translate-y-3px:hover {
    --tw-translate-y: -3px;
  }

  .sm\:hover\:-translate-y-menu:hover {
    --tw-translate-y: -275px;
  }

  .sm\:hover\:-translate-y-px:hover {
    --tw-translate-y: 1px !important;
  }

  .sm\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .sm\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .sm\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .sm\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .sm\:focus\:translate-x-0:focus {
    --tw-translate-x: 0;
  }

  .sm\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }

  .sm\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }

  .sm\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }

  .sm\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .sm\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .sm\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .sm\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .sm\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .sm\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .sm\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .sm\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .sm\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .sm\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .sm\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .sm\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .sm\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .sm\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .sm\:focus\:translate-x-300px:focus {
    --tw-translate-x: 300px;
  }

  .sm\:focus\:translate-x-230px:focus {
    --tw-translate-x: 230px;
  }

  .sm\:focus\:translate-x-180px:focus {
    --tw-translate-x: 180px;
  }

  .sm\:focus\:translate-x-147px:focus {
    --tw-translate-x: 147px;
  }

  .sm\:focus\:translate-x-123px:focus {
    --tw-translate-x: 123px;
  }

  .sm\:focus\:translate-x-100px:focus {
    --tw-translate-x: 100px;
  }

  .sm\:focus\:translate-x-80px:focus {
    --tw-translate-x: 80px;
  }

  .sm\:focus\:translate-x-60px:focus {
    --tw-translate-x: 60px;
  }

  .sm\:focus\:translate-x-50px:focus {
    --tw-translate-x: 50px;
  }

  .sm\:focus\:translate-x-40px:focus {
    --tw-translate-x: 40px;
  }

  .sm\:focus\:translate-x-35px:focus {
    --tw-translate-x: 35px;
  }

  .sm\:focus\:translate-x-34px-i:focus {
    --tw-translate-x: 34px !important;
  }

  .sm\:focus\:translate-x-30px-i:focus {
    --tw-translate-x: 30px !important;
  }

  .sm\:focus\:translate-x-24px-i:focus {
    --tw-translate-x: 24px !important;
  }

  .sm\:focus\:translate-x-20px-i:focus {
    --tw-translate-x: 20px !important;
  }

  .sm\:focus\:translate-x-15px-i:focus {
    --tw-translate-x: 15px !important;
  }

  .sm\:focus\:translate-x-13px-i:focus {
    --tw-translate-x: 13px !important;
  }

  .sm\:focus\:translate-x-12px-i:focus {
    --tw-translate-x: 12px !important;
  }

  .sm\:focus\:translate-x-10px-i:focus {
    --tw-translate-x: 10px !important;
  }

  .sm\:focus\:translate-x-8px-i:focus {
    --tw-translate-x: 8px !important;
  }

  .sm\:focus\:translate-x-6px-i:focus {
    --tw-translate-x: 6px !important;
  }

  .sm\:focus\:translate-x-5px-i:focus {
    --tw-translate-x: 5px !important;
  }

  .sm\:focus\:translate-x-3px-i:focus {
    --tw-translate-x: 3px !important;
  }

  .sm\:focus\:translate-x-0px-i:focus {
    --tw-translate-x: 0px !important;
  }

  .sm\:focus\:translate-x-34px:focus {
    --tw-translate-x: 34px;
  }

  .sm\:focus\:translate-x-30px:focus {
    --tw-translate-x: 30px;
  }

  .sm\:focus\:translate-x-24px:focus {
    --tw-translate-x: 24px;
  }

  .sm\:focus\:translate-x-20px:focus {
    --tw-translate-x: 20px;
  }

  .sm\:focus\:translate-x-15px:focus {
    --tw-translate-x: 15px;
  }

  .sm\:focus\:translate-x-13px:focus {
    --tw-translate-x: 13px;
  }

  .sm\:focus\:translate-x-12px:focus {
    --tw-translate-x: 12px;
  }

  .sm\:focus\:translate-x-10px:focus {
    --tw-translate-x: 10px;
  }

  .sm\:focus\:translate-x-8px:focus {
    --tw-translate-x: 8px;
  }

  .sm\:focus\:translate-x-6px:focus {
    --tw-translate-x: 6px;
  }

  .sm\:focus\:translate-x-5px:focus {
    --tw-translate-x: 5px;
  }

  .sm\:focus\:translate-x-3px:focus {
    --tw-translate-x: 3px;
  }

  .sm\:focus\:translate-x-menu:focus {
    --tw-translate-x: 275px;
  }

  .sm\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px !important;
  }

  .sm\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }

  .sm\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }

  .sm\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }

  .sm\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .sm\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .sm\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .sm\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .sm\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .sm\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .sm\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .sm\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .sm\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .sm\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .sm\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .sm\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .sm\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .sm\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .sm\:focus\:-translate-x-300px:focus {
    --tw-translate-x: -300px;
  }

  .sm\:focus\:-translate-x-230px:focus {
    --tw-translate-x: -230px;
  }

  .sm\:focus\:-translate-x-180px:focus {
    --tw-translate-x: -180px;
  }

  .sm\:focus\:-translate-x-147px:focus {
    --tw-translate-x: -147px;
  }

  .sm\:focus\:-translate-x-123px:focus {
    --tw-translate-x: -123px;
  }

  .sm\:focus\:-translate-x-100px:focus {
    --tw-translate-x: -100px;
  }

  .sm\:focus\:-translate-x-80px:focus {
    --tw-translate-x: -80px;
  }

  .sm\:focus\:-translate-x-60px:focus {
    --tw-translate-x: -60px;
  }

  .sm\:focus\:-translate-x-50px:focus {
    --tw-translate-x: -50px;
  }

  .sm\:focus\:-translate-x-40px:focus {
    --tw-translate-x: -40px;
  }

  .sm\:focus\:-translate-x-35px:focus {
    --tw-translate-x: -35px;
  }

  .sm\:focus\:-translate-x-34px-i:focus {
    --tw-translate-x: 34px !important;
  }

  .sm\:focus\:-translate-x-30px-i:focus {
    --tw-translate-x: 30px !important;
  }

  .sm\:focus\:-translate-x-24px-i:focus {
    --tw-translate-x: 24px !important;
  }

  .sm\:focus\:-translate-x-20px-i:focus {
    --tw-translate-x: 20px !important;
  }

  .sm\:focus\:-translate-x-15px-i:focus {
    --tw-translate-x: 15px !important;
  }

  .sm\:focus\:-translate-x-13px-i:focus {
    --tw-translate-x: 13px !important;
  }

  .sm\:focus\:-translate-x-12px-i:focus {
    --tw-translate-x: 12px !important;
  }

  .sm\:focus\:-translate-x-10px-i:focus {
    --tw-translate-x: 10px !important;
  }

  .sm\:focus\:-translate-x-8px-i:focus {
    --tw-translate-x: 8px !important;
  }

  .sm\:focus\:-translate-x-6px-i:focus {
    --tw-translate-x: 6px !important;
  }

  .sm\:focus\:-translate-x-5px-i:focus {
    --tw-translate-x: 5px !important;
  }

  .sm\:focus\:-translate-x-3px-i:focus {
    --tw-translate-x: 3px !important;
  }

  .sm\:focus\:-translate-x-0px-i:focus {
    --tw-translate-x: 0px !important;
  }

  .sm\:focus\:-translate-x-34px:focus {
    --tw-translate-x: -34px;
  }

  .sm\:focus\:-translate-x-30px:focus {
    --tw-translate-x: -30px;
  }

  .sm\:focus\:-translate-x-24px:focus {
    --tw-translate-x: -24px;
  }

  .sm\:focus\:-translate-x-20px:focus {
    --tw-translate-x: -20px;
  }

  .sm\:focus\:-translate-x-15px:focus {
    --tw-translate-x: -15px;
  }

  .sm\:focus\:-translate-x-13px:focus {
    --tw-translate-x: -13px;
  }

  .sm\:focus\:-translate-x-12px:focus {
    --tw-translate-x: -12px;
  }

  .sm\:focus\:-translate-x-10px:focus {
    --tw-translate-x: -10px;
  }

  .sm\:focus\:-translate-x-8px:focus {
    --tw-translate-x: -8px;
  }

  .sm\:focus\:-translate-x-6px:focus {
    --tw-translate-x: -6px;
  }

  .sm\:focus\:-translate-x-5px:focus {
    --tw-translate-x: -5px;
  }

  .sm\:focus\:-translate-x-3px:focus {
    --tw-translate-x: -3px;
  }

  .sm\:focus\:-translate-x-menu:focus {
    --tw-translate-x: -275px;
  }

  .sm\:focus\:-translate-x-px:focus {
    --tw-translate-x: 1px !important;
  }

  .sm\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .sm\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .sm\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .sm\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .sm\:focus\:translate-y-0:focus {
    --tw-translate-y: 0;
  }

  .sm\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }

  .sm\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }

  .sm\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }

  .sm\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .sm\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .sm\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .sm\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .sm\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .sm\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .sm\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .sm\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .sm\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .sm\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .sm\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .sm\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .sm\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .sm\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .sm\:focus\:translate-y-300px:focus {
    --tw-translate-y: 300px;
  }

  .sm\:focus\:translate-y-230px:focus {
    --tw-translate-y: 230px;
  }

  .sm\:focus\:translate-y-180px:focus {
    --tw-translate-y: 180px;
  }

  .sm\:focus\:translate-y-147px:focus {
    --tw-translate-y: 147px;
  }

  .sm\:focus\:translate-y-123px:focus {
    --tw-translate-y: 123px;
  }

  .sm\:focus\:translate-y-100px:focus {
    --tw-translate-y: 100px;
  }

  .sm\:focus\:translate-y-80px:focus {
    --tw-translate-y: 80px;
  }

  .sm\:focus\:translate-y-60px:focus {
    --tw-translate-y: 60px;
  }

  .sm\:focus\:translate-y-50px:focus {
    --tw-translate-y: 50px;
  }

  .sm\:focus\:translate-y-40px:focus {
    --tw-translate-y: 40px;
  }

  .sm\:focus\:translate-y-35px:focus {
    --tw-translate-y: 35px;
  }

  .sm\:focus\:translate-y-34px-i:focus {
    --tw-translate-y: 34px !important;
  }

  .sm\:focus\:translate-y-30px-i:focus {
    --tw-translate-y: 30px !important;
  }

  .sm\:focus\:translate-y-24px-i:focus {
    --tw-translate-y: 24px !important;
  }

  .sm\:focus\:translate-y-20px-i:focus {
    --tw-translate-y: 20px !important;
  }

  .sm\:focus\:translate-y-15px-i:focus {
    --tw-translate-y: 15px !important;
  }

  .sm\:focus\:translate-y-13px-i:focus {
    --tw-translate-y: 13px !important;
  }

  .sm\:focus\:translate-y-12px-i:focus {
    --tw-translate-y: 12px !important;
  }

  .sm\:focus\:translate-y-10px-i:focus {
    --tw-translate-y: 10px !important;
  }

  .sm\:focus\:translate-y-8px-i:focus {
    --tw-translate-y: 8px !important;
  }

  .sm\:focus\:translate-y-6px-i:focus {
    --tw-translate-y: 6px !important;
  }

  .sm\:focus\:translate-y-5px-i:focus {
    --tw-translate-y: 5px !important;
  }

  .sm\:focus\:translate-y-3px-i:focus {
    --tw-translate-y: 3px !important;
  }

  .sm\:focus\:translate-y-0px-i:focus {
    --tw-translate-y: 0px !important;
  }

  .sm\:focus\:translate-y-34px:focus {
    --tw-translate-y: 34px;
  }

  .sm\:focus\:translate-y-30px:focus {
    --tw-translate-y: 30px;
  }

  .sm\:focus\:translate-y-24px:focus {
    --tw-translate-y: 24px;
  }

  .sm\:focus\:translate-y-20px:focus {
    --tw-translate-y: 20px;
  }

  .sm\:focus\:translate-y-15px:focus {
    --tw-translate-y: 15px;
  }

  .sm\:focus\:translate-y-13px:focus {
    --tw-translate-y: 13px;
  }

  .sm\:focus\:translate-y-12px:focus {
    --tw-translate-y: 12px;
  }

  .sm\:focus\:translate-y-10px:focus {
    --tw-translate-y: 10px;
  }

  .sm\:focus\:translate-y-8px:focus {
    --tw-translate-y: 8px;
  }

  .sm\:focus\:translate-y-6px:focus {
    --tw-translate-y: 6px;
  }

  .sm\:focus\:translate-y-5px:focus {
    --tw-translate-y: 5px;
  }

  .sm\:focus\:translate-y-3px:focus {
    --tw-translate-y: 3px;
  }

  .sm\:focus\:translate-y-menu:focus {
    --tw-translate-y: 275px;
  }

  .sm\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px !important;
  }

  .sm\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }

  .sm\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }

  .sm\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }

  .sm\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .sm\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .sm\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .sm\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .sm\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .sm\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .sm\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .sm\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .sm\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .sm\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .sm\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .sm\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .sm\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .sm\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .sm\:focus\:-translate-y-300px:focus {
    --tw-translate-y: -300px;
  }

  .sm\:focus\:-translate-y-230px:focus {
    --tw-translate-y: -230px;
  }

  .sm\:focus\:-translate-y-180px:focus {
    --tw-translate-y: -180px;
  }

  .sm\:focus\:-translate-y-147px:focus {
    --tw-translate-y: -147px;
  }

  .sm\:focus\:-translate-y-123px:focus {
    --tw-translate-y: -123px;
  }

  .sm\:focus\:-translate-y-100px:focus {
    --tw-translate-y: -100px;
  }

  .sm\:focus\:-translate-y-80px:focus {
    --tw-translate-y: -80px;
  }

  .sm\:focus\:-translate-y-60px:focus {
    --tw-translate-y: -60px;
  }

  .sm\:focus\:-translate-y-50px:focus {
    --tw-translate-y: -50px;
  }

  .sm\:focus\:-translate-y-40px:focus {
    --tw-translate-y: -40px;
  }

  .sm\:focus\:-translate-y-35px:focus {
    --tw-translate-y: -35px;
  }

  .sm\:focus\:-translate-y-34px-i:focus {
    --tw-translate-y: 34px !important;
  }

  .sm\:focus\:-translate-y-30px-i:focus {
    --tw-translate-y: 30px !important;
  }

  .sm\:focus\:-translate-y-24px-i:focus {
    --tw-translate-y: 24px !important;
  }

  .sm\:focus\:-translate-y-20px-i:focus {
    --tw-translate-y: 20px !important;
  }

  .sm\:focus\:-translate-y-15px-i:focus {
    --tw-translate-y: 15px !important;
  }

  .sm\:focus\:-translate-y-13px-i:focus {
    --tw-translate-y: 13px !important;
  }

  .sm\:focus\:-translate-y-12px-i:focus {
    --tw-translate-y: 12px !important;
  }

  .sm\:focus\:-translate-y-10px-i:focus {
    --tw-translate-y: 10px !important;
  }

  .sm\:focus\:-translate-y-8px-i:focus {
    --tw-translate-y: 8px !important;
  }

  .sm\:focus\:-translate-y-6px-i:focus {
    --tw-translate-y: 6px !important;
  }

  .sm\:focus\:-translate-y-5px-i:focus {
    --tw-translate-y: 5px !important;
  }

  .sm\:focus\:-translate-y-3px-i:focus {
    --tw-translate-y: 3px !important;
  }

  .sm\:focus\:-translate-y-0px-i:focus {
    --tw-translate-y: 0px !important;
  }

  .sm\:focus\:-translate-y-34px:focus {
    --tw-translate-y: -34px;
  }

  .sm\:focus\:-translate-y-30px:focus {
    --tw-translate-y: -30px;
  }

  .sm\:focus\:-translate-y-24px:focus {
    --tw-translate-y: -24px;
  }

  .sm\:focus\:-translate-y-20px:focus {
    --tw-translate-y: -20px;
  }

  .sm\:focus\:-translate-y-15px:focus {
    --tw-translate-y: -15px;
  }

  .sm\:focus\:-translate-y-13px:focus {
    --tw-translate-y: -13px;
  }

  .sm\:focus\:-translate-y-12px:focus {
    --tw-translate-y: -12px;
  }

  .sm\:focus\:-translate-y-10px:focus {
    --tw-translate-y: -10px;
  }

  .sm\:focus\:-translate-y-8px:focus {
    --tw-translate-y: -8px;
  }

  .sm\:focus\:-translate-y-6px:focus {
    --tw-translate-y: -6px;
  }

  .sm\:focus\:-translate-y-5px:focus {
    --tw-translate-y: -5px;
  }

  .sm\:focus\:-translate-y-3px:focus {
    --tw-translate-y: -3px;
  }

  .sm\:focus\:-translate-y-menu:focus {
    --tw-translate-y: -275px;
  }

  .sm\:focus\:-translate-y-px:focus {
    --tw-translate-y: 1px !important;
  }

  .sm\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .sm\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .sm\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .sm\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .sm\:rotate-0 {
    --tw-rotate: 0;
  }

  .sm\:rotate-1 {
    --tw-rotate: 1deg;
  }

  .sm\:rotate-2 {
    --tw-rotate: 2deg;
  }

  .sm\:rotate-3 {
    --tw-rotate: 3deg;
  }

  .sm\:rotate-6 {
    --tw-rotate: 6deg;
  }

  .sm\:rotate-12 {
    --tw-rotate: 12deg;
  }

  .sm\:rotate-45 {
    --tw-rotate: 45deg;
  }

  .sm\:rotate-90 {
    --tw-rotate: 90deg;
  }

  .sm\:rotate-180 {
    --tw-rotate: 180deg;
  }

  .sm\:-rotate-180 {
    --tw-rotate: -180deg;
  }

  .sm\:-rotate-90 {
    --tw-rotate: -90deg;
  }

  .sm\:-rotate-45 {
    --tw-rotate: -45deg;
  }

  .sm\:-rotate-12 {
    --tw-rotate: -12deg;
  }

  .sm\:-rotate-6 {
    --tw-rotate: -6deg;
  }

  .sm\:-rotate-3 {
    --tw-rotate: -3deg;
  }

  .sm\:-rotate-2 {
    --tw-rotate: -2deg;
  }

  .sm\:-rotate-1 {
    --tw-rotate: -1deg;
  }

  .sm\:hover\:rotate-0:hover {
    --tw-rotate: 0;
  }

  .sm\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .sm\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .sm\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .sm\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .sm\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .sm\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .sm\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .sm\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .sm\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .sm\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .sm\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .sm\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .sm\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .sm\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .sm\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .sm\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .sm\:focus\:rotate-0:focus {
    --tw-rotate: 0;
  }

  .sm\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .sm\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .sm\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .sm\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .sm\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .sm\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .sm\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .sm\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .sm\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .sm\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .sm\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .sm\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .sm\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .sm\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .sm\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .sm\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .sm\:skew-x-0 {
    --tw-skew-x: 0;
  }

  .sm\:skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .sm\:skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .sm\:skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .sm\:skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .sm\:skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .sm\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .sm\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .sm\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .sm\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .sm\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .sm\:skew-y-0 {
    --tw-skew-y: 0;
  }

  .sm\:skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .sm\:skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .sm\:skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .sm\:skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .sm\:skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .sm\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .sm\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .sm\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .sm\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .sm\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .sm\:hover\:skew-x-0:hover {
    --tw-skew-x: 0;
  }

  .sm\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .sm\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .sm\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .sm\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .sm\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .sm\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .sm\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .sm\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .sm\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .sm\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .sm\:hover\:skew-y-0:hover {
    --tw-skew-y: 0;
  }

  .sm\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .sm\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .sm\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .sm\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .sm\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .sm\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .sm\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .sm\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .sm\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .sm\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .sm\:focus\:skew-x-0:focus {
    --tw-skew-x: 0;
  }

  .sm\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .sm\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .sm\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .sm\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .sm\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .sm\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .sm\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .sm\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .sm\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .sm\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .sm\:focus\:skew-y-0:focus {
    --tw-skew-y: 0;
  }

  .sm\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .sm\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .sm\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .sm\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .sm\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .sm\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .sm\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .sm\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .sm\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .sm\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .sm\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .sm\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .sm\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .sm\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .sm\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .sm\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .sm\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .sm\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .sm\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .sm\:scale-x-0 {
    --tw-scale-x: 0;
  }

  .sm\:scale-x-50 {
    --tw-scale-x: .5;
  }

  .sm\:scale-x-75 {
    --tw-scale-x: .75;
  }

  .sm\:scale-x-90 {
    --tw-scale-x: .9;
  }

  .sm\:scale-x-95 {
    --tw-scale-x: .95;
  }

  .sm\:scale-x-100 {
    --tw-scale-x: 1;
  }

  .sm\:scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .sm\:scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .sm\:scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .sm\:scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .sm\:scale-y-0 {
    --tw-scale-y: 0;
  }

  .sm\:scale-y-50 {
    --tw-scale-y: .5;
  }

  .sm\:scale-y-75 {
    --tw-scale-y: .75;
  }

  .sm\:scale-y-90 {
    --tw-scale-y: .9;
  }

  .sm\:scale-y-95 {
    --tw-scale-y: .95;
  }

  .sm\:scale-y-100 {
    --tw-scale-y: 1;
  }

  .sm\:scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .sm\:scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .sm\:scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .sm\:scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .sm\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .sm\:hover\:scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .sm\:hover\:scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .sm\:hover\:scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .sm\:hover\:scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .sm\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .sm\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .sm\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .sm\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .sm\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .sm\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .sm\:hover\:scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .sm\:hover\:scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .sm\:hover\:scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .sm\:hover\:scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .sm\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .sm\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .sm\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .sm\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .sm\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .sm\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .sm\:focus\:scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .sm\:focus\:scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .sm\:focus\:scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .sm\:focus\:scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .sm\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .sm\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .sm\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .sm\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .sm\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .sm\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .sm\:focus\:scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .sm\:focus\:scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .sm\:focus\:scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .sm\:focus\:scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .sm\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .sm\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .sm\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .sm\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .sm\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .sm\:animate-none {
    animation: none;
  }

  .sm\:animate-spin {
    animation: spin 1s linear infinite;
  }

  .sm\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .sm\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .sm\:animate-bounce {
    animation: bounce 1s infinite;
  }

  .sm\:cursor-auto {
    cursor: auto;
  }

  .sm\:cursor-default {
    cursor: default;
  }

  .sm\:cursor-pointer {
    cursor: pointer;
  }

  .sm\:cursor-wait {
    cursor: wait;
  }

  .sm\:cursor-text {
    cursor: text;
  }

  .sm\:cursor-move {
    cursor: move;
  }

  .sm\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .sm\:select-none {
    -webkit-user-select: none;
            user-select: none;
  }

  .sm\:select-text {
    -webkit-user-select: text;
            user-select: text;
  }

  .sm\:select-all {
    -webkit-user-select: all;
            user-select: all;
  }

  .sm\:select-auto {
    -webkit-user-select: auto;
            user-select: auto;
  }

  .sm\:resize-none {
    resize: none;
  }

  .sm\:resize-y {
    resize: vertical;
  }

  .sm\:resize-x {
    resize: horizontal;
  }

  .sm\:resize {
    resize: both;
  }

  .sm\:list-inside {
    list-style-position: inside;
  }

  .sm\:list-outside {
    list-style-position: outside;
  }

  .sm\:list-none {
    list-style-type: none;
  }

  .sm\:list-disc {
    list-style-type: disc;
  }

  .sm\:list-decimal {
    list-style-type: decimal;
  }

  .sm\:appearance-none {
    appearance: none;
  }

  .sm\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .sm\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .sm\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .sm\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .sm\:grid-flow-row {
    grid-auto-flow: row;
  }

  .sm\:grid-flow-col {
    grid-auto-flow: column;
  }

  .sm\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .sm\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .sm\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .sm\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .sm\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .sm\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:grid-cols-none {
    grid-template-columns: none;
  }

  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .sm\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .sm\:grid-rows-none {
    grid-template-rows: none;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:place-content-center {
    place-content: center;
  }

  .sm\:place-content-start {
    place-content: start;
  }

  .sm\:place-content-end {
    place-content: end;
  }

  .sm\:place-content-between {
    place-content: space-between;
  }

  .sm\:place-content-around {
    place-content: space-around;
  }

  .sm\:place-content-evenly {
    place-content: space-evenly;
  }

  .sm\:place-content-stretch {
    place-content: stretch;
  }

  .sm\:place-items-start {
    place-items: start;
  }

  .sm\:place-items-end {
    place-items: end;
  }

  .sm\:place-items-center {
    place-items: center;
  }

  .sm\:place-items-stretch {
    place-items: stretch;
  }

  .sm\:content-center {
    align-content: center;
  }

  .sm\:content-start {
    align-content: flex-start;
  }

  .sm\:content-end {
    align-content: flex-end;
  }

  .sm\:content-between {
    align-content: space-between;
  }

  .sm\:content-around {
    align-content: space-around;
  }

  .sm\:content-evenly {
    align-content: space-evenly;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:items-baseline {
    align-items: baseline;
  }

  .sm\:items-stretch {
    align-items: stretch;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:justify-around {
    justify-content: space-around;
  }

  .sm\:justify-evenly {
    justify-content: space-evenly;
  }

  .sm\:justify-items-start {
    justify-items: start;
  }

  .sm\:justify-items-end {
    justify-items: end;
  }

  .sm\:justify-items-center {
    justify-items: center;
  }

  .sm\:justify-items-stretch {
    justify-items: stretch;
  }

  .sm\:gap-0 {
    gap: 0;
  }

  .sm\:gap-1 {
    gap: 0.25rem;
  }

  .sm\:gap-2 {
    gap: 0.5rem;
  }

  .sm\:gap-3 {
    gap: 0.75rem;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:gap-5 {
    gap: 1.25rem;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:gap-10 {
    gap: 2.5rem;
  }

  .sm\:gap-12 {
    gap: 3rem;
  }

  .sm\:gap-16 {
    gap: 4rem;
  }

  .sm\:gap-20 {
    gap: 5rem;
  }

  .sm\:gap-24 {
    gap: 6rem;
  }

  .sm\:gap-32 {
    gap: 8rem;
  }

  .sm\:gap-40 {
    gap: 10rem;
  }

  .sm\:gap-48 {
    gap: 12rem;
  }

  .sm\:gap-56 {
    gap: 14rem;
  }

  .sm\:gap-64 {
    gap: 16rem;
  }

  .sm\:gap-300px {
    gap: 300px;
  }

  .sm\:gap-230px {
    gap: 230px;
  }

  .sm\:gap-180px {
    gap: 180px;
  }

  .sm\:gap-147px {
    gap: 147px;
  }

  .sm\:gap-123px {
    gap: 123px;
  }

  .sm\:gap-100px {
    gap: 100px;
  }

  .sm\:gap-80px {
    gap: 80px;
  }

  .sm\:gap-60px {
    gap: 60px;
  }

  .sm\:gap-50px {
    gap: 50px;
  }

  .sm\:gap-40px {
    gap: 40px;
  }

  .sm\:gap-35px {
    gap: 35px;
  }

  .sm\:gap-34px-i {
    gap: 34px !important;
  }

  .sm\:gap-30px-i {
    gap: 30px !important;
  }

  .sm\:gap-24px-i {
    gap: 24px !important;
  }

  .sm\:gap-20px-i {
    gap: 20px !important;
  }

  .sm\:gap-15px-i {
    gap: 15px !important;
  }

  .sm\:gap-13px-i {
    gap: 13px !important;
  }

  .sm\:gap-12px-i {
    gap: 12px !important;
  }

  .sm\:gap-10px-i {
    gap: 10px !important;
  }

  .sm\:gap-8px-i {
    gap: 8px !important;
  }

  .sm\:gap-6px-i {
    gap: 6px !important;
  }

  .sm\:gap-5px-i {
    gap: 5px !important;
  }

  .sm\:gap-3px-i {
    gap: 3px !important;
  }

  .sm\:gap-0px-i {
    gap: 0px !important;
  }

  .sm\:gap-34px {
    gap: 34px;
  }

  .sm\:gap-30px {
    gap: 30px;
  }

  .sm\:gap-24px {
    gap: 24px;
  }

  .sm\:gap-20px {
    gap: 20px;
  }

  .sm\:gap-15px {
    gap: 15px;
  }

  .sm\:gap-13px {
    gap: 13px;
  }

  .sm\:gap-12px {
    gap: 12px;
  }

  .sm\:gap-10px {
    gap: 10px;
  }

  .sm\:gap-8px {
    gap: 8px;
  }

  .sm\:gap-6px {
    gap: 6px;
  }

  .sm\:gap-5px {
    gap: 5px;
  }

  .sm\:gap-3px {
    gap: 3px;
  }

  .sm\:gap-menu {
    gap: 275px;
  }

  .sm\:gap-px {
    gap: 1px !important;
  }

  .sm\:gap-x-0 {
    column-gap: 0;
  }

  .sm\:gap-x-1 {
    column-gap: 0.25rem;
  }

  .sm\:gap-x-2 {
    column-gap: 0.5rem;
  }

  .sm\:gap-x-3 {
    column-gap: 0.75rem;
  }

  .sm\:gap-x-4 {
    column-gap: 1rem;
  }

  .sm\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .sm\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .sm\:gap-x-8 {
    column-gap: 2rem;
  }

  .sm\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .sm\:gap-x-12 {
    column-gap: 3rem;
  }

  .sm\:gap-x-16 {
    column-gap: 4rem;
  }

  .sm\:gap-x-20 {
    column-gap: 5rem;
  }

  .sm\:gap-x-24 {
    column-gap: 6rem;
  }

  .sm\:gap-x-32 {
    column-gap: 8rem;
  }

  .sm\:gap-x-40 {
    column-gap: 10rem;
  }

  .sm\:gap-x-48 {
    column-gap: 12rem;
  }

  .sm\:gap-x-56 {
    column-gap: 14rem;
  }

  .sm\:gap-x-64 {
    column-gap: 16rem;
  }

  .sm\:gap-x-300px {
    column-gap: 300px;
  }

  .sm\:gap-x-230px {
    column-gap: 230px;
  }

  .sm\:gap-x-180px {
    column-gap: 180px;
  }

  .sm\:gap-x-147px {
    column-gap: 147px;
  }

  .sm\:gap-x-123px {
    column-gap: 123px;
  }

  .sm\:gap-x-100px {
    column-gap: 100px;
  }

  .sm\:gap-x-80px {
    column-gap: 80px;
  }

  .sm\:gap-x-60px {
    column-gap: 60px;
  }

  .sm\:gap-x-50px {
    column-gap: 50px;
  }

  .sm\:gap-x-40px {
    column-gap: 40px;
  }

  .sm\:gap-x-35px {
    column-gap: 35px;
  }

  .sm\:gap-x-34px-i {
    column-gap: 34px !important;
  }

  .sm\:gap-x-30px-i {
    column-gap: 30px !important;
  }

  .sm\:gap-x-24px-i {
    column-gap: 24px !important;
  }

  .sm\:gap-x-20px-i {
    column-gap: 20px !important;
  }

  .sm\:gap-x-15px-i {
    column-gap: 15px !important;
  }

  .sm\:gap-x-13px-i {
    column-gap: 13px !important;
  }

  .sm\:gap-x-12px-i {
    column-gap: 12px !important;
  }

  .sm\:gap-x-10px-i {
    column-gap: 10px !important;
  }

  .sm\:gap-x-8px-i {
    column-gap: 8px !important;
  }

  .sm\:gap-x-6px-i {
    column-gap: 6px !important;
  }

  .sm\:gap-x-5px-i {
    column-gap: 5px !important;
  }

  .sm\:gap-x-3px-i {
    column-gap: 3px !important;
  }

  .sm\:gap-x-0px-i {
    column-gap: 0px !important;
  }

  .sm\:gap-x-34px {
    column-gap: 34px;
  }

  .sm\:gap-x-30px {
    column-gap: 30px;
  }

  .sm\:gap-x-24px {
    column-gap: 24px;
  }

  .sm\:gap-x-20px {
    column-gap: 20px;
  }

  .sm\:gap-x-15px {
    column-gap: 15px;
  }

  .sm\:gap-x-13px {
    column-gap: 13px;
  }

  .sm\:gap-x-12px {
    column-gap: 12px;
  }

  .sm\:gap-x-10px {
    column-gap: 10px;
  }

  .sm\:gap-x-8px {
    column-gap: 8px;
  }

  .sm\:gap-x-6px {
    column-gap: 6px;
  }

  .sm\:gap-x-5px {
    column-gap: 5px;
  }

  .sm\:gap-x-3px {
    column-gap: 3px;
  }

  .sm\:gap-x-menu {
    column-gap: 275px;
  }

  .sm\:gap-x-px {
    column-gap: 1px !important;
  }

  .sm\:gap-y-0 {
    row-gap: 0;
  }

  .sm\:gap-y-1 {
    row-gap: 0.25rem;
  }

  .sm\:gap-y-2 {
    row-gap: 0.5rem;
  }

  .sm\:gap-y-3 {
    row-gap: 0.75rem;
  }

  .sm\:gap-y-4 {
    row-gap: 1rem;
  }

  .sm\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .sm\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .sm\:gap-y-8 {
    row-gap: 2rem;
  }

  .sm\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .sm\:gap-y-12 {
    row-gap: 3rem;
  }

  .sm\:gap-y-16 {
    row-gap: 4rem;
  }

  .sm\:gap-y-20 {
    row-gap: 5rem;
  }

  .sm\:gap-y-24 {
    row-gap: 6rem;
  }

  .sm\:gap-y-32 {
    row-gap: 8rem;
  }

  .sm\:gap-y-40 {
    row-gap: 10rem;
  }

  .sm\:gap-y-48 {
    row-gap: 12rem;
  }

  .sm\:gap-y-56 {
    row-gap: 14rem;
  }

  .sm\:gap-y-64 {
    row-gap: 16rem;
  }

  .sm\:gap-y-300px {
    row-gap: 300px;
  }

  .sm\:gap-y-230px {
    row-gap: 230px;
  }

  .sm\:gap-y-180px {
    row-gap: 180px;
  }

  .sm\:gap-y-147px {
    row-gap: 147px;
  }

  .sm\:gap-y-123px {
    row-gap: 123px;
  }

  .sm\:gap-y-100px {
    row-gap: 100px;
  }

  .sm\:gap-y-80px {
    row-gap: 80px;
  }

  .sm\:gap-y-60px {
    row-gap: 60px;
  }

  .sm\:gap-y-50px {
    row-gap: 50px;
  }

  .sm\:gap-y-40px {
    row-gap: 40px;
  }

  .sm\:gap-y-35px {
    row-gap: 35px;
  }

  .sm\:gap-y-34px-i {
    row-gap: 34px !important;
  }

  .sm\:gap-y-30px-i {
    row-gap: 30px !important;
  }

  .sm\:gap-y-24px-i {
    row-gap: 24px !important;
  }

  .sm\:gap-y-20px-i {
    row-gap: 20px !important;
  }

  .sm\:gap-y-15px-i {
    row-gap: 15px !important;
  }

  .sm\:gap-y-13px-i {
    row-gap: 13px !important;
  }

  .sm\:gap-y-12px-i {
    row-gap: 12px !important;
  }

  .sm\:gap-y-10px-i {
    row-gap: 10px !important;
  }

  .sm\:gap-y-8px-i {
    row-gap: 8px !important;
  }

  .sm\:gap-y-6px-i {
    row-gap: 6px !important;
  }

  .sm\:gap-y-5px-i {
    row-gap: 5px !important;
  }

  .sm\:gap-y-3px-i {
    row-gap: 3px !important;
  }

  .sm\:gap-y-0px-i {
    row-gap: 0px !important;
  }

  .sm\:gap-y-34px {
    row-gap: 34px;
  }

  .sm\:gap-y-30px {
    row-gap: 30px;
  }

  .sm\:gap-y-24px {
    row-gap: 24px;
  }

  .sm\:gap-y-20px {
    row-gap: 20px;
  }

  .sm\:gap-y-15px {
    row-gap: 15px;
  }

  .sm\:gap-y-13px {
    row-gap: 13px;
  }

  .sm\:gap-y-12px {
    row-gap: 12px;
  }

  .sm\:gap-y-10px {
    row-gap: 10px;
  }

  .sm\:gap-y-8px {
    row-gap: 8px;
  }

  .sm\:gap-y-6px {
    row-gap: 6px;
  }

  .sm\:gap-y-5px {
    row-gap: 5px;
  }

  .sm\:gap-y-3px {
    row-gap: 3px;
  }

  .sm\:gap-y-menu {
    row-gap: 275px;
  }

  .sm\:gap-y-px {
    row-gap: 1px !important;
  }

  .sm\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(300px * var(--tw-space-x-reverse));
    margin-left: calc(300px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(230px * var(--tw-space-x-reverse));
    margin-left: calc(230px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(180px * var(--tw-space-x-reverse));
    margin-left: calc(180px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(147px * var(--tw-space-x-reverse));
    margin-left: calc(147px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(123px * var(--tw-space-x-reverse));
    margin-left: calc(123px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(100px * var(--tw-space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(80px * var(--tw-space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(60px * var(--tw-space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(50px * var(--tw-space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(40px * var(--tw-space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(35px * var(--tw-space-x-reverse));
    margin-left: calc(35px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px !important * var(--tw-space-x-reverse));
    margin-left: calc(34px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px !important * var(--tw-space-x-reverse));
    margin-left: calc(30px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px !important * var(--tw-space-x-reverse));
    margin-left: calc(24px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px !important * var(--tw-space-x-reverse));
    margin-left: calc(20px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px !important * var(--tw-space-x-reverse));
    margin-left: calc(15px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px !important * var(--tw-space-x-reverse));
    margin-left: calc(13px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px !important * var(--tw-space-x-reverse));
    margin-left: calc(12px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px !important * var(--tw-space-x-reverse));
    margin-left: calc(10px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px !important * var(--tw-space-x-reverse));
    margin-left: calc(8px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px !important * var(--tw-space-x-reverse));
    margin-left: calc(6px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px !important * var(--tw-space-x-reverse));
    margin-left: calc(5px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px !important * var(--tw-space-x-reverse));
    margin-left: calc(3px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px !important * var(--tw-space-x-reverse));
    margin-left: calc(0px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px * var(--tw-space-x-reverse));
    margin-left: calc(34px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px * var(--tw-space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px * var(--tw-space-x-reverse));
    margin-left: calc(24px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px * var(--tw-space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px * var(--tw-space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px * var(--tw-space-x-reverse));
    margin-left: calc(13px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px * var(--tw-space-x-reverse));
    margin-left: calc(12px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px * var(--tw-space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px * var(--tw-space-x-reverse));
    margin-left: calc(8px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px * var(--tw-space-x-reverse));
    margin-left: calc(6px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px * var(--tw-space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px * var(--tw-space-x-reverse));
    margin-left: calc(3px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(275px * var(--tw-space-x-reverse));
    margin-left: calc(275px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px !important * var(--tw-space-x-reverse));
    margin-left: calc(1px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-300px * var(--tw-space-x-reverse));
    margin-left: calc(-300px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-230px * var(--tw-space-x-reverse));
    margin-left: calc(-230px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-180px * var(--tw-space-x-reverse));
    margin-left: calc(-180px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-147px * var(--tw-space-x-reverse));
    margin-left: calc(-147px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-123px * var(--tw-space-x-reverse));
    margin-left: calc(-123px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-100px * var(--tw-space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-80px * var(--tw-space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-60px * var(--tw-space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-50px * var(--tw-space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-40px * var(--tw-space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-35px * var(--tw-space-x-reverse));
    margin-left: calc(-35px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px !important * var(--tw-space-x-reverse));
    margin-left: calc(34px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px !important * var(--tw-space-x-reverse));
    margin-left: calc(30px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px !important * var(--tw-space-x-reverse));
    margin-left: calc(24px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px !important * var(--tw-space-x-reverse));
    margin-left: calc(20px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px !important * var(--tw-space-x-reverse));
    margin-left: calc(15px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px !important * var(--tw-space-x-reverse));
    margin-left: calc(13px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px !important * var(--tw-space-x-reverse));
    margin-left: calc(12px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px !important * var(--tw-space-x-reverse));
    margin-left: calc(10px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px !important * var(--tw-space-x-reverse));
    margin-left: calc(8px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px !important * var(--tw-space-x-reverse));
    margin-left: calc(6px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px !important * var(--tw-space-x-reverse));
    margin-left: calc(5px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px !important * var(--tw-space-x-reverse));
    margin-left: calc(3px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px !important * var(--tw-space-x-reverse));
    margin-left: calc(0px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-34px * var(--tw-space-x-reverse));
    margin-left: calc(-34px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-30px * var(--tw-space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24px * var(--tw-space-x-reverse));
    margin-left: calc(-24px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20px * var(--tw-space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15px * var(--tw-space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13px * var(--tw-space-x-reverse));
    margin-left: calc(-13px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12px * var(--tw-space-x-reverse));
    margin-left: calc(-12px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10px * var(--tw-space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8px * var(--tw-space-x-reverse));
    margin-left: calc(-8px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6px * var(--tw-space-x-reverse));
    margin-left: calc(-6px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5px * var(--tw-space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3px * var(--tw-space-x-reverse));
    margin-left: calc(-3px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-275px * var(--tw-space-x-reverse));
    margin-left: calc(-275px * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px !important * var(--tw-space-x-reverse));
    margin-left: calc(1px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(300px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(300px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(230px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(230px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(180px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(180px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(147px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(147px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(123px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(123px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(100px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(80px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(60px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(50px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(40px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(35px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(35px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(275px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(275px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-300px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-300px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-230px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-230px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-180px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-180px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-147px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-147px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-123px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-123px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-100px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-80px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-60px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-50px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-40px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-35px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-35px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px !important * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-34px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-34px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-30px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-275px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-275px * var(--tw-space-y-reverse));
  }

  .sm\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px !important * var(--tw-space-y-reverse));
  }

  .sm\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .sm\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .sm\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-x-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .sm\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .sm\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .sm\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .sm\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .sm\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .sm\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .sm\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }

  .sm\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .sm\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-divide-opacity));
  }

  .sm\:divide-black40pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-divide-opacity));
  }

  .sm\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .sm\:divide-petroleum > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-divide-opacity));
  }

  .sm\:divide-petroleum70pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-divide-opacity));
  }

  .sm\:divide-petroleum30pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-divide-opacity));
  }

  .sm\:divide-disabled > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-divide-opacity));
  }

  .sm\:divide-petroleum10pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-divide-opacity));
  }

  .sm\:divide-petroleum5pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-divide-opacity));
  }

  .sm\:divide-darkGreen > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-divide-opacity));
  }

  .sm\:divide-ok > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-divide-opacity));
  }

  .sm\:divide-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-divide-opacity));
  }

  .sm\:divide-green10pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-divide-opacity));
  }

  .sm\:divide-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-divide-opacity));
  }

  .sm\:divide-darkOrange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-divide-opacity));
  }

  .sm\:divide-lightOrange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-divide-opacity));
  }

  .sm\:divide-error > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-divide-opacity));
  }

  .sm\:divide-yellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-divide-opacity));
  }

  .sm\:divide-darkYellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-divide-opacity));
  }

  .sm\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-divide-opacity));
  }

  .sm\:divide-lightGray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-divide-opacity));
  }

  .sm\:divide-borderGray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-divide-opacity));
  }

  .sm\:divide-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-divide-opacity));
  }

  .sm\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .sm\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }

  .sm\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }

  .sm\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }

  .sm\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .sm\:place-self-auto {
    place-self: auto;
  }

  .sm\:place-self-start {
    place-self: start;
  }

  .sm\:place-self-end {
    place-self: end;
  }

  .sm\:place-self-center {
    place-self: center;
  }

  .sm\:place-self-stretch {
    place-self: stretch;
  }

  .sm\:self-auto {
    align-self: auto;
  }

  .sm\:self-start {
    align-self: flex-start;
  }

  .sm\:self-end {
    align-self: flex-end;
  }

  .sm\:self-center {
    align-self: center;
  }

  .sm\:self-stretch {
    align-self: stretch;
  }

  .sm\:self-baseline {
    align-self: baseline;
  }

  .sm\:justify-self-auto {
    justify-self: auto;
  }

  .sm\:justify-self-start {
    justify-self: start;
  }

  .sm\:justify-self-end {
    justify-self: end;
  }

  .sm\:justify-self-center {
    justify-self: center;
  }

  .sm\:justify-self-stretch {
    justify-self: stretch;
  }

  .sm\:overflow-auto {
    overflow: auto;
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:overflow-visible {
    overflow: visible;
  }

  .sm\:overflow-scroll {
    overflow: scroll;
  }

  .sm\:overflow-x-auto {
    overflow-x: auto;
  }

  .sm\:overflow-y-auto {
    overflow-y: auto;
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .sm\:overflow-x-visible {
    overflow-x: visible;
  }

  .sm\:overflow-y-visible {
    overflow-y: visible;
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .sm\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .sm\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .sm\:overscroll-none {
    overscroll-behavior: none;
  }

  .sm\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .sm\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .sm\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .sm\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .sm\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .sm\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .sm\:whitespace-normal {
    white-space: normal;
  }

  .sm\:whitespace-nowrap {
    white-space: nowrap;
  }

  .sm\:whitespace-pre {
    white-space: pre;
  }

  .sm\:whitespace-pre-line {
    white-space: pre-line;
  }

  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .sm\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .sm\:break-words {
    overflow-wrap: break-word;
  }

  .sm\:break-all {
    word-break: break-all;
  }

  .sm\:rounded-none {
    border-radius: 0;
  }

  .sm\:rounded-5px {
    border-radius: 5px;
  }

  .sm\:rounded-sm {
    border-radius: 0.125rem;
  }

  .sm\:rounded-default {
    border-radius: 0.25rem;
  }

  .sm\:rounded-md {
    border-radius: 0.375rem;
  }

  .sm\:rounded-lg {
    border-radius: 0.5rem;
  }

  .sm\:rounded-xl {
    border-radius: 0.75rem;
  }

  .sm\:rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .sm\:rounded-full {
    border-radius: 9999px;
  }

  .sm\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .sm\:rounded-t-5px {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .sm\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .sm\:rounded-t-default {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .sm\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .sm\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .sm\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .sm\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .sm\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .sm\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .sm\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sm\:rounded-r-5px {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .sm\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .sm\:rounded-r-default {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .sm\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .sm\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .sm\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .sm\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .sm\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .sm\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:rounded-b-5px {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .sm\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .sm\:rounded-b-default {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .sm\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .sm\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .sm\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .sm\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:rounded-l-5px {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .sm\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .sm\:rounded-l-default {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .sm\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .sm\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .sm\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .sm\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .sm\:rounded-tl-5px {
    border-top-left-radius: 5px;
  }

  .sm\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .sm\:rounded-tl-default {
    border-top-left-radius: 0.25rem;
  }

  .sm\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .sm\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .sm\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .sm\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .sm\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .sm\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .sm\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .sm\:rounded-tr-5px {
    border-top-right-radius: 5px;
  }

  .sm\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .sm\:rounded-tr-default {
    border-top-right-radius: 0.25rem;
  }

  .sm\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .sm\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .sm\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .sm\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .sm\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .sm\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .sm\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .sm\:rounded-br-5px {
    border-bottom-right-radius: 5px;
  }

  .sm\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .sm\:rounded-br-default {
    border-bottom-right-radius: 0.25rem;
  }

  .sm\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .sm\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .sm\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .sm\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .sm\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .sm\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .sm\:rounded-bl-5px {
    border-bottom-left-radius: 5px;
  }

  .sm\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .sm\:rounded-bl-default {
    border-bottom-left-radius: 0.25rem;
  }

  .sm\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .sm\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .sm\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .sm\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .sm\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .sm\:border-0 {
    border-width: 0;
  }

  .sm\:border-2 {
    border-width: 2px;
  }

  .sm\:border-4 {
    border-width: 4px;
  }

  .sm\:border-8 {
    border-width: 8px;
  }

  .sm\:border-default {
    border-width: 1px;
  }

  .sm\:border-t-0 {
    border-top-width: 0;
  }

  .sm\:border-t-2 {
    border-top-width: 2px;
  }

  .sm\:border-t-4 {
    border-top-width: 4px;
  }

  .sm\:border-t-8 {
    border-top-width: 8px;
  }

  .sm\:border-t-default {
    border-top-width: 1px;
  }

  .sm\:border-r-0 {
    border-right-width: 0;
  }

  .sm\:border-r-2 {
    border-right-width: 2px;
  }

  .sm\:border-r-4 {
    border-right-width: 4px;
  }

  .sm\:border-r-8 {
    border-right-width: 8px;
  }

  .sm\:border-r-default {
    border-right-width: 1px;
  }

  .sm\:border-b-0 {
    border-bottom-width: 0;
  }

  .sm\:border-b-2 {
    border-bottom-width: 2px;
  }

  .sm\:border-b-4 {
    border-bottom-width: 4px;
  }

  .sm\:border-b-8 {
    border-bottom-width: 8px;
  }

  .sm\:border-b-default {
    border-bottom-width: 1px;
  }

  .sm\:border-l-0 {
    border-left-width: 0;
  }

  .sm\:border-l-2 {
    border-left-width: 2px;
  }

  .sm\:border-l-4 {
    border-left-width: 4px;
  }

  .sm\:border-l-8 {
    border-left-width: 8px;
  }

  .sm\:border-l-default {
    border-left-width: 1px;
  }

  .sm\:border-solid {
    border-style: solid;
  }

  .sm\:border-dashed {
    border-style: dashed;
  }

  .sm\:border-dotted {
    border-style: dotted;
  }

  .sm\:border-double {
    border-style: double;
  }

  .sm\:border-none {
    border-style: none;
  }

  .sm\:border-transparent {
    border-color: transparent;
  }

  .sm\:border-current {
    border-color: currentColor;
  }

  .sm\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .sm\:border-black40pct {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .sm\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:border-petroleum {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .sm\:border-petroleum70pct {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .sm\:border-petroleum30pct {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .sm\:border-disabled {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .sm\:border-petroleum10pct {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .sm\:border-petroleum5pct {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .sm\:border-darkGreen {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .sm\:border-ok {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .sm\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .sm\:border-green10pct {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .sm\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .sm\:border-darkOrange {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .sm\:border-lightOrange {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .sm\:border-error {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .sm\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .sm\:border-darkYellow {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .sm\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .sm\:border-lightGray {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .sm\:border-borderGray {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .sm\:border-default {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .sm\:hover\:border-transparent:hover {
    border-color: transparent;
  }

  .sm\:hover\:border-current:hover {
    border-color: currentColor;
  }

  .sm\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .sm\:hover\:border-black40pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .sm\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:hover\:border-petroleum:hover {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .sm\:hover\:border-petroleum70pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .sm\:hover\:border-petroleum30pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .sm\:hover\:border-disabled:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .sm\:hover\:border-petroleum10pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .sm\:hover\:border-petroleum5pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .sm\:hover\:border-darkGreen:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .sm\:hover\:border-ok:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .sm\:hover\:border-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .sm\:hover\:border-green10pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .sm\:hover\:border-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .sm\:hover\:border-darkOrange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .sm\:hover\:border-lightOrange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .sm\:hover\:border-error:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .sm\:hover\:border-yellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .sm\:hover\:border-darkYellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .sm\:hover\:border-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .sm\:hover\:border-lightGray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .sm\:hover\:border-borderGray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .sm\:hover\:border-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .sm\:focus\:border-transparent:focus {
    border-color: transparent;
  }

  .sm\:focus\:border-current:focus {
    border-color: currentColor;
  }

  .sm\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .sm\:focus\:border-black40pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .sm\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .sm\:focus\:border-petroleum:focus {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .sm\:focus\:border-petroleum70pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .sm\:focus\:border-petroleum30pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .sm\:focus\:border-disabled:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .sm\:focus\:border-petroleum10pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .sm\:focus\:border-petroleum5pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .sm\:focus\:border-darkGreen:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .sm\:focus\:border-ok:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .sm\:focus\:border-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .sm\:focus\:border-green10pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .sm\:focus\:border-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .sm\:focus\:border-darkOrange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .sm\:focus\:border-lightOrange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .sm\:focus\:border-error:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .sm\:focus\:border-yellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .sm\:focus\:border-darkYellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .sm\:focus\:border-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .sm\:focus\:border-lightGray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .sm\:focus\:border-borderGray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .sm\:focus\:border-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .sm\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .sm\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .sm\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .sm\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .sm\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .sm\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .sm\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }

  .sm\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }

  .sm\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }

  .sm\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .sm\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .sm\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }

  .sm\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }

  .sm\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }

  .sm\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .sm\:bg-transparent {
    background-color: transparent;
  }

  .sm\:bg-current {
    background-color: currentColor;
  }

  .sm\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .sm\:bg-black40pct {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .sm\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:bg-petroleum {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .sm\:bg-petroleum70pct {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .sm\:bg-petroleum30pct {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .sm\:bg-disabled {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .sm\:bg-petroleum10pct {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .sm\:bg-petroleum5pct {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .sm\:bg-darkGreen {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .sm\:bg-ok {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .sm\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .sm\:bg-green10pct {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .sm\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .sm\:bg-darkOrange {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .sm\:bg-lightOrange {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .sm\:bg-error {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .sm\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .sm\:bg-darkYellow {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .sm\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .sm\:bg-lightGray {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .sm\:bg-borderGray {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-transparent:hover {
    background-color: transparent;
  }

  .sm\:hover\:bg-current:hover {
    background-color: currentColor;
  }

  .sm\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-black40pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-petroleum:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-petroleum70pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-petroleum30pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-disabled:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-petroleum10pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-petroleum5pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-darkGreen:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-ok:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-green10pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-darkOrange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-lightOrange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-error:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-yellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-darkYellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-lightGray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-borderGray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-transparent:focus {
    background-color: transparent;
  }

  .sm\:focus\:bg-current:focus {
    background-color: currentColor;
  }

  .sm\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-black40pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-petroleum:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-petroleum70pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-petroleum30pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-disabled:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-petroleum10pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-petroleum5pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-darkGreen:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-ok:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-green10pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-darkOrange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-lightOrange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-error:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-yellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-darkYellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-lightGray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .sm\:focus\:bg-borderGray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .sm\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .sm\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .sm\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .sm\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .sm\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .sm\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .sm\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }

  .sm\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }

  .sm\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }

  .sm\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .sm\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .sm\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }

  .sm\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }

  .sm\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }

  .sm\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .sm\:bg-none {
    background-image: none;
  }

  .sm\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .sm\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .sm\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .sm\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .sm\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .sm\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .sm\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .sm\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .sm\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:from-black {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .sm\:from-black40pct {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .sm\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:from-petroleum {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .sm\:from-petroleum70pct {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .sm\:from-petroleum30pct {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .sm\:from-disabled {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .sm\:from-petroleum10pct {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .sm\:from-petroleum5pct {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .sm\:from-darkGreen {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .sm\:from-ok {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .sm\:from-green {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .sm\:from-green10pct {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .sm\:from-orange {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .sm\:from-darkOrange {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .sm\:from-lightOrange {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .sm\:from-error {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .sm\:from-yellow {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .sm\:from-darkYellow {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .sm\:from-gray {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .sm\:from-lightGray {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .sm\:from-borderGray {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .sm\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:from-black:hover {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .sm\:hover\:from-black40pct:hover {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .sm\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:from-petroleum:hover {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .sm\:hover\:from-petroleum70pct:hover {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .sm\:hover\:from-petroleum30pct:hover {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .sm\:hover\:from-disabled:hover {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .sm\:hover\:from-petroleum10pct:hover {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .sm\:hover\:from-petroleum5pct:hover {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .sm\:hover\:from-darkGreen:hover {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .sm\:hover\:from-ok:hover {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .sm\:hover\:from-green:hover {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .sm\:hover\:from-green10pct:hover {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .sm\:hover\:from-orange:hover {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .sm\:hover\:from-darkOrange:hover {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .sm\:hover\:from-lightOrange:hover {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .sm\:hover\:from-error:hover {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .sm\:hover\:from-yellow:hover {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .sm\:hover\:from-darkYellow:hover {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .sm\:hover\:from-gray:hover {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .sm\:hover\:from-lightGray:hover {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .sm\:hover\:from-borderGray:hover {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .sm\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:from-black:focus {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .sm\:focus\:from-black40pct:focus {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .sm\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:from-petroleum:focus {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .sm\:focus\:from-petroleum70pct:focus {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .sm\:focus\:from-petroleum30pct:focus {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .sm\:focus\:from-disabled:focus {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .sm\:focus\:from-petroleum10pct:focus {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .sm\:focus\:from-petroleum5pct:focus {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .sm\:focus\:from-darkGreen:focus {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .sm\:focus\:from-ok:focus {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .sm\:focus\:from-green:focus {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .sm\:focus\:from-green10pct:focus {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .sm\:focus\:from-orange:focus {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .sm\:focus\:from-darkOrange:focus {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .sm\:focus\:from-lightOrange:focus {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .sm\:focus\:from-error:focus {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .sm\:focus\:from-yellow:focus {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .sm\:focus\:from-darkYellow:focus {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .sm\:focus\:from-gray:focus {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .sm\:focus\:from-lightGray:focus {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .sm\:focus\:from-borderGray:focus {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .sm\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .sm\:via-black40pct {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .sm\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:via-petroleum {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .sm\:via-petroleum70pct {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .sm\:via-petroleum30pct {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .sm\:via-disabled {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .sm\:via-petroleum10pct {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .sm\:via-petroleum5pct {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .sm\:via-darkGreen {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .sm\:via-ok {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .sm\:via-green {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .sm\:via-green10pct {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .sm\:via-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .sm\:via-darkOrange {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .sm\:via-lightOrange {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .sm\:via-error {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .sm\:via-yellow {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .sm\:via-darkYellow {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .sm\:via-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .sm\:via-lightGray {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .sm\:via-borderGray {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .sm\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .sm\:hover\:via-black40pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .sm\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:hover\:via-petroleum:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .sm\:hover\:via-petroleum70pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .sm\:hover\:via-petroleum30pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .sm\:hover\:via-disabled:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .sm\:hover\:via-petroleum10pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .sm\:hover\:via-petroleum5pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .sm\:hover\:via-darkGreen:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .sm\:hover\:via-ok:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .sm\:hover\:via-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .sm\:hover\:via-green10pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .sm\:hover\:via-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .sm\:hover\:via-darkOrange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .sm\:hover\:via-lightOrange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .sm\:hover\:via-error:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .sm\:hover\:via-yellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .sm\:hover\:via-darkYellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .sm\:hover\:via-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .sm\:hover\:via-lightGray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .sm\:hover\:via-borderGray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .sm\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .sm\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .sm\:focus\:via-black40pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .sm\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .sm\:focus\:via-petroleum:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .sm\:focus\:via-petroleum70pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .sm\:focus\:via-petroleum30pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .sm\:focus\:via-disabled:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .sm\:focus\:via-petroleum10pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .sm\:focus\:via-petroleum5pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .sm\:focus\:via-darkGreen:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .sm\:focus\:via-ok:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .sm\:focus\:via-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .sm\:focus\:via-green10pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .sm\:focus\:via-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .sm\:focus\:via-darkOrange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .sm\:focus\:via-lightOrange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .sm\:focus\:via-error:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .sm\:focus\:via-yellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .sm\:focus\:via-darkYellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .sm\:focus\:via-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .sm\:focus\:via-lightGray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .sm\:focus\:via-borderGray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .sm\:to-transparent {
    --tw-gradient-to: transparent;
  }

  .sm\:to-current {
    --tw-gradient-to: currentColor;
  }

  .sm\:to-black {
    --tw-gradient-to: #1b1b1a;
  }

  .sm\:to-black40pct {
    --tw-gradient-to: #a4a4a3;
  }

  .sm\:to-white {
    --tw-gradient-to: #fff;
  }

  .sm\:to-petroleum {
    --tw-gradient-to: #0e4744;
  }

  .sm\:to-petroleum70pct {
    --tw-gradient-to: #567e7c;
  }

  .sm\:to-petroleum30pct {
    --tw-gradient-to: #b7c8c7;
  }

  .sm\:to-disabled {
    --tw-gradient-to: #b7c8c7;
  }

  .sm\:to-petroleum10pct {
    --tw-gradient-to: #e7edec;
  }

  .sm\:to-petroleum5pct {
    --tw-gradient-to: #f3f6f6;
  }

  .sm\:to-darkGreen {
    --tw-gradient-to: #267b38;
  }

  .sm\:to-ok {
    --tw-gradient-to: #267b38;
  }

  .sm\:to-green {
    --tw-gradient-to: #2ea149;
  }

  .sm\:to-green10pct {
    --tw-gradient-to: #eaf6ed;
  }

  .sm\:to-orange {
    --tw-gradient-to: #ed7100;
  }

  .sm\:to-darkOrange {
    --tw-gradient-to: #aa3636;
  }

  .sm\:to-lightOrange {
    --tw-gradient-to: #fbe3cc;
  }

  .sm\:to-error {
    --tw-gradient-to: #aa3636;
  }

  .sm\:to-yellow {
    --tw-gradient-to: #e9c258;
  }

  .sm\:to-darkYellow {
    --tw-gradient-to: #c3b091;
  }

  .sm\:to-gray {
    --tw-gradient-to: #949493;
  }

  .sm\:to-lightGray {
    --tw-gradient-to: #e5e5e5;
  }

  .sm\:to-borderGray {
    --tw-gradient-to: #e6e6e6;
  }

  .sm\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .sm\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .sm\:hover\:to-black:hover {
    --tw-gradient-to: #1b1b1a;
  }

  .sm\:hover\:to-black40pct:hover {
    --tw-gradient-to: #a4a4a3;
  }

  .sm\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }

  .sm\:hover\:to-petroleum:hover {
    --tw-gradient-to: #0e4744;
  }

  .sm\:hover\:to-petroleum70pct:hover {
    --tw-gradient-to: #567e7c;
  }

  .sm\:hover\:to-petroleum30pct:hover {
    --tw-gradient-to: #b7c8c7;
  }

  .sm\:hover\:to-disabled:hover {
    --tw-gradient-to: #b7c8c7;
  }

  .sm\:hover\:to-petroleum10pct:hover {
    --tw-gradient-to: #e7edec;
  }

  .sm\:hover\:to-petroleum5pct:hover {
    --tw-gradient-to: #f3f6f6;
  }

  .sm\:hover\:to-darkGreen:hover {
    --tw-gradient-to: #267b38;
  }

  .sm\:hover\:to-ok:hover {
    --tw-gradient-to: #267b38;
  }

  .sm\:hover\:to-green:hover {
    --tw-gradient-to: #2ea149;
  }

  .sm\:hover\:to-green10pct:hover {
    --tw-gradient-to: #eaf6ed;
  }

  .sm\:hover\:to-orange:hover {
    --tw-gradient-to: #ed7100;
  }

  .sm\:hover\:to-darkOrange:hover {
    --tw-gradient-to: #aa3636;
  }

  .sm\:hover\:to-lightOrange:hover {
    --tw-gradient-to: #fbe3cc;
  }

  .sm\:hover\:to-error:hover {
    --tw-gradient-to: #aa3636;
  }

  .sm\:hover\:to-yellow:hover {
    --tw-gradient-to: #e9c258;
  }

  .sm\:hover\:to-darkYellow:hover {
    --tw-gradient-to: #c3b091;
  }

  .sm\:hover\:to-gray:hover {
    --tw-gradient-to: #949493;
  }

  .sm\:hover\:to-lightGray:hover {
    --tw-gradient-to: #e5e5e5;
  }

  .sm\:hover\:to-borderGray:hover {
    --tw-gradient-to: #e6e6e6;
  }

  .sm\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .sm\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .sm\:focus\:to-black:focus {
    --tw-gradient-to: #1b1b1a;
  }

  .sm\:focus\:to-black40pct:focus {
    --tw-gradient-to: #a4a4a3;
  }

  .sm\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }

  .sm\:focus\:to-petroleum:focus {
    --tw-gradient-to: #0e4744;
  }

  .sm\:focus\:to-petroleum70pct:focus {
    --tw-gradient-to: #567e7c;
  }

  .sm\:focus\:to-petroleum30pct:focus {
    --tw-gradient-to: #b7c8c7;
  }

  .sm\:focus\:to-disabled:focus {
    --tw-gradient-to: #b7c8c7;
  }

  .sm\:focus\:to-petroleum10pct:focus {
    --tw-gradient-to: #e7edec;
  }

  .sm\:focus\:to-petroleum5pct:focus {
    --tw-gradient-to: #f3f6f6;
  }

  .sm\:focus\:to-darkGreen:focus {
    --tw-gradient-to: #267b38;
  }

  .sm\:focus\:to-ok:focus {
    --tw-gradient-to: #267b38;
  }

  .sm\:focus\:to-green:focus {
    --tw-gradient-to: #2ea149;
  }

  .sm\:focus\:to-green10pct:focus {
    --tw-gradient-to: #eaf6ed;
  }

  .sm\:focus\:to-orange:focus {
    --tw-gradient-to: #ed7100;
  }

  .sm\:focus\:to-darkOrange:focus {
    --tw-gradient-to: #aa3636;
  }

  .sm\:focus\:to-lightOrange:focus {
    --tw-gradient-to: #fbe3cc;
  }

  .sm\:focus\:to-error:focus {
    --tw-gradient-to: #aa3636;
  }

  .sm\:focus\:to-yellow:focus {
    --tw-gradient-to: #e9c258;
  }

  .sm\:focus\:to-darkYellow:focus {
    --tw-gradient-to: #c3b091;
  }

  .sm\:focus\:to-gray:focus {
    --tw-gradient-to: #949493;
  }

  .sm\:focus\:to-lightGray:focus {
    --tw-gradient-to: #e5e5e5;
  }

  .sm\:focus\:to-borderGray:focus {
    --tw-gradient-to: #e6e6e6;
  }

  .sm\:bg-auto {
    background-size: auto;
  }

  .sm\:bg-cover {
    background-size: cover;
  }

  .sm\:bg-contain {
    background-size: contain;
  }

  .sm\:bg-fixed {
    background-attachment: fixed;
  }

  .sm\:bg-local {
    background-attachment: local;
  }

  .sm\:bg-scroll {
    background-attachment: scroll;
  }

  .sm\:bg-clip-border {
    background-clip: border-box;
  }

  .sm\:bg-clip-padding {
    background-clip: padding-box;
  }

  .sm\:bg-clip-content {
    background-clip: content-box;
  }

  .sm\:bg-clip-text {
    background-clip: text;
  }

  .sm\:bg-bottom {
    background-position: bottom;
  }

  .sm\:bg-center {
    background-position: center;
  }

  .sm\:bg-left {
    background-position: left;
  }

  .sm\:bg-left-bottom {
    background-position: left bottom;
  }

  .sm\:bg-left-top {
    background-position: left top;
  }

  .sm\:bg-right {
    background-position: right;
  }

  .sm\:bg-right-bottom {
    background-position: right bottom;
  }

  .sm\:bg-right-top {
    background-position: right top;
  }

  .sm\:bg-top {
    background-position: top;
  }

  .sm\:bg-repeat {
    background-repeat: repeat;
  }

  .sm\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .sm\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .sm\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .sm\:bg-repeat-round {
    background-repeat: round;
  }

  .sm\:bg-repeat-space {
    background-repeat: space;
  }

  .sm\:fill-current {
    fill: currentColor;
  }

  .sm\:stroke-current {
    stroke: currentColor;
  }

  .sm\:stroke-0 {
    stroke-width: 0;
  }

  .sm\:stroke-1 {
    stroke-width: 1;
  }

  .sm\:stroke-2 {
    stroke-width: 2;
  }

  .sm\:object-contain {
    object-fit: contain;
  }

  .sm\:object-cover {
    object-fit: cover;
  }

  .sm\:object-fill {
    object-fit: fill;
  }

  .sm\:object-none {
    object-fit: none;
  }

  .sm\:object-scale-down {
    object-fit: scale-down;
  }

  .sm\:object-bottom {
    object-position: bottom;
  }

  .sm\:object-center {
    object-position: center;
  }

  .sm\:object-left {
    object-position: left;
  }

  .sm\:object-left-bottom {
    object-position: left bottom;
  }

  .sm\:object-left-top {
    object-position: left top;
  }

  .sm\:object-right {
    object-position: right;
  }

  .sm\:object-right-bottom {
    object-position: right bottom;
  }

  .sm\:object-right-top {
    object-position: right top;
  }

  .sm\:object-top {
    object-position: top;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-1 {
    padding: 0.25rem;
  }

  .sm\:p-2 {
    padding: 0.5rem;
  }

  .sm\:p-3 {
    padding: 0.75rem;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:p-10 {
    padding: 2.5rem;
  }

  .sm\:p-12 {
    padding: 3rem;
  }

  .sm\:p-16 {
    padding: 4rem;
  }

  .sm\:p-20 {
    padding: 5rem;
  }

  .sm\:p-24 {
    padding: 6rem;
  }

  .sm\:p-32 {
    padding: 8rem;
  }

  .sm\:p-40 {
    padding: 10rem;
  }

  .sm\:p-48 {
    padding: 12rem;
  }

  .sm\:p-56 {
    padding: 14rem;
  }

  .sm\:p-64 {
    padding: 16rem;
  }

  .sm\:p-300px {
    padding: 300px;
  }

  .sm\:p-230px {
    padding: 230px;
  }

  .sm\:p-180px {
    padding: 180px;
  }

  .sm\:p-147px {
    padding: 147px;
  }

  .sm\:p-123px {
    padding: 123px;
  }

  .sm\:p-100px {
    padding: 100px;
  }

  .sm\:p-80px {
    padding: 80px;
  }

  .sm\:p-60px {
    padding: 60px;
  }

  .sm\:p-50px {
    padding: 50px;
  }

  .sm\:p-40px {
    padding: 40px;
  }

  .sm\:p-35px {
    padding: 35px;
  }

  .sm\:p-34px-i {
    padding: 34px !important;
  }

  .sm\:p-30px-i {
    padding: 30px !important;
  }

  .sm\:p-24px-i {
    padding: 24px !important;
  }

  .sm\:p-20px-i {
    padding: 20px !important;
  }

  .sm\:p-15px-i {
    padding: 15px !important;
  }

  .sm\:p-13px-i {
    padding: 13px !important;
  }

  .sm\:p-12px-i {
    padding: 12px !important;
  }

  .sm\:p-10px-i {
    padding: 10px !important;
  }

  .sm\:p-8px-i {
    padding: 8px !important;
  }

  .sm\:p-6px-i {
    padding: 6px !important;
  }

  .sm\:p-5px-i {
    padding: 5px !important;
  }

  .sm\:p-3px-i {
    padding: 3px !important;
  }

  .sm\:p-0px-i {
    padding: 0px !important;
  }

  .sm\:p-34px {
    padding: 34px;
  }

  .sm\:p-30px {
    padding: 30px;
  }

  .sm\:p-24px {
    padding: 24px;
  }

  .sm\:p-20px {
    padding: 20px;
  }

  .sm\:p-15px {
    padding: 15px;
  }

  .sm\:p-13px {
    padding: 13px;
  }

  .sm\:p-12px {
    padding: 12px;
  }

  .sm\:p-10px {
    padding: 10px;
  }

  .sm\:p-8px {
    padding: 8px;
  }

  .sm\:p-6px {
    padding: 6px;
  }

  .sm\:p-5px {
    padding: 5px;
  }

  .sm\:p-3px {
    padding: 3px;
  }

  .sm\:p-menu {
    padding: 275px;
  }

  .sm\:p-px {
    padding: 1px !important;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .sm\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .sm\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .sm\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .sm\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .sm\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .sm\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .sm\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .sm\:px-300px {
    padding-left: 300px;
    padding-right: 300px;
  }

  .sm\:px-230px {
    padding-left: 230px;
    padding-right: 230px;
  }

  .sm\:px-180px {
    padding-left: 180px;
    padding-right: 180px;
  }

  .sm\:px-147px {
    padding-left: 147px;
    padding-right: 147px;
  }

  .sm\:px-123px {
    padding-left: 123px;
    padding-right: 123px;
  }

  .sm\:px-100px {
    padding-left: 100px;
    padding-right: 100px;
  }

  .sm\:px-80px {
    padding-left: 80px;
    padding-right: 80px;
  }

  .sm\:px-60px {
    padding-left: 60px;
    padding-right: 60px;
  }

  .sm\:px-50px {
    padding-left: 50px;
    padding-right: 50px;
  }

  .sm\:px-40px {
    padding-left: 40px;
    padding-right: 40px;
  }

  .sm\:px-35px {
    padding-left: 35px;
    padding-right: 35px;
  }

  .sm\:px-34px-i {
    padding-left: 34px !important;
    padding-right: 34px !important;
  }

  .sm\:px-30px-i {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .sm\:px-24px-i {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .sm\:px-20px-i {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .sm\:px-15px-i {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .sm\:px-13px-i {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }

  .sm\:px-12px-i {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .sm\:px-10px-i {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .sm\:px-8px-i {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .sm\:px-6px-i {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .sm\:px-5px-i {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .sm\:px-3px-i {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .sm\:px-0px-i {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .sm\:px-34px {
    padding-left: 34px;
    padding-right: 34px;
  }

  .sm\:px-30px {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sm\:px-24px {
    padding-left: 24px;
    padding-right: 24px;
  }

  .sm\:px-20px {
    padding-left: 20px;
    padding-right: 20px;
  }

  .sm\:px-15px {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sm\:px-13px {
    padding-left: 13px;
    padding-right: 13px;
  }

  .sm\:px-12px {
    padding-left: 12px;
    padding-right: 12px;
  }

  .sm\:px-10px {
    padding-left: 10px;
    padding-right: 10px;
  }

  .sm\:px-8px {
    padding-left: 8px;
    padding-right: 8px;
  }

  .sm\:px-6px {
    padding-left: 6px;
    padding-right: 6px;
  }

  .sm\:px-5px {
    padding-left: 5px;
    padding-right: 5px;
  }

  .sm\:px-3px {
    padding-left: 3px;
    padding-right: 3px;
  }

  .sm\:px-menu {
    padding-left: 275px;
    padding-right: 275px;
  }

  .sm\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .sm\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .sm\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .sm\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .sm\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .sm\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .sm\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .sm\:py-300px {
    padding-top: 300px;
    padding-bottom: 300px;
  }

  .sm\:py-230px {
    padding-top: 230px;
    padding-bottom: 230px;
  }

  .sm\:py-180px {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .sm\:py-147px {
    padding-top: 147px;
    padding-bottom: 147px;
  }

  .sm\:py-123px {
    padding-top: 123px;
    padding-bottom: 123px;
  }

  .sm\:py-100px {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .sm\:py-80px {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .sm\:py-60px {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .sm\:py-50px {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .sm\:py-40px {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .sm\:py-35px {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .sm\:py-34px-i {
    padding-top: 34px !important;
    padding-bottom: 34px !important;
  }

  .sm\:py-30px-i {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .sm\:py-24px-i {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .sm\:py-20px-i {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .sm\:py-15px-i {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .sm\:py-13px-i {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  .sm\:py-12px-i {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .sm\:py-10px-i {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .sm\:py-8px-i {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .sm\:py-6px-i {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .sm\:py-5px-i {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .sm\:py-3px-i {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .sm\:py-0px-i {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .sm\:py-34px {
    padding-top: 34px;
    padding-bottom: 34px;
  }

  .sm\:py-30px {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .sm\:py-24px {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .sm\:py-20px {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .sm\:py-15px {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .sm\:py-13px {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .sm\:py-12px {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .sm\:py-10px {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .sm\:py-8px {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .sm\:py-6px {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .sm\:py-5px {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .sm\:py-3px {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .sm\:py-menu {
    padding-top: 275px;
    padding-bottom: 275px;
  }

  .sm\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .sm\:pt-0 {
    padding-top: 0;
  }

  .sm\:pt-1 {
    padding-top: 0.25rem;
  }

  .sm\:pt-2 {
    padding-top: 0.5rem;
  }

  .sm\:pt-3 {
    padding-top: 0.75rem;
  }

  .sm\:pt-4 {
    padding-top: 1rem;
  }

  .sm\:pt-5 {
    padding-top: 1.25rem;
  }

  .sm\:pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:pt-8 {
    padding-top: 2rem;
  }

  .sm\:pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:pt-12 {
    padding-top: 3rem;
  }

  .sm\:pt-16 {
    padding-top: 4rem;
  }

  .sm\:pt-20 {
    padding-top: 5rem;
  }

  .sm\:pt-24 {
    padding-top: 6rem;
  }

  .sm\:pt-32 {
    padding-top: 8rem;
  }

  .sm\:pt-40 {
    padding-top: 10rem;
  }

  .sm\:pt-48 {
    padding-top: 12rem;
  }

  .sm\:pt-56 {
    padding-top: 14rem;
  }

  .sm\:pt-64 {
    padding-top: 16rem;
  }

  .sm\:pt-300px {
    padding-top: 300px;
  }

  .sm\:pt-230px {
    padding-top: 230px;
  }

  .sm\:pt-180px {
    padding-top: 180px;
  }

  .sm\:pt-147px {
    padding-top: 147px;
  }

  .sm\:pt-123px {
    padding-top: 123px;
  }

  .sm\:pt-100px {
    padding-top: 100px;
  }

  .sm\:pt-80px {
    padding-top: 80px;
  }

  .sm\:pt-60px {
    padding-top: 60px;
  }

  .sm\:pt-50px {
    padding-top: 50px;
  }

  .sm\:pt-40px {
    padding-top: 40px;
  }

  .sm\:pt-35px {
    padding-top: 35px;
  }

  .sm\:pt-34px-i {
    padding-top: 34px !important;
  }

  .sm\:pt-30px-i {
    padding-top: 30px !important;
  }

  .sm\:pt-24px-i {
    padding-top: 24px !important;
  }

  .sm\:pt-20px-i {
    padding-top: 20px !important;
  }

  .sm\:pt-15px-i {
    padding-top: 15px !important;
  }

  .sm\:pt-13px-i {
    padding-top: 13px !important;
  }

  .sm\:pt-12px-i {
    padding-top: 12px !important;
  }

  .sm\:pt-10px-i {
    padding-top: 10px !important;
  }

  .sm\:pt-8px-i {
    padding-top: 8px !important;
  }

  .sm\:pt-6px-i {
    padding-top: 6px !important;
  }

  .sm\:pt-5px-i {
    padding-top: 5px !important;
  }

  .sm\:pt-3px-i {
    padding-top: 3px !important;
  }

  .sm\:pt-0px-i {
    padding-top: 0px !important;
  }

  .sm\:pt-34px {
    padding-top: 34px;
  }

  .sm\:pt-30px {
    padding-top: 30px;
  }

  .sm\:pt-24px {
    padding-top: 24px;
  }

  .sm\:pt-20px {
    padding-top: 20px;
  }

  .sm\:pt-15px {
    padding-top: 15px;
  }

  .sm\:pt-13px {
    padding-top: 13px;
  }

  .sm\:pt-12px {
    padding-top: 12px;
  }

  .sm\:pt-10px {
    padding-top: 10px;
  }

  .sm\:pt-8px {
    padding-top: 8px;
  }

  .sm\:pt-6px {
    padding-top: 6px;
  }

  .sm\:pt-5px {
    padding-top: 5px;
  }

  .sm\:pt-3px {
    padding-top: 3px;
  }

  .sm\:pt-menu {
    padding-top: 275px;
  }

  .sm\:pt-px {
    padding-top: 1px !important;
  }

  .sm\:pr-0 {
    padding-right: 0;
  }

  .sm\:pr-1 {
    padding-right: 0.25rem;
  }

  .sm\:pr-2 {
    padding-right: 0.5rem;
  }

  .sm\:pr-3 {
    padding-right: 0.75rem;
  }

  .sm\:pr-4 {
    padding-right: 1rem;
  }

  .sm\:pr-5 {
    padding-right: 1.25rem;
  }

  .sm\:pr-6 {
    padding-right: 1.5rem;
  }

  .sm\:pr-8 {
    padding-right: 2rem;
  }

  .sm\:pr-10 {
    padding-right: 2.5rem;
  }

  .sm\:pr-12 {
    padding-right: 3rem;
  }

  .sm\:pr-16 {
    padding-right: 4rem;
  }

  .sm\:pr-20 {
    padding-right: 5rem;
  }

  .sm\:pr-24 {
    padding-right: 6rem;
  }

  .sm\:pr-32 {
    padding-right: 8rem;
  }

  .sm\:pr-40 {
    padding-right: 10rem;
  }

  .sm\:pr-48 {
    padding-right: 12rem;
  }

  .sm\:pr-56 {
    padding-right: 14rem;
  }

  .sm\:pr-64 {
    padding-right: 16rem;
  }

  .sm\:pr-300px {
    padding-right: 300px;
  }

  .sm\:pr-230px {
    padding-right: 230px;
  }

  .sm\:pr-180px {
    padding-right: 180px;
  }

  .sm\:pr-147px {
    padding-right: 147px;
  }

  .sm\:pr-123px {
    padding-right: 123px;
  }

  .sm\:pr-100px {
    padding-right: 100px;
  }

  .sm\:pr-80px {
    padding-right: 80px;
  }

  .sm\:pr-60px {
    padding-right: 60px;
  }

  .sm\:pr-50px {
    padding-right: 50px;
  }

  .sm\:pr-40px {
    padding-right: 40px;
  }

  .sm\:pr-35px {
    padding-right: 35px;
  }

  .sm\:pr-34px-i {
    padding-right: 34px !important;
  }

  .sm\:pr-30px-i {
    padding-right: 30px !important;
  }

  .sm\:pr-24px-i {
    padding-right: 24px !important;
  }

  .sm\:pr-20px-i {
    padding-right: 20px !important;
  }

  .sm\:pr-15px-i {
    padding-right: 15px !important;
  }

  .sm\:pr-13px-i {
    padding-right: 13px !important;
  }

  .sm\:pr-12px-i {
    padding-right: 12px !important;
  }

  .sm\:pr-10px-i {
    padding-right: 10px !important;
  }

  .sm\:pr-8px-i {
    padding-right: 8px !important;
  }

  .sm\:pr-6px-i {
    padding-right: 6px !important;
  }

  .sm\:pr-5px-i {
    padding-right: 5px !important;
  }

  .sm\:pr-3px-i {
    padding-right: 3px !important;
  }

  .sm\:pr-0px-i {
    padding-right: 0px !important;
  }

  .sm\:pr-34px {
    padding-right: 34px;
  }

  .sm\:pr-30px {
    padding-right: 30px;
  }

  .sm\:pr-24px {
    padding-right: 24px;
  }

  .sm\:pr-20px {
    padding-right: 20px;
  }

  .sm\:pr-15px {
    padding-right: 15px;
  }

  .sm\:pr-13px {
    padding-right: 13px;
  }

  .sm\:pr-12px {
    padding-right: 12px;
  }

  .sm\:pr-10px {
    padding-right: 10px;
  }

  .sm\:pr-8px {
    padding-right: 8px;
  }

  .sm\:pr-6px {
    padding-right: 6px;
  }

  .sm\:pr-5px {
    padding-right: 5px;
  }

  .sm\:pr-3px {
    padding-right: 3px;
  }

  .sm\:pr-menu {
    padding-right: 275px;
  }

  .sm\:pr-px {
    padding-right: 1px !important;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pb-1 {
    padding-bottom: 0.25rem;
  }

  .sm\:pb-2 {
    padding-bottom: 0.5rem;
  }

  .sm\:pb-3 {
    padding-bottom: 0.75rem;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .sm\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .sm\:pb-8 {
    padding-bottom: 2rem;
  }

  .sm\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .sm\:pb-12 {
    padding-bottom: 3rem;
  }

  .sm\:pb-16 {
    padding-bottom: 4rem;
  }

  .sm\:pb-20 {
    padding-bottom: 5rem;
  }

  .sm\:pb-24 {
    padding-bottom: 6rem;
  }

  .sm\:pb-32 {
    padding-bottom: 8rem;
  }

  .sm\:pb-40 {
    padding-bottom: 10rem;
  }

  .sm\:pb-48 {
    padding-bottom: 12rem;
  }

  .sm\:pb-56 {
    padding-bottom: 14rem;
  }

  .sm\:pb-64 {
    padding-bottom: 16rem;
  }

  .sm\:pb-300px {
    padding-bottom: 300px;
  }

  .sm\:pb-230px {
    padding-bottom: 230px;
  }

  .sm\:pb-180px {
    padding-bottom: 180px;
  }

  .sm\:pb-147px {
    padding-bottom: 147px;
  }

  .sm\:pb-123px {
    padding-bottom: 123px;
  }

  .sm\:pb-100px {
    padding-bottom: 100px;
  }

  .sm\:pb-80px {
    padding-bottom: 80px;
  }

  .sm\:pb-60px {
    padding-bottom: 60px;
  }

  .sm\:pb-50px {
    padding-bottom: 50px;
  }

  .sm\:pb-40px {
    padding-bottom: 40px;
  }

  .sm\:pb-35px {
    padding-bottom: 35px;
  }

  .sm\:pb-34px-i {
    padding-bottom: 34px !important;
  }

  .sm\:pb-30px-i {
    padding-bottom: 30px !important;
  }

  .sm\:pb-24px-i {
    padding-bottom: 24px !important;
  }

  .sm\:pb-20px-i {
    padding-bottom: 20px !important;
  }

  .sm\:pb-15px-i {
    padding-bottom: 15px !important;
  }

  .sm\:pb-13px-i {
    padding-bottom: 13px !important;
  }

  .sm\:pb-12px-i {
    padding-bottom: 12px !important;
  }

  .sm\:pb-10px-i {
    padding-bottom: 10px !important;
  }

  .sm\:pb-8px-i {
    padding-bottom: 8px !important;
  }

  .sm\:pb-6px-i {
    padding-bottom: 6px !important;
  }

  .sm\:pb-5px-i {
    padding-bottom: 5px !important;
  }

  .sm\:pb-3px-i {
    padding-bottom: 3px !important;
  }

  .sm\:pb-0px-i {
    padding-bottom: 0px !important;
  }

  .sm\:pb-34px {
    padding-bottom: 34px;
  }

  .sm\:pb-30px {
    padding-bottom: 30px;
  }

  .sm\:pb-24px {
    padding-bottom: 24px;
  }

  .sm\:pb-20px {
    padding-bottom: 20px;
  }

  .sm\:pb-15px {
    padding-bottom: 15px;
  }

  .sm\:pb-13px {
    padding-bottom: 13px;
  }

  .sm\:pb-12px {
    padding-bottom: 12px;
  }

  .sm\:pb-10px {
    padding-bottom: 10px;
  }

  .sm\:pb-8px {
    padding-bottom: 8px;
  }

  .sm\:pb-6px {
    padding-bottom: 6px;
  }

  .sm\:pb-5px {
    padding-bottom: 5px;
  }

  .sm\:pb-3px {
    padding-bottom: 3px;
  }

  .sm\:pb-menu {
    padding-bottom: 275px;
  }

  .sm\:pb-px {
    padding-bottom: 1px !important;
  }

  .sm\:pl-0 {
    padding-left: 0;
  }

  .sm\:pl-1 {
    padding-left: 0.25rem;
  }

  .sm\:pl-2 {
    padding-left: 0.5rem;
  }

  .sm\:pl-3 {
    padding-left: 0.75rem;
  }

  .sm\:pl-4 {
    padding-left: 1rem;
  }

  .sm\:pl-5 {
    padding-left: 1.25rem;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:pl-8 {
    padding-left: 2rem;
  }

  .sm\:pl-10 {
    padding-left: 2.5rem;
  }

  .sm\:pl-12 {
    padding-left: 3rem;
  }

  .sm\:pl-16 {
    padding-left: 4rem;
  }

  .sm\:pl-20 {
    padding-left: 5rem;
  }

  .sm\:pl-24 {
    padding-left: 6rem;
  }

  .sm\:pl-32 {
    padding-left: 8rem;
  }

  .sm\:pl-40 {
    padding-left: 10rem;
  }

  .sm\:pl-48 {
    padding-left: 12rem;
  }

  .sm\:pl-56 {
    padding-left: 14rem;
  }

  .sm\:pl-64 {
    padding-left: 16rem;
  }

  .sm\:pl-300px {
    padding-left: 300px;
  }

  .sm\:pl-230px {
    padding-left: 230px;
  }

  .sm\:pl-180px {
    padding-left: 180px;
  }

  .sm\:pl-147px {
    padding-left: 147px;
  }

  .sm\:pl-123px {
    padding-left: 123px;
  }

  .sm\:pl-100px {
    padding-left: 100px;
  }

  .sm\:pl-80px {
    padding-left: 80px;
  }

  .sm\:pl-60px {
    padding-left: 60px;
  }

  .sm\:pl-50px {
    padding-left: 50px;
  }

  .sm\:pl-40px {
    padding-left: 40px;
  }

  .sm\:pl-35px {
    padding-left: 35px;
  }

  .sm\:pl-34px-i {
    padding-left: 34px !important;
  }

  .sm\:pl-30px-i {
    padding-left: 30px !important;
  }

  .sm\:pl-24px-i {
    padding-left: 24px !important;
  }

  .sm\:pl-20px-i {
    padding-left: 20px !important;
  }

  .sm\:pl-15px-i {
    padding-left: 15px !important;
  }

  .sm\:pl-13px-i {
    padding-left: 13px !important;
  }

  .sm\:pl-12px-i {
    padding-left: 12px !important;
  }

  .sm\:pl-10px-i {
    padding-left: 10px !important;
  }

  .sm\:pl-8px-i {
    padding-left: 8px !important;
  }

  .sm\:pl-6px-i {
    padding-left: 6px !important;
  }

  .sm\:pl-5px-i {
    padding-left: 5px !important;
  }

  .sm\:pl-3px-i {
    padding-left: 3px !important;
  }

  .sm\:pl-0px-i {
    padding-left: 0px !important;
  }

  .sm\:pl-34px {
    padding-left: 34px;
  }

  .sm\:pl-30px {
    padding-left: 30px;
  }

  .sm\:pl-24px {
    padding-left: 24px;
  }

  .sm\:pl-20px {
    padding-left: 20px;
  }

  .sm\:pl-15px {
    padding-left: 15px;
  }

  .sm\:pl-13px {
    padding-left: 13px;
  }

  .sm\:pl-12px {
    padding-left: 12px;
  }

  .sm\:pl-10px {
    padding-left: 10px;
  }

  .sm\:pl-8px {
    padding-left: 8px;
  }

  .sm\:pl-6px {
    padding-left: 6px;
  }

  .sm\:pl-5px {
    padding-left: 5px;
  }

  .sm\:pl-3px {
    padding-left: 3px;
  }

  .sm\:pl-menu {
    padding-left: 275px;
  }

  .sm\:pl-px {
    padding-left: 1px !important;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:text-justify {
    text-align: justify;
  }

  .sm\:align-baseline {
    vertical-align: baseline;
  }

  .sm\:align-top {
    vertical-align: top;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }

  .sm\:align-bottom {
    vertical-align: bottom;
  }

  .sm\:align-text-top {
    vertical-align: text-top;
  }

  .sm\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .sm\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .sm\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .sm\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .sm\:text-size-42 {
    font-size: 42px !important;
  }

  .sm\:text-size-32 {
    font-size: 32px !important;
  }

  .sm\:text-size-28 {
    font-size: 24px !important;
  }

  .sm\:text-size-24 {
    font-size: 24px !important;
  }

  .sm\:text-size-20 {
    font-size: 20px !important;
  }

  .sm\:text-size-18 {
    font-size: 18px !important;
  }

  .sm\:font-hairline {
    font-weight: 100;
  }

  .sm\:font-thin {
    font-weight: 200;
  }

  .sm\:font-light {
    font-weight: 300 !important;
  }

  .sm\:font-normal {
    font-weight: 400 !important;
  }

  .sm\:font-medium {
    font-weight: 500;
  }

  .sm\:font-semibold {
    font-weight: 600;
  }

  .sm\:font-bold {
    font-weight: 700 !important;
  }

  .sm\:font-extrabold {
    font-weight: 800;
  }

  .sm\:font-black {
    font-weight: 900;
  }

  .sm\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .sm\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .sm\:hover\:font-light:hover {
    font-weight: 300 !important;
  }

  .sm\:hover\:font-normal:hover {
    font-weight: 400 !important;
  }

  .sm\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .sm\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .sm\:hover\:font-bold:hover {
    font-weight: 700 !important;
  }

  .sm\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .sm\:hover\:font-black:hover {
    font-weight: 900;
  }

  .sm\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .sm\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .sm\:focus\:font-light:focus {
    font-weight: 300 !important;
  }

  .sm\:focus\:font-normal:focus {
    font-weight: 400 !important;
  }

  .sm\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .sm\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .sm\:focus\:font-bold:focus {
    font-weight: 700 !important;
  }

  .sm\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .sm\:focus\:font-black:focus {
    font-weight: 900;
  }

  .sm\:uppercase {
    text-transform: uppercase;
  }

  .sm\:lowercase {
    text-transform: lowercase;
  }

  .sm\:capitalize {
    text-transform: capitalize;
  }

  .sm\:normal-case {
    text-transform: none;
  }

  .sm\:italic {
    font-style: italic;
  }

  .sm\:not-italic {
    font-style: normal;
  }

  .sm\:ordinal, .sm\:slashed-zero, .sm\:lining-nums, .sm\:oldstyle-nums, .sm\:proportional-nums, .sm\:tabular-nums, .sm\:diagonal-fractions, .sm\:stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .sm\:normal-nums {
    font-variant-numeric: normal;
  }

  .sm\:ordinal {
    --tw-ordinal: ordinal;
  }

  .sm\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .sm\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .sm\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .sm\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .sm\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .sm\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .sm\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .sm\:leading-size-54 {
    line-height: 54px !important;
  }

  .sm\:leading-size-40 {
    line-height: 40px !important;
  }

  .sm\:leading-size-32 {
    line-height: 32px !important;
  }

  .sm\:leading-size-28 {
    line-height: 28px !important;
  }

  .sm\:leading-size-24 {
    line-height: 24px !important;
  }

  .sm\:tracking-tightest {
    letter-spacing: -0.1em;
  }

  .sm\:tracking-tighter {
    letter-spacing: -0.05em;
  }

  .sm\:tracking-tight {
    letter-spacing: -0.025em;
  }

  .sm\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .sm\:tracking-wide {
    letter-spacing: 0.025em;
  }

  .sm\:tracking-wider {
    letter-spacing: 0.05em;
  }

  .sm\:tracking-widest {
    letter-spacing: 0.1em;
  }

  .sm\:text-transparent {
    color: transparent;
  }

  .sm\:text-current {
    color: currentColor;
  }

  .sm\:text-black {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .sm\:text-black40pct {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .sm\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:text-petroleum {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .sm\:text-petroleum70pct {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .sm\:text-petroleum30pct {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .sm\:text-disabled {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .sm\:text-petroleum10pct {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .sm\:text-petroleum5pct {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .sm\:text-darkGreen {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .sm\:text-ok {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .sm\:text-green {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .sm\:text-green10pct {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .sm\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .sm\:text-darkOrange {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .sm\:text-lightOrange {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .sm\:text-error {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .sm\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .sm\:text-darkYellow {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .sm\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .sm\:text-lightGray {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .sm\:text-borderGray {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .sm\:hover\:text-transparent:hover {
    color: transparent;
  }

  .sm\:hover\:text-current:hover {
    color: currentColor;
  }

  .sm\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .sm\:hover\:text-black40pct:hover {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .sm\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:hover\:text-petroleum:hover {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .sm\:hover\:text-petroleum70pct:hover {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .sm\:hover\:text-petroleum30pct:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .sm\:hover\:text-disabled:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .sm\:hover\:text-petroleum10pct:hover {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .sm\:hover\:text-petroleum5pct:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .sm\:hover\:text-darkGreen:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .sm\:hover\:text-ok:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .sm\:hover\:text-green:hover {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .sm\:hover\:text-green10pct:hover {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .sm\:hover\:text-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .sm\:hover\:text-darkOrange:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .sm\:hover\:text-lightOrange:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .sm\:hover\:text-error:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .sm\:hover\:text-yellow:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .sm\:hover\:text-darkYellow:hover {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .sm\:hover\:text-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .sm\:hover\:text-lightGray:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .sm\:hover\:text-borderGray:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .sm\:focus\:text-transparent:focus {
    color: transparent;
  }

  .sm\:focus\:text-current:focus {
    color: currentColor;
  }

  .sm\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .sm\:focus\:text-black40pct:focus {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .sm\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:focus\:text-petroleum:focus {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .sm\:focus\:text-petroleum70pct:focus {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .sm\:focus\:text-petroleum30pct:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .sm\:focus\:text-disabled:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .sm\:focus\:text-petroleum10pct:focus {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .sm\:focus\:text-petroleum5pct:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .sm\:focus\:text-darkGreen:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .sm\:focus\:text-ok:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .sm\:focus\:text-green:focus {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .sm\:focus\:text-green10pct:focus {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .sm\:focus\:text-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .sm\:focus\:text-darkOrange:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .sm\:focus\:text-lightOrange:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .sm\:focus\:text-error:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .sm\:focus\:text-yellow:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .sm\:focus\:text-darkYellow:focus {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .sm\:focus\:text-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .sm\:focus\:text-lightGray:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .sm\:focus\:text-borderGray:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .sm\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .sm\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .sm\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .sm\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .sm\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .sm\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .sm\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }

  .sm\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }

  .sm\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }

  .sm\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .sm\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .sm\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }

  .sm\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }

  .sm\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }

  .sm\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .sm\:underline {
    text-decoration: underline;
  }

  .sm\:line-through {
    text-decoration: line-through;
  }

  .sm\:no-underline {
    text-decoration: none;
  }

  .sm\:hover\:underline:hover {
    text-decoration: underline;
  }

  .sm\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .sm\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .sm\:focus\:underline:focus {
    text-decoration: underline;
  }

  .sm\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .sm\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .sm\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sm\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .sm\:placeholder-transparent::placeholder {
    color: transparent;
  }

  .sm\:placeholder-current::placeholder {
    color: currentColor;
  }

  .sm\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-black40pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-petroleum::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-petroleum70pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-petroleum30pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-disabled::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-petroleum10pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-petroleum5pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-darkGreen::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-ok::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-green10pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-darkOrange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-lightOrange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-error::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-yellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-darkYellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-lightGray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-borderGray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .sm\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .sm\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-black40pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-petroleum:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-petroleum70pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-petroleum30pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-disabled:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-petroleum10pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-petroleum5pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-darkGreen:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-ok:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-green10pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-darkOrange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-lightOrange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-error:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-yellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-darkYellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-lightGray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-placeholder-opacity));
  }

  .sm\:focus\:placeholder-borderGray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-placeholder-opacity));
  }

  .sm\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .sm\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .sm\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .sm\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .sm\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .sm\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .sm\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .sm\:opacity-0 {
    opacity: 0;
  }

  .sm\:opacity-25 {
    opacity: 0.25;
  }

  .sm\:opacity-50 {
    opacity: 0.5;
  }

  .sm\:opacity-75 {
    opacity: 0.75;
  }

  .sm\:opacity-100 {
    opacity: 1;
  }

  .sm\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .sm\:hover\:opacity-25:hover {
    opacity: 0.25;
  }

  .sm\:hover\:opacity-50:hover {
    opacity: 0.5;
  }

  .sm\:hover\:opacity-75:hover {
    opacity: 0.75;
  }

  .sm\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .sm\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .sm\:focus\:opacity-25:focus {
    opacity: 0.25;
  }

  .sm\:focus\:opacity-50:focus {
    opacity: 0.5;
  }

  .sm\:focus\:opacity-75:focus {
    opacity: 0.75;
  }

  .sm\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .sm\:shadow-xs {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-default {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-outline {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-xs:hover {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-default:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-outline:hover {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-xs:focus {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-default:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-outline:focus {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .sm\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .sm\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .sm\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .sm\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .sm\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .sm\:transition-none {
    transition-property: none;
  }

  .sm\:transition-all {
    transition-property: all;
  }

  .sm\:transition-default {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .sm\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .sm\:transition-opacity {
    transition-property: opacity;
  }

  .sm\:transition-shadow {
    transition-property: box-shadow;
  }

  .sm\:transition-transform {
    transition-property: transform;
  }

  .sm\:delay-75 {
    transition-delay: 75ms;
  }

  .sm\:delay-100 {
    transition-delay: 100ms;
  }

  .sm\:delay-150 {
    transition-delay: 150ms;
  }

  .sm\:delay-200 {
    transition-delay: 200ms;
  }

  .sm\:delay-300 {
    transition-delay: 300ms;
  }

  .sm\:delay-500 {
    transition-delay: 500ms;
  }

  .sm\:delay-700 {
    transition-delay: 700ms;
  }

  .sm\:delay-1000 {
    transition-delay: 1000ms;
  }

  .sm\:duration-75 {
    transition-duration: 75ms;
  }

  .sm\:duration-100 {
    transition-duration: 100ms;
  }

  .sm\:duration-150 {
    transition-duration: 150ms;
  }

  .sm\:duration-200 {
    transition-duration: 200ms;
  }

  .sm\:duration-300 {
    transition-duration: 300ms;
  }

  .sm\:duration-500 {
    transition-duration: 500ms;
  }

  .sm\:duration-700 {
    transition-duration: 700ms;
  }

  .sm\:duration-1000 {
    transition-duration: 1000ms;
  }

  .sm\:ease-linear {
    transition-timing-function: linear;
  }

  .sm\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .sm\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .sm\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (min-width: 1024px) {
  .md\:container {
    width: 100%;
  }

  @media (min-width: 1px) {
    .md\:container {
      max-width: 1px;
    }
  }

  @media (min-width: 768px) {
    .md\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .md\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1440px) {
    .md\:container {
      max-width: 1440px;
    }
  }

  @media (min-width: 1920px) {
    .md\:container {
      max-width: 1920px;
    }
  }

  .md\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .md\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .md\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .md\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .md\:pointer-events-none {
    pointer-events: none;
  }

  .md\:pointer-events-auto {
    pointer-events: auto;
  }

  .md\:visible {
    visibility: visible;
  }

  .md\:invisible {
    visibility: hidden;
  }

  .md\:static {
    position: static;
  }

  .md\:fixed {
    position: fixed;
  }

  .md\:absolute {
    position: absolute;
  }

  .md\:relative {
    position: relative;
  }

  .md\:sticky {
    position: sticky;
  }

  .md\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .md\:inset-60px {
    top: 60px;
    right: 60px;
    bottom: 60px;
    left: 60px;
  }

  .md\:inset-80px {
    top: 80px;
    right: 80px;
    bottom: 80px;
    left: 80px;
  }

  .md\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .md\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .md\:inset-x-60px {
    left: 60px;
    right: 60px;
  }

  .md\:inset-x-80px {
    left: 80px;
    right: 80px;
  }

  .md\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .md\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .md\:inset-y-60px {
    top: 60px;
    bottom: 60px;
  }

  .md\:inset-y-80px {
    top: 80px;
    bottom: 80px;
  }

  .md\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:top-60px {
    top: 60px;
  }

  .md\:top-80px {
    top: 80px;
  }

  .md\:top-auto {
    top: auto;
  }

  .md\:right-0 {
    right: 0;
  }

  .md\:right-60px {
    right: 60px;
  }

  .md\:right-80px {
    right: 80px;
  }

  .md\:right-auto {
    right: auto;
  }

  .md\:bottom-0 {
    bottom: 0;
  }

  .md\:bottom-60px {
    bottom: 60px;
  }

  .md\:bottom-80px {
    bottom: 80px;
  }

  .md\:bottom-auto {
    bottom: auto;
  }

  .md\:left-0 {
    left: 0;
  }

  .md\:left-60px {
    left: 60px;
  }

  .md\:left-80px {
    left: 80px;
  }

  .md\:left-auto {
    left: auto;
  }

  .md\:z-0 {
    z-index: 0 !important;
  }

  .md\:z-10 {
    z-index: 10 !important;
  }

  .md\:z-20 {
    z-index: 20;
  }

  .md\:z-30 {
    z-index: 30;
  }

  .md\:z-40 {
    z-index: 40;
  }

  .md\:z-50 {
    z-index: 50;
  }

  .md\:z-auto {
    z-index: auto !important;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:order-3 {
    order: 3;
  }

  .md\:order-4 {
    order: 4;
  }

  .md\:order-5 {
    order: 5;
  }

  .md\:order-6 {
    order: 6;
  }

  .md\:order-7 {
    order: 7;
  }

  .md\:order-8 {
    order: 8;
  }

  .md\:order-9 {
    order: 9;
  }

  .md\:order-10 {
    order: 10;
  }

  .md\:order-11 {
    order: 11;
  }

  .md\:order-12 {
    order: 12;
  }

  .md\:order-first {
    order: -9999;
  }

  .md\:order-last {
    order: 9999;
  }

  .md\:order-none {
    order: 0;
  }

  .md\:col-auto {
    grid-column: auto;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:col-span-full {
    grid-column: 1 / -1;
  }

  .md\:col-start-1 {
    grid-column-start: 1;
  }

  .md\:col-start-2 {
    grid-column-start: 2;
  }

  .md\:col-start-3 {
    grid-column-start: 3;
  }

  .md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:col-start-5 {
    grid-column-start: 5;
  }

  .md\:col-start-6 {
    grid-column-start: 6;
  }

  .md\:col-start-7 {
    grid-column-start: 7;
  }

  .md\:col-start-8 {
    grid-column-start: 8;
  }

  .md\:col-start-9 {
    grid-column-start: 9;
  }

  .md\:col-start-10 {
    grid-column-start: 10;
  }

  .md\:col-start-11 {
    grid-column-start: 11;
  }

  .md\:col-start-12 {
    grid-column-start: 12;
  }

  .md\:col-start-13 {
    grid-column-start: 13;
  }

  .md\:col-start-auto {
    grid-column-start: auto;
  }

  .md\:col-end-1 {
    grid-column-end: 1;
  }

  .md\:col-end-2 {
    grid-column-end: 2;
  }

  .md\:col-end-3 {
    grid-column-end: 3;
  }

  .md\:col-end-4 {
    grid-column-end: 4;
  }

  .md\:col-end-5 {
    grid-column-end: 5;
  }

  .md\:col-end-6 {
    grid-column-end: 6;
  }

  .md\:col-end-7 {
    grid-column-end: 7;
  }

  .md\:col-end-8 {
    grid-column-end: 8;
  }

  .md\:col-end-9 {
    grid-column-end: 9;
  }

  .md\:col-end-10 {
    grid-column-end: 10;
  }

  .md\:col-end-11 {
    grid-column-end: 11;
  }

  .md\:col-end-12 {
    grid-column-end: 12;
  }

  .md\:col-end-13 {
    grid-column-end: 13;
  }

  .md\:col-end-auto {
    grid-column-end: auto;
  }

  .md\:row-auto {
    grid-row: auto;
  }

  .md\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .md\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .md\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .md\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .md\:row-span-full {
    grid-row: 1 / -1;
  }

  .md\:row-start-1 {
    grid-row-start: 1;
  }

  .md\:row-start-2 {
    grid-row-start: 2;
  }

  .md\:row-start-3 {
    grid-row-start: 3;
  }

  .md\:row-start-4 {
    grid-row-start: 4;
  }

  .md\:row-start-5 {
    grid-row-start: 5;
  }

  .md\:row-start-6 {
    grid-row-start: 6;
  }

  .md\:row-start-7 {
    grid-row-start: 7;
  }

  .md\:row-start-auto {
    grid-row-start: auto;
  }

  .md\:row-end-1 {
    grid-row-end: 1;
  }

  .md\:row-end-2 {
    grid-row-end: 2;
  }

  .md\:row-end-3 {
    grid-row-end: 3;
  }

  .md\:row-end-4 {
    grid-row-end: 4;
  }

  .md\:row-end-5 {
    grid-row-end: 5;
  }

  .md\:row-end-6 {
    grid-row-end: 6;
  }

  .md\:row-end-7 {
    grid-row-end: 7;
  }

  .md\:row-end-auto {
    grid-row-end: auto;
  }

  .md\:float-right {
    float: right;
  }

  .md\:float-left {
    float: left;
  }

  .md\:float-none {
    float: none;
  }

  .md\:clear-left {
    clear: left;
  }

  .md\:clear-right {
    clear: right;
  }

  .md\:clear-both {
    clear: both;
  }

  .md\:clear-none {
    clear: none;
  }

  .md\:m-0 {
    margin: 0;
  }

  .md\:m-1 {
    margin: 0.25rem;
  }

  .md\:m-2 {
    margin: 0.5rem;
  }

  .md\:m-3 {
    margin: 0.75rem;
  }

  .md\:m-4 {
    margin: 1rem;
  }

  .md\:m-5 {
    margin: 1.25rem;
  }

  .md\:m-6 {
    margin: 1.5rem;
  }

  .md\:m-8 {
    margin: 2rem;
  }

  .md\:m-10 {
    margin: 2.5rem;
  }

  .md\:m-12 {
    margin: 3rem;
  }

  .md\:m-16 {
    margin: 4rem;
  }

  .md\:m-20 {
    margin: 5rem;
  }

  .md\:m-24 {
    margin: 6rem;
  }

  .md\:m-32 {
    margin: 8rem;
  }

  .md\:m-40 {
    margin: 10rem;
  }

  .md\:m-48 {
    margin: 12rem;
  }

  .md\:m-56 {
    margin: 14rem;
  }

  .md\:m-64 {
    margin: 16rem;
  }

  .md\:m-auto {
    margin: auto;
  }

  .md\:m-300px {
    margin: 300px;
  }

  .md\:m-230px {
    margin: 230px;
  }

  .md\:m-180px {
    margin: 180px;
  }

  .md\:m-147px {
    margin: 147px;
  }

  .md\:m-123px {
    margin: 123px;
  }

  .md\:m-100px {
    margin: 100px;
  }

  .md\:m-80px {
    margin: 80px;
  }

  .md\:m-60px {
    margin: 60px;
  }

  .md\:m-50px {
    margin: 50px;
  }

  .md\:m-40px {
    margin: 40px;
  }

  .md\:m-35px {
    margin: 35px;
  }

  .md\:m-34px-i {
    margin: 34px !important;
  }

  .md\:m-30px-i {
    margin: 30px !important;
  }

  .md\:m-24px-i {
    margin: 24px !important;
  }

  .md\:m-20px-i {
    margin: 20px !important;
  }

  .md\:m-15px-i {
    margin: 15px !important;
  }

  .md\:m-13px-i {
    margin: 13px !important;
  }

  .md\:m-12px-i {
    margin: 12px !important;
  }

  .md\:m-10px-i {
    margin: 10px !important;
  }

  .md\:m-8px-i {
    margin: 8px !important;
  }

  .md\:m-6px-i {
    margin: 6px !important;
  }

  .md\:m-5px-i {
    margin: 5px !important;
  }

  .md\:m-3px-i {
    margin: 3px !important;
  }

  .md\:m-0px-i {
    margin: 0px !important;
  }

  .md\:m-34px {
    margin: 34px;
  }

  .md\:m-30px {
    margin: 30px;
  }

  .md\:m-24px {
    margin: 24px;
  }

  .md\:m-20px {
    margin: 20px;
  }

  .md\:m-15px {
    margin: 15px;
  }

  .md\:m-13px {
    margin: 13px;
  }

  .md\:m-12px {
    margin: 12px;
  }

  .md\:m-10px {
    margin: 10px;
  }

  .md\:m-8px {
    margin: 8px;
  }

  .md\:m-6px {
    margin: 6px;
  }

  .md\:m-5px {
    margin: 5px;
  }

  .md\:m-3px {
    margin: 3px;
  }

  .md\:m-menu {
    margin: 275px;
  }

  .md\:m-px {
    margin: 1px !important;
  }

  .md\:-m-1 {
    margin: -0.25rem;
  }

  .md\:-m-2 {
    margin: -0.5rem;
  }

  .md\:-m-3 {
    margin: -0.75rem;
  }

  .md\:-m-4 {
    margin: -1rem;
  }

  .md\:-m-5 {
    margin: -1.25rem;
  }

  .md\:-m-6 {
    margin: -1.5rem;
  }

  .md\:-m-8 {
    margin: -2rem;
  }

  .md\:-m-10 {
    margin: -2.5rem;
  }

  .md\:-m-12 {
    margin: -3rem;
  }

  .md\:-m-16 {
    margin: -4rem;
  }

  .md\:-m-20 {
    margin: -5rem;
  }

  .md\:-m-24 {
    margin: -6rem;
  }

  .md\:-m-32 {
    margin: -8rem;
  }

  .md\:-m-40 {
    margin: -10rem;
  }

  .md\:-m-48 {
    margin: -12rem;
  }

  .md\:-m-56 {
    margin: -14rem;
  }

  .md\:-m-64 {
    margin: -16rem;
  }

  .md\:-m-300px {
    margin: -300px;
  }

  .md\:-m-230px {
    margin: -230px;
  }

  .md\:-m-180px {
    margin: -180px;
  }

  .md\:-m-147px {
    margin: -147px;
  }

  .md\:-m-123px {
    margin: -123px;
  }

  .md\:-m-100px {
    margin: -100px;
  }

  .md\:-m-80px {
    margin: -80px;
  }

  .md\:-m-60px {
    margin: -60px;
  }

  .md\:-m-50px {
    margin: -50px;
  }

  .md\:-m-40px {
    margin: -40px;
  }

  .md\:-m-35px {
    margin: -35px;
  }

  .md\:-m-34px-i {
    margin: 34px !important;
  }

  .md\:-m-30px-i {
    margin: 30px !important;
  }

  .md\:-m-24px-i {
    margin: 24px !important;
  }

  .md\:-m-20px-i {
    margin: 20px !important;
  }

  .md\:-m-15px-i {
    margin: 15px !important;
  }

  .md\:-m-13px-i {
    margin: 13px !important;
  }

  .md\:-m-12px-i {
    margin: 12px !important;
  }

  .md\:-m-10px-i {
    margin: 10px !important;
  }

  .md\:-m-8px-i {
    margin: 8px !important;
  }

  .md\:-m-6px-i {
    margin: 6px !important;
  }

  .md\:-m-5px-i {
    margin: 5px !important;
  }

  .md\:-m-3px-i {
    margin: 3px !important;
  }

  .md\:-m-0px-i {
    margin: 0px !important;
  }

  .md\:-m-34px {
    margin: -34px;
  }

  .md\:-m-30px {
    margin: -30px;
  }

  .md\:-m-24px {
    margin: -24px;
  }

  .md\:-m-20px {
    margin: -20px;
  }

  .md\:-m-15px {
    margin: -15px;
  }

  .md\:-m-13px {
    margin: -13px;
  }

  .md\:-m-12px {
    margin: -12px;
  }

  .md\:-m-10px {
    margin: -10px;
  }

  .md\:-m-8px {
    margin: -8px;
  }

  .md\:-m-6px {
    margin: -6px;
  }

  .md\:-m-5px {
    margin: -5px;
  }

  .md\:-m-3px {
    margin: -3px;
  }

  .md\:-m-menu {
    margin: -275px;
  }

  .md\:-m-px {
    margin: 1px !important;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .md\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .md\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .md\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .md\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .md\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .md\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .md\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .md\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .md\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .md\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:mx-300px {
    margin-left: 300px;
    margin-right: 300px;
  }

  .md\:mx-230px {
    margin-left: 230px;
    margin-right: 230px;
  }

  .md\:mx-180px {
    margin-left: 180px;
    margin-right: 180px;
  }

  .md\:mx-147px {
    margin-left: 147px;
    margin-right: 147px;
  }

  .md\:mx-123px {
    margin-left: 123px;
    margin-right: 123px;
  }

  .md\:mx-100px {
    margin-left: 100px;
    margin-right: 100px;
  }

  .md\:mx-80px {
    margin-left: 80px;
    margin-right: 80px;
  }

  .md\:mx-60px {
    margin-left: 60px;
    margin-right: 60px;
  }

  .md\:mx-50px {
    margin-left: 50px;
    margin-right: 50px;
  }

  .md\:mx-40px {
    margin-left: 40px;
    margin-right: 40px;
  }

  .md\:mx-35px {
    margin-left: 35px;
    margin-right: 35px;
  }

  .md\:mx-34px-i {
    margin-left: 34px !important;
    margin-right: 34px !important;
  }

  .md\:mx-30px-i {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .md\:mx-24px-i {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .md\:mx-20px-i {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .md\:mx-15px-i {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .md\:mx-13px-i {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }

  .md\:mx-12px-i {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .md\:mx-10px-i {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .md\:mx-8px-i {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .md\:mx-6px-i {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .md\:mx-5px-i {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .md\:mx-3px-i {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .md\:mx-0px-i {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .md\:mx-34px {
    margin-left: 34px;
    margin-right: 34px;
  }

  .md\:mx-30px {
    margin-left: 30px;
    margin-right: 30px;
  }

  .md\:mx-24px {
    margin-left: 24px;
    margin-right: 24px;
  }

  .md\:mx-20px {
    margin-left: 20px;
    margin-right: 20px;
  }

  .md\:mx-15px {
    margin-left: 15px;
    margin-right: 15px;
  }

  .md\:mx-13px {
    margin-left: 13px;
    margin-right: 13px;
  }

  .md\:mx-12px {
    margin-left: 12px;
    margin-right: 12px;
  }

  .md\:mx-10px {
    margin-left: 10px;
    margin-right: 10px;
  }

  .md\:mx-8px {
    margin-left: 8px;
    margin-right: 8px;
  }

  .md\:mx-6px {
    margin-left: 6px;
    margin-right: 6px;
  }

  .md\:mx-5px {
    margin-left: 5px;
    margin-right: 5px;
  }

  .md\:mx-3px {
    margin-left: 3px;
    margin-right: 3px;
  }

  .md\:mx-menu {
    margin-left: 275px;
    margin-right: 275px;
  }

  .md\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .md\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .md\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .md\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .md\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .md\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .md\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .md\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .md\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .md\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .md\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .md\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .md\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .md\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .md\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .md\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .md\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .md\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .md\:-mx-300px {
    margin-left: -300px;
    margin-right: -300px;
  }

  .md\:-mx-230px {
    margin-left: -230px;
    margin-right: -230px;
  }

  .md\:-mx-180px {
    margin-left: -180px;
    margin-right: -180px;
  }

  .md\:-mx-147px {
    margin-left: -147px;
    margin-right: -147px;
  }

  .md\:-mx-123px {
    margin-left: -123px;
    margin-right: -123px;
  }

  .md\:-mx-100px {
    margin-left: -100px;
    margin-right: -100px;
  }

  .md\:-mx-80px {
    margin-left: -80px;
    margin-right: -80px;
  }

  .md\:-mx-60px {
    margin-left: -60px;
    margin-right: -60px;
  }

  .md\:-mx-50px {
    margin-left: -50px;
    margin-right: -50px;
  }

  .md\:-mx-40px {
    margin-left: -40px;
    margin-right: -40px;
  }

  .md\:-mx-35px {
    margin-left: -35px;
    margin-right: -35px;
  }

  .md\:-mx-34px-i {
    margin-left: 34px !important;
    margin-right: 34px !important;
  }

  .md\:-mx-30px-i {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .md\:-mx-24px-i {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .md\:-mx-20px-i {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .md\:-mx-15px-i {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .md\:-mx-13px-i {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }

  .md\:-mx-12px-i {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .md\:-mx-10px-i {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .md\:-mx-8px-i {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .md\:-mx-6px-i {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .md\:-mx-5px-i {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .md\:-mx-3px-i {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .md\:-mx-0px-i {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .md\:-mx-34px {
    margin-left: -34px;
    margin-right: -34px;
  }

  .md\:-mx-30px {
    margin-left: -30px;
    margin-right: -30px;
  }

  .md\:-mx-24px {
    margin-left: -24px;
    margin-right: -24px;
  }

  .md\:-mx-20px {
    margin-left: -20px;
    margin-right: -20px;
  }

  .md\:-mx-15px {
    margin-left: -15px;
    margin-right: -15px;
  }

  .md\:-mx-13px {
    margin-left: -13px;
    margin-right: -13px;
  }

  .md\:-mx-12px {
    margin-left: -12px;
    margin-right: -12px;
  }

  .md\:-mx-10px {
    margin-left: -10px;
    margin-right: -10px;
  }

  .md\:-mx-8px {
    margin-left: -8px;
    margin-right: -8px;
  }

  .md\:-mx-6px {
    margin-left: -6px;
    margin-right: -6px;
  }

  .md\:-mx-5px {
    margin-left: -5px;
    margin-right: -5px;
  }

  .md\:-mx-3px {
    margin-left: -3px;
    margin-right: -3px;
  }

  .md\:-mx-menu {
    margin-left: -275px;
    margin-right: -275px;
  }

  .md\:-mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .md\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .md\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .md\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .md\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .md\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .md\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .md\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .md\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .md\:my-300px {
    margin-top: 300px;
    margin-bottom: 300px;
  }

  .md\:my-230px {
    margin-top: 230px;
    margin-bottom: 230px;
  }

  .md\:my-180px {
    margin-top: 180px;
    margin-bottom: 180px;
  }

  .md\:my-147px {
    margin-top: 147px;
    margin-bottom: 147px;
  }

  .md\:my-123px {
    margin-top: 123px;
    margin-bottom: 123px;
  }

  .md\:my-100px {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .md\:my-80px {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .md\:my-60px {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .md\:my-50px {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .md\:my-40px {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .md\:my-35px {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .md\:my-34px-i {
    margin-top: 34px !important;
    margin-bottom: 34px !important;
  }

  .md\:my-30px-i {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .md\:my-24px-i {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .md\:my-20px-i {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .md\:my-15px-i {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .md\:my-13px-i {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }

  .md\:my-12px-i {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .md\:my-10px-i {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .md\:my-8px-i {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .md\:my-6px-i {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .md\:my-5px-i {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .md\:my-3px-i {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .md\:my-0px-i {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .md\:my-34px {
    margin-top: 34px;
    margin-bottom: 34px;
  }

  .md\:my-30px {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .md\:my-24px {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .md\:my-20px {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .md\:my-15px {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .md\:my-13px {
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .md\:my-12px {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .md\:my-10px {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .md\:my-8px {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .md\:my-6px {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .md\:my-5px {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .md\:my-3px {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .md\:my-menu {
    margin-top: 275px;
    margin-bottom: 275px;
  }

  .md\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .md\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .md\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .md\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .md\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .md\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .md\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .md\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .md\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .md\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .md\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .md\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .md\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .md\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .md\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .md\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .md\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .md\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .md\:-my-300px {
    margin-top: -300px;
    margin-bottom: -300px;
  }

  .md\:-my-230px {
    margin-top: -230px;
    margin-bottom: -230px;
  }

  .md\:-my-180px {
    margin-top: -180px;
    margin-bottom: -180px;
  }

  .md\:-my-147px {
    margin-top: -147px;
    margin-bottom: -147px;
  }

  .md\:-my-123px {
    margin-top: -123px;
    margin-bottom: -123px;
  }

  .md\:-my-100px {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .md\:-my-80px {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .md\:-my-60px {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .md\:-my-50px {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .md\:-my-40px {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .md\:-my-35px {
    margin-top: -35px;
    margin-bottom: -35px;
  }

  .md\:-my-34px-i {
    margin-top: 34px !important;
    margin-bottom: 34px !important;
  }

  .md\:-my-30px-i {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .md\:-my-24px-i {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .md\:-my-20px-i {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .md\:-my-15px-i {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .md\:-my-13px-i {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }

  .md\:-my-12px-i {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .md\:-my-10px-i {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .md\:-my-8px-i {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .md\:-my-6px-i {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .md\:-my-5px-i {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .md\:-my-3px-i {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .md\:-my-0px-i {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .md\:-my-34px {
    margin-top: -34px;
    margin-bottom: -34px;
  }

  .md\:-my-30px {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .md\:-my-24px {
    margin-top: -24px;
    margin-bottom: -24px;
  }

  .md\:-my-20px {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .md\:-my-15px {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .md\:-my-13px {
    margin-top: -13px;
    margin-bottom: -13px;
  }

  .md\:-my-12px {
    margin-top: -12px;
    margin-bottom: -12px;
  }

  .md\:-my-10px {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .md\:-my-8px {
    margin-top: -8px;
    margin-bottom: -8px;
  }

  .md\:-my-6px {
    margin-top: -6px;
    margin-bottom: -6px;
  }

  .md\:-my-5px {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .md\:-my-3px {
    margin-top: -3px;
    margin-bottom: -3px;
  }

  .md\:-my-menu {
    margin-top: -275px;
    margin-bottom: -275px;
  }

  .md\:-my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-1 {
    margin-top: 0.25rem;
  }

  .md\:mt-2 {
    margin-top: 0.5rem;
  }

  .md\:mt-3 {
    margin-top: 0.75rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mt-5 {
    margin-top: 1.25rem;
  }

  .md\:mt-6 {
    margin-top: 1.5rem;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mt-12 {
    margin-top: 3rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:mt-24 {
    margin-top: 6rem;
  }

  .md\:mt-32 {
    margin-top: 8rem;
  }

  .md\:mt-40 {
    margin-top: 10rem;
  }

  .md\:mt-48 {
    margin-top: 12rem;
  }

  .md\:mt-56 {
    margin-top: 14rem;
  }

  .md\:mt-64 {
    margin-top: 16rem;
  }

  .md\:mt-auto {
    margin-top: auto;
  }

  .md\:mt-300px {
    margin-top: 300px;
  }

  .md\:mt-230px {
    margin-top: 230px;
  }

  .md\:mt-180px {
    margin-top: 180px;
  }

  .md\:mt-147px {
    margin-top: 147px;
  }

  .md\:mt-123px {
    margin-top: 123px;
  }

  .md\:mt-100px {
    margin-top: 100px;
  }

  .md\:mt-80px {
    margin-top: 80px;
  }

  .md\:mt-60px {
    margin-top: 60px;
  }

  .md\:mt-50px {
    margin-top: 50px;
  }

  .md\:mt-40px {
    margin-top: 40px;
  }

  .md\:mt-35px {
    margin-top: 35px;
  }

  .md\:mt-34px-i {
    margin-top: 34px !important;
  }

  .md\:mt-30px-i {
    margin-top: 30px !important;
  }

  .md\:mt-24px-i {
    margin-top: 24px !important;
  }

  .md\:mt-20px-i {
    margin-top: 20px !important;
  }

  .md\:mt-15px-i {
    margin-top: 15px !important;
  }

  .md\:mt-13px-i {
    margin-top: 13px !important;
  }

  .md\:mt-12px-i {
    margin-top: 12px !important;
  }

  .md\:mt-10px-i {
    margin-top: 10px !important;
  }

  .md\:mt-8px-i {
    margin-top: 8px !important;
  }

  .md\:mt-6px-i {
    margin-top: 6px !important;
  }

  .md\:mt-5px-i {
    margin-top: 5px !important;
  }

  .md\:mt-3px-i {
    margin-top: 3px !important;
  }

  .md\:mt-0px-i {
    margin-top: 0px !important;
  }

  .md\:mt-34px {
    margin-top: 34px;
  }

  .md\:mt-30px {
    margin-top: 30px;
  }

  .md\:mt-24px {
    margin-top: 24px;
  }

  .md\:mt-20px {
    margin-top: 20px;
  }

  .md\:mt-15px {
    margin-top: 15px;
  }

  .md\:mt-13px {
    margin-top: 13px;
  }

  .md\:mt-12px {
    margin-top: 12px;
  }

  .md\:mt-10px {
    margin-top: 10px;
  }

  .md\:mt-8px {
    margin-top: 8px;
  }

  .md\:mt-6px {
    margin-top: 6px;
  }

  .md\:mt-5px {
    margin-top: 5px;
  }

  .md\:mt-3px {
    margin-top: 3px;
  }

  .md\:mt-menu {
    margin-top: 275px;
  }

  .md\:mt-px {
    margin-top: 1px !important;
  }

  .md\:-mt-1 {
    margin-top: -0.25rem;
  }

  .md\:-mt-2 {
    margin-top: -0.5rem;
  }

  .md\:-mt-3 {
    margin-top: -0.75rem;
  }

  .md\:-mt-4 {
    margin-top: -1rem;
  }

  .md\:-mt-5 {
    margin-top: -1.25rem;
  }

  .md\:-mt-6 {
    margin-top: -1.5rem;
  }

  .md\:-mt-8 {
    margin-top: -2rem;
  }

  .md\:-mt-10 {
    margin-top: -2.5rem;
  }

  .md\:-mt-12 {
    margin-top: -3rem;
  }

  .md\:-mt-16 {
    margin-top: -4rem;
  }

  .md\:-mt-20 {
    margin-top: -5rem;
  }

  .md\:-mt-24 {
    margin-top: -6rem;
  }

  .md\:-mt-32 {
    margin-top: -8rem;
  }

  .md\:-mt-40 {
    margin-top: -10rem;
  }

  .md\:-mt-48 {
    margin-top: -12rem;
  }

  .md\:-mt-56 {
    margin-top: -14rem;
  }

  .md\:-mt-64 {
    margin-top: -16rem;
  }

  .md\:-mt-300px {
    margin-top: -300px;
  }

  .md\:-mt-230px {
    margin-top: -230px;
  }

  .md\:-mt-180px {
    margin-top: -180px;
  }

  .md\:-mt-147px {
    margin-top: -147px;
  }

  .md\:-mt-123px {
    margin-top: -123px;
  }

  .md\:-mt-100px {
    margin-top: -100px;
  }

  .md\:-mt-80px {
    margin-top: -80px;
  }

  .md\:-mt-60px {
    margin-top: -60px;
  }

  .md\:-mt-50px {
    margin-top: -50px;
  }

  .md\:-mt-40px {
    margin-top: -40px;
  }

  .md\:-mt-35px {
    margin-top: -35px;
  }

  .md\:-mt-34px-i {
    margin-top: 34px !important;
  }

  .md\:-mt-30px-i {
    margin-top: 30px !important;
  }

  .md\:-mt-24px-i {
    margin-top: 24px !important;
  }

  .md\:-mt-20px-i {
    margin-top: 20px !important;
  }

  .md\:-mt-15px-i {
    margin-top: 15px !important;
  }

  .md\:-mt-13px-i {
    margin-top: 13px !important;
  }

  .md\:-mt-12px-i {
    margin-top: 12px !important;
  }

  .md\:-mt-10px-i {
    margin-top: 10px !important;
  }

  .md\:-mt-8px-i {
    margin-top: 8px !important;
  }

  .md\:-mt-6px-i {
    margin-top: 6px !important;
  }

  .md\:-mt-5px-i {
    margin-top: 5px !important;
  }

  .md\:-mt-3px-i {
    margin-top: 3px !important;
  }

  .md\:-mt-0px-i {
    margin-top: 0px !important;
  }

  .md\:-mt-34px {
    margin-top: -34px;
  }

  .md\:-mt-30px {
    margin-top: -30px;
  }

  .md\:-mt-24px {
    margin-top: -24px;
  }

  .md\:-mt-20px {
    margin-top: -20px;
  }

  .md\:-mt-15px {
    margin-top: -15px;
  }

  .md\:-mt-13px {
    margin-top: -13px;
  }

  .md\:-mt-12px {
    margin-top: -12px;
  }

  .md\:-mt-10px {
    margin-top: -10px;
  }

  .md\:-mt-8px {
    margin-top: -8px;
  }

  .md\:-mt-6px {
    margin-top: -6px;
  }

  .md\:-mt-5px {
    margin-top: -5px;
  }

  .md\:-mt-3px {
    margin-top: -3px;
  }

  .md\:-mt-menu {
    margin-top: -275px;
  }

  .md\:-mt-px {
    margin-top: 1px !important;
  }

  .md\:mr-0 {
    margin-right: 0;
  }

  .md\:mr-1 {
    margin-right: 0.25rem;
  }

  .md\:mr-2 {
    margin-right: 0.5rem;
  }

  .md\:mr-3 {
    margin-right: 0.75rem;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:mr-5 {
    margin-right: 1.25rem;
  }

  .md\:mr-6 {
    margin-right: 1.5rem;
  }

  .md\:mr-8 {
    margin-right: 2rem;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:mr-12 {
    margin-right: 3rem;
  }

  .md\:mr-16 {
    margin-right: 4rem;
  }

  .md\:mr-20 {
    margin-right: 5rem;
  }

  .md\:mr-24 {
    margin-right: 6rem;
  }

  .md\:mr-32 {
    margin-right: 8rem;
  }

  .md\:mr-40 {
    margin-right: 10rem;
  }

  .md\:mr-48 {
    margin-right: 12rem;
  }

  .md\:mr-56 {
    margin-right: 14rem;
  }

  .md\:mr-64 {
    margin-right: 16rem;
  }

  .md\:mr-auto {
    margin-right: auto;
  }

  .md\:mr-300px {
    margin-right: 300px;
  }

  .md\:mr-230px {
    margin-right: 230px;
  }

  .md\:mr-180px {
    margin-right: 180px;
  }

  .md\:mr-147px {
    margin-right: 147px;
  }

  .md\:mr-123px {
    margin-right: 123px;
  }

  .md\:mr-100px {
    margin-right: 100px;
  }

  .md\:mr-80px {
    margin-right: 80px;
  }

  .md\:mr-60px {
    margin-right: 60px;
  }

  .md\:mr-50px {
    margin-right: 50px;
  }

  .md\:mr-40px {
    margin-right: 40px;
  }

  .md\:mr-35px {
    margin-right: 35px;
  }

  .md\:mr-34px-i {
    margin-right: 34px !important;
  }

  .md\:mr-30px-i {
    margin-right: 30px !important;
  }

  .md\:mr-24px-i {
    margin-right: 24px !important;
  }

  .md\:mr-20px-i {
    margin-right: 20px !important;
  }

  .md\:mr-15px-i {
    margin-right: 15px !important;
  }

  .md\:mr-13px-i {
    margin-right: 13px !important;
  }

  .md\:mr-12px-i {
    margin-right: 12px !important;
  }

  .md\:mr-10px-i {
    margin-right: 10px !important;
  }

  .md\:mr-8px-i {
    margin-right: 8px !important;
  }

  .md\:mr-6px-i {
    margin-right: 6px !important;
  }

  .md\:mr-5px-i {
    margin-right: 5px !important;
  }

  .md\:mr-3px-i {
    margin-right: 3px !important;
  }

  .md\:mr-0px-i {
    margin-right: 0px !important;
  }

  .md\:mr-34px {
    margin-right: 34px;
  }

  .md\:mr-30px {
    margin-right: 30px;
  }

  .md\:mr-24px {
    margin-right: 24px;
  }

  .md\:mr-20px {
    margin-right: 20px;
  }

  .md\:mr-15px {
    margin-right: 15px;
  }

  .md\:mr-13px {
    margin-right: 13px;
  }

  .md\:mr-12px {
    margin-right: 12px;
  }

  .md\:mr-10px {
    margin-right: 10px;
  }

  .md\:mr-8px {
    margin-right: 8px;
  }

  .md\:mr-6px {
    margin-right: 6px;
  }

  .md\:mr-5px {
    margin-right: 5px;
  }

  .md\:mr-3px {
    margin-right: 3px;
  }

  .md\:mr-menu {
    margin-right: 275px;
  }

  .md\:mr-px {
    margin-right: 1px !important;
  }

  .md\:-mr-1 {
    margin-right: -0.25rem;
  }

  .md\:-mr-2 {
    margin-right: -0.5rem;
  }

  .md\:-mr-3 {
    margin-right: -0.75rem;
  }

  .md\:-mr-4 {
    margin-right: -1rem;
  }

  .md\:-mr-5 {
    margin-right: -1.25rem;
  }

  .md\:-mr-6 {
    margin-right: -1.5rem;
  }

  .md\:-mr-8 {
    margin-right: -2rem;
  }

  .md\:-mr-10 {
    margin-right: -2.5rem;
  }

  .md\:-mr-12 {
    margin-right: -3rem;
  }

  .md\:-mr-16 {
    margin-right: -4rem;
  }

  .md\:-mr-20 {
    margin-right: -5rem;
  }

  .md\:-mr-24 {
    margin-right: -6rem;
  }

  .md\:-mr-32 {
    margin-right: -8rem;
  }

  .md\:-mr-40 {
    margin-right: -10rem;
  }

  .md\:-mr-48 {
    margin-right: -12rem;
  }

  .md\:-mr-56 {
    margin-right: -14rem;
  }

  .md\:-mr-64 {
    margin-right: -16rem;
  }

  .md\:-mr-300px {
    margin-right: -300px;
  }

  .md\:-mr-230px {
    margin-right: -230px;
  }

  .md\:-mr-180px {
    margin-right: -180px;
  }

  .md\:-mr-147px {
    margin-right: -147px;
  }

  .md\:-mr-123px {
    margin-right: -123px;
  }

  .md\:-mr-100px {
    margin-right: -100px;
  }

  .md\:-mr-80px {
    margin-right: -80px;
  }

  .md\:-mr-60px {
    margin-right: -60px;
  }

  .md\:-mr-50px {
    margin-right: -50px;
  }

  .md\:-mr-40px {
    margin-right: -40px;
  }

  .md\:-mr-35px {
    margin-right: -35px;
  }

  .md\:-mr-34px-i {
    margin-right: 34px !important;
  }

  .md\:-mr-30px-i {
    margin-right: 30px !important;
  }

  .md\:-mr-24px-i {
    margin-right: 24px !important;
  }

  .md\:-mr-20px-i {
    margin-right: 20px !important;
  }

  .md\:-mr-15px-i {
    margin-right: 15px !important;
  }

  .md\:-mr-13px-i {
    margin-right: 13px !important;
  }

  .md\:-mr-12px-i {
    margin-right: 12px !important;
  }

  .md\:-mr-10px-i {
    margin-right: 10px !important;
  }

  .md\:-mr-8px-i {
    margin-right: 8px !important;
  }

  .md\:-mr-6px-i {
    margin-right: 6px !important;
  }

  .md\:-mr-5px-i {
    margin-right: 5px !important;
  }

  .md\:-mr-3px-i {
    margin-right: 3px !important;
  }

  .md\:-mr-0px-i {
    margin-right: 0px !important;
  }

  .md\:-mr-34px {
    margin-right: -34px;
  }

  .md\:-mr-30px {
    margin-right: -30px;
  }

  .md\:-mr-24px {
    margin-right: -24px;
  }

  .md\:-mr-20px {
    margin-right: -20px;
  }

  .md\:-mr-15px {
    margin-right: -15px;
  }

  .md\:-mr-13px {
    margin-right: -13px;
  }

  .md\:-mr-12px {
    margin-right: -12px;
  }

  .md\:-mr-10px {
    margin-right: -10px;
  }

  .md\:-mr-8px {
    margin-right: -8px;
  }

  .md\:-mr-6px {
    margin-right: -6px;
  }

  .md\:-mr-5px {
    margin-right: -5px;
  }

  .md\:-mr-3px {
    margin-right: -3px;
  }

  .md\:-mr-menu {
    margin-right: -275px;
  }

  .md\:-mr-px {
    margin-right: 1px !important;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-1 {
    margin-bottom: 0.25rem;
  }

  .md\:mb-2 {
    margin-bottom: 0.5rem;
  }

  .md\:mb-3 {
    margin-bottom: 0.75rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:mb-24 {
    margin-bottom: 6rem;
  }

  .md\:mb-32 {
    margin-bottom: 8rem;
  }

  .md\:mb-40 {
    margin-bottom: 10rem;
  }

  .md\:mb-48 {
    margin-bottom: 12rem;
  }

  .md\:mb-56 {
    margin-bottom: 14rem;
  }

  .md\:mb-64 {
    margin-bottom: 16rem;
  }

  .md\:mb-auto {
    margin-bottom: auto;
  }

  .md\:mb-300px {
    margin-bottom: 300px;
  }

  .md\:mb-230px {
    margin-bottom: 230px;
  }

  .md\:mb-180px {
    margin-bottom: 180px;
  }

  .md\:mb-147px {
    margin-bottom: 147px;
  }

  .md\:mb-123px {
    margin-bottom: 123px;
  }

  .md\:mb-100px {
    margin-bottom: 100px;
  }

  .md\:mb-80px {
    margin-bottom: 80px;
  }

  .md\:mb-60px {
    margin-bottom: 60px;
  }

  .md\:mb-50px {
    margin-bottom: 50px;
  }

  .md\:mb-40px {
    margin-bottom: 40px;
  }

  .md\:mb-35px {
    margin-bottom: 35px;
  }

  .md\:mb-34px-i {
    margin-bottom: 34px !important;
  }

  .md\:mb-30px-i {
    margin-bottom: 30px !important;
  }

  .md\:mb-24px-i {
    margin-bottom: 24px !important;
  }

  .md\:mb-20px-i {
    margin-bottom: 20px !important;
  }

  .md\:mb-15px-i {
    margin-bottom: 15px !important;
  }

  .md\:mb-13px-i {
    margin-bottom: 13px !important;
  }

  .md\:mb-12px-i {
    margin-bottom: 12px !important;
  }

  .md\:mb-10px-i {
    margin-bottom: 10px !important;
  }

  .md\:mb-8px-i {
    margin-bottom: 8px !important;
  }

  .md\:mb-6px-i {
    margin-bottom: 6px !important;
  }

  .md\:mb-5px-i {
    margin-bottom: 5px !important;
  }

  .md\:mb-3px-i {
    margin-bottom: 3px !important;
  }

  .md\:mb-0px-i {
    margin-bottom: 0px !important;
  }

  .md\:mb-34px {
    margin-bottom: 34px;
  }

  .md\:mb-30px {
    margin-bottom: 30px;
  }

  .md\:mb-24px {
    margin-bottom: 24px;
  }

  .md\:mb-20px {
    margin-bottom: 20px;
  }

  .md\:mb-15px {
    margin-bottom: 15px;
  }

  .md\:mb-13px {
    margin-bottom: 13px;
  }

  .md\:mb-12px {
    margin-bottom: 12px;
  }

  .md\:mb-10px {
    margin-bottom: 10px;
  }

  .md\:mb-8px {
    margin-bottom: 8px;
  }

  .md\:mb-6px {
    margin-bottom: 6px;
  }

  .md\:mb-5px {
    margin-bottom: 5px;
  }

  .md\:mb-3px {
    margin-bottom: 3px;
  }

  .md\:mb-menu {
    margin-bottom: 275px;
  }

  .md\:mb-px {
    margin-bottom: 1px !important;
  }

  .md\:-mb-1 {
    margin-bottom: -0.25rem;
  }

  .md\:-mb-2 {
    margin-bottom: -0.5rem;
  }

  .md\:-mb-3 {
    margin-bottom: -0.75rem;
  }

  .md\:-mb-4 {
    margin-bottom: -1rem;
  }

  .md\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .md\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .md\:-mb-8 {
    margin-bottom: -2rem;
  }

  .md\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .md\:-mb-12 {
    margin-bottom: -3rem;
  }

  .md\:-mb-16 {
    margin-bottom: -4rem;
  }

  .md\:-mb-20 {
    margin-bottom: -5rem;
  }

  .md\:-mb-24 {
    margin-bottom: -6rem;
  }

  .md\:-mb-32 {
    margin-bottom: -8rem;
  }

  .md\:-mb-40 {
    margin-bottom: -10rem;
  }

  .md\:-mb-48 {
    margin-bottom: -12rem;
  }

  .md\:-mb-56 {
    margin-bottom: -14rem;
  }

  .md\:-mb-64 {
    margin-bottom: -16rem;
  }

  .md\:-mb-300px {
    margin-bottom: -300px;
  }

  .md\:-mb-230px {
    margin-bottom: -230px;
  }

  .md\:-mb-180px {
    margin-bottom: -180px;
  }

  .md\:-mb-147px {
    margin-bottom: -147px;
  }

  .md\:-mb-123px {
    margin-bottom: -123px;
  }

  .md\:-mb-100px {
    margin-bottom: -100px;
  }

  .md\:-mb-80px {
    margin-bottom: -80px;
  }

  .md\:-mb-60px {
    margin-bottom: -60px;
  }

  .md\:-mb-50px {
    margin-bottom: -50px;
  }

  .md\:-mb-40px {
    margin-bottom: -40px;
  }

  .md\:-mb-35px {
    margin-bottom: -35px;
  }

  .md\:-mb-34px-i {
    margin-bottom: 34px !important;
  }

  .md\:-mb-30px-i {
    margin-bottom: 30px !important;
  }

  .md\:-mb-24px-i {
    margin-bottom: 24px !important;
  }

  .md\:-mb-20px-i {
    margin-bottom: 20px !important;
  }

  .md\:-mb-15px-i {
    margin-bottom: 15px !important;
  }

  .md\:-mb-13px-i {
    margin-bottom: 13px !important;
  }

  .md\:-mb-12px-i {
    margin-bottom: 12px !important;
  }

  .md\:-mb-10px-i {
    margin-bottom: 10px !important;
  }

  .md\:-mb-8px-i {
    margin-bottom: 8px !important;
  }

  .md\:-mb-6px-i {
    margin-bottom: 6px !important;
  }

  .md\:-mb-5px-i {
    margin-bottom: 5px !important;
  }

  .md\:-mb-3px-i {
    margin-bottom: 3px !important;
  }

  .md\:-mb-0px-i {
    margin-bottom: 0px !important;
  }

  .md\:-mb-34px {
    margin-bottom: -34px;
  }

  .md\:-mb-30px {
    margin-bottom: -30px;
  }

  .md\:-mb-24px {
    margin-bottom: -24px;
  }

  .md\:-mb-20px {
    margin-bottom: -20px;
  }

  .md\:-mb-15px {
    margin-bottom: -15px;
  }

  .md\:-mb-13px {
    margin-bottom: -13px;
  }

  .md\:-mb-12px {
    margin-bottom: -12px;
  }

  .md\:-mb-10px {
    margin-bottom: -10px;
  }

  .md\:-mb-8px {
    margin-bottom: -8px;
  }

  .md\:-mb-6px {
    margin-bottom: -6px;
  }

  .md\:-mb-5px {
    margin-bottom: -5px;
  }

  .md\:-mb-3px {
    margin-bottom: -3px;
  }

  .md\:-mb-menu {
    margin-bottom: -275px;
  }

  .md\:-mb-px {
    margin-bottom: 1px !important;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:ml-1 {
    margin-left: 0.25rem;
  }

  .md\:ml-2 {
    margin-left: 0.5rem;
  }

  .md\:ml-3 {
    margin-left: 0.75rem;
  }

  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:ml-5 {
    margin-left: 1.25rem;
  }

  .md\:ml-6 {
    margin-left: 1.5rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:ml-10 {
    margin-left: 2.5rem;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:ml-20 {
    margin-left: 5rem;
  }

  .md\:ml-24 {
    margin-left: 6rem;
  }

  .md\:ml-32 {
    margin-left: 8rem;
  }

  .md\:ml-40 {
    margin-left: 10rem;
  }

  .md\:ml-48 {
    margin-left: 12rem;
  }

  .md\:ml-56 {
    margin-left: 14rem;
  }

  .md\:ml-64 {
    margin-left: 16rem;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:ml-300px {
    margin-left: 300px;
  }

  .md\:ml-230px {
    margin-left: 230px;
  }

  .md\:ml-180px {
    margin-left: 180px;
  }

  .md\:ml-147px {
    margin-left: 147px;
  }

  .md\:ml-123px {
    margin-left: 123px;
  }

  .md\:ml-100px {
    margin-left: 100px;
  }

  .md\:ml-80px {
    margin-left: 80px;
  }

  .md\:ml-60px {
    margin-left: 60px;
  }

  .md\:ml-50px {
    margin-left: 50px;
  }

  .md\:ml-40px {
    margin-left: 40px;
  }

  .md\:ml-35px {
    margin-left: 35px;
  }

  .md\:ml-34px-i {
    margin-left: 34px !important;
  }

  .md\:ml-30px-i {
    margin-left: 30px !important;
  }

  .md\:ml-24px-i {
    margin-left: 24px !important;
  }

  .md\:ml-20px-i {
    margin-left: 20px !important;
  }

  .md\:ml-15px-i {
    margin-left: 15px !important;
  }

  .md\:ml-13px-i {
    margin-left: 13px !important;
  }

  .md\:ml-12px-i {
    margin-left: 12px !important;
  }

  .md\:ml-10px-i {
    margin-left: 10px !important;
  }

  .md\:ml-8px-i {
    margin-left: 8px !important;
  }

  .md\:ml-6px-i {
    margin-left: 6px !important;
  }

  .md\:ml-5px-i {
    margin-left: 5px !important;
  }

  .md\:ml-3px-i {
    margin-left: 3px !important;
  }

  .md\:ml-0px-i {
    margin-left: 0px !important;
  }

  .md\:ml-34px {
    margin-left: 34px;
  }

  .md\:ml-30px {
    margin-left: 30px;
  }

  .md\:ml-24px {
    margin-left: 24px;
  }

  .md\:ml-20px {
    margin-left: 20px;
  }

  .md\:ml-15px {
    margin-left: 15px;
  }

  .md\:ml-13px {
    margin-left: 13px;
  }

  .md\:ml-12px {
    margin-left: 12px;
  }

  .md\:ml-10px {
    margin-left: 10px;
  }

  .md\:ml-8px {
    margin-left: 8px;
  }

  .md\:ml-6px {
    margin-left: 6px;
  }

  .md\:ml-5px {
    margin-left: 5px;
  }

  .md\:ml-3px {
    margin-left: 3px;
  }

  .md\:ml-menu {
    margin-left: 275px;
  }

  .md\:ml-px {
    margin-left: 1px !important;
  }

  .md\:-ml-1 {
    margin-left: -0.25rem;
  }

  .md\:-ml-2 {
    margin-left: -0.5rem;
  }

  .md\:-ml-3 {
    margin-left: -0.75rem;
  }

  .md\:-ml-4 {
    margin-left: -1rem;
  }

  .md\:-ml-5 {
    margin-left: -1.25rem;
  }

  .md\:-ml-6 {
    margin-left: -1.5rem;
  }

  .md\:-ml-8 {
    margin-left: -2rem;
  }

  .md\:-ml-10 {
    margin-left: -2.5rem;
  }

  .md\:-ml-12 {
    margin-left: -3rem;
  }

  .md\:-ml-16 {
    margin-left: -4rem;
  }

  .md\:-ml-20 {
    margin-left: -5rem;
  }

  .md\:-ml-24 {
    margin-left: -6rem;
  }

  .md\:-ml-32 {
    margin-left: -8rem;
  }

  .md\:-ml-40 {
    margin-left: -10rem;
  }

  .md\:-ml-48 {
    margin-left: -12rem;
  }

  .md\:-ml-56 {
    margin-left: -14rem;
  }

  .md\:-ml-64 {
    margin-left: -16rem;
  }

  .md\:-ml-300px {
    margin-left: -300px;
  }

  .md\:-ml-230px {
    margin-left: -230px;
  }

  .md\:-ml-180px {
    margin-left: -180px;
  }

  .md\:-ml-147px {
    margin-left: -147px;
  }

  .md\:-ml-123px {
    margin-left: -123px;
  }

  .md\:-ml-100px {
    margin-left: -100px;
  }

  .md\:-ml-80px {
    margin-left: -80px;
  }

  .md\:-ml-60px {
    margin-left: -60px;
  }

  .md\:-ml-50px {
    margin-left: -50px;
  }

  .md\:-ml-40px {
    margin-left: -40px;
  }

  .md\:-ml-35px {
    margin-left: -35px;
  }

  .md\:-ml-34px-i {
    margin-left: 34px !important;
  }

  .md\:-ml-30px-i {
    margin-left: 30px !important;
  }

  .md\:-ml-24px-i {
    margin-left: 24px !important;
  }

  .md\:-ml-20px-i {
    margin-left: 20px !important;
  }

  .md\:-ml-15px-i {
    margin-left: 15px !important;
  }

  .md\:-ml-13px-i {
    margin-left: 13px !important;
  }

  .md\:-ml-12px-i {
    margin-left: 12px !important;
  }

  .md\:-ml-10px-i {
    margin-left: 10px !important;
  }

  .md\:-ml-8px-i {
    margin-left: 8px !important;
  }

  .md\:-ml-6px-i {
    margin-left: 6px !important;
  }

  .md\:-ml-5px-i {
    margin-left: 5px !important;
  }

  .md\:-ml-3px-i {
    margin-left: 3px !important;
  }

  .md\:-ml-0px-i {
    margin-left: 0px !important;
  }

  .md\:-ml-34px {
    margin-left: -34px;
  }

  .md\:-ml-30px {
    margin-left: -30px;
  }

  .md\:-ml-24px {
    margin-left: -24px;
  }

  .md\:-ml-20px {
    margin-left: -20px;
  }

  .md\:-ml-15px {
    margin-left: -15px;
  }

  .md\:-ml-13px {
    margin-left: -13px;
  }

  .md\:-ml-12px {
    margin-left: -12px;
  }

  .md\:-ml-10px {
    margin-left: -10px;
  }

  .md\:-ml-8px {
    margin-left: -8px;
  }

  .md\:-ml-6px {
    margin-left: -6px;
  }

  .md\:-ml-5px {
    margin-left: -5px;
  }

  .md\:-ml-3px {
    margin-left: -3px;
  }

  .md\:-ml-menu {
    margin-left: -275px;
  }

  .md\:-ml-px {
    margin-left: 1px !important;
  }

  .md\:box-border {
    box-sizing: border-box;
  }

  .md\:box-content {
    box-sizing: content-box;
  }

  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:table {
    display: table;
  }

  .md\:inline-table {
    display: inline-table;
  }

  .md\:table-caption {
    display: table-caption;
  }

  .md\:table-cell {
    display: table-cell;
  }

  .md\:table-column {
    display: table-column;
  }

  .md\:table-column-group {
    display: table-column-group;
  }

  .md\:table-footer-group {
    display: table-footer-group;
  }

  .md\:table-header-group {
    display: table-header-group;
  }

  .md\:table-row-group {
    display: table-row-group;
  }

  .md\:table-row {
    display: table-row;
  }

  .md\:flow-root {
    display: flow-root;
  }

  .md\:grid {
    display: grid;
  }

  .md\:inline-grid {
    display: inline-grid;
  }

  .md\:contents {
    display: contents;
  }

  .md\:list-item {
    display: list-item;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-0 {
    height: 0;
  }

  .md\:h-1 {
    height: 0.25rem;
  }

  .md\:h-2 {
    height: 0.5rem;
  }

  .md\:h-3 {
    height: 0.75rem;
  }

  .md\:h-4 {
    height: 1rem;
  }

  .md\:h-5 {
    height: 1.25rem;
  }

  .md\:h-6 {
    height: 1.5rem;
  }

  .md\:h-8 {
    height: 2rem;
  }

  .md\:h-10 {
    height: 2.5rem;
  }

  .md\:h-12 {
    height: 3rem;
  }

  .md\:h-16 {
    height: 4rem;
  }

  .md\:h-20 {
    height: 5rem;
  }

  .md\:h-24 {
    height: 6rem;
  }

  .md\:h-32 {
    height: 8rem;
  }

  .md\:h-40 {
    height: 10rem;
  }

  .md\:h-48 {
    height: 12rem;
  }

  .md\:h-56 {
    height: 14rem;
  }

  .md\:h-64 {
    height: 16rem;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:h-300px {
    height: 300px;
  }

  .md\:h-230px {
    height: 230px;
  }

  .md\:h-180px {
    height: 180px;
  }

  .md\:h-147px {
    height: 147px;
  }

  .md\:h-123px {
    height: 123px;
  }

  .md\:h-100px {
    height: 100px;
  }

  .md\:h-80px {
    height: 80px;
  }

  .md\:h-60px {
    height: 60px;
  }

  .md\:h-50px {
    height: 50px;
  }

  .md\:h-40px {
    height: 40px;
  }

  .md\:h-35px {
    height: 35px;
  }

  .md\:h-34px-i {
    height: 34px !important;
  }

  .md\:h-30px-i {
    height: 30px !important;
  }

  .md\:h-24px-i {
    height: 24px !important;
  }

  .md\:h-20px-i {
    height: 20px !important;
  }

  .md\:h-15px-i {
    height: 15px !important;
  }

  .md\:h-13px-i {
    height: 13px !important;
  }

  .md\:h-12px-i {
    height: 12px !important;
  }

  .md\:h-10px-i {
    height: 10px !important;
  }

  .md\:h-8px-i {
    height: 8px !important;
  }

  .md\:h-6px-i {
    height: 6px !important;
  }

  .md\:h-5px-i {
    height: 5px !important;
  }

  .md\:h-3px-i {
    height: 3px !important;
  }

  .md\:h-0px-i {
    height: 0px !important;
  }

  .md\:h-34px {
    height: 34px;
  }

  .md\:h-30px {
    height: 30px;
  }

  .md\:h-24px {
    height: 24px;
  }

  .md\:h-20px {
    height: 20px;
  }

  .md\:h-15px {
    height: 15px;
  }

  .md\:h-13px {
    height: 13px;
  }

  .md\:h-12px {
    height: 12px;
  }

  .md\:h-10px {
    height: 10px;
  }

  .md\:h-8px {
    height: 8px;
  }

  .md\:h-6px {
    height: 6px;
  }

  .md\:h-5px {
    height: 5px;
  }

  .md\:h-3px {
    height: 3px;
  }

  .md\:h-menu {
    height: 275px;
  }

  .md\:h-px {
    height: 1px !important;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:h-screen {
    height: 100vh;
  }

  .md\:max-h-full {
    max-height: 100%;
  }

  .md\:max-h-screen {
    max-height: 100vh;
  }

  .md\:min-h-0 {
    min-height: 0;
  }

  .md\:min-h-full {
    min-height: 100%;
  }

  .md\:min-h-screen {
    min-height: 100vh;
  }

  .md\:w-0 {
    width: 0;
  }

  .md\:w-1 {
    width: 0.25rem;
  }

  .md\:w-2 {
    width: 0.5rem;
  }

  .md\:w-3 {
    width: 0.75rem;
  }

  .md\:w-4 {
    width: 1rem;
  }

  .md\:w-5 {
    width: 1.25rem;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:w-8 {
    width: 2rem;
  }

  .md\:w-10 {
    width: 2.5rem;
  }

  .md\:w-12 {
    width: 3rem;
  }

  .md\:w-16 {
    width: 4rem;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:w-32 {
    width: 8rem;
  }

  .md\:w-40 {
    width: 10rem;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-56 {
    width: 14rem;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-auto {
    width: auto !important;
  }

  .md\:w-300px {
    width: 300px;
  }

  .md\:w-230px {
    width: 230px;
  }

  .md\:w-180px {
    width: 180px;
  }

  .md\:w-147px {
    width: 147px;
  }

  .md\:w-123px {
    width: 123px;
  }

  .md\:w-100px {
    width: 100px;
  }

  .md\:w-80px {
    width: 80px;
  }

  .md\:w-60px {
    width: 60px;
  }

  .md\:w-50px {
    width: 50px;
  }

  .md\:w-40px {
    width: 40px;
  }

  .md\:w-35px {
    width: 35px;
  }

  .md\:w-34px-i {
    width: 34px !important;
  }

  .md\:w-30px-i {
    width: 30px !important;
  }

  .md\:w-24px-i {
    width: 24px !important;
  }

  .md\:w-20px-i {
    width: 20px !important;
  }

  .md\:w-15px-i {
    width: 15px !important;
  }

  .md\:w-13px-i {
    width: 13px !important;
  }

  .md\:w-12px-i {
    width: 12px !important;
  }

  .md\:w-10px-i {
    width: 10px !important;
  }

  .md\:w-8px-i {
    width: 8px !important;
  }

  .md\:w-6px-i {
    width: 6px !important;
  }

  .md\:w-5px-i {
    width: 5px !important;
  }

  .md\:w-3px-i {
    width: 3px !important;
  }

  .md\:w-0px-i {
    width: 0px !important;
  }

  .md\:w-34px {
    width: 34px;
  }

  .md\:w-30px {
    width: 30px;
  }

  .md\:w-24px {
    width: 24px;
  }

  .md\:w-20px {
    width: 20px;
  }

  .md\:w-15px {
    width: 15px;
  }

  .md\:w-13px {
    width: 13px;
  }

  .md\:w-12px {
    width: 12px;
  }

  .md\:w-10px {
    width: 10px;
  }

  .md\:w-8px {
    width: 8px;
  }

  .md\:w-6px {
    width: 6px;
  }

  .md\:w-5px {
    width: 5px;
  }

  .md\:w-3px {
    width: 3px;
  }

  .md\:w-menu {
    width: 275px;
  }

  .md\:w-px {
    width: 1px !important;
  }

  .md\:w-1\/2 {
    width: 50% !important;
  }

  .md\:w-1\/3 {
    width: 33.333333% !important;
  }

  .md\:w-2\/3 {
    width: 66.666667% !important;
  }

  .md\:w-1\/4 {
    width: 25% !important;
  }

  .md\:w-2\/4 {
    width: 50% !important;
  }

  .md\:w-3\/4 {
    width: 75% !important;
  }

  .md\:w-1\/5 {
    width: 20% !important;
  }

  .md\:w-2\/5 {
    width: 40% !important;
  }

  .md\:w-3\/5 {
    width: 60% !important;
  }

  .md\:w-4\/5 {
    width: 80% !important;
  }

  .md\:w-1\/6 {
    width: 16.666667% !important;
  }

  .md\:w-2\/6 {
    width: 33.333333% !important;
  }

  .md\:w-3\/6 {
    width: 50% !important;
  }

  .md\:w-4\/6 {
    width: 66.666667% !important;
  }

  .md\:w-5\/6 {
    width: 83.333333% !important;
  }

  .md\:w-1\/12 {
    width: 8.333333% !important;
  }

  .md\:w-2\/12 {
    width: 16.666667% !important;
  }

  .md\:w-3\/12 {
    width: 25% !important;
  }

  .md\:w-4\/12 {
    width: 33.333333% !important;
  }

  .md\:w-5\/12 {
    width: 41.666667% !important;
  }

  .md\:w-6\/12 {
    width: 50% !important;
  }

  .md\:w-7\/12 {
    width: 58.333333% !important;
  }

  .md\:w-8\/12 {
    width: 66.666667% !important;
  }

  .md\:w-9\/12 {
    width: 75% !important;
  }

  .md\:w-10\/12 {
    width: 83.333333% !important;
  }

  .md\:w-11\/12 {
    width: 91.666667% !important;
  }

  .md\:w-full {
    width: 100% !important;
  }

  .md\:w-screen {
    width: 100vw !important;
  }

  .md\:min-w-0 {
    min-width: 0;
  }

  .md\:min-w-full {
    min-width: 100%;
  }

  .md\:max-w-1420px {
    max-width: 1420px;
  }

  .md\:max-w-1004px {
    max-width: 1004px;
  }

  .md\:max-w-920px {
    max-width: 920px;
  }

  .md\:max-w-645px {
    max-width: 645px;
  }

  .md\:max-w-menu {
    max-width: 275px;
  }

  .md\:max-w-780px {
    max-width: 780px !important;
  }

  .md\:max-w-240px {
    max-width: 240px !important;
  }

  .md\:max-w-100px {
    max-width: 100px !important;
  }

  .md\:max-w-285px {
    max-width: 285px !important;
  }

  .md\:max-w-100pct {
    max-width: 100% !important;
  }

  .md\:max-w-none {
    max-width: none !important;
  }

  .md\:max-w-xs {
    max-width: 20rem;
  }

  .md\:max-w-sm {
    max-width: 24rem;
  }

  .md\:max-w-md {
    max-width: 28rem;
  }

  .md\:max-w-lg {
    max-width: 32rem;
  }

  .md\:max-w-xl {
    max-width: 36rem;
  }

  .md\:max-w-2xl {
    max-width: 42rem;
  }

  .md\:max-w-3xl {
    max-width: 48rem;
  }

  .md\:max-w-4xl {
    max-width: 56rem;
  }

  .md\:max-w-5xl {
    max-width: 64rem;
  }

  .md\:max-w-6xl {
    max-width: 72rem;
  }

  .md\:max-w-full {
    max-width: 100% !important;
  }

  .md\:max-w-screen-xs {
    max-width: 1px;
  }

  .md\:max-w-screen-sm {
    max-width: 768px;
  }

  .md\:max-w-screen-md {
    max-width: 1024px;
  }

  .md\:max-w-screen-lg {
    max-width: 1440px;
  }

  .md\:max-w-screen-xl {
    max-width: 1920px;
  }

  .md\:flex-1 {
    flex: 1 1 0%;
  }

  .md\:flex-auto {
    flex: 1 1 auto;
  }

  .md\:flex-initial {
    flex: 0 1 auto;
  }

  .md\:flex-none {
    flex: none;
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .md\:flex-shrink {
    flex-shrink: 1;
  }

  .md\:flex-grow-0 {
    flex-grow: 0;
  }

  .md\:flex-grow {
    flex-grow: 1;
  }

  .md\:table-auto {
    table-layout: auto;
  }

  .md\:table-fixed {
    table-layout: fixed;
  }

  .md\:border-collapse {
    border-collapse: collapse;
  }

  .md\:border-separate {
    border-collapse: separate;
  }

  .md\:origin-center {
    transform-origin: center;
  }

  .md\:origin-top {
    transform-origin: top;
  }

  .md\:origin-top-right {
    transform-origin: top right;
  }

  .md\:origin-right {
    transform-origin: right;
  }

  .md\:origin-bottom-right {
    transform-origin: bottom right;
  }

  .md\:origin-bottom {
    transform-origin: bottom;
  }

  .md\:origin-bottom-left {
    transform-origin: bottom left;
  }

  .md\:origin-left {
    transform-origin: left;
  }

  .md\:origin-top-left {
    transform-origin: top left;
  }

  .md\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:transform-none {
    transform: none;
  }

  .md\:translate-x-0 {
    --tw-translate-x: 0;
  }

  .md\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }

  .md\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }

  .md\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }

  .md\:translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .md\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .md\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .md\:translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .md\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .md\:translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .md\:translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .md\:translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .md\:translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .md\:translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .md\:translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .md\:translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .md\:translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .md\:translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .md\:translate-x-300px {
    --tw-translate-x: 300px;
  }

  .md\:translate-x-230px {
    --tw-translate-x: 230px;
  }

  .md\:translate-x-180px {
    --tw-translate-x: 180px;
  }

  .md\:translate-x-147px {
    --tw-translate-x: 147px;
  }

  .md\:translate-x-123px {
    --tw-translate-x: 123px;
  }

  .md\:translate-x-100px {
    --tw-translate-x: 100px;
  }

  .md\:translate-x-80px {
    --tw-translate-x: 80px;
  }

  .md\:translate-x-60px {
    --tw-translate-x: 60px;
  }

  .md\:translate-x-50px {
    --tw-translate-x: 50px;
  }

  .md\:translate-x-40px {
    --tw-translate-x: 40px;
  }

  .md\:translate-x-35px {
    --tw-translate-x: 35px;
  }

  .md\:translate-x-34px-i {
    --tw-translate-x: 34px !important;
  }

  .md\:translate-x-30px-i {
    --tw-translate-x: 30px !important;
  }

  .md\:translate-x-24px-i {
    --tw-translate-x: 24px !important;
  }

  .md\:translate-x-20px-i {
    --tw-translate-x: 20px !important;
  }

  .md\:translate-x-15px-i {
    --tw-translate-x: 15px !important;
  }

  .md\:translate-x-13px-i {
    --tw-translate-x: 13px !important;
  }

  .md\:translate-x-12px-i {
    --tw-translate-x: 12px !important;
  }

  .md\:translate-x-10px-i {
    --tw-translate-x: 10px !important;
  }

  .md\:translate-x-8px-i {
    --tw-translate-x: 8px !important;
  }

  .md\:translate-x-6px-i {
    --tw-translate-x: 6px !important;
  }

  .md\:translate-x-5px-i {
    --tw-translate-x: 5px !important;
  }

  .md\:translate-x-3px-i {
    --tw-translate-x: 3px !important;
  }

  .md\:translate-x-0px-i {
    --tw-translate-x: 0px !important;
  }

  .md\:translate-x-34px {
    --tw-translate-x: 34px;
  }

  .md\:translate-x-30px {
    --tw-translate-x: 30px;
  }

  .md\:translate-x-24px {
    --tw-translate-x: 24px;
  }

  .md\:translate-x-20px {
    --tw-translate-x: 20px;
  }

  .md\:translate-x-15px {
    --tw-translate-x: 15px;
  }

  .md\:translate-x-13px {
    --tw-translate-x: 13px;
  }

  .md\:translate-x-12px {
    --tw-translate-x: 12px;
  }

  .md\:translate-x-10px {
    --tw-translate-x: 10px;
  }

  .md\:translate-x-8px {
    --tw-translate-x: 8px;
  }

  .md\:translate-x-6px {
    --tw-translate-x: 6px;
  }

  .md\:translate-x-5px {
    --tw-translate-x: 5px;
  }

  .md\:translate-x-3px {
    --tw-translate-x: 3px;
  }

  .md\:translate-x-menu {
    --tw-translate-x: 275px;
  }

  .md\:translate-x-px {
    --tw-translate-x: 1px !important;
  }

  .md\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }

  .md\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }

  .md\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }

  .md\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .md\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .md\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .md\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .md\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .md\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .md\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .md\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .md\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .md\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .md\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .md\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .md\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .md\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .md\:-translate-x-300px {
    --tw-translate-x: -300px;
  }

  .md\:-translate-x-230px {
    --tw-translate-x: -230px;
  }

  .md\:-translate-x-180px {
    --tw-translate-x: -180px;
  }

  .md\:-translate-x-147px {
    --tw-translate-x: -147px;
  }

  .md\:-translate-x-123px {
    --tw-translate-x: -123px;
  }

  .md\:-translate-x-100px {
    --tw-translate-x: -100px;
  }

  .md\:-translate-x-80px {
    --tw-translate-x: -80px;
  }

  .md\:-translate-x-60px {
    --tw-translate-x: -60px;
  }

  .md\:-translate-x-50px {
    --tw-translate-x: -50px;
  }

  .md\:-translate-x-40px {
    --tw-translate-x: -40px;
  }

  .md\:-translate-x-35px {
    --tw-translate-x: -35px;
  }

  .md\:-translate-x-34px-i {
    --tw-translate-x: 34px !important;
  }

  .md\:-translate-x-30px-i {
    --tw-translate-x: 30px !important;
  }

  .md\:-translate-x-24px-i {
    --tw-translate-x: 24px !important;
  }

  .md\:-translate-x-20px-i {
    --tw-translate-x: 20px !important;
  }

  .md\:-translate-x-15px-i {
    --tw-translate-x: 15px !important;
  }

  .md\:-translate-x-13px-i {
    --tw-translate-x: 13px !important;
  }

  .md\:-translate-x-12px-i {
    --tw-translate-x: 12px !important;
  }

  .md\:-translate-x-10px-i {
    --tw-translate-x: 10px !important;
  }

  .md\:-translate-x-8px-i {
    --tw-translate-x: 8px !important;
  }

  .md\:-translate-x-6px-i {
    --tw-translate-x: 6px !important;
  }

  .md\:-translate-x-5px-i {
    --tw-translate-x: 5px !important;
  }

  .md\:-translate-x-3px-i {
    --tw-translate-x: 3px !important;
  }

  .md\:-translate-x-0px-i {
    --tw-translate-x: 0px !important;
  }

  .md\:-translate-x-34px {
    --tw-translate-x: -34px;
  }

  .md\:-translate-x-30px {
    --tw-translate-x: -30px;
  }

  .md\:-translate-x-24px {
    --tw-translate-x: -24px;
  }

  .md\:-translate-x-20px {
    --tw-translate-x: -20px;
  }

  .md\:-translate-x-15px {
    --tw-translate-x: -15px;
  }

  .md\:-translate-x-13px {
    --tw-translate-x: -13px;
  }

  .md\:-translate-x-12px {
    --tw-translate-x: -12px;
  }

  .md\:-translate-x-10px {
    --tw-translate-x: -10px;
  }

  .md\:-translate-x-8px {
    --tw-translate-x: -8px;
  }

  .md\:-translate-x-6px {
    --tw-translate-x: -6px;
  }

  .md\:-translate-x-5px {
    --tw-translate-x: -5px;
  }

  .md\:-translate-x-3px {
    --tw-translate-x: -3px;
  }

  .md\:-translate-x-menu {
    --tw-translate-x: -275px;
  }

  .md\:-translate-x-px {
    --tw-translate-x: 1px !important;
  }

  .md\:-translate-x-full {
    --tw-translate-x: -100%;
  }

  .md\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .md\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .md\:translate-x-full {
    --tw-translate-x: 100%;
  }

  .md\:translate-y-0 {
    --tw-translate-y: 0;
  }

  .md\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }

  .md\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }

  .md\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }

  .md\:translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .md\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .md\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .md\:translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .md\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .md\:translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .md\:translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .md\:translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .md\:translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .md\:translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .md\:translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .md\:translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .md\:translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .md\:translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .md\:translate-y-300px {
    --tw-translate-y: 300px;
  }

  .md\:translate-y-230px {
    --tw-translate-y: 230px;
  }

  .md\:translate-y-180px {
    --tw-translate-y: 180px;
  }

  .md\:translate-y-147px {
    --tw-translate-y: 147px;
  }

  .md\:translate-y-123px {
    --tw-translate-y: 123px;
  }

  .md\:translate-y-100px {
    --tw-translate-y: 100px;
  }

  .md\:translate-y-80px {
    --tw-translate-y: 80px;
  }

  .md\:translate-y-60px {
    --tw-translate-y: 60px;
  }

  .md\:translate-y-50px {
    --tw-translate-y: 50px;
  }

  .md\:translate-y-40px {
    --tw-translate-y: 40px;
  }

  .md\:translate-y-35px {
    --tw-translate-y: 35px;
  }

  .md\:translate-y-34px-i {
    --tw-translate-y: 34px !important;
  }

  .md\:translate-y-30px-i {
    --tw-translate-y: 30px !important;
  }

  .md\:translate-y-24px-i {
    --tw-translate-y: 24px !important;
  }

  .md\:translate-y-20px-i {
    --tw-translate-y: 20px !important;
  }

  .md\:translate-y-15px-i {
    --tw-translate-y: 15px !important;
  }

  .md\:translate-y-13px-i {
    --tw-translate-y: 13px !important;
  }

  .md\:translate-y-12px-i {
    --tw-translate-y: 12px !important;
  }

  .md\:translate-y-10px-i {
    --tw-translate-y: 10px !important;
  }

  .md\:translate-y-8px-i {
    --tw-translate-y: 8px !important;
  }

  .md\:translate-y-6px-i {
    --tw-translate-y: 6px !important;
  }

  .md\:translate-y-5px-i {
    --tw-translate-y: 5px !important;
  }

  .md\:translate-y-3px-i {
    --tw-translate-y: 3px !important;
  }

  .md\:translate-y-0px-i {
    --tw-translate-y: 0px !important;
  }

  .md\:translate-y-34px {
    --tw-translate-y: 34px;
  }

  .md\:translate-y-30px {
    --tw-translate-y: 30px;
  }

  .md\:translate-y-24px {
    --tw-translate-y: 24px;
  }

  .md\:translate-y-20px {
    --tw-translate-y: 20px;
  }

  .md\:translate-y-15px {
    --tw-translate-y: 15px;
  }

  .md\:translate-y-13px {
    --tw-translate-y: 13px;
  }

  .md\:translate-y-12px {
    --tw-translate-y: 12px;
  }

  .md\:translate-y-10px {
    --tw-translate-y: 10px;
  }

  .md\:translate-y-8px {
    --tw-translate-y: 8px;
  }

  .md\:translate-y-6px {
    --tw-translate-y: 6px;
  }

  .md\:translate-y-5px {
    --tw-translate-y: 5px;
  }

  .md\:translate-y-3px {
    --tw-translate-y: 3px;
  }

  .md\:translate-y-menu {
    --tw-translate-y: 275px;
  }

  .md\:translate-y-px {
    --tw-translate-y: 1px !important;
  }

  .md\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }

  .md\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }

  .md\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }

  .md\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .md\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .md\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .md\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .md\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .md\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .md\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .md\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .md\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .md\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .md\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .md\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .md\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .md\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .md\:-translate-y-300px {
    --tw-translate-y: -300px;
  }

  .md\:-translate-y-230px {
    --tw-translate-y: -230px;
  }

  .md\:-translate-y-180px {
    --tw-translate-y: -180px;
  }

  .md\:-translate-y-147px {
    --tw-translate-y: -147px;
  }

  .md\:-translate-y-123px {
    --tw-translate-y: -123px;
  }

  .md\:-translate-y-100px {
    --tw-translate-y: -100px;
  }

  .md\:-translate-y-80px {
    --tw-translate-y: -80px;
  }

  .md\:-translate-y-60px {
    --tw-translate-y: -60px;
  }

  .md\:-translate-y-50px {
    --tw-translate-y: -50px;
  }

  .md\:-translate-y-40px {
    --tw-translate-y: -40px;
  }

  .md\:-translate-y-35px {
    --tw-translate-y: -35px;
  }

  .md\:-translate-y-34px-i {
    --tw-translate-y: 34px !important;
  }

  .md\:-translate-y-30px-i {
    --tw-translate-y: 30px !important;
  }

  .md\:-translate-y-24px-i {
    --tw-translate-y: 24px !important;
  }

  .md\:-translate-y-20px-i {
    --tw-translate-y: 20px !important;
  }

  .md\:-translate-y-15px-i {
    --tw-translate-y: 15px !important;
  }

  .md\:-translate-y-13px-i {
    --tw-translate-y: 13px !important;
  }

  .md\:-translate-y-12px-i {
    --tw-translate-y: 12px !important;
  }

  .md\:-translate-y-10px-i {
    --tw-translate-y: 10px !important;
  }

  .md\:-translate-y-8px-i {
    --tw-translate-y: 8px !important;
  }

  .md\:-translate-y-6px-i {
    --tw-translate-y: 6px !important;
  }

  .md\:-translate-y-5px-i {
    --tw-translate-y: 5px !important;
  }

  .md\:-translate-y-3px-i {
    --tw-translate-y: 3px !important;
  }

  .md\:-translate-y-0px-i {
    --tw-translate-y: 0px !important;
  }

  .md\:-translate-y-34px {
    --tw-translate-y: -34px;
  }

  .md\:-translate-y-30px {
    --tw-translate-y: -30px;
  }

  .md\:-translate-y-24px {
    --tw-translate-y: -24px;
  }

  .md\:-translate-y-20px {
    --tw-translate-y: -20px;
  }

  .md\:-translate-y-15px {
    --tw-translate-y: -15px;
  }

  .md\:-translate-y-13px {
    --tw-translate-y: -13px;
  }

  .md\:-translate-y-12px {
    --tw-translate-y: -12px;
  }

  .md\:-translate-y-10px {
    --tw-translate-y: -10px;
  }

  .md\:-translate-y-8px {
    --tw-translate-y: -8px;
  }

  .md\:-translate-y-6px {
    --tw-translate-y: -6px;
  }

  .md\:-translate-y-5px {
    --tw-translate-y: -5px;
  }

  .md\:-translate-y-3px {
    --tw-translate-y: -3px;
  }

  .md\:-translate-y-menu {
    --tw-translate-y: -275px;
  }

  .md\:-translate-y-px {
    --tw-translate-y: 1px !important;
  }

  .md\:-translate-y-full {
    --tw-translate-y: -100%;
  }

  .md\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .md\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .md\:translate-y-full {
    --tw-translate-y: 100%;
  }

  .md\:hover\:translate-x-0:hover {
    --tw-translate-x: 0;
  }

  .md\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }

  .md\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }

  .md\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }

  .md\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .md\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .md\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .md\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .md\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .md\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .md\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .md\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .md\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .md\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .md\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .md\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .md\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .md\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .md\:hover\:translate-x-300px:hover {
    --tw-translate-x: 300px;
  }

  .md\:hover\:translate-x-230px:hover {
    --tw-translate-x: 230px;
  }

  .md\:hover\:translate-x-180px:hover {
    --tw-translate-x: 180px;
  }

  .md\:hover\:translate-x-147px:hover {
    --tw-translate-x: 147px;
  }

  .md\:hover\:translate-x-123px:hover {
    --tw-translate-x: 123px;
  }

  .md\:hover\:translate-x-100px:hover {
    --tw-translate-x: 100px;
  }

  .md\:hover\:translate-x-80px:hover {
    --tw-translate-x: 80px;
  }

  .md\:hover\:translate-x-60px:hover {
    --tw-translate-x: 60px;
  }

  .md\:hover\:translate-x-50px:hover {
    --tw-translate-x: 50px;
  }

  .md\:hover\:translate-x-40px:hover {
    --tw-translate-x: 40px;
  }

  .md\:hover\:translate-x-35px:hover {
    --tw-translate-x: 35px;
  }

  .md\:hover\:translate-x-34px-i:hover {
    --tw-translate-x: 34px !important;
  }

  .md\:hover\:translate-x-30px-i:hover {
    --tw-translate-x: 30px !important;
  }

  .md\:hover\:translate-x-24px-i:hover {
    --tw-translate-x: 24px !important;
  }

  .md\:hover\:translate-x-20px-i:hover {
    --tw-translate-x: 20px !important;
  }

  .md\:hover\:translate-x-15px-i:hover {
    --tw-translate-x: 15px !important;
  }

  .md\:hover\:translate-x-13px-i:hover {
    --tw-translate-x: 13px !important;
  }

  .md\:hover\:translate-x-12px-i:hover {
    --tw-translate-x: 12px !important;
  }

  .md\:hover\:translate-x-10px-i:hover {
    --tw-translate-x: 10px !important;
  }

  .md\:hover\:translate-x-8px-i:hover {
    --tw-translate-x: 8px !important;
  }

  .md\:hover\:translate-x-6px-i:hover {
    --tw-translate-x: 6px !important;
  }

  .md\:hover\:translate-x-5px-i:hover {
    --tw-translate-x: 5px !important;
  }

  .md\:hover\:translate-x-3px-i:hover {
    --tw-translate-x: 3px !important;
  }

  .md\:hover\:translate-x-0px-i:hover {
    --tw-translate-x: 0px !important;
  }

  .md\:hover\:translate-x-34px:hover {
    --tw-translate-x: 34px;
  }

  .md\:hover\:translate-x-30px:hover {
    --tw-translate-x: 30px;
  }

  .md\:hover\:translate-x-24px:hover {
    --tw-translate-x: 24px;
  }

  .md\:hover\:translate-x-20px:hover {
    --tw-translate-x: 20px;
  }

  .md\:hover\:translate-x-15px:hover {
    --tw-translate-x: 15px;
  }

  .md\:hover\:translate-x-13px:hover {
    --tw-translate-x: 13px;
  }

  .md\:hover\:translate-x-12px:hover {
    --tw-translate-x: 12px;
  }

  .md\:hover\:translate-x-10px:hover {
    --tw-translate-x: 10px;
  }

  .md\:hover\:translate-x-8px:hover {
    --tw-translate-x: 8px;
  }

  .md\:hover\:translate-x-6px:hover {
    --tw-translate-x: 6px;
  }

  .md\:hover\:translate-x-5px:hover {
    --tw-translate-x: 5px;
  }

  .md\:hover\:translate-x-3px:hover {
    --tw-translate-x: 3px;
  }

  .md\:hover\:translate-x-menu:hover {
    --tw-translate-x: 275px;
  }

  .md\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px !important;
  }

  .md\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }

  .md\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }

  .md\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }

  .md\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .md\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .md\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .md\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .md\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .md\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .md\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .md\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .md\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .md\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .md\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .md\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .md\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .md\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .md\:hover\:-translate-x-300px:hover {
    --tw-translate-x: -300px;
  }

  .md\:hover\:-translate-x-230px:hover {
    --tw-translate-x: -230px;
  }

  .md\:hover\:-translate-x-180px:hover {
    --tw-translate-x: -180px;
  }

  .md\:hover\:-translate-x-147px:hover {
    --tw-translate-x: -147px;
  }

  .md\:hover\:-translate-x-123px:hover {
    --tw-translate-x: -123px;
  }

  .md\:hover\:-translate-x-100px:hover {
    --tw-translate-x: -100px;
  }

  .md\:hover\:-translate-x-80px:hover {
    --tw-translate-x: -80px;
  }

  .md\:hover\:-translate-x-60px:hover {
    --tw-translate-x: -60px;
  }

  .md\:hover\:-translate-x-50px:hover {
    --tw-translate-x: -50px;
  }

  .md\:hover\:-translate-x-40px:hover {
    --tw-translate-x: -40px;
  }

  .md\:hover\:-translate-x-35px:hover {
    --tw-translate-x: -35px;
  }

  .md\:hover\:-translate-x-34px-i:hover {
    --tw-translate-x: 34px !important;
  }

  .md\:hover\:-translate-x-30px-i:hover {
    --tw-translate-x: 30px !important;
  }

  .md\:hover\:-translate-x-24px-i:hover {
    --tw-translate-x: 24px !important;
  }

  .md\:hover\:-translate-x-20px-i:hover {
    --tw-translate-x: 20px !important;
  }

  .md\:hover\:-translate-x-15px-i:hover {
    --tw-translate-x: 15px !important;
  }

  .md\:hover\:-translate-x-13px-i:hover {
    --tw-translate-x: 13px !important;
  }

  .md\:hover\:-translate-x-12px-i:hover {
    --tw-translate-x: 12px !important;
  }

  .md\:hover\:-translate-x-10px-i:hover {
    --tw-translate-x: 10px !important;
  }

  .md\:hover\:-translate-x-8px-i:hover {
    --tw-translate-x: 8px !important;
  }

  .md\:hover\:-translate-x-6px-i:hover {
    --tw-translate-x: 6px !important;
  }

  .md\:hover\:-translate-x-5px-i:hover {
    --tw-translate-x: 5px !important;
  }

  .md\:hover\:-translate-x-3px-i:hover {
    --tw-translate-x: 3px !important;
  }

  .md\:hover\:-translate-x-0px-i:hover {
    --tw-translate-x: 0px !important;
  }

  .md\:hover\:-translate-x-34px:hover {
    --tw-translate-x: -34px;
  }

  .md\:hover\:-translate-x-30px:hover {
    --tw-translate-x: -30px;
  }

  .md\:hover\:-translate-x-24px:hover {
    --tw-translate-x: -24px;
  }

  .md\:hover\:-translate-x-20px:hover {
    --tw-translate-x: -20px;
  }

  .md\:hover\:-translate-x-15px:hover {
    --tw-translate-x: -15px;
  }

  .md\:hover\:-translate-x-13px:hover {
    --tw-translate-x: -13px;
  }

  .md\:hover\:-translate-x-12px:hover {
    --tw-translate-x: -12px;
  }

  .md\:hover\:-translate-x-10px:hover {
    --tw-translate-x: -10px;
  }

  .md\:hover\:-translate-x-8px:hover {
    --tw-translate-x: -8px;
  }

  .md\:hover\:-translate-x-6px:hover {
    --tw-translate-x: -6px;
  }

  .md\:hover\:-translate-x-5px:hover {
    --tw-translate-x: -5px;
  }

  .md\:hover\:-translate-x-3px:hover {
    --tw-translate-x: -3px;
  }

  .md\:hover\:-translate-x-menu:hover {
    --tw-translate-x: -275px;
  }

  .md\:hover\:-translate-x-px:hover {
    --tw-translate-x: 1px !important;
  }

  .md\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .md\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .md\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .md\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .md\:hover\:translate-y-0:hover {
    --tw-translate-y: 0;
  }

  .md\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }

  .md\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }

  .md\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }

  .md\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .md\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .md\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .md\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .md\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .md\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .md\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .md\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .md\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .md\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .md\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .md\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .md\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .md\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .md\:hover\:translate-y-300px:hover {
    --tw-translate-y: 300px;
  }

  .md\:hover\:translate-y-230px:hover {
    --tw-translate-y: 230px;
  }

  .md\:hover\:translate-y-180px:hover {
    --tw-translate-y: 180px;
  }

  .md\:hover\:translate-y-147px:hover {
    --tw-translate-y: 147px;
  }

  .md\:hover\:translate-y-123px:hover {
    --tw-translate-y: 123px;
  }

  .md\:hover\:translate-y-100px:hover {
    --tw-translate-y: 100px;
  }

  .md\:hover\:translate-y-80px:hover {
    --tw-translate-y: 80px;
  }

  .md\:hover\:translate-y-60px:hover {
    --tw-translate-y: 60px;
  }

  .md\:hover\:translate-y-50px:hover {
    --tw-translate-y: 50px;
  }

  .md\:hover\:translate-y-40px:hover {
    --tw-translate-y: 40px;
  }

  .md\:hover\:translate-y-35px:hover {
    --tw-translate-y: 35px;
  }

  .md\:hover\:translate-y-34px-i:hover {
    --tw-translate-y: 34px !important;
  }

  .md\:hover\:translate-y-30px-i:hover {
    --tw-translate-y: 30px !important;
  }

  .md\:hover\:translate-y-24px-i:hover {
    --tw-translate-y: 24px !important;
  }

  .md\:hover\:translate-y-20px-i:hover {
    --tw-translate-y: 20px !important;
  }

  .md\:hover\:translate-y-15px-i:hover {
    --tw-translate-y: 15px !important;
  }

  .md\:hover\:translate-y-13px-i:hover {
    --tw-translate-y: 13px !important;
  }

  .md\:hover\:translate-y-12px-i:hover {
    --tw-translate-y: 12px !important;
  }

  .md\:hover\:translate-y-10px-i:hover {
    --tw-translate-y: 10px !important;
  }

  .md\:hover\:translate-y-8px-i:hover {
    --tw-translate-y: 8px !important;
  }

  .md\:hover\:translate-y-6px-i:hover {
    --tw-translate-y: 6px !important;
  }

  .md\:hover\:translate-y-5px-i:hover {
    --tw-translate-y: 5px !important;
  }

  .md\:hover\:translate-y-3px-i:hover {
    --tw-translate-y: 3px !important;
  }

  .md\:hover\:translate-y-0px-i:hover {
    --tw-translate-y: 0px !important;
  }

  .md\:hover\:translate-y-34px:hover {
    --tw-translate-y: 34px;
  }

  .md\:hover\:translate-y-30px:hover {
    --tw-translate-y: 30px;
  }

  .md\:hover\:translate-y-24px:hover {
    --tw-translate-y: 24px;
  }

  .md\:hover\:translate-y-20px:hover {
    --tw-translate-y: 20px;
  }

  .md\:hover\:translate-y-15px:hover {
    --tw-translate-y: 15px;
  }

  .md\:hover\:translate-y-13px:hover {
    --tw-translate-y: 13px;
  }

  .md\:hover\:translate-y-12px:hover {
    --tw-translate-y: 12px;
  }

  .md\:hover\:translate-y-10px:hover {
    --tw-translate-y: 10px;
  }

  .md\:hover\:translate-y-8px:hover {
    --tw-translate-y: 8px;
  }

  .md\:hover\:translate-y-6px:hover {
    --tw-translate-y: 6px;
  }

  .md\:hover\:translate-y-5px:hover {
    --tw-translate-y: 5px;
  }

  .md\:hover\:translate-y-3px:hover {
    --tw-translate-y: 3px;
  }

  .md\:hover\:translate-y-menu:hover {
    --tw-translate-y: 275px;
  }

  .md\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px !important;
  }

  .md\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }

  .md\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }

  .md\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }

  .md\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .md\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .md\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .md\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .md\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .md\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .md\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .md\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .md\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .md\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .md\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .md\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .md\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .md\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .md\:hover\:-translate-y-300px:hover {
    --tw-translate-y: -300px;
  }

  .md\:hover\:-translate-y-230px:hover {
    --tw-translate-y: -230px;
  }

  .md\:hover\:-translate-y-180px:hover {
    --tw-translate-y: -180px;
  }

  .md\:hover\:-translate-y-147px:hover {
    --tw-translate-y: -147px;
  }

  .md\:hover\:-translate-y-123px:hover {
    --tw-translate-y: -123px;
  }

  .md\:hover\:-translate-y-100px:hover {
    --tw-translate-y: -100px;
  }

  .md\:hover\:-translate-y-80px:hover {
    --tw-translate-y: -80px;
  }

  .md\:hover\:-translate-y-60px:hover {
    --tw-translate-y: -60px;
  }

  .md\:hover\:-translate-y-50px:hover {
    --tw-translate-y: -50px;
  }

  .md\:hover\:-translate-y-40px:hover {
    --tw-translate-y: -40px;
  }

  .md\:hover\:-translate-y-35px:hover {
    --tw-translate-y: -35px;
  }

  .md\:hover\:-translate-y-34px-i:hover {
    --tw-translate-y: 34px !important;
  }

  .md\:hover\:-translate-y-30px-i:hover {
    --tw-translate-y: 30px !important;
  }

  .md\:hover\:-translate-y-24px-i:hover {
    --tw-translate-y: 24px !important;
  }

  .md\:hover\:-translate-y-20px-i:hover {
    --tw-translate-y: 20px !important;
  }

  .md\:hover\:-translate-y-15px-i:hover {
    --tw-translate-y: 15px !important;
  }

  .md\:hover\:-translate-y-13px-i:hover {
    --tw-translate-y: 13px !important;
  }

  .md\:hover\:-translate-y-12px-i:hover {
    --tw-translate-y: 12px !important;
  }

  .md\:hover\:-translate-y-10px-i:hover {
    --tw-translate-y: 10px !important;
  }

  .md\:hover\:-translate-y-8px-i:hover {
    --tw-translate-y: 8px !important;
  }

  .md\:hover\:-translate-y-6px-i:hover {
    --tw-translate-y: 6px !important;
  }

  .md\:hover\:-translate-y-5px-i:hover {
    --tw-translate-y: 5px !important;
  }

  .md\:hover\:-translate-y-3px-i:hover {
    --tw-translate-y: 3px !important;
  }

  .md\:hover\:-translate-y-0px-i:hover {
    --tw-translate-y: 0px !important;
  }

  .md\:hover\:-translate-y-34px:hover {
    --tw-translate-y: -34px;
  }

  .md\:hover\:-translate-y-30px:hover {
    --tw-translate-y: -30px;
  }

  .md\:hover\:-translate-y-24px:hover {
    --tw-translate-y: -24px;
  }

  .md\:hover\:-translate-y-20px:hover {
    --tw-translate-y: -20px;
  }

  .md\:hover\:-translate-y-15px:hover {
    --tw-translate-y: -15px;
  }

  .md\:hover\:-translate-y-13px:hover {
    --tw-translate-y: -13px;
  }

  .md\:hover\:-translate-y-12px:hover {
    --tw-translate-y: -12px;
  }

  .md\:hover\:-translate-y-10px:hover {
    --tw-translate-y: -10px;
  }

  .md\:hover\:-translate-y-8px:hover {
    --tw-translate-y: -8px;
  }

  .md\:hover\:-translate-y-6px:hover {
    --tw-translate-y: -6px;
  }

  .md\:hover\:-translate-y-5px:hover {
    --tw-translate-y: -5px;
  }

  .md\:hover\:-translate-y-3px:hover {
    --tw-translate-y: -3px;
  }

  .md\:hover\:-translate-y-menu:hover {
    --tw-translate-y: -275px;
  }

  .md\:hover\:-translate-y-px:hover {
    --tw-translate-y: 1px !important;
  }

  .md\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .md\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .md\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .md\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .md\:focus\:translate-x-0:focus {
    --tw-translate-x: 0;
  }

  .md\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }

  .md\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }

  .md\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }

  .md\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .md\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .md\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .md\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .md\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .md\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .md\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .md\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .md\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .md\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .md\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .md\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .md\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .md\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .md\:focus\:translate-x-300px:focus {
    --tw-translate-x: 300px;
  }

  .md\:focus\:translate-x-230px:focus {
    --tw-translate-x: 230px;
  }

  .md\:focus\:translate-x-180px:focus {
    --tw-translate-x: 180px;
  }

  .md\:focus\:translate-x-147px:focus {
    --tw-translate-x: 147px;
  }

  .md\:focus\:translate-x-123px:focus {
    --tw-translate-x: 123px;
  }

  .md\:focus\:translate-x-100px:focus {
    --tw-translate-x: 100px;
  }

  .md\:focus\:translate-x-80px:focus {
    --tw-translate-x: 80px;
  }

  .md\:focus\:translate-x-60px:focus {
    --tw-translate-x: 60px;
  }

  .md\:focus\:translate-x-50px:focus {
    --tw-translate-x: 50px;
  }

  .md\:focus\:translate-x-40px:focus {
    --tw-translate-x: 40px;
  }

  .md\:focus\:translate-x-35px:focus {
    --tw-translate-x: 35px;
  }

  .md\:focus\:translate-x-34px-i:focus {
    --tw-translate-x: 34px !important;
  }

  .md\:focus\:translate-x-30px-i:focus {
    --tw-translate-x: 30px !important;
  }

  .md\:focus\:translate-x-24px-i:focus {
    --tw-translate-x: 24px !important;
  }

  .md\:focus\:translate-x-20px-i:focus {
    --tw-translate-x: 20px !important;
  }

  .md\:focus\:translate-x-15px-i:focus {
    --tw-translate-x: 15px !important;
  }

  .md\:focus\:translate-x-13px-i:focus {
    --tw-translate-x: 13px !important;
  }

  .md\:focus\:translate-x-12px-i:focus {
    --tw-translate-x: 12px !important;
  }

  .md\:focus\:translate-x-10px-i:focus {
    --tw-translate-x: 10px !important;
  }

  .md\:focus\:translate-x-8px-i:focus {
    --tw-translate-x: 8px !important;
  }

  .md\:focus\:translate-x-6px-i:focus {
    --tw-translate-x: 6px !important;
  }

  .md\:focus\:translate-x-5px-i:focus {
    --tw-translate-x: 5px !important;
  }

  .md\:focus\:translate-x-3px-i:focus {
    --tw-translate-x: 3px !important;
  }

  .md\:focus\:translate-x-0px-i:focus {
    --tw-translate-x: 0px !important;
  }

  .md\:focus\:translate-x-34px:focus {
    --tw-translate-x: 34px;
  }

  .md\:focus\:translate-x-30px:focus {
    --tw-translate-x: 30px;
  }

  .md\:focus\:translate-x-24px:focus {
    --tw-translate-x: 24px;
  }

  .md\:focus\:translate-x-20px:focus {
    --tw-translate-x: 20px;
  }

  .md\:focus\:translate-x-15px:focus {
    --tw-translate-x: 15px;
  }

  .md\:focus\:translate-x-13px:focus {
    --tw-translate-x: 13px;
  }

  .md\:focus\:translate-x-12px:focus {
    --tw-translate-x: 12px;
  }

  .md\:focus\:translate-x-10px:focus {
    --tw-translate-x: 10px;
  }

  .md\:focus\:translate-x-8px:focus {
    --tw-translate-x: 8px;
  }

  .md\:focus\:translate-x-6px:focus {
    --tw-translate-x: 6px;
  }

  .md\:focus\:translate-x-5px:focus {
    --tw-translate-x: 5px;
  }

  .md\:focus\:translate-x-3px:focus {
    --tw-translate-x: 3px;
  }

  .md\:focus\:translate-x-menu:focus {
    --tw-translate-x: 275px;
  }

  .md\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px !important;
  }

  .md\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }

  .md\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }

  .md\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }

  .md\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .md\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .md\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .md\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .md\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .md\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .md\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .md\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .md\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .md\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .md\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .md\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .md\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .md\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .md\:focus\:-translate-x-300px:focus {
    --tw-translate-x: -300px;
  }

  .md\:focus\:-translate-x-230px:focus {
    --tw-translate-x: -230px;
  }

  .md\:focus\:-translate-x-180px:focus {
    --tw-translate-x: -180px;
  }

  .md\:focus\:-translate-x-147px:focus {
    --tw-translate-x: -147px;
  }

  .md\:focus\:-translate-x-123px:focus {
    --tw-translate-x: -123px;
  }

  .md\:focus\:-translate-x-100px:focus {
    --tw-translate-x: -100px;
  }

  .md\:focus\:-translate-x-80px:focus {
    --tw-translate-x: -80px;
  }

  .md\:focus\:-translate-x-60px:focus {
    --tw-translate-x: -60px;
  }

  .md\:focus\:-translate-x-50px:focus {
    --tw-translate-x: -50px;
  }

  .md\:focus\:-translate-x-40px:focus {
    --tw-translate-x: -40px;
  }

  .md\:focus\:-translate-x-35px:focus {
    --tw-translate-x: -35px;
  }

  .md\:focus\:-translate-x-34px-i:focus {
    --tw-translate-x: 34px !important;
  }

  .md\:focus\:-translate-x-30px-i:focus {
    --tw-translate-x: 30px !important;
  }

  .md\:focus\:-translate-x-24px-i:focus {
    --tw-translate-x: 24px !important;
  }

  .md\:focus\:-translate-x-20px-i:focus {
    --tw-translate-x: 20px !important;
  }

  .md\:focus\:-translate-x-15px-i:focus {
    --tw-translate-x: 15px !important;
  }

  .md\:focus\:-translate-x-13px-i:focus {
    --tw-translate-x: 13px !important;
  }

  .md\:focus\:-translate-x-12px-i:focus {
    --tw-translate-x: 12px !important;
  }

  .md\:focus\:-translate-x-10px-i:focus {
    --tw-translate-x: 10px !important;
  }

  .md\:focus\:-translate-x-8px-i:focus {
    --tw-translate-x: 8px !important;
  }

  .md\:focus\:-translate-x-6px-i:focus {
    --tw-translate-x: 6px !important;
  }

  .md\:focus\:-translate-x-5px-i:focus {
    --tw-translate-x: 5px !important;
  }

  .md\:focus\:-translate-x-3px-i:focus {
    --tw-translate-x: 3px !important;
  }

  .md\:focus\:-translate-x-0px-i:focus {
    --tw-translate-x: 0px !important;
  }

  .md\:focus\:-translate-x-34px:focus {
    --tw-translate-x: -34px;
  }

  .md\:focus\:-translate-x-30px:focus {
    --tw-translate-x: -30px;
  }

  .md\:focus\:-translate-x-24px:focus {
    --tw-translate-x: -24px;
  }

  .md\:focus\:-translate-x-20px:focus {
    --tw-translate-x: -20px;
  }

  .md\:focus\:-translate-x-15px:focus {
    --tw-translate-x: -15px;
  }

  .md\:focus\:-translate-x-13px:focus {
    --tw-translate-x: -13px;
  }

  .md\:focus\:-translate-x-12px:focus {
    --tw-translate-x: -12px;
  }

  .md\:focus\:-translate-x-10px:focus {
    --tw-translate-x: -10px;
  }

  .md\:focus\:-translate-x-8px:focus {
    --tw-translate-x: -8px;
  }

  .md\:focus\:-translate-x-6px:focus {
    --tw-translate-x: -6px;
  }

  .md\:focus\:-translate-x-5px:focus {
    --tw-translate-x: -5px;
  }

  .md\:focus\:-translate-x-3px:focus {
    --tw-translate-x: -3px;
  }

  .md\:focus\:-translate-x-menu:focus {
    --tw-translate-x: -275px;
  }

  .md\:focus\:-translate-x-px:focus {
    --tw-translate-x: 1px !important;
  }

  .md\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .md\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .md\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .md\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .md\:focus\:translate-y-0:focus {
    --tw-translate-y: 0;
  }

  .md\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }

  .md\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }

  .md\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }

  .md\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .md\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .md\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .md\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .md\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .md\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .md\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .md\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .md\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .md\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .md\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .md\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .md\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .md\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .md\:focus\:translate-y-300px:focus {
    --tw-translate-y: 300px;
  }

  .md\:focus\:translate-y-230px:focus {
    --tw-translate-y: 230px;
  }

  .md\:focus\:translate-y-180px:focus {
    --tw-translate-y: 180px;
  }

  .md\:focus\:translate-y-147px:focus {
    --tw-translate-y: 147px;
  }

  .md\:focus\:translate-y-123px:focus {
    --tw-translate-y: 123px;
  }

  .md\:focus\:translate-y-100px:focus {
    --tw-translate-y: 100px;
  }

  .md\:focus\:translate-y-80px:focus {
    --tw-translate-y: 80px;
  }

  .md\:focus\:translate-y-60px:focus {
    --tw-translate-y: 60px;
  }

  .md\:focus\:translate-y-50px:focus {
    --tw-translate-y: 50px;
  }

  .md\:focus\:translate-y-40px:focus {
    --tw-translate-y: 40px;
  }

  .md\:focus\:translate-y-35px:focus {
    --tw-translate-y: 35px;
  }

  .md\:focus\:translate-y-34px-i:focus {
    --tw-translate-y: 34px !important;
  }

  .md\:focus\:translate-y-30px-i:focus {
    --tw-translate-y: 30px !important;
  }

  .md\:focus\:translate-y-24px-i:focus {
    --tw-translate-y: 24px !important;
  }

  .md\:focus\:translate-y-20px-i:focus {
    --tw-translate-y: 20px !important;
  }

  .md\:focus\:translate-y-15px-i:focus {
    --tw-translate-y: 15px !important;
  }

  .md\:focus\:translate-y-13px-i:focus {
    --tw-translate-y: 13px !important;
  }

  .md\:focus\:translate-y-12px-i:focus {
    --tw-translate-y: 12px !important;
  }

  .md\:focus\:translate-y-10px-i:focus {
    --tw-translate-y: 10px !important;
  }

  .md\:focus\:translate-y-8px-i:focus {
    --tw-translate-y: 8px !important;
  }

  .md\:focus\:translate-y-6px-i:focus {
    --tw-translate-y: 6px !important;
  }

  .md\:focus\:translate-y-5px-i:focus {
    --tw-translate-y: 5px !important;
  }

  .md\:focus\:translate-y-3px-i:focus {
    --tw-translate-y: 3px !important;
  }

  .md\:focus\:translate-y-0px-i:focus {
    --tw-translate-y: 0px !important;
  }

  .md\:focus\:translate-y-34px:focus {
    --tw-translate-y: 34px;
  }

  .md\:focus\:translate-y-30px:focus {
    --tw-translate-y: 30px;
  }

  .md\:focus\:translate-y-24px:focus {
    --tw-translate-y: 24px;
  }

  .md\:focus\:translate-y-20px:focus {
    --tw-translate-y: 20px;
  }

  .md\:focus\:translate-y-15px:focus {
    --tw-translate-y: 15px;
  }

  .md\:focus\:translate-y-13px:focus {
    --tw-translate-y: 13px;
  }

  .md\:focus\:translate-y-12px:focus {
    --tw-translate-y: 12px;
  }

  .md\:focus\:translate-y-10px:focus {
    --tw-translate-y: 10px;
  }

  .md\:focus\:translate-y-8px:focus {
    --tw-translate-y: 8px;
  }

  .md\:focus\:translate-y-6px:focus {
    --tw-translate-y: 6px;
  }

  .md\:focus\:translate-y-5px:focus {
    --tw-translate-y: 5px;
  }

  .md\:focus\:translate-y-3px:focus {
    --tw-translate-y: 3px;
  }

  .md\:focus\:translate-y-menu:focus {
    --tw-translate-y: 275px;
  }

  .md\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px !important;
  }

  .md\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }

  .md\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }

  .md\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }

  .md\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .md\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .md\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .md\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .md\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .md\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .md\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .md\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .md\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .md\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .md\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .md\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .md\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .md\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .md\:focus\:-translate-y-300px:focus {
    --tw-translate-y: -300px;
  }

  .md\:focus\:-translate-y-230px:focus {
    --tw-translate-y: -230px;
  }

  .md\:focus\:-translate-y-180px:focus {
    --tw-translate-y: -180px;
  }

  .md\:focus\:-translate-y-147px:focus {
    --tw-translate-y: -147px;
  }

  .md\:focus\:-translate-y-123px:focus {
    --tw-translate-y: -123px;
  }

  .md\:focus\:-translate-y-100px:focus {
    --tw-translate-y: -100px;
  }

  .md\:focus\:-translate-y-80px:focus {
    --tw-translate-y: -80px;
  }

  .md\:focus\:-translate-y-60px:focus {
    --tw-translate-y: -60px;
  }

  .md\:focus\:-translate-y-50px:focus {
    --tw-translate-y: -50px;
  }

  .md\:focus\:-translate-y-40px:focus {
    --tw-translate-y: -40px;
  }

  .md\:focus\:-translate-y-35px:focus {
    --tw-translate-y: -35px;
  }

  .md\:focus\:-translate-y-34px-i:focus {
    --tw-translate-y: 34px !important;
  }

  .md\:focus\:-translate-y-30px-i:focus {
    --tw-translate-y: 30px !important;
  }

  .md\:focus\:-translate-y-24px-i:focus {
    --tw-translate-y: 24px !important;
  }

  .md\:focus\:-translate-y-20px-i:focus {
    --tw-translate-y: 20px !important;
  }

  .md\:focus\:-translate-y-15px-i:focus {
    --tw-translate-y: 15px !important;
  }

  .md\:focus\:-translate-y-13px-i:focus {
    --tw-translate-y: 13px !important;
  }

  .md\:focus\:-translate-y-12px-i:focus {
    --tw-translate-y: 12px !important;
  }

  .md\:focus\:-translate-y-10px-i:focus {
    --tw-translate-y: 10px !important;
  }

  .md\:focus\:-translate-y-8px-i:focus {
    --tw-translate-y: 8px !important;
  }

  .md\:focus\:-translate-y-6px-i:focus {
    --tw-translate-y: 6px !important;
  }

  .md\:focus\:-translate-y-5px-i:focus {
    --tw-translate-y: 5px !important;
  }

  .md\:focus\:-translate-y-3px-i:focus {
    --tw-translate-y: 3px !important;
  }

  .md\:focus\:-translate-y-0px-i:focus {
    --tw-translate-y: 0px !important;
  }

  .md\:focus\:-translate-y-34px:focus {
    --tw-translate-y: -34px;
  }

  .md\:focus\:-translate-y-30px:focus {
    --tw-translate-y: -30px;
  }

  .md\:focus\:-translate-y-24px:focus {
    --tw-translate-y: -24px;
  }

  .md\:focus\:-translate-y-20px:focus {
    --tw-translate-y: -20px;
  }

  .md\:focus\:-translate-y-15px:focus {
    --tw-translate-y: -15px;
  }

  .md\:focus\:-translate-y-13px:focus {
    --tw-translate-y: -13px;
  }

  .md\:focus\:-translate-y-12px:focus {
    --tw-translate-y: -12px;
  }

  .md\:focus\:-translate-y-10px:focus {
    --tw-translate-y: -10px;
  }

  .md\:focus\:-translate-y-8px:focus {
    --tw-translate-y: -8px;
  }

  .md\:focus\:-translate-y-6px:focus {
    --tw-translate-y: -6px;
  }

  .md\:focus\:-translate-y-5px:focus {
    --tw-translate-y: -5px;
  }

  .md\:focus\:-translate-y-3px:focus {
    --tw-translate-y: -3px;
  }

  .md\:focus\:-translate-y-menu:focus {
    --tw-translate-y: -275px;
  }

  .md\:focus\:-translate-y-px:focus {
    --tw-translate-y: 1px !important;
  }

  .md\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .md\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .md\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .md\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .md\:rotate-0 {
    --tw-rotate: 0;
  }

  .md\:rotate-1 {
    --tw-rotate: 1deg;
  }

  .md\:rotate-2 {
    --tw-rotate: 2deg;
  }

  .md\:rotate-3 {
    --tw-rotate: 3deg;
  }

  .md\:rotate-6 {
    --tw-rotate: 6deg;
  }

  .md\:rotate-12 {
    --tw-rotate: 12deg;
  }

  .md\:rotate-45 {
    --tw-rotate: 45deg;
  }

  .md\:rotate-90 {
    --tw-rotate: 90deg;
  }

  .md\:rotate-180 {
    --tw-rotate: 180deg;
  }

  .md\:-rotate-180 {
    --tw-rotate: -180deg;
  }

  .md\:-rotate-90 {
    --tw-rotate: -90deg;
  }

  .md\:-rotate-45 {
    --tw-rotate: -45deg;
  }

  .md\:-rotate-12 {
    --tw-rotate: -12deg;
  }

  .md\:-rotate-6 {
    --tw-rotate: -6deg;
  }

  .md\:-rotate-3 {
    --tw-rotate: -3deg;
  }

  .md\:-rotate-2 {
    --tw-rotate: -2deg;
  }

  .md\:-rotate-1 {
    --tw-rotate: -1deg;
  }

  .md\:hover\:rotate-0:hover {
    --tw-rotate: 0;
  }

  .md\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .md\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .md\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .md\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .md\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .md\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .md\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .md\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .md\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .md\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .md\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .md\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .md\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .md\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .md\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .md\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .md\:focus\:rotate-0:focus {
    --tw-rotate: 0;
  }

  .md\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .md\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .md\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .md\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .md\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .md\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .md\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .md\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .md\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .md\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .md\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .md\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .md\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .md\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .md\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .md\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .md\:skew-x-0 {
    --tw-skew-x: 0;
  }

  .md\:skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .md\:skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .md\:skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .md\:skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .md\:skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .md\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .md\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .md\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .md\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .md\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .md\:skew-y-0 {
    --tw-skew-y: 0;
  }

  .md\:skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .md\:skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .md\:skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .md\:skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .md\:skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .md\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .md\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .md\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .md\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .md\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .md\:hover\:skew-x-0:hover {
    --tw-skew-x: 0;
  }

  .md\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .md\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .md\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .md\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .md\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .md\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .md\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .md\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .md\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .md\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .md\:hover\:skew-y-0:hover {
    --tw-skew-y: 0;
  }

  .md\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .md\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .md\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .md\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .md\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .md\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .md\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .md\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .md\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .md\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .md\:focus\:skew-x-0:focus {
    --tw-skew-x: 0;
  }

  .md\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .md\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .md\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .md\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .md\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .md\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .md\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .md\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .md\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .md\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .md\:focus\:skew-y-0:focus {
    --tw-skew-y: 0;
  }

  .md\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .md\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .md\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .md\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .md\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .md\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .md\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .md\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .md\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .md\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .md\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .md\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .md\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .md\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .md\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .md\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .md\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .md\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .md\:scale-x-0 {
    --tw-scale-x: 0;
  }

  .md\:scale-x-50 {
    --tw-scale-x: .5;
  }

  .md\:scale-x-75 {
    --tw-scale-x: .75;
  }

  .md\:scale-x-90 {
    --tw-scale-x: .9;
  }

  .md\:scale-x-95 {
    --tw-scale-x: .95;
  }

  .md\:scale-x-100 {
    --tw-scale-x: 1;
  }

  .md\:scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .md\:scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .md\:scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .md\:scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .md\:scale-y-0 {
    --tw-scale-y: 0;
  }

  .md\:scale-y-50 {
    --tw-scale-y: .5;
  }

  .md\:scale-y-75 {
    --tw-scale-y: .75;
  }

  .md\:scale-y-90 {
    --tw-scale-y: .9;
  }

  .md\:scale-y-95 {
    --tw-scale-y: .95;
  }

  .md\:scale-y-100 {
    --tw-scale-y: 1;
  }

  .md\:scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .md\:scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .md\:scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .md\:scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .md\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .md\:hover\:scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .md\:hover\:scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .md\:hover\:scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .md\:hover\:scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .md\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .md\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .md\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .md\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .md\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .md\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .md\:hover\:scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .md\:hover\:scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .md\:hover\:scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .md\:hover\:scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .md\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .md\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .md\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .md\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .md\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .md\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .md\:focus\:scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .md\:focus\:scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .md\:focus\:scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .md\:focus\:scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .md\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .md\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .md\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .md\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .md\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .md\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .md\:focus\:scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .md\:focus\:scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .md\:focus\:scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .md\:focus\:scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .md\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .md\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .md\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .md\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .md\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .md\:animate-none {
    animation: none;
  }

  .md\:animate-spin {
    animation: spin 1s linear infinite;
  }

  .md\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .md\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .md\:animate-bounce {
    animation: bounce 1s infinite;
  }

  .md\:cursor-auto {
    cursor: auto;
  }

  .md\:cursor-default {
    cursor: default;
  }

  .md\:cursor-pointer {
    cursor: pointer;
  }

  .md\:cursor-wait {
    cursor: wait;
  }

  .md\:cursor-text {
    cursor: text;
  }

  .md\:cursor-move {
    cursor: move;
  }

  .md\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .md\:select-none {
    -webkit-user-select: none;
            user-select: none;
  }

  .md\:select-text {
    -webkit-user-select: text;
            user-select: text;
  }

  .md\:select-all {
    -webkit-user-select: all;
            user-select: all;
  }

  .md\:select-auto {
    -webkit-user-select: auto;
            user-select: auto;
  }

  .md\:resize-none {
    resize: none;
  }

  .md\:resize-y {
    resize: vertical;
  }

  .md\:resize-x {
    resize: horizontal;
  }

  .md\:resize {
    resize: both;
  }

  .md\:list-inside {
    list-style-position: inside;
  }

  .md\:list-outside {
    list-style-position: outside;
  }

  .md\:list-none {
    list-style-type: none;
  }

  .md\:list-disc {
    list-style-type: disc;
  }

  .md\:list-decimal {
    list-style-type: decimal;
  }

  .md\:appearance-none {
    appearance: none;
  }

  .md\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .md\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .md\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .md\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .md\:grid-flow-row {
    grid-auto-flow: row;
  }

  .md\:grid-flow-col {
    grid-auto-flow: column;
  }

  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .md\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .md\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .md\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .md\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .md\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-cols-none {
    grid-template-columns: none;
  }

  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-rows-none {
    grid-template-rows: none;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:place-content-center {
    place-content: center;
  }

  .md\:place-content-start {
    place-content: start;
  }

  .md\:place-content-end {
    place-content: end;
  }

  .md\:place-content-between {
    place-content: space-between;
  }

  .md\:place-content-around {
    place-content: space-around;
  }

  .md\:place-content-evenly {
    place-content: space-evenly;
  }

  .md\:place-content-stretch {
    place-content: stretch;
  }

  .md\:place-items-start {
    place-items: start;
  }

  .md\:place-items-end {
    place-items: end;
  }

  .md\:place-items-center {
    place-items: center;
  }

  .md\:place-items-stretch {
    place-items: stretch;
  }

  .md\:content-center {
    align-content: center;
  }

  .md\:content-start {
    align-content: flex-start;
  }

  .md\:content-end {
    align-content: flex-end;
  }

  .md\:content-between {
    align-content: space-between;
  }

  .md\:content-around {
    align-content: space-around;
  }

  .md\:content-evenly {
    align-content: space-evenly;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:items-baseline {
    align-items: baseline;
  }

  .md\:items-stretch {
    align-items: stretch;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:justify-around {
    justify-content: space-around;
  }

  .md\:justify-evenly {
    justify-content: space-evenly;
  }

  .md\:justify-items-start {
    justify-items: start;
  }

  .md\:justify-items-end {
    justify-items: end;
  }

  .md\:justify-items-center {
    justify-items: center;
  }

  .md\:justify-items-stretch {
    justify-items: stretch;
  }

  .md\:gap-0 {
    gap: 0;
  }

  .md\:gap-1 {
    gap: 0.25rem;
  }

  .md\:gap-2 {
    gap: 0.5rem;
  }

  .md\:gap-3 {
    gap: 0.75rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-5 {
    gap: 1.25rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-12 {
    gap: 3rem;
  }

  .md\:gap-16 {
    gap: 4rem;
  }

  .md\:gap-20 {
    gap: 5rem;
  }

  .md\:gap-24 {
    gap: 6rem;
  }

  .md\:gap-32 {
    gap: 8rem;
  }

  .md\:gap-40 {
    gap: 10rem;
  }

  .md\:gap-48 {
    gap: 12rem;
  }

  .md\:gap-56 {
    gap: 14rem;
  }

  .md\:gap-64 {
    gap: 16rem;
  }

  .md\:gap-300px {
    gap: 300px;
  }

  .md\:gap-230px {
    gap: 230px;
  }

  .md\:gap-180px {
    gap: 180px;
  }

  .md\:gap-147px {
    gap: 147px;
  }

  .md\:gap-123px {
    gap: 123px;
  }

  .md\:gap-100px {
    gap: 100px;
  }

  .md\:gap-80px {
    gap: 80px;
  }

  .md\:gap-60px {
    gap: 60px;
  }

  .md\:gap-50px {
    gap: 50px;
  }

  .md\:gap-40px {
    gap: 40px;
  }

  .md\:gap-35px {
    gap: 35px;
  }

  .md\:gap-34px-i {
    gap: 34px !important;
  }

  .md\:gap-30px-i {
    gap: 30px !important;
  }

  .md\:gap-24px-i {
    gap: 24px !important;
  }

  .md\:gap-20px-i {
    gap: 20px !important;
  }

  .md\:gap-15px-i {
    gap: 15px !important;
  }

  .md\:gap-13px-i {
    gap: 13px !important;
  }

  .md\:gap-12px-i {
    gap: 12px !important;
  }

  .md\:gap-10px-i {
    gap: 10px !important;
  }

  .md\:gap-8px-i {
    gap: 8px !important;
  }

  .md\:gap-6px-i {
    gap: 6px !important;
  }

  .md\:gap-5px-i {
    gap: 5px !important;
  }

  .md\:gap-3px-i {
    gap: 3px !important;
  }

  .md\:gap-0px-i {
    gap: 0px !important;
  }

  .md\:gap-34px {
    gap: 34px;
  }

  .md\:gap-30px {
    gap: 30px;
  }

  .md\:gap-24px {
    gap: 24px;
  }

  .md\:gap-20px {
    gap: 20px;
  }

  .md\:gap-15px {
    gap: 15px;
  }

  .md\:gap-13px {
    gap: 13px;
  }

  .md\:gap-12px {
    gap: 12px;
  }

  .md\:gap-10px {
    gap: 10px;
  }

  .md\:gap-8px {
    gap: 8px;
  }

  .md\:gap-6px {
    gap: 6px;
  }

  .md\:gap-5px {
    gap: 5px;
  }

  .md\:gap-3px {
    gap: 3px;
  }

  .md\:gap-menu {
    gap: 275px;
  }

  .md\:gap-px {
    gap: 1px !important;
  }

  .md\:gap-x-0 {
    column-gap: 0;
  }

  .md\:gap-x-1 {
    column-gap: 0.25rem;
  }

  .md\:gap-x-2 {
    column-gap: 0.5rem;
  }

  .md\:gap-x-3 {
    column-gap: 0.75rem;
  }

  .md\:gap-x-4 {
    column-gap: 1rem;
  }

  .md\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .md\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .md\:gap-x-8 {
    column-gap: 2rem;
  }

  .md\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .md\:gap-x-12 {
    column-gap: 3rem;
  }

  .md\:gap-x-16 {
    column-gap: 4rem;
  }

  .md\:gap-x-20 {
    column-gap: 5rem;
  }

  .md\:gap-x-24 {
    column-gap: 6rem;
  }

  .md\:gap-x-32 {
    column-gap: 8rem;
  }

  .md\:gap-x-40 {
    column-gap: 10rem;
  }

  .md\:gap-x-48 {
    column-gap: 12rem;
  }

  .md\:gap-x-56 {
    column-gap: 14rem;
  }

  .md\:gap-x-64 {
    column-gap: 16rem;
  }

  .md\:gap-x-300px {
    column-gap: 300px;
  }

  .md\:gap-x-230px {
    column-gap: 230px;
  }

  .md\:gap-x-180px {
    column-gap: 180px;
  }

  .md\:gap-x-147px {
    column-gap: 147px;
  }

  .md\:gap-x-123px {
    column-gap: 123px;
  }

  .md\:gap-x-100px {
    column-gap: 100px;
  }

  .md\:gap-x-80px {
    column-gap: 80px;
  }

  .md\:gap-x-60px {
    column-gap: 60px;
  }

  .md\:gap-x-50px {
    column-gap: 50px;
  }

  .md\:gap-x-40px {
    column-gap: 40px;
  }

  .md\:gap-x-35px {
    column-gap: 35px;
  }

  .md\:gap-x-34px-i {
    column-gap: 34px !important;
  }

  .md\:gap-x-30px-i {
    column-gap: 30px !important;
  }

  .md\:gap-x-24px-i {
    column-gap: 24px !important;
  }

  .md\:gap-x-20px-i {
    column-gap: 20px !important;
  }

  .md\:gap-x-15px-i {
    column-gap: 15px !important;
  }

  .md\:gap-x-13px-i {
    column-gap: 13px !important;
  }

  .md\:gap-x-12px-i {
    column-gap: 12px !important;
  }

  .md\:gap-x-10px-i {
    column-gap: 10px !important;
  }

  .md\:gap-x-8px-i {
    column-gap: 8px !important;
  }

  .md\:gap-x-6px-i {
    column-gap: 6px !important;
  }

  .md\:gap-x-5px-i {
    column-gap: 5px !important;
  }

  .md\:gap-x-3px-i {
    column-gap: 3px !important;
  }

  .md\:gap-x-0px-i {
    column-gap: 0px !important;
  }

  .md\:gap-x-34px {
    column-gap: 34px;
  }

  .md\:gap-x-30px {
    column-gap: 30px;
  }

  .md\:gap-x-24px {
    column-gap: 24px;
  }

  .md\:gap-x-20px {
    column-gap: 20px;
  }

  .md\:gap-x-15px {
    column-gap: 15px;
  }

  .md\:gap-x-13px {
    column-gap: 13px;
  }

  .md\:gap-x-12px {
    column-gap: 12px;
  }

  .md\:gap-x-10px {
    column-gap: 10px;
  }

  .md\:gap-x-8px {
    column-gap: 8px;
  }

  .md\:gap-x-6px {
    column-gap: 6px;
  }

  .md\:gap-x-5px {
    column-gap: 5px;
  }

  .md\:gap-x-3px {
    column-gap: 3px;
  }

  .md\:gap-x-menu {
    column-gap: 275px;
  }

  .md\:gap-x-px {
    column-gap: 1px !important;
  }

  .md\:gap-y-0 {
    row-gap: 0;
  }

  .md\:gap-y-1 {
    row-gap: 0.25rem;
  }

  .md\:gap-y-2 {
    row-gap: 0.5rem;
  }

  .md\:gap-y-3 {
    row-gap: 0.75rem;
  }

  .md\:gap-y-4 {
    row-gap: 1rem;
  }

  .md\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .md\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .md\:gap-y-8 {
    row-gap: 2rem;
  }

  .md\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .md\:gap-y-12 {
    row-gap: 3rem;
  }

  .md\:gap-y-16 {
    row-gap: 4rem;
  }

  .md\:gap-y-20 {
    row-gap: 5rem;
  }

  .md\:gap-y-24 {
    row-gap: 6rem;
  }

  .md\:gap-y-32 {
    row-gap: 8rem;
  }

  .md\:gap-y-40 {
    row-gap: 10rem;
  }

  .md\:gap-y-48 {
    row-gap: 12rem;
  }

  .md\:gap-y-56 {
    row-gap: 14rem;
  }

  .md\:gap-y-64 {
    row-gap: 16rem;
  }

  .md\:gap-y-300px {
    row-gap: 300px;
  }

  .md\:gap-y-230px {
    row-gap: 230px;
  }

  .md\:gap-y-180px {
    row-gap: 180px;
  }

  .md\:gap-y-147px {
    row-gap: 147px;
  }

  .md\:gap-y-123px {
    row-gap: 123px;
  }

  .md\:gap-y-100px {
    row-gap: 100px;
  }

  .md\:gap-y-80px {
    row-gap: 80px;
  }

  .md\:gap-y-60px {
    row-gap: 60px;
  }

  .md\:gap-y-50px {
    row-gap: 50px;
  }

  .md\:gap-y-40px {
    row-gap: 40px;
  }

  .md\:gap-y-35px {
    row-gap: 35px;
  }

  .md\:gap-y-34px-i {
    row-gap: 34px !important;
  }

  .md\:gap-y-30px-i {
    row-gap: 30px !important;
  }

  .md\:gap-y-24px-i {
    row-gap: 24px !important;
  }

  .md\:gap-y-20px-i {
    row-gap: 20px !important;
  }

  .md\:gap-y-15px-i {
    row-gap: 15px !important;
  }

  .md\:gap-y-13px-i {
    row-gap: 13px !important;
  }

  .md\:gap-y-12px-i {
    row-gap: 12px !important;
  }

  .md\:gap-y-10px-i {
    row-gap: 10px !important;
  }

  .md\:gap-y-8px-i {
    row-gap: 8px !important;
  }

  .md\:gap-y-6px-i {
    row-gap: 6px !important;
  }

  .md\:gap-y-5px-i {
    row-gap: 5px !important;
  }

  .md\:gap-y-3px-i {
    row-gap: 3px !important;
  }

  .md\:gap-y-0px-i {
    row-gap: 0px !important;
  }

  .md\:gap-y-34px {
    row-gap: 34px;
  }

  .md\:gap-y-30px {
    row-gap: 30px;
  }

  .md\:gap-y-24px {
    row-gap: 24px;
  }

  .md\:gap-y-20px {
    row-gap: 20px;
  }

  .md\:gap-y-15px {
    row-gap: 15px;
  }

  .md\:gap-y-13px {
    row-gap: 13px;
  }

  .md\:gap-y-12px {
    row-gap: 12px;
  }

  .md\:gap-y-10px {
    row-gap: 10px;
  }

  .md\:gap-y-8px {
    row-gap: 8px;
  }

  .md\:gap-y-6px {
    row-gap: 6px;
  }

  .md\:gap-y-5px {
    row-gap: 5px;
  }

  .md\:gap-y-3px {
    row-gap: 3px;
  }

  .md\:gap-y-menu {
    row-gap: 275px;
  }

  .md\:gap-y-px {
    row-gap: 1px !important;
  }

  .md\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(300px * var(--tw-space-x-reverse));
    margin-left: calc(300px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(230px * var(--tw-space-x-reverse));
    margin-left: calc(230px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(180px * var(--tw-space-x-reverse));
    margin-left: calc(180px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(147px * var(--tw-space-x-reverse));
    margin-left: calc(147px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(123px * var(--tw-space-x-reverse));
    margin-left: calc(123px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(100px * var(--tw-space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(80px * var(--tw-space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(60px * var(--tw-space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(50px * var(--tw-space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(40px * var(--tw-space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(35px * var(--tw-space-x-reverse));
    margin-left: calc(35px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px !important * var(--tw-space-x-reverse));
    margin-left: calc(34px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px !important * var(--tw-space-x-reverse));
    margin-left: calc(30px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px !important * var(--tw-space-x-reverse));
    margin-left: calc(24px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px !important * var(--tw-space-x-reverse));
    margin-left: calc(20px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px !important * var(--tw-space-x-reverse));
    margin-left: calc(15px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px !important * var(--tw-space-x-reverse));
    margin-left: calc(13px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px !important * var(--tw-space-x-reverse));
    margin-left: calc(12px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px !important * var(--tw-space-x-reverse));
    margin-left: calc(10px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px !important * var(--tw-space-x-reverse));
    margin-left: calc(8px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px !important * var(--tw-space-x-reverse));
    margin-left: calc(6px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px !important * var(--tw-space-x-reverse));
    margin-left: calc(5px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px !important * var(--tw-space-x-reverse));
    margin-left: calc(3px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px !important * var(--tw-space-x-reverse));
    margin-left: calc(0px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px * var(--tw-space-x-reverse));
    margin-left: calc(34px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px * var(--tw-space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px * var(--tw-space-x-reverse));
    margin-left: calc(24px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px * var(--tw-space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px * var(--tw-space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px * var(--tw-space-x-reverse));
    margin-left: calc(13px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px * var(--tw-space-x-reverse));
    margin-left: calc(12px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px * var(--tw-space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px * var(--tw-space-x-reverse));
    margin-left: calc(8px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px * var(--tw-space-x-reverse));
    margin-left: calc(6px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px * var(--tw-space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px * var(--tw-space-x-reverse));
    margin-left: calc(3px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(275px * var(--tw-space-x-reverse));
    margin-left: calc(275px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px !important * var(--tw-space-x-reverse));
    margin-left: calc(1px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-300px * var(--tw-space-x-reverse));
    margin-left: calc(-300px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-230px * var(--tw-space-x-reverse));
    margin-left: calc(-230px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-180px * var(--tw-space-x-reverse));
    margin-left: calc(-180px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-147px * var(--tw-space-x-reverse));
    margin-left: calc(-147px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-123px * var(--tw-space-x-reverse));
    margin-left: calc(-123px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-100px * var(--tw-space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-80px * var(--tw-space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-60px * var(--tw-space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-50px * var(--tw-space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-40px * var(--tw-space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-35px * var(--tw-space-x-reverse));
    margin-left: calc(-35px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px !important * var(--tw-space-x-reverse));
    margin-left: calc(34px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px !important * var(--tw-space-x-reverse));
    margin-left: calc(30px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px !important * var(--tw-space-x-reverse));
    margin-left: calc(24px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px !important * var(--tw-space-x-reverse));
    margin-left: calc(20px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px !important * var(--tw-space-x-reverse));
    margin-left: calc(15px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px !important * var(--tw-space-x-reverse));
    margin-left: calc(13px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px !important * var(--tw-space-x-reverse));
    margin-left: calc(12px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px !important * var(--tw-space-x-reverse));
    margin-left: calc(10px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px !important * var(--tw-space-x-reverse));
    margin-left: calc(8px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px !important * var(--tw-space-x-reverse));
    margin-left: calc(6px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px !important * var(--tw-space-x-reverse));
    margin-left: calc(5px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px !important * var(--tw-space-x-reverse));
    margin-left: calc(3px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px !important * var(--tw-space-x-reverse));
    margin-left: calc(0px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-34px * var(--tw-space-x-reverse));
    margin-left: calc(-34px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-30px * var(--tw-space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24px * var(--tw-space-x-reverse));
    margin-left: calc(-24px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20px * var(--tw-space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15px * var(--tw-space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13px * var(--tw-space-x-reverse));
    margin-left: calc(-13px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12px * var(--tw-space-x-reverse));
    margin-left: calc(-12px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10px * var(--tw-space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8px * var(--tw-space-x-reverse));
    margin-left: calc(-8px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6px * var(--tw-space-x-reverse));
    margin-left: calc(-6px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5px * var(--tw-space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3px * var(--tw-space-x-reverse));
    margin-left: calc(-3px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-275px * var(--tw-space-x-reverse));
    margin-left: calc(-275px * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px !important * var(--tw-space-x-reverse));
    margin-left: calc(1px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(300px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(300px * var(--tw-space-y-reverse));
  }

  .md\:space-y-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(230px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(230px * var(--tw-space-y-reverse));
  }

  .md\:space-y-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(180px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(180px * var(--tw-space-y-reverse));
  }

  .md\:space-y-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(147px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(147px * var(--tw-space-y-reverse));
  }

  .md\:space-y-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(123px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(123px * var(--tw-space-y-reverse));
  }

  .md\:space-y-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(100px * var(--tw-space-y-reverse));
  }

  .md\:space-y-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(80px * var(--tw-space-y-reverse));
  }

  .md\:space-y-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(60px * var(--tw-space-y-reverse));
  }

  .md\:space-y-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(50px * var(--tw-space-y-reverse));
  }

  .md\:space-y-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(40px * var(--tw-space-y-reverse));
  }

  .md\:space-y-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(35px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(35px * var(--tw-space-y-reverse));
  }

  .md\:space-y-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px * var(--tw-space-y-reverse));
  }

  .md\:space-y-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px * var(--tw-space-y-reverse));
  }

  .md\:space-y-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px * var(--tw-space-y-reverse));
  }

  .md\:space-y-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px * var(--tw-space-y-reverse));
  }

  .md\:space-y-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px * var(--tw-space-y-reverse));
  }

  .md\:space-y-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px * var(--tw-space-y-reverse));
  }

  .md\:space-y-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px * var(--tw-space-y-reverse));
  }

  .md\:space-y-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px * var(--tw-space-y-reverse));
  }

  .md\:space-y-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px * var(--tw-space-y-reverse));
  }

  .md\:space-y-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px * var(--tw-space-y-reverse));
  }

  .md\:space-y-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px * var(--tw-space-y-reverse));
  }

  .md\:space-y-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px * var(--tw-space-y-reverse));
  }

  .md\:space-y-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(275px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(275px * var(--tw-space-y-reverse));
  }

  .md\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .md\:-space-y-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-300px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-300px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-230px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-230px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-180px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-180px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-147px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-147px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-123px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-123px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-100px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-80px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-60px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-50px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-40px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-35px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-35px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px !important * var(--tw-space-y-reverse));
  }

  .md\:-space-y-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-34px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-34px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-30px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-275px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-275px * var(--tw-space-y-reverse));
  }

  .md\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px !important * var(--tw-space-y-reverse));
  }

  .md\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .md\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .md\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-x-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .md\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .md\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .md\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .md\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .md\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .md\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .md\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .md\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .md\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }

  .md\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .md\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-divide-opacity));
  }

  .md\:divide-black40pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-divide-opacity));
  }

  .md\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .md\:divide-petroleum > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-divide-opacity));
  }

  .md\:divide-petroleum70pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-divide-opacity));
  }

  .md\:divide-petroleum30pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-divide-opacity));
  }

  .md\:divide-disabled > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-divide-opacity));
  }

  .md\:divide-petroleum10pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-divide-opacity));
  }

  .md\:divide-petroleum5pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-divide-opacity));
  }

  .md\:divide-darkGreen > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-divide-opacity));
  }

  .md\:divide-ok > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-divide-opacity));
  }

  .md\:divide-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-divide-opacity));
  }

  .md\:divide-green10pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-divide-opacity));
  }

  .md\:divide-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-divide-opacity));
  }

  .md\:divide-darkOrange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-divide-opacity));
  }

  .md\:divide-lightOrange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-divide-opacity));
  }

  .md\:divide-error > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-divide-opacity));
  }

  .md\:divide-yellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-divide-opacity));
  }

  .md\:divide-darkYellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-divide-opacity));
  }

  .md\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-divide-opacity));
  }

  .md\:divide-lightGray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-divide-opacity));
  }

  .md\:divide-borderGray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-divide-opacity));
  }

  .md\:divide-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-divide-opacity));
  }

  .md\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .md\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }

  .md\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }

  .md\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }

  .md\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .md\:place-self-auto {
    place-self: auto;
  }

  .md\:place-self-start {
    place-self: start;
  }

  .md\:place-self-end {
    place-self: end;
  }

  .md\:place-self-center {
    place-self: center;
  }

  .md\:place-self-stretch {
    place-self: stretch;
  }

  .md\:self-auto {
    align-self: auto;
  }

  .md\:self-start {
    align-self: flex-start;
  }

  .md\:self-end {
    align-self: flex-end;
  }

  .md\:self-center {
    align-self: center;
  }

  .md\:self-stretch {
    align-self: stretch;
  }

  .md\:self-baseline {
    align-self: baseline;
  }

  .md\:justify-self-auto {
    justify-self: auto;
  }

  .md\:justify-self-start {
    justify-self: start;
  }

  .md\:justify-self-end {
    justify-self: end;
  }

  .md\:justify-self-center {
    justify-self: center;
  }

  .md\:justify-self-stretch {
    justify-self: stretch;
  }

  .md\:overflow-auto {
    overflow: auto;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:overflow-visible {
    overflow: visible;
  }

  .md\:overflow-scroll {
    overflow: scroll;
  }

  .md\:overflow-x-auto {
    overflow-x: auto;
  }

  .md\:overflow-y-auto {
    overflow-y: auto;
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .md\:overflow-x-visible {
    overflow-x: visible;
  }

  .md\:overflow-y-visible {
    overflow-y: visible;
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .md\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .md\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .md\:overscroll-none {
    overscroll-behavior: none;
  }

  .md\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .md\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .md\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .md\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .md\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .md\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .md\:whitespace-normal {
    white-space: normal;
  }

  .md\:whitespace-nowrap {
    white-space: nowrap;
  }

  .md\:whitespace-pre {
    white-space: pre;
  }

  .md\:whitespace-pre-line {
    white-space: pre-line;
  }

  .md\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .md\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .md\:break-words {
    overflow-wrap: break-word;
  }

  .md\:break-all {
    word-break: break-all;
  }

  .md\:rounded-none {
    border-radius: 0;
  }

  .md\:rounded-5px {
    border-radius: 5px;
  }

  .md\:rounded-sm {
    border-radius: 0.125rem;
  }

  .md\:rounded-default {
    border-radius: 0.25rem;
  }

  .md\:rounded-md {
    border-radius: 0.375rem;
  }

  .md\:rounded-lg {
    border-radius: 0.5rem;
  }

  .md\:rounded-xl {
    border-radius: 0.75rem;
  }

  .md\:rounded-2xl {
    border-radius: 1rem;
  }

  .md\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .md\:rounded-full {
    border-radius: 9999px;
  }

  .md\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .md\:rounded-t-5px {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .md\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .md\:rounded-t-default {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .md\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .md\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .md\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .md\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .md\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .md\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .md\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .md\:rounded-r-5px {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .md\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .md\:rounded-r-default {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .md\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .md\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .md\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .md\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .md\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .md\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .md\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md\:rounded-b-5px {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .md\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .md\:rounded-b-default {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .md\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .md\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .md\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .md\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md\:rounded-l-5px {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .md\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .md\:rounded-l-default {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .md\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .md\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .md\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .md\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .md\:rounded-tl-5px {
    border-top-left-radius: 5px;
  }

  .md\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .md\:rounded-tl-default {
    border-top-left-radius: 0.25rem;
  }

  .md\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .md\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .md\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .md\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .md\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .md\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .md\:rounded-tr-5px {
    border-top-right-radius: 5px;
  }

  .md\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .md\:rounded-tr-default {
    border-top-right-radius: 0.25rem;
  }

  .md\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .md\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .md\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .md\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .md\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .md\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .md\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .md\:rounded-br-5px {
    border-bottom-right-radius: 5px;
  }

  .md\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .md\:rounded-br-default {
    border-bottom-right-radius: 0.25rem;
  }

  .md\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .md\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .md\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .md\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .md\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .md\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .md\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .md\:rounded-bl-5px {
    border-bottom-left-radius: 5px;
  }

  .md\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .md\:rounded-bl-default {
    border-bottom-left-radius: 0.25rem;
  }

  .md\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .md\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .md\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .md\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .md\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .md\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .md\:border-0 {
    border-width: 0;
  }

  .md\:border-2 {
    border-width: 2px;
  }

  .md\:border-4 {
    border-width: 4px;
  }

  .md\:border-8 {
    border-width: 8px;
  }

  .md\:border-default {
    border-width: 1px;
  }

  .md\:border-t-0 {
    border-top-width: 0;
  }

  .md\:border-t-2 {
    border-top-width: 2px;
  }

  .md\:border-t-4 {
    border-top-width: 4px;
  }

  .md\:border-t-8 {
    border-top-width: 8px;
  }

  .md\:border-t-default {
    border-top-width: 1px;
  }

  .md\:border-r-0 {
    border-right-width: 0;
  }

  .md\:border-r-2 {
    border-right-width: 2px;
  }

  .md\:border-r-4 {
    border-right-width: 4px;
  }

  .md\:border-r-8 {
    border-right-width: 8px;
  }

  .md\:border-r-default {
    border-right-width: 1px;
  }

  .md\:border-b-0 {
    border-bottom-width: 0;
  }

  .md\:border-b-2 {
    border-bottom-width: 2px;
  }

  .md\:border-b-4 {
    border-bottom-width: 4px;
  }

  .md\:border-b-8 {
    border-bottom-width: 8px;
  }

  .md\:border-b-default {
    border-bottom-width: 1px;
  }

  .md\:border-l-0 {
    border-left-width: 0;
  }

  .md\:border-l-2 {
    border-left-width: 2px;
  }

  .md\:border-l-4 {
    border-left-width: 4px;
  }

  .md\:border-l-8 {
    border-left-width: 8px;
  }

  .md\:border-l-default {
    border-left-width: 1px;
  }

  .md\:border-solid {
    border-style: solid;
  }

  .md\:border-dashed {
    border-style: dashed;
  }

  .md\:border-dotted {
    border-style: dotted;
  }

  .md\:border-double {
    border-style: double;
  }

  .md\:border-none {
    border-style: none;
  }

  .md\:border-transparent {
    border-color: transparent;
  }

  .md\:border-current {
    border-color: currentColor;
  }

  .md\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .md\:border-black40pct {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .md\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:border-petroleum {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .md\:border-petroleum70pct {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .md\:border-petroleum30pct {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .md\:border-disabled {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .md\:border-petroleum10pct {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .md\:border-petroleum5pct {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .md\:border-darkGreen {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .md\:border-ok {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .md\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .md\:border-green10pct {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .md\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .md\:border-darkOrange {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .md\:border-lightOrange {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .md\:border-error {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .md\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .md\:border-darkYellow {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .md\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .md\:border-lightGray {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .md\:border-borderGray {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .md\:border-default {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .md\:hover\:border-transparent:hover {
    border-color: transparent;
  }

  .md\:hover\:border-current:hover {
    border-color: currentColor;
  }

  .md\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .md\:hover\:border-black40pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .md\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:hover\:border-petroleum:hover {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .md\:hover\:border-petroleum70pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .md\:hover\:border-petroleum30pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .md\:hover\:border-disabled:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .md\:hover\:border-petroleum10pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .md\:hover\:border-petroleum5pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .md\:hover\:border-darkGreen:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .md\:hover\:border-ok:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .md\:hover\:border-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .md\:hover\:border-green10pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .md\:hover\:border-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .md\:hover\:border-darkOrange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .md\:hover\:border-lightOrange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .md\:hover\:border-error:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .md\:hover\:border-yellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .md\:hover\:border-darkYellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .md\:hover\:border-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .md\:hover\:border-lightGray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .md\:hover\:border-borderGray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .md\:hover\:border-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .md\:focus\:border-transparent:focus {
    border-color: transparent;
  }

  .md\:focus\:border-current:focus {
    border-color: currentColor;
  }

  .md\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .md\:focus\:border-black40pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .md\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .md\:focus\:border-petroleum:focus {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .md\:focus\:border-petroleum70pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .md\:focus\:border-petroleum30pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .md\:focus\:border-disabled:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .md\:focus\:border-petroleum10pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .md\:focus\:border-petroleum5pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .md\:focus\:border-darkGreen:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .md\:focus\:border-ok:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .md\:focus\:border-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .md\:focus\:border-green10pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .md\:focus\:border-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .md\:focus\:border-darkOrange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .md\:focus\:border-lightOrange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .md\:focus\:border-error:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .md\:focus\:border-yellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .md\:focus\:border-darkYellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .md\:focus\:border-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .md\:focus\:border-lightGray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .md\:focus\:border-borderGray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .md\:focus\:border-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .md\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .md\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .md\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .md\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .md\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .md\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .md\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }

  .md\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }

  .md\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }

  .md\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .md\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .md\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }

  .md\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }

  .md\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }

  .md\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .md\:bg-transparent {
    background-color: transparent;
  }

  .md\:bg-current {
    background-color: currentColor;
  }

  .md\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .md\:bg-black40pct {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:bg-petroleum {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .md\:bg-petroleum70pct {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .md\:bg-petroleum30pct {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .md\:bg-disabled {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .md\:bg-petroleum10pct {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .md\:bg-petroleum5pct {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .md\:bg-darkGreen {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .md\:bg-ok {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .md\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .md\:bg-green10pct {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .md\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .md\:bg-darkOrange {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .md\:bg-lightOrange {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .md\:bg-error {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .md\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .md\:bg-darkYellow {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .md\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .md\:bg-lightGray {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .md\:bg-borderGray {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-transparent:hover {
    background-color: transparent;
  }

  .md\:hover\:bg-current:hover {
    background-color: currentColor;
  }

  .md\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-black40pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-petroleum:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-petroleum70pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-petroleum30pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-disabled:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-petroleum10pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-petroleum5pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-darkGreen:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-ok:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-green10pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-darkOrange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-lightOrange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-error:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-yellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-darkYellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-lightGray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .md\:hover\:bg-borderGray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-transparent:focus {
    background-color: transparent;
  }

  .md\:focus\:bg-current:focus {
    background-color: currentColor;
  }

  .md\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-black40pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-petroleum:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-petroleum70pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-petroleum30pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-disabled:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-petroleum10pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-petroleum5pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-darkGreen:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-ok:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-green10pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-darkOrange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-lightOrange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-error:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-yellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-darkYellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-lightGray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .md\:focus\:bg-borderGray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .md\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .md\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .md\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .md\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .md\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .md\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .md\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }

  .md\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }

  .md\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }

  .md\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .md\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .md\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }

  .md\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }

  .md\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }

  .md\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .md\:bg-none {
    background-image: none;
  }

  .md\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .md\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .md\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .md\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .md\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .md\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .md\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .md\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .md\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:from-black {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .md\:from-black40pct {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .md\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:from-petroleum {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .md\:from-petroleum70pct {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .md\:from-petroleum30pct {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .md\:from-disabled {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .md\:from-petroleum10pct {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .md\:from-petroleum5pct {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .md\:from-darkGreen {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .md\:from-ok {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .md\:from-green {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .md\:from-green10pct {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .md\:from-orange {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .md\:from-darkOrange {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .md\:from-lightOrange {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .md\:from-error {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .md\:from-yellow {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .md\:from-darkYellow {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .md\:from-gray {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .md\:from-lightGray {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .md\:from-borderGray {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .md\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:hover\:from-black:hover {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .md\:hover\:from-black40pct:hover {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .md\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:hover\:from-petroleum:hover {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .md\:hover\:from-petroleum70pct:hover {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .md\:hover\:from-petroleum30pct:hover {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .md\:hover\:from-disabled:hover {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .md\:hover\:from-petroleum10pct:hover {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .md\:hover\:from-petroleum5pct:hover {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .md\:hover\:from-darkGreen:hover {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .md\:hover\:from-ok:hover {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .md\:hover\:from-green:hover {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .md\:hover\:from-green10pct:hover {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .md\:hover\:from-orange:hover {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .md\:hover\:from-darkOrange:hover {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .md\:hover\:from-lightOrange:hover {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .md\:hover\:from-error:hover {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .md\:hover\:from-yellow:hover {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .md\:hover\:from-darkYellow:hover {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .md\:hover\:from-gray:hover {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .md\:hover\:from-lightGray:hover {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .md\:hover\:from-borderGray:hover {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .md\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:focus\:from-black:focus {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .md\:focus\:from-black40pct:focus {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .md\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:focus\:from-petroleum:focus {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .md\:focus\:from-petroleum70pct:focus {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .md\:focus\:from-petroleum30pct:focus {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .md\:focus\:from-disabled:focus {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .md\:focus\:from-petroleum10pct:focus {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .md\:focus\:from-petroleum5pct:focus {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .md\:focus\:from-darkGreen:focus {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .md\:focus\:from-ok:focus {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .md\:focus\:from-green:focus {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .md\:focus\:from-green10pct:focus {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .md\:focus\:from-orange:focus {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .md\:focus\:from-darkOrange:focus {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .md\:focus\:from-lightOrange:focus {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .md\:focus\:from-error:focus {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .md\:focus\:from-yellow:focus {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .md\:focus\:from-darkYellow:focus {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .md\:focus\:from-gray:focus {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .md\:focus\:from-lightGray:focus {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .md\:focus\:from-borderGray:focus {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .md\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .md\:via-black40pct {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .md\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:via-petroleum {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .md\:via-petroleum70pct {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .md\:via-petroleum30pct {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .md\:via-disabled {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .md\:via-petroleum10pct {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .md\:via-petroleum5pct {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .md\:via-darkGreen {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .md\:via-ok {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .md\:via-green {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .md\:via-green10pct {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .md\:via-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .md\:via-darkOrange {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .md\:via-lightOrange {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .md\:via-error {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .md\:via-yellow {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .md\:via-darkYellow {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .md\:via-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .md\:via-lightGray {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .md\:via-borderGray {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .md\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .md\:hover\:via-black40pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .md\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:hover\:via-petroleum:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .md\:hover\:via-petroleum70pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .md\:hover\:via-petroleum30pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .md\:hover\:via-disabled:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .md\:hover\:via-petroleum10pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .md\:hover\:via-petroleum5pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .md\:hover\:via-darkGreen:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .md\:hover\:via-ok:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .md\:hover\:via-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .md\:hover\:via-green10pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .md\:hover\:via-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .md\:hover\:via-darkOrange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .md\:hover\:via-lightOrange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .md\:hover\:via-error:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .md\:hover\:via-yellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .md\:hover\:via-darkYellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .md\:hover\:via-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .md\:hover\:via-lightGray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .md\:hover\:via-borderGray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .md\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .md\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .md\:focus\:via-black40pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .md\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .md\:focus\:via-petroleum:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .md\:focus\:via-petroleum70pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .md\:focus\:via-petroleum30pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .md\:focus\:via-disabled:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .md\:focus\:via-petroleum10pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .md\:focus\:via-petroleum5pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .md\:focus\:via-darkGreen:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .md\:focus\:via-ok:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .md\:focus\:via-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .md\:focus\:via-green10pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .md\:focus\:via-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .md\:focus\:via-darkOrange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .md\:focus\:via-lightOrange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .md\:focus\:via-error:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .md\:focus\:via-yellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .md\:focus\:via-darkYellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .md\:focus\:via-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .md\:focus\:via-lightGray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .md\:focus\:via-borderGray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .md\:to-transparent {
    --tw-gradient-to: transparent;
  }

  .md\:to-current {
    --tw-gradient-to: currentColor;
  }

  .md\:to-black {
    --tw-gradient-to: #1b1b1a;
  }

  .md\:to-black40pct {
    --tw-gradient-to: #a4a4a3;
  }

  .md\:to-white {
    --tw-gradient-to: #fff;
  }

  .md\:to-petroleum {
    --tw-gradient-to: #0e4744;
  }

  .md\:to-petroleum70pct {
    --tw-gradient-to: #567e7c;
  }

  .md\:to-petroleum30pct {
    --tw-gradient-to: #b7c8c7;
  }

  .md\:to-disabled {
    --tw-gradient-to: #b7c8c7;
  }

  .md\:to-petroleum10pct {
    --tw-gradient-to: #e7edec;
  }

  .md\:to-petroleum5pct {
    --tw-gradient-to: #f3f6f6;
  }

  .md\:to-darkGreen {
    --tw-gradient-to: #267b38;
  }

  .md\:to-ok {
    --tw-gradient-to: #267b38;
  }

  .md\:to-green {
    --tw-gradient-to: #2ea149;
  }

  .md\:to-green10pct {
    --tw-gradient-to: #eaf6ed;
  }

  .md\:to-orange {
    --tw-gradient-to: #ed7100;
  }

  .md\:to-darkOrange {
    --tw-gradient-to: #aa3636;
  }

  .md\:to-lightOrange {
    --tw-gradient-to: #fbe3cc;
  }

  .md\:to-error {
    --tw-gradient-to: #aa3636;
  }

  .md\:to-yellow {
    --tw-gradient-to: #e9c258;
  }

  .md\:to-darkYellow {
    --tw-gradient-to: #c3b091;
  }

  .md\:to-gray {
    --tw-gradient-to: #949493;
  }

  .md\:to-lightGray {
    --tw-gradient-to: #e5e5e5;
  }

  .md\:to-borderGray {
    --tw-gradient-to: #e6e6e6;
  }

  .md\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .md\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .md\:hover\:to-black:hover {
    --tw-gradient-to: #1b1b1a;
  }

  .md\:hover\:to-black40pct:hover {
    --tw-gradient-to: #a4a4a3;
  }

  .md\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }

  .md\:hover\:to-petroleum:hover {
    --tw-gradient-to: #0e4744;
  }

  .md\:hover\:to-petroleum70pct:hover {
    --tw-gradient-to: #567e7c;
  }

  .md\:hover\:to-petroleum30pct:hover {
    --tw-gradient-to: #b7c8c7;
  }

  .md\:hover\:to-disabled:hover {
    --tw-gradient-to: #b7c8c7;
  }

  .md\:hover\:to-petroleum10pct:hover {
    --tw-gradient-to: #e7edec;
  }

  .md\:hover\:to-petroleum5pct:hover {
    --tw-gradient-to: #f3f6f6;
  }

  .md\:hover\:to-darkGreen:hover {
    --tw-gradient-to: #267b38;
  }

  .md\:hover\:to-ok:hover {
    --tw-gradient-to: #267b38;
  }

  .md\:hover\:to-green:hover {
    --tw-gradient-to: #2ea149;
  }

  .md\:hover\:to-green10pct:hover {
    --tw-gradient-to: #eaf6ed;
  }

  .md\:hover\:to-orange:hover {
    --tw-gradient-to: #ed7100;
  }

  .md\:hover\:to-darkOrange:hover {
    --tw-gradient-to: #aa3636;
  }

  .md\:hover\:to-lightOrange:hover {
    --tw-gradient-to: #fbe3cc;
  }

  .md\:hover\:to-error:hover {
    --tw-gradient-to: #aa3636;
  }

  .md\:hover\:to-yellow:hover {
    --tw-gradient-to: #e9c258;
  }

  .md\:hover\:to-darkYellow:hover {
    --tw-gradient-to: #c3b091;
  }

  .md\:hover\:to-gray:hover {
    --tw-gradient-to: #949493;
  }

  .md\:hover\:to-lightGray:hover {
    --tw-gradient-to: #e5e5e5;
  }

  .md\:hover\:to-borderGray:hover {
    --tw-gradient-to: #e6e6e6;
  }

  .md\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .md\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .md\:focus\:to-black:focus {
    --tw-gradient-to: #1b1b1a;
  }

  .md\:focus\:to-black40pct:focus {
    --tw-gradient-to: #a4a4a3;
  }

  .md\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }

  .md\:focus\:to-petroleum:focus {
    --tw-gradient-to: #0e4744;
  }

  .md\:focus\:to-petroleum70pct:focus {
    --tw-gradient-to: #567e7c;
  }

  .md\:focus\:to-petroleum30pct:focus {
    --tw-gradient-to: #b7c8c7;
  }

  .md\:focus\:to-disabled:focus {
    --tw-gradient-to: #b7c8c7;
  }

  .md\:focus\:to-petroleum10pct:focus {
    --tw-gradient-to: #e7edec;
  }

  .md\:focus\:to-petroleum5pct:focus {
    --tw-gradient-to: #f3f6f6;
  }

  .md\:focus\:to-darkGreen:focus {
    --tw-gradient-to: #267b38;
  }

  .md\:focus\:to-ok:focus {
    --tw-gradient-to: #267b38;
  }

  .md\:focus\:to-green:focus {
    --tw-gradient-to: #2ea149;
  }

  .md\:focus\:to-green10pct:focus {
    --tw-gradient-to: #eaf6ed;
  }

  .md\:focus\:to-orange:focus {
    --tw-gradient-to: #ed7100;
  }

  .md\:focus\:to-darkOrange:focus {
    --tw-gradient-to: #aa3636;
  }

  .md\:focus\:to-lightOrange:focus {
    --tw-gradient-to: #fbe3cc;
  }

  .md\:focus\:to-error:focus {
    --tw-gradient-to: #aa3636;
  }

  .md\:focus\:to-yellow:focus {
    --tw-gradient-to: #e9c258;
  }

  .md\:focus\:to-darkYellow:focus {
    --tw-gradient-to: #c3b091;
  }

  .md\:focus\:to-gray:focus {
    --tw-gradient-to: #949493;
  }

  .md\:focus\:to-lightGray:focus {
    --tw-gradient-to: #e5e5e5;
  }

  .md\:focus\:to-borderGray:focus {
    --tw-gradient-to: #e6e6e6;
  }

  .md\:bg-auto {
    background-size: auto;
  }

  .md\:bg-cover {
    background-size: cover;
  }

  .md\:bg-contain {
    background-size: contain;
  }

  .md\:bg-fixed {
    background-attachment: fixed;
  }

  .md\:bg-local {
    background-attachment: local;
  }

  .md\:bg-scroll {
    background-attachment: scroll;
  }

  .md\:bg-clip-border {
    background-clip: border-box;
  }

  .md\:bg-clip-padding {
    background-clip: padding-box;
  }

  .md\:bg-clip-content {
    background-clip: content-box;
  }

  .md\:bg-clip-text {
    background-clip: text;
  }

  .md\:bg-bottom {
    background-position: bottom;
  }

  .md\:bg-center {
    background-position: center;
  }

  .md\:bg-left {
    background-position: left;
  }

  .md\:bg-left-bottom {
    background-position: left bottom;
  }

  .md\:bg-left-top {
    background-position: left top;
  }

  .md\:bg-right {
    background-position: right;
  }

  .md\:bg-right-bottom {
    background-position: right bottom;
  }

  .md\:bg-right-top {
    background-position: right top;
  }

  .md\:bg-top {
    background-position: top;
  }

  .md\:bg-repeat {
    background-repeat: repeat;
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .md\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .md\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .md\:bg-repeat-round {
    background-repeat: round;
  }

  .md\:bg-repeat-space {
    background-repeat: space;
  }

  .md\:fill-current {
    fill: currentColor;
  }

  .md\:stroke-current {
    stroke: currentColor;
  }

  .md\:stroke-0 {
    stroke-width: 0;
  }

  .md\:stroke-1 {
    stroke-width: 1;
  }

  .md\:stroke-2 {
    stroke-width: 2;
  }

  .md\:object-contain {
    object-fit: contain;
  }

  .md\:object-cover {
    object-fit: cover;
  }

  .md\:object-fill {
    object-fit: fill;
  }

  .md\:object-none {
    object-fit: none;
  }

  .md\:object-scale-down {
    object-fit: scale-down;
  }

  .md\:object-bottom {
    object-position: bottom;
  }

  .md\:object-center {
    object-position: center;
  }

  .md\:object-left {
    object-position: left;
  }

  .md\:object-left-bottom {
    object-position: left bottom;
  }

  .md\:object-left-top {
    object-position: left top;
  }

  .md\:object-right {
    object-position: right;
  }

  .md\:object-right-bottom {
    object-position: right bottom;
  }

  .md\:object-right-top {
    object-position: right top;
  }

  .md\:object-top {
    object-position: top;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-1 {
    padding: 0.25rem;
  }

  .md\:p-2 {
    padding: 0.5rem;
  }

  .md\:p-3 {
    padding: 0.75rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:p-12 {
    padding: 3rem;
  }

  .md\:p-16 {
    padding: 4rem;
  }

  .md\:p-20 {
    padding: 5rem;
  }

  .md\:p-24 {
    padding: 6rem;
  }

  .md\:p-32 {
    padding: 8rem;
  }

  .md\:p-40 {
    padding: 10rem;
  }

  .md\:p-48 {
    padding: 12rem;
  }

  .md\:p-56 {
    padding: 14rem;
  }

  .md\:p-64 {
    padding: 16rem;
  }

  .md\:p-300px {
    padding: 300px;
  }

  .md\:p-230px {
    padding: 230px;
  }

  .md\:p-180px {
    padding: 180px;
  }

  .md\:p-147px {
    padding: 147px;
  }

  .md\:p-123px {
    padding: 123px;
  }

  .md\:p-100px {
    padding: 100px;
  }

  .md\:p-80px {
    padding: 80px;
  }

  .md\:p-60px {
    padding: 60px;
  }

  .md\:p-50px {
    padding: 50px;
  }

  .md\:p-40px {
    padding: 40px;
  }

  .md\:p-35px {
    padding: 35px;
  }

  .md\:p-34px-i {
    padding: 34px !important;
  }

  .md\:p-30px-i {
    padding: 30px !important;
  }

  .md\:p-24px-i {
    padding: 24px !important;
  }

  .md\:p-20px-i {
    padding: 20px !important;
  }

  .md\:p-15px-i {
    padding: 15px !important;
  }

  .md\:p-13px-i {
    padding: 13px !important;
  }

  .md\:p-12px-i {
    padding: 12px !important;
  }

  .md\:p-10px-i {
    padding: 10px !important;
  }

  .md\:p-8px-i {
    padding: 8px !important;
  }

  .md\:p-6px-i {
    padding: 6px !important;
  }

  .md\:p-5px-i {
    padding: 5px !important;
  }

  .md\:p-3px-i {
    padding: 3px !important;
  }

  .md\:p-0px-i {
    padding: 0px !important;
  }

  .md\:p-34px {
    padding: 34px;
  }

  .md\:p-30px {
    padding: 30px;
  }

  .md\:p-24px {
    padding: 24px;
  }

  .md\:p-20px {
    padding: 20px;
  }

  .md\:p-15px {
    padding: 15px;
  }

  .md\:p-13px {
    padding: 13px;
  }

  .md\:p-12px {
    padding: 12px;
  }

  .md\:p-10px {
    padding: 10px;
  }

  .md\:p-8px {
    padding: 8px;
  }

  .md\:p-6px {
    padding: 6px;
  }

  .md\:p-5px {
    padding: 5px;
  }

  .md\:p-3px {
    padding: 3px;
  }

  .md\:p-menu {
    padding: 275px;
  }

  .md\:p-px {
    padding: 1px !important;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .md\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .md\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .md\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .md\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .md\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .md\:px-300px {
    padding-left: 300px;
    padding-right: 300px;
  }

  .md\:px-230px {
    padding-left: 230px;
    padding-right: 230px;
  }

  .md\:px-180px {
    padding-left: 180px;
    padding-right: 180px;
  }

  .md\:px-147px {
    padding-left: 147px;
    padding-right: 147px;
  }

  .md\:px-123px {
    padding-left: 123px;
    padding-right: 123px;
  }

  .md\:px-100px {
    padding-left: 100px;
    padding-right: 100px;
  }

  .md\:px-80px {
    padding-left: 80px;
    padding-right: 80px;
  }

  .md\:px-60px {
    padding-left: 60px;
    padding-right: 60px;
  }

  .md\:px-50px {
    padding-left: 50px;
    padding-right: 50px;
  }

  .md\:px-40px {
    padding-left: 40px;
    padding-right: 40px;
  }

  .md\:px-35px {
    padding-left: 35px;
    padding-right: 35px;
  }

  .md\:px-34px-i {
    padding-left: 34px !important;
    padding-right: 34px !important;
  }

  .md\:px-30px-i {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .md\:px-24px-i {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .md\:px-20px-i {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .md\:px-15px-i {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .md\:px-13px-i {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }

  .md\:px-12px-i {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .md\:px-10px-i {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .md\:px-8px-i {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .md\:px-6px-i {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .md\:px-5px-i {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .md\:px-3px-i {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .md\:px-0px-i {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .md\:px-34px {
    padding-left: 34px;
    padding-right: 34px;
  }

  .md\:px-30px {
    padding-left: 30px;
    padding-right: 30px;
  }

  .md\:px-24px {
    padding-left: 24px;
    padding-right: 24px;
  }

  .md\:px-20px {
    padding-left: 20px;
    padding-right: 20px;
  }

  .md\:px-15px {
    padding-left: 15px;
    padding-right: 15px;
  }

  .md\:px-13px {
    padding-left: 13px;
    padding-right: 13px;
  }

  .md\:px-12px {
    padding-left: 12px;
    padding-right: 12px;
  }

  .md\:px-10px {
    padding-left: 10px;
    padding-right: 10px;
  }

  .md\:px-8px {
    padding-left: 8px;
    padding-right: 8px;
  }

  .md\:px-6px {
    padding-left: 6px;
    padding-right: 6px;
  }

  .md\:px-5px {
    padding-left: 5px;
    padding-right: 5px;
  }

  .md\:px-3px {
    padding-left: 3px;
    padding-right: 3px;
  }

  .md\:px-menu {
    padding-left: 275px;
    padding-right: 275px;
  }

  .md\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .md\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .md\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .md\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .md\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .md\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .md\:py-300px {
    padding-top: 300px;
    padding-bottom: 300px;
  }

  .md\:py-230px {
    padding-top: 230px;
    padding-bottom: 230px;
  }

  .md\:py-180px {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .md\:py-147px {
    padding-top: 147px;
    padding-bottom: 147px;
  }

  .md\:py-123px {
    padding-top: 123px;
    padding-bottom: 123px;
  }

  .md\:py-100px {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .md\:py-80px {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .md\:py-60px {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .md\:py-50px {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .md\:py-40px {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .md\:py-35px {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .md\:py-34px-i {
    padding-top: 34px !important;
    padding-bottom: 34px !important;
  }

  .md\:py-30px-i {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .md\:py-24px-i {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .md\:py-20px-i {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .md\:py-15px-i {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .md\:py-13px-i {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  .md\:py-12px-i {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .md\:py-10px-i {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .md\:py-8px-i {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .md\:py-6px-i {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .md\:py-5px-i {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .md\:py-3px-i {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .md\:py-0px-i {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .md\:py-34px {
    padding-top: 34px;
    padding-bottom: 34px;
  }

  .md\:py-30px {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .md\:py-24px {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .md\:py-20px {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .md\:py-15px {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .md\:py-13px {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .md\:py-12px {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .md\:py-10px {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .md\:py-8px {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .md\:py-6px {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .md\:py-5px {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .md\:py-3px {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .md\:py-menu {
    padding-top: 275px;
    padding-bottom: 275px;
  }

  .md\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:pt-1 {
    padding-top: 0.25rem;
  }

  .md\:pt-2 {
    padding-top: 0.5rem;
  }

  .md\:pt-3 {
    padding-top: 0.75rem;
  }

  .md\:pt-4 {
    padding-top: 1rem;
  }

  .md\:pt-5 {
    padding-top: 1.25rem;
  }

  .md\:pt-6 {
    padding-top: 1.5rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:pt-10 {
    padding-top: 2.5rem;
  }

  .md\:pt-12 {
    padding-top: 3rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:pt-20 {
    padding-top: 5rem;
  }

  .md\:pt-24 {
    padding-top: 6rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:pt-40 {
    padding-top: 10rem;
  }

  .md\:pt-48 {
    padding-top: 12rem;
  }

  .md\:pt-56 {
    padding-top: 14rem;
  }

  .md\:pt-64 {
    padding-top: 16rem;
  }

  .md\:pt-300px {
    padding-top: 300px;
  }

  .md\:pt-230px {
    padding-top: 230px;
  }

  .md\:pt-180px {
    padding-top: 180px;
  }

  .md\:pt-147px {
    padding-top: 147px;
  }

  .md\:pt-123px {
    padding-top: 123px;
  }

  .md\:pt-100px {
    padding-top: 100px;
  }

  .md\:pt-80px {
    padding-top: 80px;
  }

  .md\:pt-60px {
    padding-top: 60px;
  }

  .md\:pt-50px {
    padding-top: 50px;
  }

  .md\:pt-40px {
    padding-top: 40px;
  }

  .md\:pt-35px {
    padding-top: 35px;
  }

  .md\:pt-34px-i {
    padding-top: 34px !important;
  }

  .md\:pt-30px-i {
    padding-top: 30px !important;
  }

  .md\:pt-24px-i {
    padding-top: 24px !important;
  }

  .md\:pt-20px-i {
    padding-top: 20px !important;
  }

  .md\:pt-15px-i {
    padding-top: 15px !important;
  }

  .md\:pt-13px-i {
    padding-top: 13px !important;
  }

  .md\:pt-12px-i {
    padding-top: 12px !important;
  }

  .md\:pt-10px-i {
    padding-top: 10px !important;
  }

  .md\:pt-8px-i {
    padding-top: 8px !important;
  }

  .md\:pt-6px-i {
    padding-top: 6px !important;
  }

  .md\:pt-5px-i {
    padding-top: 5px !important;
  }

  .md\:pt-3px-i {
    padding-top: 3px !important;
  }

  .md\:pt-0px-i {
    padding-top: 0px !important;
  }

  .md\:pt-34px {
    padding-top: 34px;
  }

  .md\:pt-30px {
    padding-top: 30px;
  }

  .md\:pt-24px {
    padding-top: 24px;
  }

  .md\:pt-20px {
    padding-top: 20px;
  }

  .md\:pt-15px {
    padding-top: 15px;
  }

  .md\:pt-13px {
    padding-top: 13px;
  }

  .md\:pt-12px {
    padding-top: 12px;
  }

  .md\:pt-10px {
    padding-top: 10px;
  }

  .md\:pt-8px {
    padding-top: 8px;
  }

  .md\:pt-6px {
    padding-top: 6px;
  }

  .md\:pt-5px {
    padding-top: 5px;
  }

  .md\:pt-3px {
    padding-top: 3px;
  }

  .md\:pt-menu {
    padding-top: 275px;
  }

  .md\:pt-px {
    padding-top: 1px !important;
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:pr-1 {
    padding-right: 0.25rem;
  }

  .md\:pr-2 {
    padding-right: 0.5rem;
  }

  .md\:pr-3 {
    padding-right: 0.75rem;
  }

  .md\:pr-4 {
    padding-right: 1rem;
  }

  .md\:pr-5 {
    padding-right: 1.25rem;
  }

  .md\:pr-6 {
    padding-right: 1.5rem;
  }

  .md\:pr-8 {
    padding-right: 2rem;
  }

  .md\:pr-10 {
    padding-right: 2.5rem;
  }

  .md\:pr-12 {
    padding-right: 3rem;
  }

  .md\:pr-16 {
    padding-right: 4rem;
  }

  .md\:pr-20 {
    padding-right: 5rem;
  }

  .md\:pr-24 {
    padding-right: 6rem;
  }

  .md\:pr-32 {
    padding-right: 8rem;
  }

  .md\:pr-40 {
    padding-right: 10rem;
  }

  .md\:pr-48 {
    padding-right: 12rem;
  }

  .md\:pr-56 {
    padding-right: 14rem;
  }

  .md\:pr-64 {
    padding-right: 16rem;
  }

  .md\:pr-300px {
    padding-right: 300px;
  }

  .md\:pr-230px {
    padding-right: 230px;
  }

  .md\:pr-180px {
    padding-right: 180px;
  }

  .md\:pr-147px {
    padding-right: 147px;
  }

  .md\:pr-123px {
    padding-right: 123px;
  }

  .md\:pr-100px {
    padding-right: 100px;
  }

  .md\:pr-80px {
    padding-right: 80px;
  }

  .md\:pr-60px {
    padding-right: 60px;
  }

  .md\:pr-50px {
    padding-right: 50px;
  }

  .md\:pr-40px {
    padding-right: 40px;
  }

  .md\:pr-35px {
    padding-right: 35px;
  }

  .md\:pr-34px-i {
    padding-right: 34px !important;
  }

  .md\:pr-30px-i {
    padding-right: 30px !important;
  }

  .md\:pr-24px-i {
    padding-right: 24px !important;
  }

  .md\:pr-20px-i {
    padding-right: 20px !important;
  }

  .md\:pr-15px-i {
    padding-right: 15px !important;
  }

  .md\:pr-13px-i {
    padding-right: 13px !important;
  }

  .md\:pr-12px-i {
    padding-right: 12px !important;
  }

  .md\:pr-10px-i {
    padding-right: 10px !important;
  }

  .md\:pr-8px-i {
    padding-right: 8px !important;
  }

  .md\:pr-6px-i {
    padding-right: 6px !important;
  }

  .md\:pr-5px-i {
    padding-right: 5px !important;
  }

  .md\:pr-3px-i {
    padding-right: 3px !important;
  }

  .md\:pr-0px-i {
    padding-right: 0px !important;
  }

  .md\:pr-34px {
    padding-right: 34px;
  }

  .md\:pr-30px {
    padding-right: 30px;
  }

  .md\:pr-24px {
    padding-right: 24px;
  }

  .md\:pr-20px {
    padding-right: 20px;
  }

  .md\:pr-15px {
    padding-right: 15px;
  }

  .md\:pr-13px {
    padding-right: 13px;
  }

  .md\:pr-12px {
    padding-right: 12px;
  }

  .md\:pr-10px {
    padding-right: 10px;
  }

  .md\:pr-8px {
    padding-right: 8px;
  }

  .md\:pr-6px {
    padding-right: 6px;
  }

  .md\:pr-5px {
    padding-right: 5px;
  }

  .md\:pr-3px {
    padding-right: 3px;
  }

  .md\:pr-menu {
    padding-right: 275px;
  }

  .md\:pr-px {
    padding-right: 1px !important;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:pb-1 {
    padding-bottom: 0.25rem;
  }

  .md\:pb-2 {
    padding-bottom: 0.5rem;
  }

  .md\:pb-3 {
    padding-bottom: 0.75rem;
  }

  .md\:pb-4 {
    padding-bottom: 1rem;
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:pb-8 {
    padding-bottom: 2rem;
  }

  .md\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .md\:pb-12 {
    padding-bottom: 3rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pb-20 {
    padding-bottom: 5rem;
  }

  .md\:pb-24 {
    padding-bottom: 6rem;
  }

  .md\:pb-32 {
    padding-bottom: 8rem;
  }

  .md\:pb-40 {
    padding-bottom: 10rem;
  }

  .md\:pb-48 {
    padding-bottom: 12rem;
  }

  .md\:pb-56 {
    padding-bottom: 14rem;
  }

  .md\:pb-64 {
    padding-bottom: 16rem;
  }

  .md\:pb-300px {
    padding-bottom: 300px;
  }

  .md\:pb-230px {
    padding-bottom: 230px;
  }

  .md\:pb-180px {
    padding-bottom: 180px;
  }

  .md\:pb-147px {
    padding-bottom: 147px;
  }

  .md\:pb-123px {
    padding-bottom: 123px;
  }

  .md\:pb-100px {
    padding-bottom: 100px;
  }

  .md\:pb-80px {
    padding-bottom: 80px;
  }

  .md\:pb-60px {
    padding-bottom: 60px;
  }

  .md\:pb-50px {
    padding-bottom: 50px;
  }

  .md\:pb-40px {
    padding-bottom: 40px;
  }

  .md\:pb-35px {
    padding-bottom: 35px;
  }

  .md\:pb-34px-i {
    padding-bottom: 34px !important;
  }

  .md\:pb-30px-i {
    padding-bottom: 30px !important;
  }

  .md\:pb-24px-i {
    padding-bottom: 24px !important;
  }

  .md\:pb-20px-i {
    padding-bottom: 20px !important;
  }

  .md\:pb-15px-i {
    padding-bottom: 15px !important;
  }

  .md\:pb-13px-i {
    padding-bottom: 13px !important;
  }

  .md\:pb-12px-i {
    padding-bottom: 12px !important;
  }

  .md\:pb-10px-i {
    padding-bottom: 10px !important;
  }

  .md\:pb-8px-i {
    padding-bottom: 8px !important;
  }

  .md\:pb-6px-i {
    padding-bottom: 6px !important;
  }

  .md\:pb-5px-i {
    padding-bottom: 5px !important;
  }

  .md\:pb-3px-i {
    padding-bottom: 3px !important;
  }

  .md\:pb-0px-i {
    padding-bottom: 0px !important;
  }

  .md\:pb-34px {
    padding-bottom: 34px;
  }

  .md\:pb-30px {
    padding-bottom: 30px;
  }

  .md\:pb-24px {
    padding-bottom: 24px;
  }

  .md\:pb-20px {
    padding-bottom: 20px;
  }

  .md\:pb-15px {
    padding-bottom: 15px;
  }

  .md\:pb-13px {
    padding-bottom: 13px;
  }

  .md\:pb-12px {
    padding-bottom: 12px;
  }

  .md\:pb-10px {
    padding-bottom: 10px;
  }

  .md\:pb-8px {
    padding-bottom: 8px;
  }

  .md\:pb-6px {
    padding-bottom: 6px;
  }

  .md\:pb-5px {
    padding-bottom: 5px;
  }

  .md\:pb-3px {
    padding-bottom: 3px;
  }

  .md\:pb-menu {
    padding-bottom: 275px;
  }

  .md\:pb-px {
    padding-bottom: 1px !important;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:pl-1 {
    padding-left: 0.25rem;
  }

  .md\:pl-2 {
    padding-left: 0.5rem;
  }

  .md\:pl-3 {
    padding-left: 0.75rem;
  }

  .md\:pl-4 {
    padding-left: 1rem;
  }

  .md\:pl-5 {
    padding-left: 1.25rem;
  }

  .md\:pl-6 {
    padding-left: 1.5rem;
  }

  .md\:pl-8 {
    padding-left: 2rem;
  }

  .md\:pl-10 {
    padding-left: 2.5rem;
  }

  .md\:pl-12 {
    padding-left: 3rem;
  }

  .md\:pl-16 {
    padding-left: 4rem;
  }

  .md\:pl-20 {
    padding-left: 5rem;
  }

  .md\:pl-24 {
    padding-left: 6rem;
  }

  .md\:pl-32 {
    padding-left: 8rem;
  }

  .md\:pl-40 {
    padding-left: 10rem;
  }

  .md\:pl-48 {
    padding-left: 12rem;
  }

  .md\:pl-56 {
    padding-left: 14rem;
  }

  .md\:pl-64 {
    padding-left: 16rem;
  }

  .md\:pl-300px {
    padding-left: 300px;
  }

  .md\:pl-230px {
    padding-left: 230px;
  }

  .md\:pl-180px {
    padding-left: 180px;
  }

  .md\:pl-147px {
    padding-left: 147px;
  }

  .md\:pl-123px {
    padding-left: 123px;
  }

  .md\:pl-100px {
    padding-left: 100px;
  }

  .md\:pl-80px {
    padding-left: 80px;
  }

  .md\:pl-60px {
    padding-left: 60px;
  }

  .md\:pl-50px {
    padding-left: 50px;
  }

  .md\:pl-40px {
    padding-left: 40px;
  }

  .md\:pl-35px {
    padding-left: 35px;
  }

  .md\:pl-34px-i {
    padding-left: 34px !important;
  }

  .md\:pl-30px-i {
    padding-left: 30px !important;
  }

  .md\:pl-24px-i {
    padding-left: 24px !important;
  }

  .md\:pl-20px-i {
    padding-left: 20px !important;
  }

  .md\:pl-15px-i {
    padding-left: 15px !important;
  }

  .md\:pl-13px-i {
    padding-left: 13px !important;
  }

  .md\:pl-12px-i {
    padding-left: 12px !important;
  }

  .md\:pl-10px-i {
    padding-left: 10px !important;
  }

  .md\:pl-8px-i {
    padding-left: 8px !important;
  }

  .md\:pl-6px-i {
    padding-left: 6px !important;
  }

  .md\:pl-5px-i {
    padding-left: 5px !important;
  }

  .md\:pl-3px-i {
    padding-left: 3px !important;
  }

  .md\:pl-0px-i {
    padding-left: 0px !important;
  }

  .md\:pl-34px {
    padding-left: 34px;
  }

  .md\:pl-30px {
    padding-left: 30px;
  }

  .md\:pl-24px {
    padding-left: 24px;
  }

  .md\:pl-20px {
    padding-left: 20px;
  }

  .md\:pl-15px {
    padding-left: 15px;
  }

  .md\:pl-13px {
    padding-left: 13px;
  }

  .md\:pl-12px {
    padding-left: 12px;
  }

  .md\:pl-10px {
    padding-left: 10px;
  }

  .md\:pl-8px {
    padding-left: 8px;
  }

  .md\:pl-6px {
    padding-left: 6px;
  }

  .md\:pl-5px {
    padding-left: 5px;
  }

  .md\:pl-3px {
    padding-left: 3px;
  }

  .md\:pl-menu {
    padding-left: 275px;
  }

  .md\:pl-px {
    padding-left: 1px !important;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-justify {
    text-align: justify;
  }

  .md\:align-baseline {
    vertical-align: baseline;
  }

  .md\:align-top {
    vertical-align: top;
  }

  .md\:align-middle {
    vertical-align: middle;
  }

  .md\:align-bottom {
    vertical-align: bottom;
  }

  .md\:align-text-top {
    vertical-align: text-top;
  }

  .md\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .md\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .md\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .md\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .md\:text-size-42 {
    font-size: 42px !important;
  }

  .md\:text-size-32 {
    font-size: 32px !important;
  }

  .md\:text-size-28 {
    font-size: 24px !important;
  }

  .md\:text-size-24 {
    font-size: 24px !important;
  }

  .md\:text-size-20 {
    font-size: 20px !important;
  }

  .md\:text-size-18 {
    font-size: 18px !important;
  }

  .md\:font-hairline {
    font-weight: 100;
  }

  .md\:font-thin {
    font-weight: 200;
  }

  .md\:font-light {
    font-weight: 300 !important;
  }

  .md\:font-normal {
    font-weight: 400 !important;
  }

  .md\:font-medium {
    font-weight: 500;
  }

  .md\:font-semibold {
    font-weight: 600;
  }

  .md\:font-bold {
    font-weight: 700 !important;
  }

  .md\:font-extrabold {
    font-weight: 800;
  }

  .md\:font-black {
    font-weight: 900;
  }

  .md\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .md\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .md\:hover\:font-light:hover {
    font-weight: 300 !important;
  }

  .md\:hover\:font-normal:hover {
    font-weight: 400 !important;
  }

  .md\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .md\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .md\:hover\:font-bold:hover {
    font-weight: 700 !important;
  }

  .md\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .md\:hover\:font-black:hover {
    font-weight: 900;
  }

  .md\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .md\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .md\:focus\:font-light:focus {
    font-weight: 300 !important;
  }

  .md\:focus\:font-normal:focus {
    font-weight: 400 !important;
  }

  .md\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .md\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .md\:focus\:font-bold:focus {
    font-weight: 700 !important;
  }

  .md\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .md\:focus\:font-black:focus {
    font-weight: 900;
  }

  .md\:uppercase {
    text-transform: uppercase;
  }

  .md\:lowercase {
    text-transform: lowercase;
  }

  .md\:capitalize {
    text-transform: capitalize;
  }

  .md\:normal-case {
    text-transform: none;
  }

  .md\:italic {
    font-style: italic;
  }

  .md\:not-italic {
    font-style: normal;
  }

  .md\:ordinal, .md\:slashed-zero, .md\:lining-nums, .md\:oldstyle-nums, .md\:proportional-nums, .md\:tabular-nums, .md\:diagonal-fractions, .md\:stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .md\:normal-nums {
    font-variant-numeric: normal;
  }

  .md\:ordinal {
    --tw-ordinal: ordinal;
  }

  .md\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .md\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .md\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .md\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .md\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .md\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .md\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .md\:leading-size-54 {
    line-height: 54px !important;
  }

  .md\:leading-size-40 {
    line-height: 40px !important;
  }

  .md\:leading-size-32 {
    line-height: 32px !important;
  }

  .md\:leading-size-28 {
    line-height: 28px !important;
  }

  .md\:leading-size-24 {
    line-height: 24px !important;
  }

  .md\:tracking-tightest {
    letter-spacing: -0.1em;
  }

  .md\:tracking-tighter {
    letter-spacing: -0.05em;
  }

  .md\:tracking-tight {
    letter-spacing: -0.025em;
  }

  .md\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .md\:tracking-wide {
    letter-spacing: 0.025em;
  }

  .md\:tracking-wider {
    letter-spacing: 0.05em;
  }

  .md\:tracking-widest {
    letter-spacing: 0.1em;
  }

  .md\:text-transparent {
    color: transparent;
  }

  .md\:text-current {
    color: currentColor;
  }

  .md\:text-black {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .md\:text-black40pct {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .md\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:text-petroleum {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .md\:text-petroleum70pct {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .md\:text-petroleum30pct {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .md\:text-disabled {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .md\:text-petroleum10pct {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .md\:text-petroleum5pct {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .md\:text-darkGreen {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .md\:text-ok {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .md\:text-green {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .md\:text-green10pct {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .md\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .md\:text-darkOrange {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .md\:text-lightOrange {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .md\:text-error {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .md\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .md\:text-darkYellow {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .md\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .md\:text-lightGray {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .md\:text-borderGray {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .md\:hover\:text-transparent:hover {
    color: transparent;
  }

  .md\:hover\:text-current:hover {
    color: currentColor;
  }

  .md\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .md\:hover\:text-black40pct:hover {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .md\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:hover\:text-petroleum:hover {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .md\:hover\:text-petroleum70pct:hover {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .md\:hover\:text-petroleum30pct:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .md\:hover\:text-disabled:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .md\:hover\:text-petroleum10pct:hover {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .md\:hover\:text-petroleum5pct:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .md\:hover\:text-darkGreen:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .md\:hover\:text-ok:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .md\:hover\:text-green:hover {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .md\:hover\:text-green10pct:hover {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .md\:hover\:text-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .md\:hover\:text-darkOrange:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .md\:hover\:text-lightOrange:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .md\:hover\:text-error:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .md\:hover\:text-yellow:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .md\:hover\:text-darkYellow:hover {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .md\:hover\:text-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .md\:hover\:text-lightGray:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .md\:hover\:text-borderGray:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .md\:focus\:text-transparent:focus {
    color: transparent;
  }

  .md\:focus\:text-current:focus {
    color: currentColor;
  }

  .md\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .md\:focus\:text-black40pct:focus {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .md\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .md\:focus\:text-petroleum:focus {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .md\:focus\:text-petroleum70pct:focus {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .md\:focus\:text-petroleum30pct:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .md\:focus\:text-disabled:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .md\:focus\:text-petroleum10pct:focus {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .md\:focus\:text-petroleum5pct:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .md\:focus\:text-darkGreen:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .md\:focus\:text-ok:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .md\:focus\:text-green:focus {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .md\:focus\:text-green10pct:focus {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .md\:focus\:text-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .md\:focus\:text-darkOrange:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .md\:focus\:text-lightOrange:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .md\:focus\:text-error:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .md\:focus\:text-yellow:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .md\:focus\:text-darkYellow:focus {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .md\:focus\:text-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .md\:focus\:text-lightGray:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .md\:focus\:text-borderGray:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .md\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .md\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .md\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .md\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .md\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .md\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .md\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }

  .md\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }

  .md\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }

  .md\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .md\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .md\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }

  .md\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }

  .md\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }

  .md\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .md\:underline {
    text-decoration: underline;
  }

  .md\:line-through {
    text-decoration: line-through;
  }

  .md\:no-underline {
    text-decoration: none;
  }

  .md\:hover\:underline:hover {
    text-decoration: underline;
  }

  .md\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .md\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .md\:focus\:underline:focus {
    text-decoration: underline;
  }

  .md\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .md\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .md\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .md\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .md\:placeholder-transparent::placeholder {
    color: transparent;
  }

  .md\:placeholder-current::placeholder {
    color: currentColor;
  }

  .md\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-black40pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-petroleum::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-petroleum70pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-petroleum30pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-disabled::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-petroleum10pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-petroleum5pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-darkGreen::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-ok::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-green10pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-darkOrange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-lightOrange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-error::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-yellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-darkYellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-lightGray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-borderGray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .md\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .md\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-black40pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-petroleum:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-petroleum70pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-petroleum30pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-disabled:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-petroleum10pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-petroleum5pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-darkGreen:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-ok:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-green10pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-darkOrange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-lightOrange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-error:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-yellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-darkYellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-lightGray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-placeholder-opacity));
  }

  .md\:focus\:placeholder-borderGray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-placeholder-opacity));
  }

  .md\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .md\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .md\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .md\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .md\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .md\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .md\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .md\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .md\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .md\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .md\:opacity-0 {
    opacity: 0;
  }

  .md\:opacity-25 {
    opacity: 0.25;
  }

  .md\:opacity-50 {
    opacity: 0.5;
  }

  .md\:opacity-75 {
    opacity: 0.75;
  }

  .md\:opacity-100 {
    opacity: 1;
  }

  .md\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .md\:hover\:opacity-25:hover {
    opacity: 0.25;
  }

  .md\:hover\:opacity-50:hover {
    opacity: 0.5;
  }

  .md\:hover\:opacity-75:hover {
    opacity: 0.75;
  }

  .md\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .md\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .md\:focus\:opacity-25:focus {
    opacity: 0.25;
  }

  .md\:focus\:opacity-50:focus {
    opacity: 0.5;
  }

  .md\:focus\:opacity-75:focus {
    opacity: 0.75;
  }

  .md\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .md\:shadow-xs {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-default {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-outline {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-xs:hover {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-default:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-outline:hover {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-xs:focus {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-default:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-outline:focus {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .md\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .md\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .md\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .md\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .md\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .md\:transition-none {
    transition-property: none;
  }

  .md\:transition-all {
    transition-property: all;
  }

  .md\:transition-default {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .md\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .md\:transition-opacity {
    transition-property: opacity;
  }

  .md\:transition-shadow {
    transition-property: box-shadow;
  }

  .md\:transition-transform {
    transition-property: transform;
  }

  .md\:delay-75 {
    transition-delay: 75ms;
  }

  .md\:delay-100 {
    transition-delay: 100ms;
  }

  .md\:delay-150 {
    transition-delay: 150ms;
  }

  .md\:delay-200 {
    transition-delay: 200ms;
  }

  .md\:delay-300 {
    transition-delay: 300ms;
  }

  .md\:delay-500 {
    transition-delay: 500ms;
  }

  .md\:delay-700 {
    transition-delay: 700ms;
  }

  .md\:delay-1000 {
    transition-delay: 1000ms;
  }

  .md\:duration-75 {
    transition-duration: 75ms;
  }

  .md\:duration-100 {
    transition-duration: 100ms;
  }

  .md\:duration-150 {
    transition-duration: 150ms;
  }

  .md\:duration-200 {
    transition-duration: 200ms;
  }

  .md\:duration-300 {
    transition-duration: 300ms;
  }

  .md\:duration-500 {
    transition-duration: 500ms;
  }

  .md\:duration-700 {
    transition-duration: 700ms;
  }

  .md\:duration-1000 {
    transition-duration: 1000ms;
  }

  .md\:ease-linear {
    transition-timing-function: linear;
  }

  .md\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .md\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .md\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (min-width: 1440px) {
  .lg\:container {
    width: 100%;
  }

  @media (min-width: 1px) {
    .lg\:container {
      max-width: 1px;
    }
  }

  @media (min-width: 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .lg\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1440px) {
    .lg\:container {
      max-width: 1440px;
    }
  }

  @media (min-width: 1920px) {
    .lg\:container {
      max-width: 1920px;
    }
  }

  .lg\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .lg\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .lg\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .lg\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .lg\:pointer-events-none {
    pointer-events: none;
  }

  .lg\:pointer-events-auto {
    pointer-events: auto;
  }

  .lg\:visible {
    visibility: visible;
  }

  .lg\:invisible {
    visibility: hidden;
  }

  .lg\:static {
    position: static;
  }

  .lg\:fixed {
    position: fixed;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:sticky {
    position: sticky;
  }

  .lg\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .lg\:inset-60px {
    top: 60px;
    right: 60px;
    bottom: 60px;
    left: 60px;
  }

  .lg\:inset-80px {
    top: 80px;
    right: 80px;
    bottom: 80px;
    left: 80px;
  }

  .lg\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .lg\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .lg\:inset-x-60px {
    left: 60px;
    right: 60px;
  }

  .lg\:inset-x-80px {
    left: 80px;
    right: 80px;
  }

  .lg\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .lg\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg\:inset-y-60px {
    top: 60px;
    bottom: 60px;
  }

  .lg\:inset-y-80px {
    top: 80px;
    bottom: 80px;
  }

  .lg\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .lg\:top-0 {
    top: 0;
  }

  .lg\:top-60px {
    top: 60px;
  }

  .lg\:top-80px {
    top: 80px;
  }

  .lg\:top-auto {
    top: auto;
  }

  .lg\:right-0 {
    right: 0;
  }

  .lg\:right-60px {
    right: 60px;
  }

  .lg\:right-80px {
    right: 80px;
  }

  .lg\:right-auto {
    right: auto;
  }

  .lg\:bottom-0 {
    bottom: 0;
  }

  .lg\:bottom-60px {
    bottom: 60px;
  }

  .lg\:bottom-80px {
    bottom: 80px;
  }

  .lg\:bottom-auto {
    bottom: auto;
  }

  .lg\:left-0 {
    left: 0;
  }

  .lg\:left-60px {
    left: 60px;
  }

  .lg\:left-80px {
    left: 80px;
  }

  .lg\:left-auto {
    left: auto;
  }

  .lg\:z-0 {
    z-index: 0 !important;
  }

  .lg\:z-10 {
    z-index: 10 !important;
  }

  .lg\:z-20 {
    z-index: 20;
  }

  .lg\:z-30 {
    z-index: 30;
  }

  .lg\:z-40 {
    z-index: 40;
  }

  .lg\:z-50 {
    z-index: 50;
  }

  .lg\:z-auto {
    z-index: auto !important;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-3 {
    order: 3;
  }

  .lg\:order-4 {
    order: 4;
  }

  .lg\:order-5 {
    order: 5;
  }

  .lg\:order-6 {
    order: 6;
  }

  .lg\:order-7 {
    order: 7;
  }

  .lg\:order-8 {
    order: 8;
  }

  .lg\:order-9 {
    order: 9;
  }

  .lg\:order-10 {
    order: 10;
  }

  .lg\:order-11 {
    order: 11;
  }

  .lg\:order-12 {
    order: 12;
  }

  .lg\:order-first {
    order: -9999;
  }

  .lg\:order-last {
    order: 9999;
  }

  .lg\:order-none {
    order: 0;
  }

  .lg\:col-auto {
    grid-column: auto;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:col-span-full {
    grid-column: 1 / -1;
  }

  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }

  .lg\:col-start-4 {
    grid-column-start: 4;
  }

  .lg\:col-start-5 {
    grid-column-start: 5;
  }

  .lg\:col-start-6 {
    grid-column-start: 6;
  }

  .lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:col-start-8 {
    grid-column-start: 8;
  }

  .lg\:col-start-9 {
    grid-column-start: 9;
  }

  .lg\:col-start-10 {
    grid-column-start: 10;
  }

  .lg\:col-start-11 {
    grid-column-start: 11;
  }

  .lg\:col-start-12 {
    grid-column-start: 12;
  }

  .lg\:col-start-13 {
    grid-column-start: 13;
  }

  .lg\:col-start-auto {
    grid-column-start: auto;
  }

  .lg\:col-end-1 {
    grid-column-end: 1;
  }

  .lg\:col-end-2 {
    grid-column-end: 2;
  }

  .lg\:col-end-3 {
    grid-column-end: 3;
  }

  .lg\:col-end-4 {
    grid-column-end: 4;
  }

  .lg\:col-end-5 {
    grid-column-end: 5;
  }

  .lg\:col-end-6 {
    grid-column-end: 6;
  }

  .lg\:col-end-7 {
    grid-column-end: 7;
  }

  .lg\:col-end-8 {
    grid-column-end: 8;
  }

  .lg\:col-end-9 {
    grid-column-end: 9;
  }

  .lg\:col-end-10 {
    grid-column-end: 10;
  }

  .lg\:col-end-11 {
    grid-column-end: 11;
  }

  .lg\:col-end-12 {
    grid-column-end: 12;
  }

  .lg\:col-end-13 {
    grid-column-end: 13;
  }

  .lg\:col-end-auto {
    grid-column-end: auto;
  }

  .lg\:row-auto {
    grid-row: auto;
  }

  .lg\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .lg\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .lg\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .lg\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .lg\:row-span-full {
    grid-row: 1 / -1;
  }

  .lg\:row-start-1 {
    grid-row-start: 1;
  }

  .lg\:row-start-2 {
    grid-row-start: 2;
  }

  .lg\:row-start-3 {
    grid-row-start: 3;
  }

  .lg\:row-start-4 {
    grid-row-start: 4;
  }

  .lg\:row-start-5 {
    grid-row-start: 5;
  }

  .lg\:row-start-6 {
    grid-row-start: 6;
  }

  .lg\:row-start-7 {
    grid-row-start: 7;
  }

  .lg\:row-start-auto {
    grid-row-start: auto;
  }

  .lg\:row-end-1 {
    grid-row-end: 1;
  }

  .lg\:row-end-2 {
    grid-row-end: 2;
  }

  .lg\:row-end-3 {
    grid-row-end: 3;
  }

  .lg\:row-end-4 {
    grid-row-end: 4;
  }

  .lg\:row-end-5 {
    grid-row-end: 5;
  }

  .lg\:row-end-6 {
    grid-row-end: 6;
  }

  .lg\:row-end-7 {
    grid-row-end: 7;
  }

  .lg\:row-end-auto {
    grid-row-end: auto;
  }

  .lg\:float-right {
    float: right;
  }

  .lg\:float-left {
    float: left;
  }

  .lg\:float-none {
    float: none;
  }

  .lg\:clear-left {
    clear: left;
  }

  .lg\:clear-right {
    clear: right;
  }

  .lg\:clear-both {
    clear: both;
  }

  .lg\:clear-none {
    clear: none;
  }

  .lg\:m-0 {
    margin: 0;
  }

  .lg\:m-1 {
    margin: 0.25rem;
  }

  .lg\:m-2 {
    margin: 0.5rem;
  }

  .lg\:m-3 {
    margin: 0.75rem;
  }

  .lg\:m-4 {
    margin: 1rem;
  }

  .lg\:m-5 {
    margin: 1.25rem;
  }

  .lg\:m-6 {
    margin: 1.5rem;
  }

  .lg\:m-8 {
    margin: 2rem;
  }

  .lg\:m-10 {
    margin: 2.5rem;
  }

  .lg\:m-12 {
    margin: 3rem;
  }

  .lg\:m-16 {
    margin: 4rem;
  }

  .lg\:m-20 {
    margin: 5rem;
  }

  .lg\:m-24 {
    margin: 6rem;
  }

  .lg\:m-32 {
    margin: 8rem;
  }

  .lg\:m-40 {
    margin: 10rem;
  }

  .lg\:m-48 {
    margin: 12rem;
  }

  .lg\:m-56 {
    margin: 14rem;
  }

  .lg\:m-64 {
    margin: 16rem;
  }

  .lg\:m-auto {
    margin: auto;
  }

  .lg\:m-300px {
    margin: 300px;
  }

  .lg\:m-230px {
    margin: 230px;
  }

  .lg\:m-180px {
    margin: 180px;
  }

  .lg\:m-147px {
    margin: 147px;
  }

  .lg\:m-123px {
    margin: 123px;
  }

  .lg\:m-100px {
    margin: 100px;
  }

  .lg\:m-80px {
    margin: 80px;
  }

  .lg\:m-60px {
    margin: 60px;
  }

  .lg\:m-50px {
    margin: 50px;
  }

  .lg\:m-40px {
    margin: 40px;
  }

  .lg\:m-35px {
    margin: 35px;
  }

  .lg\:m-34px-i {
    margin: 34px !important;
  }

  .lg\:m-30px-i {
    margin: 30px !important;
  }

  .lg\:m-24px-i {
    margin: 24px !important;
  }

  .lg\:m-20px-i {
    margin: 20px !important;
  }

  .lg\:m-15px-i {
    margin: 15px !important;
  }

  .lg\:m-13px-i {
    margin: 13px !important;
  }

  .lg\:m-12px-i {
    margin: 12px !important;
  }

  .lg\:m-10px-i {
    margin: 10px !important;
  }

  .lg\:m-8px-i {
    margin: 8px !important;
  }

  .lg\:m-6px-i {
    margin: 6px !important;
  }

  .lg\:m-5px-i {
    margin: 5px !important;
  }

  .lg\:m-3px-i {
    margin: 3px !important;
  }

  .lg\:m-0px-i {
    margin: 0px !important;
  }

  .lg\:m-34px {
    margin: 34px;
  }

  .lg\:m-30px {
    margin: 30px;
  }

  .lg\:m-24px {
    margin: 24px;
  }

  .lg\:m-20px {
    margin: 20px;
  }

  .lg\:m-15px {
    margin: 15px;
  }

  .lg\:m-13px {
    margin: 13px;
  }

  .lg\:m-12px {
    margin: 12px;
  }

  .lg\:m-10px {
    margin: 10px;
  }

  .lg\:m-8px {
    margin: 8px;
  }

  .lg\:m-6px {
    margin: 6px;
  }

  .lg\:m-5px {
    margin: 5px;
  }

  .lg\:m-3px {
    margin: 3px;
  }

  .lg\:m-menu {
    margin: 275px;
  }

  .lg\:m-px {
    margin: 1px !important;
  }

  .lg\:-m-1 {
    margin: -0.25rem;
  }

  .lg\:-m-2 {
    margin: -0.5rem;
  }

  .lg\:-m-3 {
    margin: -0.75rem;
  }

  .lg\:-m-4 {
    margin: -1rem;
  }

  .lg\:-m-5 {
    margin: -1.25rem;
  }

  .lg\:-m-6 {
    margin: -1.5rem;
  }

  .lg\:-m-8 {
    margin: -2rem;
  }

  .lg\:-m-10 {
    margin: -2.5rem;
  }

  .lg\:-m-12 {
    margin: -3rem;
  }

  .lg\:-m-16 {
    margin: -4rem;
  }

  .lg\:-m-20 {
    margin: -5rem;
  }

  .lg\:-m-24 {
    margin: -6rem;
  }

  .lg\:-m-32 {
    margin: -8rem;
  }

  .lg\:-m-40 {
    margin: -10rem;
  }

  .lg\:-m-48 {
    margin: -12rem;
  }

  .lg\:-m-56 {
    margin: -14rem;
  }

  .lg\:-m-64 {
    margin: -16rem;
  }

  .lg\:-m-300px {
    margin: -300px;
  }

  .lg\:-m-230px {
    margin: -230px;
  }

  .lg\:-m-180px {
    margin: -180px;
  }

  .lg\:-m-147px {
    margin: -147px;
  }

  .lg\:-m-123px {
    margin: -123px;
  }

  .lg\:-m-100px {
    margin: -100px;
  }

  .lg\:-m-80px {
    margin: -80px;
  }

  .lg\:-m-60px {
    margin: -60px;
  }

  .lg\:-m-50px {
    margin: -50px;
  }

  .lg\:-m-40px {
    margin: -40px;
  }

  .lg\:-m-35px {
    margin: -35px;
  }

  .lg\:-m-34px-i {
    margin: 34px !important;
  }

  .lg\:-m-30px-i {
    margin: 30px !important;
  }

  .lg\:-m-24px-i {
    margin: 24px !important;
  }

  .lg\:-m-20px-i {
    margin: 20px !important;
  }

  .lg\:-m-15px-i {
    margin: 15px !important;
  }

  .lg\:-m-13px-i {
    margin: 13px !important;
  }

  .lg\:-m-12px-i {
    margin: 12px !important;
  }

  .lg\:-m-10px-i {
    margin: 10px !important;
  }

  .lg\:-m-8px-i {
    margin: 8px !important;
  }

  .lg\:-m-6px-i {
    margin: 6px !important;
  }

  .lg\:-m-5px-i {
    margin: 5px !important;
  }

  .lg\:-m-3px-i {
    margin: 3px !important;
  }

  .lg\:-m-0px-i {
    margin: 0px !important;
  }

  .lg\:-m-34px {
    margin: -34px;
  }

  .lg\:-m-30px {
    margin: -30px;
  }

  .lg\:-m-24px {
    margin: -24px;
  }

  .lg\:-m-20px {
    margin: -20px;
  }

  .lg\:-m-15px {
    margin: -15px;
  }

  .lg\:-m-13px {
    margin: -13px;
  }

  .lg\:-m-12px {
    margin: -12px;
  }

  .lg\:-m-10px {
    margin: -10px;
  }

  .lg\:-m-8px {
    margin: -8px;
  }

  .lg\:-m-6px {
    margin: -6px;
  }

  .lg\:-m-5px {
    margin: -5px;
  }

  .lg\:-m-3px {
    margin: -3px;
  }

  .lg\:-m-menu {
    margin: -275px;
  }

  .lg\:-m-px {
    margin: 1px !important;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .lg\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .lg\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .lg\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .lg\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .lg\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .lg\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .lg\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:mx-300px {
    margin-left: 300px;
    margin-right: 300px;
  }

  .lg\:mx-230px {
    margin-left: 230px;
    margin-right: 230px;
  }

  .lg\:mx-180px {
    margin-left: 180px;
    margin-right: 180px;
  }

  .lg\:mx-147px {
    margin-left: 147px;
    margin-right: 147px;
  }

  .lg\:mx-123px {
    margin-left: 123px;
    margin-right: 123px;
  }

  .lg\:mx-100px {
    margin-left: 100px;
    margin-right: 100px;
  }

  .lg\:mx-80px {
    margin-left: 80px;
    margin-right: 80px;
  }

  .lg\:mx-60px {
    margin-left: 60px;
    margin-right: 60px;
  }

  .lg\:mx-50px {
    margin-left: 50px;
    margin-right: 50px;
  }

  .lg\:mx-40px {
    margin-left: 40px;
    margin-right: 40px;
  }

  .lg\:mx-35px {
    margin-left: 35px;
    margin-right: 35px;
  }

  .lg\:mx-34px-i {
    margin-left: 34px !important;
    margin-right: 34px !important;
  }

  .lg\:mx-30px-i {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .lg\:mx-24px-i {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .lg\:mx-20px-i {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .lg\:mx-15px-i {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .lg\:mx-13px-i {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }

  .lg\:mx-12px-i {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .lg\:mx-10px-i {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .lg\:mx-8px-i {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .lg\:mx-6px-i {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .lg\:mx-5px-i {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .lg\:mx-3px-i {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .lg\:mx-0px-i {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .lg\:mx-34px {
    margin-left: 34px;
    margin-right: 34px;
  }

  .lg\:mx-30px {
    margin-left: 30px;
    margin-right: 30px;
  }

  .lg\:mx-24px {
    margin-left: 24px;
    margin-right: 24px;
  }

  .lg\:mx-20px {
    margin-left: 20px;
    margin-right: 20px;
  }

  .lg\:mx-15px {
    margin-left: 15px;
    margin-right: 15px;
  }

  .lg\:mx-13px {
    margin-left: 13px;
    margin-right: 13px;
  }

  .lg\:mx-12px {
    margin-left: 12px;
    margin-right: 12px;
  }

  .lg\:mx-10px {
    margin-left: 10px;
    margin-right: 10px;
  }

  .lg\:mx-8px {
    margin-left: 8px;
    margin-right: 8px;
  }

  .lg\:mx-6px {
    margin-left: 6px;
    margin-right: 6px;
  }

  .lg\:mx-5px {
    margin-left: 5px;
    margin-right: 5px;
  }

  .lg\:mx-3px {
    margin-left: 3px;
    margin-right: 3px;
  }

  .lg\:mx-menu {
    margin-left: 275px;
    margin-right: 275px;
  }

  .lg\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .lg\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .lg\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .lg\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .lg\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .lg\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .lg\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .lg\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .lg\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .lg\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .lg\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .lg\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .lg\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .lg\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .lg\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .lg\:-mx-300px {
    margin-left: -300px;
    margin-right: -300px;
  }

  .lg\:-mx-230px {
    margin-left: -230px;
    margin-right: -230px;
  }

  .lg\:-mx-180px {
    margin-left: -180px;
    margin-right: -180px;
  }

  .lg\:-mx-147px {
    margin-left: -147px;
    margin-right: -147px;
  }

  .lg\:-mx-123px {
    margin-left: -123px;
    margin-right: -123px;
  }

  .lg\:-mx-100px {
    margin-left: -100px;
    margin-right: -100px;
  }

  .lg\:-mx-80px {
    margin-left: -80px;
    margin-right: -80px;
  }

  .lg\:-mx-60px {
    margin-left: -60px;
    margin-right: -60px;
  }

  .lg\:-mx-50px {
    margin-left: -50px;
    margin-right: -50px;
  }

  .lg\:-mx-40px {
    margin-left: -40px;
    margin-right: -40px;
  }

  .lg\:-mx-35px {
    margin-left: -35px;
    margin-right: -35px;
  }

  .lg\:-mx-34px-i {
    margin-left: 34px !important;
    margin-right: 34px !important;
  }

  .lg\:-mx-30px-i {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .lg\:-mx-24px-i {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .lg\:-mx-20px-i {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .lg\:-mx-15px-i {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .lg\:-mx-13px-i {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }

  .lg\:-mx-12px-i {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .lg\:-mx-10px-i {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .lg\:-mx-8px-i {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .lg\:-mx-6px-i {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .lg\:-mx-5px-i {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .lg\:-mx-3px-i {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .lg\:-mx-0px-i {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .lg\:-mx-34px {
    margin-left: -34px;
    margin-right: -34px;
  }

  .lg\:-mx-30px {
    margin-left: -30px;
    margin-right: -30px;
  }

  .lg\:-mx-24px {
    margin-left: -24px;
    margin-right: -24px;
  }

  .lg\:-mx-20px {
    margin-left: -20px;
    margin-right: -20px;
  }

  .lg\:-mx-15px {
    margin-left: -15px;
    margin-right: -15px;
  }

  .lg\:-mx-13px {
    margin-left: -13px;
    margin-right: -13px;
  }

  .lg\:-mx-12px {
    margin-left: -12px;
    margin-right: -12px;
  }

  .lg\:-mx-10px {
    margin-left: -10px;
    margin-right: -10px;
  }

  .lg\:-mx-8px {
    margin-left: -8px;
    margin-right: -8px;
  }

  .lg\:-mx-6px {
    margin-left: -6px;
    margin-right: -6px;
  }

  .lg\:-mx-5px {
    margin-left: -5px;
    margin-right: -5px;
  }

  .lg\:-mx-3px {
    margin-left: -3px;
    margin-right: -3px;
  }

  .lg\:-mx-menu {
    margin-left: -275px;
    margin-right: -275px;
  }

  .lg\:-mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .lg\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .lg\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .lg\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .lg\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .lg\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .lg\:my-300px {
    margin-top: 300px;
    margin-bottom: 300px;
  }

  .lg\:my-230px {
    margin-top: 230px;
    margin-bottom: 230px;
  }

  .lg\:my-180px {
    margin-top: 180px;
    margin-bottom: 180px;
  }

  .lg\:my-147px {
    margin-top: 147px;
    margin-bottom: 147px;
  }

  .lg\:my-123px {
    margin-top: 123px;
    margin-bottom: 123px;
  }

  .lg\:my-100px {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .lg\:my-80px {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .lg\:my-60px {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .lg\:my-50px {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .lg\:my-40px {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .lg\:my-35px {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .lg\:my-34px-i {
    margin-top: 34px !important;
    margin-bottom: 34px !important;
  }

  .lg\:my-30px-i {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .lg\:my-24px-i {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .lg\:my-20px-i {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .lg\:my-15px-i {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .lg\:my-13px-i {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }

  .lg\:my-12px-i {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .lg\:my-10px-i {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .lg\:my-8px-i {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .lg\:my-6px-i {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .lg\:my-5px-i {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .lg\:my-3px-i {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .lg\:my-0px-i {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .lg\:my-34px {
    margin-top: 34px;
    margin-bottom: 34px;
  }

  .lg\:my-30px {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .lg\:my-24px {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .lg\:my-20px {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .lg\:my-15px {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .lg\:my-13px {
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .lg\:my-12px {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .lg\:my-10px {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .lg\:my-8px {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .lg\:my-6px {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .lg\:my-5px {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .lg\:my-3px {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .lg\:my-menu {
    margin-top: 275px;
    margin-bottom: 275px;
  }

  .lg\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .lg\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .lg\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .lg\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .lg\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .lg\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .lg\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .lg\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .lg\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .lg\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .lg\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .lg\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .lg\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .lg\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .lg\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .lg\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .lg\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .lg\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .lg\:-my-300px {
    margin-top: -300px;
    margin-bottom: -300px;
  }

  .lg\:-my-230px {
    margin-top: -230px;
    margin-bottom: -230px;
  }

  .lg\:-my-180px {
    margin-top: -180px;
    margin-bottom: -180px;
  }

  .lg\:-my-147px {
    margin-top: -147px;
    margin-bottom: -147px;
  }

  .lg\:-my-123px {
    margin-top: -123px;
    margin-bottom: -123px;
  }

  .lg\:-my-100px {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .lg\:-my-80px {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .lg\:-my-60px {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .lg\:-my-50px {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .lg\:-my-40px {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .lg\:-my-35px {
    margin-top: -35px;
    margin-bottom: -35px;
  }

  .lg\:-my-34px-i {
    margin-top: 34px !important;
    margin-bottom: 34px !important;
  }

  .lg\:-my-30px-i {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .lg\:-my-24px-i {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .lg\:-my-20px-i {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .lg\:-my-15px-i {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .lg\:-my-13px-i {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }

  .lg\:-my-12px-i {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .lg\:-my-10px-i {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .lg\:-my-8px-i {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .lg\:-my-6px-i {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .lg\:-my-5px-i {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .lg\:-my-3px-i {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .lg\:-my-0px-i {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .lg\:-my-34px {
    margin-top: -34px;
    margin-bottom: -34px;
  }

  .lg\:-my-30px {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .lg\:-my-24px {
    margin-top: -24px;
    margin-bottom: -24px;
  }

  .lg\:-my-20px {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .lg\:-my-15px {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .lg\:-my-13px {
    margin-top: -13px;
    margin-bottom: -13px;
  }

  .lg\:-my-12px {
    margin-top: -12px;
    margin-bottom: -12px;
  }

  .lg\:-my-10px {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .lg\:-my-8px {
    margin-top: -8px;
    margin-bottom: -8px;
  }

  .lg\:-my-6px {
    margin-top: -6px;
    margin-bottom: -6px;
  }

  .lg\:-my-5px {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .lg\:-my-3px {
    margin-top: -3px;
    margin-bottom: -3px;
  }

  .lg\:-my-menu {
    margin-top: -275px;
    margin-bottom: -275px;
  }

  .lg\:-my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-1 {
    margin-top: 0.25rem;
  }

  .lg\:mt-2 {
    margin-top: 0.5rem;
  }

  .lg\:mt-3 {
    margin-top: 0.75rem;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mt-24 {
    margin-top: 6rem;
  }

  .lg\:mt-32 {
    margin-top: 8rem;
  }

  .lg\:mt-40 {
    margin-top: 10rem;
  }

  .lg\:mt-48 {
    margin-top: 12rem;
  }

  .lg\:mt-56 {
    margin-top: 14rem;
  }

  .lg\:mt-64 {
    margin-top: 16rem;
  }

  .lg\:mt-auto {
    margin-top: auto;
  }

  .lg\:mt-300px {
    margin-top: 300px;
  }

  .lg\:mt-230px {
    margin-top: 230px;
  }

  .lg\:mt-180px {
    margin-top: 180px;
  }

  .lg\:mt-147px {
    margin-top: 147px;
  }

  .lg\:mt-123px {
    margin-top: 123px;
  }

  .lg\:mt-100px {
    margin-top: 100px;
  }

  .lg\:mt-80px {
    margin-top: 80px;
  }

  .lg\:mt-60px {
    margin-top: 60px;
  }

  .lg\:mt-50px {
    margin-top: 50px;
  }

  .lg\:mt-40px {
    margin-top: 40px;
  }

  .lg\:mt-35px {
    margin-top: 35px;
  }

  .lg\:mt-34px-i {
    margin-top: 34px !important;
  }

  .lg\:mt-30px-i {
    margin-top: 30px !important;
  }

  .lg\:mt-24px-i {
    margin-top: 24px !important;
  }

  .lg\:mt-20px-i {
    margin-top: 20px !important;
  }

  .lg\:mt-15px-i {
    margin-top: 15px !important;
  }

  .lg\:mt-13px-i {
    margin-top: 13px !important;
  }

  .lg\:mt-12px-i {
    margin-top: 12px !important;
  }

  .lg\:mt-10px-i {
    margin-top: 10px !important;
  }

  .lg\:mt-8px-i {
    margin-top: 8px !important;
  }

  .lg\:mt-6px-i {
    margin-top: 6px !important;
  }

  .lg\:mt-5px-i {
    margin-top: 5px !important;
  }

  .lg\:mt-3px-i {
    margin-top: 3px !important;
  }

  .lg\:mt-0px-i {
    margin-top: 0px !important;
  }

  .lg\:mt-34px {
    margin-top: 34px;
  }

  .lg\:mt-30px {
    margin-top: 30px;
  }

  .lg\:mt-24px {
    margin-top: 24px;
  }

  .lg\:mt-20px {
    margin-top: 20px;
  }

  .lg\:mt-15px {
    margin-top: 15px;
  }

  .lg\:mt-13px {
    margin-top: 13px;
  }

  .lg\:mt-12px {
    margin-top: 12px;
  }

  .lg\:mt-10px {
    margin-top: 10px;
  }

  .lg\:mt-8px {
    margin-top: 8px;
  }

  .lg\:mt-6px {
    margin-top: 6px;
  }

  .lg\:mt-5px {
    margin-top: 5px;
  }

  .lg\:mt-3px {
    margin-top: 3px;
  }

  .lg\:mt-menu {
    margin-top: 275px;
  }

  .lg\:mt-px {
    margin-top: 1px !important;
  }

  .lg\:-mt-1 {
    margin-top: -0.25rem;
  }

  .lg\:-mt-2 {
    margin-top: -0.5rem;
  }

  .lg\:-mt-3 {
    margin-top: -0.75rem;
  }

  .lg\:-mt-4 {
    margin-top: -1rem;
  }

  .lg\:-mt-5 {
    margin-top: -1.25rem;
  }

  .lg\:-mt-6 {
    margin-top: -1.5rem;
  }

  .lg\:-mt-8 {
    margin-top: -2rem;
  }

  .lg\:-mt-10 {
    margin-top: -2.5rem;
  }

  .lg\:-mt-12 {
    margin-top: -3rem;
  }

  .lg\:-mt-16 {
    margin-top: -4rem;
  }

  .lg\:-mt-20 {
    margin-top: -5rem;
  }

  .lg\:-mt-24 {
    margin-top: -6rem;
  }

  .lg\:-mt-32 {
    margin-top: -8rem;
  }

  .lg\:-mt-40 {
    margin-top: -10rem;
  }

  .lg\:-mt-48 {
    margin-top: -12rem;
  }

  .lg\:-mt-56 {
    margin-top: -14rem;
  }

  .lg\:-mt-64 {
    margin-top: -16rem;
  }

  .lg\:-mt-300px {
    margin-top: -300px;
  }

  .lg\:-mt-230px {
    margin-top: -230px;
  }

  .lg\:-mt-180px {
    margin-top: -180px;
  }

  .lg\:-mt-147px {
    margin-top: -147px;
  }

  .lg\:-mt-123px {
    margin-top: -123px;
  }

  .lg\:-mt-100px {
    margin-top: -100px;
  }

  .lg\:-mt-80px {
    margin-top: -80px;
  }

  .lg\:-mt-60px {
    margin-top: -60px;
  }

  .lg\:-mt-50px {
    margin-top: -50px;
  }

  .lg\:-mt-40px {
    margin-top: -40px;
  }

  .lg\:-mt-35px {
    margin-top: -35px;
  }

  .lg\:-mt-34px-i {
    margin-top: 34px !important;
  }

  .lg\:-mt-30px-i {
    margin-top: 30px !important;
  }

  .lg\:-mt-24px-i {
    margin-top: 24px !important;
  }

  .lg\:-mt-20px-i {
    margin-top: 20px !important;
  }

  .lg\:-mt-15px-i {
    margin-top: 15px !important;
  }

  .lg\:-mt-13px-i {
    margin-top: 13px !important;
  }

  .lg\:-mt-12px-i {
    margin-top: 12px !important;
  }

  .lg\:-mt-10px-i {
    margin-top: 10px !important;
  }

  .lg\:-mt-8px-i {
    margin-top: 8px !important;
  }

  .lg\:-mt-6px-i {
    margin-top: 6px !important;
  }

  .lg\:-mt-5px-i {
    margin-top: 5px !important;
  }

  .lg\:-mt-3px-i {
    margin-top: 3px !important;
  }

  .lg\:-mt-0px-i {
    margin-top: 0px !important;
  }

  .lg\:-mt-34px {
    margin-top: -34px;
  }

  .lg\:-mt-30px {
    margin-top: -30px;
  }

  .lg\:-mt-24px {
    margin-top: -24px;
  }

  .lg\:-mt-20px {
    margin-top: -20px;
  }

  .lg\:-mt-15px {
    margin-top: -15px;
  }

  .lg\:-mt-13px {
    margin-top: -13px;
  }

  .lg\:-mt-12px {
    margin-top: -12px;
  }

  .lg\:-mt-10px {
    margin-top: -10px;
  }

  .lg\:-mt-8px {
    margin-top: -8px;
  }

  .lg\:-mt-6px {
    margin-top: -6px;
  }

  .lg\:-mt-5px {
    margin-top: -5px;
  }

  .lg\:-mt-3px {
    margin-top: -3px;
  }

  .lg\:-mt-menu {
    margin-top: -275px;
  }

  .lg\:-mt-px {
    margin-top: 1px !important;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mr-1 {
    margin-right: 0.25rem;
  }

  .lg\:mr-2 {
    margin-right: 0.5rem;
  }

  .lg\:mr-3 {
    margin-right: 0.75rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:mr-10 {
    margin-right: 2.5rem;
  }

  .lg\:mr-12 {
    margin-right: 3rem;
  }

  .lg\:mr-16 {
    margin-right: 4rem;
  }

  .lg\:mr-20 {
    margin-right: 5rem;
  }

  .lg\:mr-24 {
    margin-right: 6rem;
  }

  .lg\:mr-32 {
    margin-right: 8rem;
  }

  .lg\:mr-40 {
    margin-right: 10rem;
  }

  .lg\:mr-48 {
    margin-right: 12rem;
  }

  .lg\:mr-56 {
    margin-right: 14rem;
  }

  .lg\:mr-64 {
    margin-right: 16rem;
  }

  .lg\:mr-auto {
    margin-right: auto;
  }

  .lg\:mr-300px {
    margin-right: 300px;
  }

  .lg\:mr-230px {
    margin-right: 230px;
  }

  .lg\:mr-180px {
    margin-right: 180px;
  }

  .lg\:mr-147px {
    margin-right: 147px;
  }

  .lg\:mr-123px {
    margin-right: 123px;
  }

  .lg\:mr-100px {
    margin-right: 100px;
  }

  .lg\:mr-80px {
    margin-right: 80px;
  }

  .lg\:mr-60px {
    margin-right: 60px;
  }

  .lg\:mr-50px {
    margin-right: 50px;
  }

  .lg\:mr-40px {
    margin-right: 40px;
  }

  .lg\:mr-35px {
    margin-right: 35px;
  }

  .lg\:mr-34px-i {
    margin-right: 34px !important;
  }

  .lg\:mr-30px-i {
    margin-right: 30px !important;
  }

  .lg\:mr-24px-i {
    margin-right: 24px !important;
  }

  .lg\:mr-20px-i {
    margin-right: 20px !important;
  }

  .lg\:mr-15px-i {
    margin-right: 15px !important;
  }

  .lg\:mr-13px-i {
    margin-right: 13px !important;
  }

  .lg\:mr-12px-i {
    margin-right: 12px !important;
  }

  .lg\:mr-10px-i {
    margin-right: 10px !important;
  }

  .lg\:mr-8px-i {
    margin-right: 8px !important;
  }

  .lg\:mr-6px-i {
    margin-right: 6px !important;
  }

  .lg\:mr-5px-i {
    margin-right: 5px !important;
  }

  .lg\:mr-3px-i {
    margin-right: 3px !important;
  }

  .lg\:mr-0px-i {
    margin-right: 0px !important;
  }

  .lg\:mr-34px {
    margin-right: 34px;
  }

  .lg\:mr-30px {
    margin-right: 30px;
  }

  .lg\:mr-24px {
    margin-right: 24px;
  }

  .lg\:mr-20px {
    margin-right: 20px;
  }

  .lg\:mr-15px {
    margin-right: 15px;
  }

  .lg\:mr-13px {
    margin-right: 13px;
  }

  .lg\:mr-12px {
    margin-right: 12px;
  }

  .lg\:mr-10px {
    margin-right: 10px;
  }

  .lg\:mr-8px {
    margin-right: 8px;
  }

  .lg\:mr-6px {
    margin-right: 6px;
  }

  .lg\:mr-5px {
    margin-right: 5px;
  }

  .lg\:mr-3px {
    margin-right: 3px;
  }

  .lg\:mr-menu {
    margin-right: 275px;
  }

  .lg\:mr-px {
    margin-right: 1px !important;
  }

  .lg\:-mr-1 {
    margin-right: -0.25rem;
  }

  .lg\:-mr-2 {
    margin-right: -0.5rem;
  }

  .lg\:-mr-3 {
    margin-right: -0.75rem;
  }

  .lg\:-mr-4 {
    margin-right: -1rem;
  }

  .lg\:-mr-5 {
    margin-right: -1.25rem;
  }

  .lg\:-mr-6 {
    margin-right: -1.5rem;
  }

  .lg\:-mr-8 {
    margin-right: -2rem;
  }

  .lg\:-mr-10 {
    margin-right: -2.5rem;
  }

  .lg\:-mr-12 {
    margin-right: -3rem;
  }

  .lg\:-mr-16 {
    margin-right: -4rem;
  }

  .lg\:-mr-20 {
    margin-right: -5rem;
  }

  .lg\:-mr-24 {
    margin-right: -6rem;
  }

  .lg\:-mr-32 {
    margin-right: -8rem;
  }

  .lg\:-mr-40 {
    margin-right: -10rem;
  }

  .lg\:-mr-48 {
    margin-right: -12rem;
  }

  .lg\:-mr-56 {
    margin-right: -14rem;
  }

  .lg\:-mr-64 {
    margin-right: -16rem;
  }

  .lg\:-mr-300px {
    margin-right: -300px;
  }

  .lg\:-mr-230px {
    margin-right: -230px;
  }

  .lg\:-mr-180px {
    margin-right: -180px;
  }

  .lg\:-mr-147px {
    margin-right: -147px;
  }

  .lg\:-mr-123px {
    margin-right: -123px;
  }

  .lg\:-mr-100px {
    margin-right: -100px;
  }

  .lg\:-mr-80px {
    margin-right: -80px;
  }

  .lg\:-mr-60px {
    margin-right: -60px;
  }

  .lg\:-mr-50px {
    margin-right: -50px;
  }

  .lg\:-mr-40px {
    margin-right: -40px;
  }

  .lg\:-mr-35px {
    margin-right: -35px;
  }

  .lg\:-mr-34px-i {
    margin-right: 34px !important;
  }

  .lg\:-mr-30px-i {
    margin-right: 30px !important;
  }

  .lg\:-mr-24px-i {
    margin-right: 24px !important;
  }

  .lg\:-mr-20px-i {
    margin-right: 20px !important;
  }

  .lg\:-mr-15px-i {
    margin-right: 15px !important;
  }

  .lg\:-mr-13px-i {
    margin-right: 13px !important;
  }

  .lg\:-mr-12px-i {
    margin-right: 12px !important;
  }

  .lg\:-mr-10px-i {
    margin-right: 10px !important;
  }

  .lg\:-mr-8px-i {
    margin-right: 8px !important;
  }

  .lg\:-mr-6px-i {
    margin-right: 6px !important;
  }

  .lg\:-mr-5px-i {
    margin-right: 5px !important;
  }

  .lg\:-mr-3px-i {
    margin-right: 3px !important;
  }

  .lg\:-mr-0px-i {
    margin-right: 0px !important;
  }

  .lg\:-mr-34px {
    margin-right: -34px;
  }

  .lg\:-mr-30px {
    margin-right: -30px;
  }

  .lg\:-mr-24px {
    margin-right: -24px;
  }

  .lg\:-mr-20px {
    margin-right: -20px;
  }

  .lg\:-mr-15px {
    margin-right: -15px;
  }

  .lg\:-mr-13px {
    margin-right: -13px;
  }

  .lg\:-mr-12px {
    margin-right: -12px;
  }

  .lg\:-mr-10px {
    margin-right: -10px;
  }

  .lg\:-mr-8px {
    margin-right: -8px;
  }

  .lg\:-mr-6px {
    margin-right: -6px;
  }

  .lg\:-mr-5px {
    margin-right: -5px;
  }

  .lg\:-mr-3px {
    margin-right: -3px;
  }

  .lg\:-mr-menu {
    margin-right: -275px;
  }

  .lg\:-mr-px {
    margin-right: 1px !important;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-1 {
    margin-bottom: 0.25rem;
  }

  .lg\:mb-2 {
    margin-bottom: 0.5rem;
  }

  .lg\:mb-3 {
    margin-bottom: 0.75rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:mb-24 {
    margin-bottom: 6rem;
  }

  .lg\:mb-32 {
    margin-bottom: 8rem;
  }

  .lg\:mb-40 {
    margin-bottom: 10rem;
  }

  .lg\:mb-48 {
    margin-bottom: 12rem;
  }

  .lg\:mb-56 {
    margin-bottom: 14rem;
  }

  .lg\:mb-64 {
    margin-bottom: 16rem;
  }

  .lg\:mb-auto {
    margin-bottom: auto;
  }

  .lg\:mb-300px {
    margin-bottom: 300px;
  }

  .lg\:mb-230px {
    margin-bottom: 230px;
  }

  .lg\:mb-180px {
    margin-bottom: 180px;
  }

  .lg\:mb-147px {
    margin-bottom: 147px;
  }

  .lg\:mb-123px {
    margin-bottom: 123px;
  }

  .lg\:mb-100px {
    margin-bottom: 100px;
  }

  .lg\:mb-80px {
    margin-bottom: 80px;
  }

  .lg\:mb-60px {
    margin-bottom: 60px;
  }

  .lg\:mb-50px {
    margin-bottom: 50px;
  }

  .lg\:mb-40px {
    margin-bottom: 40px;
  }

  .lg\:mb-35px {
    margin-bottom: 35px;
  }

  .lg\:mb-34px-i {
    margin-bottom: 34px !important;
  }

  .lg\:mb-30px-i {
    margin-bottom: 30px !important;
  }

  .lg\:mb-24px-i {
    margin-bottom: 24px !important;
  }

  .lg\:mb-20px-i {
    margin-bottom: 20px !important;
  }

  .lg\:mb-15px-i {
    margin-bottom: 15px !important;
  }

  .lg\:mb-13px-i {
    margin-bottom: 13px !important;
  }

  .lg\:mb-12px-i {
    margin-bottom: 12px !important;
  }

  .lg\:mb-10px-i {
    margin-bottom: 10px !important;
  }

  .lg\:mb-8px-i {
    margin-bottom: 8px !important;
  }

  .lg\:mb-6px-i {
    margin-bottom: 6px !important;
  }

  .lg\:mb-5px-i {
    margin-bottom: 5px !important;
  }

  .lg\:mb-3px-i {
    margin-bottom: 3px !important;
  }

  .lg\:mb-0px-i {
    margin-bottom: 0px !important;
  }

  .lg\:mb-34px {
    margin-bottom: 34px;
  }

  .lg\:mb-30px {
    margin-bottom: 30px;
  }

  .lg\:mb-24px {
    margin-bottom: 24px;
  }

  .lg\:mb-20px {
    margin-bottom: 20px;
  }

  .lg\:mb-15px {
    margin-bottom: 15px;
  }

  .lg\:mb-13px {
    margin-bottom: 13px;
  }

  .lg\:mb-12px {
    margin-bottom: 12px;
  }

  .lg\:mb-10px {
    margin-bottom: 10px;
  }

  .lg\:mb-8px {
    margin-bottom: 8px;
  }

  .lg\:mb-6px {
    margin-bottom: 6px;
  }

  .lg\:mb-5px {
    margin-bottom: 5px;
  }

  .lg\:mb-3px {
    margin-bottom: 3px;
  }

  .lg\:mb-menu {
    margin-bottom: 275px;
  }

  .lg\:mb-px {
    margin-bottom: 1px !important;
  }

  .lg\:-mb-1 {
    margin-bottom: -0.25rem;
  }

  .lg\:-mb-2 {
    margin-bottom: -0.5rem;
  }

  .lg\:-mb-3 {
    margin-bottom: -0.75rem;
  }

  .lg\:-mb-4 {
    margin-bottom: -1rem;
  }

  .lg\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .lg\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .lg\:-mb-8 {
    margin-bottom: -2rem;
  }

  .lg\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .lg\:-mb-12 {
    margin-bottom: -3rem;
  }

  .lg\:-mb-16 {
    margin-bottom: -4rem;
  }

  .lg\:-mb-20 {
    margin-bottom: -5rem;
  }

  .lg\:-mb-24 {
    margin-bottom: -6rem;
  }

  .lg\:-mb-32 {
    margin-bottom: -8rem;
  }

  .lg\:-mb-40 {
    margin-bottom: -10rem;
  }

  .lg\:-mb-48 {
    margin-bottom: -12rem;
  }

  .lg\:-mb-56 {
    margin-bottom: -14rem;
  }

  .lg\:-mb-64 {
    margin-bottom: -16rem;
  }

  .lg\:-mb-300px {
    margin-bottom: -300px;
  }

  .lg\:-mb-230px {
    margin-bottom: -230px;
  }

  .lg\:-mb-180px {
    margin-bottom: -180px;
  }

  .lg\:-mb-147px {
    margin-bottom: -147px;
  }

  .lg\:-mb-123px {
    margin-bottom: -123px;
  }

  .lg\:-mb-100px {
    margin-bottom: -100px;
  }

  .lg\:-mb-80px {
    margin-bottom: -80px;
  }

  .lg\:-mb-60px {
    margin-bottom: -60px;
  }

  .lg\:-mb-50px {
    margin-bottom: -50px;
  }

  .lg\:-mb-40px {
    margin-bottom: -40px;
  }

  .lg\:-mb-35px {
    margin-bottom: -35px;
  }

  .lg\:-mb-34px-i {
    margin-bottom: 34px !important;
  }

  .lg\:-mb-30px-i {
    margin-bottom: 30px !important;
  }

  .lg\:-mb-24px-i {
    margin-bottom: 24px !important;
  }

  .lg\:-mb-20px-i {
    margin-bottom: 20px !important;
  }

  .lg\:-mb-15px-i {
    margin-bottom: 15px !important;
  }

  .lg\:-mb-13px-i {
    margin-bottom: 13px !important;
  }

  .lg\:-mb-12px-i {
    margin-bottom: 12px !important;
  }

  .lg\:-mb-10px-i {
    margin-bottom: 10px !important;
  }

  .lg\:-mb-8px-i {
    margin-bottom: 8px !important;
  }

  .lg\:-mb-6px-i {
    margin-bottom: 6px !important;
  }

  .lg\:-mb-5px-i {
    margin-bottom: 5px !important;
  }

  .lg\:-mb-3px-i {
    margin-bottom: 3px !important;
  }

  .lg\:-mb-0px-i {
    margin-bottom: 0px !important;
  }

  .lg\:-mb-34px {
    margin-bottom: -34px;
  }

  .lg\:-mb-30px {
    margin-bottom: -30px;
  }

  .lg\:-mb-24px {
    margin-bottom: -24px;
  }

  .lg\:-mb-20px {
    margin-bottom: -20px;
  }

  .lg\:-mb-15px {
    margin-bottom: -15px;
  }

  .lg\:-mb-13px {
    margin-bottom: -13px;
  }

  .lg\:-mb-12px {
    margin-bottom: -12px;
  }

  .lg\:-mb-10px {
    margin-bottom: -10px;
  }

  .lg\:-mb-8px {
    margin-bottom: -8px;
  }

  .lg\:-mb-6px {
    margin-bottom: -6px;
  }

  .lg\:-mb-5px {
    margin-bottom: -5px;
  }

  .lg\:-mb-3px {
    margin-bottom: -3px;
  }

  .lg\:-mb-menu {
    margin-bottom: -275px;
  }

  .lg\:-mb-px {
    margin-bottom: 1px !important;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:ml-1 {
    margin-left: 0.25rem;
  }

  .lg\:ml-2 {
    margin-left: 0.5rem;
  }

  .lg\:ml-3 {
    margin-left: 0.75rem;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:ml-10 {
    margin-left: 2.5rem;
  }

  .lg\:ml-12 {
    margin-left: 3rem;
  }

  .lg\:ml-16 {
    margin-left: 4rem;
  }

  .lg\:ml-20 {
    margin-left: 5rem;
  }

  .lg\:ml-24 {
    margin-left: 6rem;
  }

  .lg\:ml-32 {
    margin-left: 8rem;
  }

  .lg\:ml-40 {
    margin-left: 10rem;
  }

  .lg\:ml-48 {
    margin-left: 12rem;
  }

  .lg\:ml-56 {
    margin-left: 14rem;
  }

  .lg\:ml-64 {
    margin-left: 16rem;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:ml-300px {
    margin-left: 300px;
  }

  .lg\:ml-230px {
    margin-left: 230px;
  }

  .lg\:ml-180px {
    margin-left: 180px;
  }

  .lg\:ml-147px {
    margin-left: 147px;
  }

  .lg\:ml-123px {
    margin-left: 123px;
  }

  .lg\:ml-100px {
    margin-left: 100px;
  }

  .lg\:ml-80px {
    margin-left: 80px;
  }

  .lg\:ml-60px {
    margin-left: 60px;
  }

  .lg\:ml-50px {
    margin-left: 50px;
  }

  .lg\:ml-40px {
    margin-left: 40px;
  }

  .lg\:ml-35px {
    margin-left: 35px;
  }

  .lg\:ml-34px-i {
    margin-left: 34px !important;
  }

  .lg\:ml-30px-i {
    margin-left: 30px !important;
  }

  .lg\:ml-24px-i {
    margin-left: 24px !important;
  }

  .lg\:ml-20px-i {
    margin-left: 20px !important;
  }

  .lg\:ml-15px-i {
    margin-left: 15px !important;
  }

  .lg\:ml-13px-i {
    margin-left: 13px !important;
  }

  .lg\:ml-12px-i {
    margin-left: 12px !important;
  }

  .lg\:ml-10px-i {
    margin-left: 10px !important;
  }

  .lg\:ml-8px-i {
    margin-left: 8px !important;
  }

  .lg\:ml-6px-i {
    margin-left: 6px !important;
  }

  .lg\:ml-5px-i {
    margin-left: 5px !important;
  }

  .lg\:ml-3px-i {
    margin-left: 3px !important;
  }

  .lg\:ml-0px-i {
    margin-left: 0px !important;
  }

  .lg\:ml-34px {
    margin-left: 34px;
  }

  .lg\:ml-30px {
    margin-left: 30px;
  }

  .lg\:ml-24px {
    margin-left: 24px;
  }

  .lg\:ml-20px {
    margin-left: 20px;
  }

  .lg\:ml-15px {
    margin-left: 15px;
  }

  .lg\:ml-13px {
    margin-left: 13px;
  }

  .lg\:ml-12px {
    margin-left: 12px;
  }

  .lg\:ml-10px {
    margin-left: 10px;
  }

  .lg\:ml-8px {
    margin-left: 8px;
  }

  .lg\:ml-6px {
    margin-left: 6px;
  }

  .lg\:ml-5px {
    margin-left: 5px;
  }

  .lg\:ml-3px {
    margin-left: 3px;
  }

  .lg\:ml-menu {
    margin-left: 275px;
  }

  .lg\:ml-px {
    margin-left: 1px !important;
  }

  .lg\:-ml-1 {
    margin-left: -0.25rem;
  }

  .lg\:-ml-2 {
    margin-left: -0.5rem;
  }

  .lg\:-ml-3 {
    margin-left: -0.75rem;
  }

  .lg\:-ml-4 {
    margin-left: -1rem;
  }

  .lg\:-ml-5 {
    margin-left: -1.25rem;
  }

  .lg\:-ml-6 {
    margin-left: -1.5rem;
  }

  .lg\:-ml-8 {
    margin-left: -2rem;
  }

  .lg\:-ml-10 {
    margin-left: -2.5rem;
  }

  .lg\:-ml-12 {
    margin-left: -3rem;
  }

  .lg\:-ml-16 {
    margin-left: -4rem;
  }

  .lg\:-ml-20 {
    margin-left: -5rem;
  }

  .lg\:-ml-24 {
    margin-left: -6rem;
  }

  .lg\:-ml-32 {
    margin-left: -8rem;
  }

  .lg\:-ml-40 {
    margin-left: -10rem;
  }

  .lg\:-ml-48 {
    margin-left: -12rem;
  }

  .lg\:-ml-56 {
    margin-left: -14rem;
  }

  .lg\:-ml-64 {
    margin-left: -16rem;
  }

  .lg\:-ml-300px {
    margin-left: -300px;
  }

  .lg\:-ml-230px {
    margin-left: -230px;
  }

  .lg\:-ml-180px {
    margin-left: -180px;
  }

  .lg\:-ml-147px {
    margin-left: -147px;
  }

  .lg\:-ml-123px {
    margin-left: -123px;
  }

  .lg\:-ml-100px {
    margin-left: -100px;
  }

  .lg\:-ml-80px {
    margin-left: -80px;
  }

  .lg\:-ml-60px {
    margin-left: -60px;
  }

  .lg\:-ml-50px {
    margin-left: -50px;
  }

  .lg\:-ml-40px {
    margin-left: -40px;
  }

  .lg\:-ml-35px {
    margin-left: -35px;
  }

  .lg\:-ml-34px-i {
    margin-left: 34px !important;
  }

  .lg\:-ml-30px-i {
    margin-left: 30px !important;
  }

  .lg\:-ml-24px-i {
    margin-left: 24px !important;
  }

  .lg\:-ml-20px-i {
    margin-left: 20px !important;
  }

  .lg\:-ml-15px-i {
    margin-left: 15px !important;
  }

  .lg\:-ml-13px-i {
    margin-left: 13px !important;
  }

  .lg\:-ml-12px-i {
    margin-left: 12px !important;
  }

  .lg\:-ml-10px-i {
    margin-left: 10px !important;
  }

  .lg\:-ml-8px-i {
    margin-left: 8px !important;
  }

  .lg\:-ml-6px-i {
    margin-left: 6px !important;
  }

  .lg\:-ml-5px-i {
    margin-left: 5px !important;
  }

  .lg\:-ml-3px-i {
    margin-left: 3px !important;
  }

  .lg\:-ml-0px-i {
    margin-left: 0px !important;
  }

  .lg\:-ml-34px {
    margin-left: -34px;
  }

  .lg\:-ml-30px {
    margin-left: -30px;
  }

  .lg\:-ml-24px {
    margin-left: -24px;
  }

  .lg\:-ml-20px {
    margin-left: -20px;
  }

  .lg\:-ml-15px {
    margin-left: -15px;
  }

  .lg\:-ml-13px {
    margin-left: -13px;
  }

  .lg\:-ml-12px {
    margin-left: -12px;
  }

  .lg\:-ml-10px {
    margin-left: -10px;
  }

  .lg\:-ml-8px {
    margin-left: -8px;
  }

  .lg\:-ml-6px {
    margin-left: -6px;
  }

  .lg\:-ml-5px {
    margin-left: -5px;
  }

  .lg\:-ml-3px {
    margin-left: -3px;
  }

  .lg\:-ml-menu {
    margin-left: -275px;
  }

  .lg\:-ml-px {
    margin-left: 1px !important;
  }

  .lg\:box-border {
    box-sizing: border-box;
  }

  .lg\:box-content {
    box-sizing: content-box;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:inline-flex {
    display: inline-flex;
  }

  .lg\:table {
    display: table;
  }

  .lg\:inline-table {
    display: inline-table;
  }

  .lg\:table-caption {
    display: table-caption;
  }

  .lg\:table-cell {
    display: table-cell;
  }

  .lg\:table-column {
    display: table-column;
  }

  .lg\:table-column-group {
    display: table-column-group;
  }

  .lg\:table-footer-group {
    display: table-footer-group;
  }

  .lg\:table-header-group {
    display: table-header-group;
  }

  .lg\:table-row-group {
    display: table-row-group;
  }

  .lg\:table-row {
    display: table-row;
  }

  .lg\:flow-root {
    display: flow-root;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:inline-grid {
    display: inline-grid;
  }

  .lg\:contents {
    display: contents;
  }

  .lg\:list-item {
    display: list-item;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-0 {
    height: 0;
  }

  .lg\:h-1 {
    height: 0.25rem;
  }

  .lg\:h-2 {
    height: 0.5rem;
  }

  .lg\:h-3 {
    height: 0.75rem;
  }

  .lg\:h-4 {
    height: 1rem;
  }

  .lg\:h-5 {
    height: 1.25rem;
  }

  .lg\:h-6 {
    height: 1.5rem;
  }

  .lg\:h-8 {
    height: 2rem;
  }

  .lg\:h-10 {
    height: 2.5rem;
  }

  .lg\:h-12 {
    height: 3rem;
  }

  .lg\:h-16 {
    height: 4rem;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:h-24 {
    height: 6rem;
  }

  .lg\:h-32 {
    height: 8rem;
  }

  .lg\:h-40 {
    height: 10rem;
  }

  .lg\:h-48 {
    height: 12rem;
  }

  .lg\:h-56 {
    height: 14rem;
  }

  .lg\:h-64 {
    height: 16rem;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:h-300px {
    height: 300px;
  }

  .lg\:h-230px {
    height: 230px;
  }

  .lg\:h-180px {
    height: 180px;
  }

  .lg\:h-147px {
    height: 147px;
  }

  .lg\:h-123px {
    height: 123px;
  }

  .lg\:h-100px {
    height: 100px;
  }

  .lg\:h-80px {
    height: 80px;
  }

  .lg\:h-60px {
    height: 60px;
  }

  .lg\:h-50px {
    height: 50px;
  }

  .lg\:h-40px {
    height: 40px;
  }

  .lg\:h-35px {
    height: 35px;
  }

  .lg\:h-34px-i {
    height: 34px !important;
  }

  .lg\:h-30px-i {
    height: 30px !important;
  }

  .lg\:h-24px-i {
    height: 24px !important;
  }

  .lg\:h-20px-i {
    height: 20px !important;
  }

  .lg\:h-15px-i {
    height: 15px !important;
  }

  .lg\:h-13px-i {
    height: 13px !important;
  }

  .lg\:h-12px-i {
    height: 12px !important;
  }

  .lg\:h-10px-i {
    height: 10px !important;
  }

  .lg\:h-8px-i {
    height: 8px !important;
  }

  .lg\:h-6px-i {
    height: 6px !important;
  }

  .lg\:h-5px-i {
    height: 5px !important;
  }

  .lg\:h-3px-i {
    height: 3px !important;
  }

  .lg\:h-0px-i {
    height: 0px !important;
  }

  .lg\:h-34px {
    height: 34px;
  }

  .lg\:h-30px {
    height: 30px;
  }

  .lg\:h-24px {
    height: 24px;
  }

  .lg\:h-20px {
    height: 20px;
  }

  .lg\:h-15px {
    height: 15px;
  }

  .lg\:h-13px {
    height: 13px;
  }

  .lg\:h-12px {
    height: 12px;
  }

  .lg\:h-10px {
    height: 10px;
  }

  .lg\:h-8px {
    height: 8px;
  }

  .lg\:h-6px {
    height: 6px;
  }

  .lg\:h-5px {
    height: 5px;
  }

  .lg\:h-3px {
    height: 3px;
  }

  .lg\:h-menu {
    height: 275px;
  }

  .lg\:h-px {
    height: 1px !important;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:max-h-full {
    max-height: 100%;
  }

  .lg\:max-h-screen {
    max-height: 100vh;
  }

  .lg\:min-h-0 {
    min-height: 0;
  }

  .lg\:min-h-full {
    min-height: 100%;
  }

  .lg\:min-h-screen {
    min-height: 100vh;
  }

  .lg\:w-0 {
    width: 0;
  }

  .lg\:w-1 {
    width: 0.25rem;
  }

  .lg\:w-2 {
    width: 0.5rem;
  }

  .lg\:w-3 {
    width: 0.75rem;
  }

  .lg\:w-4 {
    width: 1rem;
  }

  .lg\:w-5 {
    width: 1.25rem;
  }

  .lg\:w-6 {
    width: 1.5rem;
  }

  .lg\:w-8 {
    width: 2rem;
  }

  .lg\:w-10 {
    width: 2.5rem;
  }

  .lg\:w-12 {
    width: 3rem;
  }

  .lg\:w-16 {
    width: 4rem;
  }

  .lg\:w-20 {
    width: 5rem;
  }

  .lg\:w-24 {
    width: 6rem;
  }

  .lg\:w-32 {
    width: 8rem;
  }

  .lg\:w-40 {
    width: 10rem;
  }

  .lg\:w-48 {
    width: 12rem;
  }

  .lg\:w-56 {
    width: 14rem;
  }

  .lg\:w-64 {
    width: 16rem;
  }

  .lg\:w-auto {
    width: auto !important;
  }

  .lg\:w-300px {
    width: 300px;
  }

  .lg\:w-230px {
    width: 230px;
  }

  .lg\:w-180px {
    width: 180px;
  }

  .lg\:w-147px {
    width: 147px;
  }

  .lg\:w-123px {
    width: 123px;
  }

  .lg\:w-100px {
    width: 100px;
  }

  .lg\:w-80px {
    width: 80px;
  }

  .lg\:w-60px {
    width: 60px;
  }

  .lg\:w-50px {
    width: 50px;
  }

  .lg\:w-40px {
    width: 40px;
  }

  .lg\:w-35px {
    width: 35px;
  }

  .lg\:w-34px-i {
    width: 34px !important;
  }

  .lg\:w-30px-i {
    width: 30px !important;
  }

  .lg\:w-24px-i {
    width: 24px !important;
  }

  .lg\:w-20px-i {
    width: 20px !important;
  }

  .lg\:w-15px-i {
    width: 15px !important;
  }

  .lg\:w-13px-i {
    width: 13px !important;
  }

  .lg\:w-12px-i {
    width: 12px !important;
  }

  .lg\:w-10px-i {
    width: 10px !important;
  }

  .lg\:w-8px-i {
    width: 8px !important;
  }

  .lg\:w-6px-i {
    width: 6px !important;
  }

  .lg\:w-5px-i {
    width: 5px !important;
  }

  .lg\:w-3px-i {
    width: 3px !important;
  }

  .lg\:w-0px-i {
    width: 0px !important;
  }

  .lg\:w-34px {
    width: 34px;
  }

  .lg\:w-30px {
    width: 30px;
  }

  .lg\:w-24px {
    width: 24px;
  }

  .lg\:w-20px {
    width: 20px;
  }

  .lg\:w-15px {
    width: 15px;
  }

  .lg\:w-13px {
    width: 13px;
  }

  .lg\:w-12px {
    width: 12px;
  }

  .lg\:w-10px {
    width: 10px;
  }

  .lg\:w-8px {
    width: 8px;
  }

  .lg\:w-6px {
    width: 6px;
  }

  .lg\:w-5px {
    width: 5px;
  }

  .lg\:w-3px {
    width: 3px;
  }

  .lg\:w-menu {
    width: 275px;
  }

  .lg\:w-px {
    width: 1px !important;
  }

  .lg\:w-1\/2 {
    width: 50% !important;
  }

  .lg\:w-1\/3 {
    width: 33.333333% !important;
  }

  .lg\:w-2\/3 {
    width: 66.666667% !important;
  }

  .lg\:w-1\/4 {
    width: 25% !important;
  }

  .lg\:w-2\/4 {
    width: 50% !important;
  }

  .lg\:w-3\/4 {
    width: 75% !important;
  }

  .lg\:w-1\/5 {
    width: 20% !important;
  }

  .lg\:w-2\/5 {
    width: 40% !important;
  }

  .lg\:w-3\/5 {
    width: 60% !important;
  }

  .lg\:w-4\/5 {
    width: 80% !important;
  }

  .lg\:w-1\/6 {
    width: 16.666667% !important;
  }

  .lg\:w-2\/6 {
    width: 33.333333% !important;
  }

  .lg\:w-3\/6 {
    width: 50% !important;
  }

  .lg\:w-4\/6 {
    width: 66.666667% !important;
  }

  .lg\:w-5\/6 {
    width: 83.333333% !important;
  }

  .lg\:w-1\/12 {
    width: 8.333333% !important;
  }

  .lg\:w-2\/12 {
    width: 16.666667% !important;
  }

  .lg\:w-3\/12 {
    width: 25% !important;
  }

  .lg\:w-4\/12 {
    width: 33.333333% !important;
  }

  .lg\:w-5\/12 {
    width: 41.666667% !important;
  }

  .lg\:w-6\/12 {
    width: 50% !important;
  }

  .lg\:w-7\/12 {
    width: 58.333333% !important;
  }

  .lg\:w-8\/12 {
    width: 66.666667% !important;
  }

  .lg\:w-9\/12 {
    width: 75% !important;
  }

  .lg\:w-10\/12 {
    width: 83.333333% !important;
  }

  .lg\:w-11\/12 {
    width: 91.666667% !important;
  }

  .lg\:w-full {
    width: 100% !important;
  }

  .lg\:w-screen {
    width: 100vw !important;
  }

  .lg\:min-w-0 {
    min-width: 0;
  }

  .lg\:min-w-full {
    min-width: 100%;
  }

  .lg\:max-w-1420px {
    max-width: 1420px;
  }

  .lg\:max-w-1004px {
    max-width: 1004px;
  }

  .lg\:max-w-920px {
    max-width: 920px;
  }

  .lg\:max-w-645px {
    max-width: 645px;
  }

  .lg\:max-w-menu {
    max-width: 275px;
  }

  .lg\:max-w-780px {
    max-width: 780px !important;
  }

  .lg\:max-w-240px {
    max-width: 240px !important;
  }

  .lg\:max-w-100px {
    max-width: 100px !important;
  }

  .lg\:max-w-285px {
    max-width: 285px !important;
  }

  .lg\:max-w-100pct {
    max-width: 100% !important;
  }

  .lg\:max-w-none {
    max-width: none !important;
  }

  .lg\:max-w-xs {
    max-width: 20rem;
  }

  .lg\:max-w-sm {
    max-width: 24rem;
  }

  .lg\:max-w-md {
    max-width: 28rem;
  }

  .lg\:max-w-lg {
    max-width: 32rem;
  }

  .lg\:max-w-xl {
    max-width: 36rem;
  }

  .lg\:max-w-2xl {
    max-width: 42rem;
  }

  .lg\:max-w-3xl {
    max-width: 48rem;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:max-w-5xl {
    max-width: 64rem;
  }

  .lg\:max-w-6xl {
    max-width: 72rem;
  }

  .lg\:max-w-full {
    max-width: 100% !important;
  }

  .lg\:max-w-screen-xs {
    max-width: 1px;
  }

  .lg\:max-w-screen-sm {
    max-width: 768px;
  }

  .lg\:max-w-screen-md {
    max-width: 1024px;
  }

  .lg\:max-w-screen-lg {
    max-width: 1440px;
  }

  .lg\:max-w-screen-xl {
    max-width: 1920px;
  }

  .lg\:flex-1 {
    flex: 1 1 0%;
  }

  .lg\:flex-auto {
    flex: 1 1 auto;
  }

  .lg\:flex-initial {
    flex: 0 1 auto;
  }

  .lg\:flex-none {
    flex: none;
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .lg\:flex-shrink {
    flex-shrink: 1;
  }

  .lg\:flex-grow-0 {
    flex-grow: 0;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:table-auto {
    table-layout: auto;
  }

  .lg\:table-fixed {
    table-layout: fixed;
  }

  .lg\:border-collapse {
    border-collapse: collapse;
  }

  .lg\:border-separate {
    border-collapse: separate;
  }

  .lg\:origin-center {
    transform-origin: center;
  }

  .lg\:origin-top {
    transform-origin: top;
  }

  .lg\:origin-top-right {
    transform-origin: top right;
  }

  .lg\:origin-right {
    transform-origin: right;
  }

  .lg\:origin-bottom-right {
    transform-origin: bottom right;
  }

  .lg\:origin-bottom {
    transform-origin: bottom;
  }

  .lg\:origin-bottom-left {
    transform-origin: bottom left;
  }

  .lg\:origin-left {
    transform-origin: left;
  }

  .lg\:origin-top-left {
    transform-origin: top left;
  }

  .lg\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:transform-none {
    transform: none;
  }

  .lg\:translate-x-0 {
    --tw-translate-x: 0;
  }

  .lg\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }

  .lg\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }

  .lg\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }

  .lg\:translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .lg\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .lg\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .lg\:translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .lg\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .lg\:translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .lg\:translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .lg\:translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .lg\:translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .lg\:translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .lg\:translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .lg\:translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .lg\:translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .lg\:translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .lg\:translate-x-300px {
    --tw-translate-x: 300px;
  }

  .lg\:translate-x-230px {
    --tw-translate-x: 230px;
  }

  .lg\:translate-x-180px {
    --tw-translate-x: 180px;
  }

  .lg\:translate-x-147px {
    --tw-translate-x: 147px;
  }

  .lg\:translate-x-123px {
    --tw-translate-x: 123px;
  }

  .lg\:translate-x-100px {
    --tw-translate-x: 100px;
  }

  .lg\:translate-x-80px {
    --tw-translate-x: 80px;
  }

  .lg\:translate-x-60px {
    --tw-translate-x: 60px;
  }

  .lg\:translate-x-50px {
    --tw-translate-x: 50px;
  }

  .lg\:translate-x-40px {
    --tw-translate-x: 40px;
  }

  .lg\:translate-x-35px {
    --tw-translate-x: 35px;
  }

  .lg\:translate-x-34px-i {
    --tw-translate-x: 34px !important;
  }

  .lg\:translate-x-30px-i {
    --tw-translate-x: 30px !important;
  }

  .lg\:translate-x-24px-i {
    --tw-translate-x: 24px !important;
  }

  .lg\:translate-x-20px-i {
    --tw-translate-x: 20px !important;
  }

  .lg\:translate-x-15px-i {
    --tw-translate-x: 15px !important;
  }

  .lg\:translate-x-13px-i {
    --tw-translate-x: 13px !important;
  }

  .lg\:translate-x-12px-i {
    --tw-translate-x: 12px !important;
  }

  .lg\:translate-x-10px-i {
    --tw-translate-x: 10px !important;
  }

  .lg\:translate-x-8px-i {
    --tw-translate-x: 8px !important;
  }

  .lg\:translate-x-6px-i {
    --tw-translate-x: 6px !important;
  }

  .lg\:translate-x-5px-i {
    --tw-translate-x: 5px !important;
  }

  .lg\:translate-x-3px-i {
    --tw-translate-x: 3px !important;
  }

  .lg\:translate-x-0px-i {
    --tw-translate-x: 0px !important;
  }

  .lg\:translate-x-34px {
    --tw-translate-x: 34px;
  }

  .lg\:translate-x-30px {
    --tw-translate-x: 30px;
  }

  .lg\:translate-x-24px {
    --tw-translate-x: 24px;
  }

  .lg\:translate-x-20px {
    --tw-translate-x: 20px;
  }

  .lg\:translate-x-15px {
    --tw-translate-x: 15px;
  }

  .lg\:translate-x-13px {
    --tw-translate-x: 13px;
  }

  .lg\:translate-x-12px {
    --tw-translate-x: 12px;
  }

  .lg\:translate-x-10px {
    --tw-translate-x: 10px;
  }

  .lg\:translate-x-8px {
    --tw-translate-x: 8px;
  }

  .lg\:translate-x-6px {
    --tw-translate-x: 6px;
  }

  .lg\:translate-x-5px {
    --tw-translate-x: 5px;
  }

  .lg\:translate-x-3px {
    --tw-translate-x: 3px;
  }

  .lg\:translate-x-menu {
    --tw-translate-x: 275px;
  }

  .lg\:translate-x-px {
    --tw-translate-x: 1px !important;
  }

  .lg\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }

  .lg\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }

  .lg\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }

  .lg\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .lg\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .lg\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .lg\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .lg\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .lg\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .lg\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .lg\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .lg\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .lg\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .lg\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .lg\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .lg\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .lg\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .lg\:-translate-x-300px {
    --tw-translate-x: -300px;
  }

  .lg\:-translate-x-230px {
    --tw-translate-x: -230px;
  }

  .lg\:-translate-x-180px {
    --tw-translate-x: -180px;
  }

  .lg\:-translate-x-147px {
    --tw-translate-x: -147px;
  }

  .lg\:-translate-x-123px {
    --tw-translate-x: -123px;
  }

  .lg\:-translate-x-100px {
    --tw-translate-x: -100px;
  }

  .lg\:-translate-x-80px {
    --tw-translate-x: -80px;
  }

  .lg\:-translate-x-60px {
    --tw-translate-x: -60px;
  }

  .lg\:-translate-x-50px {
    --tw-translate-x: -50px;
  }

  .lg\:-translate-x-40px {
    --tw-translate-x: -40px;
  }

  .lg\:-translate-x-35px {
    --tw-translate-x: -35px;
  }

  .lg\:-translate-x-34px-i {
    --tw-translate-x: 34px !important;
  }

  .lg\:-translate-x-30px-i {
    --tw-translate-x: 30px !important;
  }

  .lg\:-translate-x-24px-i {
    --tw-translate-x: 24px !important;
  }

  .lg\:-translate-x-20px-i {
    --tw-translate-x: 20px !important;
  }

  .lg\:-translate-x-15px-i {
    --tw-translate-x: 15px !important;
  }

  .lg\:-translate-x-13px-i {
    --tw-translate-x: 13px !important;
  }

  .lg\:-translate-x-12px-i {
    --tw-translate-x: 12px !important;
  }

  .lg\:-translate-x-10px-i {
    --tw-translate-x: 10px !important;
  }

  .lg\:-translate-x-8px-i {
    --tw-translate-x: 8px !important;
  }

  .lg\:-translate-x-6px-i {
    --tw-translate-x: 6px !important;
  }

  .lg\:-translate-x-5px-i {
    --tw-translate-x: 5px !important;
  }

  .lg\:-translate-x-3px-i {
    --tw-translate-x: 3px !important;
  }

  .lg\:-translate-x-0px-i {
    --tw-translate-x: 0px !important;
  }

  .lg\:-translate-x-34px {
    --tw-translate-x: -34px;
  }

  .lg\:-translate-x-30px {
    --tw-translate-x: -30px;
  }

  .lg\:-translate-x-24px {
    --tw-translate-x: -24px;
  }

  .lg\:-translate-x-20px {
    --tw-translate-x: -20px;
  }

  .lg\:-translate-x-15px {
    --tw-translate-x: -15px;
  }

  .lg\:-translate-x-13px {
    --tw-translate-x: -13px;
  }

  .lg\:-translate-x-12px {
    --tw-translate-x: -12px;
  }

  .lg\:-translate-x-10px {
    --tw-translate-x: -10px;
  }

  .lg\:-translate-x-8px {
    --tw-translate-x: -8px;
  }

  .lg\:-translate-x-6px {
    --tw-translate-x: -6px;
  }

  .lg\:-translate-x-5px {
    --tw-translate-x: -5px;
  }

  .lg\:-translate-x-3px {
    --tw-translate-x: -3px;
  }

  .lg\:-translate-x-menu {
    --tw-translate-x: -275px;
  }

  .lg\:-translate-x-px {
    --tw-translate-x: 1px !important;
  }

  .lg\:-translate-x-full {
    --tw-translate-x: -100%;
  }

  .lg\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .lg\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .lg\:translate-x-full {
    --tw-translate-x: 100%;
  }

  .lg\:translate-y-0 {
    --tw-translate-y: 0;
  }

  .lg\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }

  .lg\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }

  .lg\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }

  .lg\:translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .lg\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .lg\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .lg\:translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .lg\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .lg\:translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .lg\:translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .lg\:translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .lg\:translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .lg\:translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .lg\:translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .lg\:translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .lg\:translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .lg\:translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .lg\:translate-y-300px {
    --tw-translate-y: 300px;
  }

  .lg\:translate-y-230px {
    --tw-translate-y: 230px;
  }

  .lg\:translate-y-180px {
    --tw-translate-y: 180px;
  }

  .lg\:translate-y-147px {
    --tw-translate-y: 147px;
  }

  .lg\:translate-y-123px {
    --tw-translate-y: 123px;
  }

  .lg\:translate-y-100px {
    --tw-translate-y: 100px;
  }

  .lg\:translate-y-80px {
    --tw-translate-y: 80px;
  }

  .lg\:translate-y-60px {
    --tw-translate-y: 60px;
  }

  .lg\:translate-y-50px {
    --tw-translate-y: 50px;
  }

  .lg\:translate-y-40px {
    --tw-translate-y: 40px;
  }

  .lg\:translate-y-35px {
    --tw-translate-y: 35px;
  }

  .lg\:translate-y-34px-i {
    --tw-translate-y: 34px !important;
  }

  .lg\:translate-y-30px-i {
    --tw-translate-y: 30px !important;
  }

  .lg\:translate-y-24px-i {
    --tw-translate-y: 24px !important;
  }

  .lg\:translate-y-20px-i {
    --tw-translate-y: 20px !important;
  }

  .lg\:translate-y-15px-i {
    --tw-translate-y: 15px !important;
  }

  .lg\:translate-y-13px-i {
    --tw-translate-y: 13px !important;
  }

  .lg\:translate-y-12px-i {
    --tw-translate-y: 12px !important;
  }

  .lg\:translate-y-10px-i {
    --tw-translate-y: 10px !important;
  }

  .lg\:translate-y-8px-i {
    --tw-translate-y: 8px !important;
  }

  .lg\:translate-y-6px-i {
    --tw-translate-y: 6px !important;
  }

  .lg\:translate-y-5px-i {
    --tw-translate-y: 5px !important;
  }

  .lg\:translate-y-3px-i {
    --tw-translate-y: 3px !important;
  }

  .lg\:translate-y-0px-i {
    --tw-translate-y: 0px !important;
  }

  .lg\:translate-y-34px {
    --tw-translate-y: 34px;
  }

  .lg\:translate-y-30px {
    --tw-translate-y: 30px;
  }

  .lg\:translate-y-24px {
    --tw-translate-y: 24px;
  }

  .lg\:translate-y-20px {
    --tw-translate-y: 20px;
  }

  .lg\:translate-y-15px {
    --tw-translate-y: 15px;
  }

  .lg\:translate-y-13px {
    --tw-translate-y: 13px;
  }

  .lg\:translate-y-12px {
    --tw-translate-y: 12px;
  }

  .lg\:translate-y-10px {
    --tw-translate-y: 10px;
  }

  .lg\:translate-y-8px {
    --tw-translate-y: 8px;
  }

  .lg\:translate-y-6px {
    --tw-translate-y: 6px;
  }

  .lg\:translate-y-5px {
    --tw-translate-y: 5px;
  }

  .lg\:translate-y-3px {
    --tw-translate-y: 3px;
  }

  .lg\:translate-y-menu {
    --tw-translate-y: 275px;
  }

  .lg\:translate-y-px {
    --tw-translate-y: 1px !important;
  }

  .lg\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }

  .lg\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }

  .lg\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }

  .lg\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .lg\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .lg\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .lg\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .lg\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .lg\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .lg\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .lg\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .lg\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .lg\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .lg\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .lg\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .lg\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .lg\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .lg\:-translate-y-300px {
    --tw-translate-y: -300px;
  }

  .lg\:-translate-y-230px {
    --tw-translate-y: -230px;
  }

  .lg\:-translate-y-180px {
    --tw-translate-y: -180px;
  }

  .lg\:-translate-y-147px {
    --tw-translate-y: -147px;
  }

  .lg\:-translate-y-123px {
    --tw-translate-y: -123px;
  }

  .lg\:-translate-y-100px {
    --tw-translate-y: -100px;
  }

  .lg\:-translate-y-80px {
    --tw-translate-y: -80px;
  }

  .lg\:-translate-y-60px {
    --tw-translate-y: -60px;
  }

  .lg\:-translate-y-50px {
    --tw-translate-y: -50px;
  }

  .lg\:-translate-y-40px {
    --tw-translate-y: -40px;
  }

  .lg\:-translate-y-35px {
    --tw-translate-y: -35px;
  }

  .lg\:-translate-y-34px-i {
    --tw-translate-y: 34px !important;
  }

  .lg\:-translate-y-30px-i {
    --tw-translate-y: 30px !important;
  }

  .lg\:-translate-y-24px-i {
    --tw-translate-y: 24px !important;
  }

  .lg\:-translate-y-20px-i {
    --tw-translate-y: 20px !important;
  }

  .lg\:-translate-y-15px-i {
    --tw-translate-y: 15px !important;
  }

  .lg\:-translate-y-13px-i {
    --tw-translate-y: 13px !important;
  }

  .lg\:-translate-y-12px-i {
    --tw-translate-y: 12px !important;
  }

  .lg\:-translate-y-10px-i {
    --tw-translate-y: 10px !important;
  }

  .lg\:-translate-y-8px-i {
    --tw-translate-y: 8px !important;
  }

  .lg\:-translate-y-6px-i {
    --tw-translate-y: 6px !important;
  }

  .lg\:-translate-y-5px-i {
    --tw-translate-y: 5px !important;
  }

  .lg\:-translate-y-3px-i {
    --tw-translate-y: 3px !important;
  }

  .lg\:-translate-y-0px-i {
    --tw-translate-y: 0px !important;
  }

  .lg\:-translate-y-34px {
    --tw-translate-y: -34px;
  }

  .lg\:-translate-y-30px {
    --tw-translate-y: -30px;
  }

  .lg\:-translate-y-24px {
    --tw-translate-y: -24px;
  }

  .lg\:-translate-y-20px {
    --tw-translate-y: -20px;
  }

  .lg\:-translate-y-15px {
    --tw-translate-y: -15px;
  }

  .lg\:-translate-y-13px {
    --tw-translate-y: -13px;
  }

  .lg\:-translate-y-12px {
    --tw-translate-y: -12px;
  }

  .lg\:-translate-y-10px {
    --tw-translate-y: -10px;
  }

  .lg\:-translate-y-8px {
    --tw-translate-y: -8px;
  }

  .lg\:-translate-y-6px {
    --tw-translate-y: -6px;
  }

  .lg\:-translate-y-5px {
    --tw-translate-y: -5px;
  }

  .lg\:-translate-y-3px {
    --tw-translate-y: -3px;
  }

  .lg\:-translate-y-menu {
    --tw-translate-y: -275px;
  }

  .lg\:-translate-y-px {
    --tw-translate-y: 1px !important;
  }

  .lg\:-translate-y-full {
    --tw-translate-y: -100%;
  }

  .lg\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .lg\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .lg\:translate-y-full {
    --tw-translate-y: 100%;
  }

  .lg\:hover\:translate-x-0:hover {
    --tw-translate-x: 0;
  }

  .lg\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }

  .lg\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }

  .lg\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }

  .lg\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .lg\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .lg\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .lg\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .lg\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .lg\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .lg\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .lg\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .lg\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .lg\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .lg\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .lg\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .lg\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .lg\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .lg\:hover\:translate-x-300px:hover {
    --tw-translate-x: 300px;
  }

  .lg\:hover\:translate-x-230px:hover {
    --tw-translate-x: 230px;
  }

  .lg\:hover\:translate-x-180px:hover {
    --tw-translate-x: 180px;
  }

  .lg\:hover\:translate-x-147px:hover {
    --tw-translate-x: 147px;
  }

  .lg\:hover\:translate-x-123px:hover {
    --tw-translate-x: 123px;
  }

  .lg\:hover\:translate-x-100px:hover {
    --tw-translate-x: 100px;
  }

  .lg\:hover\:translate-x-80px:hover {
    --tw-translate-x: 80px;
  }

  .lg\:hover\:translate-x-60px:hover {
    --tw-translate-x: 60px;
  }

  .lg\:hover\:translate-x-50px:hover {
    --tw-translate-x: 50px;
  }

  .lg\:hover\:translate-x-40px:hover {
    --tw-translate-x: 40px;
  }

  .lg\:hover\:translate-x-35px:hover {
    --tw-translate-x: 35px;
  }

  .lg\:hover\:translate-x-34px-i:hover {
    --tw-translate-x: 34px !important;
  }

  .lg\:hover\:translate-x-30px-i:hover {
    --tw-translate-x: 30px !important;
  }

  .lg\:hover\:translate-x-24px-i:hover {
    --tw-translate-x: 24px !important;
  }

  .lg\:hover\:translate-x-20px-i:hover {
    --tw-translate-x: 20px !important;
  }

  .lg\:hover\:translate-x-15px-i:hover {
    --tw-translate-x: 15px !important;
  }

  .lg\:hover\:translate-x-13px-i:hover {
    --tw-translate-x: 13px !important;
  }

  .lg\:hover\:translate-x-12px-i:hover {
    --tw-translate-x: 12px !important;
  }

  .lg\:hover\:translate-x-10px-i:hover {
    --tw-translate-x: 10px !important;
  }

  .lg\:hover\:translate-x-8px-i:hover {
    --tw-translate-x: 8px !important;
  }

  .lg\:hover\:translate-x-6px-i:hover {
    --tw-translate-x: 6px !important;
  }

  .lg\:hover\:translate-x-5px-i:hover {
    --tw-translate-x: 5px !important;
  }

  .lg\:hover\:translate-x-3px-i:hover {
    --tw-translate-x: 3px !important;
  }

  .lg\:hover\:translate-x-0px-i:hover {
    --tw-translate-x: 0px !important;
  }

  .lg\:hover\:translate-x-34px:hover {
    --tw-translate-x: 34px;
  }

  .lg\:hover\:translate-x-30px:hover {
    --tw-translate-x: 30px;
  }

  .lg\:hover\:translate-x-24px:hover {
    --tw-translate-x: 24px;
  }

  .lg\:hover\:translate-x-20px:hover {
    --tw-translate-x: 20px;
  }

  .lg\:hover\:translate-x-15px:hover {
    --tw-translate-x: 15px;
  }

  .lg\:hover\:translate-x-13px:hover {
    --tw-translate-x: 13px;
  }

  .lg\:hover\:translate-x-12px:hover {
    --tw-translate-x: 12px;
  }

  .lg\:hover\:translate-x-10px:hover {
    --tw-translate-x: 10px;
  }

  .lg\:hover\:translate-x-8px:hover {
    --tw-translate-x: 8px;
  }

  .lg\:hover\:translate-x-6px:hover {
    --tw-translate-x: 6px;
  }

  .lg\:hover\:translate-x-5px:hover {
    --tw-translate-x: 5px;
  }

  .lg\:hover\:translate-x-3px:hover {
    --tw-translate-x: 3px;
  }

  .lg\:hover\:translate-x-menu:hover {
    --tw-translate-x: 275px;
  }

  .lg\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px !important;
  }

  .lg\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }

  .lg\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }

  .lg\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }

  .lg\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .lg\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .lg\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .lg\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .lg\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .lg\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .lg\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .lg\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .lg\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .lg\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .lg\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .lg\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .lg\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .lg\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .lg\:hover\:-translate-x-300px:hover {
    --tw-translate-x: -300px;
  }

  .lg\:hover\:-translate-x-230px:hover {
    --tw-translate-x: -230px;
  }

  .lg\:hover\:-translate-x-180px:hover {
    --tw-translate-x: -180px;
  }

  .lg\:hover\:-translate-x-147px:hover {
    --tw-translate-x: -147px;
  }

  .lg\:hover\:-translate-x-123px:hover {
    --tw-translate-x: -123px;
  }

  .lg\:hover\:-translate-x-100px:hover {
    --tw-translate-x: -100px;
  }

  .lg\:hover\:-translate-x-80px:hover {
    --tw-translate-x: -80px;
  }

  .lg\:hover\:-translate-x-60px:hover {
    --tw-translate-x: -60px;
  }

  .lg\:hover\:-translate-x-50px:hover {
    --tw-translate-x: -50px;
  }

  .lg\:hover\:-translate-x-40px:hover {
    --tw-translate-x: -40px;
  }

  .lg\:hover\:-translate-x-35px:hover {
    --tw-translate-x: -35px;
  }

  .lg\:hover\:-translate-x-34px-i:hover {
    --tw-translate-x: 34px !important;
  }

  .lg\:hover\:-translate-x-30px-i:hover {
    --tw-translate-x: 30px !important;
  }

  .lg\:hover\:-translate-x-24px-i:hover {
    --tw-translate-x: 24px !important;
  }

  .lg\:hover\:-translate-x-20px-i:hover {
    --tw-translate-x: 20px !important;
  }

  .lg\:hover\:-translate-x-15px-i:hover {
    --tw-translate-x: 15px !important;
  }

  .lg\:hover\:-translate-x-13px-i:hover {
    --tw-translate-x: 13px !important;
  }

  .lg\:hover\:-translate-x-12px-i:hover {
    --tw-translate-x: 12px !important;
  }

  .lg\:hover\:-translate-x-10px-i:hover {
    --tw-translate-x: 10px !important;
  }

  .lg\:hover\:-translate-x-8px-i:hover {
    --tw-translate-x: 8px !important;
  }

  .lg\:hover\:-translate-x-6px-i:hover {
    --tw-translate-x: 6px !important;
  }

  .lg\:hover\:-translate-x-5px-i:hover {
    --tw-translate-x: 5px !important;
  }

  .lg\:hover\:-translate-x-3px-i:hover {
    --tw-translate-x: 3px !important;
  }

  .lg\:hover\:-translate-x-0px-i:hover {
    --tw-translate-x: 0px !important;
  }

  .lg\:hover\:-translate-x-34px:hover {
    --tw-translate-x: -34px;
  }

  .lg\:hover\:-translate-x-30px:hover {
    --tw-translate-x: -30px;
  }

  .lg\:hover\:-translate-x-24px:hover {
    --tw-translate-x: -24px;
  }

  .lg\:hover\:-translate-x-20px:hover {
    --tw-translate-x: -20px;
  }

  .lg\:hover\:-translate-x-15px:hover {
    --tw-translate-x: -15px;
  }

  .lg\:hover\:-translate-x-13px:hover {
    --tw-translate-x: -13px;
  }

  .lg\:hover\:-translate-x-12px:hover {
    --tw-translate-x: -12px;
  }

  .lg\:hover\:-translate-x-10px:hover {
    --tw-translate-x: -10px;
  }

  .lg\:hover\:-translate-x-8px:hover {
    --tw-translate-x: -8px;
  }

  .lg\:hover\:-translate-x-6px:hover {
    --tw-translate-x: -6px;
  }

  .lg\:hover\:-translate-x-5px:hover {
    --tw-translate-x: -5px;
  }

  .lg\:hover\:-translate-x-3px:hover {
    --tw-translate-x: -3px;
  }

  .lg\:hover\:-translate-x-menu:hover {
    --tw-translate-x: -275px;
  }

  .lg\:hover\:-translate-x-px:hover {
    --tw-translate-x: 1px !important;
  }

  .lg\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .lg\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .lg\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .lg\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .lg\:hover\:translate-y-0:hover {
    --tw-translate-y: 0;
  }

  .lg\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }

  .lg\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }

  .lg\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }

  .lg\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .lg\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .lg\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .lg\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .lg\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .lg\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .lg\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .lg\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .lg\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .lg\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .lg\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .lg\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .lg\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .lg\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .lg\:hover\:translate-y-300px:hover {
    --tw-translate-y: 300px;
  }

  .lg\:hover\:translate-y-230px:hover {
    --tw-translate-y: 230px;
  }

  .lg\:hover\:translate-y-180px:hover {
    --tw-translate-y: 180px;
  }

  .lg\:hover\:translate-y-147px:hover {
    --tw-translate-y: 147px;
  }

  .lg\:hover\:translate-y-123px:hover {
    --tw-translate-y: 123px;
  }

  .lg\:hover\:translate-y-100px:hover {
    --tw-translate-y: 100px;
  }

  .lg\:hover\:translate-y-80px:hover {
    --tw-translate-y: 80px;
  }

  .lg\:hover\:translate-y-60px:hover {
    --tw-translate-y: 60px;
  }

  .lg\:hover\:translate-y-50px:hover {
    --tw-translate-y: 50px;
  }

  .lg\:hover\:translate-y-40px:hover {
    --tw-translate-y: 40px;
  }

  .lg\:hover\:translate-y-35px:hover {
    --tw-translate-y: 35px;
  }

  .lg\:hover\:translate-y-34px-i:hover {
    --tw-translate-y: 34px !important;
  }

  .lg\:hover\:translate-y-30px-i:hover {
    --tw-translate-y: 30px !important;
  }

  .lg\:hover\:translate-y-24px-i:hover {
    --tw-translate-y: 24px !important;
  }

  .lg\:hover\:translate-y-20px-i:hover {
    --tw-translate-y: 20px !important;
  }

  .lg\:hover\:translate-y-15px-i:hover {
    --tw-translate-y: 15px !important;
  }

  .lg\:hover\:translate-y-13px-i:hover {
    --tw-translate-y: 13px !important;
  }

  .lg\:hover\:translate-y-12px-i:hover {
    --tw-translate-y: 12px !important;
  }

  .lg\:hover\:translate-y-10px-i:hover {
    --tw-translate-y: 10px !important;
  }

  .lg\:hover\:translate-y-8px-i:hover {
    --tw-translate-y: 8px !important;
  }

  .lg\:hover\:translate-y-6px-i:hover {
    --tw-translate-y: 6px !important;
  }

  .lg\:hover\:translate-y-5px-i:hover {
    --tw-translate-y: 5px !important;
  }

  .lg\:hover\:translate-y-3px-i:hover {
    --tw-translate-y: 3px !important;
  }

  .lg\:hover\:translate-y-0px-i:hover {
    --tw-translate-y: 0px !important;
  }

  .lg\:hover\:translate-y-34px:hover {
    --tw-translate-y: 34px;
  }

  .lg\:hover\:translate-y-30px:hover {
    --tw-translate-y: 30px;
  }

  .lg\:hover\:translate-y-24px:hover {
    --tw-translate-y: 24px;
  }

  .lg\:hover\:translate-y-20px:hover {
    --tw-translate-y: 20px;
  }

  .lg\:hover\:translate-y-15px:hover {
    --tw-translate-y: 15px;
  }

  .lg\:hover\:translate-y-13px:hover {
    --tw-translate-y: 13px;
  }

  .lg\:hover\:translate-y-12px:hover {
    --tw-translate-y: 12px;
  }

  .lg\:hover\:translate-y-10px:hover {
    --tw-translate-y: 10px;
  }

  .lg\:hover\:translate-y-8px:hover {
    --tw-translate-y: 8px;
  }

  .lg\:hover\:translate-y-6px:hover {
    --tw-translate-y: 6px;
  }

  .lg\:hover\:translate-y-5px:hover {
    --tw-translate-y: 5px;
  }

  .lg\:hover\:translate-y-3px:hover {
    --tw-translate-y: 3px;
  }

  .lg\:hover\:translate-y-menu:hover {
    --tw-translate-y: 275px;
  }

  .lg\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px !important;
  }

  .lg\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }

  .lg\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }

  .lg\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }

  .lg\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .lg\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .lg\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .lg\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .lg\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .lg\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .lg\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .lg\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .lg\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .lg\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .lg\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .lg\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .lg\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .lg\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .lg\:hover\:-translate-y-300px:hover {
    --tw-translate-y: -300px;
  }

  .lg\:hover\:-translate-y-230px:hover {
    --tw-translate-y: -230px;
  }

  .lg\:hover\:-translate-y-180px:hover {
    --tw-translate-y: -180px;
  }

  .lg\:hover\:-translate-y-147px:hover {
    --tw-translate-y: -147px;
  }

  .lg\:hover\:-translate-y-123px:hover {
    --tw-translate-y: -123px;
  }

  .lg\:hover\:-translate-y-100px:hover {
    --tw-translate-y: -100px;
  }

  .lg\:hover\:-translate-y-80px:hover {
    --tw-translate-y: -80px;
  }

  .lg\:hover\:-translate-y-60px:hover {
    --tw-translate-y: -60px;
  }

  .lg\:hover\:-translate-y-50px:hover {
    --tw-translate-y: -50px;
  }

  .lg\:hover\:-translate-y-40px:hover {
    --tw-translate-y: -40px;
  }

  .lg\:hover\:-translate-y-35px:hover {
    --tw-translate-y: -35px;
  }

  .lg\:hover\:-translate-y-34px-i:hover {
    --tw-translate-y: 34px !important;
  }

  .lg\:hover\:-translate-y-30px-i:hover {
    --tw-translate-y: 30px !important;
  }

  .lg\:hover\:-translate-y-24px-i:hover {
    --tw-translate-y: 24px !important;
  }

  .lg\:hover\:-translate-y-20px-i:hover {
    --tw-translate-y: 20px !important;
  }

  .lg\:hover\:-translate-y-15px-i:hover {
    --tw-translate-y: 15px !important;
  }

  .lg\:hover\:-translate-y-13px-i:hover {
    --tw-translate-y: 13px !important;
  }

  .lg\:hover\:-translate-y-12px-i:hover {
    --tw-translate-y: 12px !important;
  }

  .lg\:hover\:-translate-y-10px-i:hover {
    --tw-translate-y: 10px !important;
  }

  .lg\:hover\:-translate-y-8px-i:hover {
    --tw-translate-y: 8px !important;
  }

  .lg\:hover\:-translate-y-6px-i:hover {
    --tw-translate-y: 6px !important;
  }

  .lg\:hover\:-translate-y-5px-i:hover {
    --tw-translate-y: 5px !important;
  }

  .lg\:hover\:-translate-y-3px-i:hover {
    --tw-translate-y: 3px !important;
  }

  .lg\:hover\:-translate-y-0px-i:hover {
    --tw-translate-y: 0px !important;
  }

  .lg\:hover\:-translate-y-34px:hover {
    --tw-translate-y: -34px;
  }

  .lg\:hover\:-translate-y-30px:hover {
    --tw-translate-y: -30px;
  }

  .lg\:hover\:-translate-y-24px:hover {
    --tw-translate-y: -24px;
  }

  .lg\:hover\:-translate-y-20px:hover {
    --tw-translate-y: -20px;
  }

  .lg\:hover\:-translate-y-15px:hover {
    --tw-translate-y: -15px;
  }

  .lg\:hover\:-translate-y-13px:hover {
    --tw-translate-y: -13px;
  }

  .lg\:hover\:-translate-y-12px:hover {
    --tw-translate-y: -12px;
  }

  .lg\:hover\:-translate-y-10px:hover {
    --tw-translate-y: -10px;
  }

  .lg\:hover\:-translate-y-8px:hover {
    --tw-translate-y: -8px;
  }

  .lg\:hover\:-translate-y-6px:hover {
    --tw-translate-y: -6px;
  }

  .lg\:hover\:-translate-y-5px:hover {
    --tw-translate-y: -5px;
  }

  .lg\:hover\:-translate-y-3px:hover {
    --tw-translate-y: -3px;
  }

  .lg\:hover\:-translate-y-menu:hover {
    --tw-translate-y: -275px;
  }

  .lg\:hover\:-translate-y-px:hover {
    --tw-translate-y: 1px !important;
  }

  .lg\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .lg\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .lg\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .lg\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .lg\:focus\:translate-x-0:focus {
    --tw-translate-x: 0;
  }

  .lg\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }

  .lg\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }

  .lg\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }

  .lg\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .lg\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .lg\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .lg\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .lg\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .lg\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .lg\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .lg\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .lg\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .lg\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .lg\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .lg\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .lg\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .lg\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .lg\:focus\:translate-x-300px:focus {
    --tw-translate-x: 300px;
  }

  .lg\:focus\:translate-x-230px:focus {
    --tw-translate-x: 230px;
  }

  .lg\:focus\:translate-x-180px:focus {
    --tw-translate-x: 180px;
  }

  .lg\:focus\:translate-x-147px:focus {
    --tw-translate-x: 147px;
  }

  .lg\:focus\:translate-x-123px:focus {
    --tw-translate-x: 123px;
  }

  .lg\:focus\:translate-x-100px:focus {
    --tw-translate-x: 100px;
  }

  .lg\:focus\:translate-x-80px:focus {
    --tw-translate-x: 80px;
  }

  .lg\:focus\:translate-x-60px:focus {
    --tw-translate-x: 60px;
  }

  .lg\:focus\:translate-x-50px:focus {
    --tw-translate-x: 50px;
  }

  .lg\:focus\:translate-x-40px:focus {
    --tw-translate-x: 40px;
  }

  .lg\:focus\:translate-x-35px:focus {
    --tw-translate-x: 35px;
  }

  .lg\:focus\:translate-x-34px-i:focus {
    --tw-translate-x: 34px !important;
  }

  .lg\:focus\:translate-x-30px-i:focus {
    --tw-translate-x: 30px !important;
  }

  .lg\:focus\:translate-x-24px-i:focus {
    --tw-translate-x: 24px !important;
  }

  .lg\:focus\:translate-x-20px-i:focus {
    --tw-translate-x: 20px !important;
  }

  .lg\:focus\:translate-x-15px-i:focus {
    --tw-translate-x: 15px !important;
  }

  .lg\:focus\:translate-x-13px-i:focus {
    --tw-translate-x: 13px !important;
  }

  .lg\:focus\:translate-x-12px-i:focus {
    --tw-translate-x: 12px !important;
  }

  .lg\:focus\:translate-x-10px-i:focus {
    --tw-translate-x: 10px !important;
  }

  .lg\:focus\:translate-x-8px-i:focus {
    --tw-translate-x: 8px !important;
  }

  .lg\:focus\:translate-x-6px-i:focus {
    --tw-translate-x: 6px !important;
  }

  .lg\:focus\:translate-x-5px-i:focus {
    --tw-translate-x: 5px !important;
  }

  .lg\:focus\:translate-x-3px-i:focus {
    --tw-translate-x: 3px !important;
  }

  .lg\:focus\:translate-x-0px-i:focus {
    --tw-translate-x: 0px !important;
  }

  .lg\:focus\:translate-x-34px:focus {
    --tw-translate-x: 34px;
  }

  .lg\:focus\:translate-x-30px:focus {
    --tw-translate-x: 30px;
  }

  .lg\:focus\:translate-x-24px:focus {
    --tw-translate-x: 24px;
  }

  .lg\:focus\:translate-x-20px:focus {
    --tw-translate-x: 20px;
  }

  .lg\:focus\:translate-x-15px:focus {
    --tw-translate-x: 15px;
  }

  .lg\:focus\:translate-x-13px:focus {
    --tw-translate-x: 13px;
  }

  .lg\:focus\:translate-x-12px:focus {
    --tw-translate-x: 12px;
  }

  .lg\:focus\:translate-x-10px:focus {
    --tw-translate-x: 10px;
  }

  .lg\:focus\:translate-x-8px:focus {
    --tw-translate-x: 8px;
  }

  .lg\:focus\:translate-x-6px:focus {
    --tw-translate-x: 6px;
  }

  .lg\:focus\:translate-x-5px:focus {
    --tw-translate-x: 5px;
  }

  .lg\:focus\:translate-x-3px:focus {
    --tw-translate-x: 3px;
  }

  .lg\:focus\:translate-x-menu:focus {
    --tw-translate-x: 275px;
  }

  .lg\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px !important;
  }

  .lg\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }

  .lg\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }

  .lg\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }

  .lg\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .lg\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .lg\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .lg\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .lg\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .lg\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .lg\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .lg\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .lg\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .lg\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .lg\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .lg\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .lg\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .lg\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .lg\:focus\:-translate-x-300px:focus {
    --tw-translate-x: -300px;
  }

  .lg\:focus\:-translate-x-230px:focus {
    --tw-translate-x: -230px;
  }

  .lg\:focus\:-translate-x-180px:focus {
    --tw-translate-x: -180px;
  }

  .lg\:focus\:-translate-x-147px:focus {
    --tw-translate-x: -147px;
  }

  .lg\:focus\:-translate-x-123px:focus {
    --tw-translate-x: -123px;
  }

  .lg\:focus\:-translate-x-100px:focus {
    --tw-translate-x: -100px;
  }

  .lg\:focus\:-translate-x-80px:focus {
    --tw-translate-x: -80px;
  }

  .lg\:focus\:-translate-x-60px:focus {
    --tw-translate-x: -60px;
  }

  .lg\:focus\:-translate-x-50px:focus {
    --tw-translate-x: -50px;
  }

  .lg\:focus\:-translate-x-40px:focus {
    --tw-translate-x: -40px;
  }

  .lg\:focus\:-translate-x-35px:focus {
    --tw-translate-x: -35px;
  }

  .lg\:focus\:-translate-x-34px-i:focus {
    --tw-translate-x: 34px !important;
  }

  .lg\:focus\:-translate-x-30px-i:focus {
    --tw-translate-x: 30px !important;
  }

  .lg\:focus\:-translate-x-24px-i:focus {
    --tw-translate-x: 24px !important;
  }

  .lg\:focus\:-translate-x-20px-i:focus {
    --tw-translate-x: 20px !important;
  }

  .lg\:focus\:-translate-x-15px-i:focus {
    --tw-translate-x: 15px !important;
  }

  .lg\:focus\:-translate-x-13px-i:focus {
    --tw-translate-x: 13px !important;
  }

  .lg\:focus\:-translate-x-12px-i:focus {
    --tw-translate-x: 12px !important;
  }

  .lg\:focus\:-translate-x-10px-i:focus {
    --tw-translate-x: 10px !important;
  }

  .lg\:focus\:-translate-x-8px-i:focus {
    --tw-translate-x: 8px !important;
  }

  .lg\:focus\:-translate-x-6px-i:focus {
    --tw-translate-x: 6px !important;
  }

  .lg\:focus\:-translate-x-5px-i:focus {
    --tw-translate-x: 5px !important;
  }

  .lg\:focus\:-translate-x-3px-i:focus {
    --tw-translate-x: 3px !important;
  }

  .lg\:focus\:-translate-x-0px-i:focus {
    --tw-translate-x: 0px !important;
  }

  .lg\:focus\:-translate-x-34px:focus {
    --tw-translate-x: -34px;
  }

  .lg\:focus\:-translate-x-30px:focus {
    --tw-translate-x: -30px;
  }

  .lg\:focus\:-translate-x-24px:focus {
    --tw-translate-x: -24px;
  }

  .lg\:focus\:-translate-x-20px:focus {
    --tw-translate-x: -20px;
  }

  .lg\:focus\:-translate-x-15px:focus {
    --tw-translate-x: -15px;
  }

  .lg\:focus\:-translate-x-13px:focus {
    --tw-translate-x: -13px;
  }

  .lg\:focus\:-translate-x-12px:focus {
    --tw-translate-x: -12px;
  }

  .lg\:focus\:-translate-x-10px:focus {
    --tw-translate-x: -10px;
  }

  .lg\:focus\:-translate-x-8px:focus {
    --tw-translate-x: -8px;
  }

  .lg\:focus\:-translate-x-6px:focus {
    --tw-translate-x: -6px;
  }

  .lg\:focus\:-translate-x-5px:focus {
    --tw-translate-x: -5px;
  }

  .lg\:focus\:-translate-x-3px:focus {
    --tw-translate-x: -3px;
  }

  .lg\:focus\:-translate-x-menu:focus {
    --tw-translate-x: -275px;
  }

  .lg\:focus\:-translate-x-px:focus {
    --tw-translate-x: 1px !important;
  }

  .lg\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .lg\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .lg\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .lg\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .lg\:focus\:translate-y-0:focus {
    --tw-translate-y: 0;
  }

  .lg\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }

  .lg\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }

  .lg\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }

  .lg\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .lg\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .lg\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .lg\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .lg\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .lg\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .lg\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .lg\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .lg\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .lg\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .lg\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .lg\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .lg\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .lg\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .lg\:focus\:translate-y-300px:focus {
    --tw-translate-y: 300px;
  }

  .lg\:focus\:translate-y-230px:focus {
    --tw-translate-y: 230px;
  }

  .lg\:focus\:translate-y-180px:focus {
    --tw-translate-y: 180px;
  }

  .lg\:focus\:translate-y-147px:focus {
    --tw-translate-y: 147px;
  }

  .lg\:focus\:translate-y-123px:focus {
    --tw-translate-y: 123px;
  }

  .lg\:focus\:translate-y-100px:focus {
    --tw-translate-y: 100px;
  }

  .lg\:focus\:translate-y-80px:focus {
    --tw-translate-y: 80px;
  }

  .lg\:focus\:translate-y-60px:focus {
    --tw-translate-y: 60px;
  }

  .lg\:focus\:translate-y-50px:focus {
    --tw-translate-y: 50px;
  }

  .lg\:focus\:translate-y-40px:focus {
    --tw-translate-y: 40px;
  }

  .lg\:focus\:translate-y-35px:focus {
    --tw-translate-y: 35px;
  }

  .lg\:focus\:translate-y-34px-i:focus {
    --tw-translate-y: 34px !important;
  }

  .lg\:focus\:translate-y-30px-i:focus {
    --tw-translate-y: 30px !important;
  }

  .lg\:focus\:translate-y-24px-i:focus {
    --tw-translate-y: 24px !important;
  }

  .lg\:focus\:translate-y-20px-i:focus {
    --tw-translate-y: 20px !important;
  }

  .lg\:focus\:translate-y-15px-i:focus {
    --tw-translate-y: 15px !important;
  }

  .lg\:focus\:translate-y-13px-i:focus {
    --tw-translate-y: 13px !important;
  }

  .lg\:focus\:translate-y-12px-i:focus {
    --tw-translate-y: 12px !important;
  }

  .lg\:focus\:translate-y-10px-i:focus {
    --tw-translate-y: 10px !important;
  }

  .lg\:focus\:translate-y-8px-i:focus {
    --tw-translate-y: 8px !important;
  }

  .lg\:focus\:translate-y-6px-i:focus {
    --tw-translate-y: 6px !important;
  }

  .lg\:focus\:translate-y-5px-i:focus {
    --tw-translate-y: 5px !important;
  }

  .lg\:focus\:translate-y-3px-i:focus {
    --tw-translate-y: 3px !important;
  }

  .lg\:focus\:translate-y-0px-i:focus {
    --tw-translate-y: 0px !important;
  }

  .lg\:focus\:translate-y-34px:focus {
    --tw-translate-y: 34px;
  }

  .lg\:focus\:translate-y-30px:focus {
    --tw-translate-y: 30px;
  }

  .lg\:focus\:translate-y-24px:focus {
    --tw-translate-y: 24px;
  }

  .lg\:focus\:translate-y-20px:focus {
    --tw-translate-y: 20px;
  }

  .lg\:focus\:translate-y-15px:focus {
    --tw-translate-y: 15px;
  }

  .lg\:focus\:translate-y-13px:focus {
    --tw-translate-y: 13px;
  }

  .lg\:focus\:translate-y-12px:focus {
    --tw-translate-y: 12px;
  }

  .lg\:focus\:translate-y-10px:focus {
    --tw-translate-y: 10px;
  }

  .lg\:focus\:translate-y-8px:focus {
    --tw-translate-y: 8px;
  }

  .lg\:focus\:translate-y-6px:focus {
    --tw-translate-y: 6px;
  }

  .lg\:focus\:translate-y-5px:focus {
    --tw-translate-y: 5px;
  }

  .lg\:focus\:translate-y-3px:focus {
    --tw-translate-y: 3px;
  }

  .lg\:focus\:translate-y-menu:focus {
    --tw-translate-y: 275px;
  }

  .lg\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px !important;
  }

  .lg\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }

  .lg\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }

  .lg\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }

  .lg\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .lg\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .lg\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .lg\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .lg\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .lg\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .lg\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .lg\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .lg\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .lg\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .lg\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .lg\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .lg\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .lg\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .lg\:focus\:-translate-y-300px:focus {
    --tw-translate-y: -300px;
  }

  .lg\:focus\:-translate-y-230px:focus {
    --tw-translate-y: -230px;
  }

  .lg\:focus\:-translate-y-180px:focus {
    --tw-translate-y: -180px;
  }

  .lg\:focus\:-translate-y-147px:focus {
    --tw-translate-y: -147px;
  }

  .lg\:focus\:-translate-y-123px:focus {
    --tw-translate-y: -123px;
  }

  .lg\:focus\:-translate-y-100px:focus {
    --tw-translate-y: -100px;
  }

  .lg\:focus\:-translate-y-80px:focus {
    --tw-translate-y: -80px;
  }

  .lg\:focus\:-translate-y-60px:focus {
    --tw-translate-y: -60px;
  }

  .lg\:focus\:-translate-y-50px:focus {
    --tw-translate-y: -50px;
  }

  .lg\:focus\:-translate-y-40px:focus {
    --tw-translate-y: -40px;
  }

  .lg\:focus\:-translate-y-35px:focus {
    --tw-translate-y: -35px;
  }

  .lg\:focus\:-translate-y-34px-i:focus {
    --tw-translate-y: 34px !important;
  }

  .lg\:focus\:-translate-y-30px-i:focus {
    --tw-translate-y: 30px !important;
  }

  .lg\:focus\:-translate-y-24px-i:focus {
    --tw-translate-y: 24px !important;
  }

  .lg\:focus\:-translate-y-20px-i:focus {
    --tw-translate-y: 20px !important;
  }

  .lg\:focus\:-translate-y-15px-i:focus {
    --tw-translate-y: 15px !important;
  }

  .lg\:focus\:-translate-y-13px-i:focus {
    --tw-translate-y: 13px !important;
  }

  .lg\:focus\:-translate-y-12px-i:focus {
    --tw-translate-y: 12px !important;
  }

  .lg\:focus\:-translate-y-10px-i:focus {
    --tw-translate-y: 10px !important;
  }

  .lg\:focus\:-translate-y-8px-i:focus {
    --tw-translate-y: 8px !important;
  }

  .lg\:focus\:-translate-y-6px-i:focus {
    --tw-translate-y: 6px !important;
  }

  .lg\:focus\:-translate-y-5px-i:focus {
    --tw-translate-y: 5px !important;
  }

  .lg\:focus\:-translate-y-3px-i:focus {
    --tw-translate-y: 3px !important;
  }

  .lg\:focus\:-translate-y-0px-i:focus {
    --tw-translate-y: 0px !important;
  }

  .lg\:focus\:-translate-y-34px:focus {
    --tw-translate-y: -34px;
  }

  .lg\:focus\:-translate-y-30px:focus {
    --tw-translate-y: -30px;
  }

  .lg\:focus\:-translate-y-24px:focus {
    --tw-translate-y: -24px;
  }

  .lg\:focus\:-translate-y-20px:focus {
    --tw-translate-y: -20px;
  }

  .lg\:focus\:-translate-y-15px:focus {
    --tw-translate-y: -15px;
  }

  .lg\:focus\:-translate-y-13px:focus {
    --tw-translate-y: -13px;
  }

  .lg\:focus\:-translate-y-12px:focus {
    --tw-translate-y: -12px;
  }

  .lg\:focus\:-translate-y-10px:focus {
    --tw-translate-y: -10px;
  }

  .lg\:focus\:-translate-y-8px:focus {
    --tw-translate-y: -8px;
  }

  .lg\:focus\:-translate-y-6px:focus {
    --tw-translate-y: -6px;
  }

  .lg\:focus\:-translate-y-5px:focus {
    --tw-translate-y: -5px;
  }

  .lg\:focus\:-translate-y-3px:focus {
    --tw-translate-y: -3px;
  }

  .lg\:focus\:-translate-y-menu:focus {
    --tw-translate-y: -275px;
  }

  .lg\:focus\:-translate-y-px:focus {
    --tw-translate-y: 1px !important;
  }

  .lg\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .lg\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .lg\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .lg\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .lg\:rotate-0 {
    --tw-rotate: 0;
  }

  .lg\:rotate-1 {
    --tw-rotate: 1deg;
  }

  .lg\:rotate-2 {
    --tw-rotate: 2deg;
  }

  .lg\:rotate-3 {
    --tw-rotate: 3deg;
  }

  .lg\:rotate-6 {
    --tw-rotate: 6deg;
  }

  .lg\:rotate-12 {
    --tw-rotate: 12deg;
  }

  .lg\:rotate-45 {
    --tw-rotate: 45deg;
  }

  .lg\:rotate-90 {
    --tw-rotate: 90deg;
  }

  .lg\:rotate-180 {
    --tw-rotate: 180deg;
  }

  .lg\:-rotate-180 {
    --tw-rotate: -180deg;
  }

  .lg\:-rotate-90 {
    --tw-rotate: -90deg;
  }

  .lg\:-rotate-45 {
    --tw-rotate: -45deg;
  }

  .lg\:-rotate-12 {
    --tw-rotate: -12deg;
  }

  .lg\:-rotate-6 {
    --tw-rotate: -6deg;
  }

  .lg\:-rotate-3 {
    --tw-rotate: -3deg;
  }

  .lg\:-rotate-2 {
    --tw-rotate: -2deg;
  }

  .lg\:-rotate-1 {
    --tw-rotate: -1deg;
  }

  .lg\:hover\:rotate-0:hover {
    --tw-rotate: 0;
  }

  .lg\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .lg\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .lg\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .lg\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .lg\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .lg\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .lg\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .lg\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .lg\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .lg\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .lg\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .lg\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .lg\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .lg\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .lg\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .lg\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .lg\:focus\:rotate-0:focus {
    --tw-rotate: 0;
  }

  .lg\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .lg\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .lg\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .lg\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .lg\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .lg\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .lg\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .lg\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .lg\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .lg\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .lg\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .lg\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .lg\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .lg\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .lg\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .lg\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .lg\:skew-x-0 {
    --tw-skew-x: 0;
  }

  .lg\:skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .lg\:skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .lg\:skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .lg\:skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .lg\:skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .lg\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .lg\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .lg\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .lg\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .lg\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .lg\:skew-y-0 {
    --tw-skew-y: 0;
  }

  .lg\:skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .lg\:skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .lg\:skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .lg\:skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .lg\:skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .lg\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .lg\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .lg\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .lg\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .lg\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .lg\:hover\:skew-x-0:hover {
    --tw-skew-x: 0;
  }

  .lg\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .lg\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .lg\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .lg\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .lg\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .lg\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .lg\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .lg\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .lg\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .lg\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .lg\:hover\:skew-y-0:hover {
    --tw-skew-y: 0;
  }

  .lg\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .lg\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .lg\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .lg\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .lg\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .lg\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .lg\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .lg\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .lg\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .lg\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .lg\:focus\:skew-x-0:focus {
    --tw-skew-x: 0;
  }

  .lg\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .lg\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .lg\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .lg\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .lg\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .lg\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .lg\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .lg\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .lg\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .lg\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .lg\:focus\:skew-y-0:focus {
    --tw-skew-y: 0;
  }

  .lg\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .lg\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .lg\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .lg\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .lg\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .lg\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .lg\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .lg\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .lg\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .lg\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .lg\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .lg\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .lg\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .lg\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .lg\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .lg\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .lg\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .lg\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .lg\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .lg\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .lg\:scale-x-0 {
    --tw-scale-x: 0;
  }

  .lg\:scale-x-50 {
    --tw-scale-x: .5;
  }

  .lg\:scale-x-75 {
    --tw-scale-x: .75;
  }

  .lg\:scale-x-90 {
    --tw-scale-x: .9;
  }

  .lg\:scale-x-95 {
    --tw-scale-x: .95;
  }

  .lg\:scale-x-100 {
    --tw-scale-x: 1;
  }

  .lg\:scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .lg\:scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .lg\:scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .lg\:scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .lg\:scale-y-0 {
    --tw-scale-y: 0;
  }

  .lg\:scale-y-50 {
    --tw-scale-y: .5;
  }

  .lg\:scale-y-75 {
    --tw-scale-y: .75;
  }

  .lg\:scale-y-90 {
    --tw-scale-y: .9;
  }

  .lg\:scale-y-95 {
    --tw-scale-y: .95;
  }

  .lg\:scale-y-100 {
    --tw-scale-y: 1;
  }

  .lg\:scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .lg\:scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .lg\:scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .lg\:scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .lg\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .lg\:hover\:scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .lg\:hover\:scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .lg\:hover\:scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .lg\:hover\:scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .lg\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .lg\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .lg\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .lg\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .lg\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .lg\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .lg\:hover\:scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .lg\:hover\:scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .lg\:hover\:scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .lg\:hover\:scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .lg\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .lg\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .lg\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .lg\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .lg\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .lg\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .lg\:focus\:scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .lg\:focus\:scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .lg\:focus\:scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .lg\:focus\:scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .lg\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .lg\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .lg\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .lg\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .lg\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .lg\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .lg\:focus\:scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .lg\:focus\:scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .lg\:focus\:scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .lg\:focus\:scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .lg\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .lg\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .lg\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .lg\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .lg\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .lg\:animate-none {
    animation: none;
  }

  .lg\:animate-spin {
    animation: spin 1s linear infinite;
  }

  .lg\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .lg\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .lg\:animate-bounce {
    animation: bounce 1s infinite;
  }

  .lg\:cursor-auto {
    cursor: auto;
  }

  .lg\:cursor-default {
    cursor: default;
  }

  .lg\:cursor-pointer {
    cursor: pointer;
  }

  .lg\:cursor-wait {
    cursor: wait;
  }

  .lg\:cursor-text {
    cursor: text;
  }

  .lg\:cursor-move {
    cursor: move;
  }

  .lg\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .lg\:select-none {
    -webkit-user-select: none;
            user-select: none;
  }

  .lg\:select-text {
    -webkit-user-select: text;
            user-select: text;
  }

  .lg\:select-all {
    -webkit-user-select: all;
            user-select: all;
  }

  .lg\:select-auto {
    -webkit-user-select: auto;
            user-select: auto;
  }

  .lg\:resize-none {
    resize: none;
  }

  .lg\:resize-y {
    resize: vertical;
  }

  .lg\:resize-x {
    resize: horizontal;
  }

  .lg\:resize {
    resize: both;
  }

  .lg\:list-inside {
    list-style-position: inside;
  }

  .lg\:list-outside {
    list-style-position: outside;
  }

  .lg\:list-none {
    list-style-type: none;
  }

  .lg\:list-disc {
    list-style-type: disc;
  }

  .lg\:list-decimal {
    list-style-type: decimal;
  }

  .lg\:appearance-none {
    appearance: none;
  }

  .lg\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .lg\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .lg\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .lg\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .lg\:grid-flow-row {
    grid-auto-flow: row;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .lg\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .lg\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .lg\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .lg\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-none {
    grid-template-columns: none;
  }

  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-rows-none {
    grid-template-rows: none;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:place-content-center {
    place-content: center;
  }

  .lg\:place-content-start {
    place-content: start;
  }

  .lg\:place-content-end {
    place-content: end;
  }

  .lg\:place-content-between {
    place-content: space-between;
  }

  .lg\:place-content-around {
    place-content: space-around;
  }

  .lg\:place-content-evenly {
    place-content: space-evenly;
  }

  .lg\:place-content-stretch {
    place-content: stretch;
  }

  .lg\:place-items-start {
    place-items: start;
  }

  .lg\:place-items-end {
    place-items: end;
  }

  .lg\:place-items-center {
    place-items: center;
  }

  .lg\:place-items-stretch {
    place-items: stretch;
  }

  .lg\:content-center {
    align-content: center;
  }

  .lg\:content-start {
    align-content: flex-start;
  }

  .lg\:content-end {
    align-content: flex-end;
  }

  .lg\:content-between {
    align-content: space-between;
  }

  .lg\:content-around {
    align-content: space-around;
  }

  .lg\:content-evenly {
    align-content: space-evenly;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:items-baseline {
    align-items: baseline;
  }

  .lg\:items-stretch {
    align-items: stretch;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:justify-around {
    justify-content: space-around;
  }

  .lg\:justify-evenly {
    justify-content: space-evenly;
  }

  .lg\:justify-items-start {
    justify-items: start;
  }

  .lg\:justify-items-end {
    justify-items: end;
  }

  .lg\:justify-items-center {
    justify-items: center;
  }

  .lg\:justify-items-stretch {
    justify-items: stretch;
  }

  .lg\:gap-0 {
    gap: 0;
  }

  .lg\:gap-1 {
    gap: 0.25rem;
  }

  .lg\:gap-2 {
    gap: 0.5rem;
  }

  .lg\:gap-3 {
    gap: 0.75rem;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:gap-5 {
    gap: 1.25rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:gap-16 {
    gap: 4rem;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-24 {
    gap: 6rem;
  }

  .lg\:gap-32 {
    gap: 8rem;
  }

  .lg\:gap-40 {
    gap: 10rem;
  }

  .lg\:gap-48 {
    gap: 12rem;
  }

  .lg\:gap-56 {
    gap: 14rem;
  }

  .lg\:gap-64 {
    gap: 16rem;
  }

  .lg\:gap-300px {
    gap: 300px;
  }

  .lg\:gap-230px {
    gap: 230px;
  }

  .lg\:gap-180px {
    gap: 180px;
  }

  .lg\:gap-147px {
    gap: 147px;
  }

  .lg\:gap-123px {
    gap: 123px;
  }

  .lg\:gap-100px {
    gap: 100px;
  }

  .lg\:gap-80px {
    gap: 80px;
  }

  .lg\:gap-60px {
    gap: 60px;
  }

  .lg\:gap-50px {
    gap: 50px;
  }

  .lg\:gap-40px {
    gap: 40px;
  }

  .lg\:gap-35px {
    gap: 35px;
  }

  .lg\:gap-34px-i {
    gap: 34px !important;
  }

  .lg\:gap-30px-i {
    gap: 30px !important;
  }

  .lg\:gap-24px-i {
    gap: 24px !important;
  }

  .lg\:gap-20px-i {
    gap: 20px !important;
  }

  .lg\:gap-15px-i {
    gap: 15px !important;
  }

  .lg\:gap-13px-i {
    gap: 13px !important;
  }

  .lg\:gap-12px-i {
    gap: 12px !important;
  }

  .lg\:gap-10px-i {
    gap: 10px !important;
  }

  .lg\:gap-8px-i {
    gap: 8px !important;
  }

  .lg\:gap-6px-i {
    gap: 6px !important;
  }

  .lg\:gap-5px-i {
    gap: 5px !important;
  }

  .lg\:gap-3px-i {
    gap: 3px !important;
  }

  .lg\:gap-0px-i {
    gap: 0px !important;
  }

  .lg\:gap-34px {
    gap: 34px;
  }

  .lg\:gap-30px {
    gap: 30px;
  }

  .lg\:gap-24px {
    gap: 24px;
  }

  .lg\:gap-20px {
    gap: 20px;
  }

  .lg\:gap-15px {
    gap: 15px;
  }

  .lg\:gap-13px {
    gap: 13px;
  }

  .lg\:gap-12px {
    gap: 12px;
  }

  .lg\:gap-10px {
    gap: 10px;
  }

  .lg\:gap-8px {
    gap: 8px;
  }

  .lg\:gap-6px {
    gap: 6px;
  }

  .lg\:gap-5px {
    gap: 5px;
  }

  .lg\:gap-3px {
    gap: 3px;
  }

  .lg\:gap-menu {
    gap: 275px;
  }

  .lg\:gap-px {
    gap: 1px !important;
  }

  .lg\:gap-x-0 {
    column-gap: 0;
  }

  .lg\:gap-x-1 {
    column-gap: 0.25rem;
  }

  .lg\:gap-x-2 {
    column-gap: 0.5rem;
  }

  .lg\:gap-x-3 {
    column-gap: 0.75rem;
  }

  .lg\:gap-x-4 {
    column-gap: 1rem;
  }

  .lg\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .lg\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .lg\:gap-x-8 {
    column-gap: 2rem;
  }

  .lg\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .lg\:gap-x-12 {
    column-gap: 3rem;
  }

  .lg\:gap-x-16 {
    column-gap: 4rem;
  }

  .lg\:gap-x-20 {
    column-gap: 5rem;
  }

  .lg\:gap-x-24 {
    column-gap: 6rem;
  }

  .lg\:gap-x-32 {
    column-gap: 8rem;
  }

  .lg\:gap-x-40 {
    column-gap: 10rem;
  }

  .lg\:gap-x-48 {
    column-gap: 12rem;
  }

  .lg\:gap-x-56 {
    column-gap: 14rem;
  }

  .lg\:gap-x-64 {
    column-gap: 16rem;
  }

  .lg\:gap-x-300px {
    column-gap: 300px;
  }

  .lg\:gap-x-230px {
    column-gap: 230px;
  }

  .lg\:gap-x-180px {
    column-gap: 180px;
  }

  .lg\:gap-x-147px {
    column-gap: 147px;
  }

  .lg\:gap-x-123px {
    column-gap: 123px;
  }

  .lg\:gap-x-100px {
    column-gap: 100px;
  }

  .lg\:gap-x-80px {
    column-gap: 80px;
  }

  .lg\:gap-x-60px {
    column-gap: 60px;
  }

  .lg\:gap-x-50px {
    column-gap: 50px;
  }

  .lg\:gap-x-40px {
    column-gap: 40px;
  }

  .lg\:gap-x-35px {
    column-gap: 35px;
  }

  .lg\:gap-x-34px-i {
    column-gap: 34px !important;
  }

  .lg\:gap-x-30px-i {
    column-gap: 30px !important;
  }

  .lg\:gap-x-24px-i {
    column-gap: 24px !important;
  }

  .lg\:gap-x-20px-i {
    column-gap: 20px !important;
  }

  .lg\:gap-x-15px-i {
    column-gap: 15px !important;
  }

  .lg\:gap-x-13px-i {
    column-gap: 13px !important;
  }

  .lg\:gap-x-12px-i {
    column-gap: 12px !important;
  }

  .lg\:gap-x-10px-i {
    column-gap: 10px !important;
  }

  .lg\:gap-x-8px-i {
    column-gap: 8px !important;
  }

  .lg\:gap-x-6px-i {
    column-gap: 6px !important;
  }

  .lg\:gap-x-5px-i {
    column-gap: 5px !important;
  }

  .lg\:gap-x-3px-i {
    column-gap: 3px !important;
  }

  .lg\:gap-x-0px-i {
    column-gap: 0px !important;
  }

  .lg\:gap-x-34px {
    column-gap: 34px;
  }

  .lg\:gap-x-30px {
    column-gap: 30px;
  }

  .lg\:gap-x-24px {
    column-gap: 24px;
  }

  .lg\:gap-x-20px {
    column-gap: 20px;
  }

  .lg\:gap-x-15px {
    column-gap: 15px;
  }

  .lg\:gap-x-13px {
    column-gap: 13px;
  }

  .lg\:gap-x-12px {
    column-gap: 12px;
  }

  .lg\:gap-x-10px {
    column-gap: 10px;
  }

  .lg\:gap-x-8px {
    column-gap: 8px;
  }

  .lg\:gap-x-6px {
    column-gap: 6px;
  }

  .lg\:gap-x-5px {
    column-gap: 5px;
  }

  .lg\:gap-x-3px {
    column-gap: 3px;
  }

  .lg\:gap-x-menu {
    column-gap: 275px;
  }

  .lg\:gap-x-px {
    column-gap: 1px !important;
  }

  .lg\:gap-y-0 {
    row-gap: 0;
  }

  .lg\:gap-y-1 {
    row-gap: 0.25rem;
  }

  .lg\:gap-y-2 {
    row-gap: 0.5rem;
  }

  .lg\:gap-y-3 {
    row-gap: 0.75rem;
  }

  .lg\:gap-y-4 {
    row-gap: 1rem;
  }

  .lg\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .lg\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .lg\:gap-y-8 {
    row-gap: 2rem;
  }

  .lg\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .lg\:gap-y-12 {
    row-gap: 3rem;
  }

  .lg\:gap-y-16 {
    row-gap: 4rem;
  }

  .lg\:gap-y-20 {
    row-gap: 5rem;
  }

  .lg\:gap-y-24 {
    row-gap: 6rem;
  }

  .lg\:gap-y-32 {
    row-gap: 8rem;
  }

  .lg\:gap-y-40 {
    row-gap: 10rem;
  }

  .lg\:gap-y-48 {
    row-gap: 12rem;
  }

  .lg\:gap-y-56 {
    row-gap: 14rem;
  }

  .lg\:gap-y-64 {
    row-gap: 16rem;
  }

  .lg\:gap-y-300px {
    row-gap: 300px;
  }

  .lg\:gap-y-230px {
    row-gap: 230px;
  }

  .lg\:gap-y-180px {
    row-gap: 180px;
  }

  .lg\:gap-y-147px {
    row-gap: 147px;
  }

  .lg\:gap-y-123px {
    row-gap: 123px;
  }

  .lg\:gap-y-100px {
    row-gap: 100px;
  }

  .lg\:gap-y-80px {
    row-gap: 80px;
  }

  .lg\:gap-y-60px {
    row-gap: 60px;
  }

  .lg\:gap-y-50px {
    row-gap: 50px;
  }

  .lg\:gap-y-40px {
    row-gap: 40px;
  }

  .lg\:gap-y-35px {
    row-gap: 35px;
  }

  .lg\:gap-y-34px-i {
    row-gap: 34px !important;
  }

  .lg\:gap-y-30px-i {
    row-gap: 30px !important;
  }

  .lg\:gap-y-24px-i {
    row-gap: 24px !important;
  }

  .lg\:gap-y-20px-i {
    row-gap: 20px !important;
  }

  .lg\:gap-y-15px-i {
    row-gap: 15px !important;
  }

  .lg\:gap-y-13px-i {
    row-gap: 13px !important;
  }

  .lg\:gap-y-12px-i {
    row-gap: 12px !important;
  }

  .lg\:gap-y-10px-i {
    row-gap: 10px !important;
  }

  .lg\:gap-y-8px-i {
    row-gap: 8px !important;
  }

  .lg\:gap-y-6px-i {
    row-gap: 6px !important;
  }

  .lg\:gap-y-5px-i {
    row-gap: 5px !important;
  }

  .lg\:gap-y-3px-i {
    row-gap: 3px !important;
  }

  .lg\:gap-y-0px-i {
    row-gap: 0px !important;
  }

  .lg\:gap-y-34px {
    row-gap: 34px;
  }

  .lg\:gap-y-30px {
    row-gap: 30px;
  }

  .lg\:gap-y-24px {
    row-gap: 24px;
  }

  .lg\:gap-y-20px {
    row-gap: 20px;
  }

  .lg\:gap-y-15px {
    row-gap: 15px;
  }

  .lg\:gap-y-13px {
    row-gap: 13px;
  }

  .lg\:gap-y-12px {
    row-gap: 12px;
  }

  .lg\:gap-y-10px {
    row-gap: 10px;
  }

  .lg\:gap-y-8px {
    row-gap: 8px;
  }

  .lg\:gap-y-6px {
    row-gap: 6px;
  }

  .lg\:gap-y-5px {
    row-gap: 5px;
  }

  .lg\:gap-y-3px {
    row-gap: 3px;
  }

  .lg\:gap-y-menu {
    row-gap: 275px;
  }

  .lg\:gap-y-px {
    row-gap: 1px !important;
  }

  .lg\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(300px * var(--tw-space-x-reverse));
    margin-left: calc(300px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(230px * var(--tw-space-x-reverse));
    margin-left: calc(230px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(180px * var(--tw-space-x-reverse));
    margin-left: calc(180px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(147px * var(--tw-space-x-reverse));
    margin-left: calc(147px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(123px * var(--tw-space-x-reverse));
    margin-left: calc(123px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(100px * var(--tw-space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(80px * var(--tw-space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(60px * var(--tw-space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(50px * var(--tw-space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(40px * var(--tw-space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(35px * var(--tw-space-x-reverse));
    margin-left: calc(35px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px !important * var(--tw-space-x-reverse));
    margin-left: calc(34px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px !important * var(--tw-space-x-reverse));
    margin-left: calc(30px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px !important * var(--tw-space-x-reverse));
    margin-left: calc(24px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px !important * var(--tw-space-x-reverse));
    margin-left: calc(20px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px !important * var(--tw-space-x-reverse));
    margin-left: calc(15px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px !important * var(--tw-space-x-reverse));
    margin-left: calc(13px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px !important * var(--tw-space-x-reverse));
    margin-left: calc(12px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px !important * var(--tw-space-x-reverse));
    margin-left: calc(10px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px !important * var(--tw-space-x-reverse));
    margin-left: calc(8px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px !important * var(--tw-space-x-reverse));
    margin-left: calc(6px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px !important * var(--tw-space-x-reverse));
    margin-left: calc(5px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px !important * var(--tw-space-x-reverse));
    margin-left: calc(3px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px !important * var(--tw-space-x-reverse));
    margin-left: calc(0px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px * var(--tw-space-x-reverse));
    margin-left: calc(34px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px * var(--tw-space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px * var(--tw-space-x-reverse));
    margin-left: calc(24px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px * var(--tw-space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px * var(--tw-space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px * var(--tw-space-x-reverse));
    margin-left: calc(13px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px * var(--tw-space-x-reverse));
    margin-left: calc(12px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px * var(--tw-space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px * var(--tw-space-x-reverse));
    margin-left: calc(8px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px * var(--tw-space-x-reverse));
    margin-left: calc(6px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px * var(--tw-space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px * var(--tw-space-x-reverse));
    margin-left: calc(3px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(275px * var(--tw-space-x-reverse));
    margin-left: calc(275px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px !important * var(--tw-space-x-reverse));
    margin-left: calc(1px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-300px * var(--tw-space-x-reverse));
    margin-left: calc(-300px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-230px * var(--tw-space-x-reverse));
    margin-left: calc(-230px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-180px * var(--tw-space-x-reverse));
    margin-left: calc(-180px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-147px * var(--tw-space-x-reverse));
    margin-left: calc(-147px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-123px * var(--tw-space-x-reverse));
    margin-left: calc(-123px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-100px * var(--tw-space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-80px * var(--tw-space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-60px * var(--tw-space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-50px * var(--tw-space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-40px * var(--tw-space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-35px * var(--tw-space-x-reverse));
    margin-left: calc(-35px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px !important * var(--tw-space-x-reverse));
    margin-left: calc(34px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px !important * var(--tw-space-x-reverse));
    margin-left: calc(30px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px !important * var(--tw-space-x-reverse));
    margin-left: calc(24px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px !important * var(--tw-space-x-reverse));
    margin-left: calc(20px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px !important * var(--tw-space-x-reverse));
    margin-left: calc(15px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px !important * var(--tw-space-x-reverse));
    margin-left: calc(13px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px !important * var(--tw-space-x-reverse));
    margin-left: calc(12px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px !important * var(--tw-space-x-reverse));
    margin-left: calc(10px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px !important * var(--tw-space-x-reverse));
    margin-left: calc(8px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px !important * var(--tw-space-x-reverse));
    margin-left: calc(6px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px !important * var(--tw-space-x-reverse));
    margin-left: calc(5px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px !important * var(--tw-space-x-reverse));
    margin-left: calc(3px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px !important * var(--tw-space-x-reverse));
    margin-left: calc(0px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-34px * var(--tw-space-x-reverse));
    margin-left: calc(-34px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-30px * var(--tw-space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24px * var(--tw-space-x-reverse));
    margin-left: calc(-24px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20px * var(--tw-space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15px * var(--tw-space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13px * var(--tw-space-x-reverse));
    margin-left: calc(-13px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12px * var(--tw-space-x-reverse));
    margin-left: calc(-12px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10px * var(--tw-space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8px * var(--tw-space-x-reverse));
    margin-left: calc(-8px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6px * var(--tw-space-x-reverse));
    margin-left: calc(-6px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5px * var(--tw-space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3px * var(--tw-space-x-reverse));
    margin-left: calc(-3px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-275px * var(--tw-space-x-reverse));
    margin-left: calc(-275px * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px !important * var(--tw-space-x-reverse));
    margin-left: calc(1px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .lg\:space-y-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(300px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(300px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(230px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(230px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(180px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(180px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(147px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(147px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(123px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(123px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(100px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(80px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(60px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(50px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(40px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(35px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(35px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(275px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(275px * var(--tw-space-y-reverse));
  }

  .lg\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-300px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-300px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-230px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-230px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-180px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-180px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-147px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-147px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-123px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-123px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-100px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-80px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-60px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-50px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-40px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-35px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-35px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px !important * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-34px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-34px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-30px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-275px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-275px * var(--tw-space-y-reverse));
  }

  .lg\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px !important * var(--tw-space-y-reverse));
  }

  .lg\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .lg\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .lg\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-x-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .lg\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .lg\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .lg\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .lg\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .lg\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .lg\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .lg\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .lg\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .lg\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }

  .lg\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .lg\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-divide-opacity));
  }

  .lg\:divide-black40pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-divide-opacity));
  }

  .lg\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .lg\:divide-petroleum > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-divide-opacity));
  }

  .lg\:divide-petroleum70pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-divide-opacity));
  }

  .lg\:divide-petroleum30pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-divide-opacity));
  }

  .lg\:divide-disabled > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-divide-opacity));
  }

  .lg\:divide-petroleum10pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-divide-opacity));
  }

  .lg\:divide-petroleum5pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-divide-opacity));
  }

  .lg\:divide-darkGreen > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-divide-opacity));
  }

  .lg\:divide-ok > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-divide-opacity));
  }

  .lg\:divide-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-divide-opacity));
  }

  .lg\:divide-green10pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-divide-opacity));
  }

  .lg\:divide-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-divide-opacity));
  }

  .lg\:divide-darkOrange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-divide-opacity));
  }

  .lg\:divide-lightOrange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-divide-opacity));
  }

  .lg\:divide-error > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-divide-opacity));
  }

  .lg\:divide-yellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-divide-opacity));
  }

  .lg\:divide-darkYellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-divide-opacity));
  }

  .lg\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-divide-opacity));
  }

  .lg\:divide-lightGray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-divide-opacity));
  }

  .lg\:divide-borderGray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-divide-opacity));
  }

  .lg\:divide-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-divide-opacity));
  }

  .lg\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .lg\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }

  .lg\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }

  .lg\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }

  .lg\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .lg\:place-self-auto {
    place-self: auto;
  }

  .lg\:place-self-start {
    place-self: start;
  }

  .lg\:place-self-end {
    place-self: end;
  }

  .lg\:place-self-center {
    place-self: center;
  }

  .lg\:place-self-stretch {
    place-self: stretch;
  }

  .lg\:self-auto {
    align-self: auto;
  }

  .lg\:self-start {
    align-self: flex-start;
  }

  .lg\:self-end {
    align-self: flex-end;
  }

  .lg\:self-center {
    align-self: center;
  }

  .lg\:self-stretch {
    align-self: stretch;
  }

  .lg\:self-baseline {
    align-self: baseline;
  }

  .lg\:justify-self-auto {
    justify-self: auto;
  }

  .lg\:justify-self-start {
    justify-self: start;
  }

  .lg\:justify-self-end {
    justify-self: end;
  }

  .lg\:justify-self-center {
    justify-self: center;
  }

  .lg\:justify-self-stretch {
    justify-self: stretch;
  }

  .lg\:overflow-auto {
    overflow: auto;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:overflow-visible {
    overflow: visible;
  }

  .lg\:overflow-scroll {
    overflow: scroll;
  }

  .lg\:overflow-x-auto {
    overflow-x: auto;
  }

  .lg\:overflow-y-auto {
    overflow-y: auto;
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .lg\:overflow-x-visible {
    overflow-x: visible;
  }

  .lg\:overflow-y-visible {
    overflow-y: visible;
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .lg\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .lg\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .lg\:overscroll-none {
    overscroll-behavior: none;
  }

  .lg\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .lg\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .lg\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .lg\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .lg\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .lg\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .lg\:whitespace-normal {
    white-space: normal;
  }

  .lg\:whitespace-nowrap {
    white-space: nowrap;
  }

  .lg\:whitespace-pre {
    white-space: pre;
  }

  .lg\:whitespace-pre-line {
    white-space: pre-line;
  }

  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .lg\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .lg\:break-words {
    overflow-wrap: break-word;
  }

  .lg\:break-all {
    word-break: break-all;
  }

  .lg\:rounded-none {
    border-radius: 0;
  }

  .lg\:rounded-5px {
    border-radius: 5px;
  }

  .lg\:rounded-sm {
    border-radius: 0.125rem;
  }

  .lg\:rounded-default {
    border-radius: 0.25rem;
  }

  .lg\:rounded-md {
    border-radius: 0.375rem;
  }

  .lg\:rounded-lg {
    border-radius: 0.5rem;
  }

  .lg\:rounded-xl {
    border-radius: 0.75rem;
  }

  .lg\:rounded-2xl {
    border-radius: 1rem;
  }

  .lg\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .lg\:rounded-full {
    border-radius: 9999px;
  }

  .lg\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .lg\:rounded-t-5px {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .lg\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .lg\:rounded-t-default {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .lg\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .lg\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .lg\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .lg\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .lg\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .lg\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .lg\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .lg\:rounded-r-5px {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .lg\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .lg\:rounded-r-default {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .lg\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .lg\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .lg\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .lg\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .lg\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .lg\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-b-5px {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .lg\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:rounded-b-default {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .lg\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-l-5px {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .lg\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:rounded-l-default {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .lg\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .lg\:rounded-tl-5px {
    border-top-left-radius: 5px;
  }

  .lg\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .lg\:rounded-tl-default {
    border-top-left-radius: 0.25rem;
  }

  .lg\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .lg\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .lg\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .lg\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .lg\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .lg\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .lg\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .lg\:rounded-tr-5px {
    border-top-right-radius: 5px;
  }

  .lg\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .lg\:rounded-tr-default {
    border-top-right-radius: 0.25rem;
  }

  .lg\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .lg\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .lg\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .lg\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .lg\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .lg\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .lg\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .lg\:rounded-br-5px {
    border-bottom-right-radius: 5px;
  }

  .lg\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .lg\:rounded-br-default {
    border-bottom-right-radius: 0.25rem;
  }

  .lg\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .lg\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .lg\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .lg\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .lg\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-bl-5px {
    border-bottom-left-radius: 5px;
  }

  .lg\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .lg\:rounded-bl-default {
    border-bottom-left-radius: 0.25rem;
  }

  .lg\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .lg\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .lg\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .lg\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .lg\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .lg\:border-0 {
    border-width: 0;
  }

  .lg\:border-2 {
    border-width: 2px;
  }

  .lg\:border-4 {
    border-width: 4px;
  }

  .lg\:border-8 {
    border-width: 8px;
  }

  .lg\:border-default {
    border-width: 1px;
  }

  .lg\:border-t-0 {
    border-top-width: 0;
  }

  .lg\:border-t-2 {
    border-top-width: 2px;
  }

  .lg\:border-t-4 {
    border-top-width: 4px;
  }

  .lg\:border-t-8 {
    border-top-width: 8px;
  }

  .lg\:border-t-default {
    border-top-width: 1px;
  }

  .lg\:border-r-0 {
    border-right-width: 0;
  }

  .lg\:border-r-2 {
    border-right-width: 2px;
  }

  .lg\:border-r-4 {
    border-right-width: 4px;
  }

  .lg\:border-r-8 {
    border-right-width: 8px;
  }

  .lg\:border-r-default {
    border-right-width: 1px;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0;
  }

  .lg\:border-b-2 {
    border-bottom-width: 2px;
  }

  .lg\:border-b-4 {
    border-bottom-width: 4px;
  }

  .lg\:border-b-8 {
    border-bottom-width: 8px;
  }

  .lg\:border-b-default {
    border-bottom-width: 1px;
  }

  .lg\:border-l-0 {
    border-left-width: 0;
  }

  .lg\:border-l-2 {
    border-left-width: 2px;
  }

  .lg\:border-l-4 {
    border-left-width: 4px;
  }

  .lg\:border-l-8 {
    border-left-width: 8px;
  }

  .lg\:border-l-default {
    border-left-width: 1px;
  }

  .lg\:border-solid {
    border-style: solid;
  }

  .lg\:border-dashed {
    border-style: dashed;
  }

  .lg\:border-dotted {
    border-style: dotted;
  }

  .lg\:border-double {
    border-style: double;
  }

  .lg\:border-none {
    border-style: none;
  }

  .lg\:border-transparent {
    border-color: transparent;
  }

  .lg\:border-current {
    border-color: currentColor;
  }

  .lg\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .lg\:border-black40pct {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .lg\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:border-petroleum {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .lg\:border-petroleum70pct {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .lg\:border-petroleum30pct {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .lg\:border-disabled {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .lg\:border-petroleum10pct {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .lg\:border-petroleum5pct {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .lg\:border-darkGreen {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .lg\:border-ok {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .lg\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .lg\:border-green10pct {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .lg\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .lg\:border-darkOrange {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .lg\:border-lightOrange {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .lg\:border-error {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .lg\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .lg\:border-darkYellow {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .lg\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .lg\:border-lightGray {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .lg\:border-borderGray {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .lg\:border-default {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .lg\:hover\:border-transparent:hover {
    border-color: transparent;
  }

  .lg\:hover\:border-current:hover {
    border-color: currentColor;
  }

  .lg\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .lg\:hover\:border-black40pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .lg\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:hover\:border-petroleum:hover {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .lg\:hover\:border-petroleum70pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .lg\:hover\:border-petroleum30pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .lg\:hover\:border-disabled:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .lg\:hover\:border-petroleum10pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .lg\:hover\:border-petroleum5pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .lg\:hover\:border-darkGreen:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .lg\:hover\:border-ok:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .lg\:hover\:border-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .lg\:hover\:border-green10pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .lg\:hover\:border-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .lg\:hover\:border-darkOrange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .lg\:hover\:border-lightOrange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .lg\:hover\:border-error:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .lg\:hover\:border-yellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .lg\:hover\:border-darkYellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .lg\:hover\:border-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .lg\:hover\:border-lightGray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .lg\:hover\:border-borderGray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .lg\:hover\:border-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .lg\:focus\:border-transparent:focus {
    border-color: transparent;
  }

  .lg\:focus\:border-current:focus {
    border-color: currentColor;
  }

  .lg\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .lg\:focus\:border-black40pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .lg\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .lg\:focus\:border-petroleum:focus {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .lg\:focus\:border-petroleum70pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .lg\:focus\:border-petroleum30pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .lg\:focus\:border-disabled:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .lg\:focus\:border-petroleum10pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .lg\:focus\:border-petroleum5pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .lg\:focus\:border-darkGreen:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .lg\:focus\:border-ok:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .lg\:focus\:border-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .lg\:focus\:border-green10pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .lg\:focus\:border-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .lg\:focus\:border-darkOrange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .lg\:focus\:border-lightOrange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .lg\:focus\:border-error:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .lg\:focus\:border-yellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .lg\:focus\:border-darkYellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .lg\:focus\:border-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .lg\:focus\:border-lightGray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .lg\:focus\:border-borderGray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .lg\:focus\:border-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .lg\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .lg\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .lg\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .lg\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .lg\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .lg\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .lg\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }

  .lg\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }

  .lg\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }

  .lg\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .lg\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .lg\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }

  .lg\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }

  .lg\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }

  .lg\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .lg\:bg-transparent {
    background-color: transparent;
  }

  .lg\:bg-current {
    background-color: currentColor;
  }

  .lg\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .lg\:bg-black40pct {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:bg-petroleum {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .lg\:bg-petroleum70pct {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .lg\:bg-petroleum30pct {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .lg\:bg-disabled {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .lg\:bg-petroleum10pct {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .lg\:bg-petroleum5pct {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .lg\:bg-darkGreen {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .lg\:bg-ok {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .lg\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .lg\:bg-green10pct {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .lg\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .lg\:bg-darkOrange {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .lg\:bg-lightOrange {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .lg\:bg-error {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .lg\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .lg\:bg-darkYellow {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .lg\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .lg\:bg-lightGray {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .lg\:bg-borderGray {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-transparent:hover {
    background-color: transparent;
  }

  .lg\:hover\:bg-current:hover {
    background-color: currentColor;
  }

  .lg\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-black40pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-petroleum:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-petroleum70pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-petroleum30pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-disabled:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-petroleum10pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-petroleum5pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-darkGreen:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-ok:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-green10pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-darkOrange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-lightOrange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-error:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-yellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-darkYellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-lightGray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .lg\:hover\:bg-borderGray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-transparent:focus {
    background-color: transparent;
  }

  .lg\:focus\:bg-current:focus {
    background-color: currentColor;
  }

  .lg\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-black40pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-petroleum:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-petroleum70pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-petroleum30pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-disabled:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-petroleum10pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-petroleum5pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-darkGreen:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-ok:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-green10pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-darkOrange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-lightOrange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-error:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-yellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-darkYellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-lightGray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .lg\:focus\:bg-borderGray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .lg\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .lg\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .lg\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .lg\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .lg\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .lg\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .lg\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }

  .lg\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }

  .lg\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }

  .lg\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .lg\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .lg\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }

  .lg\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }

  .lg\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }

  .lg\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .lg\:bg-none {
    background-image: none;
  }

  .lg\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .lg\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .lg\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .lg\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .lg\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .lg\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .lg\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .lg\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .lg\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:from-black {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .lg\:from-black40pct {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .lg\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:from-petroleum {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .lg\:from-petroleum70pct {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .lg\:from-petroleum30pct {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .lg\:from-disabled {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .lg\:from-petroleum10pct {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .lg\:from-petroleum5pct {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .lg\:from-darkGreen {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .lg\:from-ok {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .lg\:from-green {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .lg\:from-green10pct {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .lg\:from-orange {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .lg\:from-darkOrange {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .lg\:from-lightOrange {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .lg\:from-error {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .lg\:from-yellow {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .lg\:from-darkYellow {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .lg\:from-gray {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .lg\:from-lightGray {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .lg\:from-borderGray {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .lg\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:from-black:hover {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .lg\:hover\:from-black40pct:hover {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .lg\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:from-petroleum:hover {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .lg\:hover\:from-petroleum70pct:hover {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .lg\:hover\:from-petroleum30pct:hover {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .lg\:hover\:from-disabled:hover {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .lg\:hover\:from-petroleum10pct:hover {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .lg\:hover\:from-petroleum5pct:hover {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .lg\:hover\:from-darkGreen:hover {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .lg\:hover\:from-ok:hover {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .lg\:hover\:from-green:hover {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .lg\:hover\:from-green10pct:hover {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .lg\:hover\:from-orange:hover {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .lg\:hover\:from-darkOrange:hover {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .lg\:hover\:from-lightOrange:hover {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .lg\:hover\:from-error:hover {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .lg\:hover\:from-yellow:hover {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .lg\:hover\:from-darkYellow:hover {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .lg\:hover\:from-gray:hover {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .lg\:hover\:from-lightGray:hover {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .lg\:hover\:from-borderGray:hover {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .lg\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:from-black:focus {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .lg\:focus\:from-black40pct:focus {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .lg\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:from-petroleum:focus {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .lg\:focus\:from-petroleum70pct:focus {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .lg\:focus\:from-petroleum30pct:focus {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .lg\:focus\:from-disabled:focus {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .lg\:focus\:from-petroleum10pct:focus {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .lg\:focus\:from-petroleum5pct:focus {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .lg\:focus\:from-darkGreen:focus {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .lg\:focus\:from-ok:focus {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .lg\:focus\:from-green:focus {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .lg\:focus\:from-green10pct:focus {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .lg\:focus\:from-orange:focus {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .lg\:focus\:from-darkOrange:focus {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .lg\:focus\:from-lightOrange:focus {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .lg\:focus\:from-error:focus {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .lg\:focus\:from-yellow:focus {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .lg\:focus\:from-darkYellow:focus {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .lg\:focus\:from-gray:focus {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .lg\:focus\:from-lightGray:focus {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .lg\:focus\:from-borderGray:focus {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .lg\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .lg\:via-black40pct {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .lg\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:via-petroleum {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .lg\:via-petroleum70pct {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .lg\:via-petroleum30pct {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .lg\:via-disabled {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .lg\:via-petroleum10pct {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .lg\:via-petroleum5pct {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .lg\:via-darkGreen {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .lg\:via-ok {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .lg\:via-green {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .lg\:via-green10pct {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .lg\:via-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .lg\:via-darkOrange {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .lg\:via-lightOrange {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .lg\:via-error {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .lg\:via-yellow {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .lg\:via-darkYellow {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .lg\:via-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .lg\:via-lightGray {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .lg\:via-borderGray {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .lg\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .lg\:hover\:via-black40pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .lg\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:hover\:via-petroleum:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .lg\:hover\:via-petroleum70pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .lg\:hover\:via-petroleum30pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .lg\:hover\:via-disabled:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .lg\:hover\:via-petroleum10pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .lg\:hover\:via-petroleum5pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .lg\:hover\:via-darkGreen:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .lg\:hover\:via-ok:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .lg\:hover\:via-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .lg\:hover\:via-green10pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .lg\:hover\:via-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .lg\:hover\:via-darkOrange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .lg\:hover\:via-lightOrange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .lg\:hover\:via-error:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .lg\:hover\:via-yellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .lg\:hover\:via-darkYellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .lg\:hover\:via-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .lg\:hover\:via-lightGray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .lg\:hover\:via-borderGray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .lg\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .lg\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .lg\:focus\:via-black40pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .lg\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .lg\:focus\:via-petroleum:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .lg\:focus\:via-petroleum70pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .lg\:focus\:via-petroleum30pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .lg\:focus\:via-disabled:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .lg\:focus\:via-petroleum10pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .lg\:focus\:via-petroleum5pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .lg\:focus\:via-darkGreen:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .lg\:focus\:via-ok:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .lg\:focus\:via-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .lg\:focus\:via-green10pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .lg\:focus\:via-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .lg\:focus\:via-darkOrange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .lg\:focus\:via-lightOrange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .lg\:focus\:via-error:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .lg\:focus\:via-yellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .lg\:focus\:via-darkYellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .lg\:focus\:via-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .lg\:focus\:via-lightGray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .lg\:focus\:via-borderGray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .lg\:to-transparent {
    --tw-gradient-to: transparent;
  }

  .lg\:to-current {
    --tw-gradient-to: currentColor;
  }

  .lg\:to-black {
    --tw-gradient-to: #1b1b1a;
  }

  .lg\:to-black40pct {
    --tw-gradient-to: #a4a4a3;
  }

  .lg\:to-white {
    --tw-gradient-to: #fff;
  }

  .lg\:to-petroleum {
    --tw-gradient-to: #0e4744;
  }

  .lg\:to-petroleum70pct {
    --tw-gradient-to: #567e7c;
  }

  .lg\:to-petroleum30pct {
    --tw-gradient-to: #b7c8c7;
  }

  .lg\:to-disabled {
    --tw-gradient-to: #b7c8c7;
  }

  .lg\:to-petroleum10pct {
    --tw-gradient-to: #e7edec;
  }

  .lg\:to-petroleum5pct {
    --tw-gradient-to: #f3f6f6;
  }

  .lg\:to-darkGreen {
    --tw-gradient-to: #267b38;
  }

  .lg\:to-ok {
    --tw-gradient-to: #267b38;
  }

  .lg\:to-green {
    --tw-gradient-to: #2ea149;
  }

  .lg\:to-green10pct {
    --tw-gradient-to: #eaf6ed;
  }

  .lg\:to-orange {
    --tw-gradient-to: #ed7100;
  }

  .lg\:to-darkOrange {
    --tw-gradient-to: #aa3636;
  }

  .lg\:to-lightOrange {
    --tw-gradient-to: #fbe3cc;
  }

  .lg\:to-error {
    --tw-gradient-to: #aa3636;
  }

  .lg\:to-yellow {
    --tw-gradient-to: #e9c258;
  }

  .lg\:to-darkYellow {
    --tw-gradient-to: #c3b091;
  }

  .lg\:to-gray {
    --tw-gradient-to: #949493;
  }

  .lg\:to-lightGray {
    --tw-gradient-to: #e5e5e5;
  }

  .lg\:to-borderGray {
    --tw-gradient-to: #e6e6e6;
  }

  .lg\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .lg\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .lg\:hover\:to-black:hover {
    --tw-gradient-to: #1b1b1a;
  }

  .lg\:hover\:to-black40pct:hover {
    --tw-gradient-to: #a4a4a3;
  }

  .lg\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }

  .lg\:hover\:to-petroleum:hover {
    --tw-gradient-to: #0e4744;
  }

  .lg\:hover\:to-petroleum70pct:hover {
    --tw-gradient-to: #567e7c;
  }

  .lg\:hover\:to-petroleum30pct:hover {
    --tw-gradient-to: #b7c8c7;
  }

  .lg\:hover\:to-disabled:hover {
    --tw-gradient-to: #b7c8c7;
  }

  .lg\:hover\:to-petroleum10pct:hover {
    --tw-gradient-to: #e7edec;
  }

  .lg\:hover\:to-petroleum5pct:hover {
    --tw-gradient-to: #f3f6f6;
  }

  .lg\:hover\:to-darkGreen:hover {
    --tw-gradient-to: #267b38;
  }

  .lg\:hover\:to-ok:hover {
    --tw-gradient-to: #267b38;
  }

  .lg\:hover\:to-green:hover {
    --tw-gradient-to: #2ea149;
  }

  .lg\:hover\:to-green10pct:hover {
    --tw-gradient-to: #eaf6ed;
  }

  .lg\:hover\:to-orange:hover {
    --tw-gradient-to: #ed7100;
  }

  .lg\:hover\:to-darkOrange:hover {
    --tw-gradient-to: #aa3636;
  }

  .lg\:hover\:to-lightOrange:hover {
    --tw-gradient-to: #fbe3cc;
  }

  .lg\:hover\:to-error:hover {
    --tw-gradient-to: #aa3636;
  }

  .lg\:hover\:to-yellow:hover {
    --tw-gradient-to: #e9c258;
  }

  .lg\:hover\:to-darkYellow:hover {
    --tw-gradient-to: #c3b091;
  }

  .lg\:hover\:to-gray:hover {
    --tw-gradient-to: #949493;
  }

  .lg\:hover\:to-lightGray:hover {
    --tw-gradient-to: #e5e5e5;
  }

  .lg\:hover\:to-borderGray:hover {
    --tw-gradient-to: #e6e6e6;
  }

  .lg\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .lg\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .lg\:focus\:to-black:focus {
    --tw-gradient-to: #1b1b1a;
  }

  .lg\:focus\:to-black40pct:focus {
    --tw-gradient-to: #a4a4a3;
  }

  .lg\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }

  .lg\:focus\:to-petroleum:focus {
    --tw-gradient-to: #0e4744;
  }

  .lg\:focus\:to-petroleum70pct:focus {
    --tw-gradient-to: #567e7c;
  }

  .lg\:focus\:to-petroleum30pct:focus {
    --tw-gradient-to: #b7c8c7;
  }

  .lg\:focus\:to-disabled:focus {
    --tw-gradient-to: #b7c8c7;
  }

  .lg\:focus\:to-petroleum10pct:focus {
    --tw-gradient-to: #e7edec;
  }

  .lg\:focus\:to-petroleum5pct:focus {
    --tw-gradient-to: #f3f6f6;
  }

  .lg\:focus\:to-darkGreen:focus {
    --tw-gradient-to: #267b38;
  }

  .lg\:focus\:to-ok:focus {
    --tw-gradient-to: #267b38;
  }

  .lg\:focus\:to-green:focus {
    --tw-gradient-to: #2ea149;
  }

  .lg\:focus\:to-green10pct:focus {
    --tw-gradient-to: #eaf6ed;
  }

  .lg\:focus\:to-orange:focus {
    --tw-gradient-to: #ed7100;
  }

  .lg\:focus\:to-darkOrange:focus {
    --tw-gradient-to: #aa3636;
  }

  .lg\:focus\:to-lightOrange:focus {
    --tw-gradient-to: #fbe3cc;
  }

  .lg\:focus\:to-error:focus {
    --tw-gradient-to: #aa3636;
  }

  .lg\:focus\:to-yellow:focus {
    --tw-gradient-to: #e9c258;
  }

  .lg\:focus\:to-darkYellow:focus {
    --tw-gradient-to: #c3b091;
  }

  .lg\:focus\:to-gray:focus {
    --tw-gradient-to: #949493;
  }

  .lg\:focus\:to-lightGray:focus {
    --tw-gradient-to: #e5e5e5;
  }

  .lg\:focus\:to-borderGray:focus {
    --tw-gradient-to: #e6e6e6;
  }

  .lg\:bg-auto {
    background-size: auto;
  }

  .lg\:bg-cover {
    background-size: cover;
  }

  .lg\:bg-contain {
    background-size: contain;
  }

  .lg\:bg-fixed {
    background-attachment: fixed;
  }

  .lg\:bg-local {
    background-attachment: local;
  }

  .lg\:bg-scroll {
    background-attachment: scroll;
  }

  .lg\:bg-clip-border {
    background-clip: border-box;
  }

  .lg\:bg-clip-padding {
    background-clip: padding-box;
  }

  .lg\:bg-clip-content {
    background-clip: content-box;
  }

  .lg\:bg-clip-text {
    background-clip: text;
  }

  .lg\:bg-bottom {
    background-position: bottom;
  }

  .lg\:bg-center {
    background-position: center;
  }

  .lg\:bg-left {
    background-position: left;
  }

  .lg\:bg-left-bottom {
    background-position: left bottom;
  }

  .lg\:bg-left-top {
    background-position: left top;
  }

  .lg\:bg-right {
    background-position: right;
  }

  .lg\:bg-right-bottom {
    background-position: right bottom;
  }

  .lg\:bg-right-top {
    background-position: right top;
  }

  .lg\:bg-top {
    background-position: top;
  }

  .lg\:bg-repeat {
    background-repeat: repeat;
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .lg\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .lg\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .lg\:bg-repeat-round {
    background-repeat: round;
  }

  .lg\:bg-repeat-space {
    background-repeat: space;
  }

  .lg\:fill-current {
    fill: currentColor;
  }

  .lg\:stroke-current {
    stroke: currentColor;
  }

  .lg\:stroke-0 {
    stroke-width: 0;
  }

  .lg\:stroke-1 {
    stroke-width: 1;
  }

  .lg\:stroke-2 {
    stroke-width: 2;
  }

  .lg\:object-contain {
    object-fit: contain;
  }

  .lg\:object-cover {
    object-fit: cover;
  }

  .lg\:object-fill {
    object-fit: fill;
  }

  .lg\:object-none {
    object-fit: none;
  }

  .lg\:object-scale-down {
    object-fit: scale-down;
  }

  .lg\:object-bottom {
    object-position: bottom;
  }

  .lg\:object-center {
    object-position: center;
  }

  .lg\:object-left {
    object-position: left;
  }

  .lg\:object-left-bottom {
    object-position: left bottom;
  }

  .lg\:object-left-top {
    object-position: left top;
  }

  .lg\:object-right {
    object-position: right;
  }

  .lg\:object-right-bottom {
    object-position: right bottom;
  }

  .lg\:object-right-top {
    object-position: right top;
  }

  .lg\:object-top {
    object-position: top;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-1 {
    padding: 0.25rem;
  }

  .lg\:p-2 {
    padding: 0.5rem;
  }

  .lg\:p-3 {
    padding: 0.75rem;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-5 {
    padding: 1.25rem;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:p-16 {
    padding: 4rem;
  }

  .lg\:p-20 {
    padding: 5rem;
  }

  .lg\:p-24 {
    padding: 6rem;
  }

  .lg\:p-32 {
    padding: 8rem;
  }

  .lg\:p-40 {
    padding: 10rem;
  }

  .lg\:p-48 {
    padding: 12rem;
  }

  .lg\:p-56 {
    padding: 14rem;
  }

  .lg\:p-64 {
    padding: 16rem;
  }

  .lg\:p-300px {
    padding: 300px;
  }

  .lg\:p-230px {
    padding: 230px;
  }

  .lg\:p-180px {
    padding: 180px;
  }

  .lg\:p-147px {
    padding: 147px;
  }

  .lg\:p-123px {
    padding: 123px;
  }

  .lg\:p-100px {
    padding: 100px;
  }

  .lg\:p-80px {
    padding: 80px;
  }

  .lg\:p-60px {
    padding: 60px;
  }

  .lg\:p-50px {
    padding: 50px;
  }

  .lg\:p-40px {
    padding: 40px;
  }

  .lg\:p-35px {
    padding: 35px;
  }

  .lg\:p-34px-i {
    padding: 34px !important;
  }

  .lg\:p-30px-i {
    padding: 30px !important;
  }

  .lg\:p-24px-i {
    padding: 24px !important;
  }

  .lg\:p-20px-i {
    padding: 20px !important;
  }

  .lg\:p-15px-i {
    padding: 15px !important;
  }

  .lg\:p-13px-i {
    padding: 13px !important;
  }

  .lg\:p-12px-i {
    padding: 12px !important;
  }

  .lg\:p-10px-i {
    padding: 10px !important;
  }

  .lg\:p-8px-i {
    padding: 8px !important;
  }

  .lg\:p-6px-i {
    padding: 6px !important;
  }

  .lg\:p-5px-i {
    padding: 5px !important;
  }

  .lg\:p-3px-i {
    padding: 3px !important;
  }

  .lg\:p-0px-i {
    padding: 0px !important;
  }

  .lg\:p-34px {
    padding: 34px;
  }

  .lg\:p-30px {
    padding: 30px;
  }

  .lg\:p-24px {
    padding: 24px;
  }

  .lg\:p-20px {
    padding: 20px;
  }

  .lg\:p-15px {
    padding: 15px;
  }

  .lg\:p-13px {
    padding: 13px;
  }

  .lg\:p-12px {
    padding: 12px;
  }

  .lg\:p-10px {
    padding: 10px;
  }

  .lg\:p-8px {
    padding: 8px;
  }

  .lg\:p-6px {
    padding: 6px;
  }

  .lg\:p-5px {
    padding: 5px;
  }

  .lg\:p-3px {
    padding: 3px;
  }

  .lg\:p-menu {
    padding: 275px;
  }

  .lg\:p-px {
    padding: 1px !important;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .lg\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .lg\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .lg\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .lg\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .lg\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .lg\:px-300px {
    padding-left: 300px;
    padding-right: 300px;
  }

  .lg\:px-230px {
    padding-left: 230px;
    padding-right: 230px;
  }

  .lg\:px-180px {
    padding-left: 180px;
    padding-right: 180px;
  }

  .lg\:px-147px {
    padding-left: 147px;
    padding-right: 147px;
  }

  .lg\:px-123px {
    padding-left: 123px;
    padding-right: 123px;
  }

  .lg\:px-100px {
    padding-left: 100px;
    padding-right: 100px;
  }

  .lg\:px-80px {
    padding-left: 80px;
    padding-right: 80px;
  }

  .lg\:px-60px {
    padding-left: 60px;
    padding-right: 60px;
  }

  .lg\:px-50px {
    padding-left: 50px;
    padding-right: 50px;
  }

  .lg\:px-40px {
    padding-left: 40px;
    padding-right: 40px;
  }

  .lg\:px-35px {
    padding-left: 35px;
    padding-right: 35px;
  }

  .lg\:px-34px-i {
    padding-left: 34px !important;
    padding-right: 34px !important;
  }

  .lg\:px-30px-i {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .lg\:px-24px-i {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .lg\:px-20px-i {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .lg\:px-15px-i {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .lg\:px-13px-i {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }

  .lg\:px-12px-i {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .lg\:px-10px-i {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .lg\:px-8px-i {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .lg\:px-6px-i {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .lg\:px-5px-i {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .lg\:px-3px-i {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .lg\:px-0px-i {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .lg\:px-34px {
    padding-left: 34px;
    padding-right: 34px;
  }

  .lg\:px-30px {
    padding-left: 30px;
    padding-right: 30px;
  }

  .lg\:px-24px {
    padding-left: 24px;
    padding-right: 24px;
  }

  .lg\:px-20px {
    padding-left: 20px;
    padding-right: 20px;
  }

  .lg\:px-15px {
    padding-left: 15px;
    padding-right: 15px;
  }

  .lg\:px-13px {
    padding-left: 13px;
    padding-right: 13px;
  }

  .lg\:px-12px {
    padding-left: 12px;
    padding-right: 12px;
  }

  .lg\:px-10px {
    padding-left: 10px;
    padding-right: 10px;
  }

  .lg\:px-8px {
    padding-left: 8px;
    padding-right: 8px;
  }

  .lg\:px-6px {
    padding-left: 6px;
    padding-right: 6px;
  }

  .lg\:px-5px {
    padding-left: 5px;
    padding-right: 5px;
  }

  .lg\:px-3px {
    padding-left: 3px;
    padding-right: 3px;
  }

  .lg\:px-menu {
    padding-left: 275px;
    padding-right: 275px;
  }

  .lg\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .lg\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .lg\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .lg\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .lg\:py-300px {
    padding-top: 300px;
    padding-bottom: 300px;
  }

  .lg\:py-230px {
    padding-top: 230px;
    padding-bottom: 230px;
  }

  .lg\:py-180px {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .lg\:py-147px {
    padding-top: 147px;
    padding-bottom: 147px;
  }

  .lg\:py-123px {
    padding-top: 123px;
    padding-bottom: 123px;
  }

  .lg\:py-100px {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .lg\:py-80px {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .lg\:py-60px {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .lg\:py-50px {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .lg\:py-40px {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .lg\:py-35px {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .lg\:py-34px-i {
    padding-top: 34px !important;
    padding-bottom: 34px !important;
  }

  .lg\:py-30px-i {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .lg\:py-24px-i {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .lg\:py-20px-i {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .lg\:py-15px-i {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .lg\:py-13px-i {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  .lg\:py-12px-i {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .lg\:py-10px-i {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .lg\:py-8px-i {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .lg\:py-6px-i {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .lg\:py-5px-i {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .lg\:py-3px-i {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .lg\:py-0px-i {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .lg\:py-34px {
    padding-top: 34px;
    padding-bottom: 34px;
  }

  .lg\:py-30px {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .lg\:py-24px {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .lg\:py-20px {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .lg\:py-15px {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .lg\:py-13px {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .lg\:py-12px {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .lg\:py-10px {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .lg\:py-8px {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .lg\:py-6px {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .lg\:py-5px {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .lg\:py-3px {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .lg\:py-menu {
    padding-top: 275px;
    padding-bottom: 275px;
  }

  .lg\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pt-1 {
    padding-top: 0.25rem;
  }

  .lg\:pt-2 {
    padding-top: 0.5rem;
  }

  .lg\:pt-3 {
    padding-top: 0.75rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-5 {
    padding-top: 1.25rem;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:pt-16 {
    padding-top: 4rem;
  }

  .lg\:pt-20 {
    padding-top: 5rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pt-32 {
    padding-top: 8rem;
  }

  .lg\:pt-40 {
    padding-top: 10rem;
  }

  .lg\:pt-48 {
    padding-top: 12rem;
  }

  .lg\:pt-56 {
    padding-top: 14rem;
  }

  .lg\:pt-64 {
    padding-top: 16rem;
  }

  .lg\:pt-300px {
    padding-top: 300px;
  }

  .lg\:pt-230px {
    padding-top: 230px;
  }

  .lg\:pt-180px {
    padding-top: 180px;
  }

  .lg\:pt-147px {
    padding-top: 147px;
  }

  .lg\:pt-123px {
    padding-top: 123px;
  }

  .lg\:pt-100px {
    padding-top: 100px;
  }

  .lg\:pt-80px {
    padding-top: 80px;
  }

  .lg\:pt-60px {
    padding-top: 60px;
  }

  .lg\:pt-50px {
    padding-top: 50px;
  }

  .lg\:pt-40px {
    padding-top: 40px;
  }

  .lg\:pt-35px {
    padding-top: 35px;
  }

  .lg\:pt-34px-i {
    padding-top: 34px !important;
  }

  .lg\:pt-30px-i {
    padding-top: 30px !important;
  }

  .lg\:pt-24px-i {
    padding-top: 24px !important;
  }

  .lg\:pt-20px-i {
    padding-top: 20px !important;
  }

  .lg\:pt-15px-i {
    padding-top: 15px !important;
  }

  .lg\:pt-13px-i {
    padding-top: 13px !important;
  }

  .lg\:pt-12px-i {
    padding-top: 12px !important;
  }

  .lg\:pt-10px-i {
    padding-top: 10px !important;
  }

  .lg\:pt-8px-i {
    padding-top: 8px !important;
  }

  .lg\:pt-6px-i {
    padding-top: 6px !important;
  }

  .lg\:pt-5px-i {
    padding-top: 5px !important;
  }

  .lg\:pt-3px-i {
    padding-top: 3px !important;
  }

  .lg\:pt-0px-i {
    padding-top: 0px !important;
  }

  .lg\:pt-34px {
    padding-top: 34px;
  }

  .lg\:pt-30px {
    padding-top: 30px;
  }

  .lg\:pt-24px {
    padding-top: 24px;
  }

  .lg\:pt-20px {
    padding-top: 20px;
  }

  .lg\:pt-15px {
    padding-top: 15px;
  }

  .lg\:pt-13px {
    padding-top: 13px;
  }

  .lg\:pt-12px {
    padding-top: 12px;
  }

  .lg\:pt-10px {
    padding-top: 10px;
  }

  .lg\:pt-8px {
    padding-top: 8px;
  }

  .lg\:pt-6px {
    padding-top: 6px;
  }

  .lg\:pt-5px {
    padding-top: 5px;
  }

  .lg\:pt-3px {
    padding-top: 3px;
  }

  .lg\:pt-menu {
    padding-top: 275px;
  }

  .lg\:pt-px {
    padding-top: 1px !important;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pr-1 {
    padding-right: 0.25rem;
  }

  .lg\:pr-2 {
    padding-right: 0.5rem;
  }

  .lg\:pr-3 {
    padding-right: 0.75rem;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pr-5 {
    padding-right: 1.25rem;
  }

  .lg\:pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:pr-12 {
    padding-right: 3rem;
  }

  .lg\:pr-16 {
    padding-right: 4rem;
  }

  .lg\:pr-20 {
    padding-right: 5rem;
  }

  .lg\:pr-24 {
    padding-right: 6rem;
  }

  .lg\:pr-32 {
    padding-right: 8rem;
  }

  .lg\:pr-40 {
    padding-right: 10rem;
  }

  .lg\:pr-48 {
    padding-right: 12rem;
  }

  .lg\:pr-56 {
    padding-right: 14rem;
  }

  .lg\:pr-64 {
    padding-right: 16rem;
  }

  .lg\:pr-300px {
    padding-right: 300px;
  }

  .lg\:pr-230px {
    padding-right: 230px;
  }

  .lg\:pr-180px {
    padding-right: 180px;
  }

  .lg\:pr-147px {
    padding-right: 147px;
  }

  .lg\:pr-123px {
    padding-right: 123px;
  }

  .lg\:pr-100px {
    padding-right: 100px;
  }

  .lg\:pr-80px {
    padding-right: 80px;
  }

  .lg\:pr-60px {
    padding-right: 60px;
  }

  .lg\:pr-50px {
    padding-right: 50px;
  }

  .lg\:pr-40px {
    padding-right: 40px;
  }

  .lg\:pr-35px {
    padding-right: 35px;
  }

  .lg\:pr-34px-i {
    padding-right: 34px !important;
  }

  .lg\:pr-30px-i {
    padding-right: 30px !important;
  }

  .lg\:pr-24px-i {
    padding-right: 24px !important;
  }

  .lg\:pr-20px-i {
    padding-right: 20px !important;
  }

  .lg\:pr-15px-i {
    padding-right: 15px !important;
  }

  .lg\:pr-13px-i {
    padding-right: 13px !important;
  }

  .lg\:pr-12px-i {
    padding-right: 12px !important;
  }

  .lg\:pr-10px-i {
    padding-right: 10px !important;
  }

  .lg\:pr-8px-i {
    padding-right: 8px !important;
  }

  .lg\:pr-6px-i {
    padding-right: 6px !important;
  }

  .lg\:pr-5px-i {
    padding-right: 5px !important;
  }

  .lg\:pr-3px-i {
    padding-right: 3px !important;
  }

  .lg\:pr-0px-i {
    padding-right: 0px !important;
  }

  .lg\:pr-34px {
    padding-right: 34px;
  }

  .lg\:pr-30px {
    padding-right: 30px;
  }

  .lg\:pr-24px {
    padding-right: 24px;
  }

  .lg\:pr-20px {
    padding-right: 20px;
  }

  .lg\:pr-15px {
    padding-right: 15px;
  }

  .lg\:pr-13px {
    padding-right: 13px;
  }

  .lg\:pr-12px {
    padding-right: 12px;
  }

  .lg\:pr-10px {
    padding-right: 10px;
  }

  .lg\:pr-8px {
    padding-right: 8px;
  }

  .lg\:pr-6px {
    padding-right: 6px;
  }

  .lg\:pr-5px {
    padding-right: 5px;
  }

  .lg\:pr-3px {
    padding-right: 3px;
  }

  .lg\:pr-menu {
    padding-right: 275px;
  }

  .lg\:pr-px {
    padding-right: 1px !important;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-1 {
    padding-bottom: 0.25rem;
  }

  .lg\:pb-2 {
    padding-bottom: 0.5rem;
  }

  .lg\:pb-3 {
    padding-bottom: 0.75rem;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:pb-40 {
    padding-bottom: 10rem;
  }

  .lg\:pb-48 {
    padding-bottom: 12rem;
  }

  .lg\:pb-56 {
    padding-bottom: 14rem;
  }

  .lg\:pb-64 {
    padding-bottom: 16rem;
  }

  .lg\:pb-300px {
    padding-bottom: 300px;
  }

  .lg\:pb-230px {
    padding-bottom: 230px;
  }

  .lg\:pb-180px {
    padding-bottom: 180px;
  }

  .lg\:pb-147px {
    padding-bottom: 147px;
  }

  .lg\:pb-123px {
    padding-bottom: 123px;
  }

  .lg\:pb-100px {
    padding-bottom: 100px;
  }

  .lg\:pb-80px {
    padding-bottom: 80px;
  }

  .lg\:pb-60px {
    padding-bottom: 60px;
  }

  .lg\:pb-50px {
    padding-bottom: 50px;
  }

  .lg\:pb-40px {
    padding-bottom: 40px;
  }

  .lg\:pb-35px {
    padding-bottom: 35px;
  }

  .lg\:pb-34px-i {
    padding-bottom: 34px !important;
  }

  .lg\:pb-30px-i {
    padding-bottom: 30px !important;
  }

  .lg\:pb-24px-i {
    padding-bottom: 24px !important;
  }

  .lg\:pb-20px-i {
    padding-bottom: 20px !important;
  }

  .lg\:pb-15px-i {
    padding-bottom: 15px !important;
  }

  .lg\:pb-13px-i {
    padding-bottom: 13px !important;
  }

  .lg\:pb-12px-i {
    padding-bottom: 12px !important;
  }

  .lg\:pb-10px-i {
    padding-bottom: 10px !important;
  }

  .lg\:pb-8px-i {
    padding-bottom: 8px !important;
  }

  .lg\:pb-6px-i {
    padding-bottom: 6px !important;
  }

  .lg\:pb-5px-i {
    padding-bottom: 5px !important;
  }

  .lg\:pb-3px-i {
    padding-bottom: 3px !important;
  }

  .lg\:pb-0px-i {
    padding-bottom: 0px !important;
  }

  .lg\:pb-34px {
    padding-bottom: 34px;
  }

  .lg\:pb-30px {
    padding-bottom: 30px;
  }

  .lg\:pb-24px {
    padding-bottom: 24px;
  }

  .lg\:pb-20px {
    padding-bottom: 20px;
  }

  .lg\:pb-15px {
    padding-bottom: 15px;
  }

  .lg\:pb-13px {
    padding-bottom: 13px;
  }

  .lg\:pb-12px {
    padding-bottom: 12px;
  }

  .lg\:pb-10px {
    padding-bottom: 10px;
  }

  .lg\:pb-8px {
    padding-bottom: 8px;
  }

  .lg\:pb-6px {
    padding-bottom: 6px;
  }

  .lg\:pb-5px {
    padding-bottom: 5px;
  }

  .lg\:pb-3px {
    padding-bottom: 3px;
  }

  .lg\:pb-menu {
    padding-bottom: 275px;
  }

  .lg\:pb-px {
    padding-bottom: 1px !important;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:pl-1 {
    padding-left: 0.25rem;
  }

  .lg\:pl-2 {
    padding-left: 0.5rem;
  }

  .lg\:pl-3 {
    padding-left: 0.75rem;
  }

  .lg\:pl-4 {
    padding-left: 1rem;
  }

  .lg\:pl-5 {
    padding-left: 1.25rem;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }

  .lg\:pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:pl-12 {
    padding-left: 3rem;
  }

  .lg\:pl-16 {
    padding-left: 4rem;
  }

  .lg\:pl-20 {
    padding-left: 5rem;
  }

  .lg\:pl-24 {
    padding-left: 6rem;
  }

  .lg\:pl-32 {
    padding-left: 8rem;
  }

  .lg\:pl-40 {
    padding-left: 10rem;
  }

  .lg\:pl-48 {
    padding-left: 12rem;
  }

  .lg\:pl-56 {
    padding-left: 14rem;
  }

  .lg\:pl-64 {
    padding-left: 16rem;
  }

  .lg\:pl-300px {
    padding-left: 300px;
  }

  .lg\:pl-230px {
    padding-left: 230px;
  }

  .lg\:pl-180px {
    padding-left: 180px;
  }

  .lg\:pl-147px {
    padding-left: 147px;
  }

  .lg\:pl-123px {
    padding-left: 123px;
  }

  .lg\:pl-100px {
    padding-left: 100px;
  }

  .lg\:pl-80px {
    padding-left: 80px;
  }

  .lg\:pl-60px {
    padding-left: 60px;
  }

  .lg\:pl-50px {
    padding-left: 50px;
  }

  .lg\:pl-40px {
    padding-left: 40px;
  }

  .lg\:pl-35px {
    padding-left: 35px;
  }

  .lg\:pl-34px-i {
    padding-left: 34px !important;
  }

  .lg\:pl-30px-i {
    padding-left: 30px !important;
  }

  .lg\:pl-24px-i {
    padding-left: 24px !important;
  }

  .lg\:pl-20px-i {
    padding-left: 20px !important;
  }

  .lg\:pl-15px-i {
    padding-left: 15px !important;
  }

  .lg\:pl-13px-i {
    padding-left: 13px !important;
  }

  .lg\:pl-12px-i {
    padding-left: 12px !important;
  }

  .lg\:pl-10px-i {
    padding-left: 10px !important;
  }

  .lg\:pl-8px-i {
    padding-left: 8px !important;
  }

  .lg\:pl-6px-i {
    padding-left: 6px !important;
  }

  .lg\:pl-5px-i {
    padding-left: 5px !important;
  }

  .lg\:pl-3px-i {
    padding-left: 3px !important;
  }

  .lg\:pl-0px-i {
    padding-left: 0px !important;
  }

  .lg\:pl-34px {
    padding-left: 34px;
  }

  .lg\:pl-30px {
    padding-left: 30px;
  }

  .lg\:pl-24px {
    padding-left: 24px;
  }

  .lg\:pl-20px {
    padding-left: 20px;
  }

  .lg\:pl-15px {
    padding-left: 15px;
  }

  .lg\:pl-13px {
    padding-left: 13px;
  }

  .lg\:pl-12px {
    padding-left: 12px;
  }

  .lg\:pl-10px {
    padding-left: 10px;
  }

  .lg\:pl-8px {
    padding-left: 8px;
  }

  .lg\:pl-6px {
    padding-left: 6px;
  }

  .lg\:pl-5px {
    padding-left: 5px;
  }

  .lg\:pl-3px {
    padding-left: 3px;
  }

  .lg\:pl-menu {
    padding-left: 275px;
  }

  .lg\:pl-px {
    padding-left: 1px !important;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-justify {
    text-align: justify;
  }

  .lg\:align-baseline {
    vertical-align: baseline;
  }

  .lg\:align-top {
    vertical-align: top;
  }

  .lg\:align-middle {
    vertical-align: middle;
  }

  .lg\:align-bottom {
    vertical-align: bottom;
  }

  .lg\:align-text-top {
    vertical-align: text-top;
  }

  .lg\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .lg\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .lg\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .lg\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .lg\:text-size-42 {
    font-size: 42px !important;
  }

  .lg\:text-size-32 {
    font-size: 32px !important;
  }

  .lg\:text-size-28 {
    font-size: 24px !important;
  }

  .lg\:text-size-24 {
    font-size: 24px !important;
  }

  .lg\:text-size-20 {
    font-size: 20px !important;
  }

  .lg\:text-size-18 {
    font-size: 18px !important;
  }

  .lg\:font-hairline {
    font-weight: 100;
  }

  .lg\:font-thin {
    font-weight: 200;
  }

  .lg\:font-light {
    font-weight: 300 !important;
  }

  .lg\:font-normal {
    font-weight: 400 !important;
  }

  .lg\:font-medium {
    font-weight: 500;
  }

  .lg\:font-semibold {
    font-weight: 600;
  }

  .lg\:font-bold {
    font-weight: 700 !important;
  }

  .lg\:font-extrabold {
    font-weight: 800;
  }

  .lg\:font-black {
    font-weight: 900;
  }

  .lg\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .lg\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .lg\:hover\:font-light:hover {
    font-weight: 300 !important;
  }

  .lg\:hover\:font-normal:hover {
    font-weight: 400 !important;
  }

  .lg\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .lg\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .lg\:hover\:font-bold:hover {
    font-weight: 700 !important;
  }

  .lg\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .lg\:hover\:font-black:hover {
    font-weight: 900;
  }

  .lg\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .lg\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .lg\:focus\:font-light:focus {
    font-weight: 300 !important;
  }

  .lg\:focus\:font-normal:focus {
    font-weight: 400 !important;
  }

  .lg\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .lg\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .lg\:focus\:font-bold:focus {
    font-weight: 700 !important;
  }

  .lg\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .lg\:focus\:font-black:focus {
    font-weight: 900;
  }

  .lg\:uppercase {
    text-transform: uppercase;
  }

  .lg\:lowercase {
    text-transform: lowercase;
  }

  .lg\:capitalize {
    text-transform: capitalize;
  }

  .lg\:normal-case {
    text-transform: none;
  }

  .lg\:italic {
    font-style: italic;
  }

  .lg\:not-italic {
    font-style: normal;
  }

  .lg\:ordinal, .lg\:slashed-zero, .lg\:lining-nums, .lg\:oldstyle-nums, .lg\:proportional-nums, .lg\:tabular-nums, .lg\:diagonal-fractions, .lg\:stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .lg\:normal-nums {
    font-variant-numeric: normal;
  }

  .lg\:ordinal {
    --tw-ordinal: ordinal;
  }

  .lg\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .lg\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .lg\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .lg\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .lg\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .lg\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .lg\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .lg\:leading-size-54 {
    line-height: 54px !important;
  }

  .lg\:leading-size-40 {
    line-height: 40px !important;
  }

  .lg\:leading-size-32 {
    line-height: 32px !important;
  }

  .lg\:leading-size-28 {
    line-height: 28px !important;
  }

  .lg\:leading-size-24 {
    line-height: 24px !important;
  }

  .lg\:tracking-tightest {
    letter-spacing: -0.1em;
  }

  .lg\:tracking-tighter {
    letter-spacing: -0.05em;
  }

  .lg\:tracking-tight {
    letter-spacing: -0.025em;
  }

  .lg\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .lg\:tracking-wide {
    letter-spacing: 0.025em;
  }

  .lg\:tracking-wider {
    letter-spacing: 0.05em;
  }

  .lg\:tracking-widest {
    letter-spacing: 0.1em;
  }

  .lg\:text-transparent {
    color: transparent;
  }

  .lg\:text-current {
    color: currentColor;
  }

  .lg\:text-black {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .lg\:text-black40pct {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:text-petroleum {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .lg\:text-petroleum70pct {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .lg\:text-petroleum30pct {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .lg\:text-disabled {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .lg\:text-petroleum10pct {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .lg\:text-petroleum5pct {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .lg\:text-darkGreen {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .lg\:text-ok {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .lg\:text-green {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .lg\:text-green10pct {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .lg\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .lg\:text-darkOrange {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .lg\:text-lightOrange {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .lg\:text-error {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .lg\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .lg\:text-darkYellow {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .lg\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .lg\:text-lightGray {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .lg\:text-borderGray {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .lg\:hover\:text-transparent:hover {
    color: transparent;
  }

  .lg\:hover\:text-current:hover {
    color: currentColor;
  }

  .lg\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .lg\:hover\:text-black40pct:hover {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .lg\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:hover\:text-petroleum:hover {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .lg\:hover\:text-petroleum70pct:hover {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .lg\:hover\:text-petroleum30pct:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .lg\:hover\:text-disabled:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .lg\:hover\:text-petroleum10pct:hover {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .lg\:hover\:text-petroleum5pct:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .lg\:hover\:text-darkGreen:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .lg\:hover\:text-ok:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .lg\:hover\:text-green:hover {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .lg\:hover\:text-green10pct:hover {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .lg\:hover\:text-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .lg\:hover\:text-darkOrange:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .lg\:hover\:text-lightOrange:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .lg\:hover\:text-error:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .lg\:hover\:text-yellow:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .lg\:hover\:text-darkYellow:hover {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .lg\:hover\:text-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .lg\:hover\:text-lightGray:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .lg\:hover\:text-borderGray:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .lg\:focus\:text-transparent:focus {
    color: transparent;
  }

  .lg\:focus\:text-current:focus {
    color: currentColor;
  }

  .lg\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .lg\:focus\:text-black40pct:focus {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .lg\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:focus\:text-petroleum:focus {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .lg\:focus\:text-petroleum70pct:focus {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .lg\:focus\:text-petroleum30pct:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .lg\:focus\:text-disabled:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .lg\:focus\:text-petroleum10pct:focus {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .lg\:focus\:text-petroleum5pct:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .lg\:focus\:text-darkGreen:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .lg\:focus\:text-ok:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .lg\:focus\:text-green:focus {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .lg\:focus\:text-green10pct:focus {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .lg\:focus\:text-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .lg\:focus\:text-darkOrange:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .lg\:focus\:text-lightOrange:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .lg\:focus\:text-error:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .lg\:focus\:text-yellow:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .lg\:focus\:text-darkYellow:focus {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .lg\:focus\:text-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .lg\:focus\:text-lightGray:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .lg\:focus\:text-borderGray:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .lg\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .lg\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .lg\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .lg\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .lg\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .lg\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .lg\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }

  .lg\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }

  .lg\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }

  .lg\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .lg\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .lg\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }

  .lg\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }

  .lg\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }

  .lg\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .lg\:underline {
    text-decoration: underline;
  }

  .lg\:line-through {
    text-decoration: line-through;
  }

  .lg\:no-underline {
    text-decoration: none;
  }

  .lg\:hover\:underline:hover {
    text-decoration: underline;
  }

  .lg\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .lg\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .lg\:focus\:underline:focus {
    text-decoration: underline;
  }

  .lg\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .lg\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .lg\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .lg\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .lg\:placeholder-transparent::placeholder {
    color: transparent;
  }

  .lg\:placeholder-current::placeholder {
    color: currentColor;
  }

  .lg\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-black40pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-petroleum::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-petroleum70pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-petroleum30pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-disabled::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-petroleum10pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-petroleum5pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-darkGreen::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-ok::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-green10pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-darkOrange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-lightOrange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-error::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-yellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-darkYellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-lightGray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-borderGray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .lg\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .lg\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-black40pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-petroleum:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-petroleum70pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-petroleum30pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-disabled:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-petroleum10pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-petroleum5pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-darkGreen:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-ok:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-green10pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-darkOrange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-lightOrange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-error:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-yellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-darkYellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-lightGray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-placeholder-opacity));
  }

  .lg\:focus\:placeholder-borderGray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-placeholder-opacity));
  }

  .lg\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .lg\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .lg\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .lg\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .lg\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .lg\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .lg\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .lg\:opacity-0 {
    opacity: 0;
  }

  .lg\:opacity-25 {
    opacity: 0.25;
  }

  .lg\:opacity-50 {
    opacity: 0.5;
  }

  .lg\:opacity-75 {
    opacity: 0.75;
  }

  .lg\:opacity-100 {
    opacity: 1;
  }

  .lg\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .lg\:hover\:opacity-25:hover {
    opacity: 0.25;
  }

  .lg\:hover\:opacity-50:hover {
    opacity: 0.5;
  }

  .lg\:hover\:opacity-75:hover {
    opacity: 0.75;
  }

  .lg\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .lg\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .lg\:focus\:opacity-25:focus {
    opacity: 0.25;
  }

  .lg\:focus\:opacity-50:focus {
    opacity: 0.5;
  }

  .lg\:focus\:opacity-75:focus {
    opacity: 0.75;
  }

  .lg\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .lg\:shadow-xs {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-default {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-outline {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-xs:hover {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-default:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-outline:hover {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-xs:focus {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-default:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-outline:focus {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .lg\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .lg\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .lg\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .lg\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .lg\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .lg\:transition-none {
    transition-property: none;
  }

  .lg\:transition-all {
    transition-property: all;
  }

  .lg\:transition-default {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .lg\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .lg\:transition-opacity {
    transition-property: opacity;
  }

  .lg\:transition-shadow {
    transition-property: box-shadow;
  }

  .lg\:transition-transform {
    transition-property: transform;
  }

  .lg\:delay-75 {
    transition-delay: 75ms;
  }

  .lg\:delay-100 {
    transition-delay: 100ms;
  }

  .lg\:delay-150 {
    transition-delay: 150ms;
  }

  .lg\:delay-200 {
    transition-delay: 200ms;
  }

  .lg\:delay-300 {
    transition-delay: 300ms;
  }

  .lg\:delay-500 {
    transition-delay: 500ms;
  }

  .lg\:delay-700 {
    transition-delay: 700ms;
  }

  .lg\:delay-1000 {
    transition-delay: 1000ms;
  }

  .lg\:duration-75 {
    transition-duration: 75ms;
  }

  .lg\:duration-100 {
    transition-duration: 100ms;
  }

  .lg\:duration-150 {
    transition-duration: 150ms;
  }

  .lg\:duration-200 {
    transition-duration: 200ms;
  }

  .lg\:duration-300 {
    transition-duration: 300ms;
  }

  .lg\:duration-500 {
    transition-duration: 500ms;
  }

  .lg\:duration-700 {
    transition-duration: 700ms;
  }

  .lg\:duration-1000 {
    transition-duration: 1000ms;
  }

  .lg\:ease-linear {
    transition-timing-function: linear;
  }

  .lg\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .lg\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .lg\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (min-width: 1920px) {
  .xl\:container {
    width: 100%;
  }

  @media (min-width: 1px) {
    .xl\:container {
      max-width: 1px;
    }
  }

  @media (min-width: 768px) {
    .xl\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .xl\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1440px) {
    .xl\:container {
      max-width: 1440px;
    }
  }

  @media (min-width: 1920px) {
    .xl\:container {
      max-width: 1920px;
    }
  }

  .xl\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .xl\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .xl\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .xl\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }

  .xl\:pointer-events-none {
    pointer-events: none;
  }

  .xl\:pointer-events-auto {
    pointer-events: auto;
  }

  .xl\:visible {
    visibility: visible;
  }

  .xl\:invisible {
    visibility: hidden;
  }

  .xl\:static {
    position: static;
  }

  .xl\:fixed {
    position: fixed;
  }

  .xl\:absolute {
    position: absolute;
  }

  .xl\:relative {
    position: relative;
  }

  .xl\:sticky {
    position: sticky;
  }

  .xl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .xl\:inset-60px {
    top: 60px;
    right: 60px;
    bottom: 60px;
    left: 60px;
  }

  .xl\:inset-80px {
    top: 80px;
    right: 80px;
    bottom: 80px;
    left: 80px;
  }

  .xl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .xl\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .xl\:inset-x-60px {
    left: 60px;
    right: 60px;
  }

  .xl\:inset-x-80px {
    left: 80px;
    right: 80px;
  }

  .xl\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .xl\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .xl\:inset-y-60px {
    top: 60px;
    bottom: 60px;
  }

  .xl\:inset-y-80px {
    top: 80px;
    bottom: 80px;
  }

  .xl\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .xl\:top-0 {
    top: 0;
  }

  .xl\:top-60px {
    top: 60px;
  }

  .xl\:top-80px {
    top: 80px;
  }

  .xl\:top-auto {
    top: auto;
  }

  .xl\:right-0 {
    right: 0;
  }

  .xl\:right-60px {
    right: 60px;
  }

  .xl\:right-80px {
    right: 80px;
  }

  .xl\:right-auto {
    right: auto;
  }

  .xl\:bottom-0 {
    bottom: 0;
  }

  .xl\:bottom-60px {
    bottom: 60px;
  }

  .xl\:bottom-80px {
    bottom: 80px;
  }

  .xl\:bottom-auto {
    bottom: auto;
  }

  .xl\:left-0 {
    left: 0;
  }

  .xl\:left-60px {
    left: 60px;
  }

  .xl\:left-80px {
    left: 80px;
  }

  .xl\:left-auto {
    left: auto;
  }

  .xl\:z-0 {
    z-index: 0 !important;
  }

  .xl\:z-10 {
    z-index: 10 !important;
  }

  .xl\:z-20 {
    z-index: 20;
  }

  .xl\:z-30 {
    z-index: 30;
  }

  .xl\:z-40 {
    z-index: 40;
  }

  .xl\:z-50 {
    z-index: 50;
  }

  .xl\:z-auto {
    z-index: auto !important;
  }

  .xl\:order-1 {
    order: 1;
  }

  .xl\:order-2 {
    order: 2;
  }

  .xl\:order-3 {
    order: 3;
  }

  .xl\:order-4 {
    order: 4;
  }

  .xl\:order-5 {
    order: 5;
  }

  .xl\:order-6 {
    order: 6;
  }

  .xl\:order-7 {
    order: 7;
  }

  .xl\:order-8 {
    order: 8;
  }

  .xl\:order-9 {
    order: 9;
  }

  .xl\:order-10 {
    order: 10;
  }

  .xl\:order-11 {
    order: 11;
  }

  .xl\:order-12 {
    order: 12;
  }

  .xl\:order-first {
    order: -9999;
  }

  .xl\:order-last {
    order: 9999;
  }

  .xl\:order-none {
    order: 0;
  }

  .xl\:col-auto {
    grid-column: auto;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xl\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .xl\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .xl\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .xl\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .xl\:col-span-full {
    grid-column: 1 / -1;
  }

  .xl\:col-start-1 {
    grid-column-start: 1;
  }

  .xl\:col-start-2 {
    grid-column-start: 2;
  }

  .xl\:col-start-3 {
    grid-column-start: 3;
  }

  .xl\:col-start-4 {
    grid-column-start: 4;
  }

  .xl\:col-start-5 {
    grid-column-start: 5;
  }

  .xl\:col-start-6 {
    grid-column-start: 6;
  }

  .xl\:col-start-7 {
    grid-column-start: 7;
  }

  .xl\:col-start-8 {
    grid-column-start: 8;
  }

  .xl\:col-start-9 {
    grid-column-start: 9;
  }

  .xl\:col-start-10 {
    grid-column-start: 10;
  }

  .xl\:col-start-11 {
    grid-column-start: 11;
  }

  .xl\:col-start-12 {
    grid-column-start: 12;
  }

  .xl\:col-start-13 {
    grid-column-start: 13;
  }

  .xl\:col-start-auto {
    grid-column-start: auto;
  }

  .xl\:col-end-1 {
    grid-column-end: 1;
  }

  .xl\:col-end-2 {
    grid-column-end: 2;
  }

  .xl\:col-end-3 {
    grid-column-end: 3;
  }

  .xl\:col-end-4 {
    grid-column-end: 4;
  }

  .xl\:col-end-5 {
    grid-column-end: 5;
  }

  .xl\:col-end-6 {
    grid-column-end: 6;
  }

  .xl\:col-end-7 {
    grid-column-end: 7;
  }

  .xl\:col-end-8 {
    grid-column-end: 8;
  }

  .xl\:col-end-9 {
    grid-column-end: 9;
  }

  .xl\:col-end-10 {
    grid-column-end: 10;
  }

  .xl\:col-end-11 {
    grid-column-end: 11;
  }

  .xl\:col-end-12 {
    grid-column-end: 12;
  }

  .xl\:col-end-13 {
    grid-column-end: 13;
  }

  .xl\:col-end-auto {
    grid-column-end: auto;
  }

  .xl\:row-auto {
    grid-row: auto;
  }

  .xl\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .xl\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .xl\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .xl\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .xl\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .xl\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .xl\:row-span-full {
    grid-row: 1 / -1;
  }

  .xl\:row-start-1 {
    grid-row-start: 1;
  }

  .xl\:row-start-2 {
    grid-row-start: 2;
  }

  .xl\:row-start-3 {
    grid-row-start: 3;
  }

  .xl\:row-start-4 {
    grid-row-start: 4;
  }

  .xl\:row-start-5 {
    grid-row-start: 5;
  }

  .xl\:row-start-6 {
    grid-row-start: 6;
  }

  .xl\:row-start-7 {
    grid-row-start: 7;
  }

  .xl\:row-start-auto {
    grid-row-start: auto;
  }

  .xl\:row-end-1 {
    grid-row-end: 1;
  }

  .xl\:row-end-2 {
    grid-row-end: 2;
  }

  .xl\:row-end-3 {
    grid-row-end: 3;
  }

  .xl\:row-end-4 {
    grid-row-end: 4;
  }

  .xl\:row-end-5 {
    grid-row-end: 5;
  }

  .xl\:row-end-6 {
    grid-row-end: 6;
  }

  .xl\:row-end-7 {
    grid-row-end: 7;
  }

  .xl\:row-end-auto {
    grid-row-end: auto;
  }

  .xl\:float-right {
    float: right;
  }

  .xl\:float-left {
    float: left;
  }

  .xl\:float-none {
    float: none;
  }

  .xl\:clear-left {
    clear: left;
  }

  .xl\:clear-right {
    clear: right;
  }

  .xl\:clear-both {
    clear: both;
  }

  .xl\:clear-none {
    clear: none;
  }

  .xl\:m-0 {
    margin: 0;
  }

  .xl\:m-1 {
    margin: 0.25rem;
  }

  .xl\:m-2 {
    margin: 0.5rem;
  }

  .xl\:m-3 {
    margin: 0.75rem;
  }

  .xl\:m-4 {
    margin: 1rem;
  }

  .xl\:m-5 {
    margin: 1.25rem;
  }

  .xl\:m-6 {
    margin: 1.5rem;
  }

  .xl\:m-8 {
    margin: 2rem;
  }

  .xl\:m-10 {
    margin: 2.5rem;
  }

  .xl\:m-12 {
    margin: 3rem;
  }

  .xl\:m-16 {
    margin: 4rem;
  }

  .xl\:m-20 {
    margin: 5rem;
  }

  .xl\:m-24 {
    margin: 6rem;
  }

  .xl\:m-32 {
    margin: 8rem;
  }

  .xl\:m-40 {
    margin: 10rem;
  }

  .xl\:m-48 {
    margin: 12rem;
  }

  .xl\:m-56 {
    margin: 14rem;
  }

  .xl\:m-64 {
    margin: 16rem;
  }

  .xl\:m-auto {
    margin: auto;
  }

  .xl\:m-300px {
    margin: 300px;
  }

  .xl\:m-230px {
    margin: 230px;
  }

  .xl\:m-180px {
    margin: 180px;
  }

  .xl\:m-147px {
    margin: 147px;
  }

  .xl\:m-123px {
    margin: 123px;
  }

  .xl\:m-100px {
    margin: 100px;
  }

  .xl\:m-80px {
    margin: 80px;
  }

  .xl\:m-60px {
    margin: 60px;
  }

  .xl\:m-50px {
    margin: 50px;
  }

  .xl\:m-40px {
    margin: 40px;
  }

  .xl\:m-35px {
    margin: 35px;
  }

  .xl\:m-34px-i {
    margin: 34px !important;
  }

  .xl\:m-30px-i {
    margin: 30px !important;
  }

  .xl\:m-24px-i {
    margin: 24px !important;
  }

  .xl\:m-20px-i {
    margin: 20px !important;
  }

  .xl\:m-15px-i {
    margin: 15px !important;
  }

  .xl\:m-13px-i {
    margin: 13px !important;
  }

  .xl\:m-12px-i {
    margin: 12px !important;
  }

  .xl\:m-10px-i {
    margin: 10px !important;
  }

  .xl\:m-8px-i {
    margin: 8px !important;
  }

  .xl\:m-6px-i {
    margin: 6px !important;
  }

  .xl\:m-5px-i {
    margin: 5px !important;
  }

  .xl\:m-3px-i {
    margin: 3px !important;
  }

  .xl\:m-0px-i {
    margin: 0px !important;
  }

  .xl\:m-34px {
    margin: 34px;
  }

  .xl\:m-30px {
    margin: 30px;
  }

  .xl\:m-24px {
    margin: 24px;
  }

  .xl\:m-20px {
    margin: 20px;
  }

  .xl\:m-15px {
    margin: 15px;
  }

  .xl\:m-13px {
    margin: 13px;
  }

  .xl\:m-12px {
    margin: 12px;
  }

  .xl\:m-10px {
    margin: 10px;
  }

  .xl\:m-8px {
    margin: 8px;
  }

  .xl\:m-6px {
    margin: 6px;
  }

  .xl\:m-5px {
    margin: 5px;
  }

  .xl\:m-3px {
    margin: 3px;
  }

  .xl\:m-menu {
    margin: 275px;
  }

  .xl\:m-px {
    margin: 1px !important;
  }

  .xl\:-m-1 {
    margin: -0.25rem;
  }

  .xl\:-m-2 {
    margin: -0.5rem;
  }

  .xl\:-m-3 {
    margin: -0.75rem;
  }

  .xl\:-m-4 {
    margin: -1rem;
  }

  .xl\:-m-5 {
    margin: -1.25rem;
  }

  .xl\:-m-6 {
    margin: -1.5rem;
  }

  .xl\:-m-8 {
    margin: -2rem;
  }

  .xl\:-m-10 {
    margin: -2.5rem;
  }

  .xl\:-m-12 {
    margin: -3rem;
  }

  .xl\:-m-16 {
    margin: -4rem;
  }

  .xl\:-m-20 {
    margin: -5rem;
  }

  .xl\:-m-24 {
    margin: -6rem;
  }

  .xl\:-m-32 {
    margin: -8rem;
  }

  .xl\:-m-40 {
    margin: -10rem;
  }

  .xl\:-m-48 {
    margin: -12rem;
  }

  .xl\:-m-56 {
    margin: -14rem;
  }

  .xl\:-m-64 {
    margin: -16rem;
  }

  .xl\:-m-300px {
    margin: -300px;
  }

  .xl\:-m-230px {
    margin: -230px;
  }

  .xl\:-m-180px {
    margin: -180px;
  }

  .xl\:-m-147px {
    margin: -147px;
  }

  .xl\:-m-123px {
    margin: -123px;
  }

  .xl\:-m-100px {
    margin: -100px;
  }

  .xl\:-m-80px {
    margin: -80px;
  }

  .xl\:-m-60px {
    margin: -60px;
  }

  .xl\:-m-50px {
    margin: -50px;
  }

  .xl\:-m-40px {
    margin: -40px;
  }

  .xl\:-m-35px {
    margin: -35px;
  }

  .xl\:-m-34px-i {
    margin: 34px !important;
  }

  .xl\:-m-30px-i {
    margin: 30px !important;
  }

  .xl\:-m-24px-i {
    margin: 24px !important;
  }

  .xl\:-m-20px-i {
    margin: 20px !important;
  }

  .xl\:-m-15px-i {
    margin: 15px !important;
  }

  .xl\:-m-13px-i {
    margin: 13px !important;
  }

  .xl\:-m-12px-i {
    margin: 12px !important;
  }

  .xl\:-m-10px-i {
    margin: 10px !important;
  }

  .xl\:-m-8px-i {
    margin: 8px !important;
  }

  .xl\:-m-6px-i {
    margin: 6px !important;
  }

  .xl\:-m-5px-i {
    margin: 5px !important;
  }

  .xl\:-m-3px-i {
    margin: 3px !important;
  }

  .xl\:-m-0px-i {
    margin: 0px !important;
  }

  .xl\:-m-34px {
    margin: -34px;
  }

  .xl\:-m-30px {
    margin: -30px;
  }

  .xl\:-m-24px {
    margin: -24px;
  }

  .xl\:-m-20px {
    margin: -20px;
  }

  .xl\:-m-15px {
    margin: -15px;
  }

  .xl\:-m-13px {
    margin: -13px;
  }

  .xl\:-m-12px {
    margin: -12px;
  }

  .xl\:-m-10px {
    margin: -10px;
  }

  .xl\:-m-8px {
    margin: -8px;
  }

  .xl\:-m-6px {
    margin: -6px;
  }

  .xl\:-m-5px {
    margin: -5px;
  }

  .xl\:-m-3px {
    margin: -3px;
  }

  .xl\:-m-menu {
    margin: -275px;
  }

  .xl\:-m-px {
    margin: 1px !important;
  }

  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .xl\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .xl\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .xl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .xl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .xl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .xl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .xl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .xl\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .xl\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .xl\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .xl\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xl\:mx-300px {
    margin-left: 300px;
    margin-right: 300px;
  }

  .xl\:mx-230px {
    margin-left: 230px;
    margin-right: 230px;
  }

  .xl\:mx-180px {
    margin-left: 180px;
    margin-right: 180px;
  }

  .xl\:mx-147px {
    margin-left: 147px;
    margin-right: 147px;
  }

  .xl\:mx-123px {
    margin-left: 123px;
    margin-right: 123px;
  }

  .xl\:mx-100px {
    margin-left: 100px;
    margin-right: 100px;
  }

  .xl\:mx-80px {
    margin-left: 80px;
    margin-right: 80px;
  }

  .xl\:mx-60px {
    margin-left: 60px;
    margin-right: 60px;
  }

  .xl\:mx-50px {
    margin-left: 50px;
    margin-right: 50px;
  }

  .xl\:mx-40px {
    margin-left: 40px;
    margin-right: 40px;
  }

  .xl\:mx-35px {
    margin-left: 35px;
    margin-right: 35px;
  }

  .xl\:mx-34px-i {
    margin-left: 34px !important;
    margin-right: 34px !important;
  }

  .xl\:mx-30px-i {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .xl\:mx-24px-i {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .xl\:mx-20px-i {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .xl\:mx-15px-i {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .xl\:mx-13px-i {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }

  .xl\:mx-12px-i {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .xl\:mx-10px-i {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .xl\:mx-8px-i {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .xl\:mx-6px-i {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .xl\:mx-5px-i {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .xl\:mx-3px-i {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .xl\:mx-0px-i {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .xl\:mx-34px {
    margin-left: 34px;
    margin-right: 34px;
  }

  .xl\:mx-30px {
    margin-left: 30px;
    margin-right: 30px;
  }

  .xl\:mx-24px {
    margin-left: 24px;
    margin-right: 24px;
  }

  .xl\:mx-20px {
    margin-left: 20px;
    margin-right: 20px;
  }

  .xl\:mx-15px {
    margin-left: 15px;
    margin-right: 15px;
  }

  .xl\:mx-13px {
    margin-left: 13px;
    margin-right: 13px;
  }

  .xl\:mx-12px {
    margin-left: 12px;
    margin-right: 12px;
  }

  .xl\:mx-10px {
    margin-left: 10px;
    margin-right: 10px;
  }

  .xl\:mx-8px {
    margin-left: 8px;
    margin-right: 8px;
  }

  .xl\:mx-6px {
    margin-left: 6px;
    margin-right: 6px;
  }

  .xl\:mx-5px {
    margin-left: 5px;
    margin-right: 5px;
  }

  .xl\:mx-3px {
    margin-left: 3px;
    margin-right: 3px;
  }

  .xl\:mx-menu {
    margin-left: 275px;
    margin-right: 275px;
  }

  .xl\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .xl\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  .xl\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .xl\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .xl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .xl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .xl\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .xl\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .xl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .xl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .xl\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .xl\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .xl\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .xl\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .xl\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .xl\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .xl\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .xl\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .xl\:-mx-300px {
    margin-left: -300px;
    margin-right: -300px;
  }

  .xl\:-mx-230px {
    margin-left: -230px;
    margin-right: -230px;
  }

  .xl\:-mx-180px {
    margin-left: -180px;
    margin-right: -180px;
  }

  .xl\:-mx-147px {
    margin-left: -147px;
    margin-right: -147px;
  }

  .xl\:-mx-123px {
    margin-left: -123px;
    margin-right: -123px;
  }

  .xl\:-mx-100px {
    margin-left: -100px;
    margin-right: -100px;
  }

  .xl\:-mx-80px {
    margin-left: -80px;
    margin-right: -80px;
  }

  .xl\:-mx-60px {
    margin-left: -60px;
    margin-right: -60px;
  }

  .xl\:-mx-50px {
    margin-left: -50px;
    margin-right: -50px;
  }

  .xl\:-mx-40px {
    margin-left: -40px;
    margin-right: -40px;
  }

  .xl\:-mx-35px {
    margin-left: -35px;
    margin-right: -35px;
  }

  .xl\:-mx-34px-i {
    margin-left: 34px !important;
    margin-right: 34px !important;
  }

  .xl\:-mx-30px-i {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .xl\:-mx-24px-i {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .xl\:-mx-20px-i {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .xl\:-mx-15px-i {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .xl\:-mx-13px-i {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }

  .xl\:-mx-12px-i {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .xl\:-mx-10px-i {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .xl\:-mx-8px-i {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .xl\:-mx-6px-i {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .xl\:-mx-5px-i {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .xl\:-mx-3px-i {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .xl\:-mx-0px-i {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .xl\:-mx-34px {
    margin-left: -34px;
    margin-right: -34px;
  }

  .xl\:-mx-30px {
    margin-left: -30px;
    margin-right: -30px;
  }

  .xl\:-mx-24px {
    margin-left: -24px;
    margin-right: -24px;
  }

  .xl\:-mx-20px {
    margin-left: -20px;
    margin-right: -20px;
  }

  .xl\:-mx-15px {
    margin-left: -15px;
    margin-right: -15px;
  }

  .xl\:-mx-13px {
    margin-left: -13px;
    margin-right: -13px;
  }

  .xl\:-mx-12px {
    margin-left: -12px;
    margin-right: -12px;
  }

  .xl\:-mx-10px {
    margin-left: -10px;
    margin-right: -10px;
  }

  .xl\:-mx-8px {
    margin-left: -8px;
    margin-right: -8px;
  }

  .xl\:-mx-6px {
    margin-left: -6px;
    margin-right: -6px;
  }

  .xl\:-mx-5px {
    margin-left: -5px;
    margin-right: -5px;
  }

  .xl\:-mx-3px {
    margin-left: -3px;
    margin-right: -3px;
  }

  .xl\:-mx-menu {
    margin-left: -275px;
    margin-right: -275px;
  }

  .xl\:-mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .xl\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .xl\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .xl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .xl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .xl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .xl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .xl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .xl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .xl\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .xl\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .xl\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .xl\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .xl\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .xl\:my-300px {
    margin-top: 300px;
    margin-bottom: 300px;
  }

  .xl\:my-230px {
    margin-top: 230px;
    margin-bottom: 230px;
  }

  .xl\:my-180px {
    margin-top: 180px;
    margin-bottom: 180px;
  }

  .xl\:my-147px {
    margin-top: 147px;
    margin-bottom: 147px;
  }

  .xl\:my-123px {
    margin-top: 123px;
    margin-bottom: 123px;
  }

  .xl\:my-100px {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .xl\:my-80px {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .xl\:my-60px {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .xl\:my-50px {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .xl\:my-40px {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .xl\:my-35px {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .xl\:my-34px-i {
    margin-top: 34px !important;
    margin-bottom: 34px !important;
  }

  .xl\:my-30px-i {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .xl\:my-24px-i {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .xl\:my-20px-i {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .xl\:my-15px-i {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .xl\:my-13px-i {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }

  .xl\:my-12px-i {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .xl\:my-10px-i {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .xl\:my-8px-i {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .xl\:my-6px-i {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .xl\:my-5px-i {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .xl\:my-3px-i {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .xl\:my-0px-i {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .xl\:my-34px {
    margin-top: 34px;
    margin-bottom: 34px;
  }

  .xl\:my-30px {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .xl\:my-24px {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .xl\:my-20px {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .xl\:my-15px {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .xl\:my-13px {
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .xl\:my-12px {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .xl\:my-10px {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .xl\:my-8px {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .xl\:my-6px {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .xl\:my-5px {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .xl\:my-3px {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .xl\:my-menu {
    margin-top: 275px;
    margin-bottom: 275px;
  }

  .xl\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .xl\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }

  .xl\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  .xl\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }

  .xl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .xl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .xl\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .xl\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .xl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .xl\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .xl\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .xl\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .xl\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .xl\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .xl\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .xl\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .xl\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .xl\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .xl\:-my-300px {
    margin-top: -300px;
    margin-bottom: -300px;
  }

  .xl\:-my-230px {
    margin-top: -230px;
    margin-bottom: -230px;
  }

  .xl\:-my-180px {
    margin-top: -180px;
    margin-bottom: -180px;
  }

  .xl\:-my-147px {
    margin-top: -147px;
    margin-bottom: -147px;
  }

  .xl\:-my-123px {
    margin-top: -123px;
    margin-bottom: -123px;
  }

  .xl\:-my-100px {
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .xl\:-my-80px {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  .xl\:-my-60px {
    margin-top: -60px;
    margin-bottom: -60px;
  }

  .xl\:-my-50px {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .xl\:-my-40px {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .xl\:-my-35px {
    margin-top: -35px;
    margin-bottom: -35px;
  }

  .xl\:-my-34px-i {
    margin-top: 34px !important;
    margin-bottom: 34px !important;
  }

  .xl\:-my-30px-i {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .xl\:-my-24px-i {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .xl\:-my-20px-i {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .xl\:-my-15px-i {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .xl\:-my-13px-i {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }

  .xl\:-my-12px-i {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .xl\:-my-10px-i {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .xl\:-my-8px-i {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .xl\:-my-6px-i {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .xl\:-my-5px-i {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .xl\:-my-3px-i {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .xl\:-my-0px-i {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .xl\:-my-34px {
    margin-top: -34px;
    margin-bottom: -34px;
  }

  .xl\:-my-30px {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .xl\:-my-24px {
    margin-top: -24px;
    margin-bottom: -24px;
  }

  .xl\:-my-20px {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .xl\:-my-15px {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .xl\:-my-13px {
    margin-top: -13px;
    margin-bottom: -13px;
  }

  .xl\:-my-12px {
    margin-top: -12px;
    margin-bottom: -12px;
  }

  .xl\:-my-10px {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .xl\:-my-8px {
    margin-top: -8px;
    margin-bottom: -8px;
  }

  .xl\:-my-6px {
    margin-top: -6px;
    margin-bottom: -6px;
  }

  .xl\:-my-5px {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .xl\:-my-3px {
    margin-top: -3px;
    margin-bottom: -3px;
  }

  .xl\:-my-menu {
    margin-top: -275px;
    margin-bottom: -275px;
  }

  .xl\:-my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:mt-1 {
    margin-top: 0.25rem;
  }

  .xl\:mt-2 {
    margin-top: 0.5rem;
  }

  .xl\:mt-3 {
    margin-top: 0.75rem;
  }

  .xl\:mt-4 {
    margin-top: 1rem;
  }

  .xl\:mt-5 {
    margin-top: 1.25rem;
  }

  .xl\:mt-6 {
    margin-top: 1.5rem;
  }

  .xl\:mt-8 {
    margin-top: 2rem;
  }

  .xl\:mt-10 {
    margin-top: 2.5rem;
  }

  .xl\:mt-12 {
    margin-top: 3rem;
  }

  .xl\:mt-16 {
    margin-top: 4rem;
  }

  .xl\:mt-20 {
    margin-top: 5rem;
  }

  .xl\:mt-24 {
    margin-top: 6rem;
  }

  .xl\:mt-32 {
    margin-top: 8rem;
  }

  .xl\:mt-40 {
    margin-top: 10rem;
  }

  .xl\:mt-48 {
    margin-top: 12rem;
  }

  .xl\:mt-56 {
    margin-top: 14rem;
  }

  .xl\:mt-64 {
    margin-top: 16rem;
  }

  .xl\:mt-auto {
    margin-top: auto;
  }

  .xl\:mt-300px {
    margin-top: 300px;
  }

  .xl\:mt-230px {
    margin-top: 230px;
  }

  .xl\:mt-180px {
    margin-top: 180px;
  }

  .xl\:mt-147px {
    margin-top: 147px;
  }

  .xl\:mt-123px {
    margin-top: 123px;
  }

  .xl\:mt-100px {
    margin-top: 100px;
  }

  .xl\:mt-80px {
    margin-top: 80px;
  }

  .xl\:mt-60px {
    margin-top: 60px;
  }

  .xl\:mt-50px {
    margin-top: 50px;
  }

  .xl\:mt-40px {
    margin-top: 40px;
  }

  .xl\:mt-35px {
    margin-top: 35px;
  }

  .xl\:mt-34px-i {
    margin-top: 34px !important;
  }

  .xl\:mt-30px-i {
    margin-top: 30px !important;
  }

  .xl\:mt-24px-i {
    margin-top: 24px !important;
  }

  .xl\:mt-20px-i {
    margin-top: 20px !important;
  }

  .xl\:mt-15px-i {
    margin-top: 15px !important;
  }

  .xl\:mt-13px-i {
    margin-top: 13px !important;
  }

  .xl\:mt-12px-i {
    margin-top: 12px !important;
  }

  .xl\:mt-10px-i {
    margin-top: 10px !important;
  }

  .xl\:mt-8px-i {
    margin-top: 8px !important;
  }

  .xl\:mt-6px-i {
    margin-top: 6px !important;
  }

  .xl\:mt-5px-i {
    margin-top: 5px !important;
  }

  .xl\:mt-3px-i {
    margin-top: 3px !important;
  }

  .xl\:mt-0px-i {
    margin-top: 0px !important;
  }

  .xl\:mt-34px {
    margin-top: 34px;
  }

  .xl\:mt-30px {
    margin-top: 30px;
  }

  .xl\:mt-24px {
    margin-top: 24px;
  }

  .xl\:mt-20px {
    margin-top: 20px;
  }

  .xl\:mt-15px {
    margin-top: 15px;
  }

  .xl\:mt-13px {
    margin-top: 13px;
  }

  .xl\:mt-12px {
    margin-top: 12px;
  }

  .xl\:mt-10px {
    margin-top: 10px;
  }

  .xl\:mt-8px {
    margin-top: 8px;
  }

  .xl\:mt-6px {
    margin-top: 6px;
  }

  .xl\:mt-5px {
    margin-top: 5px;
  }

  .xl\:mt-3px {
    margin-top: 3px;
  }

  .xl\:mt-menu {
    margin-top: 275px;
  }

  .xl\:mt-px {
    margin-top: 1px !important;
  }

  .xl\:-mt-1 {
    margin-top: -0.25rem;
  }

  .xl\:-mt-2 {
    margin-top: -0.5rem;
  }

  .xl\:-mt-3 {
    margin-top: -0.75rem;
  }

  .xl\:-mt-4 {
    margin-top: -1rem;
  }

  .xl\:-mt-5 {
    margin-top: -1.25rem;
  }

  .xl\:-mt-6 {
    margin-top: -1.5rem;
  }

  .xl\:-mt-8 {
    margin-top: -2rem;
  }

  .xl\:-mt-10 {
    margin-top: -2.5rem;
  }

  .xl\:-mt-12 {
    margin-top: -3rem;
  }

  .xl\:-mt-16 {
    margin-top: -4rem;
  }

  .xl\:-mt-20 {
    margin-top: -5rem;
  }

  .xl\:-mt-24 {
    margin-top: -6rem;
  }

  .xl\:-mt-32 {
    margin-top: -8rem;
  }

  .xl\:-mt-40 {
    margin-top: -10rem;
  }

  .xl\:-mt-48 {
    margin-top: -12rem;
  }

  .xl\:-mt-56 {
    margin-top: -14rem;
  }

  .xl\:-mt-64 {
    margin-top: -16rem;
  }

  .xl\:-mt-300px {
    margin-top: -300px;
  }

  .xl\:-mt-230px {
    margin-top: -230px;
  }

  .xl\:-mt-180px {
    margin-top: -180px;
  }

  .xl\:-mt-147px {
    margin-top: -147px;
  }

  .xl\:-mt-123px {
    margin-top: -123px;
  }

  .xl\:-mt-100px {
    margin-top: -100px;
  }

  .xl\:-mt-80px {
    margin-top: -80px;
  }

  .xl\:-mt-60px {
    margin-top: -60px;
  }

  .xl\:-mt-50px {
    margin-top: -50px;
  }

  .xl\:-mt-40px {
    margin-top: -40px;
  }

  .xl\:-mt-35px {
    margin-top: -35px;
  }

  .xl\:-mt-34px-i {
    margin-top: 34px !important;
  }

  .xl\:-mt-30px-i {
    margin-top: 30px !important;
  }

  .xl\:-mt-24px-i {
    margin-top: 24px !important;
  }

  .xl\:-mt-20px-i {
    margin-top: 20px !important;
  }

  .xl\:-mt-15px-i {
    margin-top: 15px !important;
  }

  .xl\:-mt-13px-i {
    margin-top: 13px !important;
  }

  .xl\:-mt-12px-i {
    margin-top: 12px !important;
  }

  .xl\:-mt-10px-i {
    margin-top: 10px !important;
  }

  .xl\:-mt-8px-i {
    margin-top: 8px !important;
  }

  .xl\:-mt-6px-i {
    margin-top: 6px !important;
  }

  .xl\:-mt-5px-i {
    margin-top: 5px !important;
  }

  .xl\:-mt-3px-i {
    margin-top: 3px !important;
  }

  .xl\:-mt-0px-i {
    margin-top: 0px !important;
  }

  .xl\:-mt-34px {
    margin-top: -34px;
  }

  .xl\:-mt-30px {
    margin-top: -30px;
  }

  .xl\:-mt-24px {
    margin-top: -24px;
  }

  .xl\:-mt-20px {
    margin-top: -20px;
  }

  .xl\:-mt-15px {
    margin-top: -15px;
  }

  .xl\:-mt-13px {
    margin-top: -13px;
  }

  .xl\:-mt-12px {
    margin-top: -12px;
  }

  .xl\:-mt-10px {
    margin-top: -10px;
  }

  .xl\:-mt-8px {
    margin-top: -8px;
  }

  .xl\:-mt-6px {
    margin-top: -6px;
  }

  .xl\:-mt-5px {
    margin-top: -5px;
  }

  .xl\:-mt-3px {
    margin-top: -3px;
  }

  .xl\:-mt-menu {
    margin-top: -275px;
  }

  .xl\:-mt-px {
    margin-top: 1px !important;
  }

  .xl\:mr-0 {
    margin-right: 0;
  }

  .xl\:mr-1 {
    margin-right: 0.25rem;
  }

  .xl\:mr-2 {
    margin-right: 0.5rem;
  }

  .xl\:mr-3 {
    margin-right: 0.75rem;
  }

  .xl\:mr-4 {
    margin-right: 1rem;
  }

  .xl\:mr-5 {
    margin-right: 1.25rem;
  }

  .xl\:mr-6 {
    margin-right: 1.5rem;
  }

  .xl\:mr-8 {
    margin-right: 2rem;
  }

  .xl\:mr-10 {
    margin-right: 2.5rem;
  }

  .xl\:mr-12 {
    margin-right: 3rem;
  }

  .xl\:mr-16 {
    margin-right: 4rem;
  }

  .xl\:mr-20 {
    margin-right: 5rem;
  }

  .xl\:mr-24 {
    margin-right: 6rem;
  }

  .xl\:mr-32 {
    margin-right: 8rem;
  }

  .xl\:mr-40 {
    margin-right: 10rem;
  }

  .xl\:mr-48 {
    margin-right: 12rem;
  }

  .xl\:mr-56 {
    margin-right: 14rem;
  }

  .xl\:mr-64 {
    margin-right: 16rem;
  }

  .xl\:mr-auto {
    margin-right: auto;
  }

  .xl\:mr-300px {
    margin-right: 300px;
  }

  .xl\:mr-230px {
    margin-right: 230px;
  }

  .xl\:mr-180px {
    margin-right: 180px;
  }

  .xl\:mr-147px {
    margin-right: 147px;
  }

  .xl\:mr-123px {
    margin-right: 123px;
  }

  .xl\:mr-100px {
    margin-right: 100px;
  }

  .xl\:mr-80px {
    margin-right: 80px;
  }

  .xl\:mr-60px {
    margin-right: 60px;
  }

  .xl\:mr-50px {
    margin-right: 50px;
  }

  .xl\:mr-40px {
    margin-right: 40px;
  }

  .xl\:mr-35px {
    margin-right: 35px;
  }

  .xl\:mr-34px-i {
    margin-right: 34px !important;
  }

  .xl\:mr-30px-i {
    margin-right: 30px !important;
  }

  .xl\:mr-24px-i {
    margin-right: 24px !important;
  }

  .xl\:mr-20px-i {
    margin-right: 20px !important;
  }

  .xl\:mr-15px-i {
    margin-right: 15px !important;
  }

  .xl\:mr-13px-i {
    margin-right: 13px !important;
  }

  .xl\:mr-12px-i {
    margin-right: 12px !important;
  }

  .xl\:mr-10px-i {
    margin-right: 10px !important;
  }

  .xl\:mr-8px-i {
    margin-right: 8px !important;
  }

  .xl\:mr-6px-i {
    margin-right: 6px !important;
  }

  .xl\:mr-5px-i {
    margin-right: 5px !important;
  }

  .xl\:mr-3px-i {
    margin-right: 3px !important;
  }

  .xl\:mr-0px-i {
    margin-right: 0px !important;
  }

  .xl\:mr-34px {
    margin-right: 34px;
  }

  .xl\:mr-30px {
    margin-right: 30px;
  }

  .xl\:mr-24px {
    margin-right: 24px;
  }

  .xl\:mr-20px {
    margin-right: 20px;
  }

  .xl\:mr-15px {
    margin-right: 15px;
  }

  .xl\:mr-13px {
    margin-right: 13px;
  }

  .xl\:mr-12px {
    margin-right: 12px;
  }

  .xl\:mr-10px {
    margin-right: 10px;
  }

  .xl\:mr-8px {
    margin-right: 8px;
  }

  .xl\:mr-6px {
    margin-right: 6px;
  }

  .xl\:mr-5px {
    margin-right: 5px;
  }

  .xl\:mr-3px {
    margin-right: 3px;
  }

  .xl\:mr-menu {
    margin-right: 275px;
  }

  .xl\:mr-px {
    margin-right: 1px !important;
  }

  .xl\:-mr-1 {
    margin-right: -0.25rem;
  }

  .xl\:-mr-2 {
    margin-right: -0.5rem;
  }

  .xl\:-mr-3 {
    margin-right: -0.75rem;
  }

  .xl\:-mr-4 {
    margin-right: -1rem;
  }

  .xl\:-mr-5 {
    margin-right: -1.25rem;
  }

  .xl\:-mr-6 {
    margin-right: -1.5rem;
  }

  .xl\:-mr-8 {
    margin-right: -2rem;
  }

  .xl\:-mr-10 {
    margin-right: -2.5rem;
  }

  .xl\:-mr-12 {
    margin-right: -3rem;
  }

  .xl\:-mr-16 {
    margin-right: -4rem;
  }

  .xl\:-mr-20 {
    margin-right: -5rem;
  }

  .xl\:-mr-24 {
    margin-right: -6rem;
  }

  .xl\:-mr-32 {
    margin-right: -8rem;
  }

  .xl\:-mr-40 {
    margin-right: -10rem;
  }

  .xl\:-mr-48 {
    margin-right: -12rem;
  }

  .xl\:-mr-56 {
    margin-right: -14rem;
  }

  .xl\:-mr-64 {
    margin-right: -16rem;
  }

  .xl\:-mr-300px {
    margin-right: -300px;
  }

  .xl\:-mr-230px {
    margin-right: -230px;
  }

  .xl\:-mr-180px {
    margin-right: -180px;
  }

  .xl\:-mr-147px {
    margin-right: -147px;
  }

  .xl\:-mr-123px {
    margin-right: -123px;
  }

  .xl\:-mr-100px {
    margin-right: -100px;
  }

  .xl\:-mr-80px {
    margin-right: -80px;
  }

  .xl\:-mr-60px {
    margin-right: -60px;
  }

  .xl\:-mr-50px {
    margin-right: -50px;
  }

  .xl\:-mr-40px {
    margin-right: -40px;
  }

  .xl\:-mr-35px {
    margin-right: -35px;
  }

  .xl\:-mr-34px-i {
    margin-right: 34px !important;
  }

  .xl\:-mr-30px-i {
    margin-right: 30px !important;
  }

  .xl\:-mr-24px-i {
    margin-right: 24px !important;
  }

  .xl\:-mr-20px-i {
    margin-right: 20px !important;
  }

  .xl\:-mr-15px-i {
    margin-right: 15px !important;
  }

  .xl\:-mr-13px-i {
    margin-right: 13px !important;
  }

  .xl\:-mr-12px-i {
    margin-right: 12px !important;
  }

  .xl\:-mr-10px-i {
    margin-right: 10px !important;
  }

  .xl\:-mr-8px-i {
    margin-right: 8px !important;
  }

  .xl\:-mr-6px-i {
    margin-right: 6px !important;
  }

  .xl\:-mr-5px-i {
    margin-right: 5px !important;
  }

  .xl\:-mr-3px-i {
    margin-right: 3px !important;
  }

  .xl\:-mr-0px-i {
    margin-right: 0px !important;
  }

  .xl\:-mr-34px {
    margin-right: -34px;
  }

  .xl\:-mr-30px {
    margin-right: -30px;
  }

  .xl\:-mr-24px {
    margin-right: -24px;
  }

  .xl\:-mr-20px {
    margin-right: -20px;
  }

  .xl\:-mr-15px {
    margin-right: -15px;
  }

  .xl\:-mr-13px {
    margin-right: -13px;
  }

  .xl\:-mr-12px {
    margin-right: -12px;
  }

  .xl\:-mr-10px {
    margin-right: -10px;
  }

  .xl\:-mr-8px {
    margin-right: -8px;
  }

  .xl\:-mr-6px {
    margin-right: -6px;
  }

  .xl\:-mr-5px {
    margin-right: -5px;
  }

  .xl\:-mr-3px {
    margin-right: -3px;
  }

  .xl\:-mr-menu {
    margin-right: -275px;
  }

  .xl\:-mr-px {
    margin-right: 1px !important;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:mb-1 {
    margin-bottom: 0.25rem;
  }

  .xl\:mb-2 {
    margin-bottom: 0.5rem;
  }

  .xl\:mb-3 {
    margin-bottom: 0.75rem;
  }

  .xl\:mb-4 {
    margin-bottom: 1rem;
  }

  .xl\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .xl\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .xl\:mb-8 {
    margin-bottom: 2rem;
  }

  .xl\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .xl\:mb-12 {
    margin-bottom: 3rem;
  }

  .xl\:mb-16 {
    margin-bottom: 4rem;
  }

  .xl\:mb-20 {
    margin-bottom: 5rem;
  }

  .xl\:mb-24 {
    margin-bottom: 6rem;
  }

  .xl\:mb-32 {
    margin-bottom: 8rem;
  }

  .xl\:mb-40 {
    margin-bottom: 10rem;
  }

  .xl\:mb-48 {
    margin-bottom: 12rem;
  }

  .xl\:mb-56 {
    margin-bottom: 14rem;
  }

  .xl\:mb-64 {
    margin-bottom: 16rem;
  }

  .xl\:mb-auto {
    margin-bottom: auto;
  }

  .xl\:mb-300px {
    margin-bottom: 300px;
  }

  .xl\:mb-230px {
    margin-bottom: 230px;
  }

  .xl\:mb-180px {
    margin-bottom: 180px;
  }

  .xl\:mb-147px {
    margin-bottom: 147px;
  }

  .xl\:mb-123px {
    margin-bottom: 123px;
  }

  .xl\:mb-100px {
    margin-bottom: 100px;
  }

  .xl\:mb-80px {
    margin-bottom: 80px;
  }

  .xl\:mb-60px {
    margin-bottom: 60px;
  }

  .xl\:mb-50px {
    margin-bottom: 50px;
  }

  .xl\:mb-40px {
    margin-bottom: 40px;
  }

  .xl\:mb-35px {
    margin-bottom: 35px;
  }

  .xl\:mb-34px-i {
    margin-bottom: 34px !important;
  }

  .xl\:mb-30px-i {
    margin-bottom: 30px !important;
  }

  .xl\:mb-24px-i {
    margin-bottom: 24px !important;
  }

  .xl\:mb-20px-i {
    margin-bottom: 20px !important;
  }

  .xl\:mb-15px-i {
    margin-bottom: 15px !important;
  }

  .xl\:mb-13px-i {
    margin-bottom: 13px !important;
  }

  .xl\:mb-12px-i {
    margin-bottom: 12px !important;
  }

  .xl\:mb-10px-i {
    margin-bottom: 10px !important;
  }

  .xl\:mb-8px-i {
    margin-bottom: 8px !important;
  }

  .xl\:mb-6px-i {
    margin-bottom: 6px !important;
  }

  .xl\:mb-5px-i {
    margin-bottom: 5px !important;
  }

  .xl\:mb-3px-i {
    margin-bottom: 3px !important;
  }

  .xl\:mb-0px-i {
    margin-bottom: 0px !important;
  }

  .xl\:mb-34px {
    margin-bottom: 34px;
  }

  .xl\:mb-30px {
    margin-bottom: 30px;
  }

  .xl\:mb-24px {
    margin-bottom: 24px;
  }

  .xl\:mb-20px {
    margin-bottom: 20px;
  }

  .xl\:mb-15px {
    margin-bottom: 15px;
  }

  .xl\:mb-13px {
    margin-bottom: 13px;
  }

  .xl\:mb-12px {
    margin-bottom: 12px;
  }

  .xl\:mb-10px {
    margin-bottom: 10px;
  }

  .xl\:mb-8px {
    margin-bottom: 8px;
  }

  .xl\:mb-6px {
    margin-bottom: 6px;
  }

  .xl\:mb-5px {
    margin-bottom: 5px;
  }

  .xl\:mb-3px {
    margin-bottom: 3px;
  }

  .xl\:mb-menu {
    margin-bottom: 275px;
  }

  .xl\:mb-px {
    margin-bottom: 1px !important;
  }

  .xl\:-mb-1 {
    margin-bottom: -0.25rem;
  }

  .xl\:-mb-2 {
    margin-bottom: -0.5rem;
  }

  .xl\:-mb-3 {
    margin-bottom: -0.75rem;
  }

  .xl\:-mb-4 {
    margin-bottom: -1rem;
  }

  .xl\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .xl\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .xl\:-mb-8 {
    margin-bottom: -2rem;
  }

  .xl\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .xl\:-mb-12 {
    margin-bottom: -3rem;
  }

  .xl\:-mb-16 {
    margin-bottom: -4rem;
  }

  .xl\:-mb-20 {
    margin-bottom: -5rem;
  }

  .xl\:-mb-24 {
    margin-bottom: -6rem;
  }

  .xl\:-mb-32 {
    margin-bottom: -8rem;
  }

  .xl\:-mb-40 {
    margin-bottom: -10rem;
  }

  .xl\:-mb-48 {
    margin-bottom: -12rem;
  }

  .xl\:-mb-56 {
    margin-bottom: -14rem;
  }

  .xl\:-mb-64 {
    margin-bottom: -16rem;
  }

  .xl\:-mb-300px {
    margin-bottom: -300px;
  }

  .xl\:-mb-230px {
    margin-bottom: -230px;
  }

  .xl\:-mb-180px {
    margin-bottom: -180px;
  }

  .xl\:-mb-147px {
    margin-bottom: -147px;
  }

  .xl\:-mb-123px {
    margin-bottom: -123px;
  }

  .xl\:-mb-100px {
    margin-bottom: -100px;
  }

  .xl\:-mb-80px {
    margin-bottom: -80px;
  }

  .xl\:-mb-60px {
    margin-bottom: -60px;
  }

  .xl\:-mb-50px {
    margin-bottom: -50px;
  }

  .xl\:-mb-40px {
    margin-bottom: -40px;
  }

  .xl\:-mb-35px {
    margin-bottom: -35px;
  }

  .xl\:-mb-34px-i {
    margin-bottom: 34px !important;
  }

  .xl\:-mb-30px-i {
    margin-bottom: 30px !important;
  }

  .xl\:-mb-24px-i {
    margin-bottom: 24px !important;
  }

  .xl\:-mb-20px-i {
    margin-bottom: 20px !important;
  }

  .xl\:-mb-15px-i {
    margin-bottom: 15px !important;
  }

  .xl\:-mb-13px-i {
    margin-bottom: 13px !important;
  }

  .xl\:-mb-12px-i {
    margin-bottom: 12px !important;
  }

  .xl\:-mb-10px-i {
    margin-bottom: 10px !important;
  }

  .xl\:-mb-8px-i {
    margin-bottom: 8px !important;
  }

  .xl\:-mb-6px-i {
    margin-bottom: 6px !important;
  }

  .xl\:-mb-5px-i {
    margin-bottom: 5px !important;
  }

  .xl\:-mb-3px-i {
    margin-bottom: 3px !important;
  }

  .xl\:-mb-0px-i {
    margin-bottom: 0px !important;
  }

  .xl\:-mb-34px {
    margin-bottom: -34px;
  }

  .xl\:-mb-30px {
    margin-bottom: -30px;
  }

  .xl\:-mb-24px {
    margin-bottom: -24px;
  }

  .xl\:-mb-20px {
    margin-bottom: -20px;
  }

  .xl\:-mb-15px {
    margin-bottom: -15px;
  }

  .xl\:-mb-13px {
    margin-bottom: -13px;
  }

  .xl\:-mb-12px {
    margin-bottom: -12px;
  }

  .xl\:-mb-10px {
    margin-bottom: -10px;
  }

  .xl\:-mb-8px {
    margin-bottom: -8px;
  }

  .xl\:-mb-6px {
    margin-bottom: -6px;
  }

  .xl\:-mb-5px {
    margin-bottom: -5px;
  }

  .xl\:-mb-3px {
    margin-bottom: -3px;
  }

  .xl\:-mb-menu {
    margin-bottom: -275px;
  }

  .xl\:-mb-px {
    margin-bottom: 1px !important;
  }

  .xl\:ml-0 {
    margin-left: 0;
  }

  .xl\:ml-1 {
    margin-left: 0.25rem;
  }

  .xl\:ml-2 {
    margin-left: 0.5rem;
  }

  .xl\:ml-3 {
    margin-left: 0.75rem;
  }

  .xl\:ml-4 {
    margin-left: 1rem;
  }

  .xl\:ml-5 {
    margin-left: 1.25rem;
  }

  .xl\:ml-6 {
    margin-left: 1.5rem;
  }

  .xl\:ml-8 {
    margin-left: 2rem;
  }

  .xl\:ml-10 {
    margin-left: 2.5rem;
  }

  .xl\:ml-12 {
    margin-left: 3rem;
  }

  .xl\:ml-16 {
    margin-left: 4rem;
  }

  .xl\:ml-20 {
    margin-left: 5rem;
  }

  .xl\:ml-24 {
    margin-left: 6rem;
  }

  .xl\:ml-32 {
    margin-left: 8rem;
  }

  .xl\:ml-40 {
    margin-left: 10rem;
  }

  .xl\:ml-48 {
    margin-left: 12rem;
  }

  .xl\:ml-56 {
    margin-left: 14rem;
  }

  .xl\:ml-64 {
    margin-left: 16rem;
  }

  .xl\:ml-auto {
    margin-left: auto;
  }

  .xl\:ml-300px {
    margin-left: 300px;
  }

  .xl\:ml-230px {
    margin-left: 230px;
  }

  .xl\:ml-180px {
    margin-left: 180px;
  }

  .xl\:ml-147px {
    margin-left: 147px;
  }

  .xl\:ml-123px {
    margin-left: 123px;
  }

  .xl\:ml-100px {
    margin-left: 100px;
  }

  .xl\:ml-80px {
    margin-left: 80px;
  }

  .xl\:ml-60px {
    margin-left: 60px;
  }

  .xl\:ml-50px {
    margin-left: 50px;
  }

  .xl\:ml-40px {
    margin-left: 40px;
  }

  .xl\:ml-35px {
    margin-left: 35px;
  }

  .xl\:ml-34px-i {
    margin-left: 34px !important;
  }

  .xl\:ml-30px-i {
    margin-left: 30px !important;
  }

  .xl\:ml-24px-i {
    margin-left: 24px !important;
  }

  .xl\:ml-20px-i {
    margin-left: 20px !important;
  }

  .xl\:ml-15px-i {
    margin-left: 15px !important;
  }

  .xl\:ml-13px-i {
    margin-left: 13px !important;
  }

  .xl\:ml-12px-i {
    margin-left: 12px !important;
  }

  .xl\:ml-10px-i {
    margin-left: 10px !important;
  }

  .xl\:ml-8px-i {
    margin-left: 8px !important;
  }

  .xl\:ml-6px-i {
    margin-left: 6px !important;
  }

  .xl\:ml-5px-i {
    margin-left: 5px !important;
  }

  .xl\:ml-3px-i {
    margin-left: 3px !important;
  }

  .xl\:ml-0px-i {
    margin-left: 0px !important;
  }

  .xl\:ml-34px {
    margin-left: 34px;
  }

  .xl\:ml-30px {
    margin-left: 30px;
  }

  .xl\:ml-24px {
    margin-left: 24px;
  }

  .xl\:ml-20px {
    margin-left: 20px;
  }

  .xl\:ml-15px {
    margin-left: 15px;
  }

  .xl\:ml-13px {
    margin-left: 13px;
  }

  .xl\:ml-12px {
    margin-left: 12px;
  }

  .xl\:ml-10px {
    margin-left: 10px;
  }

  .xl\:ml-8px {
    margin-left: 8px;
  }

  .xl\:ml-6px {
    margin-left: 6px;
  }

  .xl\:ml-5px {
    margin-left: 5px;
  }

  .xl\:ml-3px {
    margin-left: 3px;
  }

  .xl\:ml-menu {
    margin-left: 275px;
  }

  .xl\:ml-px {
    margin-left: 1px !important;
  }

  .xl\:-ml-1 {
    margin-left: -0.25rem;
  }

  .xl\:-ml-2 {
    margin-left: -0.5rem;
  }

  .xl\:-ml-3 {
    margin-left: -0.75rem;
  }

  .xl\:-ml-4 {
    margin-left: -1rem;
  }

  .xl\:-ml-5 {
    margin-left: -1.25rem;
  }

  .xl\:-ml-6 {
    margin-left: -1.5rem;
  }

  .xl\:-ml-8 {
    margin-left: -2rem;
  }

  .xl\:-ml-10 {
    margin-left: -2.5rem;
  }

  .xl\:-ml-12 {
    margin-left: -3rem;
  }

  .xl\:-ml-16 {
    margin-left: -4rem;
  }

  .xl\:-ml-20 {
    margin-left: -5rem;
  }

  .xl\:-ml-24 {
    margin-left: -6rem;
  }

  .xl\:-ml-32 {
    margin-left: -8rem;
  }

  .xl\:-ml-40 {
    margin-left: -10rem;
  }

  .xl\:-ml-48 {
    margin-left: -12rem;
  }

  .xl\:-ml-56 {
    margin-left: -14rem;
  }

  .xl\:-ml-64 {
    margin-left: -16rem;
  }

  .xl\:-ml-300px {
    margin-left: -300px;
  }

  .xl\:-ml-230px {
    margin-left: -230px;
  }

  .xl\:-ml-180px {
    margin-left: -180px;
  }

  .xl\:-ml-147px {
    margin-left: -147px;
  }

  .xl\:-ml-123px {
    margin-left: -123px;
  }

  .xl\:-ml-100px {
    margin-left: -100px;
  }

  .xl\:-ml-80px {
    margin-left: -80px;
  }

  .xl\:-ml-60px {
    margin-left: -60px;
  }

  .xl\:-ml-50px {
    margin-left: -50px;
  }

  .xl\:-ml-40px {
    margin-left: -40px;
  }

  .xl\:-ml-35px {
    margin-left: -35px;
  }

  .xl\:-ml-34px-i {
    margin-left: 34px !important;
  }

  .xl\:-ml-30px-i {
    margin-left: 30px !important;
  }

  .xl\:-ml-24px-i {
    margin-left: 24px !important;
  }

  .xl\:-ml-20px-i {
    margin-left: 20px !important;
  }

  .xl\:-ml-15px-i {
    margin-left: 15px !important;
  }

  .xl\:-ml-13px-i {
    margin-left: 13px !important;
  }

  .xl\:-ml-12px-i {
    margin-left: 12px !important;
  }

  .xl\:-ml-10px-i {
    margin-left: 10px !important;
  }

  .xl\:-ml-8px-i {
    margin-left: 8px !important;
  }

  .xl\:-ml-6px-i {
    margin-left: 6px !important;
  }

  .xl\:-ml-5px-i {
    margin-left: 5px !important;
  }

  .xl\:-ml-3px-i {
    margin-left: 3px !important;
  }

  .xl\:-ml-0px-i {
    margin-left: 0px !important;
  }

  .xl\:-ml-34px {
    margin-left: -34px;
  }

  .xl\:-ml-30px {
    margin-left: -30px;
  }

  .xl\:-ml-24px {
    margin-left: -24px;
  }

  .xl\:-ml-20px {
    margin-left: -20px;
  }

  .xl\:-ml-15px {
    margin-left: -15px;
  }

  .xl\:-ml-13px {
    margin-left: -13px;
  }

  .xl\:-ml-12px {
    margin-left: -12px;
  }

  .xl\:-ml-10px {
    margin-left: -10px;
  }

  .xl\:-ml-8px {
    margin-left: -8px;
  }

  .xl\:-ml-6px {
    margin-left: -6px;
  }

  .xl\:-ml-5px {
    margin-left: -5px;
  }

  .xl\:-ml-3px {
    margin-left: -3px;
  }

  .xl\:-ml-menu {
    margin-left: -275px;
  }

  .xl\:-ml-px {
    margin-left: 1px !important;
  }

  .xl\:box-border {
    box-sizing: border-box;
  }

  .xl\:box-content {
    box-sizing: content-box;
  }

  .xl\:block {
    display: block;
  }

  .xl\:inline-block {
    display: inline-block;
  }

  .xl\:inline {
    display: inline;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:inline-flex {
    display: inline-flex;
  }

  .xl\:table {
    display: table;
  }

  .xl\:inline-table {
    display: inline-table;
  }

  .xl\:table-caption {
    display: table-caption;
  }

  .xl\:table-cell {
    display: table-cell;
  }

  .xl\:table-column {
    display: table-column;
  }

  .xl\:table-column-group {
    display: table-column-group;
  }

  .xl\:table-footer-group {
    display: table-footer-group;
  }

  .xl\:table-header-group {
    display: table-header-group;
  }

  .xl\:table-row-group {
    display: table-row-group;
  }

  .xl\:table-row {
    display: table-row;
  }

  .xl\:flow-root {
    display: flow-root;
  }

  .xl\:grid {
    display: grid;
  }

  .xl\:inline-grid {
    display: inline-grid;
  }

  .xl\:contents {
    display: contents;
  }

  .xl\:list-item {
    display: list-item;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:h-0 {
    height: 0;
  }

  .xl\:h-1 {
    height: 0.25rem;
  }

  .xl\:h-2 {
    height: 0.5rem;
  }

  .xl\:h-3 {
    height: 0.75rem;
  }

  .xl\:h-4 {
    height: 1rem;
  }

  .xl\:h-5 {
    height: 1.25rem;
  }

  .xl\:h-6 {
    height: 1.5rem;
  }

  .xl\:h-8 {
    height: 2rem;
  }

  .xl\:h-10 {
    height: 2.5rem;
  }

  .xl\:h-12 {
    height: 3rem;
  }

  .xl\:h-16 {
    height: 4rem;
  }

  .xl\:h-20 {
    height: 5rem;
  }

  .xl\:h-24 {
    height: 6rem;
  }

  .xl\:h-32 {
    height: 8rem;
  }

  .xl\:h-40 {
    height: 10rem;
  }

  .xl\:h-48 {
    height: 12rem;
  }

  .xl\:h-56 {
    height: 14rem;
  }

  .xl\:h-64 {
    height: 16rem;
  }

  .xl\:h-auto {
    height: auto;
  }

  .xl\:h-300px {
    height: 300px;
  }

  .xl\:h-230px {
    height: 230px;
  }

  .xl\:h-180px {
    height: 180px;
  }

  .xl\:h-147px {
    height: 147px;
  }

  .xl\:h-123px {
    height: 123px;
  }

  .xl\:h-100px {
    height: 100px;
  }

  .xl\:h-80px {
    height: 80px;
  }

  .xl\:h-60px {
    height: 60px;
  }

  .xl\:h-50px {
    height: 50px;
  }

  .xl\:h-40px {
    height: 40px;
  }

  .xl\:h-35px {
    height: 35px;
  }

  .xl\:h-34px-i {
    height: 34px !important;
  }

  .xl\:h-30px-i {
    height: 30px !important;
  }

  .xl\:h-24px-i {
    height: 24px !important;
  }

  .xl\:h-20px-i {
    height: 20px !important;
  }

  .xl\:h-15px-i {
    height: 15px !important;
  }

  .xl\:h-13px-i {
    height: 13px !important;
  }

  .xl\:h-12px-i {
    height: 12px !important;
  }

  .xl\:h-10px-i {
    height: 10px !important;
  }

  .xl\:h-8px-i {
    height: 8px !important;
  }

  .xl\:h-6px-i {
    height: 6px !important;
  }

  .xl\:h-5px-i {
    height: 5px !important;
  }

  .xl\:h-3px-i {
    height: 3px !important;
  }

  .xl\:h-0px-i {
    height: 0px !important;
  }

  .xl\:h-34px {
    height: 34px;
  }

  .xl\:h-30px {
    height: 30px;
  }

  .xl\:h-24px {
    height: 24px;
  }

  .xl\:h-20px {
    height: 20px;
  }

  .xl\:h-15px {
    height: 15px;
  }

  .xl\:h-13px {
    height: 13px;
  }

  .xl\:h-12px {
    height: 12px;
  }

  .xl\:h-10px {
    height: 10px;
  }

  .xl\:h-8px {
    height: 8px;
  }

  .xl\:h-6px {
    height: 6px;
  }

  .xl\:h-5px {
    height: 5px;
  }

  .xl\:h-3px {
    height: 3px;
  }

  .xl\:h-menu {
    height: 275px;
  }

  .xl\:h-px {
    height: 1px !important;
  }

  .xl\:h-full {
    height: 100%;
  }

  .xl\:h-screen {
    height: 100vh;
  }

  .xl\:max-h-full {
    max-height: 100%;
  }

  .xl\:max-h-screen {
    max-height: 100vh;
  }

  .xl\:min-h-0 {
    min-height: 0;
  }

  .xl\:min-h-full {
    min-height: 100%;
  }

  .xl\:min-h-screen {
    min-height: 100vh;
  }

  .xl\:w-0 {
    width: 0;
  }

  .xl\:w-1 {
    width: 0.25rem;
  }

  .xl\:w-2 {
    width: 0.5rem;
  }

  .xl\:w-3 {
    width: 0.75rem;
  }

  .xl\:w-4 {
    width: 1rem;
  }

  .xl\:w-5 {
    width: 1.25rem;
  }

  .xl\:w-6 {
    width: 1.5rem;
  }

  .xl\:w-8 {
    width: 2rem;
  }

  .xl\:w-10 {
    width: 2.5rem;
  }

  .xl\:w-12 {
    width: 3rem;
  }

  .xl\:w-16 {
    width: 4rem;
  }

  .xl\:w-20 {
    width: 5rem;
  }

  .xl\:w-24 {
    width: 6rem;
  }

  .xl\:w-32 {
    width: 8rem;
  }

  .xl\:w-40 {
    width: 10rem;
  }

  .xl\:w-48 {
    width: 12rem;
  }

  .xl\:w-56 {
    width: 14rem;
  }

  .xl\:w-64 {
    width: 16rem;
  }

  .xl\:w-auto {
    width: auto !important;
  }

  .xl\:w-300px {
    width: 300px;
  }

  .xl\:w-230px {
    width: 230px;
  }

  .xl\:w-180px {
    width: 180px;
  }

  .xl\:w-147px {
    width: 147px;
  }

  .xl\:w-123px {
    width: 123px;
  }

  .xl\:w-100px {
    width: 100px;
  }

  .xl\:w-80px {
    width: 80px;
  }

  .xl\:w-60px {
    width: 60px;
  }

  .xl\:w-50px {
    width: 50px;
  }

  .xl\:w-40px {
    width: 40px;
  }

  .xl\:w-35px {
    width: 35px;
  }

  .xl\:w-34px-i {
    width: 34px !important;
  }

  .xl\:w-30px-i {
    width: 30px !important;
  }

  .xl\:w-24px-i {
    width: 24px !important;
  }

  .xl\:w-20px-i {
    width: 20px !important;
  }

  .xl\:w-15px-i {
    width: 15px !important;
  }

  .xl\:w-13px-i {
    width: 13px !important;
  }

  .xl\:w-12px-i {
    width: 12px !important;
  }

  .xl\:w-10px-i {
    width: 10px !important;
  }

  .xl\:w-8px-i {
    width: 8px !important;
  }

  .xl\:w-6px-i {
    width: 6px !important;
  }

  .xl\:w-5px-i {
    width: 5px !important;
  }

  .xl\:w-3px-i {
    width: 3px !important;
  }

  .xl\:w-0px-i {
    width: 0px !important;
  }

  .xl\:w-34px {
    width: 34px;
  }

  .xl\:w-30px {
    width: 30px;
  }

  .xl\:w-24px {
    width: 24px;
  }

  .xl\:w-20px {
    width: 20px;
  }

  .xl\:w-15px {
    width: 15px;
  }

  .xl\:w-13px {
    width: 13px;
  }

  .xl\:w-12px {
    width: 12px;
  }

  .xl\:w-10px {
    width: 10px;
  }

  .xl\:w-8px {
    width: 8px;
  }

  .xl\:w-6px {
    width: 6px;
  }

  .xl\:w-5px {
    width: 5px;
  }

  .xl\:w-3px {
    width: 3px;
  }

  .xl\:w-menu {
    width: 275px;
  }

  .xl\:w-px {
    width: 1px !important;
  }

  .xl\:w-1\/2 {
    width: 50% !important;
  }

  .xl\:w-1\/3 {
    width: 33.333333% !important;
  }

  .xl\:w-2\/3 {
    width: 66.666667% !important;
  }

  .xl\:w-1\/4 {
    width: 25% !important;
  }

  .xl\:w-2\/4 {
    width: 50% !important;
  }

  .xl\:w-3\/4 {
    width: 75% !important;
  }

  .xl\:w-1\/5 {
    width: 20% !important;
  }

  .xl\:w-2\/5 {
    width: 40% !important;
  }

  .xl\:w-3\/5 {
    width: 60% !important;
  }

  .xl\:w-4\/5 {
    width: 80% !important;
  }

  .xl\:w-1\/6 {
    width: 16.666667% !important;
  }

  .xl\:w-2\/6 {
    width: 33.333333% !important;
  }

  .xl\:w-3\/6 {
    width: 50% !important;
  }

  .xl\:w-4\/6 {
    width: 66.666667% !important;
  }

  .xl\:w-5\/6 {
    width: 83.333333% !important;
  }

  .xl\:w-1\/12 {
    width: 8.333333% !important;
  }

  .xl\:w-2\/12 {
    width: 16.666667% !important;
  }

  .xl\:w-3\/12 {
    width: 25% !important;
  }

  .xl\:w-4\/12 {
    width: 33.333333% !important;
  }

  .xl\:w-5\/12 {
    width: 41.666667% !important;
  }

  .xl\:w-6\/12 {
    width: 50% !important;
  }

  .xl\:w-7\/12 {
    width: 58.333333% !important;
  }

  .xl\:w-8\/12 {
    width: 66.666667% !important;
  }

  .xl\:w-9\/12 {
    width: 75% !important;
  }

  .xl\:w-10\/12 {
    width: 83.333333% !important;
  }

  .xl\:w-11\/12 {
    width: 91.666667% !important;
  }

  .xl\:w-full {
    width: 100% !important;
  }

  .xl\:w-screen {
    width: 100vw !important;
  }

  .xl\:min-w-0 {
    min-width: 0;
  }

  .xl\:min-w-full {
    min-width: 100%;
  }

  .xl\:max-w-1420px {
    max-width: 1420px;
  }

  .xl\:max-w-1004px {
    max-width: 1004px;
  }

  .xl\:max-w-920px {
    max-width: 920px;
  }

  .xl\:max-w-645px {
    max-width: 645px;
  }

  .xl\:max-w-menu {
    max-width: 275px;
  }

  .xl\:max-w-780px {
    max-width: 780px !important;
  }

  .xl\:max-w-240px {
    max-width: 240px !important;
  }

  .xl\:max-w-100px {
    max-width: 100px !important;
  }

  .xl\:max-w-285px {
    max-width: 285px !important;
  }

  .xl\:max-w-100pct {
    max-width: 100% !important;
  }

  .xl\:max-w-none {
    max-width: none !important;
  }

  .xl\:max-w-xs {
    max-width: 20rem;
  }

  .xl\:max-w-sm {
    max-width: 24rem;
  }

  .xl\:max-w-md {
    max-width: 28rem;
  }

  .xl\:max-w-lg {
    max-width: 32rem;
  }

  .xl\:max-w-xl {
    max-width: 36rem;
  }

  .xl\:max-w-2xl {
    max-width: 42rem;
  }

  .xl\:max-w-3xl {
    max-width: 48rem;
  }

  .xl\:max-w-4xl {
    max-width: 56rem;
  }

  .xl\:max-w-5xl {
    max-width: 64rem;
  }

  .xl\:max-w-6xl {
    max-width: 72rem;
  }

  .xl\:max-w-full {
    max-width: 100% !important;
  }

  .xl\:max-w-screen-xs {
    max-width: 1px;
  }

  .xl\:max-w-screen-sm {
    max-width: 768px;
  }

  .xl\:max-w-screen-md {
    max-width: 1024px;
  }

  .xl\:max-w-screen-lg {
    max-width: 1440px;
  }

  .xl\:max-w-screen-xl {
    max-width: 1920px;
  }

  .xl\:flex-1 {
    flex: 1 1 0%;
  }

  .xl\:flex-auto {
    flex: 1 1 auto;
  }

  .xl\:flex-initial {
    flex: 0 1 auto;
  }

  .xl\:flex-none {
    flex: none;
  }

  .xl\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .xl\:flex-shrink {
    flex-shrink: 1;
  }

  .xl\:flex-grow-0 {
    flex-grow: 0;
  }

  .xl\:flex-grow {
    flex-grow: 1;
  }

  .xl\:table-auto {
    table-layout: auto;
  }

  .xl\:table-fixed {
    table-layout: fixed;
  }

  .xl\:border-collapse {
    border-collapse: collapse;
  }

  .xl\:border-separate {
    border-collapse: separate;
  }

  .xl\:origin-center {
    transform-origin: center;
  }

  .xl\:origin-top {
    transform-origin: top;
  }

  .xl\:origin-top-right {
    transform-origin: top right;
  }

  .xl\:origin-right {
    transform-origin: right;
  }

  .xl\:origin-bottom-right {
    transform-origin: bottom right;
  }

  .xl\:origin-bottom {
    transform-origin: bottom;
  }

  .xl\:origin-bottom-left {
    transform-origin: bottom left;
  }

  .xl\:origin-left {
    transform-origin: left;
  }

  .xl\:origin-top-left {
    transform-origin: top left;
  }

  .xl\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:transform-none {
    transform: none;
  }

  .xl\:translate-x-0 {
    --tw-translate-x: 0;
  }

  .xl\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }

  .xl\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }

  .xl\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }

  .xl\:translate-x-4 {
    --tw-translate-x: 1rem;
  }

  .xl\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }

  .xl\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }

  .xl\:translate-x-8 {
    --tw-translate-x: 2rem;
  }

  .xl\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }

  .xl\:translate-x-12 {
    --tw-translate-x: 3rem;
  }

  .xl\:translate-x-16 {
    --tw-translate-x: 4rem;
  }

  .xl\:translate-x-20 {
    --tw-translate-x: 5rem;
  }

  .xl\:translate-x-24 {
    --tw-translate-x: 6rem;
  }

  .xl\:translate-x-32 {
    --tw-translate-x: 8rem;
  }

  .xl\:translate-x-40 {
    --tw-translate-x: 10rem;
  }

  .xl\:translate-x-48 {
    --tw-translate-x: 12rem;
  }

  .xl\:translate-x-56 {
    --tw-translate-x: 14rem;
  }

  .xl\:translate-x-64 {
    --tw-translate-x: 16rem;
  }

  .xl\:translate-x-300px {
    --tw-translate-x: 300px;
  }

  .xl\:translate-x-230px {
    --tw-translate-x: 230px;
  }

  .xl\:translate-x-180px {
    --tw-translate-x: 180px;
  }

  .xl\:translate-x-147px {
    --tw-translate-x: 147px;
  }

  .xl\:translate-x-123px {
    --tw-translate-x: 123px;
  }

  .xl\:translate-x-100px {
    --tw-translate-x: 100px;
  }

  .xl\:translate-x-80px {
    --tw-translate-x: 80px;
  }

  .xl\:translate-x-60px {
    --tw-translate-x: 60px;
  }

  .xl\:translate-x-50px {
    --tw-translate-x: 50px;
  }

  .xl\:translate-x-40px {
    --tw-translate-x: 40px;
  }

  .xl\:translate-x-35px {
    --tw-translate-x: 35px;
  }

  .xl\:translate-x-34px-i {
    --tw-translate-x: 34px !important;
  }

  .xl\:translate-x-30px-i {
    --tw-translate-x: 30px !important;
  }

  .xl\:translate-x-24px-i {
    --tw-translate-x: 24px !important;
  }

  .xl\:translate-x-20px-i {
    --tw-translate-x: 20px !important;
  }

  .xl\:translate-x-15px-i {
    --tw-translate-x: 15px !important;
  }

  .xl\:translate-x-13px-i {
    --tw-translate-x: 13px !important;
  }

  .xl\:translate-x-12px-i {
    --tw-translate-x: 12px !important;
  }

  .xl\:translate-x-10px-i {
    --tw-translate-x: 10px !important;
  }

  .xl\:translate-x-8px-i {
    --tw-translate-x: 8px !important;
  }

  .xl\:translate-x-6px-i {
    --tw-translate-x: 6px !important;
  }

  .xl\:translate-x-5px-i {
    --tw-translate-x: 5px !important;
  }

  .xl\:translate-x-3px-i {
    --tw-translate-x: 3px !important;
  }

  .xl\:translate-x-0px-i {
    --tw-translate-x: 0px !important;
  }

  .xl\:translate-x-34px {
    --tw-translate-x: 34px;
  }

  .xl\:translate-x-30px {
    --tw-translate-x: 30px;
  }

  .xl\:translate-x-24px {
    --tw-translate-x: 24px;
  }

  .xl\:translate-x-20px {
    --tw-translate-x: 20px;
  }

  .xl\:translate-x-15px {
    --tw-translate-x: 15px;
  }

  .xl\:translate-x-13px {
    --tw-translate-x: 13px;
  }

  .xl\:translate-x-12px {
    --tw-translate-x: 12px;
  }

  .xl\:translate-x-10px {
    --tw-translate-x: 10px;
  }

  .xl\:translate-x-8px {
    --tw-translate-x: 8px;
  }

  .xl\:translate-x-6px {
    --tw-translate-x: 6px;
  }

  .xl\:translate-x-5px {
    --tw-translate-x: 5px;
  }

  .xl\:translate-x-3px {
    --tw-translate-x: 3px;
  }

  .xl\:translate-x-menu {
    --tw-translate-x: 275px;
  }

  .xl\:translate-x-px {
    --tw-translate-x: 1px !important;
  }

  .xl\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }

  .xl\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }

  .xl\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }

  .xl\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }

  .xl\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }

  .xl\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }

  .xl\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }

  .xl\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }

  .xl\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }

  .xl\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }

  .xl\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }

  .xl\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }

  .xl\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }

  .xl\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }

  .xl\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }

  .xl\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }

  .xl\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }

  .xl\:-translate-x-300px {
    --tw-translate-x: -300px;
  }

  .xl\:-translate-x-230px {
    --tw-translate-x: -230px;
  }

  .xl\:-translate-x-180px {
    --tw-translate-x: -180px;
  }

  .xl\:-translate-x-147px {
    --tw-translate-x: -147px;
  }

  .xl\:-translate-x-123px {
    --tw-translate-x: -123px;
  }

  .xl\:-translate-x-100px {
    --tw-translate-x: -100px;
  }

  .xl\:-translate-x-80px {
    --tw-translate-x: -80px;
  }

  .xl\:-translate-x-60px {
    --tw-translate-x: -60px;
  }

  .xl\:-translate-x-50px {
    --tw-translate-x: -50px;
  }

  .xl\:-translate-x-40px {
    --tw-translate-x: -40px;
  }

  .xl\:-translate-x-35px {
    --tw-translate-x: -35px;
  }

  .xl\:-translate-x-34px-i {
    --tw-translate-x: 34px !important;
  }

  .xl\:-translate-x-30px-i {
    --tw-translate-x: 30px !important;
  }

  .xl\:-translate-x-24px-i {
    --tw-translate-x: 24px !important;
  }

  .xl\:-translate-x-20px-i {
    --tw-translate-x: 20px !important;
  }

  .xl\:-translate-x-15px-i {
    --tw-translate-x: 15px !important;
  }

  .xl\:-translate-x-13px-i {
    --tw-translate-x: 13px !important;
  }

  .xl\:-translate-x-12px-i {
    --tw-translate-x: 12px !important;
  }

  .xl\:-translate-x-10px-i {
    --tw-translate-x: 10px !important;
  }

  .xl\:-translate-x-8px-i {
    --tw-translate-x: 8px !important;
  }

  .xl\:-translate-x-6px-i {
    --tw-translate-x: 6px !important;
  }

  .xl\:-translate-x-5px-i {
    --tw-translate-x: 5px !important;
  }

  .xl\:-translate-x-3px-i {
    --tw-translate-x: 3px !important;
  }

  .xl\:-translate-x-0px-i {
    --tw-translate-x: 0px !important;
  }

  .xl\:-translate-x-34px {
    --tw-translate-x: -34px;
  }

  .xl\:-translate-x-30px {
    --tw-translate-x: -30px;
  }

  .xl\:-translate-x-24px {
    --tw-translate-x: -24px;
  }

  .xl\:-translate-x-20px {
    --tw-translate-x: -20px;
  }

  .xl\:-translate-x-15px {
    --tw-translate-x: -15px;
  }

  .xl\:-translate-x-13px {
    --tw-translate-x: -13px;
  }

  .xl\:-translate-x-12px {
    --tw-translate-x: -12px;
  }

  .xl\:-translate-x-10px {
    --tw-translate-x: -10px;
  }

  .xl\:-translate-x-8px {
    --tw-translate-x: -8px;
  }

  .xl\:-translate-x-6px {
    --tw-translate-x: -6px;
  }

  .xl\:-translate-x-5px {
    --tw-translate-x: -5px;
  }

  .xl\:-translate-x-3px {
    --tw-translate-x: -3px;
  }

  .xl\:-translate-x-menu {
    --tw-translate-x: -275px;
  }

  .xl\:-translate-x-px {
    --tw-translate-x: 1px !important;
  }

  .xl\:-translate-x-full {
    --tw-translate-x: -100%;
  }

  .xl\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }

  .xl\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }

  .xl\:translate-x-full {
    --tw-translate-x: 100%;
  }

  .xl\:translate-y-0 {
    --tw-translate-y: 0;
  }

  .xl\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }

  .xl\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }

  .xl\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }

  .xl\:translate-y-4 {
    --tw-translate-y: 1rem;
  }

  .xl\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }

  .xl\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }

  .xl\:translate-y-8 {
    --tw-translate-y: 2rem;
  }

  .xl\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }

  .xl\:translate-y-12 {
    --tw-translate-y: 3rem;
  }

  .xl\:translate-y-16 {
    --tw-translate-y: 4rem;
  }

  .xl\:translate-y-20 {
    --tw-translate-y: 5rem;
  }

  .xl\:translate-y-24 {
    --tw-translate-y: 6rem;
  }

  .xl\:translate-y-32 {
    --tw-translate-y: 8rem;
  }

  .xl\:translate-y-40 {
    --tw-translate-y: 10rem;
  }

  .xl\:translate-y-48 {
    --tw-translate-y: 12rem;
  }

  .xl\:translate-y-56 {
    --tw-translate-y: 14rem;
  }

  .xl\:translate-y-64 {
    --tw-translate-y: 16rem;
  }

  .xl\:translate-y-300px {
    --tw-translate-y: 300px;
  }

  .xl\:translate-y-230px {
    --tw-translate-y: 230px;
  }

  .xl\:translate-y-180px {
    --tw-translate-y: 180px;
  }

  .xl\:translate-y-147px {
    --tw-translate-y: 147px;
  }

  .xl\:translate-y-123px {
    --tw-translate-y: 123px;
  }

  .xl\:translate-y-100px {
    --tw-translate-y: 100px;
  }

  .xl\:translate-y-80px {
    --tw-translate-y: 80px;
  }

  .xl\:translate-y-60px {
    --tw-translate-y: 60px;
  }

  .xl\:translate-y-50px {
    --tw-translate-y: 50px;
  }

  .xl\:translate-y-40px {
    --tw-translate-y: 40px;
  }

  .xl\:translate-y-35px {
    --tw-translate-y: 35px;
  }

  .xl\:translate-y-34px-i {
    --tw-translate-y: 34px !important;
  }

  .xl\:translate-y-30px-i {
    --tw-translate-y: 30px !important;
  }

  .xl\:translate-y-24px-i {
    --tw-translate-y: 24px !important;
  }

  .xl\:translate-y-20px-i {
    --tw-translate-y: 20px !important;
  }

  .xl\:translate-y-15px-i {
    --tw-translate-y: 15px !important;
  }

  .xl\:translate-y-13px-i {
    --tw-translate-y: 13px !important;
  }

  .xl\:translate-y-12px-i {
    --tw-translate-y: 12px !important;
  }

  .xl\:translate-y-10px-i {
    --tw-translate-y: 10px !important;
  }

  .xl\:translate-y-8px-i {
    --tw-translate-y: 8px !important;
  }

  .xl\:translate-y-6px-i {
    --tw-translate-y: 6px !important;
  }

  .xl\:translate-y-5px-i {
    --tw-translate-y: 5px !important;
  }

  .xl\:translate-y-3px-i {
    --tw-translate-y: 3px !important;
  }

  .xl\:translate-y-0px-i {
    --tw-translate-y: 0px !important;
  }

  .xl\:translate-y-34px {
    --tw-translate-y: 34px;
  }

  .xl\:translate-y-30px {
    --tw-translate-y: 30px;
  }

  .xl\:translate-y-24px {
    --tw-translate-y: 24px;
  }

  .xl\:translate-y-20px {
    --tw-translate-y: 20px;
  }

  .xl\:translate-y-15px {
    --tw-translate-y: 15px;
  }

  .xl\:translate-y-13px {
    --tw-translate-y: 13px;
  }

  .xl\:translate-y-12px {
    --tw-translate-y: 12px;
  }

  .xl\:translate-y-10px {
    --tw-translate-y: 10px;
  }

  .xl\:translate-y-8px {
    --tw-translate-y: 8px;
  }

  .xl\:translate-y-6px {
    --tw-translate-y: 6px;
  }

  .xl\:translate-y-5px {
    --tw-translate-y: 5px;
  }

  .xl\:translate-y-3px {
    --tw-translate-y: 3px;
  }

  .xl\:translate-y-menu {
    --tw-translate-y: 275px;
  }

  .xl\:translate-y-px {
    --tw-translate-y: 1px !important;
  }

  .xl\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }

  .xl\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }

  .xl\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }

  .xl\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }

  .xl\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }

  .xl\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }

  .xl\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }

  .xl\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }

  .xl\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }

  .xl\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }

  .xl\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }

  .xl\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }

  .xl\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }

  .xl\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }

  .xl\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }

  .xl\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }

  .xl\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }

  .xl\:-translate-y-300px {
    --tw-translate-y: -300px;
  }

  .xl\:-translate-y-230px {
    --tw-translate-y: -230px;
  }

  .xl\:-translate-y-180px {
    --tw-translate-y: -180px;
  }

  .xl\:-translate-y-147px {
    --tw-translate-y: -147px;
  }

  .xl\:-translate-y-123px {
    --tw-translate-y: -123px;
  }

  .xl\:-translate-y-100px {
    --tw-translate-y: -100px;
  }

  .xl\:-translate-y-80px {
    --tw-translate-y: -80px;
  }

  .xl\:-translate-y-60px {
    --tw-translate-y: -60px;
  }

  .xl\:-translate-y-50px {
    --tw-translate-y: -50px;
  }

  .xl\:-translate-y-40px {
    --tw-translate-y: -40px;
  }

  .xl\:-translate-y-35px {
    --tw-translate-y: -35px;
  }

  .xl\:-translate-y-34px-i {
    --tw-translate-y: 34px !important;
  }

  .xl\:-translate-y-30px-i {
    --tw-translate-y: 30px !important;
  }

  .xl\:-translate-y-24px-i {
    --tw-translate-y: 24px !important;
  }

  .xl\:-translate-y-20px-i {
    --tw-translate-y: 20px !important;
  }

  .xl\:-translate-y-15px-i {
    --tw-translate-y: 15px !important;
  }

  .xl\:-translate-y-13px-i {
    --tw-translate-y: 13px !important;
  }

  .xl\:-translate-y-12px-i {
    --tw-translate-y: 12px !important;
  }

  .xl\:-translate-y-10px-i {
    --tw-translate-y: 10px !important;
  }

  .xl\:-translate-y-8px-i {
    --tw-translate-y: 8px !important;
  }

  .xl\:-translate-y-6px-i {
    --tw-translate-y: 6px !important;
  }

  .xl\:-translate-y-5px-i {
    --tw-translate-y: 5px !important;
  }

  .xl\:-translate-y-3px-i {
    --tw-translate-y: 3px !important;
  }

  .xl\:-translate-y-0px-i {
    --tw-translate-y: 0px !important;
  }

  .xl\:-translate-y-34px {
    --tw-translate-y: -34px;
  }

  .xl\:-translate-y-30px {
    --tw-translate-y: -30px;
  }

  .xl\:-translate-y-24px {
    --tw-translate-y: -24px;
  }

  .xl\:-translate-y-20px {
    --tw-translate-y: -20px;
  }

  .xl\:-translate-y-15px {
    --tw-translate-y: -15px;
  }

  .xl\:-translate-y-13px {
    --tw-translate-y: -13px;
  }

  .xl\:-translate-y-12px {
    --tw-translate-y: -12px;
  }

  .xl\:-translate-y-10px {
    --tw-translate-y: -10px;
  }

  .xl\:-translate-y-8px {
    --tw-translate-y: -8px;
  }

  .xl\:-translate-y-6px {
    --tw-translate-y: -6px;
  }

  .xl\:-translate-y-5px {
    --tw-translate-y: -5px;
  }

  .xl\:-translate-y-3px {
    --tw-translate-y: -3px;
  }

  .xl\:-translate-y-menu {
    --tw-translate-y: -275px;
  }

  .xl\:-translate-y-px {
    --tw-translate-y: 1px !important;
  }

  .xl\:-translate-y-full {
    --tw-translate-y: -100%;
  }

  .xl\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }

  .xl\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }

  .xl\:translate-y-full {
    --tw-translate-y: 100%;
  }

  .xl\:hover\:translate-x-0:hover {
    --tw-translate-x: 0;
  }

  .xl\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }

  .xl\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }

  .xl\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }

  .xl\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }

  .xl\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }

  .xl\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }

  .xl\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }

  .xl\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }

  .xl\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }

  .xl\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }

  .xl\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }

  .xl\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }

  .xl\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }

  .xl\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }

  .xl\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }

  .xl\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }

  .xl\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }

  .xl\:hover\:translate-x-300px:hover {
    --tw-translate-x: 300px;
  }

  .xl\:hover\:translate-x-230px:hover {
    --tw-translate-x: 230px;
  }

  .xl\:hover\:translate-x-180px:hover {
    --tw-translate-x: 180px;
  }

  .xl\:hover\:translate-x-147px:hover {
    --tw-translate-x: 147px;
  }

  .xl\:hover\:translate-x-123px:hover {
    --tw-translate-x: 123px;
  }

  .xl\:hover\:translate-x-100px:hover {
    --tw-translate-x: 100px;
  }

  .xl\:hover\:translate-x-80px:hover {
    --tw-translate-x: 80px;
  }

  .xl\:hover\:translate-x-60px:hover {
    --tw-translate-x: 60px;
  }

  .xl\:hover\:translate-x-50px:hover {
    --tw-translate-x: 50px;
  }

  .xl\:hover\:translate-x-40px:hover {
    --tw-translate-x: 40px;
  }

  .xl\:hover\:translate-x-35px:hover {
    --tw-translate-x: 35px;
  }

  .xl\:hover\:translate-x-34px-i:hover {
    --tw-translate-x: 34px !important;
  }

  .xl\:hover\:translate-x-30px-i:hover {
    --tw-translate-x: 30px !important;
  }

  .xl\:hover\:translate-x-24px-i:hover {
    --tw-translate-x: 24px !important;
  }

  .xl\:hover\:translate-x-20px-i:hover {
    --tw-translate-x: 20px !important;
  }

  .xl\:hover\:translate-x-15px-i:hover {
    --tw-translate-x: 15px !important;
  }

  .xl\:hover\:translate-x-13px-i:hover {
    --tw-translate-x: 13px !important;
  }

  .xl\:hover\:translate-x-12px-i:hover {
    --tw-translate-x: 12px !important;
  }

  .xl\:hover\:translate-x-10px-i:hover {
    --tw-translate-x: 10px !important;
  }

  .xl\:hover\:translate-x-8px-i:hover {
    --tw-translate-x: 8px !important;
  }

  .xl\:hover\:translate-x-6px-i:hover {
    --tw-translate-x: 6px !important;
  }

  .xl\:hover\:translate-x-5px-i:hover {
    --tw-translate-x: 5px !important;
  }

  .xl\:hover\:translate-x-3px-i:hover {
    --tw-translate-x: 3px !important;
  }

  .xl\:hover\:translate-x-0px-i:hover {
    --tw-translate-x: 0px !important;
  }

  .xl\:hover\:translate-x-34px:hover {
    --tw-translate-x: 34px;
  }

  .xl\:hover\:translate-x-30px:hover {
    --tw-translate-x: 30px;
  }

  .xl\:hover\:translate-x-24px:hover {
    --tw-translate-x: 24px;
  }

  .xl\:hover\:translate-x-20px:hover {
    --tw-translate-x: 20px;
  }

  .xl\:hover\:translate-x-15px:hover {
    --tw-translate-x: 15px;
  }

  .xl\:hover\:translate-x-13px:hover {
    --tw-translate-x: 13px;
  }

  .xl\:hover\:translate-x-12px:hover {
    --tw-translate-x: 12px;
  }

  .xl\:hover\:translate-x-10px:hover {
    --tw-translate-x: 10px;
  }

  .xl\:hover\:translate-x-8px:hover {
    --tw-translate-x: 8px;
  }

  .xl\:hover\:translate-x-6px:hover {
    --tw-translate-x: 6px;
  }

  .xl\:hover\:translate-x-5px:hover {
    --tw-translate-x: 5px;
  }

  .xl\:hover\:translate-x-3px:hover {
    --tw-translate-x: 3px;
  }

  .xl\:hover\:translate-x-menu:hover {
    --tw-translate-x: 275px;
  }

  .xl\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px !important;
  }

  .xl\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }

  .xl\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }

  .xl\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }

  .xl\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }

  .xl\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }

  .xl\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }

  .xl\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }

  .xl\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }

  .xl\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }

  .xl\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }

  .xl\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }

  .xl\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }

  .xl\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }

  .xl\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }

  .xl\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }

  .xl\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }

  .xl\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }

  .xl\:hover\:-translate-x-300px:hover {
    --tw-translate-x: -300px;
  }

  .xl\:hover\:-translate-x-230px:hover {
    --tw-translate-x: -230px;
  }

  .xl\:hover\:-translate-x-180px:hover {
    --tw-translate-x: -180px;
  }

  .xl\:hover\:-translate-x-147px:hover {
    --tw-translate-x: -147px;
  }

  .xl\:hover\:-translate-x-123px:hover {
    --tw-translate-x: -123px;
  }

  .xl\:hover\:-translate-x-100px:hover {
    --tw-translate-x: -100px;
  }

  .xl\:hover\:-translate-x-80px:hover {
    --tw-translate-x: -80px;
  }

  .xl\:hover\:-translate-x-60px:hover {
    --tw-translate-x: -60px;
  }

  .xl\:hover\:-translate-x-50px:hover {
    --tw-translate-x: -50px;
  }

  .xl\:hover\:-translate-x-40px:hover {
    --tw-translate-x: -40px;
  }

  .xl\:hover\:-translate-x-35px:hover {
    --tw-translate-x: -35px;
  }

  .xl\:hover\:-translate-x-34px-i:hover {
    --tw-translate-x: 34px !important;
  }

  .xl\:hover\:-translate-x-30px-i:hover {
    --tw-translate-x: 30px !important;
  }

  .xl\:hover\:-translate-x-24px-i:hover {
    --tw-translate-x: 24px !important;
  }

  .xl\:hover\:-translate-x-20px-i:hover {
    --tw-translate-x: 20px !important;
  }

  .xl\:hover\:-translate-x-15px-i:hover {
    --tw-translate-x: 15px !important;
  }

  .xl\:hover\:-translate-x-13px-i:hover {
    --tw-translate-x: 13px !important;
  }

  .xl\:hover\:-translate-x-12px-i:hover {
    --tw-translate-x: 12px !important;
  }

  .xl\:hover\:-translate-x-10px-i:hover {
    --tw-translate-x: 10px !important;
  }

  .xl\:hover\:-translate-x-8px-i:hover {
    --tw-translate-x: 8px !important;
  }

  .xl\:hover\:-translate-x-6px-i:hover {
    --tw-translate-x: 6px !important;
  }

  .xl\:hover\:-translate-x-5px-i:hover {
    --tw-translate-x: 5px !important;
  }

  .xl\:hover\:-translate-x-3px-i:hover {
    --tw-translate-x: 3px !important;
  }

  .xl\:hover\:-translate-x-0px-i:hover {
    --tw-translate-x: 0px !important;
  }

  .xl\:hover\:-translate-x-34px:hover {
    --tw-translate-x: -34px;
  }

  .xl\:hover\:-translate-x-30px:hover {
    --tw-translate-x: -30px;
  }

  .xl\:hover\:-translate-x-24px:hover {
    --tw-translate-x: -24px;
  }

  .xl\:hover\:-translate-x-20px:hover {
    --tw-translate-x: -20px;
  }

  .xl\:hover\:-translate-x-15px:hover {
    --tw-translate-x: -15px;
  }

  .xl\:hover\:-translate-x-13px:hover {
    --tw-translate-x: -13px;
  }

  .xl\:hover\:-translate-x-12px:hover {
    --tw-translate-x: -12px;
  }

  .xl\:hover\:-translate-x-10px:hover {
    --tw-translate-x: -10px;
  }

  .xl\:hover\:-translate-x-8px:hover {
    --tw-translate-x: -8px;
  }

  .xl\:hover\:-translate-x-6px:hover {
    --tw-translate-x: -6px;
  }

  .xl\:hover\:-translate-x-5px:hover {
    --tw-translate-x: -5px;
  }

  .xl\:hover\:-translate-x-3px:hover {
    --tw-translate-x: -3px;
  }

  .xl\:hover\:-translate-x-menu:hover {
    --tw-translate-x: -275px;
  }

  .xl\:hover\:-translate-x-px:hover {
    --tw-translate-x: 1px !important;
  }

  .xl\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }

  .xl\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }

  .xl\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }

  .xl\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }

  .xl\:hover\:translate-y-0:hover {
    --tw-translate-y: 0;
  }

  .xl\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }

  .xl\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }

  .xl\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }

  .xl\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }

  .xl\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }

  .xl\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }

  .xl\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }

  .xl\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }

  .xl\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }

  .xl\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }

  .xl\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }

  .xl\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }

  .xl\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }

  .xl\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }

  .xl\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }

  .xl\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }

  .xl\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }

  .xl\:hover\:translate-y-300px:hover {
    --tw-translate-y: 300px;
  }

  .xl\:hover\:translate-y-230px:hover {
    --tw-translate-y: 230px;
  }

  .xl\:hover\:translate-y-180px:hover {
    --tw-translate-y: 180px;
  }

  .xl\:hover\:translate-y-147px:hover {
    --tw-translate-y: 147px;
  }

  .xl\:hover\:translate-y-123px:hover {
    --tw-translate-y: 123px;
  }

  .xl\:hover\:translate-y-100px:hover {
    --tw-translate-y: 100px;
  }

  .xl\:hover\:translate-y-80px:hover {
    --tw-translate-y: 80px;
  }

  .xl\:hover\:translate-y-60px:hover {
    --tw-translate-y: 60px;
  }

  .xl\:hover\:translate-y-50px:hover {
    --tw-translate-y: 50px;
  }

  .xl\:hover\:translate-y-40px:hover {
    --tw-translate-y: 40px;
  }

  .xl\:hover\:translate-y-35px:hover {
    --tw-translate-y: 35px;
  }

  .xl\:hover\:translate-y-34px-i:hover {
    --tw-translate-y: 34px !important;
  }

  .xl\:hover\:translate-y-30px-i:hover {
    --tw-translate-y: 30px !important;
  }

  .xl\:hover\:translate-y-24px-i:hover {
    --tw-translate-y: 24px !important;
  }

  .xl\:hover\:translate-y-20px-i:hover {
    --tw-translate-y: 20px !important;
  }

  .xl\:hover\:translate-y-15px-i:hover {
    --tw-translate-y: 15px !important;
  }

  .xl\:hover\:translate-y-13px-i:hover {
    --tw-translate-y: 13px !important;
  }

  .xl\:hover\:translate-y-12px-i:hover {
    --tw-translate-y: 12px !important;
  }

  .xl\:hover\:translate-y-10px-i:hover {
    --tw-translate-y: 10px !important;
  }

  .xl\:hover\:translate-y-8px-i:hover {
    --tw-translate-y: 8px !important;
  }

  .xl\:hover\:translate-y-6px-i:hover {
    --tw-translate-y: 6px !important;
  }

  .xl\:hover\:translate-y-5px-i:hover {
    --tw-translate-y: 5px !important;
  }

  .xl\:hover\:translate-y-3px-i:hover {
    --tw-translate-y: 3px !important;
  }

  .xl\:hover\:translate-y-0px-i:hover {
    --tw-translate-y: 0px !important;
  }

  .xl\:hover\:translate-y-34px:hover {
    --tw-translate-y: 34px;
  }

  .xl\:hover\:translate-y-30px:hover {
    --tw-translate-y: 30px;
  }

  .xl\:hover\:translate-y-24px:hover {
    --tw-translate-y: 24px;
  }

  .xl\:hover\:translate-y-20px:hover {
    --tw-translate-y: 20px;
  }

  .xl\:hover\:translate-y-15px:hover {
    --tw-translate-y: 15px;
  }

  .xl\:hover\:translate-y-13px:hover {
    --tw-translate-y: 13px;
  }

  .xl\:hover\:translate-y-12px:hover {
    --tw-translate-y: 12px;
  }

  .xl\:hover\:translate-y-10px:hover {
    --tw-translate-y: 10px;
  }

  .xl\:hover\:translate-y-8px:hover {
    --tw-translate-y: 8px;
  }

  .xl\:hover\:translate-y-6px:hover {
    --tw-translate-y: 6px;
  }

  .xl\:hover\:translate-y-5px:hover {
    --tw-translate-y: 5px;
  }

  .xl\:hover\:translate-y-3px:hover {
    --tw-translate-y: 3px;
  }

  .xl\:hover\:translate-y-menu:hover {
    --tw-translate-y: 275px;
  }

  .xl\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px !important;
  }

  .xl\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }

  .xl\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }

  .xl\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }

  .xl\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }

  .xl\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }

  .xl\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }

  .xl\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }

  .xl\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }

  .xl\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }

  .xl\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }

  .xl\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }

  .xl\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }

  .xl\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }

  .xl\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }

  .xl\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }

  .xl\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }

  .xl\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }

  .xl\:hover\:-translate-y-300px:hover {
    --tw-translate-y: -300px;
  }

  .xl\:hover\:-translate-y-230px:hover {
    --tw-translate-y: -230px;
  }

  .xl\:hover\:-translate-y-180px:hover {
    --tw-translate-y: -180px;
  }

  .xl\:hover\:-translate-y-147px:hover {
    --tw-translate-y: -147px;
  }

  .xl\:hover\:-translate-y-123px:hover {
    --tw-translate-y: -123px;
  }

  .xl\:hover\:-translate-y-100px:hover {
    --tw-translate-y: -100px;
  }

  .xl\:hover\:-translate-y-80px:hover {
    --tw-translate-y: -80px;
  }

  .xl\:hover\:-translate-y-60px:hover {
    --tw-translate-y: -60px;
  }

  .xl\:hover\:-translate-y-50px:hover {
    --tw-translate-y: -50px;
  }

  .xl\:hover\:-translate-y-40px:hover {
    --tw-translate-y: -40px;
  }

  .xl\:hover\:-translate-y-35px:hover {
    --tw-translate-y: -35px;
  }

  .xl\:hover\:-translate-y-34px-i:hover {
    --tw-translate-y: 34px !important;
  }

  .xl\:hover\:-translate-y-30px-i:hover {
    --tw-translate-y: 30px !important;
  }

  .xl\:hover\:-translate-y-24px-i:hover {
    --tw-translate-y: 24px !important;
  }

  .xl\:hover\:-translate-y-20px-i:hover {
    --tw-translate-y: 20px !important;
  }

  .xl\:hover\:-translate-y-15px-i:hover {
    --tw-translate-y: 15px !important;
  }

  .xl\:hover\:-translate-y-13px-i:hover {
    --tw-translate-y: 13px !important;
  }

  .xl\:hover\:-translate-y-12px-i:hover {
    --tw-translate-y: 12px !important;
  }

  .xl\:hover\:-translate-y-10px-i:hover {
    --tw-translate-y: 10px !important;
  }

  .xl\:hover\:-translate-y-8px-i:hover {
    --tw-translate-y: 8px !important;
  }

  .xl\:hover\:-translate-y-6px-i:hover {
    --tw-translate-y: 6px !important;
  }

  .xl\:hover\:-translate-y-5px-i:hover {
    --tw-translate-y: 5px !important;
  }

  .xl\:hover\:-translate-y-3px-i:hover {
    --tw-translate-y: 3px !important;
  }

  .xl\:hover\:-translate-y-0px-i:hover {
    --tw-translate-y: 0px !important;
  }

  .xl\:hover\:-translate-y-34px:hover {
    --tw-translate-y: -34px;
  }

  .xl\:hover\:-translate-y-30px:hover {
    --tw-translate-y: -30px;
  }

  .xl\:hover\:-translate-y-24px:hover {
    --tw-translate-y: -24px;
  }

  .xl\:hover\:-translate-y-20px:hover {
    --tw-translate-y: -20px;
  }

  .xl\:hover\:-translate-y-15px:hover {
    --tw-translate-y: -15px;
  }

  .xl\:hover\:-translate-y-13px:hover {
    --tw-translate-y: -13px;
  }

  .xl\:hover\:-translate-y-12px:hover {
    --tw-translate-y: -12px;
  }

  .xl\:hover\:-translate-y-10px:hover {
    --tw-translate-y: -10px;
  }

  .xl\:hover\:-translate-y-8px:hover {
    --tw-translate-y: -8px;
  }

  .xl\:hover\:-translate-y-6px:hover {
    --tw-translate-y: -6px;
  }

  .xl\:hover\:-translate-y-5px:hover {
    --tw-translate-y: -5px;
  }

  .xl\:hover\:-translate-y-3px:hover {
    --tw-translate-y: -3px;
  }

  .xl\:hover\:-translate-y-menu:hover {
    --tw-translate-y: -275px;
  }

  .xl\:hover\:-translate-y-px:hover {
    --tw-translate-y: 1px !important;
  }

  .xl\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }

  .xl\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }

  .xl\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }

  .xl\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }

  .xl\:focus\:translate-x-0:focus {
    --tw-translate-x: 0;
  }

  .xl\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }

  .xl\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }

  .xl\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }

  .xl\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }

  .xl\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }

  .xl\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }

  .xl\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }

  .xl\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }

  .xl\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }

  .xl\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }

  .xl\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }

  .xl\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }

  .xl\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }

  .xl\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }

  .xl\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }

  .xl\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }

  .xl\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }

  .xl\:focus\:translate-x-300px:focus {
    --tw-translate-x: 300px;
  }

  .xl\:focus\:translate-x-230px:focus {
    --tw-translate-x: 230px;
  }

  .xl\:focus\:translate-x-180px:focus {
    --tw-translate-x: 180px;
  }

  .xl\:focus\:translate-x-147px:focus {
    --tw-translate-x: 147px;
  }

  .xl\:focus\:translate-x-123px:focus {
    --tw-translate-x: 123px;
  }

  .xl\:focus\:translate-x-100px:focus {
    --tw-translate-x: 100px;
  }

  .xl\:focus\:translate-x-80px:focus {
    --tw-translate-x: 80px;
  }

  .xl\:focus\:translate-x-60px:focus {
    --tw-translate-x: 60px;
  }

  .xl\:focus\:translate-x-50px:focus {
    --tw-translate-x: 50px;
  }

  .xl\:focus\:translate-x-40px:focus {
    --tw-translate-x: 40px;
  }

  .xl\:focus\:translate-x-35px:focus {
    --tw-translate-x: 35px;
  }

  .xl\:focus\:translate-x-34px-i:focus {
    --tw-translate-x: 34px !important;
  }

  .xl\:focus\:translate-x-30px-i:focus {
    --tw-translate-x: 30px !important;
  }

  .xl\:focus\:translate-x-24px-i:focus {
    --tw-translate-x: 24px !important;
  }

  .xl\:focus\:translate-x-20px-i:focus {
    --tw-translate-x: 20px !important;
  }

  .xl\:focus\:translate-x-15px-i:focus {
    --tw-translate-x: 15px !important;
  }

  .xl\:focus\:translate-x-13px-i:focus {
    --tw-translate-x: 13px !important;
  }

  .xl\:focus\:translate-x-12px-i:focus {
    --tw-translate-x: 12px !important;
  }

  .xl\:focus\:translate-x-10px-i:focus {
    --tw-translate-x: 10px !important;
  }

  .xl\:focus\:translate-x-8px-i:focus {
    --tw-translate-x: 8px !important;
  }

  .xl\:focus\:translate-x-6px-i:focus {
    --tw-translate-x: 6px !important;
  }

  .xl\:focus\:translate-x-5px-i:focus {
    --tw-translate-x: 5px !important;
  }

  .xl\:focus\:translate-x-3px-i:focus {
    --tw-translate-x: 3px !important;
  }

  .xl\:focus\:translate-x-0px-i:focus {
    --tw-translate-x: 0px !important;
  }

  .xl\:focus\:translate-x-34px:focus {
    --tw-translate-x: 34px;
  }

  .xl\:focus\:translate-x-30px:focus {
    --tw-translate-x: 30px;
  }

  .xl\:focus\:translate-x-24px:focus {
    --tw-translate-x: 24px;
  }

  .xl\:focus\:translate-x-20px:focus {
    --tw-translate-x: 20px;
  }

  .xl\:focus\:translate-x-15px:focus {
    --tw-translate-x: 15px;
  }

  .xl\:focus\:translate-x-13px:focus {
    --tw-translate-x: 13px;
  }

  .xl\:focus\:translate-x-12px:focus {
    --tw-translate-x: 12px;
  }

  .xl\:focus\:translate-x-10px:focus {
    --tw-translate-x: 10px;
  }

  .xl\:focus\:translate-x-8px:focus {
    --tw-translate-x: 8px;
  }

  .xl\:focus\:translate-x-6px:focus {
    --tw-translate-x: 6px;
  }

  .xl\:focus\:translate-x-5px:focus {
    --tw-translate-x: 5px;
  }

  .xl\:focus\:translate-x-3px:focus {
    --tw-translate-x: 3px;
  }

  .xl\:focus\:translate-x-menu:focus {
    --tw-translate-x: 275px;
  }

  .xl\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px !important;
  }

  .xl\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }

  .xl\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }

  .xl\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }

  .xl\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }

  .xl\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }

  .xl\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }

  .xl\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }

  .xl\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }

  .xl\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }

  .xl\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }

  .xl\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }

  .xl\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }

  .xl\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }

  .xl\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }

  .xl\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }

  .xl\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }

  .xl\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }

  .xl\:focus\:-translate-x-300px:focus {
    --tw-translate-x: -300px;
  }

  .xl\:focus\:-translate-x-230px:focus {
    --tw-translate-x: -230px;
  }

  .xl\:focus\:-translate-x-180px:focus {
    --tw-translate-x: -180px;
  }

  .xl\:focus\:-translate-x-147px:focus {
    --tw-translate-x: -147px;
  }

  .xl\:focus\:-translate-x-123px:focus {
    --tw-translate-x: -123px;
  }

  .xl\:focus\:-translate-x-100px:focus {
    --tw-translate-x: -100px;
  }

  .xl\:focus\:-translate-x-80px:focus {
    --tw-translate-x: -80px;
  }

  .xl\:focus\:-translate-x-60px:focus {
    --tw-translate-x: -60px;
  }

  .xl\:focus\:-translate-x-50px:focus {
    --tw-translate-x: -50px;
  }

  .xl\:focus\:-translate-x-40px:focus {
    --tw-translate-x: -40px;
  }

  .xl\:focus\:-translate-x-35px:focus {
    --tw-translate-x: -35px;
  }

  .xl\:focus\:-translate-x-34px-i:focus {
    --tw-translate-x: 34px !important;
  }

  .xl\:focus\:-translate-x-30px-i:focus {
    --tw-translate-x: 30px !important;
  }

  .xl\:focus\:-translate-x-24px-i:focus {
    --tw-translate-x: 24px !important;
  }

  .xl\:focus\:-translate-x-20px-i:focus {
    --tw-translate-x: 20px !important;
  }

  .xl\:focus\:-translate-x-15px-i:focus {
    --tw-translate-x: 15px !important;
  }

  .xl\:focus\:-translate-x-13px-i:focus {
    --tw-translate-x: 13px !important;
  }

  .xl\:focus\:-translate-x-12px-i:focus {
    --tw-translate-x: 12px !important;
  }

  .xl\:focus\:-translate-x-10px-i:focus {
    --tw-translate-x: 10px !important;
  }

  .xl\:focus\:-translate-x-8px-i:focus {
    --tw-translate-x: 8px !important;
  }

  .xl\:focus\:-translate-x-6px-i:focus {
    --tw-translate-x: 6px !important;
  }

  .xl\:focus\:-translate-x-5px-i:focus {
    --tw-translate-x: 5px !important;
  }

  .xl\:focus\:-translate-x-3px-i:focus {
    --tw-translate-x: 3px !important;
  }

  .xl\:focus\:-translate-x-0px-i:focus {
    --tw-translate-x: 0px !important;
  }

  .xl\:focus\:-translate-x-34px:focus {
    --tw-translate-x: -34px;
  }

  .xl\:focus\:-translate-x-30px:focus {
    --tw-translate-x: -30px;
  }

  .xl\:focus\:-translate-x-24px:focus {
    --tw-translate-x: -24px;
  }

  .xl\:focus\:-translate-x-20px:focus {
    --tw-translate-x: -20px;
  }

  .xl\:focus\:-translate-x-15px:focus {
    --tw-translate-x: -15px;
  }

  .xl\:focus\:-translate-x-13px:focus {
    --tw-translate-x: -13px;
  }

  .xl\:focus\:-translate-x-12px:focus {
    --tw-translate-x: -12px;
  }

  .xl\:focus\:-translate-x-10px:focus {
    --tw-translate-x: -10px;
  }

  .xl\:focus\:-translate-x-8px:focus {
    --tw-translate-x: -8px;
  }

  .xl\:focus\:-translate-x-6px:focus {
    --tw-translate-x: -6px;
  }

  .xl\:focus\:-translate-x-5px:focus {
    --tw-translate-x: -5px;
  }

  .xl\:focus\:-translate-x-3px:focus {
    --tw-translate-x: -3px;
  }

  .xl\:focus\:-translate-x-menu:focus {
    --tw-translate-x: -275px;
  }

  .xl\:focus\:-translate-x-px:focus {
    --tw-translate-x: 1px !important;
  }

  .xl\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }

  .xl\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }

  .xl\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }

  .xl\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }

  .xl\:focus\:translate-y-0:focus {
    --tw-translate-y: 0;
  }

  .xl\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }

  .xl\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }

  .xl\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }

  .xl\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }

  .xl\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }

  .xl\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }

  .xl\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }

  .xl\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }

  .xl\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }

  .xl\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }

  .xl\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }

  .xl\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }

  .xl\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }

  .xl\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }

  .xl\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }

  .xl\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }

  .xl\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }

  .xl\:focus\:translate-y-300px:focus {
    --tw-translate-y: 300px;
  }

  .xl\:focus\:translate-y-230px:focus {
    --tw-translate-y: 230px;
  }

  .xl\:focus\:translate-y-180px:focus {
    --tw-translate-y: 180px;
  }

  .xl\:focus\:translate-y-147px:focus {
    --tw-translate-y: 147px;
  }

  .xl\:focus\:translate-y-123px:focus {
    --tw-translate-y: 123px;
  }

  .xl\:focus\:translate-y-100px:focus {
    --tw-translate-y: 100px;
  }

  .xl\:focus\:translate-y-80px:focus {
    --tw-translate-y: 80px;
  }

  .xl\:focus\:translate-y-60px:focus {
    --tw-translate-y: 60px;
  }

  .xl\:focus\:translate-y-50px:focus {
    --tw-translate-y: 50px;
  }

  .xl\:focus\:translate-y-40px:focus {
    --tw-translate-y: 40px;
  }

  .xl\:focus\:translate-y-35px:focus {
    --tw-translate-y: 35px;
  }

  .xl\:focus\:translate-y-34px-i:focus {
    --tw-translate-y: 34px !important;
  }

  .xl\:focus\:translate-y-30px-i:focus {
    --tw-translate-y: 30px !important;
  }

  .xl\:focus\:translate-y-24px-i:focus {
    --tw-translate-y: 24px !important;
  }

  .xl\:focus\:translate-y-20px-i:focus {
    --tw-translate-y: 20px !important;
  }

  .xl\:focus\:translate-y-15px-i:focus {
    --tw-translate-y: 15px !important;
  }

  .xl\:focus\:translate-y-13px-i:focus {
    --tw-translate-y: 13px !important;
  }

  .xl\:focus\:translate-y-12px-i:focus {
    --tw-translate-y: 12px !important;
  }

  .xl\:focus\:translate-y-10px-i:focus {
    --tw-translate-y: 10px !important;
  }

  .xl\:focus\:translate-y-8px-i:focus {
    --tw-translate-y: 8px !important;
  }

  .xl\:focus\:translate-y-6px-i:focus {
    --tw-translate-y: 6px !important;
  }

  .xl\:focus\:translate-y-5px-i:focus {
    --tw-translate-y: 5px !important;
  }

  .xl\:focus\:translate-y-3px-i:focus {
    --tw-translate-y: 3px !important;
  }

  .xl\:focus\:translate-y-0px-i:focus {
    --tw-translate-y: 0px !important;
  }

  .xl\:focus\:translate-y-34px:focus {
    --tw-translate-y: 34px;
  }

  .xl\:focus\:translate-y-30px:focus {
    --tw-translate-y: 30px;
  }

  .xl\:focus\:translate-y-24px:focus {
    --tw-translate-y: 24px;
  }

  .xl\:focus\:translate-y-20px:focus {
    --tw-translate-y: 20px;
  }

  .xl\:focus\:translate-y-15px:focus {
    --tw-translate-y: 15px;
  }

  .xl\:focus\:translate-y-13px:focus {
    --tw-translate-y: 13px;
  }

  .xl\:focus\:translate-y-12px:focus {
    --tw-translate-y: 12px;
  }

  .xl\:focus\:translate-y-10px:focus {
    --tw-translate-y: 10px;
  }

  .xl\:focus\:translate-y-8px:focus {
    --tw-translate-y: 8px;
  }

  .xl\:focus\:translate-y-6px:focus {
    --tw-translate-y: 6px;
  }

  .xl\:focus\:translate-y-5px:focus {
    --tw-translate-y: 5px;
  }

  .xl\:focus\:translate-y-3px:focus {
    --tw-translate-y: 3px;
  }

  .xl\:focus\:translate-y-menu:focus {
    --tw-translate-y: 275px;
  }

  .xl\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px !important;
  }

  .xl\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }

  .xl\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }

  .xl\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }

  .xl\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }

  .xl\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }

  .xl\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }

  .xl\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }

  .xl\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }

  .xl\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }

  .xl\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }

  .xl\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }

  .xl\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }

  .xl\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }

  .xl\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }

  .xl\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }

  .xl\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }

  .xl\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }

  .xl\:focus\:-translate-y-300px:focus {
    --tw-translate-y: -300px;
  }

  .xl\:focus\:-translate-y-230px:focus {
    --tw-translate-y: -230px;
  }

  .xl\:focus\:-translate-y-180px:focus {
    --tw-translate-y: -180px;
  }

  .xl\:focus\:-translate-y-147px:focus {
    --tw-translate-y: -147px;
  }

  .xl\:focus\:-translate-y-123px:focus {
    --tw-translate-y: -123px;
  }

  .xl\:focus\:-translate-y-100px:focus {
    --tw-translate-y: -100px;
  }

  .xl\:focus\:-translate-y-80px:focus {
    --tw-translate-y: -80px;
  }

  .xl\:focus\:-translate-y-60px:focus {
    --tw-translate-y: -60px;
  }

  .xl\:focus\:-translate-y-50px:focus {
    --tw-translate-y: -50px;
  }

  .xl\:focus\:-translate-y-40px:focus {
    --tw-translate-y: -40px;
  }

  .xl\:focus\:-translate-y-35px:focus {
    --tw-translate-y: -35px;
  }

  .xl\:focus\:-translate-y-34px-i:focus {
    --tw-translate-y: 34px !important;
  }

  .xl\:focus\:-translate-y-30px-i:focus {
    --tw-translate-y: 30px !important;
  }

  .xl\:focus\:-translate-y-24px-i:focus {
    --tw-translate-y: 24px !important;
  }

  .xl\:focus\:-translate-y-20px-i:focus {
    --tw-translate-y: 20px !important;
  }

  .xl\:focus\:-translate-y-15px-i:focus {
    --tw-translate-y: 15px !important;
  }

  .xl\:focus\:-translate-y-13px-i:focus {
    --tw-translate-y: 13px !important;
  }

  .xl\:focus\:-translate-y-12px-i:focus {
    --tw-translate-y: 12px !important;
  }

  .xl\:focus\:-translate-y-10px-i:focus {
    --tw-translate-y: 10px !important;
  }

  .xl\:focus\:-translate-y-8px-i:focus {
    --tw-translate-y: 8px !important;
  }

  .xl\:focus\:-translate-y-6px-i:focus {
    --tw-translate-y: 6px !important;
  }

  .xl\:focus\:-translate-y-5px-i:focus {
    --tw-translate-y: 5px !important;
  }

  .xl\:focus\:-translate-y-3px-i:focus {
    --tw-translate-y: 3px !important;
  }

  .xl\:focus\:-translate-y-0px-i:focus {
    --tw-translate-y: 0px !important;
  }

  .xl\:focus\:-translate-y-34px:focus {
    --tw-translate-y: -34px;
  }

  .xl\:focus\:-translate-y-30px:focus {
    --tw-translate-y: -30px;
  }

  .xl\:focus\:-translate-y-24px:focus {
    --tw-translate-y: -24px;
  }

  .xl\:focus\:-translate-y-20px:focus {
    --tw-translate-y: -20px;
  }

  .xl\:focus\:-translate-y-15px:focus {
    --tw-translate-y: -15px;
  }

  .xl\:focus\:-translate-y-13px:focus {
    --tw-translate-y: -13px;
  }

  .xl\:focus\:-translate-y-12px:focus {
    --tw-translate-y: -12px;
  }

  .xl\:focus\:-translate-y-10px:focus {
    --tw-translate-y: -10px;
  }

  .xl\:focus\:-translate-y-8px:focus {
    --tw-translate-y: -8px;
  }

  .xl\:focus\:-translate-y-6px:focus {
    --tw-translate-y: -6px;
  }

  .xl\:focus\:-translate-y-5px:focus {
    --tw-translate-y: -5px;
  }

  .xl\:focus\:-translate-y-3px:focus {
    --tw-translate-y: -3px;
  }

  .xl\:focus\:-translate-y-menu:focus {
    --tw-translate-y: -275px;
  }

  .xl\:focus\:-translate-y-px:focus {
    --tw-translate-y: 1px !important;
  }

  .xl\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }

  .xl\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }

  .xl\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }

  .xl\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }

  .xl\:rotate-0 {
    --tw-rotate: 0;
  }

  .xl\:rotate-1 {
    --tw-rotate: 1deg;
  }

  .xl\:rotate-2 {
    --tw-rotate: 2deg;
  }

  .xl\:rotate-3 {
    --tw-rotate: 3deg;
  }

  .xl\:rotate-6 {
    --tw-rotate: 6deg;
  }

  .xl\:rotate-12 {
    --tw-rotate: 12deg;
  }

  .xl\:rotate-45 {
    --tw-rotate: 45deg;
  }

  .xl\:rotate-90 {
    --tw-rotate: 90deg;
  }

  .xl\:rotate-180 {
    --tw-rotate: 180deg;
  }

  .xl\:-rotate-180 {
    --tw-rotate: -180deg;
  }

  .xl\:-rotate-90 {
    --tw-rotate: -90deg;
  }

  .xl\:-rotate-45 {
    --tw-rotate: -45deg;
  }

  .xl\:-rotate-12 {
    --tw-rotate: -12deg;
  }

  .xl\:-rotate-6 {
    --tw-rotate: -6deg;
  }

  .xl\:-rotate-3 {
    --tw-rotate: -3deg;
  }

  .xl\:-rotate-2 {
    --tw-rotate: -2deg;
  }

  .xl\:-rotate-1 {
    --tw-rotate: -1deg;
  }

  .xl\:hover\:rotate-0:hover {
    --tw-rotate: 0;
  }

  .xl\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }

  .xl\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }

  .xl\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }

  .xl\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }

  .xl\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }

  .xl\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }

  .xl\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }

  .xl\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }

  .xl\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }

  .xl\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }

  .xl\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }

  .xl\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }

  .xl\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }

  .xl\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }

  .xl\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }

  .xl\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }

  .xl\:focus\:rotate-0:focus {
    --tw-rotate: 0;
  }

  .xl\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }

  .xl\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }

  .xl\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }

  .xl\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }

  .xl\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }

  .xl\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }

  .xl\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }

  .xl\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }

  .xl\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }

  .xl\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }

  .xl\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }

  .xl\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }

  .xl\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }

  .xl\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }

  .xl\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }

  .xl\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }

  .xl\:skew-x-0 {
    --tw-skew-x: 0;
  }

  .xl\:skew-x-1 {
    --tw-skew-x: 1deg;
  }

  .xl\:skew-x-2 {
    --tw-skew-x: 2deg;
  }

  .xl\:skew-x-3 {
    --tw-skew-x: 3deg;
  }

  .xl\:skew-x-6 {
    --tw-skew-x: 6deg;
  }

  .xl\:skew-x-12 {
    --tw-skew-x: 12deg;
  }

  .xl\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }

  .xl\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }

  .xl\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }

  .xl\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }

  .xl\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }

  .xl\:skew-y-0 {
    --tw-skew-y: 0;
  }

  .xl\:skew-y-1 {
    --tw-skew-y: 1deg;
  }

  .xl\:skew-y-2 {
    --tw-skew-y: 2deg;
  }

  .xl\:skew-y-3 {
    --tw-skew-y: 3deg;
  }

  .xl\:skew-y-6 {
    --tw-skew-y: 6deg;
  }

  .xl\:skew-y-12 {
    --tw-skew-y: 12deg;
  }

  .xl\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }

  .xl\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }

  .xl\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }

  .xl\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }

  .xl\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }

  .xl\:hover\:skew-x-0:hover {
    --tw-skew-x: 0;
  }

  .xl\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }

  .xl\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }

  .xl\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }

  .xl\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }

  .xl\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }

  .xl\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }

  .xl\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }

  .xl\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }

  .xl\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }

  .xl\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }

  .xl\:hover\:skew-y-0:hover {
    --tw-skew-y: 0;
  }

  .xl\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }

  .xl\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }

  .xl\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }

  .xl\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }

  .xl\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }

  .xl\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }

  .xl\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }

  .xl\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }

  .xl\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }

  .xl\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }

  .xl\:focus\:skew-x-0:focus {
    --tw-skew-x: 0;
  }

  .xl\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }

  .xl\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }

  .xl\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }

  .xl\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }

  .xl\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }

  .xl\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }

  .xl\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }

  .xl\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }

  .xl\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }

  .xl\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }

  .xl\:focus\:skew-y-0:focus {
    --tw-skew-y: 0;
  }

  .xl\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }

  .xl\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }

  .xl\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }

  .xl\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }

  .xl\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }

  .xl\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }

  .xl\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }

  .xl\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }

  .xl\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }

  .xl\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }

  .xl\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xl\:scale-50 {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xl\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xl\:scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xl\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xl\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xl\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xl\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xl\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xl\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xl\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xl\:hover\:scale-50:hover {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xl\:hover\:scale-75:hover {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xl\:hover\:scale-90:hover {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xl\:hover\:scale-95:hover {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xl\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xl\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xl\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xl\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xl\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xl\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }

  .xl\:focus\:scale-50:focus {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
  }

  .xl\:focus\:scale-75:focus {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .xl\:focus\:scale-90:focus {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
  }

  .xl\:focus\:scale-95:focus {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
  }

  .xl\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }

  .xl\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }

  .xl\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .xl\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }

  .xl\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  .xl\:scale-x-0 {
    --tw-scale-x: 0;
  }

  .xl\:scale-x-50 {
    --tw-scale-x: .5;
  }

  .xl\:scale-x-75 {
    --tw-scale-x: .75;
  }

  .xl\:scale-x-90 {
    --tw-scale-x: .9;
  }

  .xl\:scale-x-95 {
    --tw-scale-x: .95;
  }

  .xl\:scale-x-100 {
    --tw-scale-x: 1;
  }

  .xl\:scale-x-105 {
    --tw-scale-x: 1.05;
  }

  .xl\:scale-x-110 {
    --tw-scale-x: 1.1;
  }

  .xl\:scale-x-125 {
    --tw-scale-x: 1.25;
  }

  .xl\:scale-x-150 {
    --tw-scale-x: 1.5;
  }

  .xl\:scale-y-0 {
    --tw-scale-y: 0;
  }

  .xl\:scale-y-50 {
    --tw-scale-y: .5;
  }

  .xl\:scale-y-75 {
    --tw-scale-y: .75;
  }

  .xl\:scale-y-90 {
    --tw-scale-y: .9;
  }

  .xl\:scale-y-95 {
    --tw-scale-y: .95;
  }

  .xl\:scale-y-100 {
    --tw-scale-y: 1;
  }

  .xl\:scale-y-105 {
    --tw-scale-y: 1.05;
  }

  .xl\:scale-y-110 {
    --tw-scale-y: 1.1;
  }

  .xl\:scale-y-125 {
    --tw-scale-y: 1.25;
  }

  .xl\:scale-y-150 {
    --tw-scale-y: 1.5;
  }

  .xl\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }

  .xl\:hover\:scale-x-50:hover {
    --tw-scale-x: .5;
  }

  .xl\:hover\:scale-x-75:hover {
    --tw-scale-x: .75;
  }

  .xl\:hover\:scale-x-90:hover {
    --tw-scale-x: .9;
  }

  .xl\:hover\:scale-x-95:hover {
    --tw-scale-x: .95;
  }

  .xl\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }

  .xl\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }

  .xl\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }

  .xl\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }

  .xl\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }

  .xl\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }

  .xl\:hover\:scale-y-50:hover {
    --tw-scale-y: .5;
  }

  .xl\:hover\:scale-y-75:hover {
    --tw-scale-y: .75;
  }

  .xl\:hover\:scale-y-90:hover {
    --tw-scale-y: .9;
  }

  .xl\:hover\:scale-y-95:hover {
    --tw-scale-y: .95;
  }

  .xl\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }

  .xl\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }

  .xl\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }

  .xl\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }

  .xl\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }

  .xl\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }

  .xl\:focus\:scale-x-50:focus {
    --tw-scale-x: .5;
  }

  .xl\:focus\:scale-x-75:focus {
    --tw-scale-x: .75;
  }

  .xl\:focus\:scale-x-90:focus {
    --tw-scale-x: .9;
  }

  .xl\:focus\:scale-x-95:focus {
    --tw-scale-x: .95;
  }

  .xl\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }

  .xl\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }

  .xl\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }

  .xl\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }

  .xl\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }

  .xl\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }

  .xl\:focus\:scale-y-50:focus {
    --tw-scale-y: .5;
  }

  .xl\:focus\:scale-y-75:focus {
    --tw-scale-y: .75;
  }

  .xl\:focus\:scale-y-90:focus {
    --tw-scale-y: .9;
  }

  .xl\:focus\:scale-y-95:focus {
    --tw-scale-y: .95;
  }

  .xl\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }

  .xl\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }

  .xl\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }

  .xl\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }

  .xl\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }

  .xl\:animate-none {
    animation: none;
  }

  .xl\:animate-spin {
    animation: spin 1s linear infinite;
  }

  .xl\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .xl\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .xl\:animate-bounce {
    animation: bounce 1s infinite;
  }

  .xl\:cursor-auto {
    cursor: auto;
  }

  .xl\:cursor-default {
    cursor: default;
  }

  .xl\:cursor-pointer {
    cursor: pointer;
  }

  .xl\:cursor-wait {
    cursor: wait;
  }

  .xl\:cursor-text {
    cursor: text;
  }

  .xl\:cursor-move {
    cursor: move;
  }

  .xl\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .xl\:select-none {
    -webkit-user-select: none;
            user-select: none;
  }

  .xl\:select-text {
    -webkit-user-select: text;
            user-select: text;
  }

  .xl\:select-all {
    -webkit-user-select: all;
            user-select: all;
  }

  .xl\:select-auto {
    -webkit-user-select: auto;
            user-select: auto;
  }

  .xl\:resize-none {
    resize: none;
  }

  .xl\:resize-y {
    resize: vertical;
  }

  .xl\:resize-x {
    resize: horizontal;
  }

  .xl\:resize {
    resize: both;
  }

  .xl\:list-inside {
    list-style-position: inside;
  }

  .xl\:list-outside {
    list-style-position: outside;
  }

  .xl\:list-none {
    list-style-type: none;
  }

  .xl\:list-disc {
    list-style-type: disc;
  }

  .xl\:list-decimal {
    list-style-type: decimal;
  }

  .xl\:appearance-none {
    appearance: none;
  }

  .xl\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .xl\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .xl\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .xl\:grid-flow-row {
    grid-auto-flow: row;
  }

  .xl\:grid-flow-col {
    grid-auto-flow: column;
  }

  .xl\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .xl\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .xl\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .xl\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .xl\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xl\:grid-cols-none {
    grid-template-columns: none;
  }

  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xl\:grid-rows-none {
    grid-template-rows: none;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .xl\:flex-col {
    flex-direction: column;
  }

  .xl\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .xl\:flex-wrap {
    flex-wrap: wrap;
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .xl\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .xl\:place-content-center {
    place-content: center;
  }

  .xl\:place-content-start {
    place-content: start;
  }

  .xl\:place-content-end {
    place-content: end;
  }

  .xl\:place-content-between {
    place-content: space-between;
  }

  .xl\:place-content-around {
    place-content: space-around;
  }

  .xl\:place-content-evenly {
    place-content: space-evenly;
  }

  .xl\:place-content-stretch {
    place-content: stretch;
  }

  .xl\:place-items-start {
    place-items: start;
  }

  .xl\:place-items-end {
    place-items: end;
  }

  .xl\:place-items-center {
    place-items: center;
  }

  .xl\:place-items-stretch {
    place-items: stretch;
  }

  .xl\:content-center {
    align-content: center;
  }

  .xl\:content-start {
    align-content: flex-start;
  }

  .xl\:content-end {
    align-content: flex-end;
  }

  .xl\:content-between {
    align-content: space-between;
  }

  .xl\:content-around {
    align-content: space-around;
  }

  .xl\:content-evenly {
    align-content: space-evenly;
  }

  .xl\:items-start {
    align-items: flex-start;
  }

  .xl\:items-end {
    align-items: flex-end;
  }

  .xl\:items-center {
    align-items: center;
  }

  .xl\:items-baseline {
    align-items: baseline;
  }

  .xl\:items-stretch {
    align-items: stretch;
  }

  .xl\:justify-start {
    justify-content: flex-start;
  }

  .xl\:justify-end {
    justify-content: flex-end;
  }

  .xl\:justify-center {
    justify-content: center;
  }

  .xl\:justify-between {
    justify-content: space-between;
  }

  .xl\:justify-around {
    justify-content: space-around;
  }

  .xl\:justify-evenly {
    justify-content: space-evenly;
  }

  .xl\:justify-items-start {
    justify-items: start;
  }

  .xl\:justify-items-end {
    justify-items: end;
  }

  .xl\:justify-items-center {
    justify-items: center;
  }

  .xl\:justify-items-stretch {
    justify-items: stretch;
  }

  .xl\:gap-0 {
    gap: 0;
  }

  .xl\:gap-1 {
    gap: 0.25rem;
  }

  .xl\:gap-2 {
    gap: 0.5rem;
  }

  .xl\:gap-3 {
    gap: 0.75rem;
  }

  .xl\:gap-4 {
    gap: 1rem;
  }

  .xl\:gap-5 {
    gap: 1.25rem;
  }

  .xl\:gap-6 {
    gap: 1.5rem;
  }

  .xl\:gap-8 {
    gap: 2rem;
  }

  .xl\:gap-10 {
    gap: 2.5rem;
  }

  .xl\:gap-12 {
    gap: 3rem;
  }

  .xl\:gap-16 {
    gap: 4rem;
  }

  .xl\:gap-20 {
    gap: 5rem;
  }

  .xl\:gap-24 {
    gap: 6rem;
  }

  .xl\:gap-32 {
    gap: 8rem;
  }

  .xl\:gap-40 {
    gap: 10rem;
  }

  .xl\:gap-48 {
    gap: 12rem;
  }

  .xl\:gap-56 {
    gap: 14rem;
  }

  .xl\:gap-64 {
    gap: 16rem;
  }

  .xl\:gap-300px {
    gap: 300px;
  }

  .xl\:gap-230px {
    gap: 230px;
  }

  .xl\:gap-180px {
    gap: 180px;
  }

  .xl\:gap-147px {
    gap: 147px;
  }

  .xl\:gap-123px {
    gap: 123px;
  }

  .xl\:gap-100px {
    gap: 100px;
  }

  .xl\:gap-80px {
    gap: 80px;
  }

  .xl\:gap-60px {
    gap: 60px;
  }

  .xl\:gap-50px {
    gap: 50px;
  }

  .xl\:gap-40px {
    gap: 40px;
  }

  .xl\:gap-35px {
    gap: 35px;
  }

  .xl\:gap-34px-i {
    gap: 34px !important;
  }

  .xl\:gap-30px-i {
    gap: 30px !important;
  }

  .xl\:gap-24px-i {
    gap: 24px !important;
  }

  .xl\:gap-20px-i {
    gap: 20px !important;
  }

  .xl\:gap-15px-i {
    gap: 15px !important;
  }

  .xl\:gap-13px-i {
    gap: 13px !important;
  }

  .xl\:gap-12px-i {
    gap: 12px !important;
  }

  .xl\:gap-10px-i {
    gap: 10px !important;
  }

  .xl\:gap-8px-i {
    gap: 8px !important;
  }

  .xl\:gap-6px-i {
    gap: 6px !important;
  }

  .xl\:gap-5px-i {
    gap: 5px !important;
  }

  .xl\:gap-3px-i {
    gap: 3px !important;
  }

  .xl\:gap-0px-i {
    gap: 0px !important;
  }

  .xl\:gap-34px {
    gap: 34px;
  }

  .xl\:gap-30px {
    gap: 30px;
  }

  .xl\:gap-24px {
    gap: 24px;
  }

  .xl\:gap-20px {
    gap: 20px;
  }

  .xl\:gap-15px {
    gap: 15px;
  }

  .xl\:gap-13px {
    gap: 13px;
  }

  .xl\:gap-12px {
    gap: 12px;
  }

  .xl\:gap-10px {
    gap: 10px;
  }

  .xl\:gap-8px {
    gap: 8px;
  }

  .xl\:gap-6px {
    gap: 6px;
  }

  .xl\:gap-5px {
    gap: 5px;
  }

  .xl\:gap-3px {
    gap: 3px;
  }

  .xl\:gap-menu {
    gap: 275px;
  }

  .xl\:gap-px {
    gap: 1px !important;
  }

  .xl\:gap-x-0 {
    column-gap: 0;
  }

  .xl\:gap-x-1 {
    column-gap: 0.25rem;
  }

  .xl\:gap-x-2 {
    column-gap: 0.5rem;
  }

  .xl\:gap-x-3 {
    column-gap: 0.75rem;
  }

  .xl\:gap-x-4 {
    column-gap: 1rem;
  }

  .xl\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .xl\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .xl\:gap-x-8 {
    column-gap: 2rem;
  }

  .xl\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .xl\:gap-x-12 {
    column-gap: 3rem;
  }

  .xl\:gap-x-16 {
    column-gap: 4rem;
  }

  .xl\:gap-x-20 {
    column-gap: 5rem;
  }

  .xl\:gap-x-24 {
    column-gap: 6rem;
  }

  .xl\:gap-x-32 {
    column-gap: 8rem;
  }

  .xl\:gap-x-40 {
    column-gap: 10rem;
  }

  .xl\:gap-x-48 {
    column-gap: 12rem;
  }

  .xl\:gap-x-56 {
    column-gap: 14rem;
  }

  .xl\:gap-x-64 {
    column-gap: 16rem;
  }

  .xl\:gap-x-300px {
    column-gap: 300px;
  }

  .xl\:gap-x-230px {
    column-gap: 230px;
  }

  .xl\:gap-x-180px {
    column-gap: 180px;
  }

  .xl\:gap-x-147px {
    column-gap: 147px;
  }

  .xl\:gap-x-123px {
    column-gap: 123px;
  }

  .xl\:gap-x-100px {
    column-gap: 100px;
  }

  .xl\:gap-x-80px {
    column-gap: 80px;
  }

  .xl\:gap-x-60px {
    column-gap: 60px;
  }

  .xl\:gap-x-50px {
    column-gap: 50px;
  }

  .xl\:gap-x-40px {
    column-gap: 40px;
  }

  .xl\:gap-x-35px {
    column-gap: 35px;
  }

  .xl\:gap-x-34px-i {
    column-gap: 34px !important;
  }

  .xl\:gap-x-30px-i {
    column-gap: 30px !important;
  }

  .xl\:gap-x-24px-i {
    column-gap: 24px !important;
  }

  .xl\:gap-x-20px-i {
    column-gap: 20px !important;
  }

  .xl\:gap-x-15px-i {
    column-gap: 15px !important;
  }

  .xl\:gap-x-13px-i {
    column-gap: 13px !important;
  }

  .xl\:gap-x-12px-i {
    column-gap: 12px !important;
  }

  .xl\:gap-x-10px-i {
    column-gap: 10px !important;
  }

  .xl\:gap-x-8px-i {
    column-gap: 8px !important;
  }

  .xl\:gap-x-6px-i {
    column-gap: 6px !important;
  }

  .xl\:gap-x-5px-i {
    column-gap: 5px !important;
  }

  .xl\:gap-x-3px-i {
    column-gap: 3px !important;
  }

  .xl\:gap-x-0px-i {
    column-gap: 0px !important;
  }

  .xl\:gap-x-34px {
    column-gap: 34px;
  }

  .xl\:gap-x-30px {
    column-gap: 30px;
  }

  .xl\:gap-x-24px {
    column-gap: 24px;
  }

  .xl\:gap-x-20px {
    column-gap: 20px;
  }

  .xl\:gap-x-15px {
    column-gap: 15px;
  }

  .xl\:gap-x-13px {
    column-gap: 13px;
  }

  .xl\:gap-x-12px {
    column-gap: 12px;
  }

  .xl\:gap-x-10px {
    column-gap: 10px;
  }

  .xl\:gap-x-8px {
    column-gap: 8px;
  }

  .xl\:gap-x-6px {
    column-gap: 6px;
  }

  .xl\:gap-x-5px {
    column-gap: 5px;
  }

  .xl\:gap-x-3px {
    column-gap: 3px;
  }

  .xl\:gap-x-menu {
    column-gap: 275px;
  }

  .xl\:gap-x-px {
    column-gap: 1px !important;
  }

  .xl\:gap-y-0 {
    row-gap: 0;
  }

  .xl\:gap-y-1 {
    row-gap: 0.25rem;
  }

  .xl\:gap-y-2 {
    row-gap: 0.5rem;
  }

  .xl\:gap-y-3 {
    row-gap: 0.75rem;
  }

  .xl\:gap-y-4 {
    row-gap: 1rem;
  }

  .xl\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .xl\:gap-y-6 {
    row-gap: 1.5rem;
  }

  .xl\:gap-y-8 {
    row-gap: 2rem;
  }

  .xl\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .xl\:gap-y-12 {
    row-gap: 3rem;
  }

  .xl\:gap-y-16 {
    row-gap: 4rem;
  }

  .xl\:gap-y-20 {
    row-gap: 5rem;
  }

  .xl\:gap-y-24 {
    row-gap: 6rem;
  }

  .xl\:gap-y-32 {
    row-gap: 8rem;
  }

  .xl\:gap-y-40 {
    row-gap: 10rem;
  }

  .xl\:gap-y-48 {
    row-gap: 12rem;
  }

  .xl\:gap-y-56 {
    row-gap: 14rem;
  }

  .xl\:gap-y-64 {
    row-gap: 16rem;
  }

  .xl\:gap-y-300px {
    row-gap: 300px;
  }

  .xl\:gap-y-230px {
    row-gap: 230px;
  }

  .xl\:gap-y-180px {
    row-gap: 180px;
  }

  .xl\:gap-y-147px {
    row-gap: 147px;
  }

  .xl\:gap-y-123px {
    row-gap: 123px;
  }

  .xl\:gap-y-100px {
    row-gap: 100px;
  }

  .xl\:gap-y-80px {
    row-gap: 80px;
  }

  .xl\:gap-y-60px {
    row-gap: 60px;
  }

  .xl\:gap-y-50px {
    row-gap: 50px;
  }

  .xl\:gap-y-40px {
    row-gap: 40px;
  }

  .xl\:gap-y-35px {
    row-gap: 35px;
  }

  .xl\:gap-y-34px-i {
    row-gap: 34px !important;
  }

  .xl\:gap-y-30px-i {
    row-gap: 30px !important;
  }

  .xl\:gap-y-24px-i {
    row-gap: 24px !important;
  }

  .xl\:gap-y-20px-i {
    row-gap: 20px !important;
  }

  .xl\:gap-y-15px-i {
    row-gap: 15px !important;
  }

  .xl\:gap-y-13px-i {
    row-gap: 13px !important;
  }

  .xl\:gap-y-12px-i {
    row-gap: 12px !important;
  }

  .xl\:gap-y-10px-i {
    row-gap: 10px !important;
  }

  .xl\:gap-y-8px-i {
    row-gap: 8px !important;
  }

  .xl\:gap-y-6px-i {
    row-gap: 6px !important;
  }

  .xl\:gap-y-5px-i {
    row-gap: 5px !important;
  }

  .xl\:gap-y-3px-i {
    row-gap: 3px !important;
  }

  .xl\:gap-y-0px-i {
    row-gap: 0px !important;
  }

  .xl\:gap-y-34px {
    row-gap: 34px;
  }

  .xl\:gap-y-30px {
    row-gap: 30px;
  }

  .xl\:gap-y-24px {
    row-gap: 24px;
  }

  .xl\:gap-y-20px {
    row-gap: 20px;
  }

  .xl\:gap-y-15px {
    row-gap: 15px;
  }

  .xl\:gap-y-13px {
    row-gap: 13px;
  }

  .xl\:gap-y-12px {
    row-gap: 12px;
  }

  .xl\:gap-y-10px {
    row-gap: 10px;
  }

  .xl\:gap-y-8px {
    row-gap: 8px;
  }

  .xl\:gap-y-6px {
    row-gap: 6px;
  }

  .xl\:gap-y-5px {
    row-gap: 5px;
  }

  .xl\:gap-y-3px {
    row-gap: 3px;
  }

  .xl\:gap-y-menu {
    row-gap: 275px;
  }

  .xl\:gap-y-px {
    row-gap: 1px !important;
  }

  .xl\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(300px * var(--tw-space-x-reverse));
    margin-left: calc(300px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(230px * var(--tw-space-x-reverse));
    margin-left: calc(230px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(180px * var(--tw-space-x-reverse));
    margin-left: calc(180px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(147px * var(--tw-space-x-reverse));
    margin-left: calc(147px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(123px * var(--tw-space-x-reverse));
    margin-left: calc(123px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(100px * var(--tw-space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(80px * var(--tw-space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(60px * var(--tw-space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(50px * var(--tw-space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(40px * var(--tw-space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(35px * var(--tw-space-x-reverse));
    margin-left: calc(35px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px !important * var(--tw-space-x-reverse));
    margin-left: calc(34px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px !important * var(--tw-space-x-reverse));
    margin-left: calc(30px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px !important * var(--tw-space-x-reverse));
    margin-left: calc(24px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px !important * var(--tw-space-x-reverse));
    margin-left: calc(20px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px !important * var(--tw-space-x-reverse));
    margin-left: calc(15px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px !important * var(--tw-space-x-reverse));
    margin-left: calc(13px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px !important * var(--tw-space-x-reverse));
    margin-left: calc(12px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px !important * var(--tw-space-x-reverse));
    margin-left: calc(10px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px !important * var(--tw-space-x-reverse));
    margin-left: calc(8px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px !important * var(--tw-space-x-reverse));
    margin-left: calc(6px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px !important * var(--tw-space-x-reverse));
    margin-left: calc(5px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px !important * var(--tw-space-x-reverse));
    margin-left: calc(3px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px !important * var(--tw-space-x-reverse));
    margin-left: calc(0px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px * var(--tw-space-x-reverse));
    margin-left: calc(34px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px * var(--tw-space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px * var(--tw-space-x-reverse));
    margin-left: calc(24px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px * var(--tw-space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px * var(--tw-space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px * var(--tw-space-x-reverse));
    margin-left: calc(13px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px * var(--tw-space-x-reverse));
    margin-left: calc(12px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px * var(--tw-space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px * var(--tw-space-x-reverse));
    margin-left: calc(8px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px * var(--tw-space-x-reverse));
    margin-left: calc(6px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px * var(--tw-space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px * var(--tw-space-x-reverse));
    margin-left: calc(3px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(275px * var(--tw-space-x-reverse));
    margin-left: calc(275px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px !important * var(--tw-space-x-reverse));
    margin-left: calc(1px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-300px * var(--tw-space-x-reverse));
    margin-left: calc(-300px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-230px * var(--tw-space-x-reverse));
    margin-left: calc(-230px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-180px * var(--tw-space-x-reverse));
    margin-left: calc(-180px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-147px * var(--tw-space-x-reverse));
    margin-left: calc(-147px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-123px * var(--tw-space-x-reverse));
    margin-left: calc(-123px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-100px * var(--tw-space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-80px * var(--tw-space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-60px * var(--tw-space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-50px * var(--tw-space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-40px * var(--tw-space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-35px * var(--tw-space-x-reverse));
    margin-left: calc(-35px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(34px !important * var(--tw-space-x-reverse));
    margin-left: calc(34px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px !important * var(--tw-space-x-reverse));
    margin-left: calc(30px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24px !important * var(--tw-space-x-reverse));
    margin-left: calc(24px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px !important * var(--tw-space-x-reverse));
    margin-left: calc(20px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15px !important * var(--tw-space-x-reverse));
    margin-left: calc(15px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13px !important * var(--tw-space-x-reverse));
    margin-left: calc(13px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px !important * var(--tw-space-x-reverse));
    margin-left: calc(12px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px !important * var(--tw-space-x-reverse));
    margin-left: calc(10px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px !important * var(--tw-space-x-reverse));
    margin-left: calc(8px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6px !important * var(--tw-space-x-reverse));
    margin-left: calc(6px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5px !important * var(--tw-space-x-reverse));
    margin-left: calc(5px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3px !important * var(--tw-space-x-reverse));
    margin-left: calc(3px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px !important * var(--tw-space-x-reverse));
    margin-left: calc(0px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-34px * var(--tw-space-x-reverse));
    margin-left: calc(-34px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-30px * var(--tw-space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24px * var(--tw-space-x-reverse));
    margin-left: calc(-24px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20px * var(--tw-space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15px * var(--tw-space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13px * var(--tw-space-x-reverse));
    margin-left: calc(-13px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12px * var(--tw-space-x-reverse));
    margin-left: calc(-12px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10px * var(--tw-space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8px * var(--tw-space-x-reverse));
    margin-left: calc(-8px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6px * var(--tw-space-x-reverse));
    margin-left: calc(-6px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5px * var(--tw-space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3px * var(--tw-space-x-reverse));
    margin-left: calc(-3px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-275px * var(--tw-space-x-reverse));
    margin-left: calc(-275px * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px !important * var(--tw-space-x-reverse));
    margin-left: calc(1px !important * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }

  .xl\:space-y-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(300px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(300px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(230px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(230px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(180px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(180px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(147px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(147px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(123px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(123px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(100px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(80px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(60px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(50px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(40px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(35px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(35px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(275px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(275px * var(--tw-space-y-reverse));
  }

  .xl\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-300px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-300px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-300px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-230px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-230px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-230px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-180px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-180px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-180px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-147px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-147px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-147px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-123px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-123px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-123px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-100px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-100px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-80px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-80px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-60px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-60px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-50px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-50px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-40px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-40px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-35px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-35px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-35px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-34px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-30px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-24px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-20px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-15px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-13px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-12px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-10px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-8px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-6px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-5px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-3px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-0px-i > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px !important * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-34px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-34px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-34px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-30px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-30px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-24px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-20px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-15px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-13px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-12px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-10px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-8px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-6px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-5px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-3px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-menu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-275px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-275px * var(--tw-space-y-reverse));
  }

  .xl\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px !important * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px !important * var(--tw-space-y-reverse));
  }

  .xl\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }

  .xl\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .xl\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-x-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .xl\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .xl\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }

  .xl\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }

  .xl\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .xl\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }

  .xl\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }

  .xl\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }

  .xl\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .xl\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }

  .xl\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }

  .xl\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-divide-opacity));
  }

  .xl\:divide-black40pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-divide-opacity));
  }

  .xl\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }

  .xl\:divide-petroleum > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-divide-opacity));
  }

  .xl\:divide-petroleum70pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-divide-opacity));
  }

  .xl\:divide-petroleum30pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-divide-opacity));
  }

  .xl\:divide-disabled > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-divide-opacity));
  }

  .xl\:divide-petroleum10pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-divide-opacity));
  }

  .xl\:divide-petroleum5pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-divide-opacity));
  }

  .xl\:divide-darkGreen > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-divide-opacity));
  }

  .xl\:divide-ok > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-divide-opacity));
  }

  .xl\:divide-green > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-divide-opacity));
  }

  .xl\:divide-green10pct > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-divide-opacity));
  }

  .xl\:divide-orange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-divide-opacity));
  }

  .xl\:divide-darkOrange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-divide-opacity));
  }

  .xl\:divide-lightOrange > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-divide-opacity));
  }

  .xl\:divide-error > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-divide-opacity));
  }

  .xl\:divide-yellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-divide-opacity));
  }

  .xl\:divide-darkYellow > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-divide-opacity));
  }

  .xl\:divide-gray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-divide-opacity));
  }

  .xl\:divide-lightGray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-divide-opacity));
  }

  .xl\:divide-borderGray > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-divide-opacity));
  }

  .xl\:divide-default > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-divide-opacity));
  }

  .xl\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }

  .xl\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }

  .xl\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }

  .xl\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }

  .xl\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }

  .xl\:place-self-auto {
    place-self: auto;
  }

  .xl\:place-self-start {
    place-self: start;
  }

  .xl\:place-self-end {
    place-self: end;
  }

  .xl\:place-self-center {
    place-self: center;
  }

  .xl\:place-self-stretch {
    place-self: stretch;
  }

  .xl\:self-auto {
    align-self: auto;
  }

  .xl\:self-start {
    align-self: flex-start;
  }

  .xl\:self-end {
    align-self: flex-end;
  }

  .xl\:self-center {
    align-self: center;
  }

  .xl\:self-stretch {
    align-self: stretch;
  }

  .xl\:self-baseline {
    align-self: baseline;
  }

  .xl\:justify-self-auto {
    justify-self: auto;
  }

  .xl\:justify-self-start {
    justify-self: start;
  }

  .xl\:justify-self-end {
    justify-self: end;
  }

  .xl\:justify-self-center {
    justify-self: center;
  }

  .xl\:justify-self-stretch {
    justify-self: stretch;
  }

  .xl\:overflow-auto {
    overflow: auto;
  }

  .xl\:overflow-hidden {
    overflow: hidden;
  }

  .xl\:overflow-visible {
    overflow: visible;
  }

  .xl\:overflow-scroll {
    overflow: scroll;
  }

  .xl\:overflow-x-auto {
    overflow-x: auto;
  }

  .xl\:overflow-y-auto {
    overflow-y: auto;
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .xl\:overflow-x-visible {
    overflow-x: visible;
  }

  .xl\:overflow-y-visible {
    overflow-y: visible;
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .xl\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .xl\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .xl\:overscroll-none {
    overscroll-behavior: none;
  }

  .xl\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .xl\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .xl\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .xl\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .xl\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .xl\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .xl\:whitespace-normal {
    white-space: normal;
  }

  .xl\:whitespace-nowrap {
    white-space: nowrap;
  }

  .xl\:whitespace-pre {
    white-space: pre;
  }

  .xl\:whitespace-pre-line {
    white-space: pre-line;
  }

  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .xl\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .xl\:break-words {
    overflow-wrap: break-word;
  }

  .xl\:break-all {
    word-break: break-all;
  }

  .xl\:rounded-none {
    border-radius: 0;
  }

  .xl\:rounded-5px {
    border-radius: 5px;
  }

  .xl\:rounded-sm {
    border-radius: 0.125rem;
  }

  .xl\:rounded-default {
    border-radius: 0.25rem;
  }

  .xl\:rounded-md {
    border-radius: 0.375rem;
  }

  .xl\:rounded-lg {
    border-radius: 0.5rem;
  }

  .xl\:rounded-xl {
    border-radius: 0.75rem;
  }

  .xl\:rounded-2xl {
    border-radius: 1rem;
  }

  .xl\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .xl\:rounded-full {
    border-radius: 9999px;
  }

  .xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .xl\:rounded-t-5px {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .xl\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }

  .xl\:rounded-t-default {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .xl\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .xl\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .xl\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .xl\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .xl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .xl\:rounded-r-5px {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .xl\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }

  .xl\:rounded-r-default {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .xl\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .xl\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .xl\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .xl\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .xl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .xl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xl\:rounded-b-5px {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .xl\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .xl\:rounded-b-default {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .xl\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .xl\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .xl\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .xl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xl\:rounded-l-5px {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .xl\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }

  .xl\:rounded-l-default {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .xl\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .xl\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .xl\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .xl\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .xl\:rounded-tl-5px {
    border-top-left-radius: 5px;
  }

  .xl\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }

  .xl\:rounded-tl-default {
    border-top-left-radius: 0.25rem;
  }

  .xl\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }

  .xl\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }

  .xl\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }

  .xl\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .xl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .xl\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .xl\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .xl\:rounded-tr-5px {
    border-top-right-radius: 5px;
  }

  .xl\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }

  .xl\:rounded-tr-default {
    border-top-right-radius: 0.25rem;
  }

  .xl\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }

  .xl\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }

  .xl\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }

  .xl\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .xl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .xl\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .xl\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .xl\:rounded-br-5px {
    border-bottom-right-radius: 5px;
  }

  .xl\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }

  .xl\:rounded-br-default {
    border-bottom-right-radius: 0.25rem;
  }

  .xl\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }

  .xl\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }

  .xl\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }

  .xl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .xl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .xl\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .xl\:rounded-bl-5px {
    border-bottom-left-radius: 5px;
  }

  .xl\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }

  .xl\:rounded-bl-default {
    border-bottom-left-radius: 0.25rem;
  }

  .xl\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }

  .xl\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }

  .xl\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }

  .xl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .xl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .xl\:border-0 {
    border-width: 0;
  }

  .xl\:border-2 {
    border-width: 2px;
  }

  .xl\:border-4 {
    border-width: 4px;
  }

  .xl\:border-8 {
    border-width: 8px;
  }

  .xl\:border-default {
    border-width: 1px;
  }

  .xl\:border-t-0 {
    border-top-width: 0;
  }

  .xl\:border-t-2 {
    border-top-width: 2px;
  }

  .xl\:border-t-4 {
    border-top-width: 4px;
  }

  .xl\:border-t-8 {
    border-top-width: 8px;
  }

  .xl\:border-t-default {
    border-top-width: 1px;
  }

  .xl\:border-r-0 {
    border-right-width: 0;
  }

  .xl\:border-r-2 {
    border-right-width: 2px;
  }

  .xl\:border-r-4 {
    border-right-width: 4px;
  }

  .xl\:border-r-8 {
    border-right-width: 8px;
  }

  .xl\:border-r-default {
    border-right-width: 1px;
  }

  .xl\:border-b-0 {
    border-bottom-width: 0;
  }

  .xl\:border-b-2 {
    border-bottom-width: 2px;
  }

  .xl\:border-b-4 {
    border-bottom-width: 4px;
  }

  .xl\:border-b-8 {
    border-bottom-width: 8px;
  }

  .xl\:border-b-default {
    border-bottom-width: 1px;
  }

  .xl\:border-l-0 {
    border-left-width: 0;
  }

  .xl\:border-l-2 {
    border-left-width: 2px;
  }

  .xl\:border-l-4 {
    border-left-width: 4px;
  }

  .xl\:border-l-8 {
    border-left-width: 8px;
  }

  .xl\:border-l-default {
    border-left-width: 1px;
  }

  .xl\:border-solid {
    border-style: solid;
  }

  .xl\:border-dashed {
    border-style: dashed;
  }

  .xl\:border-dotted {
    border-style: dotted;
  }

  .xl\:border-double {
    border-style: double;
  }

  .xl\:border-none {
    border-style: none;
  }

  .xl\:border-transparent {
    border-color: transparent;
  }

  .xl\:border-current {
    border-color: currentColor;
  }

  .xl\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .xl\:border-black40pct {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .xl\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:border-petroleum {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .xl\:border-petroleum70pct {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .xl\:border-petroleum30pct {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .xl\:border-disabled {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .xl\:border-petroleum10pct {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .xl\:border-petroleum5pct {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .xl\:border-darkGreen {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .xl\:border-ok {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .xl\:border-green {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .xl\:border-green10pct {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .xl\:border-orange {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .xl\:border-darkOrange {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .xl\:border-lightOrange {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .xl\:border-error {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .xl\:border-yellow {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .xl\:border-darkYellow {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .xl\:border-gray {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .xl\:border-lightGray {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .xl\:border-borderGray {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .xl\:border-default {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .xl\:hover\:border-transparent:hover {
    border-color: transparent;
  }

  .xl\:hover\:border-current:hover {
    border-color: currentColor;
  }

  .xl\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .xl\:hover\:border-black40pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .xl\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:hover\:border-petroleum:hover {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .xl\:hover\:border-petroleum70pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .xl\:hover\:border-petroleum30pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .xl\:hover\:border-disabled:hover {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .xl\:hover\:border-petroleum10pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .xl\:hover\:border-petroleum5pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .xl\:hover\:border-darkGreen:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .xl\:hover\:border-ok:hover {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .xl\:hover\:border-green:hover {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .xl\:hover\:border-green10pct:hover {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .xl\:hover\:border-orange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .xl\:hover\:border-darkOrange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .xl\:hover\:border-lightOrange:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .xl\:hover\:border-error:hover {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .xl\:hover\:border-yellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .xl\:hover\:border-darkYellow:hover {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .xl\:hover\:border-gray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .xl\:hover\:border-lightGray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .xl\:hover\:border-borderGray:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .xl\:hover\:border-default:hover {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .xl\:focus\:border-transparent:focus {
    border-color: transparent;
  }

  .xl\:focus\:border-current:focus {
    border-color: currentColor;
  }

  .xl\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(27, 27, 26, var(--tw-border-opacity));
  }

  .xl\:focus\:border-black40pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(164, 164, 163, var(--tw-border-opacity));
  }

  .xl\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .xl\:focus\:border-petroleum:focus {
    --tw-border-opacity: 1;
    border-color: rgba(14, 71, 68, var(--tw-border-opacity));
  }

  .xl\:focus\:border-petroleum70pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(86, 126, 124, var(--tw-border-opacity));
  }

  .xl\:focus\:border-petroleum30pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .xl\:focus\:border-disabled:focus {
    --tw-border-opacity: 1;
    border-color: rgba(183, 200, 199, var(--tw-border-opacity));
  }

  .xl\:focus\:border-petroleum10pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(231, 237, 236, var(--tw-border-opacity));
  }

  .xl\:focus\:border-petroleum5pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 246, 246, var(--tw-border-opacity));
  }

  .xl\:focus\:border-darkGreen:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .xl\:focus\:border-ok:focus {
    --tw-border-opacity: 1;
    border-color: rgba(38, 123, 56, var(--tw-border-opacity));
  }

  .xl\:focus\:border-green:focus {
    --tw-border-opacity: 1;
    border-color: rgba(46, 161, 73, var(--tw-border-opacity));
  }

  .xl\:focus\:border-green10pct:focus {
    --tw-border-opacity: 1;
    border-color: rgba(234, 246, 237, var(--tw-border-opacity));
  }

  .xl\:focus\:border-orange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 113, 0, var(--tw-border-opacity));
  }

  .xl\:focus\:border-darkOrange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .xl\:focus\:border-lightOrange:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 227, 204, var(--tw-border-opacity));
  }

  .xl\:focus\:border-error:focus {
    --tw-border-opacity: 1;
    border-color: rgba(170, 54, 54, var(--tw-border-opacity));
  }

  .xl\:focus\:border-yellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(233, 194, 88, var(--tw-border-opacity));
  }

  .xl\:focus\:border-darkYellow:focus {
    --tw-border-opacity: 1;
    border-color: rgba(195, 176, 145, var(--tw-border-opacity));
  }

  .xl\:focus\:border-gray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(148, 148, 147, var(--tw-border-opacity));
  }

  .xl\:focus\:border-lightGray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
  }

  .xl\:focus\:border-borderGray:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .xl\:focus\:border-default:focus {
    --tw-border-opacity: 1;
    border-color: rgba(230, 230, 230, var(--tw-border-opacity));
  }

  .xl\:border-opacity-0 {
    --tw-border-opacity: 0;
  }

  .xl\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }

  .xl\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }

  .xl\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }

  .xl\:border-opacity-100 {
    --tw-border-opacity: 1;
  }

  .xl\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }

  .xl\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }

  .xl\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }

  .xl\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }

  .xl\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }

  .xl\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }

  .xl\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }

  .xl\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }

  .xl\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }

  .xl\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }

  .xl\:bg-transparent {
    background-color: transparent;
  }

  .xl\:bg-current {
    background-color: currentColor;
  }

  .xl\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .xl\:bg-black40pct {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .xl\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:bg-petroleum {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .xl\:bg-petroleum70pct {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .xl\:bg-petroleum30pct {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .xl\:bg-disabled {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .xl\:bg-petroleum10pct {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .xl\:bg-petroleum5pct {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .xl\:bg-darkGreen {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .xl\:bg-ok {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .xl\:bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .xl\:bg-green10pct {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .xl\:bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .xl\:bg-darkOrange {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .xl\:bg-lightOrange {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .xl\:bg-error {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .xl\:bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .xl\:bg-darkYellow {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .xl\:bg-gray {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .xl\:bg-lightGray {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .xl\:bg-borderGray {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-transparent:hover {
    background-color: transparent;
  }

  .xl\:hover\:bg-current:hover {
    background-color: currentColor;
  }

  .xl\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-black40pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-petroleum:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-petroleum70pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-petroleum30pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-disabled:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-petroleum10pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-petroleum5pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-darkGreen:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-ok:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-green10pct:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-orange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-darkOrange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-lightOrange:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-error:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-yellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-darkYellow:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-gray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-lightGray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .xl\:hover\:bg-borderGray:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-transparent:focus {
    background-color: transparent;
  }

  .xl\:focus\:bg-current:focus {
    background-color: currentColor;
  }

  .xl\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(27, 27, 26, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-black40pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(164, 164, 163, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-petroleum:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(14, 71, 68, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-petroleum70pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 126, 124, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-petroleum30pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-disabled:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(183, 200, 199, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-petroleum10pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(231, 237, 236, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-petroleum5pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 246, 246, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-darkGreen:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-ok:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(38, 123, 56, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-green:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(46, 161, 73, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-green10pct:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 246, 237, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-orange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 113, 0, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-darkOrange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-lightOrange:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 227, 204, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-error:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(170, 54, 54, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-yellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(233, 194, 88, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-darkYellow:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(195, 176, 145, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-gray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(148, 148, 147, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-lightGray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .xl\:focus\:bg-borderGray:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(230, 230, 230, var(--tw-bg-opacity));
  }

  .xl\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .xl\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }

  .xl\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .xl\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }

  .xl\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .xl\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }

  .xl\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }

  .xl\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }

  .xl\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }

  .xl\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }

  .xl\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }

  .xl\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }

  .xl\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }

  .xl\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }

  .xl\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }

  .xl\:bg-none {
    background-image: none;
  }

  .xl\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops));
  }

  .xl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops));
  }

  .xl\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops));
  }

  .xl\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
  }

  .xl\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops));
  }

  .xl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops));
  }

  .xl\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops));
  }

  .xl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops));
  }

  .xl\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:from-black {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .xl\:from-black40pct {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .xl\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:from-petroleum {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .xl\:from-petroleum70pct {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .xl\:from-petroleum30pct {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xl\:from-disabled {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xl\:from-petroleum10pct {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .xl\:from-petroleum5pct {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .xl\:from-darkGreen {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xl\:from-ok {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xl\:from-green {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .xl\:from-green10pct {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .xl\:from-orange {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .xl\:from-darkOrange {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xl\:from-lightOrange {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .xl\:from-error {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xl\:from-yellow {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .xl\:from-darkYellow {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .xl\:from-gray {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .xl\:from-lightGray {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .xl\:from-borderGray {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .xl\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:from-black:hover {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .xl\:hover\:from-black40pct:hover {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .xl\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:from-petroleum:hover {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .xl\:hover\:from-petroleum70pct:hover {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .xl\:hover\:from-petroleum30pct:hover {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xl\:hover\:from-disabled:hover {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xl\:hover\:from-petroleum10pct:hover {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .xl\:hover\:from-petroleum5pct:hover {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .xl\:hover\:from-darkGreen:hover {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xl\:hover\:from-ok:hover {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xl\:hover\:from-green:hover {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .xl\:hover\:from-green10pct:hover {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .xl\:hover\:from-orange:hover {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .xl\:hover\:from-darkOrange:hover {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xl\:hover\:from-lightOrange:hover {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .xl\:hover\:from-error:hover {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xl\:hover\:from-yellow:hover {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .xl\:hover\:from-darkYellow:hover {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .xl\:hover\:from-gray:hover {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .xl\:hover\:from-lightGray:hover {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .xl\:hover\:from-borderGray:hover {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .xl\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:from-black:focus {
    --tw-gradient-from: #1b1b1a;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .xl\:focus\:from-black40pct:focus {
    --tw-gradient-from: #a4a4a3;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .xl\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:from-petroleum:focus {
    --tw-gradient-from: #0e4744;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .xl\:focus\:from-petroleum70pct:focus {
    --tw-gradient-from: #567e7c;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .xl\:focus\:from-petroleum30pct:focus {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xl\:focus\:from-disabled:focus {
    --tw-gradient-from: #b7c8c7;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xl\:focus\:from-petroleum10pct:focus {
    --tw-gradient-from: #e7edec;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .xl\:focus\:from-petroleum5pct:focus {
    --tw-gradient-from: #f3f6f6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .xl\:focus\:from-darkGreen:focus {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xl\:focus\:from-ok:focus {
    --tw-gradient-from: #267b38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xl\:focus\:from-green:focus {
    --tw-gradient-from: #2ea149;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .xl\:focus\:from-green10pct:focus {
    --tw-gradient-from: #eaf6ed;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .xl\:focus\:from-orange:focus {
    --tw-gradient-from: #ed7100;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .xl\:focus\:from-darkOrange:focus {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xl\:focus\:from-lightOrange:focus {
    --tw-gradient-from: #fbe3cc;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .xl\:focus\:from-error:focus {
    --tw-gradient-from: #aa3636;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xl\:focus\:from-yellow:focus {
    --tw-gradient-from: #e9c258;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .xl\:focus\:from-darkYellow:focus {
    --tw-gradient-from: #c3b091;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .xl\:focus\:from-gray:focus {
    --tw-gradient-from: #949493;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .xl\:focus\:from-lightGray:focus {
    --tw-gradient-from: #e5e5e5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .xl\:focus\:from-borderGray:focus {
    --tw-gradient-from: #e6e6e6;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .xl\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .xl\:via-black40pct {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .xl\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:via-petroleum {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .xl\:via-petroleum70pct {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .xl\:via-petroleum30pct {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xl\:via-disabled {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xl\:via-petroleum10pct {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .xl\:via-petroleum5pct {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .xl\:via-darkGreen {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xl\:via-ok {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xl\:via-green {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .xl\:via-green10pct {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .xl\:via-orange {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .xl\:via-darkOrange {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xl\:via-lightOrange {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .xl\:via-error {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xl\:via-yellow {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .xl\:via-darkYellow {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .xl\:via-gray {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .xl\:via-lightGray {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .xl\:via-borderGray {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .xl\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .xl\:hover\:via-black40pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .xl\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:hover\:via-petroleum:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .xl\:hover\:via-petroleum70pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .xl\:hover\:via-petroleum30pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xl\:hover\:via-disabled:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xl\:hover\:via-petroleum10pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .xl\:hover\:via-petroleum5pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .xl\:hover\:via-darkGreen:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xl\:hover\:via-ok:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xl\:hover\:via-green:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .xl\:hover\:via-green10pct:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .xl\:hover\:via-orange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .xl\:hover\:via-darkOrange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xl\:hover\:via-lightOrange:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .xl\:hover\:via-error:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xl\:hover\:via-yellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .xl\:hover\:via-darkYellow:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .xl\:hover\:via-gray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .xl\:hover\:via-lightGray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .xl\:hover\:via-borderGray:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .xl\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }

  .xl\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1b1b1a, var(--tw-gradient-to, rgba(27, 27, 26, 0));
  }

  .xl\:focus\:via-black40pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a4a4a3, var(--tw-gradient-to, rgba(164, 164, 163, 0));
  }

  .xl\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }

  .xl\:focus\:via-petroleum:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #0e4744, var(--tw-gradient-to, rgba(14, 71, 68, 0));
  }

  .xl\:focus\:via-petroleum70pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #567e7c, var(--tw-gradient-to, rgba(86, 126, 124, 0));
  }

  .xl\:focus\:via-petroleum30pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xl\:focus\:via-disabled:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b7c8c7, var(--tw-gradient-to, rgba(183, 200, 199, 0));
  }

  .xl\:focus\:via-petroleum10pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e7edec, var(--tw-gradient-to, rgba(231, 237, 236, 0));
  }

  .xl\:focus\:via-petroleum5pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f6f6, var(--tw-gradient-to, rgba(243, 246, 246, 0));
  }

  .xl\:focus\:via-darkGreen:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xl\:focus\:via-ok:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #267b38, var(--tw-gradient-to, rgba(38, 123, 56, 0));
  }

  .xl\:focus\:via-green:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2ea149, var(--tw-gradient-to, rgba(46, 161, 73, 0));
  }

  .xl\:focus\:via-green10pct:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eaf6ed, var(--tw-gradient-to, rgba(234, 246, 237, 0));
  }

  .xl\:focus\:via-orange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ed7100, var(--tw-gradient-to, rgba(237, 113, 0, 0));
  }

  .xl\:focus\:via-darkOrange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xl\:focus\:via-lightOrange:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbe3cc, var(--tw-gradient-to, rgba(251, 227, 204, 0));
  }

  .xl\:focus\:via-error:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #aa3636, var(--tw-gradient-to, rgba(170, 54, 54, 0));
  }

  .xl\:focus\:via-yellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e9c258, var(--tw-gradient-to, rgba(233, 194, 88, 0));
  }

  .xl\:focus\:via-darkYellow:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c3b091, var(--tw-gradient-to, rgba(195, 176, 145, 0));
  }

  .xl\:focus\:via-gray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #949493, var(--tw-gradient-to, rgba(148, 148, 147, 0));
  }

  .xl\:focus\:via-lightGray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5, var(--tw-gradient-to, rgba(229, 229, 229, 0));
  }

  .xl\:focus\:via-borderGray:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e6e6e6, var(--tw-gradient-to, rgba(230, 230, 230, 0));
  }

  .xl\:to-transparent {
    --tw-gradient-to: transparent;
  }

  .xl\:to-current {
    --tw-gradient-to: currentColor;
  }

  .xl\:to-black {
    --tw-gradient-to: #1b1b1a;
  }

  .xl\:to-black40pct {
    --tw-gradient-to: #a4a4a3;
  }

  .xl\:to-white {
    --tw-gradient-to: #fff;
  }

  .xl\:to-petroleum {
    --tw-gradient-to: #0e4744;
  }

  .xl\:to-petroleum70pct {
    --tw-gradient-to: #567e7c;
  }

  .xl\:to-petroleum30pct {
    --tw-gradient-to: #b7c8c7;
  }

  .xl\:to-disabled {
    --tw-gradient-to: #b7c8c7;
  }

  .xl\:to-petroleum10pct {
    --tw-gradient-to: #e7edec;
  }

  .xl\:to-petroleum5pct {
    --tw-gradient-to: #f3f6f6;
  }

  .xl\:to-darkGreen {
    --tw-gradient-to: #267b38;
  }

  .xl\:to-ok {
    --tw-gradient-to: #267b38;
  }

  .xl\:to-green {
    --tw-gradient-to: #2ea149;
  }

  .xl\:to-green10pct {
    --tw-gradient-to: #eaf6ed;
  }

  .xl\:to-orange {
    --tw-gradient-to: #ed7100;
  }

  .xl\:to-darkOrange {
    --tw-gradient-to: #aa3636;
  }

  .xl\:to-lightOrange {
    --tw-gradient-to: #fbe3cc;
  }

  .xl\:to-error {
    --tw-gradient-to: #aa3636;
  }

  .xl\:to-yellow {
    --tw-gradient-to: #e9c258;
  }

  .xl\:to-darkYellow {
    --tw-gradient-to: #c3b091;
  }

  .xl\:to-gray {
    --tw-gradient-to: #949493;
  }

  .xl\:to-lightGray {
    --tw-gradient-to: #e5e5e5;
  }

  .xl\:to-borderGray {
    --tw-gradient-to: #e6e6e6;
  }

  .xl\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }

  .xl\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }

  .xl\:hover\:to-black:hover {
    --tw-gradient-to: #1b1b1a;
  }

  .xl\:hover\:to-black40pct:hover {
    --tw-gradient-to: #a4a4a3;
  }

  .xl\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }

  .xl\:hover\:to-petroleum:hover {
    --tw-gradient-to: #0e4744;
  }

  .xl\:hover\:to-petroleum70pct:hover {
    --tw-gradient-to: #567e7c;
  }

  .xl\:hover\:to-petroleum30pct:hover {
    --tw-gradient-to: #b7c8c7;
  }

  .xl\:hover\:to-disabled:hover {
    --tw-gradient-to: #b7c8c7;
  }

  .xl\:hover\:to-petroleum10pct:hover {
    --tw-gradient-to: #e7edec;
  }

  .xl\:hover\:to-petroleum5pct:hover {
    --tw-gradient-to: #f3f6f6;
  }

  .xl\:hover\:to-darkGreen:hover {
    --tw-gradient-to: #267b38;
  }

  .xl\:hover\:to-ok:hover {
    --tw-gradient-to: #267b38;
  }

  .xl\:hover\:to-green:hover {
    --tw-gradient-to: #2ea149;
  }

  .xl\:hover\:to-green10pct:hover {
    --tw-gradient-to: #eaf6ed;
  }

  .xl\:hover\:to-orange:hover {
    --tw-gradient-to: #ed7100;
  }

  .xl\:hover\:to-darkOrange:hover {
    --tw-gradient-to: #aa3636;
  }

  .xl\:hover\:to-lightOrange:hover {
    --tw-gradient-to: #fbe3cc;
  }

  .xl\:hover\:to-error:hover {
    --tw-gradient-to: #aa3636;
  }

  .xl\:hover\:to-yellow:hover {
    --tw-gradient-to: #e9c258;
  }

  .xl\:hover\:to-darkYellow:hover {
    --tw-gradient-to: #c3b091;
  }

  .xl\:hover\:to-gray:hover {
    --tw-gradient-to: #949493;
  }

  .xl\:hover\:to-lightGray:hover {
    --tw-gradient-to: #e5e5e5;
  }

  .xl\:hover\:to-borderGray:hover {
    --tw-gradient-to: #e6e6e6;
  }

  .xl\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }

  .xl\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }

  .xl\:focus\:to-black:focus {
    --tw-gradient-to: #1b1b1a;
  }

  .xl\:focus\:to-black40pct:focus {
    --tw-gradient-to: #a4a4a3;
  }

  .xl\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }

  .xl\:focus\:to-petroleum:focus {
    --tw-gradient-to: #0e4744;
  }

  .xl\:focus\:to-petroleum70pct:focus {
    --tw-gradient-to: #567e7c;
  }

  .xl\:focus\:to-petroleum30pct:focus {
    --tw-gradient-to: #b7c8c7;
  }

  .xl\:focus\:to-disabled:focus {
    --tw-gradient-to: #b7c8c7;
  }

  .xl\:focus\:to-petroleum10pct:focus {
    --tw-gradient-to: #e7edec;
  }

  .xl\:focus\:to-petroleum5pct:focus {
    --tw-gradient-to: #f3f6f6;
  }

  .xl\:focus\:to-darkGreen:focus {
    --tw-gradient-to: #267b38;
  }

  .xl\:focus\:to-ok:focus {
    --tw-gradient-to: #267b38;
  }

  .xl\:focus\:to-green:focus {
    --tw-gradient-to: #2ea149;
  }

  .xl\:focus\:to-green10pct:focus {
    --tw-gradient-to: #eaf6ed;
  }

  .xl\:focus\:to-orange:focus {
    --tw-gradient-to: #ed7100;
  }

  .xl\:focus\:to-darkOrange:focus {
    --tw-gradient-to: #aa3636;
  }

  .xl\:focus\:to-lightOrange:focus {
    --tw-gradient-to: #fbe3cc;
  }

  .xl\:focus\:to-error:focus {
    --tw-gradient-to: #aa3636;
  }

  .xl\:focus\:to-yellow:focus {
    --tw-gradient-to: #e9c258;
  }

  .xl\:focus\:to-darkYellow:focus {
    --tw-gradient-to: #c3b091;
  }

  .xl\:focus\:to-gray:focus {
    --tw-gradient-to: #949493;
  }

  .xl\:focus\:to-lightGray:focus {
    --tw-gradient-to: #e5e5e5;
  }

  .xl\:focus\:to-borderGray:focus {
    --tw-gradient-to: #e6e6e6;
  }

  .xl\:bg-auto {
    background-size: auto;
  }

  .xl\:bg-cover {
    background-size: cover;
  }

  .xl\:bg-contain {
    background-size: contain;
  }

  .xl\:bg-fixed {
    background-attachment: fixed;
  }

  .xl\:bg-local {
    background-attachment: local;
  }

  .xl\:bg-scroll {
    background-attachment: scroll;
  }

  .xl\:bg-clip-border {
    background-clip: border-box;
  }

  .xl\:bg-clip-padding {
    background-clip: padding-box;
  }

  .xl\:bg-clip-content {
    background-clip: content-box;
  }

  .xl\:bg-clip-text {
    background-clip: text;
  }

  .xl\:bg-bottom {
    background-position: bottom;
  }

  .xl\:bg-center {
    background-position: center;
  }

  .xl\:bg-left {
    background-position: left;
  }

  .xl\:bg-left-bottom {
    background-position: left bottom;
  }

  .xl\:bg-left-top {
    background-position: left top;
  }

  .xl\:bg-right {
    background-position: right;
  }

  .xl\:bg-right-bottom {
    background-position: right bottom;
  }

  .xl\:bg-right-top {
    background-position: right top;
  }

  .xl\:bg-top {
    background-position: top;
  }

  .xl\:bg-repeat {
    background-repeat: repeat;
  }

  .xl\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .xl\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .xl\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .xl\:bg-repeat-round {
    background-repeat: round;
  }

  .xl\:bg-repeat-space {
    background-repeat: space;
  }

  .xl\:fill-current {
    fill: currentColor;
  }

  .xl\:stroke-current {
    stroke: currentColor;
  }

  .xl\:stroke-0 {
    stroke-width: 0;
  }

  .xl\:stroke-1 {
    stroke-width: 1;
  }

  .xl\:stroke-2 {
    stroke-width: 2;
  }

  .xl\:object-contain {
    object-fit: contain;
  }

  .xl\:object-cover {
    object-fit: cover;
  }

  .xl\:object-fill {
    object-fit: fill;
  }

  .xl\:object-none {
    object-fit: none;
  }

  .xl\:object-scale-down {
    object-fit: scale-down;
  }

  .xl\:object-bottom {
    object-position: bottom;
  }

  .xl\:object-center {
    object-position: center;
  }

  .xl\:object-left {
    object-position: left;
  }

  .xl\:object-left-bottom {
    object-position: left bottom;
  }

  .xl\:object-left-top {
    object-position: left top;
  }

  .xl\:object-right {
    object-position: right;
  }

  .xl\:object-right-bottom {
    object-position: right bottom;
  }

  .xl\:object-right-top {
    object-position: right top;
  }

  .xl\:object-top {
    object-position: top;
  }

  .xl\:p-0 {
    padding: 0;
  }

  .xl\:p-1 {
    padding: 0.25rem;
  }

  .xl\:p-2 {
    padding: 0.5rem;
  }

  .xl\:p-3 {
    padding: 0.75rem;
  }

  .xl\:p-4 {
    padding: 1rem;
  }

  .xl\:p-5 {
    padding: 1.25rem;
  }

  .xl\:p-6 {
    padding: 1.5rem;
  }

  .xl\:p-8 {
    padding: 2rem;
  }

  .xl\:p-10 {
    padding: 2.5rem;
  }

  .xl\:p-12 {
    padding: 3rem;
  }

  .xl\:p-16 {
    padding: 4rem;
  }

  .xl\:p-20 {
    padding: 5rem;
  }

  .xl\:p-24 {
    padding: 6rem;
  }

  .xl\:p-32 {
    padding: 8rem;
  }

  .xl\:p-40 {
    padding: 10rem;
  }

  .xl\:p-48 {
    padding: 12rem;
  }

  .xl\:p-56 {
    padding: 14rem;
  }

  .xl\:p-64 {
    padding: 16rem;
  }

  .xl\:p-300px {
    padding: 300px;
  }

  .xl\:p-230px {
    padding: 230px;
  }

  .xl\:p-180px {
    padding: 180px;
  }

  .xl\:p-147px {
    padding: 147px;
  }

  .xl\:p-123px {
    padding: 123px;
  }

  .xl\:p-100px {
    padding: 100px;
  }

  .xl\:p-80px {
    padding: 80px;
  }

  .xl\:p-60px {
    padding: 60px;
  }

  .xl\:p-50px {
    padding: 50px;
  }

  .xl\:p-40px {
    padding: 40px;
  }

  .xl\:p-35px {
    padding: 35px;
  }

  .xl\:p-34px-i {
    padding: 34px !important;
  }

  .xl\:p-30px-i {
    padding: 30px !important;
  }

  .xl\:p-24px-i {
    padding: 24px !important;
  }

  .xl\:p-20px-i {
    padding: 20px !important;
  }

  .xl\:p-15px-i {
    padding: 15px !important;
  }

  .xl\:p-13px-i {
    padding: 13px !important;
  }

  .xl\:p-12px-i {
    padding: 12px !important;
  }

  .xl\:p-10px-i {
    padding: 10px !important;
  }

  .xl\:p-8px-i {
    padding: 8px !important;
  }

  .xl\:p-6px-i {
    padding: 6px !important;
  }

  .xl\:p-5px-i {
    padding: 5px !important;
  }

  .xl\:p-3px-i {
    padding: 3px !important;
  }

  .xl\:p-0px-i {
    padding: 0px !important;
  }

  .xl\:p-34px {
    padding: 34px;
  }

  .xl\:p-30px {
    padding: 30px;
  }

  .xl\:p-24px {
    padding: 24px;
  }

  .xl\:p-20px {
    padding: 20px;
  }

  .xl\:p-15px {
    padding: 15px;
  }

  .xl\:p-13px {
    padding: 13px;
  }

  .xl\:p-12px {
    padding: 12px;
  }

  .xl\:p-10px {
    padding: 10px;
  }

  .xl\:p-8px {
    padding: 8px;
  }

  .xl\:p-6px {
    padding: 6px;
  }

  .xl\:p-5px {
    padding: 5px;
  }

  .xl\:p-3px {
    padding: 3px;
  }

  .xl\:p-menu {
    padding: 275px;
  }

  .xl\:p-px {
    padding: 1px !important;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .xl\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .xl\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .xl\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .xl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .xl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .xl\:px-300px {
    padding-left: 300px;
    padding-right: 300px;
  }

  .xl\:px-230px {
    padding-left: 230px;
    padding-right: 230px;
  }

  .xl\:px-180px {
    padding-left: 180px;
    padding-right: 180px;
  }

  .xl\:px-147px {
    padding-left: 147px;
    padding-right: 147px;
  }

  .xl\:px-123px {
    padding-left: 123px;
    padding-right: 123px;
  }

  .xl\:px-100px {
    padding-left: 100px;
    padding-right: 100px;
  }

  .xl\:px-80px {
    padding-left: 80px;
    padding-right: 80px;
  }

  .xl\:px-60px {
    padding-left: 60px;
    padding-right: 60px;
  }

  .xl\:px-50px {
    padding-left: 50px;
    padding-right: 50px;
  }

  .xl\:px-40px {
    padding-left: 40px;
    padding-right: 40px;
  }

  .xl\:px-35px {
    padding-left: 35px;
    padding-right: 35px;
  }

  .xl\:px-34px-i {
    padding-left: 34px !important;
    padding-right: 34px !important;
  }

  .xl\:px-30px-i {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .xl\:px-24px-i {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .xl\:px-20px-i {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .xl\:px-15px-i {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .xl\:px-13px-i {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }

  .xl\:px-12px-i {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .xl\:px-10px-i {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .xl\:px-8px-i {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .xl\:px-6px-i {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .xl\:px-5px-i {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .xl\:px-3px-i {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .xl\:px-0px-i {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .xl\:px-34px {
    padding-left: 34px;
    padding-right: 34px;
  }

  .xl\:px-30px {
    padding-left: 30px;
    padding-right: 30px;
  }

  .xl\:px-24px {
    padding-left: 24px;
    padding-right: 24px;
  }

  .xl\:px-20px {
    padding-left: 20px;
    padding-right: 20px;
  }

  .xl\:px-15px {
    padding-left: 15px;
    padding-right: 15px;
  }

  .xl\:px-13px {
    padding-left: 13px;
    padding-right: 13px;
  }

  .xl\:px-12px {
    padding-left: 12px;
    padding-right: 12px;
  }

  .xl\:px-10px {
    padding-left: 10px;
    padding-right: 10px;
  }

  .xl\:px-8px {
    padding-left: 8px;
    padding-right: 8px;
  }

  .xl\:px-6px {
    padding-left: 6px;
    padding-right: 6px;
  }

  .xl\:px-5px {
    padding-left: 5px;
    padding-right: 5px;
  }

  .xl\:px-3px {
    padding-left: 3px;
    padding-right: 3px;
  }

  .xl\:px-menu {
    padding-left: 275px;
    padding-right: 275px;
  }

  .xl\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xl\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .xl\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .xl\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .xl\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .xl\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .xl\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .xl\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .xl\:py-300px {
    padding-top: 300px;
    padding-bottom: 300px;
  }

  .xl\:py-230px {
    padding-top: 230px;
    padding-bottom: 230px;
  }

  .xl\:py-180px {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .xl\:py-147px {
    padding-top: 147px;
    padding-bottom: 147px;
  }

  .xl\:py-123px {
    padding-top: 123px;
    padding-bottom: 123px;
  }

  .xl\:py-100px {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .xl\:py-80px {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .xl\:py-60px {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .xl\:py-50px {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .xl\:py-40px {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .xl\:py-35px {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .xl\:py-34px-i {
    padding-top: 34px !important;
    padding-bottom: 34px !important;
  }

  .xl\:py-30px-i {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .xl\:py-24px-i {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .xl\:py-20px-i {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .xl\:py-15px-i {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .xl\:py-13px-i {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  .xl\:py-12px-i {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .xl\:py-10px-i {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .xl\:py-8px-i {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .xl\:py-6px-i {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .xl\:py-5px-i {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .xl\:py-3px-i {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .xl\:py-0px-i {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .xl\:py-34px {
    padding-top: 34px;
    padding-bottom: 34px;
  }

  .xl\:py-30px {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .xl\:py-24px {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .xl\:py-20px {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .xl\:py-15px {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .xl\:py-13px {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .xl\:py-12px {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .xl\:py-10px {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .xl\:py-8px {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .xl\:py-6px {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .xl\:py-5px {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .xl\:py-3px {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .xl\:py-menu {
    padding-top: 275px;
    padding-bottom: 275px;
  }

  .xl\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .xl\:pt-0 {
    padding-top: 0;
  }

  .xl\:pt-1 {
    padding-top: 0.25rem;
  }

  .xl\:pt-2 {
    padding-top: 0.5rem;
  }

  .xl\:pt-3 {
    padding-top: 0.75rem;
  }

  .xl\:pt-4 {
    padding-top: 1rem;
  }

  .xl\:pt-5 {
    padding-top: 1.25rem;
  }

  .xl\:pt-6 {
    padding-top: 1.5rem;
  }

  .xl\:pt-8 {
    padding-top: 2rem;
  }

  .xl\:pt-10 {
    padding-top: 2.5rem;
  }

  .xl\:pt-12 {
    padding-top: 3rem;
  }

  .xl\:pt-16 {
    padding-top: 4rem;
  }

  .xl\:pt-20 {
    padding-top: 5rem;
  }

  .xl\:pt-24 {
    padding-top: 6rem;
  }

  .xl\:pt-32 {
    padding-top: 8rem;
  }

  .xl\:pt-40 {
    padding-top: 10rem;
  }

  .xl\:pt-48 {
    padding-top: 12rem;
  }

  .xl\:pt-56 {
    padding-top: 14rem;
  }

  .xl\:pt-64 {
    padding-top: 16rem;
  }

  .xl\:pt-300px {
    padding-top: 300px;
  }

  .xl\:pt-230px {
    padding-top: 230px;
  }

  .xl\:pt-180px {
    padding-top: 180px;
  }

  .xl\:pt-147px {
    padding-top: 147px;
  }

  .xl\:pt-123px {
    padding-top: 123px;
  }

  .xl\:pt-100px {
    padding-top: 100px;
  }

  .xl\:pt-80px {
    padding-top: 80px;
  }

  .xl\:pt-60px {
    padding-top: 60px;
  }

  .xl\:pt-50px {
    padding-top: 50px;
  }

  .xl\:pt-40px {
    padding-top: 40px;
  }

  .xl\:pt-35px {
    padding-top: 35px;
  }

  .xl\:pt-34px-i {
    padding-top: 34px !important;
  }

  .xl\:pt-30px-i {
    padding-top: 30px !important;
  }

  .xl\:pt-24px-i {
    padding-top: 24px !important;
  }

  .xl\:pt-20px-i {
    padding-top: 20px !important;
  }

  .xl\:pt-15px-i {
    padding-top: 15px !important;
  }

  .xl\:pt-13px-i {
    padding-top: 13px !important;
  }

  .xl\:pt-12px-i {
    padding-top: 12px !important;
  }

  .xl\:pt-10px-i {
    padding-top: 10px !important;
  }

  .xl\:pt-8px-i {
    padding-top: 8px !important;
  }

  .xl\:pt-6px-i {
    padding-top: 6px !important;
  }

  .xl\:pt-5px-i {
    padding-top: 5px !important;
  }

  .xl\:pt-3px-i {
    padding-top: 3px !important;
  }

  .xl\:pt-0px-i {
    padding-top: 0px !important;
  }

  .xl\:pt-34px {
    padding-top: 34px;
  }

  .xl\:pt-30px {
    padding-top: 30px;
  }

  .xl\:pt-24px {
    padding-top: 24px;
  }

  .xl\:pt-20px {
    padding-top: 20px;
  }

  .xl\:pt-15px {
    padding-top: 15px;
  }

  .xl\:pt-13px {
    padding-top: 13px;
  }

  .xl\:pt-12px {
    padding-top: 12px;
  }

  .xl\:pt-10px {
    padding-top: 10px;
  }

  .xl\:pt-8px {
    padding-top: 8px;
  }

  .xl\:pt-6px {
    padding-top: 6px;
  }

  .xl\:pt-5px {
    padding-top: 5px;
  }

  .xl\:pt-3px {
    padding-top: 3px;
  }

  .xl\:pt-menu {
    padding-top: 275px;
  }

  .xl\:pt-px {
    padding-top: 1px !important;
  }

  .xl\:pr-0 {
    padding-right: 0;
  }

  .xl\:pr-1 {
    padding-right: 0.25rem;
  }

  .xl\:pr-2 {
    padding-right: 0.5rem;
  }

  .xl\:pr-3 {
    padding-right: 0.75rem;
  }

  .xl\:pr-4 {
    padding-right: 1rem;
  }

  .xl\:pr-5 {
    padding-right: 1.25rem;
  }

  .xl\:pr-6 {
    padding-right: 1.5rem;
  }

  .xl\:pr-8 {
    padding-right: 2rem;
  }

  .xl\:pr-10 {
    padding-right: 2.5rem;
  }

  .xl\:pr-12 {
    padding-right: 3rem;
  }

  .xl\:pr-16 {
    padding-right: 4rem;
  }

  .xl\:pr-20 {
    padding-right: 5rem;
  }

  .xl\:pr-24 {
    padding-right: 6rem;
  }

  .xl\:pr-32 {
    padding-right: 8rem;
  }

  .xl\:pr-40 {
    padding-right: 10rem;
  }

  .xl\:pr-48 {
    padding-right: 12rem;
  }

  .xl\:pr-56 {
    padding-right: 14rem;
  }

  .xl\:pr-64 {
    padding-right: 16rem;
  }

  .xl\:pr-300px {
    padding-right: 300px;
  }

  .xl\:pr-230px {
    padding-right: 230px;
  }

  .xl\:pr-180px {
    padding-right: 180px;
  }

  .xl\:pr-147px {
    padding-right: 147px;
  }

  .xl\:pr-123px {
    padding-right: 123px;
  }

  .xl\:pr-100px {
    padding-right: 100px;
  }

  .xl\:pr-80px {
    padding-right: 80px;
  }

  .xl\:pr-60px {
    padding-right: 60px;
  }

  .xl\:pr-50px {
    padding-right: 50px;
  }

  .xl\:pr-40px {
    padding-right: 40px;
  }

  .xl\:pr-35px {
    padding-right: 35px;
  }

  .xl\:pr-34px-i {
    padding-right: 34px !important;
  }

  .xl\:pr-30px-i {
    padding-right: 30px !important;
  }

  .xl\:pr-24px-i {
    padding-right: 24px !important;
  }

  .xl\:pr-20px-i {
    padding-right: 20px !important;
  }

  .xl\:pr-15px-i {
    padding-right: 15px !important;
  }

  .xl\:pr-13px-i {
    padding-right: 13px !important;
  }

  .xl\:pr-12px-i {
    padding-right: 12px !important;
  }

  .xl\:pr-10px-i {
    padding-right: 10px !important;
  }

  .xl\:pr-8px-i {
    padding-right: 8px !important;
  }

  .xl\:pr-6px-i {
    padding-right: 6px !important;
  }

  .xl\:pr-5px-i {
    padding-right: 5px !important;
  }

  .xl\:pr-3px-i {
    padding-right: 3px !important;
  }

  .xl\:pr-0px-i {
    padding-right: 0px !important;
  }

  .xl\:pr-34px {
    padding-right: 34px;
  }

  .xl\:pr-30px {
    padding-right: 30px;
  }

  .xl\:pr-24px {
    padding-right: 24px;
  }

  .xl\:pr-20px {
    padding-right: 20px;
  }

  .xl\:pr-15px {
    padding-right: 15px;
  }

  .xl\:pr-13px {
    padding-right: 13px;
  }

  .xl\:pr-12px {
    padding-right: 12px;
  }

  .xl\:pr-10px {
    padding-right: 10px;
  }

  .xl\:pr-8px {
    padding-right: 8px;
  }

  .xl\:pr-6px {
    padding-right: 6px;
  }

  .xl\:pr-5px {
    padding-right: 5px;
  }

  .xl\:pr-3px {
    padding-right: 3px;
  }

  .xl\:pr-menu {
    padding-right: 275px;
  }

  .xl\:pr-px {
    padding-right: 1px !important;
  }

  .xl\:pb-0 {
    padding-bottom: 0;
  }

  .xl\:pb-1 {
    padding-bottom: 0.25rem;
  }

  .xl\:pb-2 {
    padding-bottom: 0.5rem;
  }

  .xl\:pb-3 {
    padding-bottom: 0.75rem;
  }

  .xl\:pb-4 {
    padding-bottom: 1rem;
  }

  .xl\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .xl\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .xl\:pb-8 {
    padding-bottom: 2rem;
  }

  .xl\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .xl\:pb-12 {
    padding-bottom: 3rem;
  }

  .xl\:pb-16 {
    padding-bottom: 4rem;
  }

  .xl\:pb-20 {
    padding-bottom: 5rem;
  }

  .xl\:pb-24 {
    padding-bottom: 6rem;
  }

  .xl\:pb-32 {
    padding-bottom: 8rem;
  }

  .xl\:pb-40 {
    padding-bottom: 10rem;
  }

  .xl\:pb-48 {
    padding-bottom: 12rem;
  }

  .xl\:pb-56 {
    padding-bottom: 14rem;
  }

  .xl\:pb-64 {
    padding-bottom: 16rem;
  }

  .xl\:pb-300px {
    padding-bottom: 300px;
  }

  .xl\:pb-230px {
    padding-bottom: 230px;
  }

  .xl\:pb-180px {
    padding-bottom: 180px;
  }

  .xl\:pb-147px {
    padding-bottom: 147px;
  }

  .xl\:pb-123px {
    padding-bottom: 123px;
  }

  .xl\:pb-100px {
    padding-bottom: 100px;
  }

  .xl\:pb-80px {
    padding-bottom: 80px;
  }

  .xl\:pb-60px {
    padding-bottom: 60px;
  }

  .xl\:pb-50px {
    padding-bottom: 50px;
  }

  .xl\:pb-40px {
    padding-bottom: 40px;
  }

  .xl\:pb-35px {
    padding-bottom: 35px;
  }

  .xl\:pb-34px-i {
    padding-bottom: 34px !important;
  }

  .xl\:pb-30px-i {
    padding-bottom: 30px !important;
  }

  .xl\:pb-24px-i {
    padding-bottom: 24px !important;
  }

  .xl\:pb-20px-i {
    padding-bottom: 20px !important;
  }

  .xl\:pb-15px-i {
    padding-bottom: 15px !important;
  }

  .xl\:pb-13px-i {
    padding-bottom: 13px !important;
  }

  .xl\:pb-12px-i {
    padding-bottom: 12px !important;
  }

  .xl\:pb-10px-i {
    padding-bottom: 10px !important;
  }

  .xl\:pb-8px-i {
    padding-bottom: 8px !important;
  }

  .xl\:pb-6px-i {
    padding-bottom: 6px !important;
  }

  .xl\:pb-5px-i {
    padding-bottom: 5px !important;
  }

  .xl\:pb-3px-i {
    padding-bottom: 3px !important;
  }

  .xl\:pb-0px-i {
    padding-bottom: 0px !important;
  }

  .xl\:pb-34px {
    padding-bottom: 34px;
  }

  .xl\:pb-30px {
    padding-bottom: 30px;
  }

  .xl\:pb-24px {
    padding-bottom: 24px;
  }

  .xl\:pb-20px {
    padding-bottom: 20px;
  }

  .xl\:pb-15px {
    padding-bottom: 15px;
  }

  .xl\:pb-13px {
    padding-bottom: 13px;
  }

  .xl\:pb-12px {
    padding-bottom: 12px;
  }

  .xl\:pb-10px {
    padding-bottom: 10px;
  }

  .xl\:pb-8px {
    padding-bottom: 8px;
  }

  .xl\:pb-6px {
    padding-bottom: 6px;
  }

  .xl\:pb-5px {
    padding-bottom: 5px;
  }

  .xl\:pb-3px {
    padding-bottom: 3px;
  }

  .xl\:pb-menu {
    padding-bottom: 275px;
  }

  .xl\:pb-px {
    padding-bottom: 1px !important;
  }

  .xl\:pl-0 {
    padding-left: 0;
  }

  .xl\:pl-1 {
    padding-left: 0.25rem;
  }

  .xl\:pl-2 {
    padding-left: 0.5rem;
  }

  .xl\:pl-3 {
    padding-left: 0.75rem;
  }

  .xl\:pl-4 {
    padding-left: 1rem;
  }

  .xl\:pl-5 {
    padding-left: 1.25rem;
  }

  .xl\:pl-6 {
    padding-left: 1.5rem;
  }

  .xl\:pl-8 {
    padding-left: 2rem;
  }

  .xl\:pl-10 {
    padding-left: 2.5rem;
  }

  .xl\:pl-12 {
    padding-left: 3rem;
  }

  .xl\:pl-16 {
    padding-left: 4rem;
  }

  .xl\:pl-20 {
    padding-left: 5rem;
  }

  .xl\:pl-24 {
    padding-left: 6rem;
  }

  .xl\:pl-32 {
    padding-left: 8rem;
  }

  .xl\:pl-40 {
    padding-left: 10rem;
  }

  .xl\:pl-48 {
    padding-left: 12rem;
  }

  .xl\:pl-56 {
    padding-left: 14rem;
  }

  .xl\:pl-64 {
    padding-left: 16rem;
  }

  .xl\:pl-300px {
    padding-left: 300px;
  }

  .xl\:pl-230px {
    padding-left: 230px;
  }

  .xl\:pl-180px {
    padding-left: 180px;
  }

  .xl\:pl-147px {
    padding-left: 147px;
  }

  .xl\:pl-123px {
    padding-left: 123px;
  }

  .xl\:pl-100px {
    padding-left: 100px;
  }

  .xl\:pl-80px {
    padding-left: 80px;
  }

  .xl\:pl-60px {
    padding-left: 60px;
  }

  .xl\:pl-50px {
    padding-left: 50px;
  }

  .xl\:pl-40px {
    padding-left: 40px;
  }

  .xl\:pl-35px {
    padding-left: 35px;
  }

  .xl\:pl-34px-i {
    padding-left: 34px !important;
  }

  .xl\:pl-30px-i {
    padding-left: 30px !important;
  }

  .xl\:pl-24px-i {
    padding-left: 24px !important;
  }

  .xl\:pl-20px-i {
    padding-left: 20px !important;
  }

  .xl\:pl-15px-i {
    padding-left: 15px !important;
  }

  .xl\:pl-13px-i {
    padding-left: 13px !important;
  }

  .xl\:pl-12px-i {
    padding-left: 12px !important;
  }

  .xl\:pl-10px-i {
    padding-left: 10px !important;
  }

  .xl\:pl-8px-i {
    padding-left: 8px !important;
  }

  .xl\:pl-6px-i {
    padding-left: 6px !important;
  }

  .xl\:pl-5px-i {
    padding-left: 5px !important;
  }

  .xl\:pl-3px-i {
    padding-left: 3px !important;
  }

  .xl\:pl-0px-i {
    padding-left: 0px !important;
  }

  .xl\:pl-34px {
    padding-left: 34px;
  }

  .xl\:pl-30px {
    padding-left: 30px;
  }

  .xl\:pl-24px {
    padding-left: 24px;
  }

  .xl\:pl-20px {
    padding-left: 20px;
  }

  .xl\:pl-15px {
    padding-left: 15px;
  }

  .xl\:pl-13px {
    padding-left: 13px;
  }

  .xl\:pl-12px {
    padding-left: 12px;
  }

  .xl\:pl-10px {
    padding-left: 10px;
  }

  .xl\:pl-8px {
    padding-left: 8px;
  }

  .xl\:pl-6px {
    padding-left: 6px;
  }

  .xl\:pl-5px {
    padding-left: 5px;
  }

  .xl\:pl-3px {
    padding-left: 3px;
  }

  .xl\:pl-menu {
    padding-left: 275px;
  }

  .xl\:pl-px {
    padding-left: 1px !important;
  }

  .xl\:text-left {
    text-align: left;
  }

  .xl\:text-center {
    text-align: center;
  }

  .xl\:text-right {
    text-align: right;
  }

  .xl\:text-justify {
    text-align: justify;
  }

  .xl\:align-baseline {
    vertical-align: baseline;
  }

  .xl\:align-top {
    vertical-align: top;
  }

  .xl\:align-middle {
    vertical-align: middle;
  }

  .xl\:align-bottom {
    vertical-align: bottom;
  }

  .xl\:align-text-top {
    vertical-align: text-top;
  }

  .xl\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .xl\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .xl\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  }

  .xl\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .xl\:text-size-42 {
    font-size: 42px !important;
  }

  .xl\:text-size-32 {
    font-size: 32px !important;
  }

  .xl\:text-size-28 {
    font-size: 24px !important;
  }

  .xl\:text-size-24 {
    font-size: 24px !important;
  }

  .xl\:text-size-20 {
    font-size: 20px !important;
  }

  .xl\:text-size-18 {
    font-size: 18px !important;
  }

  .xl\:font-hairline {
    font-weight: 100;
  }

  .xl\:font-thin {
    font-weight: 200;
  }

  .xl\:font-light {
    font-weight: 300 !important;
  }

  .xl\:font-normal {
    font-weight: 400 !important;
  }

  .xl\:font-medium {
    font-weight: 500;
  }

  .xl\:font-semibold {
    font-weight: 600;
  }

  .xl\:font-bold {
    font-weight: 700 !important;
  }

  .xl\:font-extrabold {
    font-weight: 800;
  }

  .xl\:font-black {
    font-weight: 900;
  }

  .xl\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .xl\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .xl\:hover\:font-light:hover {
    font-weight: 300 !important;
  }

  .xl\:hover\:font-normal:hover {
    font-weight: 400 !important;
  }

  .xl\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .xl\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .xl\:hover\:font-bold:hover {
    font-weight: 700 !important;
  }

  .xl\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .xl\:hover\:font-black:hover {
    font-weight: 900;
  }

  .xl\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .xl\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .xl\:focus\:font-light:focus {
    font-weight: 300 !important;
  }

  .xl\:focus\:font-normal:focus {
    font-weight: 400 !important;
  }

  .xl\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .xl\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .xl\:focus\:font-bold:focus {
    font-weight: 700 !important;
  }

  .xl\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .xl\:focus\:font-black:focus {
    font-weight: 900;
  }

  .xl\:uppercase {
    text-transform: uppercase;
  }

  .xl\:lowercase {
    text-transform: lowercase;
  }

  .xl\:capitalize {
    text-transform: capitalize;
  }

  .xl\:normal-case {
    text-transform: none;
  }

  .xl\:italic {
    font-style: italic;
  }

  .xl\:not-italic {
    font-style: normal;
  }

  .xl\:ordinal, .xl\:slashed-zero, .xl\:lining-nums, .xl\:oldstyle-nums, .xl\:proportional-nums, .xl\:tabular-nums, .xl\:diagonal-fractions, .xl\:stacked-fractions {
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  }

  .xl\:normal-nums {
    font-variant-numeric: normal;
  }

  .xl\:ordinal {
    --tw-ordinal: ordinal;
  }

  .xl\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }

  .xl\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }

  .xl\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }

  .xl\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }

  .xl\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }

  .xl\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }

  .xl\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }

  .xl\:leading-size-54 {
    line-height: 54px !important;
  }

  .xl\:leading-size-40 {
    line-height: 40px !important;
  }

  .xl\:leading-size-32 {
    line-height: 32px !important;
  }

  .xl\:leading-size-28 {
    line-height: 28px !important;
  }

  .xl\:leading-size-24 {
    line-height: 24px !important;
  }

  .xl\:tracking-tightest {
    letter-spacing: -0.1em;
  }

  .xl\:tracking-tighter {
    letter-spacing: -0.05em;
  }

  .xl\:tracking-tight {
    letter-spacing: -0.025em;
  }

  .xl\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .xl\:tracking-wide {
    letter-spacing: 0.025em;
  }

  .xl\:tracking-wider {
    letter-spacing: 0.05em;
  }

  .xl\:tracking-widest {
    letter-spacing: 0.1em;
  }

  .xl\:text-transparent {
    color: transparent;
  }

  .xl\:text-current {
    color: currentColor;
  }

  .xl\:text-black {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .xl\:text-black40pct {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .xl\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:text-petroleum {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .xl\:text-petroleum70pct {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .xl\:text-petroleum30pct {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .xl\:text-disabled {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .xl\:text-petroleum10pct {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .xl\:text-petroleum5pct {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .xl\:text-darkGreen {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .xl\:text-ok {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .xl\:text-green {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .xl\:text-green10pct {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .xl\:text-orange {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .xl\:text-darkOrange {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .xl\:text-lightOrange {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .xl\:text-error {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .xl\:text-yellow {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .xl\:text-darkYellow {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .xl\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .xl\:text-lightGray {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .xl\:text-borderGray {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .xl\:hover\:text-transparent:hover {
    color: transparent;
  }

  .xl\:hover\:text-current:hover {
    color: currentColor;
  }

  .xl\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .xl\:hover\:text-black40pct:hover {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .xl\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:hover\:text-petroleum:hover {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .xl\:hover\:text-petroleum70pct:hover {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .xl\:hover\:text-petroleum30pct:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .xl\:hover\:text-disabled:hover {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .xl\:hover\:text-petroleum10pct:hover {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .xl\:hover\:text-petroleum5pct:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .xl\:hover\:text-darkGreen:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .xl\:hover\:text-ok:hover {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .xl\:hover\:text-green:hover {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .xl\:hover\:text-green10pct:hover {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .xl\:hover\:text-orange:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .xl\:hover\:text-darkOrange:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .xl\:hover\:text-lightOrange:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .xl\:hover\:text-error:hover {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .xl\:hover\:text-yellow:hover {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .xl\:hover\:text-darkYellow:hover {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .xl\:hover\:text-gray:hover {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .xl\:hover\:text-lightGray:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .xl\:hover\:text-borderGray:hover {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .xl\:focus\:text-transparent:focus {
    color: transparent;
  }

  .xl\:focus\:text-current:focus {
    color: currentColor;
  }

  .xl\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-text-opacity));
  }

  .xl\:focus\:text-black40pct:focus {
    --tw-text-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-text-opacity));
  }

  .xl\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .xl\:focus\:text-petroleum:focus {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  .xl\:focus\:text-petroleum70pct:focus {
    --tw-text-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-text-opacity));
  }

  .xl\:focus\:text-petroleum30pct:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .xl\:focus\:text-disabled:focus {
    --tw-text-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-text-opacity));
  }

  .xl\:focus\:text-petroleum10pct:focus {
    --tw-text-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-text-opacity));
  }

  .xl\:focus\:text-petroleum5pct:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-text-opacity));
  }

  .xl\:focus\:text-darkGreen:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .xl\:focus\:text-ok:focus {
    --tw-text-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-text-opacity));
  }

  .xl\:focus\:text-green:focus {
    --tw-text-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-text-opacity));
  }

  .xl\:focus\:text-green10pct:focus {
    --tw-text-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-text-opacity));
  }

  .xl\:focus\:text-orange:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-text-opacity));
  }

  .xl\:focus\:text-darkOrange:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .xl\:focus\:text-lightOrange:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-text-opacity));
  }

  .xl\:focus\:text-error:focus {
    --tw-text-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-text-opacity));
  }

  .xl\:focus\:text-yellow:focus {
    --tw-text-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-text-opacity));
  }

  .xl\:focus\:text-darkYellow:focus {
    --tw-text-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-text-opacity));
  }

  .xl\:focus\:text-gray:focus {
    --tw-text-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-text-opacity));
  }

  .xl\:focus\:text-lightGray:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-text-opacity));
  }

  .xl\:focus\:text-borderGray:focus {
    --tw-text-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-text-opacity));
  }

  .xl\:text-opacity-0 {
    --tw-text-opacity: 0;
  }

  .xl\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }

  .xl\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }

  .xl\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }

  .xl\:text-opacity-100 {
    --tw-text-opacity: 1;
  }

  .xl\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }

  .xl\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }

  .xl\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }

  .xl\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }

  .xl\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }

  .xl\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }

  .xl\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }

  .xl\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }

  .xl\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }

  .xl\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }

  .xl\:underline {
    text-decoration: underline;
  }

  .xl\:line-through {
    text-decoration: line-through;
  }

  .xl\:no-underline {
    text-decoration: none;
  }

  .xl\:hover\:underline:hover {
    text-decoration: underline;
  }

  .xl\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .xl\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .xl\:focus\:underline:focus {
    text-decoration: underline;
  }

  .xl\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .xl\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .xl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .xl\:placeholder-transparent::placeholder {
    color: transparent;
  }

  .xl\:placeholder-current::placeholder {
    color: currentColor;
  }

  .xl\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-black40pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-petroleum::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-petroleum70pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-petroleum30pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-disabled::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-petroleum10pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-petroleum5pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-darkGreen::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-ok::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-green::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-green10pct::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-orange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-darkOrange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-lightOrange::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-error::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-yellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-darkYellow::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-gray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-lightGray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-borderGray::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }

  .xl\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }

  .xl\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(27, 27, 26, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-black40pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(164, 164, 163, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-petroleum:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-petroleum70pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(86, 126, 124, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-petroleum30pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-disabled:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(183, 200, 199, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-petroleum10pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(231, 237, 236, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-petroleum5pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 246, 246, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-darkGreen:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-ok:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(38, 123, 56, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-green:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(46, 161, 73, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-green10pct:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(234, 246, 237, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-orange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 113, 0, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-darkOrange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-lightOrange:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 227, 204, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-error:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(170, 54, 54, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-yellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(233, 194, 88, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-darkYellow:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(195, 176, 145, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-gray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(148, 148, 147, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-lightGray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 229, 229, var(--tw-placeholder-opacity));
  }

  .xl\:focus\:placeholder-borderGray:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(230, 230, 230, var(--tw-placeholder-opacity));
  }

  .xl\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xl\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }

  .xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }

  .xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }

  .xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }

  .xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }

  .xl\:opacity-0 {
    opacity: 0;
  }

  .xl\:opacity-25 {
    opacity: 0.25;
  }

  .xl\:opacity-50 {
    opacity: 0.5;
  }

  .xl\:opacity-75 {
    opacity: 0.75;
  }

  .xl\:opacity-100 {
    opacity: 1;
  }

  .xl\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .xl\:hover\:opacity-25:hover {
    opacity: 0.25;
  }

  .xl\:hover\:opacity-50:hover {
    opacity: 0.5;
  }

  .xl\:hover\:opacity-75:hover {
    opacity: 0.75;
  }

  .xl\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .xl\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .xl\:focus\:opacity-25:focus {
    opacity: 0.25;
  }

  .xl\:focus\:opacity-50:focus {
    opacity: 0.5;
  }

  .xl\:focus\:opacity-75:focus {
    opacity: 0.75;
  }

  .xl\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .xl\:shadow-xs {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-default {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-outline {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-xs:hover {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-default:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-outline:hover {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-xs:focus {
    --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-default:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-outline:focus {
    --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .xl\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .xl\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .xl\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .xl\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }

  .xl\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }

  .xl\:transition-none {
    transition-property: none;
  }

  .xl\:transition-all {
    transition-property: all;
  }

  .xl\:transition-default {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .xl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .xl\:transition-opacity {
    transition-property: opacity;
  }

  .xl\:transition-shadow {
    transition-property: box-shadow;
  }

  .xl\:transition-transform {
    transition-property: transform;
  }

  .xl\:delay-75 {
    transition-delay: 75ms;
  }

  .xl\:delay-100 {
    transition-delay: 100ms;
  }

  .xl\:delay-150 {
    transition-delay: 150ms;
  }

  .xl\:delay-200 {
    transition-delay: 200ms;
  }

  .xl\:delay-300 {
    transition-delay: 300ms;
  }

  .xl\:delay-500 {
    transition-delay: 500ms;
  }

  .xl\:delay-700 {
    transition-delay: 700ms;
  }

  .xl\:delay-1000 {
    transition-delay: 1000ms;
  }

  .xl\:duration-75 {
    transition-duration: 75ms;
  }

  .xl\:duration-100 {
    transition-duration: 100ms;
  }

  .xl\:duration-150 {
    transition-duration: 150ms;
  }

  .xl\:duration-200 {
    transition-duration: 200ms;
  }

  .xl\:duration-300 {
    transition-duration: 300ms;
  }

  .xl\:duration-500 {
    transition-duration: 500ms;
  }

  .xl\:duration-700 {
    transition-duration: 700ms;
  }

  .xl\:duration-1000 {
    transition-duration: 1000ms;
  }

  .xl\:ease-linear {
    transition-timing-function: linear;
  }

  .xl\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .xl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .xl\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

/**
 * Apply cannot be used inside components since those are not processed by tailwind.
 * We can add more css with tailwind directives and include them into this file. This way they will be processed.
 */

/* autogenerated START */

@media (min-width: 1px) {
  h1, .header-large {
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 40px !important;
  }

  h1, .header-large {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h2, .header-medium {
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
  }

  h2, .header-medium {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h3, .header-small {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
  }

  h3, .header-small {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h3,
  .header-small {
    margin-block-end: 0 !important;
  }

  h4.header-x-small-bold {
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h4, .header-x-small {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
  }

  h4, .header-x-small {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h4,
  .header-x-small {
    margin-block-end: 0 !important;
  }

  .m-large {
    margin: 30px !important;
  }

  .m-medium {
    margin: 20px !important;
  }

  .m-small {
    margin: 10px !important;
  }

  .mx-large {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mx-medium {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mx-small {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-large {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-medium {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-small {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mt-large {
    margin-top: 30px !important;
  }

  .mt-medium {
    margin-top: 20px !important;
  }

  .mt-small {
    margin-top: 10px !important;
  }

  .mr-large {
    margin-right: 30px !important;
  }

  .mr-medium {
    margin-right: 20px !important;
  }

  .mr-small {
    margin-right: 10px !important;
  }

  .mb-large {
    margin-bottom: 30px !important;
  }

  .mb-medium {
    margin-bottom: 20px !important;
  }

  .mb-small {
    margin-bottom: 10px !important;
  }

  .ml-large {
    margin-left: 30px !important;
  }

  .ml-medium {
    margin-left: 20px !important;
  }

  .ml-small {
    margin-left: 10px !important;
  }

  .p-large {
    padding: 30px !important;
  }

  .p-medium {
    padding: 20px !important;
  }

  .p-small {
    padding: 10px !important;
  }

  .px-large {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .px-medium {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .px-small {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-large {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-medium {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-small {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pt-large {
    padding-top: 30px !important;
  }

  .pt-medium {
    padding-top: 20px !important;
  }

  .pt-small {
    padding-top: 10px !important;
  }

  .pr-large {
    padding-right: 30px !important;
  }

  .pr-medium {
    padding-right: 20px !important;
  }

  .pr-small {
    padding-right: 10px !important;
  }

  .pb-large {
    padding-bottom: 30px !important;
  }

  .pb-medium {
    padding-bottom: 20px !important;
  }

  .pb-small {
    padding-bottom: 10px !important;
  }

  .pl-large {
    padding-left: 30px !important;
  }

  .pl-medium {
    padding-left: 20px !important;
  }

  .pl-small {
    padding-left: 10px !important;
  }
}

@media (min-width: 768px) {
  h1, .header-large {
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 40px !important;
  }

  h1, .header-large {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h2, .header-medium {
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
  }

  h2, .header-medium {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h3, .header-small {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
  }

  h3, .header-small {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h3,
  .header-small {
    margin-block-end: 0 !important;
  }

  h4.header-x-small-bold {
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h4, .header-x-small {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
  }

  h4, .header-x-small {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h4,
  .header-x-small {
    margin-block-end: 0 !important;
  }

  .m-large {
    margin: 30px !important;
  }

  .m-medium {
    margin: 20px !important;
  }

  .m-small {
    margin: 10px !important;
  }

  .mx-large {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mx-medium {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mx-small {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .my-large {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-medium {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-small {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mt-large {
    margin-top: 30px !important;
  }

  .mt-medium {
    margin-top: 20px !important;
  }

  .mt-small {
    margin-top: 10px !important;
  }

  .mr-large {
    margin-right: 30px !important;
  }

  .mr-medium {
    margin-right: 20px !important;
  }

  .mr-small {
    margin-right: 10px !important;
  }

  .mb-large {
    margin-bottom: 30px !important;
  }

  .mb-medium {
    margin-bottom: 20px !important;
  }

  .mb-small {
    margin-bottom: 10px !important;
  }

  .ml-large {
    margin-left: 30px !important;
  }

  .ml-medium {
    margin-left: 20px !important;
  }

  .ml-small {
    margin-left: 10px !important;
  }

  .p-large {
    padding: 30px !important;
  }

  .p-medium {
    padding: 20px !important;
  }

  .p-small {
    padding: 10px !important;
  }

  .px-large {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .px-medium {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .px-small {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .py-large {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-medium {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-small {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pt-large {
    padding-top: 30px !important;
  }

  .pt-medium {
    padding-top: 20px !important;
  }

  .pt-small {
    padding-top: 10px !important;
  }

  .pr-large {
    padding-right: 30px !important;
  }

  .pr-medium {
    padding-right: 20px !important;
  }

  .pr-small {
    padding-right: 10px !important;
  }

  .pb-large {
    padding-bottom: 30px !important;
  }

  .pb-medium {
    padding-bottom: 20px !important;
  }

  .pb-small {
    padding-bottom: 10px !important;
  }

  .pl-large {
    padding-left: 30px !important;
  }

  .pl-medium {
    padding-left: 20px !important;
  }

  .pl-small {
    padding-left: 10px !important;
  }
}

@media (min-width: 1024px) {
  h1, .header-large {
    font-size: 42px !important;
    font-weight: 400 !important;
    line-height: 54px !important;
  }

  h1, .header-large {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h2, .header-medium {
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 40px !important;
  }

  h2, .header-medium {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h3, .header-small {
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
  }

  h3, .header-small {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h3,
  .header-small {
    margin-block-end: 0 !important;
  }

  h4.header-x-small-bold {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h4, .header-x-small {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
  }

  h4, .header-x-small {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h4,
  .header-x-small {
    margin-block-end: 0 !important;
  }

  .m-large {
    margin: 30px !important;
  }

  .m-medium {
    margin: 30px !important;
  }

  .m-small {
    margin: 20px !important;
  }

  .mx-large {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mx-medium {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mx-small {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-large {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-medium {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-small {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mt-large {
    margin-top: 30px !important;
  }

  .mt-medium {
    margin-top: 30px !important;
  }

  .mt-small {
    margin-top: 20px !important;
  }

  .mr-large {
    margin-right: 30px !important;
  }

  .mr-medium {
    margin-right: 30px !important;
  }

  .mr-small {
    margin-right: 20px !important;
  }

  .mb-large {
    margin-bottom: 30px !important;
  }

  .mb-medium {
    margin-bottom: 30px !important;
  }

  .mb-small {
    margin-bottom: 20px !important;
  }

  .ml-large {
    margin-left: 30px !important;
  }

  .ml-medium {
    margin-left: 30px !important;
  }

  .ml-small {
    margin-left: 20px !important;
  }

  .p-large {
    padding: 30px !important;
  }

  .p-medium {
    padding: 30px !important;
  }

  .p-small {
    padding: 20px !important;
  }

  .px-large {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .px-medium {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .px-small {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-large {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-medium {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-small {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pt-large {
    padding-top: 30px !important;
  }

  .pt-medium {
    padding-top: 30px !important;
  }

  .pt-small {
    padding-top: 20px !important;
  }

  .pr-large {
    padding-right: 30px !important;
  }

  .pr-medium {
    padding-right: 30px !important;
  }

  .pr-small {
    padding-right: 20px !important;
  }

  .pb-large {
    padding-bottom: 30px !important;
  }

  .pb-medium {
    padding-bottom: 30px !important;
  }

  .pb-small {
    padding-bottom: 20px !important;
  }

  .pl-large {
    padding-left: 30px !important;
  }

  .pl-medium {
    padding-left: 30px !important;
  }

  .pl-small {
    padding-left: 20px !important;
  }
}

@media (min-width: 1440px) {
  h1, .header-large {
    font-size: 42px !important;
    font-weight: 400 !important;
    line-height: 54px !important;
  }

  h1, .header-large {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h2, .header-medium {
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 40px !important;
  }

  h2, .header-medium {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h3, .header-small {
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
  }

  h3, .header-small {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h3,
  .header-small {
    margin-block-end: 0 !important;
  }

  h4.header-x-small-bold {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h4, .header-x-small {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
  }

  h4, .header-x-small {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h4,
  .header-x-small {
    margin-block-end: 0 !important;
  }

  .m-large {
    margin: 50px !important;
  }

  .m-medium {
    margin: 30px !important;
  }

  .m-small {
    margin: 20px !important;
  }

  .mx-large {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mx-medium {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mx-small {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-large {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .my-medium {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-small {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mt-large {
    margin-top: 50px !important;
  }

  .mt-medium {
    margin-top: 30px !important;
  }

  .mt-small {
    margin-top: 20px !important;
  }

  .mr-large {
    margin-right: 50px !important;
  }

  .mr-medium {
    margin-right: 30px !important;
  }

  .mr-small {
    margin-right: 20px !important;
  }

  .mb-large {
    margin-bottom: 50px !important;
  }

  .mb-medium {
    margin-bottom: 30px !important;
  }

  .mb-small {
    margin-bottom: 20px !important;
  }

  .ml-large {
    margin-left: 50px !important;
  }

  .ml-medium {
    margin-left: 30px !important;
  }

  .ml-small {
    margin-left: 20px !important;
  }

  .p-large {
    padding: 50px !important;
  }

  .p-medium {
    padding: 30px !important;
  }

  .p-small {
    padding: 20px !important;
  }

  .px-large {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .px-medium {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .px-small {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-large {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .py-medium {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-small {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pt-large {
    padding-top: 50px !important;
  }

  .pt-medium {
    padding-top: 30px !important;
  }

  .pt-small {
    padding-top: 20px !important;
  }

  .pr-large {
    padding-right: 50px !important;
  }

  .pr-medium {
    padding-right: 30px !important;
  }

  .pr-small {
    padding-right: 20px !important;
  }

  .pb-large {
    padding-bottom: 50px !important;
  }

  .pb-medium {
    padding-bottom: 30px !important;
  }

  .pb-small {
    padding-bottom: 20px !important;
  }

  .pl-large {
    padding-left: 50px !important;
  }

  .pl-medium {
    padding-left: 30px !important;
  }

  .pl-small {
    padding-left: 20px !important;
  }
}

@media (min-width: 1920px) {
  h1, .header-large {
    font-size: 42px !important;
    font-weight: 400 !important;
    line-height: 54px !important;
  }

  h1, .header-large {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h2, .header-medium {
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 40px !important;
  }

  h2, .header-medium {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h3, .header-small {
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 32px !important;
  }

  h3, .header-small {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h3,
  .header-small {
    margin-block-end: 0 !important;
  }

  h4.header-x-small-bold {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h4, .header-x-small {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
  }

  h4, .header-x-small {
    --tw-text-opacity: 1;
    color: rgba(14, 71, 68, var(--tw-text-opacity));
  }

  h4,
  .header-x-small {
    margin-block-end: 0 !important;
  }

  .m-large {
    margin: 50px !important;
  }

  .m-medium {
    margin: 30px !important;
  }

  .m-small {
    margin: 20px !important;
  }

  .mx-large {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mx-medium {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mx-small {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .my-large {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .my-medium {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-small {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mt-large {
    margin-top: 50px !important;
  }

  .mt-medium {
    margin-top: 30px !important;
  }

  .mt-small {
    margin-top: 20px !important;
  }

  .mr-large {
    margin-right: 50px !important;
  }

  .mr-medium {
    margin-right: 30px !important;
  }

  .mr-small {
    margin-right: 20px !important;
  }

  .mb-large {
    margin-bottom: 50px !important;
  }

  .mb-medium {
    margin-bottom: 30px !important;
  }

  .mb-small {
    margin-bottom: 20px !important;
  }

  .ml-large {
    margin-left: 50px !important;
  }

  .ml-medium {
    margin-left: 30px !important;
  }

  .ml-small {
    margin-left: 20px !important;
  }

  .p-large {
    padding: 50px !important;
  }

  .p-medium {
    padding: 30px !important;
  }

  .p-small {
    padding: 20px !important;
  }

  .px-large {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .px-medium {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .px-small {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .py-large {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .py-medium {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-small {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pt-large {
    padding-top: 50px !important;
  }

  .pt-medium {
    padding-top: 30px !important;
  }

  .pt-small {
    padding-top: 20px !important;
  }

  .pr-large {
    padding-right: 50px !important;
  }

  .pr-medium {
    padding-right: 30px !important;
  }

  .pr-small {
    padding-right: 20px !important;
  }

  .pb-large {
    padding-bottom: 50px !important;
  }

  .pb-medium {
    padding-bottom: 30px !important;
  }

  .pb-small {
    padding-bottom: 20px !important;
  }

  .pl-large {
    padding-left: 50px !important;
  }

  .pl-medium {
    padding-left: 30px !important;
  }

  .pl-small {
    padding-left: 20px !important;
  }
}
