// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@use './variables.scss' as vars;
@use './colors.scss' as cols;

// Reset Material default margin
@each $mat-class in vars.$mat-clases {
  .#{$mat-class} {
    margin: 0 !important;
  }
}
// Generate utility padding and margin classes for known sizes
// Examples: .p5: padding: 5px;
// .pl5: padding-left: 5px;
// .ph10: padding: inherit 10px;
// .pv0: padding: 0 inherit;
@each $size in vars.$sizes {
  .p#{$size} {
    padding: #{$size}px !important;
  }
  .pt#{$size} {
    padding-top: #{$size}px !important;
  }
  .pr#{$size} {
    padding-right: #{$size}px !important;
  }
  .pb#{$size} {
    padding-bottom: #{$size}px !important;
  }
  .pl#{$size} {
    padding-left: #{$size}px !important;
  }
  .ph#{$size} {
    padding-right: #{$size}px !important;
    padding-left: #{$size}px !important;
  }
  .pv#{$size} {
    padding-top: #{$size}px !important;
    padding-bottom: #{$size}px !important;
  }
  .m#{$size} {
    margin: #{$size}px !important;
  }
  .mt#{$size} {
    margin-top: #{$size}px !important;
  }
  .mr#{$size} {
    margin-right: #{$size}px !important;
  }
  .mb#{$size} {
    margin-bottom: #{$size}px !important;
  }
  .ml#{$size} {
    margin-left: #{$size}px !important;
  }
  .mh#{$size} {
    margin-right: #{$size}px !important;
    margin-left: #{$size}px !important;
  }
  .mv#{$size} {
    margin-top: #{$size}px !important;
    margin-bottom: #{$size}px !important;
  }
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

// widths
@each $width in vars.$widths {
  .w#{$width} {
    width: #{$width}px !important;
    min-width: #{$width}px !important;
  }
  // Max-width: mw
  .maxw#{$width} {
    max-width: #{$width}px !important;
  }
  // Min-width: mw
  .minw#{$width} {
    min-width: #{$width}px !important;
  }
}
@each $width in vars.$widths_pct {
  .w#{$width}-pct {
    width: #{$width * 1%} !important;
    min-width: #{$width * 1%} !important;
  }
  // Max-width pct: mw-xx-pct
  .mw#{$width}-pct {
    max-width: #{$width * 1%} !important;
  }
}

.h100-pct {
  height: 100%;
}

.evida_button {
  .mat-button-wrapper,
  .mat-mdc-button-wrapper {
    height: 40px;
    border-radius: 50%;
    width: 40px;
    line-height: 40px;
    float: left;
  }
}


/**
 * In a flex container, this item will grow to fit the parent
 */
.flex-auto {
  flex: 1 1 auto;
}

.bold {
  font-weight: 700;
}

.inactive,
[aria-disabled='true'] {
  color: cols.$petroleum10pct;
  cursor: auto !important;
}
.required {
  color: cols.$darkOrange;
  margin-left: 5px;
  font-size: 22px;
  vertical-align: sub;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.container-no-gutter {
  width: 100%;
}
.selectAll {
  user-select: all;
}
.nobr {
  white-space: nowrap;
}
